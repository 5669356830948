import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useGetClientOrdersList } from "src/hooks/useGetClientOrdersList";
import {
  ClientPage,
  FilterFieldsClientOrders,
  users,
} from "../../../../lib/constants";
import { navigate } from "gatsby";
import { Box, Typography } from "@mui/material";
import FilterOrders from "./Filter";
import { getUser } from "../../../../services/auth";
import GlobalTable from "../../admin/GlobalTable";
import StatusBadge from "../../../global/elements/StatusBadge";
import { changeMillisToDate, getInitialFilters } from "../../../../lib/helpers";
import {
  missionStatus,
  PilotPackageStatus,
  colorStatus,
  missionTypeConstant,
} from "../../../../lib/constants";

const { DateTime } = require("luxon");

const OrdersTable = ({ status, columns, enterpriseId }) => {
  const getStatus = status;
  const currentUser = getUser(users.user);

  const [
    filteredProjectList,
    isLoading,
    limit,
    setLimit,
    filter,
    setFilter,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllProject,
    setDataLoadedTill,
    setLastLoadedProject,
    sortBy,
    setSortBy,
    pageChanged,
    setPageChanged,
  ] = useGetClientOrdersList(
    getStatus,
    missionTypeConstant.clientOrder,
    enterpriseId
  );

  useEffect(() => {
    valueChangeHandler("InitialLoad", "InitialLoad", FilterFieldsClientOrders);
  }, []);

  const limitHandler = (value) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    setLimit(value);
    setDataLoadedTill(0);
    setAllProject([]);
    setPreviousPage(0);
    setLoadHandler(!loadHandler);
    setAllProject([]);
    setDataLoadedTill(0);
    setLastLoadedProject(null);
    setPagedata(pageDataNew);
  };

  const sortByHandler = (sort) => {
    setLoadHandler(!loadHandler);
    setAllProject([]);
    setDataLoadedTill(0);
    setLastLoadedProject(null);
    setPreviousPage(0);
    if (sort === sortBy.sortBy) {
      const order = sortBy.sortValue === "asc" ? "desc" : "asc";
      setSortBy({ sortBy: sort, sortValue: order });
    } else {
      setSortBy({ sortBy: sort, sortValue: "asc" });
    }
  };

  const valueChangeHandler = (value, field, filterFields) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    setDataLoadedTill(0);
    let filterDataNew = filter;
    if ((value == "") & (field == "")) {
      let changedField = Object.keys(filter).filter(
        (fld) => filterFields.indexOf(fld) == -1
      );
      changedField.forEach((chgFld) => {
        filterDataNew[chgFld] = "";
      });
    } else if ((value == "InitialLoad") & (field == "InitialLoad")) {
      const filterObj = getInitialFilters(filterFields);
      let filterDataSaved = filterObj?.filters || {};
      if (filterDataSaved) {
        if (getStatus == "draft") {
          filterDataSaved["Order Name"] = "";
          filterDataNew = filterDataSaved;
        } else {
          filterDataNew = filterDataSaved;
        }
      }
    } else {
      filterDataNew[field] = value;
    }
    localStorage.setItem("filter", JSON.stringify(filterDataNew));
    let applyFilterOn = {};
    filterFields.forEach((opt) => {
      if (filterDataNew[opt]) {
        applyFilterOn[opt] = filterDataNew[opt];
      }
    });
    setPreviousPage(0);
    setPagedata(pageDataNew);
    setAllProject([]);
    setLastLoadedProject(null);
    setFilter(applyFilterOn);
    // setLoadHandler(!loadHandler);
  };

  const valueConvertHandler = (type, value) => {
    switch (type) {
      case "date":
        return (
          <Typography variant="body1">
            {DateTime.fromISO(value["dateCreated"]).toISODate()}
          </Typography>
        );
      case "seconds":
        return (
          <Typography variant="body1">
            {value["dateCompleted"]
              ? changeMillisToDate(value["dateCompleted"])
              : "N/A"}
          </Typography>
        );
      case "array":
        return (
          <Typography variant="body1">
            {value["equipment"] && value["equipment"].length > 0
              ? value["equipment"].toString()
              : "n/a"}
          </Typography>
        );
      case "quote":
        return (
          <Typography variant="body1">
            {value["subscriptionId"] && (
              <StatusBadge status={colorStatus.yellow} name="N/A" />
            )}
            {(!value["subscriptionId"] && value["quoteStatus"] == undefined) ||
              (value["quoteStatus"] == "NOT RECEIVED" && (
                <StatusBadge status={colorStatus.red} name="Not received" />
              ))}
            {!value["subscriptionId"] && value["quoteStatus"] == "Received" && (
              <StatusBadge
                status={colorStatus.green}
                name={value["quoteStatus"]}
              />
            )}
          </Typography>
        );
      case "status":
        return (
          <Typography variant="body1">
            {(value["status"] == missionStatus.unassigned ||
              value["status"] == missionStatus.confirmedMission) && (
              <StatusBadge status={colorStatus.red} name="Pending operator" />
            )}
            {value["status"] == missionStatus.active && (
              <StatusBadge status={colorStatus.green} name="Active" />
            )}
            {value["status"] == missionStatus.initialClientOrder && (
              <StatusBadge status={colorStatus.yellow} name="Reviewing" />
            )}
            {value["status"] == missionStatus.completed && (
              <StatusBadge status={colorStatus.green} name="Completed" />
            )}
            {value["status"] == missionStatus.cancelled && (
              <StatusBadge status={colorStatus.grey} name="Cancelled" />
            )}
            {value["status"] == missionStatus.rejected && (
              <StatusBadge status={colorStatus.grey} name="Rejected" />
            )}
            {value["status"] == missionStatus.archive && (
              <StatusBadge status={colorStatus.grey} name="Archive" />
            )}
          </Typography>
        );
      case "pilotMissionStatus":
        return (
          <Typography variant="body1">
            {value["pilotPackageStatus"] == PilotPackageStatus.rejected && (
              <StatusBadge status={colorStatus.yellow} name="Rejected" />
            )}
            {value["pilotPackageStatus"] ==
              PilotPackageStatus.pendingReview && (
              <StatusBadge status={colorStatus.red} name="To review" />
            )}
            {value["pilotPackageStatus"] == PilotPackageStatus.approved && (
              <StatusBadge status={colorStatus.green} name="Approved" />
            )}
            {(!value["pilotPackageStatus"] ||
              value["pilotPackageStatus"] == PilotPackageStatus.open) && (
              <StatusBadge name="Not completed" />
            )}
          </Typography>
        );
      case "reject":
        var reason = value?.reason?.rejectedReason;
        return (
          <Typography variant="body1">
            {reason && reason.length > 0
              ? reason.map((val, i) => <li key={i}>{val}</li>)
              : ""}
          </Typography>
        );
      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    navigate(`/app/client/orderDetails/${value.id}`);
  };

  const paginationBtnClick = (type) => {
    let pageDataNew = pageData;
    if (type == "next") {
      if (!(pageDataNew.lastPage == pageDataNew["currentPage"])) {
        pageDataNew["currentPage"] = pageDataNew["currentPage"] + 1;
      }
    } else {
      pageDataNew["currentPage"] = pageDataNew["currentPage"] - 1;
    }
    setPageChanged(!pageChanged);
    setPagedata(pageDataNew);
  };
  return (
    <Box>
      <Box sx={{ marginTop: "3em" }}>
        <FilterOrders
          valueChangeHandler={valueChangeHandler}
          role={currentUser.role}
          missionTypeData={ClientPage.Orders}
        />
      </Box>
      <GlobalTable
        columns={columns}
        allData={filteredProjectList}
        valueConvertHandler={valueConvertHandler}
        goToPageFcn={goToPageFcn}
        loading={isLoading}
        limitHandler={limitHandler}
        limit={limit}
        paginationBtnClick={paginationBtnClick}
        currentPage={pageData.currentPage}
        isLastPage={pageData.isLast}
        lastPage={pageData.lastPage}
        sortByHandler={sortByHandler}
      />
    </Box>
  );
};

OrdersTable.propTypes = {
  status: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  enterpriseId: PropTypes.string,
};
OrdersTable.defaultProps = {
  enterpriseId: null,
};

export default OrdersTable;
