import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  clause_single: {
    margin: "15px 0",
  },
  clause_number: {
    marginRight: "10px",
  },
};

const Clause = ({ number, title, helperText, paragraph2 }) => {
  return (
    <Box sx={styles.clause_single}>
      <Box sx={{ display: "flex" }}>
        {number && (
          <Typography variant="subtitle1Med" sx={styles.clause_number}>
            {number}
          </Typography>
        )}
        <Box>
          <Typography variant="body1">{title}</Typography>
          {paragraph2 && (
            <Typography variant="body1" sx={{ marginTop: "20px" }}>
              {paragraph2}
            </Typography>
          )}
        </Box>
      </Box>
      {helperText && (
        <Typography
          variant="body1"
          sx={{ ...styles.clause_helper_text, marginLeft: number && "30px" }}
        >
          **{helperText}
        </Typography>
      )}
    </Box>
  );
};

Clause.propTypes = {
  number: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.string,
  paragraph2: PropTypes.string,
};

Clause.defaultProps = {
  number: undefined,
  title: undefined,
  helperText: undefined,
  paragraph2: undefined,
};

export default Clause;
