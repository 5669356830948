import React from "react";
import { Form, Formik } from "formik";
import AutoCompleteInput from "../FormInputComponents/AutoCompleteInput";
import * as Yup from "yup";
import { useStateIfMounted } from "use-state-if-mounted";
import TextFieldInput from "../FormInputComponents/TextFieldInput";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  marginRight: {
    marginRight: { sm: "10px" },
  },
  marginLeft: {
    marginLeft: { sm: "10px" },
  },
};

const FilterComponent = ({
  filterFields,
  multiple,
  filterFormName,
  filterFormLabel,
  clearDataAndTriggerReload,
  filter,
  setFilter,
}) => {
  const [selectedFields, setSelectedFields] = useStateIfMounted(null);
  const formInitialValues = {
    mainFilterFields: [],
  };

  const validationSchema = Yup.object().shape({
    mainFilterFields: Yup.array().nullable(),
  });

  const customOnChangeHandlerFcn = (value, setFieldValue, filterFormName) => {
    const previousFields = selectedFields;
    if (previousFields?.length > value?.length) {
      const deletedField = previousFields.filter((x) => !value.includes(x));
      let deletedFieldName = deletedField[0].value;
      const updatedFilter = filter;
      updatedFilter[deletedFieldName] = "";
      setFilter(updatedFilter);
      clearDataAndTriggerReload();
    }
    const checkValidityArray = selectedFields?.filter(
      (field) => field.type === "array"
    );
    const checkValidityDirectly = value.filter(
      (field) => field.type === "array"
    );
    if (
      !(checkValidityArray?.length > 0) ||
      !(checkValidityDirectly?.length > 1)
    ) {
      setSelectedFields(value);
      setFieldValue(filterFormName, value);
    }
  };
  const apiTriggerHandler = (data, value) => {
    const newFilterDataSet = filter;
    const currentFieldArray = filterFields?.filter(
      (field) => field.value === value && field.type === "array"
    );
    newFilterDataSet[value] = data;
    if (currentFieldArray?.length > 0) {
      const otherArrayFields = filterFields?.filter(
        (field) => field.value !== value && field.type === "array"
      );
      otherArrayFields?.forEach((otherFields) => {
        newFilterDataSet[otherFields.value] = "";
      });
    }
    setFilter(newFilterDataSet);
    clearDataAndTriggerReload();
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form>
            <AutoCompleteInput
              options={filterFields}
              name={filterFormName}
              label={filterFormLabel}
              multiple={multiple}
              customOnChangeHandler={true}
              customOnChangeHandlerFcn={(value) =>
                customOnChangeHandlerFcn(value, setFieldValue, filterFormName)
              }
            />
            <Grid container>
              {selectedFields &&
                selectedFields?.length > 0 &&
                selectedFields.map((data, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={5.77}
                      md={5.73}
                      lg={5.83}
                      xl={5.88}
                      sx={i % 2 === 0 ? styles.marginRight : styles.marginLeft}
                      key={i}
                    >
                      {data?.fieldType === "input" ? (
                        <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={data?.value}
                          type="text"
                          label={data?.label}
                          variant="standard"
                          customOnChangeHandler={true}
                          customOnChangeHandlerFcn={(e) =>
                            apiTriggerHandler(e.target.value, data?.value)
                          }
                        />
                      ) : (
                        <AutoCompleteInput
                          options={data.filterFields}
                          name={data?.value}
                          label={data?.label}
                          multiple={false}
                          customOnChangeHandler={true}
                          customOnChangeHandlerFcn={(value) =>
                            apiTriggerHandler(value, data?.value)
                          }
                        />
                      )}
                    </Grid>
                  );
                })}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

FilterComponent.propTypes = {
  filterFields: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  filterFormName: PropTypes.string,
  filterFormLabel: PropTypes.string,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  clearDataAndTriggerReload: PropTypes.func.isRequired,
};
FilterComponent.defaultProps = {
  multiple: false,
  filterFormLabel: "Please select a field to filter",
  filterFormName: "mainFilterFields",
};

export default FilterComponent;
