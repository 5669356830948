import React from "react";
import Layout from "../../../../Layout";
import PaymentTesting from "./PaymentTesting";

const PaymentWrapper = () => {
  return (
    <Layout pageTitle={"Payment testing"} hasSideMenu loading={false}>
      <PaymentTesting />
    </Layout>
  );
};

export default PaymentWrapper;
