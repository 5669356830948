import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
// import TextFieldInput from "../FormInputComponents/TextFieldInput";
import AppButton from "../elements/AppButton";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { userRoles } from "../../../lib/constants";
import { alignRight } from "../../../lib/styleConstants";
import MultiLineTextArea from "../../global/elements/TypographyElements/MultiLineTextArea";

const styles = {
  button: {
    width: { xs: "100%", md: "unset" },
    minWidth: "120px",
  },
};
const TextActionSection = ({ onSubmit, currentUser }) => {
  const validationSchema = Yup.object().shape({
    message: Yup.string().required("Please write a message to send."),
    document: Yup.string(),
    image: Yup.string(),
  });

  const initialValues = {
    message: "",
    document: "",
    image: "",
  };

  return (
    <Box sx={{ padding: "20px 10px" }}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => {
          return (
            <Form id="messageForm">
              <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                  <MultiLineTextArea
                    isFormik
                    name="message"
                    label="Type your text here."
                    setFieldValue={setFieldValue}
                    minRows={2}
                    required
                    placeholder="Type your text here."
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={alignRight}>
                  <AppButton
                    form="messageForm"
                    type={"submit"}
                    label={"Send"}
                    noIcon
                    look={
                      currentUser.role === userRoles.admin
                        ? "blue"
                        : userRoles.role === userRoles.pilot
                        ? "purple"
                        : "green"
                    }
                    addtionalStyle={styles.button}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

TextActionSection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default TextActionSection;
