import React, { useEffect, useState } from "react";
import Layout from "../../../../Layout";
import ShowDataLibrary from "./ShowDataLibrary";
import { getUser } from "../../../../services/auth";
import { users, userRoles } from "../../../../lib/constants";
import ArrowHeaderContainer from "../../NewDataLibrary/components/ArrowHeaderContainer";

const ShowDataLibraryLayout = () => {
  const [role, setRole] = useState(null);

  useEffect(() => {
    const userInfo = getUser(users.user);
    setRole(userInfo.role);
  }, []);

  return (
    <Layout loading={false}>
      <ArrowHeaderContainer
        header="Data library"
        redirectURL={
          role == userRoles.client
            ? "/app/client/my-account"
            : "/app/dashboard/info"
        }
      >
        <ShowDataLibrary />
      </ArrowHeaderContainer>
    </Layout>
  );
};

export default ShowDataLibraryLayout;
