import firebase from "src/firebase";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getUser } from "../services/auth";
import { getGroupOrdersList } from "../services/client";

export const useGetGroupOrderList = () => {
  let initalSortBy = { sortBy: "dateCreated", sortValue: "desc" };

  const [orders, setOrders] = useStateIfMounted([]);
  const [allOrder, setAllOrder] = useStateIfMounted([]);
  const [lastLoadedOrder, setLastLoadedOrder] = useStateIfMounted(null);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const [filteredOrderList, setFilteredOrderList] = useStateIfMounted([]);
  const [limit, setLimit] = useStateIfMounted(10);
  const [filter, setFilter] = useStateIfMounted({});
  const [loadHandler, setLoadHandler] = useStateIfMounted(false);
  const [sortBy, setSortBy] = useStateIfMounted(initalSortBy);
  const [reloadTriggered, setReloadTriggered] = useStateIfMounted(false);
  const [previousPage, setPreviousPage] = useStateIfMounted(0);
  const [dataloadedTill, setDataLoadedTill] = useStateIfMounted(0);
  const [pageChanged, setPageChanged] = useStateIfMounted(false);
  const [initialLoad, setInitialLoad] = useStateIfMounted(false);
  const [pageData, setPagedata] = useStateIfMounted({
    currentPage: 1,
    isLast: false,
    lastPage: null,
  });

  useEffect(() => {
    if (!firebase) {
      return;
    }
    if (!initialLoad) {
      setInitialLoad(true);
      return;
    }
    const user = getUser("user");
    setIsLoading(true);

    if (user?.isAnonymous) {
      setIsLoading(false);
      setPagedata({
        currentPage: 1,
        isLast: true,
        lastPage: 1,
      });
    } else {
      if (
        dataloadedTill >= pageData.currentPage ||
        (pageData.currentPage <= previousPage && reloadTriggered === false)
      ) {
        let prevPageMissionData = allOrder.slice(
          (pageData.currentPage - 1) * limit,
          pageData.currentPage * limit
        );
        setOrders(prevPageMissionData);
        setPreviousPage(pageData.currentPage - 1);
        setIsLoading(false);
      } else {
        if (reloadTriggered) {
          setPagedata({
            currentPage: 1,
            isLast: false,
            lastPage: null,
          });
          setPreviousPage(0);
        }
        getGroupOrdersList(firebase, lastLoadedOrder, limit, filter, sortBy)
          .then((data) => {
            setOrders(data.orders);
            let allOrderData = allOrder || [];
            setPreviousPage(pageData.currentPage - 1);
            setLastLoadedOrder(data.lastLoaded);
            setDataLoadedTill(pageData.currentPage);
            if (data.isLast) {
              let pageDataUpdated = pageData;
              pageDataUpdated["isLast"] = true;
              pageDataUpdated["lastPage"] = pageData.currentPage;
              setPagedata(pageDataUpdated);
            }
            if (reloadTriggered) {
              setPreviousPage(0);
              setAllOrder([...data.orders]);
            } else {
              setAllOrder([...allOrderData, ...data.orders]);
            }
            setReloadTriggered(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [filter, loadHandler, pageChanged]);

  useEffect(() => {
    let newOrderList = [...orders];
    setFilteredOrderList(newOrderList);
  }, [orders, loadHandler, pageChanged]);

  return [
    filteredOrderList,
    isLoading,
    limit,
    setLimit,
    filter,
    setFilter,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllOrder,
    setDataLoadedTill,
    setLastLoadedOrder,
    pageChanged,
    setPageChanged,
  ];
};
