import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MapDrawingTool from "../../mapDrawingTool/MapDrawingTool";
import { Box, Grid, Typography } from "@mui/material";
import theme from "../../../../theme";
import {
  OrderTypeConstant,
  singleOrderTypeLabel,
  specifyLocationTooltipInfo,
} from "../../../../lib/constants";
import TooltipInfo from "../../../global/elements/TooltipInfo";
import ShowLocations from "./ShowLocations";

const styles = {
  mapHeight: {
    height: "480px",
    borderRadius: "20px",
    overflow: "hidden",
  },
  circleCls: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.grey.white}`,
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
  },
  mTop15: { marginTop: "15px" },
  iconCls: {
    color: theme.palette.black.darkSeaBlack,
    fontSize: "24px",
  },
};

const ShowOrderTypeComp = ({ orderType }) => {
  ShowOrderTypeComp.propTypes = {
    orderType: PropTypes.string.isRequired,
  };
  const [tooltipInfo, setToolTipInfo] = useState("");

  useEffect(() => {
    if (orderType == OrderTypeConstant.area) {
      setToolTipInfo(specifyLocationTooltipInfo.areaInfo);
    } else if (orderType == OrderTypeConstant.linear) {
      setToolTipInfo(specifyLocationTooltipInfo.linearInfo);
    } else {
      setToolTipInfo(specifyLocationTooltipInfo.assetInfo);
    }
  }, []);

  return (
    <Box sx={styles.mTop15}>
      <Grid container spacing={1}>
        <Grid item xs={5} md={1}>
          <Box sx={styles.circleCls} />
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography variant="body1">
            {singleOrderTypeLabel[orderType]}
          </Typography>
        </Grid>
        <Grid item xs={1} md={4}>
          <TooltipInfo
            info={tooltipInfo}
            additionalStyle={styles.iconCls}
            type="outlined"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const SingleLocationMap = ({
  orderType,
  locationMap,
  locationLabel,
  areaSize,
  showOrderType,
  hideLocation,
}) => {
  const data = {
    locationName: locationLabel,
    areaSize: areaSize?.toFixed(2),
    orderType: orderType,
  };
  return (
    <>
      {!hideLocation && <ShowLocations i="1" item={data} />}
      <MapDrawingTool
        area={locationMap}
        readonly
        mapHeight={styles.mapHeight}
        type={orderType}
      />
      {showOrderType && <ShowOrderTypeComp orderType={orderType} />}
    </>
  );
};

SingleLocationMap.propTypes = {
  orderType: PropTypes.string.isRequired,
  locationMap: PropTypes.array,
  locationLabel: PropTypes.string,
  areaSize: PropTypes.number,
  showOrderType: PropTypes.bool,
  hideLocation: PropTypes.bool,
};

SingleLocationMap.defaultProps = {
  locationMap: [],
  locationLabel: "",
  areaSize: 0,
  showOrderType: true,
  hideLocation: false,
};

export default SingleLocationMap;
