import { Box, Typography, Card, Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../../../theme";
import firebase from "src/firebase";
import { updateMission } from "../../../../services/missions";
import { singleMissionTitle } from "../../../../lib/styleConstants";
import { AntSwitch } from "../../../../lib/styleConstants";

const styles = {
  createInvoiceCard: {
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
    flexFlow: { lg: "row", xs: "row wrap", md: "row wrap" },
    alignItems: "center",
    border: `1px solid ${theme.palette.primary.pistachio}`,
    boxShadow: "0px 0px 40px -12px rgba(0,248,0,0.3)",
    borderRadius: "10px",
    marginTop: "20px",
    padding: "20px",
    columnGap: "25px",
    color: "none",
    width: { xs: "100%", sm: "100%", md: "22%" },
    alignSelf: "end",
  },
  createInvoiceBox: {
    display: "flex",
    justifyContent: { xs: "center", md: "flex-end" },
  },
  textBox: {
    "&.MuiTypography-root": {
      fontSize: "18px",
      fontWeight: 500,
    },
    textTransform: "uppercase",
  },
};
const CreateInvoicePrompt = ({
  missionData,
  submitMissionHandler,
  onEditingFinished,
}) => {
  const [invoice, setInvoice] = React.useState(
    missionData.allowInvoice ? { allowInvoice: true } : { allowInvoice: false }
  );
  const handleChange = (event) => {
    setInvoice({ ...invoice, [event.target.name]: event.target.checked });
    onSubmitMission();
  };

  const onSubmitMission = () => {
    updateMission(firebase, missionData.id, {
      allowInvoice: !invoice.allowInvoice,
    })
      .then(() => {
        submitMissionHandler(true);
      })
      .catch((err) => submitMissionHandler(false, err.message))
      .finally(onEditingFinished());
  };
  return (
    <Box sx={styles.createInvoiceBox}>
      <Card sx={styles.createInvoiceCard}>
        <Typography variant="body2" sx={singleMissionTitle}>
          Create Invoice
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>No</Typography>
          <AntSwitch
            defaultChecked
            color="secondary"
            checked={invoice.allowInvoice}
            onChange={handleChange}
            name="allowInvoice"
            inputProps={{
              "aria-label": "secondary checkbox",
            }}
          />
          <Typography>Yes</Typography>
        </Stack>
      </Card>
    </Box>
  );
};

CreateInvoicePrompt.propTypes = {
  missionData: PropTypes.object.isRequired,
  submitMissionHandler: PropTypes.func.isRequired,
  onEditingFinished: PropTypes.func.isRequired,
};

export default CreateInvoicePrompt;
