import React, { useState } from "react";
import { Box, Typography, Modal } from "@mui/material";
import theme from "../../../theme";
import CancelIcon from "@mui/icons-material/Cancel";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import AppButton from "./AppButton";
import TwoDOrthomosiacMap from "../../../assets/images/icon_2D_orthomosiac_map.svg";
import ThreeDMapsPointClouds from "../../../assets/images/icon_3D_maps_point_clouds.svg";
import DigitalTerrainModels from "../../../assets/images/icon_digital_terrain_models.svg";
import DigitalSurfaceModels from "../../../assets/images/digital_surface_models.svg";
import TopographicMap from "../../../assets/images/icon_topographic_map.svg";
import ThermalMap from "../../../assets/images/icon_thermal_map-01.svg";
import PlantHealth from "../../../assets/images/icon_plant_health_1.svg";
import Panorama from "../../../assets/images/icon_360_panorama.svg";
import StreamData from "../../../assets/images/icon_live_stream_data.svg";
import RawData from "../../../assets/images/icon_raw_data_footage2.svg";
import lidar from "../../../assets/images/tower2.svg";

const styles = {
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: `0px 0px 4px rgb(0 0 0 / 25%) ${theme.palette.primary.palePistachio}`,
    // border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    width: { xs: 335, sm: 666, md: 800 },
    overflowY: "scroll",
    height: { xs: "100%", md: "80%" },
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "2px",
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "30px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  backgroundGradient: {
    // background: `linear-gradient(180deg, ${theme.palette.primary.main}19 0%, rgba(255,255,255,1) 30px)`,
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "3%",
  },
  closeModal: {
    color: theme.palette.status.errorLight,
  },
  dataTitle: {
    fontWeight: 500,
    color: theme.palette.black.coolGrey,
    display: "flex",
    alignItems: "center",
  },
  dataContainer: {
    display: { xs: "block", md: "flex" },
    padding: "0 2%",
  },
  dataImgBox: {
    width: { xs: "100%", md: "50%" },
  },
  mapIconBoxContainer: {
    paddingLeft: { xs: "0px", md: "6%" },
    marginTop: { xs: "3%", md: "0px" },
  },
  dataBlock: {
    width: { xs: "300px", sm: "550px", md: "400px" },
  },
  checkIcon: {
    fontSize: "18px",
    color: theme.palette.primary.main,
    marginRight: "5px",
  },
  descriptionStyle: {
    paddingLeft: "18px",
    textAlign: "left",
    marginLeft: "10px",
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  },
  button_style: {
    width: { xs: "143px", md: "156px" },
    padding: "8px 12px",
    borderRadius: 0,
    fontSize: { xs: "12px", md: "15px" },
  },
  nextPrevTitle: {
    padding: "10px",
    // fontSize: "12px",
    color: theme.palette.black.coolGrey,
  },
  mapIconBox: {
    padding: "42px",
    width: { xs: "293px", sm: "330px" },
    height: "330px",
    border: `3px solid ${theme.palette.black.darkSeaBlack}`,
    // boxShadow: `0px 0px 4px ${theme.palette.primary.pistachio}`,
    borderRadius: 0,
    textAlign: "center",
  },
  modalMapIcon: {
    height: "212px !important",
    width: "190px !important",
  },
};

const MapsModal = ({ open, setOpen, modalData, mapTypes, index }) => {
  const [activeMapType, setActiveMapType] = useState(index);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState(
    modalData?.description
  );
  const [fileFormat, setFileFormat] = useState(modalData?.acceptableFileFormat);
  const [nextTitle, setNextTitle] = useState(mapTypes[index + 1]?.label);
  const [prevTitle, setPrevTitle] = useState(mapTypes[index - 1]?.label);
  const [icon, setIcon] = useState(null);
  const [btnFlag, setBtnFlag] = useState(false);

  const getMapIcon = (index) => {
    switch (index) {
      case 0:
        return (
          <Box
            component="img"
            src={TwoDOrthomosiacMap}
            sx={styles.modalMapIcon}
          />
        );
      case 1:
        return (
          <Box
            component="img"
            src={ThreeDMapsPointClouds}
            sx={styles.modalMapIcon}
          />
        );
      case 2:
        return (
          <Box
            component="img"
            src={DigitalTerrainModels}
            sx={styles.modalMapIcon}
          />
        );
      case 3:
        return (
          <Box
            component="img"
            src={DigitalSurfaceModels}
            sx={styles.modalMapIcon}
          />
        );
      case 4:
        return (
          <Box component="img" src={TopographicMap} sx={styles.modalMapIcon} />
        );
      case 5:
        return (
          <Box component="img" src={ThermalMap} sx={styles.modalMapIcon} />
        );
      case 6:
        return (
          <Box component="img" src={PlantHealth} sx={styles.modalMapIcon} />
        );
      case 7:
        return (
          <Box component="img" src={StreamData} sx={styles.modalMapIcon} />
        );
      case 8:
        return <Box component="img" src={RawData} sx={styles.modalMapIcon} />;
      case 9:
        return <Box component="img" src={Panorama} sx={styles.modalMapIcon} />;
      case 10:
        return <Box component="img" src={lidar} sx={styles.modalMapIcon} />;
      default:
        return "none";
    }
  };

  const handleClose = () => {
    setActiveMapType(null);
    setOpen(false);
  };

  const getNext = () => {
    setBtnFlag(true);
    let arr = mapTypes.length;
    let idx = activeMapType + 1;
    let idx1 = idx % arr;
    setActiveMapType(idx1);
    setNextTitle(mapTypes[idx1 + 1]?.label);
    setPrevTitle(mapTypes[idx1 - 1]?.label);
    setModalTitle(mapTypes[idx1]?.label);
    setModalDescription(mapTypes[idx1]?.description);
    setFileFormat(mapTypes[idx1]?.acceptableFileFormat);
    setIcon(getMapIcon(idx1));
  };

  const getPrevious = () => {
    setBtnFlag(true);
    let idx;
    let arr = mapTypes.length;
    if (activeMapType === 0) {
      idx = activeMapType;
    } else {
      idx = activeMapType - 1;
    }
    let idx1 = idx % arr;
    setActiveMapType(idx1);
    setPrevTitle(mapTypes[idx1 - 1]?.label);
    setNextTitle(mapTypes[idx1 + 1]?.label);
    setModalTitle(mapTypes[idx1]?.label);
    setModalDescription(mapTypes[idx1]?.description);
    setFileFormat(mapTypes[idx1]?.acceptableFileFormat);
    setIcon(getMapIcon(idx1));
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <Box sx={styles.modalTitle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {!btnFlag ? modalData?.label : modalTitle}
            </Typography>
            <CancelIcon onClick={handleClose} sx={styles.closeModal} />
          </Box>

          <Box sx={styles.backgroundGradient}>
            <Box sx={styles.dataContainer}>
              <Box sx={styles.dataImgBox}>
                <Box sx={styles.dataBlock}>
                  <Typography sx={styles.dataTitle} variant="body1">
                    Map information
                  </Typography>
                  <Typography variant="body2">{modalDescription}</Typography>
                </Box>
                <Box sx={{ ...styles.dataBlock, marginTop: "1%" }}>
                  <Typography sx={styles.dataTitle} variant="body1">
                    DELIVERY FILE FORMATS:
                  </Typography>
                  {fileFormat?.map((item, i) => {
                    return (
                      <Box key={i}>
                        <Typography sx={styles.dataTitle} variant="body2">
                          <CheckIcon sx={styles.checkIcon} />
                          {item.title}
                        </Typography>
                        <Typography
                          sx={styles.descriptionStyle}
                          variant="body3"
                        >
                          {item.desc}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box sx={{ ...styles.dataImgBox, ...styles.mapIconBoxContainer }}>
                <Box sx={styles.mapIconBox}>
                  {!btnFlag ? getMapIcon(index) : icon}
                </Box>
              </Box>
            </Box>
            <hr
              style={{
                border: `1px solid ${theme.palette.primary.main}`,
                margin: "auto",
              }}
            />
            <Box sx={styles.btnContainer}>
              <Box>
                <AppButton
                  label={"< PREVIOUS"}
                  look={"inactive"}
                  onClick={getPrevious}
                  addtionalStyle={styles.button_style}
                />
                <Typography sx={styles.nextPrevTitle} variant="body3">
                  {prevTitle}
                </Typography>
              </Box>

              <Box>
                <AppButton
                  label={"NEXT >"}
                  look={"primary"}
                  onClick={getNext}
                  addtionalStyle={styles.button_style}
                />
                <Typography sx={styles.nextPrevTitle} variant="body3">
                  {nextTitle}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

MapsModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  mapTypes: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  mapIcon: PropTypes.func.isRequired,
};

export default MapsModal;
