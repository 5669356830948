import React from "react";
import theme from "../../../theme";
import SectionHeading from "../../global/elements/SectionHeading";
import Layout from "../../../Layout";
// import ButtonWithIcon from "../../global/elements/ButtonWithIcon";
import NewsTable from "./NewsTable";
import useGetNewsList from "../../../hooks/useGetNewsList";
import { Box } from "@mui/material";
import UniversalLoader from "../../global/elements/UniversalLoader";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AppButton from "../../global/elements/AppButton";

const styles = {
  tableContainer: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: { xs: "center", md: "flex-start" },
    marginTop: "20px",
  },
  loaderDiv: {
    paddingTop: "18%",
  },
};

const NewsPage = () => {
  const [allNews, loading] = useGetNewsList();

  return (
    <Layout pageTitle="News" hasSideMenu loading={loading}>
      {loading ? (
        <Box sx={styles.loaderDiv}>
          <UniversalLoader />
        </Box>
      ) : (
        <Box>
          <SectionHeading
            heading="News"
            backgroundColor={theme.palette.grey.warmGrey}
          />
          <br />
          <AppButton
            label="Add News"
            url="/app/admin/news/add"
            internalLink
            look="black"
            startIcon={<AddCircleOutlineOutlinedIcon />}
          />
          <br />
          <Box sx={styles.tableContainer}>
            {allNews?.map((item, i) => {
              return (
                <NewsTable
                  key={i}
                  title={item?.title}
                  content={item?.description}
                  link={`/app/admin/news/edit/${item?.id}`}
                  id={item?.id}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default NewsPage;
