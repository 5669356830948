import React from "react";
import { Box, Typography } from "@mui/material";

const dialogBody = {
  margin: "1em 0",
  textAlignLast: "center",
};

export const AdminDialogueBody = () => {
  return (
    <Box sx={dialogBody}>
      <Typography variant="h6" sx={{ marginBottom: "20px" }}>
        The client will receive this quote when you send it.
      </Typography>
      <Typography variant="body1">
        Are you sure you want to send this price quote? This action cannot be
        undone.
      </Typography>
    </Box>
  );
};
