import firebase from "src/firebase";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { isBrowser } from "src/services/auth";
import { getProjectMissionList } from "../services/project";

const useGetProjectSitesList = () => {
  const [loading, setLoading] = useStateIfMounted(false);
  const [projectSites, setProjectSites] = useStateIfMounted([]);
  const [projectSitesAll, setProjectSitesAll] = useStateIfMounted([]);
  const [limit, setLimit] = useStateIfMounted(10);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [isLastPage, setIsLastPage] = useStateIfMounted(false);
  const [lastLoadedPageNumber, setLastLoadedPageNumber] =
    useStateIfMounted(null);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [lastLoaded, setLastLoaded] = useStateIfMounted(null);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);
  const [filter, setFilter] = useStateIfMounted({
    projectNameSearch: "",
    searchMissionName: "",
    projectId: "",
    missionId: "",
    status: "",
  });
  const [sort, setSort] = useStateIfMounted({
    sortBy: "dateCreated",
    sortValue: "desc",
  });

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    try {
      setLoading(true);
      if (lastLoadedPageNumber >= currentPage) {
        let loadedDataInMemory = projectSitesAll.slice(
          limit * (currentPage - 1),
          currentPage * limit
        );
        setProjectSites(loadedDataInMemory);
      } else {
        getProjectMissionList(firebase, sort, filter, lastLoaded, limit).then(
          (snapshot) => {
            if (snapshot.status === "Success") {
              setProjectSites(snapshot.missions);
              setProjectSitesAll([...projectSitesAll, ...snapshot.missions]);
              if (snapshot.isLast) {
                setIsLastPage(true);
                setLastPageNumber(currentPage);
              } else {
                setIsLastPage(false);
                setLastPageNumber(null);
              }
              setLastLoaded(snapshot.lastLoaded);
              setLastLoadedPageNumber(currentPage);
            }
          }
        );
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setLoading(false);
    }
  }, [triggerReload]);

  return [
    projectSites,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    setIsLastPage,
    lastPageNumber,
    setLastPageNumber,
    filter,
    setFilter,
    sort,
    setSort,
    setLastLoaded,
    setLastLoadedPageNumber,
    setProjectSitesAll,
    loading,
    triggerReload,
    setTriggerReload,
  ];
};
export default useGetProjectSitesList;
