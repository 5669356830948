import React from "react";
import { Typography } from "@mui/material";
import { Box, Avatar } from "@mui/material";
import theme from "../../../theme";
import LabelDataSet from "./LabelDataSet";
import PropTypes from "prop-types";
import defaultImage from "src/assets/images/profileImage.jpg";
import CountryWithFlag from "./CountryWithFlag";
import AppButton from "./AppButton";

const styles = {
  main: {
    padding: "30px 10px 10px 20px",
    backgroundColor: theme.palette.primary.palePistachio,
    minHeight: "100%",
  },
  palePistachio: {
    display: "flex",
    position: "relative",
  },
  name: {
    fontSize: "14px",
    fontWeight: "500 !important",
    marginBottom: "5px !important",
    color: theme.palette.secondary.main,
  },
  profile_dataSet: {
    display: "flex",
    flexFlow: "wrap",
  },
  button_profile: {
    marginTop: "25px",
  },
  go_to_mission: {
    float: "left",
    width: { xs: "60%" },
    padding: "8px",
  },
  avatar: {
    width: "45px",
    height: "45px",
    marginRight: "20px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
};

const ProfileContainer = ({ page, pilotData, missionId, width, type }) => {
  return (
    <Box sx={{ width: width, marginRight: "10px" }}>
      <Box sx={styles.main}>
        <Box sx={styles.image_name_main}>
          <Avatar
            src={pilotData?.profileImageURL || defaultImage}
            alt={pilotData?.name}
            sx={styles.avatar}
          />
          <Box sx={{ marginTop: "5px" }}>
            <Typography variant="h5" component="h5" sx={styles.name}>
              {pilotData?.name}
            </Typography>
            <CountryWithFlag user_data={pilotData} marginTop={true} />
          </Box>
        </Box>
        <Box sx={styles.profile_dataSet}>
          <LabelDataSet heading="Email" value={pilotData?.email || "n/a"} />
          <LabelDataSet heading="Address" value={pilotData?.address || "n/a"} />
          {page == "contract" && (
            <LabelDataSet heading="City" value={pilotData?.city || "n/a"} />
          )}
          {page == "contract" && (
            <LabelDataSet
              heading="Registration Nr"
              value={pilotData?.registrationNo || "n/a"}
            />
          )}

          <LabelDataSet
            heading="Billing Address"
            value={pilotData?.billingAddress || "n/a"}
          />
          <LabelDataSet heading="Telephone" value={pilotData?.phone || "n/a"} />

          {page == "contract" && (
            <LabelDataSet
              heading="Postal Code"
              value={pilotData?.postalCode || "n/a"}
            />
          )}

          <LabelDataSet
            heading="Company"
            value={pilotData?.company ? "yes" : "No"}
            type="status"
          />

          {page == "contract" && (
            <LabelDataSet
              heading="Drone Licence"
              value={pilotData?.permissionToFly ? "yes" : "No"}
              type="status"
            />
          )}
          {page == "contract" && (
            <LabelDataSet
              heading="Insurance"
              value={pilotData?.insurance ? "yes" : "No"}
              type="status"
            />
          )}
        </Box>
      </Box>
      {missionId && (
        <Box sx={styles.button_profile}>
          <AppButton
            url={
              type === "mission"
                ? `/app/missions/${missionId}`
                : `/app/pilots/project/${missionId}`
            }
            internalLink
            label={
              type === "mission" ? "Go to mission page" : "Go to project page"
            }
            look="black"
          />
        </Box>
      )}
    </Box>
  );
};

ProfileContainer.propTypes = {
  page: PropTypes.string,
  pilotData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  missionId: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
};

ProfileContainer.defaultProps = {
  page: undefined,
  pilotData: undefined,
  missionId: undefined,
  width: undefined,
  type: undefined,
};

export default ProfileContainer;
