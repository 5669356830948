import React from "react";
import { Box, Typography } from "@mui/material";
import AppButton from "./AppButton";
import theme from "../../../theme";
import { boxShadowStyle } from "../../../lib/styleConstants";
const style = {
  createMission_main: {
    background: theme.palette.grey.white,
    padding: "15px",
    order: { xs: 1, md: 2 },
    height: "100%",
  },
  sample_work: {
    paddingTop: "10px",
    marginBottom: "auto",
  },
  readMoreContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "auto",
  },
  create_mission_btn: {
    marginTop: "20px",
  },
  container: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    height: "100%",
  },
};

const DashboardCreateMissionSection = () => {
  return (
    <Box sx={{ ...style.createMission_main, ...boxShadowStyle }}>
      <Box sx={style.container}>
        <Typography variant="body1" sx={style.sample_work}>
          You can sell your existing data through Crowddroning for customers in
          need of high resolution imagery. Or simply show us your skills to
          increase chances of getting missions.
        </Typography>
        <Box sx={style.readMoreContainer}>
          <AppButton
            url={`/app/missions/mission-globhe`}
            internalLink
            label="Read more"
            addtionalStyle={style.create_mission_btn}
            look="purple"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCreateMissionSection;
