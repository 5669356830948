import { Formik, Field, Form } from "formik";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Autocomplete, TextField, Grid } from "@mui/material";
import AppButton from "../../global/elements/AppButton";
import { adminTypesInput, admintype } from "../../../lib/constants";
import { formInputField } from "../../../lib/styleConstants";
import FormErrors from "../../global/forms/FormErrors";

const style = {
  root: {
    "& form": {
      display: { xs: "flex" },
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  },
  inputField: {
    marginRight: { md: "10px" },
    marginLeft: 0,
    width: "100%",
  },

  formErrorsAlert: {
    marginTop: "20px",
    marginBottom: "20px",
  },
};

const AddAdminForm = ({ submitHandler }) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Box sx={style.root}>
      <Formik
        initialValues={{
          email: "",
          adminType: admintype.regularAdmin,
        }}
        onSubmit={(values) => {
          setSubmitting(true);
          submitHandler(values);
        }}
      >
        {({ values, setFieldValue, errors, submitCount }) => {
          return (
            <Form id="adminForm">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={7}>
                  <Box sx={formInputField}>
                    <Field
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      component={TextField}
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={(e) => setFieldValue("email", e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    sx={formInputField}
                    options={adminTypesInput}
                    onChange={(e, value) => setFieldValue("adminType", value)}
                    freeSolo
                    defaultValue={values.adminType}
                    variant="outlined"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Admin Type"
                        name="adminType"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <Box sx={{ position: "relative", width: "40%" }}>
                    <AppButton
                      look="blue"
                      type="submit"
                      label="Add admin"
                      form="adminForm"
                      submittingState={submitting}
                    />
                  </Box>
                </Grid>
              </Grid>
              <br />
              <FormErrors
                style={style.formErrorsAlert}
                show={submitCount > 0}
                errors={Object.values(errors)}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

AddAdminForm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
};

export default AddAdminForm;
