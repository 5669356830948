import { useEffect } from "react";
import {
  getClientById,
  getTotalCollectedData,
  getClientMissionInfo,
} from "../services/client";
import firebase from "src/firebase";
import { useStateIfMounted } from "use-state-if-mounted";
import { userRoles, users } from "../lib/constants";
import { claculateProgress } from "../services/clientProfile";
import { getUser } from "../services/auth";

const useGetClientAccountDetails = () => {
  const [clientInfo, setClientInfo] = useStateIfMounted(null);
  const [number, setNumber] = useStateIfMounted(0);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const [subPlanData, setSubPlanData] = useStateIfMounted({});
  const [dataCollectedInfo, setDataCollectedInfo] = useStateIfMounted(null);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);
  const [askFormTerms, setAskForTerms] = useStateIfMounted(false);

  useEffect(() => {
    try {
      if (!firebase) {
        return;
      }
      setIsLoading(true);
      let user = JSON.parse(localStorage.getItem(users.user));
      if (user && user.role == userRoles.client) {
        if (user.isAnonymous) {
          setIsLoading(false);
          setClientInfo(user);
        } else {
          /**-------------------Commented this is not required now----------------------- */
          // getSelectedSubDetails(firebase, user.userID).then((data) => {
          //   if (data.status == "Success") {
          //     setSubPlanData(data.data[0]);
          //   } else {
          //     setSubPlanData({});
          //   }
          // });
          const userProfile = getUser(users.userProfile);
          getClientMissionInfo(firebase, user.userID, userProfile?.enterPriseId)
            .then((data) => {
              if (data) {
                setNumber(data?.missionCount || 0);
                const formattedData = getTotalCollectedData(
                  Number(data?.totalMBs)
                );
                setDataCollectedInfo({
                  totalMBs: formattedData?.data,
                  totalMissionOfDataCollected:
                    data?.totalMissionOfDataCollected,
                  dataUnit: formattedData?.unit,
                });
              }
            })
            .catch((e) => {
              console.log("Error", e);
            });
          getClientById(firebase, user.userID).then((data) => {
            let profilePercent = claculateProgress(data);
            setClientInfo({ ...data, profilePercent: profilePercent });
            if (!data.termsOfUse) {
              setAskForTerms(true);
            }
          });
        }
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setIsLoading(false);
    }
  }, [
    setAskForTerms,
    setClientInfo,
    setDataCollectedInfo,
    setIsLoading,
    setNumber,
    triggerReload,
  ]);

  return [
    clientInfo,
    number,
    isLoading,
    dataCollectedInfo,
    subPlanData,
    triggerReload,
    setTriggerReload,
    askFormTerms,
    setAskForTerms,
  ];
};

export default useGetClientAccountDetails;
