import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import GlohbeMenuIcon from "./GlohbeMenuIcon";
import theme from "../../../theme";
import {
  sideMenuItemsForPilot,
  sideMenuItemsForAdmin,
  sideMenuItemsForClient,
  userRoles,
  users,
} from "../../../lib/constants";
import SideMenuOption from "./SideMenuOption";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Box, Typography, Divider } from "@mui/material";
import UserRoleTag from "./UserRoleTag";
import {
  accordionMenuItems,
  sideMenuOptionText,
  menuItemsWithIcons,
} from "../../../lib/styleConstants";
import { getUser } from "../../../services/auth";
import AppContext from "../../../contexts/AppContext";

const styles = {
  rootDiv: {
    backgroundColor: `${theme.palette.black.darkSeaBlack}0A`,
    overflowY: "auto",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    zIndex: 1300,
    height: { xs: "90vh", md: "100vh" },
    width: "280px",
  },
  iconContainer: {
    display: "flex",
    height: theme.mixins.toolbar.minHeight,
    alignItems: "center",
    textAlign: "center",
    marginBottom: "50px",
    justifyContent: "space-between",
  },
  menuContainer: {
    display: "flex",
    padding: { xs: 0, md: "10px" },
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    height: "100%",
  },
  mainContainer: {
    flex: 1,
    marginBottom: "auto",
  },
  topMenu: {
    display: "inherit",
    flexFlow: "inherit",
    alignItems: "start",
  },
  footerMenu: {
    display: "inherit",
    flexFlow: "inherit",
    alignItems: "start",
    marginTop: "auto",
  },
  signOut: {
    marginTop: "0",
    paddingBottom: "30px",
  },
  bottomLine: {
    color: theme.palette.black.darkSeaBlack,
  },
  optionIcon: {
    marginRight: "10px",
    color: theme.palette.black.warmGrey,
  },
  disabledMenu: {
    backgroundColor: theme.palette.adminBlue.lightBlue,
  },
};

const SideMenuDrawer = ({ currentUser }) => {
  var disabledMenu = false;
  let userTypeValue = null;

  const appContext = useContext(AppContext);

  if (appContext?.userData?.role == userRoles.client) {
    if (appContext?.userData?.enterPriseId) {
      userTypeValue = "Enterprise account";
    } else {
      userTypeValue = userRoles.client;
    }
  }

  if (currentUser.role == userRoles.client && !currentUser.isAnonymous) {
    let client = getUser(users.userProfile);
    if (!client.termsOfUse) {
      disabledMenu = true;
    }
  }
  return (
    <Box sx={styles.rootDiv}>
      <Box sx={styles.menuContainer}>
        <Box sx={styles.mainContainer}>
          <Box sx={styles.iconContainer}>
            {/* <Hidden implementation="css"> */}
            <GlohbeMenuIcon role={currentUser.role} showLogo={true} />
            {!currentUser.isAnonymous && (
              <UserRoleTag userType={userTypeValue} />
            )}
            {/* </Hidden> */}
          </Box>
          <Box
            sx={
              disabledMenu
                ? { ...styles.topMenu, ...styles.disabledMenu }
                : { ...styles.topMenu }
            }
          >
            {currentUser.role === "admin" ? (
              <>
                {Object.keys(sideMenuItemsForAdmin).map((key, index) => {
                  return (
                    <SideMenuOption
                      key={index}
                      title={key}
                      link={sideMenuItemsForAdmin[key]["link"]}
                      hasSubMenu={sideMenuItemsForAdmin[key]["hasSubMenu"]}
                      subMenus={sideMenuItemsForAdmin[key]["subMenus"]}
                      index={index}
                      anotherWindow={
                        sideMenuItemsForAdmin[key]["anotherWindow"] || false
                      }
                    />
                  );
                })}
              </>
            ) : currentUser.role === "client" ? (
              <>
                {Object.keys(sideMenuItemsForClient).map((key, index) => {
                  return (
                    <SideMenuOption
                      key={index}
                      title={key}
                      link={sideMenuItemsForClient[key]["link"]}
                      hasSubMenu={sideMenuItemsForClient[key]["hasSubMenu"]}
                      subMenus={sideMenuItemsForClient[key]["subMenus"]}
                      anotherWindow={
                        sideMenuItemsForClient[key]["anotherWindow"] || false
                      }
                      disabled={key == "OverView" ? false : disabledMenu}
                    />
                  );
                })}
                <Divider sx={styles.bottomLine} />
              </>
            ) : (
              <>
                {Object.keys(sideMenuItemsForPilot).map((key, index) => {
                  return (
                    <SideMenuOption
                      key={index}
                      title={key}
                      link={sideMenuItemsForPilot[key]["link"]}
                      hasSubMenu={sideMenuItemsForPilot[key]["hasSubMenu"]}
                      subMenus={sideMenuItemsForPilot[key]["subMenus"]}
                      anotherWindow={
                        sideMenuItemsForPilot[key]["anotherWindow"] || false
                      }
                    />
                  );
                })}
              </>
            )}
            <Divider sx={styles.bottomLine} />
          </Box>
          {currentUser.role !== "client" && (
            <>
              <a
                href="https://www.globhe.com/resources/operator-guidelines"
                style={accordionMenuItems}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="subtitle1Med" sx={sideMenuOptionText}>
                  Guidelines
                </Typography>
              </a>
              <a
                href="https://www.globhe.com/terms-and-conditions"
                style={accordionMenuItems}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="subtitle1Med" sx={sideMenuOptionText}>
                  T&C
                </Typography>
              </a>
            </>
          )}
          <Link
            to="/app/support"
            partiallyActive={true}
            style={{ ...menuItemsWithIcons, padding: "10px 15px", margin: 0 }}
          >
            <Typography variant="subtitle1Med" sx={sideMenuOptionText}>
              Contact support
            </Typography>
          </Link>
          <a
            href="https://www.globhe.com/resources/help-center"
            style={accordionMenuItems}
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="subtitle1Med" sx={sideMenuOptionText}>
              Help center
            </Typography>
          </a>
          <Divider sx={styles.bottomLine} />
        </Box>
        <Box sx={styles.footerMenu}>
          <Box sx={styles.signOut}>
            <Link
              to="/sign-out"
              partiallyActive={true}
              style={{ ...menuItemsWithIcons, padding: "10px 15px", margin: 0 }}
            >
              <LogoutOutlinedIcon sx={styles.optionIcon} />
              <Typography variant="subtitle1Med" sx={sideMenuOptionText}>
                Sign out
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SideMenuDrawer.propTypes = {
  currentUser: PropTypes.string.isRequired,
};
export default SideMenuDrawer;
