import React, { useState } from "react";
import PilotAvailabilityMap from "../../global/PilotAvailabilityMap";
import ReactTooltip from "react-tooltip";
import { Typography, AppBar, Box } from "@mui/material";
import CountryBarChart from "../../global/CountryBarChart";
import {
  useGetPilotNumber,
  useGetCountrytNumber,
  useGetCountryByAvailabilitytNumber,
  useGetCompaniesNumber,
} from "src/hooks/useGetPilotStatistics";
import { availabilityData, countryData } from "../../global/PilotData";
import theme from "../../../theme";
import Layout from "../../../Layout";
import { StyledTab, StyledTabs, appBar } from "../../../lib/styleConstants";

const style = {
  container: {
    display: { sm: "block", md: "flex" },
    alignItems: "stretch",
  },
  map: {
    width: { sm: "100%", md: "80%" },
    background: theme.palette.black.darkSeaBlack,
  },
  statistics: {
    backgroundColor: theme.palette.black.coolGrey,
    padding: "20px",
    width: { md: "20%" },
  },
  title: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
    color: theme.palette.grey.white,
    fontSize: { sm: "0.8rem" },
  },
  sectionTitle: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
    marginBottom: "1rem",
    paddingLeft: "60px",
    maxWidth: "100%",
  },
  number: {
    color: theme.palette.grey.lightWarmGrey,
    fontSize: { sm: "1.5rem" },
    textAlign: { xs: "center", sm: "left" },
    marginBottom: { xs: "1rem" },
  },
  numberText: {
    marginBottom: "2rem",
    color: theme.palette.primary.main,
  },
  countries: {
    fontSize: "1rem",
  },
  list: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  pageTitle: {
    padding: "60px 25px",
    maxWidth: "100%",
  },
  countryList: {
    paddingTop: "50px",
  },
  tabBox: {
    borderBottom: 1,
    borderColor: "divider",
    paddingLeft: { xs: "0px", sm: "50px" },
    justifyContent: { xs: "center", sm: "left" },
    paddingBottom: "30px",
    "& button": {
      fontSize: { xs: "10px", sm: "13px" },
    },
  },
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const PilotDashboard = () => {
  const [pilotNumber, setPilotNumber] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [pilots] = useGetPilotNumber();
  const [countries] = useGetCountrytNumber();
  const [countriesAvailibility] = useGetCountryByAvailabilitytNumber();
  const [companies] = useGetCompaniesNumber();

  return (
    <Layout
      pageTitle={"Drone Operator Dashboard"}
      loading={countries ? false : true}
      hasSideMenu
    >
      <Box sx={style.tabContainer}>
        <Box sx={{ width: "100%" }}>
          <Box sx={style.tabBox}>
            <AppBar position="static" sx={appBar} elevation={0}>
              <StyledTabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <StyledTab
                  key="countryAvailability"
                  label="By Country Availability"
                  {...a11yProps(0)}
                />
                <StyledTab
                  key="countryResidence"
                  label="By Country of residence"
                  {...a11yProps(0)}
                />
              </StyledTabs>
            </AppBar>
          </Box>
          <Box sx={style.container}>
            <Box sx={style.map}>
              {tabValue === 0 ? (
                <PilotAvailabilityMap
                  showPilotsCount={false}
                  setTooltipContent={setPilotNumber}
                  activateTooltip={false}
                  colorDomain={[1, 40]}
                  data={availabilityData}
                  source="pilot-dasboard"
                />
              ) : (
                <PilotAvailabilityMap
                  showPilotsCount={false}
                  setTooltipContent={setPilotNumber}
                  activateTooltip={false}
                  colorDomain={[1, 40]}
                  data={countryData}
                />
              )}
              <ReactTooltip>{pilotNumber}</ReactTooltip>
            </Box>
            <Box sx={style.statistics}>
              <Box sx={style.number}>
                <Typography variant="h6" style={style.title}>
                  Countries
                </Typography>
                {tabValue === 0 ? (
                  <Typography variant="h3" style={style.numberText}>
                    {countriesAvailibility}
                  </Typography>
                ) : (
                  <Typography variant="h3" style={style.numberText}>
                    {countries}
                  </Typography>
                )}
                <Typography variant="h6" style={style.title}>
                  Companies registered
                </Typography>
                <Typography variant="h3" style={style.numberText}>
                  {companies}
                </Typography>
                <Typography variant="h6" style={style.title}>
                  Total Drone Operators
                </Typography>
                <Typography variant="h3" style={style.numberText}>
                  {/* {pilots + 7482} */}
                  {pilots + 10716}
                </Typography>
                <Typography variant="h6" style={style.title}>
                  Individual Drone Operators
                </Typography>
                <Typography variant="h3" style={style.numberText}>
                  {pilots}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={style.countryList}>
            <Typography
              variant="h6"
              style={style.sectionTitle}
              color="textSecondary"
            >
              Availability by country
            </Typography>
            {tabValue === 0 ? (
              <CountryBarChart data={availabilityData} />
            ) : (
              <CountryBarChart data={countryData} />
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default PilotDashboard;
