import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import {
  OrderTypeConstant,
  areaUnitV2,
  areaUnits,
} from "../../../../lib/constants";
import theme from "../../../../theme";

const styles = {
  locationPinCls: { display: "flex", flexDirection: "row" },
  locationDetailsBox: { marginTop: "10px", marginBottom: "10px" },
  locationIndexCls: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
    backgroundColor: theme.palette.primary.main,
  },
};

const ShowLocations = ({ item, i }) => {
  const getUnit = () => {
    if (item?.orderType == OrderTypeConstant.area) {
      return areaUnits.sqkm;
    } else if (item?.orderType == OrderTypeConstant.linear) {
      return areaUnitV2.km;
    } else {
      return "";
    }
  };
  return (
    <Box sx={styles.locationDetailsBox}>
      <Grid container spacing={2}>
        <Grid item xs={2} md={1}>
          <Typography variant="body2" sx={styles.locationIndexCls}>
            {i}
          </Typography>
        </Grid>
        <Grid item xs={10} md={8}>
          <Box sx={styles.locationPinCls}>
            <FmdGoodOutlinedIcon />
            <Typography variant="body2">{item?.locationName}</Typography>
          </Box>
        </Grid>
        {item?.orderType == OrderTypeConstant.area ||
        item?.orderType == OrderTypeConstant.linear ? (
          <Grid item xs={12} md={3} sx={{ textAlign: "end" }}>
            <Typography variant="body2">
              {item?.areaSize} &nbsp; {getUnit()}
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};

ShowLocations.propTypes = {
  item: PropTypes.object.isRequired,
  i: PropTypes.string.isRequired,
};

export default ShowLocations;
