import { Box, Typography } from "@mui/material";
import React from "react";
import FilterComponent from "../../../global/elements/FilterComponent";
import { navigate } from "gatsby";
import GlobalTable from "../../admin/GlobalTable";
import {
  FilterFieldsForListingClientsAllSingleOrder,
  columnForListingClientsAllSingleOrders,
} from "../../../../lib/constants";
import theme from "../../../../theme";
import { boxShadowStyle } from "../../../../lib/styleConstants";
import { useGetAllSingleOrdersList } from "../../../../hooks/useGetAllSingleOrdersList";
import PropTypes from "prop-types";

const styles = {
  marginCss: { margin: "30px 0" },
  noDataText: {
    width: "90px",
    marginRight: "30px",
    borderRight: `1px solid ${theme.palette.grey.lightWarmGrey}`,
  },
  noOrders: {
    display: "flex",
    padding: "20px",
  },
};

const SingleOrderByClient = ({ clientId }) => {
  const {
    missions,
    setAllMissions,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedMission,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  } = useGetAllSingleOrdersList(clientId);

  const goToPageFcn = (value) => {
    navigate(`/app/missions/${value.id}`);
  };
  const clearDataAndTriggerReload = () => {
    setLastLoadedMission(null);
    setCurrentPage(1);
    setAllMissions([]);
    setLastPageNumber(null);
    setDataLoadedTill(null);
    setIslastPage(false);
    setTriggerReload(!triggerReload);
  };

  const limitHandler = (value) => {
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const paginationBtnClick = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (!(currentPage === 1)) {
        setCurrentPage(currentPage - 1);
        setIslastPage(null);
        setTriggerReload(!triggerReload);
      }
    }
  };

  if (missions?.length !== 0 || loading === true || filter != {}) {
    return (
      <Box>
        <Box>
          <FilterComponent
            filterFields={FilterFieldsForListingClientsAllSingleOrder}
            multiple={true}
            filterFormName={"mainFilterFields"}
            filter={filter}
            setFilter={setFilter}
            clearDataAndTriggerReload={clearDataAndTriggerReload}
          />
        </Box>

        <Box mt={2}>
          <GlobalTable
            columns={columnForListingClientsAllSingleOrders}
            allData={missions}
            goToPageFcn={goToPageFcn}
            loading={loading}
            limitHandler={limitHandler}
            limit={limit}
            paginationBtnClick={paginationBtnClick}
            currentPage={currentPage}
            isLastPage={isLastPage}
            lastPage={lastPageNumber}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ ...styles.noOrders, ...boxShadowStyle }} mt={2}>
        <Typography variant="h6">{"No orders"}</Typography>
      </Box>
    );
  }
};

SingleOrderByClient.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default SingleOrderByClient;
