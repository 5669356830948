import React, { useEffect } from "react";
import { Box, AppBar } from "@mui/material";
import ProjectHeaders from "../Components/ProjectHeaders";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import theme from "../../../../theme";
import ProjectDetailsTab from "./ProjectDetailsTab/ProjectDetailsTab";
import QuotesTab from "./QuotesTab/QuotesTab";
import { useGetProjectInfo } from "../../../../hooks/useGetProjectInfo";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import { useStateIfMounted } from "use-state-if-mounted";
import { getUser } from "../../../../services/auth";
import { userRoles } from "../../../../lib/constants";
import { StyledTab, StyledTabs, appBar } from "../../../../lib/styleConstants";
import TabPanel from "../../../global/elements/TabPanel";
import Layout from "../../../../Layout";
import { getClientById, getEnterPrise } from "../../../../services/client";
import ClientCommunication from "../../../global/TwoWayCommunication/MultiLocationOrderCommunication/ClientCommunication";
import AdminCommunication from "../../../global/TwoWayCommunication/MultiLocationOrderCommunication/AdminCommunication";
import DroneOperatorCommunication from "../../../global/TwoWayCommunication/MultiLocationOrderCommunication/DroneOperatorCommunication";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = {
  mainTabContainer: {
    width: "100%",
    marginTop: "40px",
    background: theme.palette.grey.white,
  },
  universalLoaderPosition: {
    marginTop: { xs: "50%", sm: "40%", md: "30%", lg: "20%" },
  },
  tabPanel: {
    padding: 0,
  },
};

const ProjectMainLayout = ({ projectId }) => {
  const currentUser = getUser("user");
  const pilotId =
    currentUser?.role == userRoles.pilot ? currentUser.userID : null;

  const [
    projectDetails,
    projectMissions,
    allPilotsData,
    allAdminsData,
    barchartData,
    loading,
    triggerReload,
    setTriggerReload,
  ] = useGetProjectInfo({ projectId, role: currentUser?.role, pilotId });

  // here sectionActive === 0 is admin and sectionActive === 1 is pilot
  const [sectionActive, setSectionActive] = useStateIfMounted(0);
  const [changeRole, setChangeRole] = useStateIfMounted(false);
  const [tabValue, setTabValue] = useStateIfMounted(0);
  const [currency, setCurrency] = useStateIfMounted(null);

  const handleTabChange = (e, newTabValue) => {
    setTabValue(newTabValue);
    // setRefreshMission(true);
  };
  useEffect(() => {
    if (sectionActive === 1) {
      setChangeRole(true);
    } else {
      setChangeRole(false);
    }
  }, [sectionActive]);

  useEffect(() => {
    if (window.location.hash === "#communications") {
      setTabValue(currentUser?.role === userRoles.pilot ? 1 : 2);
    } else {
      setTabValue(0);
    }
  }, [projectDetails]);

  const getCurrency = async (project) => {
    try {
      if (!project || !project?.id) {
        return null;
      }
      if (project?.enterPriseId) {
        const enterprise = await getEnterPrise(project?.enterPriseId);
        setCurrency(enterprise?.currency);
        return enterprise?.currency;
      } else if (project?.clientId) {
        const client = await getClientById(firebase, project.clientId);
        setCurrency(client?.currency);
        return client.currency;
      }
    } catch (e) {
      console.log("Error", e);
      return null;
    }
  };

  useEffect(() => {
    if (currentUser.role !== userRoles.pilot) getCurrency(projectDetails);
  }, [projectDetails?.id]);

  return (
    <>
      {!loading ? (
        <Layout>
          <ProjectHeaders
            role={currentUser?.role}
            currentProjectStatus={projectDetails?.status}
            projectId={projectId}
            projectName={projectDetails?.projectName}
            completedMissionCount={projectDetails?.allCompletedMissionCount}
            allMissionCount={projectDetails?.allMissionCount}
            sectionActive={sectionActive}
            setSectionActive={setSectionActive}
            projectMissions={projectMissions}
          />

          <Box sx={styles.mainTabContainer}>
            <AppBar position="static" sx={appBar} elevation={0}>
              <StyledTabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <StyledTab
                  key="project details"
                  label="project Details"
                  {...a11yProps(0)}
                />
                {currentUser?.role !== userRoles.pilot && (
                  <StyledTab
                    key="quotes & invoices"
                    label="quotes & invoices"
                    {...a11yProps(1)}
                  />
                )}
                <StyledTab
                  key="communication"
                  label="Communication"
                  {...a11yProps(currentUser?.role === userRoles.pilot ? 1 : 2)}
                />
              </StyledTabs>
            </AppBar>
            <TabPanel value={tabValue} index={0} key="projectDetails">
              <ProjectDetailsTab
                currentUser={currentUser}
                changeRole={changeRole}
                projectDetails={projectDetails}
                projectMissions={projectMissions}
                allPilotsData={allPilotsData}
                allAdminsData={allAdminsData}
                barchartData={barchartData}
                triggerReload={triggerReload}
                setTriggerReload={setTriggerReload}
              />
            </TabPanel>
            {currentUser?.role !== userRoles.pilot && (
              <TabPanel value={tabValue} index={1} key="quotesDetails">
                <QuotesTab
                  projectId={projectId}
                  project={projectDetails}
                  role={changeRole ? userRoles.client : currentUser?.role}
                  triggerReload={triggerReload}
                  setTriggerReload={setTriggerReload}
                  currency={currency}
                />
              </TabPanel>
            )}
            <TabPanel
              value={tabValue}
              index={currentUser?.role === userRoles.pilot ? 1 : 2}
              key="communication"
            >
              {currentUser.role === userRoles.pilot ? (
                <DroneOperatorCommunication projectDetails={projectDetails} />
              ) : currentUser.role === userRoles.admin ? (
                <AdminCommunication projectDetails={projectDetails} />
              ) : (
                <ClientCommunication projectDetails={projectDetails} />
              )}
            </TabPanel>
          </Box>
        </Layout>
      ) : (
        <Box sx={styles.universalLoaderPosition}>
          <UniversalLoader />
        </Box>
      )}
    </>
  );
};

ProjectMainLayout.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ProjectMainLayout;
