import firebase from "src/firebase";
import { getCurrentDateTime } from "../lib/helpers";

const bankSchema = async (data) => {
  const date = getCurrentDateTime();
  return firebase
    .firestore()
    .collection("bankDetails")
    .add({
      account_number: data?.account_number || "",
      accountHolderName: data?.accountHolderName || "",
      account_type: data?.account_type || "",
      businessAccount: data?.businessAccount || false,
      SWIFT: data?.SWIFT || "",
      routing_number: data?.routing_number || "",
      bank_name: data?.bank_name || "",
      bank_address: data?.bank_address || "",
      bank_postal_address: data?.bank_postal_address || "",
      alternative_payments_methods: data?.alternative_payments_methods || [],
      IBAN: data?.IBAN || "",
      company_name: data?.company_name || "",
      companyAddress: data?.companyAddress || "",
      companyCity: data?.companyCity || "",
      companyPostalCode: data?.companyPostalCode || "",
      registration_number: data?.registration_number || "",
      country: data?.country || "",
      email: data?.email || "",
      pilotId: data?.pilotId || "",
      dateCreated: date,
      deleted: data.deleted || false,
      lastUpdated: date,
      vat_number: data?.vat_number || "",
    })
    .then((docRef) => {
      return {
        status: "Success",
        bank_details: docRef,
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
      return {
        status: "Error",
        error: err,
      };
    });
};

export const createBankDetails = async (data) => {
  let status = await bankSchema(data);
  return status;
};

export const getBankDetail = (userID) => {
  return firebase
    .firestore()
    .collection("bankDetails")
    .where("pilotId", "==", userID)
    .get()
    .then(async (snapshot) => {
      const doc_ref = await snapshot.docs[0];
      let bank_details = null;
      if (doc_ref) {
        bank_details = doc_ref.data();
        bank_details["id"] = snapshot.docs[0].id;
      }
      return {
        bank_details,
      };
    })
    .catch((err) => {
      console.log("Error in getting the collection", err);
    });
};

const createBankObj = (data) => {
  const date = getCurrentDateTime();
  let obj = {
    account_number: data?.account_number || "",
    account_type: data?.account_type || "",
    accountHolderName: data?.accountHolderName || "",
    businessAccount: data?.businessAccount || false,
    companyAddress: data?.companyAddress || "",
    companyCity: data?.companyCity || "",
    companyPostalCode: data?.companyPostalCode || "",
    SWIFT: data?.SWIFT || "",
    routing_number: data?.routing_number || "",
    bank_name: data?.bank_name || "",
    bank_address: data?.bank_address || "",
    bank_postal_address: data?.bank_postal_address || "",
    alternative_payments_methods: data?.alternative_payments_methods || [],
    IBAN: data?.IBAN || "",
    company_name: data?.company_name || "",
    registration_number: data?.registration_number || "",
    country: data?.country || "",
    email: data?.email || "",
    pilotId: data?.pilotId || "",
    deleted: data.deleted || false,
    lastUpdated: date,
    vat_number: data?.vat_number || "",
  };
  return obj;
};

export const bankDetailsUpdate = (data, id) => {
  const date = getCurrentDateTime();
  const bankObj = createBankObj(data);
  return firebase
    .firestore()
    .collection("bankDetails")
    .doc(id)
    .update({
      // ...data,
      ...bankObj,
      lastUpdated: date,
    })
    .then((docRef) => {
      return {
        status: "Success",
      };
    })
    .catch((err) => {
      console.log("Error in updating the collection", err);
      return {
        status: "Error",
        error: err,
      };
    });
};
