import React from "react";
import { Box, Typography } from "@mui/material";
import AppButton from "../elements/AppButton";
import PropTypes from "prop-types";
import theme from "../../../theme";
import { limits } from "../../../lib/constants";

const styles = {
  pagination_ul: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2em",
  },
  pagination_button: {
    height: "36px",
    margin: "4px",
    fontWeight: "500",
  },
  flex: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  pagination_span: {
    // margin: "auto",
    // marginRight: "4px",
    paddingLeft: "20px",
  },
  pagination_select: {
    width: { xs: "60px", sm: "80px" },
    height: "36px",
    fontWeight: "500",
    color: theme.palette.black.darkSeaBlack,
    margin: "4px 20px ",
    textAlign: "center",
    borderColor: theme.palette.grey.lightWarmGrey,
    backgroundColor: theme.palette.grey.lightWarmGrey,
  },
};
const TablePagination = ({
  currentPage,
  lastPage,
  limit,
  limitOnChange,
  onClickNext,
  onClickPrevious,
}) => {
  return (
    <Box sx={styles.pagination_ul}>
      <AppButton
        look={currentPage === 1 ? "blackDisabled" : "black"}
        isDisabled={currentPage === 1}
        label="Prev"
        noIcon
        small
        onClick={onClickPrevious}
        addtionalStyle={styles.pagination_button}
      />
      <Box sx={styles.flex}>
        <Typography variant="body3" style={styles.pagination_span}>
          Rows per Page:
        </Typography>
        <select
          value={parseInt(limit)}
          style={styles.pagination_select}
          onChange={limitOnChange}
        >
          {limits?.map((lmt, i) => {
            return <option key={i}>{lmt}</option>;
          })}
        </select>
      </Box>
      <AppButton
        look={lastPage === currentPage ? "blackDisabled" : "black"}
        label="Next"
        noIcon
        small
        isDisabled={lastPage === currentPage}
        onClick={onClickNext}
        addtionalStyle={styles.pagination_button}
      />
    </Box>
  );
};
TablePagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  limit: PropTypes.number,
  limitOnChange: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
};
TablePagination.defaultProps = {
  limit: 10,
};
export default TablePagination;
