import { call, fork, put, take } from "redux-saga/effects";
import * as actions from "../actions/projectdetail-action";
import { TYPES } from "../types";
import firebase from "src/firebase";
import { getSingleProject, getBarChartData } from "src/services/project";

function* fetchProjectDetails({ projectId }) {
  try {
    const result = yield call(getSingleProject, firebase, projectId);
    const barData = yield call(
      getBarChartData,
      firebase,
      projectId,
      "completed"
    );
    let ResultData = { ...result, barData: barData };
    yield put(
      actions.getProjectDetailSuccess({
        data: ResultData,
      })
    );
  } catch (e) {
    console.log("Error", e);
    yield put(
      actions.getProjectDetailError({
        error: "An error occured while trying to get project Details",
      })
    );
  }
}

function* watchFetchProject() {
  while (true) {
    const action = yield take(TYPES.GET_PROJECT_DETAIL);
    yield call(fetchProjectDetails, {
      projectId: action.payload.projectId,
    });
  }
}

const userSaga = [fork(watchFetchProject)];

export default userSaga;
