import {
  getPilotsCountFromCountry,
  inviteAllPilotsOfCountry,
} from "../../services/missions";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import theme from "../../theme";

import React, { useEffect, useState } from "react";
import { shadowBox } from "../../lib/styleConstants";
import AppButton from "../global/elements/AppButton";

const styles = {
  textLabel: {
    color: theme.palette.black.coolGrey,
    fontSize: "16px",
    fontWeight: "500",
    padding: "0 20px 10px 20px",
  },
  header: {
    color: theme.palette.black.coolGrey,
    padding: "15px 20px 15px 20px",
  },
  weitht5: {
    fontWeight: "500",
  },
  contentBox: {
    color: theme.palette.black.coolGrey,
    padding: "10px 20px 20px 20px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: { xs: "column-reverse", sm: "row", rowGap: "1em" },
    margin: { xs: "0 0 1em 0", sm: "0 1em 1em 0" },
  },
};
export default function AlertDialog(props) {
  const [PilotCount, setPliotCount] = useState(0);
  useEffect(() => {
    async function fetchData() {
      let count = await getPilotsCountFromCountry(props.Country);
      if (count) {
        setPliotCount(count);
        return count;
      }
    }
    fetchData();
  }, []);

  async function fetchData() {
    let res = await inviteAllPilotsOfCountry(
      props.mission,
      props.missionID,
      props.Country
    );

    if (res) {
      props.handlePopupClose();

      props.refreshMission();
      return res;
    }
  }

  return (
    <Box>
      <Dialog
        open={props.InvitePopupOpen}
        onClose={props.handlePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={styles.header}>
          <Typography
            variant="body1"
            sx={styles.weitht5}
          >{`Invite all Pilots from ${props.Country}`}</Typography>
        </DialogTitle>
        <Box sx={shadowBox}></Box>
        <DialogContentText id="alert-dialog-description" sx={styles.contentBox}>
          <Typography variant="body1" sx={styles.weitht5}>
            There are {PilotCount} pilots available in {props.Country}. Are you
            sure to invite all of them?
          </Typography>
        </DialogContentText>
        <DialogActions sx={styles.buttonContainer}>
          <AppButton
            look="inverted"
            noIcon
            onClick={props.handlePopupClose}
            label="Cancel"
          />
          <AppButton
            onClick={() => fetchData()}
            marginLeft
            isDisabled={PilotCount > 0 ? false : true}
            look={PilotCount > 0 ? "black" : "blackDisabled"}
            label="Invite All"
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}

AlertDialog.propTypes = {
  Country: PropTypes.string,
  missionID: PropTypes.string,
  handlePopupClose: PropTypes.func,
  refreshMission: PropTypes.func,
  InvitePopupOpen: PropTypes.bool,
  mission: PropTypes.obj,
};
AlertDialog.defaultProps = {
  Country: undefined,
  missionID: undefined,
  handlePopupClose: undefined,
  refreshMission: undefined,
  InvitePopupOpen: false,
  mission: {},
};
