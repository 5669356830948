import { Search } from "@mui/icons-material";
import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid grey",
  },
  inputField: {
    margin: "0",
    border: "none",
    padding: "10px",
    minWidth: 300,
  },
};

const AutoComplete = ({ mapApi, map, addplace }) => {
  const searchInputRef = React.useRef(null);
  const [autoComplete, setAutoComplete] = React.useState(null);

  React.useEffect(() => {
    const options = {
      // restrict your search to a specific type of result
      // types: ['geocode', 'address', 'establishment', '(regions)', '(cities)'],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    const _autoComplete = new mapApi.places.Autocomplete(
      searchInputRef.current,
      options
    );
    setAutoComplete(_autoComplete);
    return () => {
      mapApi.event.clearInstanceListeners(searchInputRef.current);
    };
  }, []);

  React.useEffect(() => {
    if (autoComplete) {
      autoComplete.addListener("place_changed", onPlaceChanged);
      autoComplete.bindTo("bounds", map);
    }
  }, [autoComplete]);

  const onPlaceChanged = () => {
    const place = autoComplete.getPlace();

    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    addplace(place);
    searchInputRef.current.blur();
  };

  const clearSearchBox = () => {
    searchInputRef.current.value = "";
  };

  return (
    <Box sx={styles.root}>
      <Search />
      <input
        ref={searchInputRef}
        type="text"
        onFocus={clearSearchBox}
        placeholder="Enter a location"
        style={styles.inputField}
      />
    </Box>
  );
};

AutoComplete.propTypes = {
  mapApi: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  addplace: PropTypes.func.isRequired,
};

export default AutoComplete;
