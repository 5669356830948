import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";

const MenuBar = ({ menuOpener, menuOptions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {menuOpener}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuOptions.map((menu, i) => {
          return (
            <MenuItem key={i} onClick={() => menu.action("hi")}>
              {menu.label}
            </MenuItem>
          );
        })}

        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </Box>
  );
};
MenuBar.propTypes = {
  talkingTo: PropTypes.string.isRequired,
  menuOptions: PropTypes.array.isRequired,
  menuOpener: PropTypes.node.isRequired,
  messagingTo: PropTypes.string,
  currentPilot: PropTypes.object,
};

MenuBar.defaultProps = {
  messagingTo: null,
  currentPilot: null,
};

export default MenuBar;
