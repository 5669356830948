import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-mui";
import firebase from "src/firebase";
import PropTypes from "prop-types";
import React, { useState, useEffect, Fragment } from "react";
import Dropzone from "react-dropzone";
import addProfileImage from "src/assets/images/addProfileImage.png";
import ProfileImage from "src/components/global/ProfileImage";
import countries from "src/lib/countries";
import { preventEnterSubmit } from "src/lib/form-helpers";
import { uploadProfileImage } from "src/services/pilots";
import { Cancel } from "@mui/icons-material";
import PilotFormSocial from "./PilotFormSocial";
import * as Yup from "yup";
import {
  drones,
  sensorsCamerasList,
  mappingProcessing,
  referralSourceList,
  checkboxOptionsProfile,
  usaStatesList,
  socialProfileFieldsForPilots,
  indudstryList,
  skillsList,
  groundCaptureEquipment,
  baseStations,
} from "../../../lib/constants";
import FormErrors from "../../global/forms/FormErrors";
import DroneLicenseUpload from "../shared/DroneLicenseUpload";
import DroneCvUpload from "../shared/DroneCvUpload";
import theme from "../../../theme";
import AppButton from "../../global/elements/AppButton";
import {
  Box,
  FormControl,
  Autocomplete,
  Typography,
  Chip,
  Grid,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import { appHeaderFont, formInputField } from "../../../lib/styleConstants";
import { navigate } from "gatsby";
import { getUser } from "../../../services/auth";
import TooltipInfo from "../../global/elements/TooltipInfo";
import UploadButton from "../../global/elements/UploadButton";
import SwitchSelect from "../../global/elements/SwitchSelect";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import FieldTitle from "../../global/elements/FieldTitle";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const pilotSchema = {
  name: Yup.string().required("Name is required"),
  searchName: Yup.array(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  location: Yup.string().required("Location is required").nullable(),
  phone: Yup.string().required("Phone is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string()
    .nullable()
    .when("location", {
      is: "United States of America (the)",
      then: Yup.string()
        .required("State is required if you live in USA")
        .nullable(),
    }),
  education: Yup.string(),
  equipment: Yup.array().required("Drone is required").min(1),
  otherEquipmentType: Yup.string(),
  profileImageURL: Yup.string().nullable(),
  droneLicenseURL: Yup.string()
    .nullable()
    .when("permissionToFly", {
      is: true,
      then: Yup.string().required("Please upload drone license"),
    }),
  originalLicenseFile: Yup.string().nullable(),
  originalCvFile: Yup.string().nullable(),
  cvURL: Yup.string().nullable(),
  instagram: Yup.string().matches(
    /^[a-zA-Z0-9_.]+$/,
    "This field cannot contain white space and special character"
  ),
  twitter: Yup.string().matches(
    /^[a-zA-Z0-9_]+$/,
    "This field cannot contain white space and special character"
  ),
  linkedin: Yup.string().matches(
    /^[a-zA-Z0-9-_.]+$/,
    "This field cannot contain white space and special character"
  ),
  flightHours: Yup.number(),
  availableLocations: Yup.array(),
  dailyCharge: Yup.number().required("Your daily rate is required."),
  associatedPilotsNb: Yup.number()
    .required("Number of associated drone operators is required")
    .min(0),
  jobRadius: Yup.number().min(
    0,
    "Job Radius must be greater than or equal to 0"
  ),
  registeredBusiness: Yup.bool(),
  EASACertified2024: Yup.bool(),
  postalCode: Yup.string().nullable(),
  address: Yup.string().nullable(),
  company: Yup.string()
    .nullable()
    .when("registeredBusiness", {
      is: true,
      then: Yup.string().required("Please write the company name").nullable(),
    }),
  registrationNo: Yup.string()
    .nullable()
    .when("registeredBusiness", {
      is: true,
      then: Yup.string()
        .required("Registration number of company is required")
        .nullable(),
    }),
  industry: Yup.array().nullable(),
  skills: Yup.array().nullable(),
};

const acceptTermsSchema = {
  termsOfUse: Yup.bool()
    .required("The terms and conditions must be accepted")
    .oneOf([true], "The terms and conditions must be accepted"),
};

const passwordSchema = {
  password: Yup.string().min(6),
};

const signUpPasswordSchema = {
  password: Yup.string().min(6).required("Password is required"),
};

const styles = {
  root: {
    "&>.MuiFormControl-root, &>.MuiInputBase-root, &>.MuiAutocomplete-root": {
      width: "100%",
      marginBottom: "30px",
    },
  },
  checkbox: {
    width: "100%",
    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  formErrorsAlert: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  profileImage: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    margin: "auto",
    width: { xs: "100%" },
  },
  btnRemove: {
    position: "absolute",
    top: { xs: "180px", md: "180px" },
    right: { xs: "10%", sm: 0, xl: "10%" },
    background: "none",
    fontWeight: 500,
    fontSize: "25px",
    color: theme.palette.status.errorDark,
    boxShadow: "none",
    width: 0,
    "&:hover": {
      color: theme.palette.status.errorLight,
      background: "none",
    },
  },
  urlCheckbox: {
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    "& label": {
      marginRight: 0,
    },
  },
  loading: {
    "&:disabled": {
      background: `${theme.palette.primary.main} !important`,
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },
  about_you_section: {
    display: { xs: "block", md: "flex" },
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    padding: { xs: "20px 10px", md: "20px 30px" },
  },
  form_container: {
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    padding: { xs: "20px 10px", md: "20px 30px" },
    margin: "20px 0px",
  },
  inputField: {
    marginBottom: "10px",
    padding: "5px",
    margin: { xs: "20px 15px", sm: "15px" },
    width: { xs: "90%", sm: "50%" },
    border: "none",
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      color: theme.palette.grey.warmGrey,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.status.errorDark,
    },
    "& .MuiChip-root": {
      color: theme.palette.black.darkSeaBlack,
      background: theme.palette.grey.lightWarmGrey,
    },
  },
  inputFieldExpended: {
    width: "100%",
    marginBottom: "20px",
    border: "none",
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      color: theme.palette.grey.warmGrey,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.status.errorDark,
    },
  },
  button_container: {
    float: "right",
    display: { xs: "flex" },
    right: { xs: "2rem", md: "4rem" },
  },
  back_button_container: {
    width: "200px",
    float: "left",
  },
  footer_button_container: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-between",
  },
  addSocialSectionMain: {
    display: "flex",
    cursor: "pointer",
    marginLeft: "15px",
  },
  selectDivSocial: {
    marginLeft: "70px",
    padding: "5px 10px",
    width: "fit-content",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    cursor: "pointer",
  },
  droneLicenseErr: {
    color: theme.palette.status.errorDark,
    fontWeight: 400,
    fontSize: "0.75rem",
  },
  grid_container_cls: {
    "& .MuiGrid-item": {
      paddingLeft: 0,
      paddingTop: { xs: "20px", sm: 0 },
    },
  },
  input_cls: {
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      color: theme.palette.grey.warmGrey,
    },
    "& .MuiChip-root": {
      color: theme.palette.black.darkSeaBlack,
      background: theme.palette.grey.lightWarmGrey,
    },
  },
  deletePilot: {
    marginRight: { xs: "5px", md: "1rem" },
  },
  uploadImageButton: {
    marginTop: "20px",
  },
  termsAndConditions: {
    textDecoration: "none",
  },
  socialBox: {
    display: "flex",
    alignItems: "center",
  },
  cancelSoMeButton: {
    color: theme.palette.status.errorDark,
    marginLeft: "20px",
    "& svg": {
      width: "1.5em",
      height: "1.5em",
    },
  },
  inputFieldPhone: {
    "& .PhoneInputInput": {
      padding: "17px !important",
    },
    // padding: "20px !important",
  },
};

const renderChips = (value, getTagProps) =>
  Array.isArray(value)
    ? value.map((option, index) => (
        <Chip key={index} label={option} {...getTagProps({ index })} />
      ))
    : null; // Return null if value is not an array

const requiredField = (field) => {
  return (
    <Fragment>
      {field}
      <span style={{ color: "red" }}> **</span>
    </Fragment>
  );
};

const PilotForm = ({
  submitHandler,
  pilot,
  acceptTerms,
  showPassword,
  deletePilot,
  closeError,
  showingSubmitError,
  googleSignUp,
  emailSignUp,
  deletePilotLoading,
}) => {
  const [instagram, setInstagram] = useState(false);
  const [twitter, setTwitter] = useState(false);
  const [linkedin, setLinkedin] = useState(false);
  const [openSocialModal, setOpenSocialModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const currentUser = getUser("user");

  useEffect(() => {
    if (pilot?.instagram) {
      setInstagram(true);
    }
    if (pilot?.twitter) {
      setTwitter(true);
    }
    if (pilot?.linkedin) {
      setLinkedin(true);
    }
  }, [pilot?.instagram, pilot?.linkedin, pilot?.twitter]);

  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [originalLicenseName, setOrigLicenseName] = useState(null);
  const [originalCvName, setOriginalCvName] = useState(null);
  const validationSchema = Yup.object().shape({
    ...pilotSchema,
    ...(acceptTerms && acceptTermsSchema),
    ...(showPassword && passwordSchema),
    ...(emailSignUp && signUpPasswordSchema),
  });

  const checkFreeSolo = (values) => {
    const freeSoloFields = [
      "skills",
      "industry",
      "mappingProcessing",
      "sensorsCameras",
      "referralSource",
    ];

    for (let i = 0; i < freeSoloFields.length; i++) {
      if (
        values &&
        values[freeSoloFields[i]] &&
        typeof values[freeSoloFields[i]] == "string"
      ) {
        values[freeSoloFields[i]] = [values[freeSoloFields[i]]];
      }
    }
    return values;
  };

  const setFreeSolo = (pilot) => {
    const freeSoloFields = [
      "skills",
      "industry",
      "mappingProcessing",
      "sensorsCameras",
      "referralSource",
      "equipment",
    ];

    let data = {};
    for (let i = 0; i < freeSoloFields.length; i++) {
      if (pilot && pilot[freeSoloFields[i]]) {
        if (typeof pilot[freeSoloFields[i]] == "string") {
          data[freeSoloFields[i]] = [pilot[freeSoloFields[i]]];
        } else {
          data[freeSoloFields[i]] = pilot[freeSoloFields[i]];
        }
      } else {
        data[freeSoloFields[i]] = [];
      }
    }
    return data;
  };

  const formInitialValues = {
    name: pilot?.name || "",
    email: pilot?.email || "",
    phone: pilot?.phone || "",
    password: "",
    searchName: pilot?.searchName || [],
    location: pilot?.location || "",
    city: pilot?.city || "",
    education: pilot?.education || "",
    state: pilot?.state || "",
    equipment: pilot?.equipment || [],
    otherEquipmentType: pilot?.otherEquipmentType || "",
    sensorsCameras: pilot?.sensorsCameras || [],
    associatedPilotsNb: pilot?.associatedPilotsNb || "",
    megapixels: pilot?.megapixels || [],
    mappingProcessing: pilot?.mappingProcessing || [],
    flightHours: pilot?.flightHours || "",
    preferredPaymentMethods: pilot?.preferredPaymentMethods || "",
    availableLocations: pilot?.availableLocations || [],
    insurance: pilot?.insurance || false,
    registeredBusiness: pilot?.registeredBusiness || false,
    EASACertified2024: pilot?.EASACertified2024 || false,
    permissionToFly: pilot?.permissionToFly || false,
    dailyCharge: pilot?.dailyCharge || "",
    ...(acceptTerms && { termsOfUse: pilot?.termsOfUse || false }),
    profileImageURL: pilot?.profileImageURL || "",
    droneLicenseURL: pilot?.droneLicenseURL || "",
    originalLicenseFile: pilot?.originalLicenseFile || "",
    instagram: pilot?.instagram || "",
    twitter: pilot?.twitter || "",
    linkedin: pilot?.linkedin || "",
    cvURL: pilot?.cvURL || "",
    originalCvFile: pilot?.originalCvFile || "",
    ambassador: pilot?.ambassador ? true : false,
    moreDetails: pilot?.moreDetails || "",
    referralSource: pilot?.referralSource || [],
    jobRadius: pilot?.jobRadius || "",
    postalCode: pilot?.postalCode || "",
    company: pilot?.company || "",
    address: pilot?.address || "",
    billingAddress: pilot?.billingAddress || "",
    registrationNo: pilot?.registrationNo || "",
    industry: pilot?.industry || [],
    skills: pilot?.skills || [],
    role: pilot?.role,
    lidarType: pilot?.lidarType || "",
    groundCaptureEquipment: pilot?.groundCaptureEquipment || [],
    baseStations:
      typeof pilot?.baseStations == "string"
        ? [pilot?.baseStations]
        : pilot?.baseStations || [],
    gcpExperience: pilot?.gcpExperience || false,
    ...setFreeSolo(pilot),
  };

  const onProfileImageDropHandler = (acceptedFiles, setFieldValue) => {
    setProfileImageLoading(true);
    if (acceptedFiles) {
      uploadProfileImage(firebase, acceptedFiles[0], pilot?.id)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((downloadURL) => {
          setFieldValue("profileImageURL", downloadURL);
        })
        .catch((err) => console.log(err))
        .finally(() => setProfileImageLoading(false));
    } else {
      console.log("incorrect image");
    }
  };

  const generateKeywords = (name) => {
    const wordArr = name?.toLowerCase().split(" ");
    const searchableKeywords = [];
    let prevKey = "";
    for (const word of wordArr) {
      const charArr = word?.toLowerCase().split("");
      for (const char of charArr) {
        const keyword = prevKey + char;
        searchableKeywords.push(keyword);
        prevKey = keyword;
      }
      prevKey = "";
    }
    return searchableKeywords;
  };
  const equipmentChangeHandler = (value, reason, details, setFieldValue) => {
    setFieldValue("equipment", value);
    if (reason === "remove-option") {
      switch (details.option) {
        case "Other":
          setFieldValue("otherEquipmentType", "");
      }
    }
  };
  const createField = (field) => {
    if (field == "Instagram") {
      setInstagram(true);
    }
    if (field == "Twitter") {
      setTwitter(true);
    }
    if (field == "LinkedIn") {
      setLinkedin(true);
    }
  };

  const goBack = () => {
    if (currentUser?.role == "admin") {
      if (currentUser?.userID == pilot?.id) {
        navigate(`/app/my-profile`);
      } else {
        navigate(`/app/pilots/${pilot.id}`);
      }
    } else {
      navigate(`/app/my-profile`);
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setBtnLoading(true);
    values = checkFreeSolo(values);
    submitHandler(values, setSubmitting, () => {
      setBtnLoading(false);
    });
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, values, setFieldValue, submitCount }) => {
        return (
          <Form
            onKeyDown={preventEnterSubmit}
            id="pilotForm"
            onClick={() => {
              showingSubmitError && closeError();
            }}
          >
            <Box sx={{ ...styles.root, ...styles.about_you_section }}>
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                  <Typography variant="h6" sx={appHeaderFont}>
                    About you
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Box sx={styles.profileImage}>
                    <ProfileImage
                      imageURL={values.profileImageURL || addProfileImage}
                      side="200px"
                      loading={profileImageLoading}
                    />
                    <Box>
                      <Dropzone
                        accept="image/jpeg, image/jpg, image/png"
                        onDrop={(acceptedFiles) =>
                          onProfileImageDropHandler(
                            acceptedFiles,
                            setFieldValue
                          )
                        }
                        maxSize={5000000}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Box sx={styles.uploadImageButton}>
                              <UploadButton
                                text="Upload my profile pic"
                                onClick={() =>
                                  setFieldValue("profileImageURL", null)
                                }
                              />
                            </Box>
                          </div>
                        )}
                      </Dropzone>
                    </Box>

                    <br />
                    {values.profileImageURL && (
                      <Tooltip title="Delete profile pic" placement="top-end">
                        <Button
                          sx={styles.btnRemove}
                          size="small"
                          onClick={() => setFieldValue("profileImageURL", null)}
                          flat
                        >
                          <Cancel />
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={6}
                  lg={8}
                  sx={styles.grid_container_cls}
                >
                  <Grid item xs={12}>
                    <Box sx={formInputField}>
                      <Field
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        variant="outlined"
                        name="name"
                        onChange={(e) => {
                          let searchNameData = generateKeywords(e.target.value);
                          setFieldValue("name", e.target.value);
                          setFieldValue("searchName", searchNameData);
                        }}
                        component={TextField}
                        label={requiredField("Name")}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={formInputField}>
                      <Field
                        type="email"
                        variant="outlined"
                        InputProps={{ disableUnderline: true }}
                        name="email"
                        component={TextField}
                        label={requiredField("Email")}
                        disabled={googleSignUp}
                      />
                    </Box>
                  </Grid>
                  {(showPassword || emailSignUp) && (
                    <Grid item xs={12}>
                      <Box sx={formInputField}>
                        <Field
                          type="password"
                          name="password"
                          component={TextField}
                          variant="outlined"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          label={"Update your password"}
                          autoComplete="new-password"
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box sx={formInputField}>
                      <FieldTitle
                        requiredFlag
                        title="Phone Number"
                        variant="body2"
                      />
                      <Box sx={styles.inputFieldPhone}>
                        {/* <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"phone"}
                          placeholder="Phone Number"
                          disabled={false}
                          type="text"
                          required
                        /> */}
                        <PhoneInput
                          placeholder="Phone number"
                          value={values.phone}
                          onChange={(e) => setFieldValue("phone", e)}
                          // style={styles.inputFieldPhone}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={styles.form_container}>
              <Typography variant="h6" sx={appHeaderFont}>
                Business & company
              </Typography>
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                {values["registeredBusiness"] === true && (
                  <>
                    <Grid item sm={6} xs={12}>
                      <Box sx={formInputField}>
                        <Field
                          type="text"
                          name="company"
                          component={TextField}
                          label="Company Name"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Box sx={formInputField}>
                        <Field
                          type="text"
                          name="registrationNo"
                          component={TextField}
                          label="Registration Number"
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                  </>
                )}
                <Grid item sm={6} xs={12}>
                  {checkboxOptionsProfile.map(({ label, name }) => {
                    return (
                      <FormControl key={name} sx={styles.checkbox}>
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name={name}
                          Label={{ label: label }}
                        />
                      </FormControl>
                    );
                  })}
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <DroneLicenseUpload
                      onUploaded={(url, origFileName) => {
                        setOrigLicenseName(origFileName);
                        setFieldValue("droneLicenseURL", url);
                        setFieldValue("originalLicenseFile", origFileName);
                      }}
                      onRemove={() => {
                        setOrigLicenseName(null);
                        setFieldValue("droneLicenseURL", "");
                        setFieldValue("originalLicenseFile", "");
                      }}
                      currentFileName={
                        originalLicenseName
                          ? originalLicenseName
                          : values.originalLicenseFile
                      }
                    />
                    {errors.droneLicenseURL && (
                      <Box component="span" sx={styles.droneLicenseErr}>
                        {errors.droneLicenseURL}
                      </Box>
                    )}
                  </Box>

                  <Box sx={formInputField}>
                    <DroneCvUpload
                      onUploaded={(url, origFileName) => {
                        setOriginalCvName(origFileName);
                        setFieldValue("cvURL", url);
                        setFieldValue("originalCvFile", origFileName);
                      }}
                      onRemove={() => {
                        setOriginalCvName(null);
                        setFieldValue("cvURL", "");
                        setFieldValue("originalCvFile", "");
                      }}
                      currentFileName={
                        originalCvName ? originalCvName : values.originalCvFile
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={styles.form_container}>
              <Typography
                variant="h6"
                sx={{
                  ...appHeaderFont,
                  ...{ display: "flex", alignItems: "center" },
                }}
              >
                Address & Location
                <TooltipInfo info="Filling your address details are important to allow the system to automatically match you in case there is a client order according to where you live and your travel radius. We will also use the details you fill here to invoice you upon payments." />
              </Typography>
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    options={countries}
                    onChange={(e, value) => setFieldValue("location", value)}
                    defaultValue={values.location}
                    renderTags={renderChips}
                    sx={formInputField}
                    variant="outlined"
                    renderInput={(params) => (
                      <Field
                        {...params}
                        type="text"
                        name="location"
                        component={TextField}
                        label={requiredField("Country")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      name="city"
                      component={TextField}
                      label={requiredField("City")}
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                {values["location"] === "United States of America (the)" && (
                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      options={usaStatesList}
                      onChange={(e, value) => setFieldValue("state", value)}
                      defaultValue={values.state}
                      renderTags={renderChips}
                      variant="outlined"
                      sx={formInputField}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="state"
                          component={TextField}
                          label="State"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box sx={formInputField}>
                    <Autocomplete
                      multiple
                      options={countries}
                      onChange={(e, value) => {
                        setFieldValue("availableLocations", value);
                      }}
                      defaultValue={values.availableLocations}
                      renderTags={renderChips}
                      sx={formInputField}
                      variant="outlined"
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="availableLocations"
                          component={TextField}
                          label="Available locations"
                          variant="outlined"
                          helperText={
                            "In case you live part-time in another country or can easily travel there, where can you also take missions?"
                          }
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      name="address"
                      component={TextField}
                      label={requiredField("Address")}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      name="postalCode"
                      component={TextField}
                      label={requiredField("Postal Code")}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      name="billingAddress"
                      component={TextField}
                      label={requiredField("Billing Address")}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="number"
                      name="jobRadius"
                      component={TextField}
                      label={requiredField(
                        "Travel distance from home base (in km)"
                      )}
                      variant="outlined"
                      min="0"
                      helperText={
                        "How far are you willing to travel away from your home for a paid mission?"
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={styles.form_container}>
              <Typography variant="h6" sx={appHeaderFont}>
                Experiences
              </Typography>

              <Grid
                container
                spacing={2}
                sx={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      name="education"
                      component={TextField}
                      label={requiredField("Education")}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Autocomplete
                      multiple
                      options={indudstryList}
                      freeSolo
                      defaultValue={values.industry}
                      sx={formInputField}
                      variant="outlined"
                      onChange={(e, value) => setFieldValue("industry", value)}
                      renderTags={renderChips}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="industry"
                          component={TextField}
                          label={requiredField(
                            "Which industries have you experience in?"
                          )}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={formInputField}>
                    <Autocomplete
                      multiple
                      options={skillsList}
                      freeSolo
                      defaultValue={values.skills}
                      sx={formInputField}
                      variant="outlined"
                      onChange={(e, value) => {
                        setFieldValue("skills", value);
                      }}
                      renderTags={renderChips}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="skills"
                          component={TextField}
                          label={requiredField("Do you have other skills?")}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Typography variant="h6" sx={appHeaderFont}>
                Equipment and Software
              </Typography>
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    multiple
                    options={drones}
                    defaultValue={values.equipment}
                    onChange={(e, value, reason, details) =>
                      equipmentChangeHandler(
                        value,
                        reason,
                        details,
                        setFieldValue
                      )
                    }
                    renderTags={renderChips}
                    sx={formInputField}
                    variant="outlined"
                    renderInput={(params) => (
                      <Field
                        {...params}
                        type="text"
                        name="equipment"
                        component={TextField}
                        label={requiredField("Drones you own")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {values.equipment?.includes("Other") && (
                  <Grid item sm={6} xs={12}>
                    <Box sx={formInputField}>
                      <Field
                        type="text"
                        name="otherEquipmentType"
                        component={TextField}
                        label="Other Drone Type"
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                )}
                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Autocomplete
                      multiple
                      options={mappingProcessing}
                      freeSolo
                      defaultValue={values.mappingProcessing}
                      sx={formInputField}
                      variant="outlined"
                      onChange={(e, value) =>
                        setFieldValue("mappingProcessing", value)
                      }
                      renderTags={renderChips}
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="mappingProcessing"
                          component={TextField}
                          label={requiredField("Mapping / Processing")}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    multiple
                    options={sensorsCamerasList}
                    freeSolo
                    defaultValue={values.sensorsCameras}
                    sx={formInputField}
                    onChange={(e, value) =>
                      setFieldValue("sensorsCameras", value)
                    }
                    renderTags={renderChips}
                    renderInput={(params) => (
                      <Field
                        {...params}
                        type="text"
                        name="sensorsCameras"
                        component={TextField}
                        label={requiredField("Sensors / Cameras")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                {values.sensorsCameras.includes("Laser (LIDAR)") && (
                  <Grid item sm={6} xs={12}>
                    <Box sx={formInputField}>
                      <Field
                        type="text"
                        name="lidarType"
                        component={TextField}
                        label={requiredField("Type of lidar you have")}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <Typography variant="body3" sx={{ fontWeight: 500 }}>
                    Do you have experience in working with ground control points
                    GCP&apos;s?
                  </Typography>
                  <SwitchSelect
                    checked={values.gcpExperience}
                    handleChange={(e, value) =>
                      setFieldValue("gcpExperience", value ? true : false)
                    }
                    textLeft="No"
                    textRight="Yes"
                    name="gcpExperience"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    multiple
                    options={groundCaptureEquipment}
                    // freeSolo
                    defaultValue={values.groundCaptureEquipment}
                    sx={formInputField}
                    onChange={(e, value) =>
                      setFieldValue("groundCaptureEquipment", value)
                    }
                    renderTags={renderChips}
                    renderInput={(params) => (
                      <Field
                        {...params}
                        type="text"
                        name="groundCaptureEquipment"
                        component={TextField}
                        label="Your ground capture equipments"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    multiple
                    options={baseStations}
                    // freeSolo
                    defaultValue={values.baseStations}
                    sx={formInputField}
                    onChange={(e, value) =>
                      setFieldValue("baseStations", value)
                    }
                    renderTags={renderChips}
                    renderInput={(params) => (
                      <Field
                        {...params}
                        type="text"
                        name="baseStations"
                        component={TextField}
                        label="Name the base stations type that you can use and have access to during a mission"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="number"
                      name="megapixels"
                      component={TextField}
                      label={requiredField("Camera's megapixels")}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="number"
                      name="flightHours"
                      component={TextField}
                      label={requiredField("Approximate total Flight Hours")}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={styles.form_container}>
              <Typography variant="h6" sx={appHeaderFont}>
                Community and social
              </Typography>
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item md={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="number"
                      name="associatedPilotsNb"
                      component={TextField}
                      label={requiredField("Associated pilots")}
                      variant="outlined"
                      helperText={
                        "How many drone operators are you connected to in your company, or in your network that can be engaged in missions if needed?"
                      }
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="number"
                      name="dailyCharge"
                      component={TextField}
                      label={requiredField("Daily charge (USD)")}
                      variant="outlined"
                      helperText={
                        "This can change depending on mission requirements of course and will not be used to price your services, but how much in average would you charge per day?"
                      }
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    multiple
                    options={referralSourceList}
                    freeSolo
                    defaultValue={values.referralSource}
                    variant="outlined"
                    onChange={(e, value) =>
                      setFieldValue("referralSource", value)
                    }
                    sx={formInputField}
                    renderTags={renderChips}
                    renderInput={(params) => (
                      <Field
                        {...params}
                        type="text"
                        name="referralSource"
                        component={TextField}
                        label={requiredField("How did you hear about GLOBHE")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="body3" sx={{ fontWeight: 500 }}>
                    Do you want to become a GLOBHE ambassador?
                  </Typography>
                  <SwitchSelect
                    textLeft="No"
                    textRight="Yes"
                    checked={values.ambassador}
                    handleChange={(e, value) =>
                      setFieldValue("ambassador", value)
                    }
                    name="ambassador"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      name="moreDetails"
                      component={TextField}
                      label="Is there anything else you want to share with us?"
                      variant="outlined"
                      multiline
                      rows={6}
                      helperText={
                        "For example if you work for a company, drones you have experience in flying, have access to more drones, or anything else"
                      }
                    />
                    <br />
                    <br />
                  </Box>
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                sx={{
                  ...appHeaderFont,
                  ...{ display: "flex", alignItems: "center" },
                }}
              >
                Social Media Profiles
                {/* <span style={{ color: "red" }}>**</span>
                    <TooltipInfo info="Adding 1 social media profile is enough to make your profile 100% filled. In some situations, we might need your social media to tag you in our posts (after taking your permission) or check some previous work that you did as part of your drone activities." /> */}
              </Typography>
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                {instagram && (
                  <Grid item xs={12}>
                    <Box sx={styles.socialBox}>
                      <PilotFormSocial name="instagram" />
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={styles.cancelSoMeButton}
                      >
                        <Cancel
                          fontSize="inherit"
                          onClick={() => {
                            setInstagram(false);
                            setFieldValue("instagram", "");
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
                {twitter && (
                  <Grid item xs={12}>
                    <Box sx={styles.socialBox}>
                      <PilotFormSocial name="twitter" />
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={styles.cancelSoMeButton}
                      >
                        <Cancel
                          fontSize="inherit"
                          onClick={() => {
                            setTwitter(false);
                            setFieldValue("twitter", "");
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
                {linkedin && (
                  <Grid item xs={12}>
                    <Box sx={styles.socialBox}>
                      <PilotFormSocial name="linkedin" />
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={styles.cancelSoMeButton}
                      >
                        <Cancel
                          fontSize="inherit"
                          onClick={() => {
                            setLinkedin(false);
                            setFieldValue("linkedin", "");
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box
                    onClick={() => setOpenSocialModal(!openSocialModal)}
                    sx={styles.addSocialSectionMain}
                  >
                    <AddCircleRoundedIcon color="secondary" />
                    <Typography>&nbsp;Add more links</Typography>
                  </Box>
                  {openSocialModal && (
                    <Box sx={styles.selectDivSocial}>
                      {socialProfileFieldsForPilots.map((socialProfile, i) => {
                        return (
                          <Typography
                            sx={{
                              padding: "3px",
                              color: theme.palette.grey.warmGrey,
                            }}
                            onClick={() => createField(socialProfile)}
                            key={i}
                            variant="body1"
                          >
                            {socialProfile}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
            {/* </>
            )} */}
            {acceptTerms && (
              <FormControl sx={styles.checkbox}>
                <Box sx={styles.urlCheckbox}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name={"termsOfUse"}
                    Label={{ label: "I agree to the" }}
                  />
                  <a
                    href="https://www.globhe.com/terms-and-conditions"
                    target="_blank"
                    style={styles.termsAndConditions}
                    rel="noreferrer"
                  >
                    &nbsp; terms & conditions
                  </a>
                </Box>
              </FormControl>
            )}

            <FormErrors
              className={styles.formErrorsAlert}
              show={submitCount > 0}
              errors={Object.values(errors)}
            />

            <Box sx={styles.footer_button_container}>
              {" "}
              <>
                <Box sx={styles.back_button_container}>
                  <AppButton
                    onClick={goBack}
                    look="inverted"
                    label="Cancel"
                    noIcon
                  />
                </Box>
              </>
              <Box
                sx={{
                  ...styles.button_container,
                  position: "absolute",
                }}
              >
                <Box sx={styles.deletePilot}>
                  <AppButton
                    onClick={deletePilot}
                    look="negative"
                    label="Delete profile"
                    submittingState={deletePilotLoading}
                  />
                </Box>
                <AppButton
                  look="green"
                  label="Save"
                  type="submit"
                  form="pilotForm"
                  submittingState={btnLoading}
                  marginLeft
                />
              </Box>
              <br />
              <br />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

PilotForm.defaultProps = {
  acceptTerms: false,
  showPassword: false,
  pilot: undefined,
  closeError: undefined,
  showingSubmitError: false,
  googleSignUp: false,
  emailSignUp: false,
};

PilotForm.propTypes = {
  pilot: PropTypes.object,
  submitHandler: PropTypes.func.isRequired,
  acceptTerms: PropTypes.bool,
  showPassword: PropTypes.bool,
  deletePilot: PropTypes.func.isRequired,
  closeError: PropTypes.func,
  showingSubmitError: PropTypes.bool,
  googleSignUp: PropTypes.bool,
  emailSignUp: PropTypes.bool,
  deletePilotLoading: PropTypes.bool.isRequired,
};

export default PilotForm;
