import React from "react";
import { projectStatus } from "../../../../lib/constants";
import ProjectTable from "./ProjectTable";

const ProjectsActive = () => {
  const status = projectStatus.active;
  return (
    <ProjectTable
      status={status}
      pageTitle={"Active"}
      tips="Here you find your active multi-location or recurrent orders. Drone operators are now busy capturing the data."
    />
  );
};

export default ProjectsActive;
