import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PackageLayout from "./PackageLayout";
import {
  PilotPackageStatus,
  packageTypeConstant,
  userRoles,
} from "../../../../lib/constants";
import SignInContract from "../shared/signInContract";
import EmptyMessage from "../shared/EmptyMessage";
import { getUser } from "../../../../services/auth";
import SubmitPackage from "./component/SubmitPackage";
import RejectModal from "./component/PopupComponents/RejectModal";
import SmallStatusBanner from "../../../global/elements/SmallStatusBanner";

const PilotPackagesTab = ({
  packageId,
  mission,
  contractRequired,
  contract,
  onAddToDelivery,
  onPackageSubmit,
  isEditable,
  mediaPackage,
  onPackageReject,
  onAddToLatestSubmission,
}) => {
  const [currentUser, setCurrentUser] = useState({});
  const isAdmin = currentUser.role === userRoles.admin;

  useEffect(() => {
    let user = getUser("user");
    setCurrentUser(user);
  }, []);

  return (
    <Box>
      {contractRequired && !mission.allowEmptyData && (
        <SignInContract contractId={contract?.id} />
      )}
      {mission.allowEmptyData ? (
        <EmptyMessage
          currentUser={currentUser}
          contract={contract}
          mission={mission}
        />
      ) : (
        <>
          {mediaPackage?.status === PilotPackageStatus.rejected && (
            <Box sx={{ marginBottom: "20px" }}>
              <SmallStatusBanner
                title="This package has been rejected"
                content={`But you can always submit again. The reason why this was rejected is because ${mediaPackage?.rejectedReason}. ${mediaPackage?.comment}`}
              />
            </Box>
          )}
          <PackageLayout
            packageId={packageId}
            mission={mission}
            packageType={packageTypeConstant.pilotPackage}
            contractRequired={contractRequired}
            isEditable={isEditable}
            mediaPackage={mediaPackage}
            onAddToDelivery={onAddToDelivery}
            onAddToLatestSubmission={onAddToLatestSubmission}
          />
          {isEditable && (
            <SubmitPackage
              isDisabled={contractRequired}
              onSubmit={onPackageSubmit}
              mapTypes={mission?.mapTypes}
              missionId={mission?.id}
              packageId={packageId}
            />
          )}
        </>
      )}
      {isAdmin &&
        !isEditable &&
        mediaPackage?.status === PilotPackageStatus?.pendingReview && (
          <RejectModal mission={mission} onPackageReject={onPackageReject} />
        )}
    </Box>
  );
};

PilotPackagesTab.propTypes = {
  packageId: PropTypes.string.isRequired,
  mission: PropTypes.object.isRequired,
  contract: PropTypes.object,
  contractRequired: PropTypes.bool.isRequired,
  mediaPackage: PropTypes.object.isRequired,
  onPackageSubmit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onAddToLatestSubmission: PropTypes.func,
  onAddToDelivery: PropTypes.func.isRequired,
  onPackageReject: PropTypes.func.isRequired,
};

PilotPackagesTab.defaultProps = {
  contract: {},
  onAddToLatestSubmission: undefined,
};

export default PilotPackagesTab;
