import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { advancedOptionLabel } from "../../../../lib/constants";
import { Link } from "gatsby";
import theme from "../../../../theme";

const ShowAdvancedOption = ({ advancedOption, variant }) => {
  let keys = Object.keys(advancedOption);

  return (
    <Box>
      <ul>
        {keys.map((key, i) =>
          advancedOption[key] ? (
            <li key={i}>
              {console.log(key)}
              <Typography variant={variant}>
                {advancedOptionLabel[key]} : &nbsp;
                {key !== "flightSpecificationPdfUrl" ? (
                  advancedOption[key]
                ) : (
                  <Link target="_blank" to={`${advancedOption[key]}`}>
                    <Typography
                      sx={{ color: theme.palette.adminBlue.main }}
                      variant="body3"
                    >
                      Uploaded document
                    </Typography>
                  </Link>
                )}
              </Typography>
            </li>
          ) : null
        )}
      </ul>
    </Box>
  );
};

ShowAdvancedOption.propTypes = {
  advancedOption: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

ShowAdvancedOption.defaultProps = {
  variant: "body3",
};

export default ShowAdvancedOption;
