import { Box, Backdrop, Typography, Stack } from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  doneCls: {
    color: theme.palette.grey.white,
    fontSize: "32px",
  },
};

const TransparentBlackSavedScreen = ({ open, textToShow }) => {
  return (
    <Box>
      <Backdrop open={open}>
        <Stack gap={1} justifyContent="center" alignItems="center">
          <DoneIcon sx={styles.doneCls} />
          <Typography variant="h4" sx={styles.doneCls}>
            {textToShow}
          </Typography>
        </Stack>
      </Backdrop>
    </Box>
  );
};

TransparentBlackSavedScreen.propTypes = {
  open: PropTypes.bool.isRequired,
  textToShow: PropTypes.string.isRequired,
};

export default TransparentBlackSavedScreen;
