import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Grid } from "@mui/material";
import AppButton from "../../global/elements/AppButton";
import theme from "../../../theme";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  flexColumn,
  flexSpace,
  boxShadowStyle,
} from "../../../lib/styleConstants";
import {
  InvoiceText,
  missionStatus,
  userRoles,
  projectStatus,
} from "../../../lib/constants";
import {
  getAcceptedQuote,
  getInvoiceByDeliveryPackageId,
} from "../../../services/missions";
import firebase from "src/firebase";
import InvoiceDetails from "./InvoiceDetails";
import PropTypes from "prop-types";
import { getInvoiceUsingMissionId } from "../../../services/invoices";
import { getQuoteTotalCost, getCurrencySymbol } from "../../../lib/helpers";
import AdminInvoiceSplitSelection from "./AdminInvoiceSplitSelection";
import {
  getBreakInvoiceStatus,
  getProjectBreakInvoiceStatus,
} from "../../../services/invoices";
import { getProjectInvoice } from "../../../services/project";

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1em",
  },
  container: {
    display: "flex",
    columnGap: "20px",
    flexDirection: { xs: "column", md: "row" },
    rowGap: "1em",
  },
  termsAndCondition: {
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
  },
  InvoiceDetailBox: {
    padding: "1.4em",
    rowGap: "0.6em",
  },
  uploadInvoiceHeaderInnerImg: {
    maxWidth: "40px",
    maxHeight: "40px",
    marginRight: "10px",
  },
  paymentStatus: {
    padding: "0.3em 1em",
    background: theme.palette.status.errorLight,
  },
  lavenderText: { color: theme.palette.secondary.lavender },
  singleInvoiceContainer: {},
  checkInvoiceButton: {
    whiteSpace: "nowrap",
    width: "120px",
    margin: "0 10px 10px 0",
  },
  priceContainer: {
    padding: "1em 0.4em 0.4em 2em",
    rowGap: "10px",
    height: "100%",
  },
  grid: {
    display: "flex",
    justifyContent: "space-between",
  },
  gridItem: {
    marginBottom: { xs: "20px", md: 0 },
  },
};

const PriceBox = ({ text, value, type, pilotInvoiceid, currency }) => {
  return (
    <Box
      sx={{
        ...flexColumn,
        background:
          type === userRoles.client
            ? theme.palette.black.darkSeaBlack
            : theme.palette.primary.palePistachio,
        ...styles.priceContainer,
      }}
    >
      <Typography variant="h3" sx={styles.lavenderText}>
        {text}
      </Typography>
      <Box sx={{ ...flexSpace }}>
        <Typography variant="h5" sx={{ ...styles.lavenderText }}>
          {getCurrencySymbol(currency)} {value}
        </Typography>
        {type === "operator" && (
          <AppButton
            label={"Check invoice"}
            look={"secondary"}
            endIcon={<ArrowRightAltIcon />}
            addtionalStyle={styles.checkInvoiceButton}
            externalLink
            url={`/app/admin/dashboard/invoices/${pilotInvoiceid}`}
          />
        )}
      </Box>
    </Box>
  );
};
PriceBox.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  pilotInvoiceid: PropTypes.string,
  currency: PropTypes.string,
};
PriceBox.defaultProps = {
  text: undefined,
  value: undefined,
  type: undefined,
  pilotInvoiceid: "",
  currency: undefined,
};
const QuoteInvoiceSection = ({
  missionDetails,
  deliveryPackage,
  isAdmin,
  isClient,
  refreshMission,
  isProject,
}) => {
  const [clientInoviceUrl1, setClientInoviceUrl1] = useState();
  const [clientInoviceUrl2, setClientInoviceUrl2] = useState();
  const [clientInoviceUrlSingle, setClientInoviceUrlSingle] = useState();
  const [acceptedQuote, setAcceptedQuote] = useState();
  const [clientInvoiceReady, setClientInvoiceReady] = useState(false);
  const [pilotInvoiceid, setPilotInvoiceId] = useState();
  const [loading, setLoading] = useState(false);
  const [isSplitInvoice, setIsSplitInvoice] = useState(false);

  useEffect(() => {
    if (!firebase) return;
    if (isProject) {
      getProjectBreakInvoiceStatus(firebase, missionDetails.id).then(
        (status) => {
          setIsSplitInvoice(status);
        }
      );
    } else {
      getBreakInvoiceStatus(firebase, missionDetails.id).then((status) => {
        setIsSplitInvoice(status);
      });
    }
  }, [isProject, missionDetails.breakInvoice, missionDetails.id]);

  useEffect(() => {
    const getClientPrice = async () => {
      try {
        if (!isProject) {
          let quote = await getAcceptedQuote(firebase, missionDetails.id);
          if (quote) {
            setAcceptedQuote(quote.data);
          } else {
            setAcceptedQuote(0);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    getClientPrice();
  }, [missionDetails]); // Only re-run effect if missionDetails changes

  const getInvoice = useCallback(async () => {
    await getInvoiceUsingMissionId(missionDetails.id).then((response) => {
      if (response) {
        console.log("response", response);
        setPilotInvoiceId(response?.id);
      }
    });
  }, [missionDetails.id]); // Only re-create the function if missionDetails.id changes

  useEffect(() => {
    if (isAdmin && missionDetails.status === missionStatus.completed) {
      getInvoice();
    }
  }, [getInvoice, isAdmin, missionDetails.status]); // Only re-run effect if isAdmin or missionDetails.status changes

  useEffect(() => {
    setLoading(true);
    // clientInvoiceHandle();
  }, [clientInoviceUrl1, clientInoviceUrl2]);

  useEffect(() => {
    const clientInvoiceHandle = async () => {
      let invoice;
      if (isProject) {
        invoice = await getProjectInvoice(firebase, missionDetails.id);
      } else {
        invoice = await getInvoiceByDeliveryPackageId(
          firebase,
          missionDetails.id,
          deliveryPackage
        );
      }
      invoice.map((doc) => {
        if (doc?.invoiceType === InvoiceText.firstInvoice) {
          setClientInoviceUrl1(doc?.url);
          setClientInvoiceReady(true);
        } else if (doc?.invoiceType === InvoiceText.secondInvoice) {
          setClientInoviceUrl2(doc?.url);
          setClientInvoiceReady(true);
        } else {
          setClientInoviceUrlSingle(doc?.url);
          setClientInvoiceReady(true);
        }
      });

      setLoading(false);
    };
    clientInvoiceHandle();
  }, [deliveryPackage, isProject, missionDetails.id]);

  const isDisabledMission = [
    missionStatus.initialClientOrder,
    missionStatus.confirmedMission,
    missionStatus.unassigned,
  ].includes(missionDetails.status);

  const isDisabledProject = [
    projectStatus.initial,
    projectStatus.active,
  ].includes(missionDetails.status);

  return (
    <>
      {isAdmin &&
        missionDetails.status !== missionStatus.completed &&
        missionDetails.status !== projectStatus.completed && (
          <AdminInvoiceSplitSelection
            missionID={missionDetails.id}
            refreshMission={refreshMission}
            isProject={isProject}
          />
        )}
      <Box sx={styles.wrapper}>
        {isSplitInvoice ? (
          <Grid container columns={20} sx={styles.grid}>
            <Grid
              item
              xs={20}
              md={9.7}
              sx={{ ...styles.gridItem, ...boxShadowStyle }}
            >
              <InvoiceDetails
                headText="Pre-delivery invoice"
                bodyText={"50% of order price paid upfront"}
                mission={missionDetails}
                invoiceType={InvoiceText.firstInvoice}
                isDisabled={
                  missionDetails.status === missionStatus.initialClientOrder ||
                  missionDetails.status === projectStatus.initial
                }
                clientInoviceUrl={clientInoviceUrl1}
                isAdmin={isAdmin}
                isClient={isClient}
                refreshMission={refreshMission}
                clientInvoiceReady={clientInvoiceReady}
                isProject={isProject}
              />
            </Grid>
            <Grid
              item
              xs={20}
              md={9.7}
              sx={{ ...styles.gridItem, ...boxShadowStyle }}
            >
              <InvoiceDetails
                headText="Post-delivery invoice"
                bodyText="50% of the order price paid after delivery"
                mission={missionDetails}
                invoiceType={InvoiceText.secondInvoice}
                clientInoviceUrl={clientInoviceUrl2}
                isDisabled={
                  isDisabledMission || isDisabledProject
                  // ||
                  // !clientInoviceUrl2?.length
                }
                isAdmin={isAdmin}
                isClient={isClient}
                refreshMission={refreshMission}
                clientInvoiceReady={clientInvoiceReady}
                isProject={isProject}
              />
            </Grid>
          </Grid>
        ) : (
          <Box sx={styles.singleInvoiceContainer}>
            <InvoiceDetails
              headText={
                isAdmin
                  ? "Upload client’s invoice"
                  : clientInvoiceReady
                  ? "Download Invoice"
                  : "Your invoice is getting prepared"
              }
              bodyText="Invoice should be paid in full after delivery"
              mission={missionDetails}
              invoiceType={InvoiceText.singleInvoice}
              isDisabled={
                missionDetails.status === missionStatus.initialClientOrder ||
                missionDetails.status === missionStatus.confirmedMission ||
                missionDetails.status === projectStatus.initial ||
                missionDetails.status === projectStatus.active
              }
              clientInoviceUrl={clientInoviceUrlSingle}
              isAdmin={isAdmin}
              isClient={isClient}
              refreshMission={refreshMission}
              clientInvoiceReady={clientInvoiceReady}
              isProject={isProject}
            />
          </Box>
        )}
        {/* price */}
        {isAdmin &&
          (missionDetails.status === missionStatus.completed ||
            missionDetails.status === projectStatus.completed) && (
            <Box sx={styles.container}>
              <Box sx={{ flex: 1, ...flexColumn, rowGap: "1em" }}>
                <PriceBox
                  text={"Client price"}
                  value={
                    acceptedQuote && acceptedQuote?.id
                      ? getQuoteTotalCost(acceptedQuote)
                      : 0
                  }
                  type={"client"}
                  currency={acceptedQuote?.currency}
                />
              </Box>
              <Box sx={{ flex: 1, ...flexColumn, rowGap: "1em" }}>
                <PriceBox
                  text={"Operator price"}
                  value={missionDetails.pilotPrice}
                  type={"operator"}
                  pilotInvoiceid={pilotInvoiceid}
                />
              </Box>
            </Box>
          )}
      </Box>
    </>
  );
};

QuoteInvoiceSection.propTypes = {
  missionDetails: PropTypes.object,
  deliveryPackage: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  refreshMission: PropTypes.func,
  isProject: PropTypes.bool,
};
QuoteInvoiceSection.defaultProps = {
  missionDetails: undefined,
  deliveryPackage: undefined,
  refreshMission: undefined,
  isProject: false,
};
export default QuoteInvoiceSection;
