import React from "react";
import { scaleLinear } from "d3-scale";
import PropTypes from "prop-types";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { geoNaturalEarth1 } from "d3-geo";
import theme from "../../theme";
import geoURLLocal from "./feature.json";
import { Box } from "@mui/material";

const geoUrl = geoURLLocal;
// "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const styles = {
  svgMap: {
    width: "100%",
    height: "100%",
    background: theme.palette.black.darkSeaBlack,
    boxSizing: { xs: "border-box", sm: "none" },
    display: "flex",
    justifyContent: "center",
  },
};

const PilotAvailabilityMap = ({
  setTooltipContent,
  showPilotsCount,
  activateTooltip,
  colorDomain,
  data,
}) => {
  const colorScale = scaleLinear()
    .domain(colorDomain)
    .range(["#c8f7ea", theme.palette.primary.main]);

  return (
    <Box sx={styles.svgMap}>
      <ComposableMap
        data-tip=""
        projection={geoNaturalEarth1()}
        style={styles.svgMap}
        width={1000}
        height={450}
      >
        <defs>
          <filter id="glow" height="200%" width="200%" x="-50%" y="-50%">
            <feGaussianBlur stdDeviation="4" result="coloredBlur" />
            <feFlood
              floodColor="rgb(0,196,137)"
              result="glowColor"
              floodOpacity="0.5"
            />
            <feComposite
              in="glowColor"
              in2="coloredBlur"
              operator="in"
              result="softGlow_colored"
            />
            {
              <feMerge>
                <feMergeNode in="SourceGraphic" />
                <feMergeNode in="softGlow_colored" />
              </feMerge>
            }
          </filter>
        </defs>
        {data.length > 0 && (
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const d = data.find((s) => s.country === geo.properties.name);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={d ? colorScale(d["pilots"]) : "#F5F4F6"}
                    onMouseEnter={() => {
                      const { name } = geo.properties;
                      {
                        showPilotsCount &&
                          activateTooltip &&
                          setTooltipContent(
                            `${name} — ${d ? d.pilots : 0} pilots`
                          );
                      }
                      {
                        !showPilotsCount &&
                          activateTooltip &&
                          setTooltipContent(`${name}`);
                      }
                      {
                        !activateTooltip && setTooltipContent("");
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    style={{
                      //visibility: this.state.driverDetails.firstName != undefined? 'visible': 'hidden',

                      hover: {
                        fill: d
                          ? theme.palette.primary.main
                          : theme.palette.grey.deepWarmGrey,
                        outline: "none",
                      },
                      pressed: {
                        fill: d
                          ? theme.palette.secondary.main
                          : theme.palette.grey.warmGrey,
                        outline: "none",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        )}
      </ComposableMap>
    </Box>
  );
};

PilotAvailabilityMap.propTypes = {
  setTooltipContent: PropTypes.func,
  showPilotsCount: PropTypes.bool,
  activateTooltip: PropTypes.bool,
  colorDomain: PropTypes.array,
  data: PropTypes.array.isRequired,
};
PilotAvailabilityMap.defaultProps = {
  setTooltipContent: "",
  showPilotsCount: false,
  activateTooltip: true,
  colorDomain: [1, 200],
};
export default PilotAvailabilityMap;
