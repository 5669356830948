import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import AppButton from "../../../../global/elements/AppButton";
import MissionContext from "./MissionContext";
import {
  pilotUndoDeclineFeedback,
  pilotUndoRejectMission,
} from "../../../../../services/missions";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import { createNotification } from "../../../../../services/notification";
import { notificationType } from "../../../../../lib/constants";
import theme from "../../../../../theme";

const styles = {
  inquiry: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
    textAlign: "left",
    "& p": {
      marginBottom: "1em",
      color: theme.palette.grey[600],
    },
    marginBottom: "40px",
  },
};

const UndoPilotRejection = () => {
  const data = useContext(MissionContext);
  const [undone, setUndone] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleUndoRejectInquiry = () => {
    setUndone(true);
    pilotUndoRejectMission(firebase, data?.currentUser?.id, data?.mission)
      .then(() => {
        pilotUndoDeclineFeedback(
          firebase,
          data?.currentUser?.id,
          data?.mission?.id
        )
          .then(() => {
            createNotification(
              firebase,
              notificationType.adminNotification,
              `An operator has accepted a mission after rejecting it.`,
              `An operator has accepted a mission after rejecting it.`,
              `/app/missions/${data?.mission?.id}`
            );
            setUndone(false);
            data?.refreshMission();
            enqueueSnackbar("You have undone your mission rejection", {
              variant: "success",
            });
          })
          .catch((e) => {
            console.log("Error", e);
            setUndone(false);
            enqueueSnackbar("Error in processing ", e);
          });
      })
      .catch((e) => {
        setUndone(false);
        console.log("Error", e);
        enqueueSnackbar("Error in processing ", e);
      });
  };
  return (
    <>
      <Box sx={styles.inquiry}>
        <Typography variant="h4" sx={styles.actionTitle}>
          Mission inquiry rejected
        </Typography>
        <Typography variant="body1">
          You have rejected this mission inquiry.
        </Typography>
        <Box marginTop={4}>
          <AppButton
            label="Undo Rejection"
            look="purple"
            onClick={() => handleUndoRejectInquiry()}
            submittingState={undone}
          />
        </Box>
      </Box>
    </>
  );
};

export default UndoPilotRejection;
