import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import GridContainer from "src/components/global/GridContainer";
import useGetApiKey from "../../../../../hooks/useGetApiKey";
import { apiKeyStatus, colorStatus } from "../../../../../lib/constants";
import { DateTime } from "luxon";
import { updateApiKeyStatus } from "../../../../../services/apiKeys";
import firebase from "src/firebase";
import ErrorMessage from "src/components/global/errorMessage";
import { getApiKeyStatus } from "../../../../../services/apiKeys";
import theme from "../../../../../theme";
import Layout from "../../../../../Layout";
import { Box, Typography } from "@mui/material";
import AppButton from "../../../../global/elements/AppButton";
import LabelDataSet from "../../../../global/elements/LabelDataSet";
import StatusBadge from "../../../../global/elements/StatusBadge";

const styles = {
  "@global body": {
    background: theme.palette.grey.lightWarmGrey,
    "& .MuiContainer-root": {
      paddingLeft: { xs: "0px", sm: "20px" },
      paddingRight: { xs: "0px", sm: "20px" },
    },
  },
  root: {
    padding: 0,
    "& h1": {
      fontSize: "1.5rem",
      textAlign: "center",
    },
  },
  pilotProfile: {
    marginBottom: "2rem",
    marginTop: { xs: "40px", lg: "3rem !important" },
  },
  infoActionContainer: {
    display: { xs: "block", lg: "flex" },
    marginTop: { xs: "0px", sm: "3rem" },
    width: "100%",
    padding: { xs: "0px", lg: "1rem" },
    justifyContent: { xs: "none", md: "space-between" },
  },
  singleContainer: {
    padding: "20px",
    width: { lg: "48%" },
    marginBottom: { xs: "2rem", md: "1rem" },
    backgroundColor: theme.palette.adminBlue.lightBlue,
  },
  content: {
    marginTop: "1rem",
  },
  editButton: {
    marginTop: { xs: "1rem", lg: "2rem" },
    float: "right",
  },
  ApiKeyStatus: {
    marginLeft: { sm: "-13px" },
    marginTop: { xs: "-37px", md: "-19px", lg: "0px" },
    direction: { xs: "rtl", sm: "ltr" },
  },
};

const SingleApiKey = ({ apiKeyId }) => {
  const [apiKey, loading] = useGetApiKey(apiKeyId);
  const [hasError, showErrorMessage] = useState(false);
  const [errorMessageLabel, setErrorMessageLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [keyStatus, setKeyStatus] = useState("");

  useEffect(() => {
    if (!firebase) return;
    getApiKeyStatus(firebase, apiKeyId).then((apiKey) => {
      setKeyStatus(apiKey);
    });
  }, [apiKeyId]);

  const grantApiAccess = () => {
    if (
      !window.confirm(
        "Are you sure you want to update the status of this API Key?"
      )
    ) {
      return;
    }
    updateApiKeyStatus(firebase, apiKey.documentId, apiKeyStatus.granted)
      .then(() => {
        setKeyStatus(apiKeyStatus.granted);
      })
      .catch(() => {
        showErrorMessage(true);
        setErrorMessageLabel("This API Key does not exist");
        setErrorMessage("Make sure that the API you are changing exists");
      });
  };
  const revokeApiAccess = () => {
    if (
      !window.confirm(
        "Are you sure you want to update the status of this API Key?"
      )
    ) {
      return;
    }
    updateApiKeyStatus(firebase, apiKey.documentId, apiKeyStatus.revoked)
      .then(() => {
        setKeyStatus(apiKeyStatus.revoked);
      })
      .catch(() => {
        showErrorMessage(true);
        setErrorMessageLabel("This API Key does not exist");
        setErrorMessage("Make sure that the API you are changing exists");
      });
  };

  const closeTriggered = () => {
    setTimeout(function () {
      showErrorMessage(false);
    }, 300);
  };

  return (
    <Layout
      loading={loading}
      hasSideMenu
      backButton
      backButtonPageName="API Key"
    >
      {!loading && (
        <>
          <Box sx={styles.ApiKeyStatus}>
            {keyStatus == apiKeyStatus.granted ? (
              <StatusBadge
                name={apiKeyStatus.granted}
                status={colorStatus.green}
              />
            ) : keyStatus == apiKeyStatus.requested ? (
              <StatusBadge
                name={apiKeyStatus.requested}
                status={colorStatus.yellow}
              />
            ) : keyStatus == apiKeyStatus.revoked ? (
              <StatusBadge
                name={apiKeyStatus.revoked}
                status={colorStatus.red}
              />
            ) : (
              ""
            )}
          </Box>

          <Box sx={styles.root}>
            <GridContainer styles={styles.pilotProfile} gutters>
              <Box sx={styles.infoActionContainer}>
                <Box sx={styles.singleContainer}>
                  <Typography variant="h4">Client info</Typography>
                  <Box sx={{ display: "flex" }}>
                    <LabelDataSet
                      heading="Company"
                      value={apiKey.company || "n/a"}
                    />
                    <LabelDataSet
                      heading="Email"
                      value={apiKey.email || "n/a"}
                    />
                    <LabelDataSet
                      heading="Date Last Used"
                      value={
                        DateTime.fromISO(apiKey.dateLastUsed).toISODate() ||
                        "n/a"
                      }
                    />
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <LabelDataSet
                      heading="Telephone"
                      value={apiKey.phone || "n/a"}
                    />
                    <LabelDataSet
                      heading="Date Created"
                      value={
                        DateTime.fromISO(apiKey.dateCreated).toISODate() ||
                        "n/a"
                      }
                    />
                    <LabelDataSet
                      heading="Date Updated"
                      value={
                        DateTime.fromISO(apiKey.dateUpdated).toISODate() ||
                        "n/a"
                      }
                    />
                  </Box>
                </Box>

                <Box sx={styles.singleContainer}>
                  <Typography variant="h4">Actions</Typography>
                  <Typography variant="body1" sx={styles.content}>
                    Granting an API key to an organisation might result in a
                    large amount of mission creation. Make sure that this
                    company has avalid subscription plan before granting access
                    with an API key. Talk to sales in case you do not know this
                    company.
                  </Typography>
                  {keyStatus === apiKeyStatus.granted ? (
                    <AppButton
                      onClick={() => revokeApiAccess()}
                      label="Revoke access"
                      look="negative"
                      addtionalStyle={styles.editButton}
                    />
                  ) : keyStatus === apiKeyStatus.requested ? (
                    <AppButton
                      onClick={() => grantApiAccess()}
                      label="Grant access"
                      look="blue"
                      addtionalStyle={styles.editButton}
                    />
                  ) : (
                    <></>
                  )}
                  <br />
                  <br />
                </Box>
                {hasError ? (
                  <ErrorMessage
                    show={true}
                    errorMessageLabel={errorMessageLabel}
                    errorMessage={errorMessage}
                    closeTriggered={closeTriggered}
                  />
                ) : null}
              </Box>
            </GridContainer>
          </Box>
        </>
      )}
    </Layout>
  );
};
SingleApiKey.propTypes = {
  apiKeyId: PropTypes.string,
};

SingleApiKey.defaultProps = {
  apiKeyId: undefined,
};

export default SingleApiKey;
