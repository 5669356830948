import React from "react";
import SingleContract from "./SingleContract";
import PropTypes from "prop-types";

const AdminSingleContract = ({ id }) => {
  return <SingleContract id={id} />;
};

AdminSingleContract.propTypes = {
  id: PropTypes.string,
};
AdminSingleContract.defaultProps = {
  id: undefined,
};
export default AdminSingleContract;
