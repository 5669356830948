import firebase from "src/firebase";
import { getUser } from "src/services/auth";
import { getPilot } from "src/services/pilots";
import { setUserData } from "./auth";
import { users } from "../lib/constants";
import { missionStatus } from "../lib/constants";

export const missionCountsUpdate = async () => {
  let userId = getUser("user").userID;
  let userData = getUser("userProfile");
  let inquiriesCount = await inquiresMissionCount(userId);
  let activeCount = await activeMissionCount(userId);
  let completedCount = await completedMissionCount(userId);

  if (
    userData?.unassignedMission != inquiriesCount ||
    userData?.activeMission != activeCount ||
    userData?.completedMission != completedCount
  ) {
    let data = {
      unassignedMission: inquiriesCount,
      activeMission: activeCount,
      completedMission: completedCount,
    };
    await firebase.firestore().collection("pilots").doc(userId).update(data);
    const updatePilotData = await getPilot(firebase, userId);
    setUserData(updatePilotData, users.userProfile);

    return {
      status: "Success",
      pilotData: updatePilotData,
    };
  } else {
    const updatePilotData = await getPilot(firebase, userId);
    setUserData(updatePilotData, users.userProfile);

    return {
      status: "Success",
      pilotData: updatePilotData,
    };
  }
};

export const inquiresMissionCount = async (userId) => {
  let allMissionsAvailable = await firebase
    .firestore()
    .collection("missions")
    .where("status", "in", [
      missionStatus.unassigned,
      missionStatus.initialClientOrder,
    ])
    .where("sentToPilots", "array-contains", userId)
    .get();
  let updatedCount = (await allMissionsAvailable.docs.length) || 0;
  return updatedCount;
};
export const activeMissionCount = async (userId) => {
  let allMissionsAvailable = await firebase
    .firestore()
    .collection("missions")
    .where("status", "==", missionStatus.active)
    .where("assignedPilot", "==", userId)
    .get();
  let updatedCount = (await allMissionsAvailable.docs.length) || 0;
  return updatedCount;
};

export const completedMissionCount = async (userId) => {
  let allMissionsAvailable = await firebase
    .firestore()
    .collection("missions")
    .where("status", "==", missionStatus.completed)
    .where("assignedPilot", "==", userId)
    .get();
  let updatedCount = (await allMissionsAvailable.docs.length) || 0;
  return updatedCount;
};

export const updateAllCount = (
  inquiriesCount,
  activeCount,
  completedCount,
  userId
) => {
  let data = {
    unassignedMission: inquiriesCount,
    activeMission: activeCount,
    completedMission: completedCount,
  };
  return firebase.firestore().collection("pilots").doc(userId).update(data);
};
