import React from "react";
import { Box } from "@mui/material";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  main: {
    display: "flex",
  },
  titleBox: {
    minWidth: "max-content",
    fontWeight: 450,
    maxWidth: "250px",
    marginRight: "30px",
  },
  lineBox: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey.lightWarmGrey}`,
    marginBottom: "8px",
  },
};

const LineHeading = ({ title }) => {
  return (
    <Box sx={styles.main}>
      <Box component="span" sx={styles.titleBox}>
        {title}
      </Box>
      <Box sx={styles.lineBox}></Box>
    </Box>
  );
};

LineHeading.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LineHeading;
