import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialogue from "../../../../global/Dialogue";
import { Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { contactSupportUrl, url } from "../../../../../lib/constants";
import AppButton from "../../../../global/elements/AppButton";
import { acceptTermsNdCondition } from "../../../../../services/client";
import { navigate } from "gatsby";

const styles = {
  popupCls: {
    marginTop: "65px",
    textAlign: "center",
  },
};

const AcceptTermsAndCondition = ({
  openPopup,
  setOpenPopup,
  onSubmit,
  clientId,
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const contactSales = () => {
    navigate(contactSupportUrl);
  };

  const onAcceptTerms = async () => {
    try {
      setLoading(true);
      const result = await acceptTermsNdCondition({ id: clientId });
      if (result.status == "Success") {
        onSubmit(result?.data);
      } else {
        enqueueSnackbar("Error in updating terms & condition.", {
          variant: "error",
        });
      }
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Error in accepting terms & condition.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const DialogueBody = () => {
    return (
      <Grid container sx={styles.popupCls}>
        <Grid item xs={12}>
          <Typography variant="h3">Welcome to Globhe</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Please find a link to our</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            component={"a"}
            href={url.termsAndConditions}
            target="_blank"
            rel="noreferrer"
          >
            Globhe terms and conditions
          </Typography>
        </Grid>
        <Grid container item xs={12} sx={{ marginTop: "30px" }}>
          <Grid item xs={6}>
            <AppButton
              look="inverted"
              label="Need help ?"
              noIcon
              onClick={contactSales}
            />
          </Grid>
          <Grid item xs={6}>
            <AppButton
              look="green"
              label="Accept Terms and Conditions"
              onClick={() => onAcceptTerms()}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Dialogue
      showDialogue={openPopup}
      setShowDialogue={setOpenPopup}
      DialogueHeadText={""}
      DialogueBody={<DialogueBody />}
      showCancelButton={true}
      cancelButtonText={"Cancel"}
      sumitButtonText={"Yes"}
      showSubmitLoader={loading}
      setShowSubmitLoader={setLoading}
      onSubmit={onSubmit}
      tableType={true}
      hideIcon={true}
    />
  );
};

AcceptTermsAndCondition.propTypes = {
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
};

AcceptTermsAndCondition.defaultProps = {
  openPopup: false,
  setOpenPopup: undefined,
};

export default AcceptTermsAndCondition;
