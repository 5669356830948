import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import PropTypes from "prop-types";
import countries from "src/lib/countries";
import {
  EUCountriesWithCommonVATrule,
  currencyList,
  indudstryList,
} from "../../../../lib/constants";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import AutoCompleteInput from "../../../global/FormInputComponents/AutoCompleteInput";
import theme from "../../../../theme";

const styles = {
  divider: {
    borderColor: theme.palette.grey.lightWarmGrey,
    margin: "10px 0px",
  },
};

const EditCompanyInfo = ({ values, setFieldValue }) => {
  return (
    <Box>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            values={values}
            name={"companyAddress"}
            label="Address"
            disabled={false}
            type="text"
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            values={values}
            name={"city"}
            label="City"
            disabled={false}
            type="text"
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            values={values}
            name={"postalCode"}
            label="Postal code"
            disabled={false}
            type="text"
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AutoCompleteInput
            options={countries}
            name="location"
            label={"Country"}
            required
            // additionalStyle={styles.autocompletStyle}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            values={values}
            name={"phone"}
            label="Phone Number"
            disabled={false}
            type="text"
            required={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldInput
            name="email"
            label="Email"
            type="email"
            setFieldValue={setFieldValue}
            required={true}
            values={values}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AutoCompleteInput
            options={indudstryList}
            name="industry"
            label={"Industry"}
            additionalStyle={styles.autocompletStyle}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AutoCompleteInput
            options={currencyList}
            name="currency"
            label={"Currency"}
            additionalStyle={styles.autocompletStyle}
          />
        </Grid>
      </Grid>

      <Divider variant="fullWidth" sx={styles.divider} />

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            values={values}
            name={"registrationNumber"}
            label="Registration number"
            disabled={false}
            type="text"
            required
          />
        </Grid>

        {EUCountriesWithCommonVATrule?.includes(values.location) && (
          <Grid item xs={6}>
            <TextFieldInput
              setFieldValue={setFieldValue}
              values={values}
              name={"vatNumber"}
              label="Vat Number"
              disabled={false}
              type="text"
              required
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

EditCompanyInfo.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default EditCompanyInfo;
