import React from "react";
import { getUser } from "../../../services/auth";
import { Box, Drawer } from "@mui/material";
import SideMenuDrawer from "./SideMenuDrawer";

const styles = {
  drawer: {
    width: { md: "280px" },
    flexShrink: { sm: 0 },
    zIndex: 1300,
  },
  drawerChild: {
    display: {
      xs: "block",
      md: "none",
    },
  },
  drawerBox: {
    display: { xs: "none", md: "block" },
  },
};

const SideMenu = () => {
  const currentUser = getUser("user");

  return (
    <Box sx={styles.drawer}>
      <nav aria-label="menu">
        <Box sx={styles.drawerBox}>
          <Drawer
            sx={{ "& .MuiDrawer-paper": styles.drawerPaper }}
            variant="permanent"
            open
          >
            <SideMenuDrawer currentUser={currentUser} />
          </Drawer>
        </Box>
      </nav>
    </Box>
  );
};

export default SideMenu;
