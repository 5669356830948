import {
  getCurrentDateTime,
  getRandomMissionName,
  getCurrentDateTimeMillis,
  changeMillisToDate,
  oneWeeksBeforeTodayMillis,
  generateMissionId,
  addDaystoTheDate,
  changeDateToMilli,
  addNWeekstoGivenDate,
  addNMonthstoGivenDate,
  addNYearstoGivenDate,
  getDateOfDayInWeek,
  getDateOfMonth,
  findEndDateOfCurrentMonth,
  yearDateSetter,
} from "../lib/helpers";
import {
  missionStatus,
  PilotPackageStatus,
  projectStatus,
  userRoles,
  missionTypeConstant,
  paymentStatus,
  contractWaiverLabels,
  defaultMissionRepresentative,
  quoteStatus,
  contractStatusLabel,
  OrderStatus,
  invoicePaymentStatus,
  expireQuoteDays,
  recurringIntervals,
  deadlineDayForIntervals,
  weekDayNumber,
  defaultCurrency,
  InvoiceText,
} from "../lib/constants";
import Papa from "papaparse";
import firebase from "src/firebase";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import kmlParser from "js-kml-parser";
import { generateKeywordsForSearch } from "../lib/helpers";
import {
  arrayUnion,
  arrayRemove,
  getDocs,
  collection,
} from "firebase/firestore";
import {
  assignPilotIdInMission,
  getMission,
  removePilotFiles,
} from "./missions";
import {
  contractDetailsUpdate,
  creactContractForProject,
  deleteProjectContract,
  getContractChecks,
  getPilotContractForProject,
  removeMissionFromContract,
} from "./contracts";
import { createNotification } from "./notification";
import { getPilotPackages, updatePilotPackage } from "./pilot-packages";
import { getUser } from "./auth";
const { DateTime } = require("luxon");

export const projectSchema = async (firebase, data) => {
  try {
    const db = firebase.firestore();
    const batch = db.batch();

    const missionIds = data.missionData?.map((mission) => mission.id) || [];
    const date = getCurrentDateTime();
    const deadline = data?.recurrence
      ? null
      : data?.deadline?.isLuxonDateTime
      ? DateTime?.fromISO(data?.deadline).toISODate()
      : DateTime?.fromJSDate(data?.deadline).toISODate();
    const startDate = data?.startDate?.isLuxonDateTime
      ? DateTime?.fromISO(data?.startDate).toISODate()
      : DateTime?.fromJSDate(data?.startDate).toISODate();

    const intervalEndDate = data?.intervalEndDate?.isLuxonDateTime
      ? DateTime?.fromISO(data?.intervalEndDate).toISODate()
      : DateTime?.fromJSDate(data?.intervalEndDate).toISODate();

    const projectObj = {
      projectName: data?.projectName,
      recurrence: data?.recurrence || false,
      projectSearchName: data?.projectSearchName || [],
      mapTypes: data?.mapTypes || [],
      status: data?.status || projectStatus.draft,
      country: data?.country || "",
      startDate: startDate || null,
      clientId: data?.clientId || null,
      clientEmail: data?.clientEmail || "",
      clientPhone: data?.clientPhone || null,
      clientName: data?.clientName || "",
      searchClientName: generateKeywordsForSearch(data?.clientName) || [],
      clientCompanyName: data?.clientCompanyName,
      clientPaymentStatus: data?.clientPaymentStatus || false,
      allMissionIds: missionIds || [],
      allAdminIds: data?.adminIds || [],
      projectCompletedDate: null,
      createdAt: date,
      lastUpdated: date,
      deadline: deadline,
      missionPrice: Number(data?.missionPrice) || 0,
      missionOwner: data.missionOwner || defaultMissionRepresentative?.email,
      missionOwnerId: data.missionOwnerId || defaultMissionRepresentative?.id,
      missionOwnerName:
        data.missionOwnerName || defaultMissionRepresentative?.name,
      allowEmptyData: data?.allowEmptyData,
      createdById: data?.createdById || null,
      createdByRole: data?.createdByRole || null,
      droneRequirements: data?.droneRequirements || "",
      otherRequirements: data?.otherRequirements || "",
      pilotInstructions: data?.pilotInstructions || "",
      description: data?.description || null,
      dataLibrary: {
        missionTitle: data?.dataLibMissionTitle || "",
        description: data?.dataLibDescription || "",
        sdgGoals: data?.dataLibSdgGoals || [],
        categories: data?.dataLibCategories || [],
      },
      recurrenceInfo: {
        everyCount: data?.everyCount || null,
        recursiveInterval: data?.recursiveInterval || null,
        intervalEndOn: data?.intervalEndOn || false,
        intervalEndAfter: data?.intervalEndAfter || false,
        intervalEndDate: intervalEndDate || null,
        intervalEndCount: data?.intervalEndCount || null,
        area: data?.area || null,
        locationMap: data?.locationMap || null,
        areaSize: data?.areaSize || null,
        kmlFile: data?.kmlFile || null,
        orderType: data?.orderType || null,
      },
      notShowInDataLibrary: data?.notShowInDataLibrary || false,
      freeToDownload: data?.freeToDownload || false,
      breakInvoice: data?.breakInvoice || false,
      paymentStatusClient: data.paymentStatusClient || paymentStatus.unpaid,
      preDeliverypaymentStatusClient:
        data.preDeliverypaymentStatusClient || paymentStatus.unpaid,
    };

    if (data?.pointOfContact) {
      projectObj.pointOfContact = data.pointOfContact;
    }

    if (data?.advancedOption) {
      projectObj.advancedOption = data.advancedOption;
    }
    const projectData = await firebase
      .firestore()
      .collection("projects")
      .add(data);

    if (missionIds.length > 0) {
      missionIds?.forEach(async (id) => {
        await batch.update(db.collection("missions").doc(id), {
          projectIds: projectData.id,
        });
      });

      await batch.commit();
    }

    return {
      status: "Success",
      data: { id: projectData.id, ...data },
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error?.message || "Error in creating the project",
    };
  }
};

const getMissionCountForRecurrsiveProject = (missionData) => {
  let numberOfMissionToCreate = 0;
  if (missionData?.recurrenceInfo?.intervalEndAfter) {
    numberOfMissionToCreate = parseInt(
      missionData?.recurrenceInfo?.intervalEndCount
    );
    return numberOfMissionToCreate;
  } else if (missionData?.recurrenceInfo?.intervalEndOn) {
    const endDate = missionData?.recurrenceInfo?.intervalEndDate;
    const startDate = missionData.startDate;

    const millDiff = changeDateToMilli(endDate) - changeDateToMilli(startDate);

    const oneDayInMillis = 86400000;
    let intervalInMillis = 0;
    if (
      missionData?.recurrenceInfo.recursiveInterval === recurringIntervals.day
    ) {
      intervalInMillis =
        oneDayInMillis * (Number(missionData?.recurrenceInfo?.everyCount) || 1);
    } else if (
      missionData?.recurrenceInfo?.recursiveInterval ===
      recurringIntervals.month
    ) {
      intervalInMillis =
        oneDayInMillis *
        30 *
        (Number(missionData?.recurrenceInfo?.everyCount) || 1);
    } else if (
      missionData?.recurrenceInfo?.recursiveInterval === recurringIntervals.week
    ) {
      intervalInMillis =
        oneDayInMillis *
        7 *
        (Number(missionData?.recurrenceInfo?.everyCount) || 1);
    } else if (
      missionData?.recurrenceInfo?.recursiveInterval === recurringIntervals.year
    ) {
      intervalInMillis =
        oneDayInMillis *
        365 *
        (Number(missionData?.recurrenceInfo?.everyCount) || 1);
    }
    numberOfMissionToCreate = parseFloat(millDiff / intervalInMillis);

    return missionData?.recurrenceInfo?.recursiveInterval ===
      recurringIntervals.month ||
      missionData?.recurrenceInfo?.recursiveInterval === recurringIntervals.year
      ? parseInt(numberOfMissionToCreate) + 1
      : parseInt(numberOfMissionToCreate);
  } else {
    return 0;
  }
};

const getStartDateForMission = (
  intervalCount,
  interval,
  startDate,
  loopCount,
  weekDay,
  intervalEndDate
) => {
  let startDateNew = startDate;
  let deadline = startDateNew;
  let skip = false;

  if (loopCount > 0) {
    if (interval === recurringIntervals.day) {
      startDateNew = addDaystoTheDate({
        theDate: startDateNew,
        days: intervalCount,
      });
      deadline = startDateNew;
    } else if (interval === recurringIntervals.week) {
      startDateNew = addNWeekstoGivenDate(startDate, intervalCount);
      deadline = addDaystoTheDate({
        theDate: startDateNew,
        days: deadlineDayForIntervals.week,
      });
    } else if (interval === recurringIntervals.month) {
      startDateNew = addNMonthstoGivenDate(startDate, intervalCount);
      deadline = addDaystoTheDate({
        theDate: startDateNew,
        days: deadlineDayForIntervals.month,
      });

      if (
        changeDateToMilli(startDateNew) > changeDateToMilli(intervalEndDate)
      ) {
        skip = true;
      }
    } else if (interval === recurringIntervals.year) {
      startDateNew = addNYearstoGivenDate(startDate, intervalCount);
      deadline = addDaystoTheDate({
        theDate: startDateNew,
        days: deadlineDayForIntervals.year,
      });
    }
  } else {
    if (interval === recurringIntervals.day) {
      startDateNew = addDaystoTheDate({
        theDate: startDateNew,
        days: intervalCount,
      });
      deadline = startDateNew;
    } else if (interval === recurringIntervals.week) {
      let dayNumber = weekDayNumber[weekDay];
      if (new Date(startDateNew).getDay() > dayNumber) {
        // skip = true;
        const startDateNewWeekAdded = addNWeekstoGivenDate(
          startDate,
          intervalCount
        ).slice(0, 10);
        startDateNew = getDateOfDayInWeek(startDateNewWeekAdded, dayNumber);
        deadline = addDaystoTheDate({
          theDate: startDateNew,
          days: deadlineDayForIntervals.week,
        });
      } else {
        startDateNew = addDaystoTheDate({
          theDate: startDateNew,
          days: dayNumber - new Date(startDateNew).getDay(),
        });
        deadline = addDaystoTheDate({
          theDate: startDateNew,
          days: deadlineDayForIntervals.week,
        });
      }
    } else if (interval === recurringIntervals.month) {
      let getSelectedDay = parseInt(new Date(weekDay).getDate());

      const endDate = findEndDateOfCurrentMonth(startDateNew);

      if (
        new Date(startDateNew).getDate() > getSelectedDay ||
        endDate == new Date(startDateNew).getDate()
      ) {
        // skip = true;
        const startDateNewMonthAdded = addNMonthstoGivenDate(
          startDate,
          intervalCount
        );
        startDateNew = getDateOfMonth(startDateNewMonthAdded, getSelectedDay);
        deadline = addDaystoTheDate({
          theDate: startDateNew,
          days: deadlineDayForIntervals.month,
        });
      } else {
        startDateNew = addDaystoTheDate({
          theDate: startDateNew,
          days: getSelectedDay - new Date(startDateNew).getDate() + 1,
        });
        deadline = addDaystoTheDate({
          theDate: startDateNew,
          days: deadlineDayForIntervals.month,
        });
      }

      if (
        changeDateToMilli(startDateNew) > changeDateToMilli(intervalEndDate)
      ) {
        skip = true;
      }
    } else if (interval === recurringIntervals.year) {
      let getSelectedDay = parseInt(new Date(weekDay).getDate());
      let getSelectedMonth = parseInt(new Date(weekDay).getMonth());

      // if (new Date(startDateNew).getMonth() > getSelectedMonth) {
      //   skip = true;
      //   console.log("skip1");
      // } else {
      //   if (new Date(startDateNew).getDate() > getSelectedDay) {
      //     console.log("skip2");
      //     skip = true;
      //   }
      // }
      if (
        new Date(startDateNew).getMonth() > getSelectedMonth ||
        new Date(startDateNew).getDate() > getSelectedDay
      ) {
        const startDateNewYearFinalized = addNYearstoGivenDate(
          startDate,
          intervalCount
        );
        startDateNew = yearDateSetter(
          startDateNewYearFinalized,
          getSelectedDay,
          getSelectedMonth
        );
        deadline = addDaystoTheDate({
          theDate: startDateNew,
          days: deadlineDayForIntervals.year,
        });
      } else {
        startDateNew = addNMonthstoGivenDate(
          startDate,
          getSelectedMonth - parseInt(new Date(weekDay).getMonth())
        );
        startDateNew = addDaystoTheDate({
          theDate: startDateNew,
          days: getSelectedDay - new Date(startDateNew).getDate(),
        });

        deadline = addDaystoTheDate({
          theDate: startDateNew,
          days: deadlineDayForIntervals.year,
        });
      }
    }
  }

  return { startDate: startDateNew, deadline: deadline, skip: skip };
};

export const setProjectDatatoRef = async (projectId, data) => {
  try {
    const projectRef = firebase
      .firestore()
      .collection("projects")
      .doc(projectId);
    await projectRef.set({
      ...data,
    });
    return {
      status: "Success",
      data: data,
    };
  } catch (err) {
    console.log("Error", err);
    return {
      status: "Error",
      message: err.message,
    };
  }
};

const createAllMissionsForRecurssiveProject = (
  projectId,
  missionCount,
  missionData
) => {
  let allMissions = [];
  let dateInfo = {};
  let currentStartDate = missionData.startDate;
  let endDate = currentStartDate;
  for (let i = 0; i < missionCount; i++) {
    const missionName = `${missionData.projectName} : order ${i + 1}`;

    const searchMissionName = generateKeywordsForSearch(missionName);
    dateInfo = getStartDateForMission(
      missionData?.recurrenceInfo?.everyCount,
      missionData?.recurrenceInfo.recursiveInterval,
      currentStartDate,
      i,
      missionData?.recurrenceInfo?.startDateForMission,
      missionData?.recurrenceInfo.intervalEndDate
    );
    if (!dateInfo.skip) {
      currentStartDate = dateInfo.startDate;
      endDate = dateInfo.deadline;

      missionData.deadline = endDate.slice(0, 10);
      missionData.missionName = missionName;
      missionData.searchMissionName = searchMissionName;
      missionData.location = missionData.country;

      const msnObj = createMissionObj(missionData);

      allMissions.push({
        ...msnObj,
        startDate: currentStartDate || null,
        clientId: missionData?.clientId || null,
        projectIds: projectId || null,
        status: missionStatus.initialProjectOrder,
        recurrence: true,
      });
    }
  }
  return allMissions;
};

export const updateMissionIdToProject = async (
  firebase,
  projectId,
  allMissionIds
) => {
  try {
    await firebase.firestore().collection("projects").doc(projectId).update({
      allMissionIds: allMissionIds,
    });
    return {
      status: "Success",
      message: "Mission ids added to project",
    };
  } catch (err) {
    console.log("Error", err);
  }
};

export const createRecursiveMissions = (projectId, missionData) => {
  try {
    const missionCount = getMissionCountForRecurrsiveProject(missionData);

    const missionDatasToAdd = createAllMissionsForRecurssiveProject(
      projectId,
      missionCount,
      missionData
    );

    return Promise.all(
      missionDatasToAdd.map(async (missionToCreate) => {
        return new Promise((resolve) => {
          const generatedMissionId = generateMissionId(4);
          const missionsDoc = firebase
            .firestore()
            .collection("missions")
            .doc(generatedMissionId);
          // resolve(
          missionsDoc.set({
            id: generatedMissionId,
            ...missionToCreate,
          });
          // );
          resolve({
            id: generatedMissionId,
            ...missionToCreate,
          });
        });
      })
    );
  } catch (err) {
    console.log("Error", err);
    return {
      status: "Error",
      message: err.message || "Error in creating recursive order.",
    };
  }
};

export const getProjectMissionList = async (
  firebase,
  sort,
  filter,
  lastLoaded,
  limit
) => {
  try {
    let query = await firebase
      .firestore()
      .collection("missions")
      .where("missionType", "==", missionTypeConstant.projectMission)
      // .where("status", "not-in", [
      //   missionStatus.archive,
      //   missionStatus.rejected,
      //   missionStatus.cancelled,
      //   missionStatus.deleted,
      //   missionStatus.missed,
      // ])
      // .orderBy("status")
      .orderBy(sort?.sortBy, sort?.sortValue);

    if (filter) {
      if (
        Object.keys(filter)?.includes("projectNameSearch") &&
        filter["projectNameSearch"]
      ) {
        const wordArr = filter["projectNameSearch"].toLowerCase().split(" ");
        query = query.where("projectSearchName", "array-contains-any", wordArr);
      } else if (
        Object.keys(filter)?.includes("searchMissionName") &&
        filter["searchMissionName"]
      ) {
        const wordArr = filter["searchMissionName"].toLowerCase().split(" ");
        query = query.where("searchMissionName", "array-contains-any", wordArr);
      } else if (
        Object.keys(filter)?.includes("projectId") &&
        filter?.projectId
      ) {
        query = query.where("projectIds", "==", filter["projectId"]);
      } else if (
        Object.keys(filter)?.includes("missionId") &&
        filter?.missionId
      ) {
        query = query.where("id", "==", filter?.missionId);
      } else if (Object.keys(filter)?.includes("status") && filter?.status) {
        query = query.where("status", "==", filter?.status);
      }
    }
    if (lastLoaded) {
      query = query.startAfter(lastLoaded);
    }

    return query
      .limit(limit)
      .get()
      .then(async (snapshot) => {
        let allAssignedPilotIds = [];
        const allData = await snapshot.docs.map((doc) => {
          if (
            doc.data()?.assignedPilot &&
            !allAssignedPilotIds.filter(
              (pid) => pid === doc.data()?.assignedPilot
            ).length > 0
          ) {
            allAssignedPilotIds.push(doc.data()?.assignedPilot);
          }
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
        let allPilotsDataForProject = [];

        let numberOfPilots = allAssignedPilotIds?.length || 0;
        let dataLoadedPage = 0;

        while (numberOfPilots > 0) {
          const idsToGet = allAssignedPilotIds.slice(
            dataLoadedPage * 10,
            dataLoadedPage * 10 + 10
          );
          const pilotDataToGet = await firebase
            .firestore()
            .collection("pilots")
            .where("__name__", "in", idsToGet)
            .get();
          allPilotsDataForProject.push(...pilotDataToGet.docs);

          dataLoadedPage = dataLoadedPage + 1;
          numberOfPilots = numberOfPilots - 10;
        }
        const missionDataUpdated = await allData.map((msnData) => {
          return {
            ...msnData,
            userName: allPilotsDataForProject
              .filter((pilotData) => pilotData.id === msnData.assignedPilot)[0]
              ?.data()?.name,
            profileImageURL: allPilotsDataForProject
              .filter((pilotData) => pilotData.id === msnData.assignedPilot)[0]
              ?.data()?.profileImageURL,
          };
        });
        return {
          status: "Success",
          missions: missionDataUpdated,
          isLast: allData.length < limit,
          lastLoaded:
            snapshot?.size > 0
              ? snapshot.docs[snapshot?.docs?.length - 1]
              : null,
        };
      })
      .catch((e) => {
        console.log("Error", e);
        return {
          status: "Error",
          missions: [],
          isLast: true,
          lastLoaded: null,
        };
      });
  } catch (err) {
    console.log("Error", err);
    return {
      status: "Error",
      missions: [],
      isLast: true,
      lastLoaded: null,
    };
  }
};

export const getProjectsList = async (
  firebase,
  status,
  role,
  id,
  lastLoaded,
  limit,
  filter,
  sort,
  enterpriseId
) => {
  let query = await firebase
    .firestore()
    .collection("projects")
    .orderBy(sort?.sortBy, sort?.sortValue);
  if (status === projectStatus.draftClient) {
    query = query
      .where("status", "==", projectStatus.draft)
      .where("createdByRole", "==", userRoles.client);
  } else {
    query = query.where("status", "==", status);
  }

  if (filter) {
    if (
      Object.keys(filter)?.includes("Project Name") &&
      filter["Project Name"]
    ) {
      const wordArr = filter["Project Name"].toLowerCase().split(" ");
      query = query.where("projectSearchName", "array-contains-any", wordArr);
    } else if (
      Object.keys(filter)?.includes("Client Name") &&
      filter["Client Name"]
    ) {
      const wordArr = filter["Client Name"].toLowerCase().split(" ");
      query = query.where("searchClientName", "array-contains-any", wordArr);
    } else if (Object.keys(filter)?.includes("Id") && filter?.Id) {
      query = query.where("id", "==", filter?.Id);
    }
    Object.keys(filter)?.map((res) => {
      if (res === "Country") {
        query = query.where("country", "==", filter["Country"]);
      } else if (res === "status") {
        query = query.where("status", "==", filter.filter);
      }
    });
  }

  if (status == projectStatus.draft) {
    if (role == userRoles.admin) {
      query = query.where("createdByRole", "==", userRoles.admin);
    } else if (role == userRoles.client) {
      query = query.where("createdByRole", "==", userRoles.client);
    }
  }

  if (role == userRoles.client) {
    /* --Fectch client projects only-- **/
    if (enterpriseId) {
      query = query.where("enterPriseId", "==", enterpriseId);
    } else {
      query = query.where("clientId", "==", id);
    }
  }

  if (lastLoaded) {
    query = query.startAfter(lastLoaded);
  }

  return query
    .limit(limit)
    .get()
    .then(async (snapshot) => {
      const allData = await snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      return {
        projects: allData,
        isLast: allData.length < limit,
        lastLoaded:
          snapshot?.size > 0 ? snapshot.docs[snapshot?.docs?.length - 1] : null,
      };
    })
    .catch((e) => {
      console.log("Error", e);
      return {
        projects: [],
        isLast: true,
        lastLoaded: null,
      };
    });
};

// --------------get project list for particular client-----------------
export const getProjectListByClientId = async (
  firebase,
  lastLoaded,
  limit,
  filter,
  clientId
) => {
  try {
    let query = await firebase
      .firestore()
      .collection("projects")
      .where("clientId", "==", clientId)
      .where("status", "!=", projectStatus.draft);

    if (filter) {
      if (
        Object.keys(filter)?.includes("projectName") &&
        filter["projectName"]
      ) {
        const wordArr = filter["projectName"]?.toLowerCase()?.split(" ") || [];
        query = query.where("projectSearchName", "array-contains-any", wordArr);
      }

      if (Object.keys(filter)?.includes("status") && filter["status"]) {
        query = query.where("status", "==", filter["status"]);
      }

      if (Object.keys(filter)?.includes("id") && filter["id"]) {
        query = query.where("id", "==", filter["id"]);
      }
    }

    if (lastLoaded) {
      query = query.startAfter(lastLoaded);
    }

    return query
      .limit(limit)
      .get()
      .then(async (snapshot) => {
        const allData = await snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
        return {
          projectList: allData,
          isLast: allData.length < limit,
          lastLoaded:
            snapshot?.size > 0
              ? snapshot.docs[snapshot?.docs?.length - 1]
              : null,
        };
      });
  } catch (err) {
    console.log("Error", err);
    return {
      status: "Error",
      projectList: [],
      message: err.message || "Error in getting project list",
    };
  }
};

export const getMissionListForProject = async (
  firebase,
  projectId,
  lastLoaded,
  limit
) => {
  let updatedLimit = Number(limit) + 1;
  try {
    const currentUser = getUser("user");
    let query = await firebase
      .firestore()
      .collection("missions")
      .where("projectIds", "==", projectId)
      .where("status", "not-in", [
        missionStatus.deleted,
        missionStatus.missed,
        missionStatus.rejected,
        missionStatus.cancelled,
        missionStatus.archive,
      ])
      .orderBy("status", "desc")
      .orderBy("dateCreated", "desc");

    if (lastLoaded) {
      query = query.startAfter(lastLoaded);
    }
    return query
      .limit(updatedLimit)
      .get()
      .then(async (snapshot) => {
        let allPilotIds = [];
        let allUpdatedMissionsData = [];
        let allData = await snapshot.docs.map((doc) => {
          if (
            doc.data().assignedPilot &&
            currentUser?.role == userRoles.admin &&
            !allPilotIds.includes(doc.data().assignedPilot)
          ) {
            allPilotIds.push(doc.data().assignedPilot);
          }
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
        allData = allData.slice(0, limit);
        let pilotData = [];
        if (allPilotIds?.length > 0) {
          let numberOfPilots = allPilotIds.length;
          let dataLoadedPage = 0;

          while (numberOfPilots > 0) {
            const idsToGet = allPilotIds.slice(
              dataLoadedPage * 10,
              dataLoadedPage * 10 + 10
            );

            const pilotDataToGet = await firebase
              .firestore()
              .collection("pilots")
              .where("__name__", "in", idsToGet)
              .get();
            pilotData.push(...pilotDataToGet.docs);

            dataLoadedPage = dataLoadedPage + 1;
            numberOfPilots = numberOfPilots - 10;
          }
        }

        await allData.forEach(async (missionInfo) => {
          if (
            missionInfo.assignedPilot &&
            currentUser?.role == userRoles.admin
          ) {
            let assignedPilotInfo = await pilotData?.filter(
              (pilotInfo) => pilotInfo.id === missionInfo.assignedPilot
            );
            allUpdatedMissionsData.push({
              ...missionInfo,
              assignedPilotName: assignedPilotInfo[0]?.data()?.name,
              assignedPilotProfileUrl:
                assignedPilotInfo[0]?.data()?.profileImageURL,
              disabled: true,
            });
          } else {
            allUpdatedMissionsData.push({
              ...missionInfo,
              assignedPilot: "",
              assignedPilotProfileUrl: "",
              disabled: false,
            });
          }
        });
        return {
          missions: allUpdatedMissionsData,
          isLast: snapshot.docs.length <= limit,
          lastLoaded:
            snapshot?.size > 0
              ? snapshot?.docs?.length > limit
                ? snapshot.docs[snapshot?.docs?.length - 2]
                : snapshot.docs[snapshot?.docs?.length - 1]
              : null,
        };
      })
      .catch((e) => {
        console.log("Error", e);
        return {
          missions: [],
          isLast: true,
          lastLoaded: null,
        };
      });
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error?.message || "Error in getting the missions for project.",
    };
  }
};

export const getMissionDataForProject = async (firebase, projectId) => {
  try {
    const getMissionForProject = await firebase
      .firestore()
      .collection("missions")
      .where("projectIds", "==", projectId)
      .get();
    const allPilotIds = [];
    if (getMissionForProject?.docs?.length > 0) {
      let missionDataAll = await getMissionForProject?.docs?.map((mission) => {
        if (
          mission.data().assignedPilot &&
          !allPilotIds.includes(mission.data().assignedPilot)
        ) {
          allPilotIds.push(mission.data().assignedPilot);
        }
        return mission.data();
      });

      let allPilotsDataForProject = [];

      let numberOfPilots = allPilotIds?.length || 0;
      let dataLoadedPage = 0;

      while (numberOfPilots > 0) {
        const idsToGet = allPilotIds.slice(
          dataLoadedPage * 10,
          dataLoadedPage * 10 + 10
        );
        const pilotDataToGet = await firebase
          .firestore()
          .collection("pilots")
          .where("__name__", "in", idsToGet)
          .get();
        allPilotsDataForProject.push(...pilotDataToGet.docs);

        dataLoadedPage = dataLoadedPage + 1;
        numberOfPilots = numberOfPilots - 10;
      }

      const missionData = await missionDataAll.map((mission) => {
        if (mission.assignedPilot) {
          let profileImageURL = allPilotsDataForProject?.filter(
            (pilot) => pilot?.id === mission.assignedPilot
          );
          mission["profileImageURL"] =
            profileImageURL[0]?.data()?.profileImageURL;
        } else {
          mission["profileImageURL"] = null;
        }
        return mission;
      });
      return missionData;
    } else {
      return [];
    }
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error?.message || "Error in getting the missions for project.",
    };
  }
};

export const getSingleProject = async (firebase, projectId, role, pilotId) => {
  try {
    let allCompletedMissionCount = 0;
    let allUnassignedMissionCount = 0;
    let allActiveMissionCount = 0;
    let dataUploadedMissionCount = 0;
    let archiveMissionCount = 0;
    let pilotPaidCount = 0;
    let allPilots = [];
    let missionCountForEachPilot = {};
    let allAdminsData = [];
    let allPilotsData = [];
    let allDistinctPilots = [];
    let missionData = [];
    const projectBasicData = await firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .get();

    let projectData = { ...projectBasicData?.data(), id: projectBasicData.id };

    let missionDatasRef = "";

    if (pilotId) {
      missionDatasRef = await firebase
        .firestore()
        .collection("missions")
        .where("assignedPilot", "==", pilotId)
        .where("projectIds", "==", projectId);
    } else {
      missionDatasRef = await firebase
        .firestore()
        .collection("missions")
        .where("projectIds", "==", projectId);
    }

    const snapshot = await missionDatasRef.get();

    const extractOrderNumber = (str) => {
      const match = str.match(/order\s*(\d+)/i);
      return match ? parseInt(match[1]) : null;
    };

    const sortedMissions = snapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .sort((a, b) => {
        const orderNumberA = extractOrderNumber(a.missionName);
        const orderNumberB = extractOrderNumber(b.missionName);

        // If order numbers are found, sort by them
        if (orderNumberA !== null && orderNumberB !== null) {
          return orderNumberA - orderNumberB;
        }

        // If the missionName is taken from a csv and we do not choose the mission name automatically, so numbers after 'order' are not found, we will sort alphabetically by missionName
        return a.missionName.localeCompare(b.missionName);
      });

    const allMissionData = sortedMissions.map((mission) => {
      let dataMission = mission;
      if (
        dataMission?.status === missionStatus?.confirmedMission ||
        dataMission?.status === missionStatus?.unassigned ||
        dataMission?.status === missionStatus?.initialProjectOrder
      ) {
        allUnassignedMissionCount = allUnassignedMissionCount + 1;
      } else if (dataMission?.status === missionStatus?.completed) {
        allCompletedMissionCount = allCompletedMissionCount + 1;
      } else if (dataMission?.status === missionStatus?.archive) {
        archiveMissionCount = archiveMissionCount + 1;
      } else if (
        dataMission?.status === missionStatus?.active &&
        dataMission?.pilotPackageStatus === PilotPackageStatus?.pendingReview
      ) {
        dataUploadedMissionCount = dataUploadedMissionCount + 1;
        // allActiveMissionCount = allActiveMissionCount + 1;
      } else if (dataMission?.status === missionStatus?.active) {
        allActiveMissionCount = allActiveMissionCount + 1;
      }
      // if (dataMission?.paymentStatusPilot === "paid") {
      //   pilotPaidCount = pilotPaidCount + 1;
      // }
      if (dataMission?.assignedPilot) {
        allPilots.push(dataMission?.assignedPilot);
      }
      return dataMission; // Return dataMission instead of mission
    });
    // let allMissionCount = allMissionData?.length;

    if (role === userRoles.admin) {
      allPilots.forEach((x) => {
        missionCountForEachPilot[x] = (missionCountForEachPilot[x] || 0) + 1;
      });
      allDistinctPilots = Object.keys(missionCountForEachPilot)?.map(
        (pilotId) => pilotId
      );
      const allUserIds = [...allDistinctPilots, ...projectData.allAdminIds];

      let getUserDataForProject = [];

      let numberOfPilots = allUserIds?.length || 0;
      let dataLoadedPage = 0;

      while (numberOfPilots > 0) {
        const idsToGet = allUserIds.slice(
          dataLoadedPage * 10,
          dataLoadedPage * 10 + 10
        );

        const pilotDataToGet = await firebase
          .firestore()
          .collection("pilots")
          .where("__name__", "in", idsToGet)
          .get();
        getUserDataForProject.push(...pilotDataToGet.docs);

        dataLoadedPage = dataLoadedPage + 1;
        numberOfPilots = numberOfPilots - 10;
      }
      getUserDataForProject?.forEach((user) => {
        const userData = user.data();
        userData.id = user.id;
        if (userData.role === userRoles.pilot) {
          userData["missionCountForPilot"] =
            allMissionData?.filter(
              (mission) =>
                mission?.assignedPilot === user.id &&
                mission?.status !==
                  (missionStatus.deleted ||
                    missionStatus.archive ||
                    missionStatus.rejected ||
                    missionStatus.cancelled)
            )?.length || 0;

          userData["userName"] = userData.name;
          userData["viewUrl"] = `/app/pilots/${user.id}`;
          userData["completedMissionCountForPilot"] =
            allMissionData?.filter(
              (mission) =>
                mission?.assignedPilot === user.id &&
                mission?.status === missionStatus.completed
            )?.length || 0;
          allPilotsData.push(userData);
        } else if (userData.role === userRoles.admin) {
          allAdminsData.push(userData);
        }
      });

      missionData = allMissionData.map((mission) => {
        if (mission.assignedPilot) {
          let profileImageURL = getUserDataForProject?.filter(
            (pilot) => pilot?.id === mission.assignedPilot
          );

          mission["profileImageURL"] =
            profileImageURL[0]?.data()?.profileImageURL;
          mission["userName"] = profileImageURL[0]?.data()?.name;
        }
        mission["viewUrl"] = `/app/missions/${mission.id}`;
        return mission;
      });
    } else {
      missionData = allMissionData.map((mission) => {
        if (role === userRoles.client) {
          mission["viewUrl"] = `/app/client/orderDetails/${mission.id}`;
        } else {
          mission["viewUrl"] = `/app/missions/${mission.id}`;
        }
        return mission;
      });
    }

    // let allSentInvoice = 0;
    // let allMissionIds = projectData?.allMissionIds;
    // let dataLoadedPageForInvoice = 0;
    // while (allMissionCount > 0) {
    //   const idsToGet = allMissionIds.slice(
    //     dataLoadedPageForInvoice * 10,
    //     dataLoadedPageForInvoice * 10 + 10
    //   );
    //   const sentInoiceData = await firebase
    //     .firestore()
    //     .collection("invoices")
    //     // .where("missionId", "in", idsToGet)
    //     .where("projectId", "==", projectId)
    //     // .where("status", "!=", "not_sent")
    //     .get();
    //   allMissionCount = allMissionCount - 10;
    //   allSentInvoice = allSentInvoice + sentInoiceData.size;
    //   dataLoadedPageForInvoice = dataLoadedPageForInvoice + 1;
    // }

    let invoicedCount = 0;

    if (role === userRoles.admin || role === userRoles.client) {
      const sentInoiceData = await firebase
        .firestore()
        .collection("invoices")
        // .where("missionId", "in", idsToGet)
        .where("projectId", "==", projectId)
        // .where("status", "!=", "not_sent")
        .get();

      sentInoiceData?.docs?.map((i) => {
        if (i.data().status != invoicePaymentStatus.not_sent) {
          invoicedCount++;
        }
        if (i.data().status == invoicePaymentStatus.paid) {
          pilotPaidCount++;
        }
      });
    }

    projectData["allMissionCount"] = allMissionData?.length || 0;
    projectData["invoicedMissionCount"] = invoicedCount || 0;
    projectData["allCompletedMissionCount"] = allCompletedMissionCount || 0;
    projectData["allUnassignedMissionCount"] = allUnassignedMissionCount || 0;
    projectData["allActiveMissionCount"] = allActiveMissionCount || 0;
    projectData["dataUploadedMissionCount"] = dataUploadedMissionCount || 0;
    projectData["archiveMissionCount"] = archiveMissionCount || 0;
    projectData["pilotPaidCount"] = pilotPaidCount || 0;
    projectData["associatedPilots"] =
      projectData?.associatedDroneOperatorsIds?.length || 0;

    return {
      status: "Success",
      projectData: projectData,
      missionData: missionData,
      allAdminsData: allAdminsData,
      allPilotsData: allPilotsData,
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error.message || "Error in getting project info.",
    };
  }
};

export const getMissionList = async (firebase, missionName) => {
  const wordArr = missionName?.toLowerCase().split(" ");
  let query = firebase
    .firestore()
    .collection("missions")
    .where("searchMissionName", "array-contains-any", wordArr)
    .orderBy("dateCreated", "desc")
    .limit(30);
  return await query.get().then(async (missions) => {
    let allMission = [];
    if (missions?.docs?.length > 0) {
      await missions?.docs?.forEach(async (mission) => {
        if (
          !(
            mission?.data()?.status == missionStatus.deleted ||
            mission?.data()?.status == missionStatus.archive ||
            mission?.data()?.status == missionStatus.initialClientOrder ||
            mission?.data()?.projectIds
          )
        ) {
          allMission.push({ id: mission.id, ...mission?.data() });
        }
      });
      if (allMission?.length > 0) {
        return {
          missions: allMission,
        };
      } else {
        return {
          status: "Error",
          message: "Mission not found",
        };
      }
    }
  });
};

export const removeSingleMissionFromProject = async (
  firebase,
  projectId,
  missionId
) => {
  try {
    const db = firebase.firestore();
    const batch = db.batch();

    await batch.update(db.collection("missions").doc(missionId), {
      status: missionStatus.deleted,
      deleted: true,
    });
    //remove mission Id from contract
    let missionData = await getMission(firebase, missionId);
    if (missionData.assignedPilot) {
      getPilotContractForProject({
        projectId,
        pilotId: missionData.assignedPilot,
      }).then(async (data) => {
        if (data?.contract?.[0]) {
          let contract = data.contract[0];
          if (
            contract?.newAddedMissionsIds?.length +
              contract?.projectMissionIds?.length ==
            1
          ) {
            // This means contract contains only single mission, so we can delete this contract
            await contractDetailsUpdate({ deleted: true }, contract.id);
          } else {
            let updatedObj = removeMissionFromContract({
              missionId,
              contract,
            });
            await contractDetailsUpdate(updatedObj, contract.id);
          }
        }
      });
    }
    await batch.update(db.collection("projects").doc(projectId), {
      allMissionIds: arrayRemove(missionId),
    });
    await batch.commit();

    return {
      status: "Success",
      message: "Mission removed successfully.",
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error.message || "Error in removing mission from the project.",
    };
  }
};

export const removeMultipleMissionFromProject = async (
  firebase,
  projectId,
  missionIds
) => {
  try {
    const db = firebase.firestore();
    const batch = db.batch();
    await missionIds?.map(async (missionId) => {
      await batch.update(db.collection("missions").doc(missionId), {
        projectIds: "",
      });
      await batch.update(db.collection("projects").doc(projectId), {
        allMissionIds: arrayRemove(...missionIds),
      });
    });

    await batch.commit();

    return {
      status: "Success",
      message: "Mission removed successfully.",
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error.message || "Error in removing mission from the project.",
    };
  }
};

export const projectEdit = async (firebase, data) => {
  const searchClientName = generateKeywordsForSearch(data?.clientName);
  try {
    let projectObj = {
      projectName: data?.projectName,
      projectSearchName: data?.projectSearchName || [],
      mapTypes: data?.mapTypes || [],
      country: data?.country || "",
      clientId: data?.clientId || null,
      clientEmail: data?.clientEmail || "",
      clientPhone: data?.clientPhone || null,
      clientName: data?.clientName || "",
      clientCompanyName: data?.clientCompanyName,
      searchClientName: searchClientName || [],
      deadline: data?.deadline,
      id: data?.id,
      missionPrice: Number(data?.missionPrice) || 0,
      missionOwner: data?.missionOwner || defaultMissionRepresentative?.email,
      missionOwnerId: data?.missionOwnerId || defaultMissionRepresentative?.id,
      missionOwnerName:
        data?.missionOwnerName || defaultMissionRepresentative?.name,
      allowEmptyData: data?.allowEmptyData,
      droneRequirements: data?.droneRequirements || "",
      otherRequirements: data?.otherRequirements || "",
      pilotInstructions: data?.pilotInstructions || "",
      description: data?.description || null,
      dataLibrary: {
        missionTitle: data?.dataLibMissionTitle || "",
        description: data?.dataLibDescription || "",
        sdgGoals: data?.dataLibSdgGoals || [],
        categories: data?.dataLibCategories || [],
      },
      notShowInDataLibrary: data?.notShowInDataLibrary || false,
      freeToDownload: data?.freeToDownload || false,
      breakInvoice: data?.breakInvoice || false,
      paymentStatusClient: data.paymentStatusClient || paymentStatus.unpaid,
      preDeliverypaymentStatusClient:
        data.preDeliverypaymentStatusClient || paymentStatus.unpaid,
    };

    if (data?.advancedOption) {
      projectObj.advancedOption = data?.advancedOption;
    }

    if (data?.pointOfContact) {
      projectObj.pointOfContact = data?.pointOfContact;
    }

    const date = getCurrentDateTime();
    let finalObj = {};
    if (data?.deadline) {
      const deadline = data?.deadline.isLuxonDateTime
        ? DateTime?.fromISO(data?.deadline).toISODate()
        : DateTime?.fromJSDate(data?.deadline).toISODate();
      finalObj = {
        ...projectObj,
        deadline: deadline,
        lastUpdated: date,
      };
    } else {
      finalObj = {
        ...projectObj,
        lastUpdated: date,
      };
    }

    const projectData = await firebase
      .firestore()
      .collection("projects")
      .doc(data.id)
      .update(finalObj);

    return {
      status: "Success",
      data: projectData,
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error?.message || "Error in creating the project",
    };
  }
};

export const addSingleMissionToProject = async (
  firebase,
  projectId,
  missionId
) => {
  try {
    const db = firebase.firestore();
    const batch = db.batch();

    await batch.update(db.collection("missions").doc(missionId), {
      projectIds: projectId,
    });
    await batch.update(db.collection("projects").doc(projectId), {
      allMissionIds: arrayUnion(missionId),
    });

    await batch.commit();

    return {
      status: "Success",
      message: "Mission added successfully.",
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error.message || "Error in adding mission to the project.",
    };
  }
};

export const addMissionsToProject = async (firebase, projectId, missions) => {
  try {
    const db = firebase.firestore();
    const batch = db.batch();

    // const missionIds = missions.map((mission) => mission.id);

    await missions.forEach(async (id) => {
      await batch.update(db.collection("missions").doc(id), {
        projectIds: projectId,
      });
      await batch.update(db.collection("projects").doc(projectId), {
        allMissionIds: arrayUnion(id),
      });
    });

    await batch.commit();

    return {
      status: "Success",
      message: "Mission added successfully.",
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error.message || "Error in adding mission to the project.",
    };
  }
};

export const getBasicProjectDetails = async (firebase, projectId) => {
  try {
    const projectBasicData = await firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .get();

    let projectData = projectBasicData?.data();
    return {
      status: "Success",
      project: { id: projectBasicData.id, ...projectData },
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error.message || "Error in getting project data.",
    };
  }
};
export const moveToActiveProject = async (firebase, projectId) => {
  try {
    const date = getCurrentDateTime();

    await firebase.firestore().collection("projects").doc(projectId).update({
      status: projectStatus.active,
      lastUpdated: date,
    });
    return {
      status: "Success",
      message: "Project moved to active successfully!",
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message:
        error.message || "Error in moving project from initial to active.",
    };
  }
};

export const completeProject = async (projectId) => {
  try {
    const date = getCurrentDateTime();

    await firebase.firestore().collection("projects").doc(projectId).update({
      status: projectStatus.completed,
      projectCompletedDate: date,
      lastUpdated: date,
    });
    return {
      status: "Success",
      message: "Project marked as completed successfully!",
    };
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error.message || "Error in completing the project.",
    };
  }
};

export const getBarChartData = async (
  firebase,
  projectId,
  trigger,
  pilotId
) => {
  try {
    if (trigger === "completed") {
      let missions = await firebase
        .firestore()
        .collection("missions")
        .where("projectIds", "==", projectId)
        .where("dateCompleted", ">", oneWeeksBeforeTodayMillis())
        .where("dateCompleted", "<", getCurrentDateTimeMillis());

      if (pilotId) {
        missions = missions.where("assignedPilot", "==", pilotId);
      }
      missions = await missions.get();
      let barChartData = {};
      let finalData = [];
      for (let i = 0; i > -7; i--) {
        const previousDateTime = DateTime.local()
          .setZone("Europe/Stockholm")
          .plus({ days: i })
          .toMillis();
        let dateStr = changeMillisToDate(previousDateTime);
        barChartData[dateStr] = 0;
      }
      missions?.docs?.forEach((mission) => {
        let date = changeMillisToDate(mission?.data()?.dateCompleted);
        barChartData[date] = (barChartData[date] || 0) + 1;
      });
      let totalMissionCountForWeek = 0;
      Object.entries(barChartData)
        .slice(0)
        .reverse()
        .map((ele) => {
          let data = {
            date: ele[0].slice(5, 10),
            mission: ele[1],
          };
          totalMissionCountForWeek = totalMissionCountForWeek + ele[1];
          finalData.push(data);
        });
      return {
        status: "Success",
        barChartData: finalData,
        totalMissionCountForWeek: totalMissionCountForWeek,
      };
    }
  } catch (error) {
    console.log("Error", error);
    return {
      status: "Error",
      message: error.message || "Error in getting project data.",
    };
  }
};
export const missionCreateForProject = async (
  firebase,
  projectData,
  missionData
) => {
  try {
    const generatedMissionId = generateMissionId(4);
    const missionsDoc = firebase
      .firestore()
      .collection("missions")
      .doc(generatedMissionId);
    const currentTime = getCurrentDateTime();
    const missionName = missionData.missionName || getRandomMissionName();
    const searchMissionName = generateKeywordsForSearch(missionName);
    const date = getCurrentDateTime();

    await missionsDoc.set({
      id: missionsDoc.id,
      droneRequirements: missionData.droneRequirements || [],
      projectIds: projectData.id,
      projectName: projectData?.projectName || "",
      projectSearchName: projectData?.projectSearchName || [],
      missionName: missionName,
      searchMissionName: searchMissionName,
      mapTypes: missionData.mapTypes || [],
      otherMapType: missionData.otherMapType || null,
      companyName: missionData?.companyName || null,
      clientName: missionData?.clientName || null,
      clientPhone: missionData?.clientPhone || null,
      locationLabel: missionData.locationLabel || missionData.area || null,
      locationMap: missionData.locationMap || null,
      kmlFile: missionData.kmlFile || null,
      areaSize: missionData.areaSize || null,
      deadline: projectData?.deadline || null,
      deleted: false,
      clientEmail: missionData.clientEmail || "",
      clientId: missionData?.clientId || null,
      otherRequirements: missionData.otherRequirements || null,
      status: missionData.status || missionStatus.initialProjectOrder,
      allowEmptyData: missionData.allowEmptyData || false, // allowEmptyData
      pilotPackageStatus: null,
      sentToPilots: missionData?.sentToPilots || [],
      declinedByPilots: missionData?.declinedByPilots || [],
      assignedPilot: missionData?.assignedPilot || null,
      assignedPilotName: missionData?.assignedPilot || null,
      pilotPrice: missionData.pilotPrice || 0,
      totalPrice: missionData.totalPrice || 0,
      description: missionData.description || null,
      availableUntil: null,
      availableUntilMillis: null,
      accessToken: null,
      missionType:
        missionData.missionType || missionTypeConstant.projectMission,
      dateCreated: currentTime,
      dateUpdated: currentTime,
      paymentStatusClient: paymentStatus.unpaid,
      paymentStatusPilot: paymentStatus.unpaid,
      subscription: null,
      subscriptionEnd: null,
      notifications: { email: !!missionData.clientEmail },
      contractAcceptedViaAdmin: contractWaiverLabels.notAccepted,
      pilotInstructions: missionData.pilotInstructions || "",
      allowInvoice: missionData.allowInvoice || false,
      missionOwner:
        missionData.missionOwner || defaultMissionRepresentative?.email,
      missionOwnerId:
        missionData.missionOwnerId || defaultMissionRepresentative?.id,
      missionOwnerName:
        missionData.missionOwnerName || defaultMissionRepresentative?.name,
      allowPilotBidding: true,
      orderType: missionData.orderType || null,
      isCorrected: false,
    });

    await firebase
      .firestore()
      .collection("projects")
      .doc(projectData.id)
      .update({
        allMissionIds: arrayUnion(missionsDoc.id),
        lastUpdated: date,
      });

    return {
      status: "Success",
      message: "Mission created successfully",
    };
  } catch (error) {
    return {
      status: "Error",
      message: error.message || "Error in creating mission",
    };
  }
};
export const createMissionObj = (missionData) => {
  const currentTime = getCurrentDateTime();
  const data = {
    droneRequirements: missionData?.droneRequirements || [],
    projectIds: missionData?.projectIds,
    missionName: missionData?.missionName,
    searchMissionName: missionData?.searchMissionName,
    mapTypes: missionData?.mapTypes || [],
    otherMapType: missionData?.otherMapType || null,
    companyName: missionData?.clientCompanyName || null,
    clientName: missionData?.clientName || null,
    clientPhone: missionData?.clientPhone || null,
    location: missionData?.location || null,
    locationLabel:
      missionData?.recurrenceInfo?.locationLabel || missionData?.area || null,
    locationMap: missionData?.recurrenceInfo?.locationMap || null,
    kmlFile: missionData?.recurrenceInfo?.kmlFile || null,
    areaSize: missionData?.recurrenceInfo?.areaSize || null,
    deadline: missionData?.deadline || null,
    clientEmail: missionData?.clientEmail || "",
    otherRequirements: missionData?.otherRequirements || null,
    status: missionData?.status || missionStatus.confirmedMission,
    pilotPackageStatus: null,
    sentToPilots: missionData?.sentToPilots || [],
    declinedByPilots: missionData?.declinedByPilots || [],
    assignedPilot: missionData?.assignedPilot || null,
    assignedPilotName: missionData?.assignedPilot || null,
    pilotPrice: missionData?.pilotPrice || 0,
    totalPrice: missionData?.totalPrice || 0,
    description: missionData?.description || null,
    availableUntil: null,
    availableUntilMillis: null,
    accessToken: null,
    missionType: missionData?.missionType || missionTypeConstant.projectMission,
    dateCreated: currentTime,
    dateUpdated: currentTime,
    paymentStatusClient: paymentStatus.unpaid,
    paymentStatusPilot: paymentStatus.unpaid,
    subscription: null,
    subscriptionEnd: null,
    notifications: { email: !!missionData?.clientEmail },
    contractAcceptedViaAdmin: contractWaiverLabels.notAccepted,
    pilotInstructions: missionData?.pilotInstructions || "",
    allowInvoice: missionData?.allowInvoice || false,
    missionOwner:
      missionData?.missionOwner || defaultMissionRepresentative?.email,
    missionOwnerId:
      missionData?.missionOwnerId || defaultMissionRepresentative?.id,
    missionOwnerName:
      missionData?.missionOwnerName || defaultMissionRepresentative?.name,
    allowPilotBidding: true,
    orderType: missionData?.recurrenceInfo?.orderType || null,
    allowEmptyData: missionData?.allowEmptyData || false,
    freeToDownload: missionData?.freeToDownload || false,
    clientId: missionData?.clientId || null,
    breakInvoice: false,
    projectName: missionData?.projectName || null,
    isCorrected: false,
  };
  if (missionData?.dataLibrary) {
    data.dataLibrary = missionData?.dataLibrary;
  }

  if (missionData.pointOfContact) {
    data.pointOfContact = missionData.pointOfContact;
  }

  if (missionData?.advancedOption) {
    data.advancedOption = missionData.advancedOption;
  }
  return data;
};
export const archiveProject = async (firebase, projectId) => {
  const date = getCurrentDateTime();
  const db = firebase.firestore();
  const batch = db.batch();
  const batchArray = [];
  batchArray.push(batch);
  let operationCounter = 0;
  let batchIndex = 0;
  try {
    const getMissionDataForProject = await firebase
      .firestore()
      .collection("missions")
      .where("projectIds", "==", projectId)
      .get();
    if (getMissionDataForProject?.docs?.length > 0) {
      getMissionDataForProject?.docs?.map(async (mission) => {
        let missionId = mission?.id;
        let missionStatusOld = mission?.data().status;

        db.collection("missions")
          .doc(missionId)
          .collection("pilotPackages")
          .get()
          .then(async (feedbackRef) => {
            if (feedbackRef && feedbackRef.docs && feedbackRef.docs.length) {
              await feedbackRef.docs.forEach(async (doc) => {
                await removePilotFiles(firebase, missionId, doc.id);
                await doc.ref.delete();
              });
            }
          });
        batchArray[batchIndex].update(
          db.collection("missions").doc(missionId),
          {
            archive: true,
            sentToPilots: [],
            declinedByPilots: [],
            assignedPilot: null,
            assignedPilotName: null,
            status: missionStatus.archive,
            beforeArchiveStatus: missionStatusOld,
          }
        );
        operationCounter++;

        if (operationCounter === 499) {
          batchArray.push(db.batch());
          batchIndex++;
          operationCounter = 0;
        }
      });
      batchArray.forEach(async (batch) => await batch.commit());
      await db.collection("projects").doc(projectId).update({
        status: projectStatus.archived,
        lastUpdated: date,
      });
      return {
        status: "Success",
        message: "Project archived successfully!",
      };
    } else {
      await db.collection("projects").doc(projectId).update({
        status: projectStatus.archived,
        lastUpdated: date,
      });
      return {
        status: "Success",
        message: "Project archived successfully!",
      };
    }
  } catch (error) {
    console.log("error", error);
    return {
      status: "Error",
      message: "Error in archiving Project",
    };
  }
};

export const fileCheck = async (
  acceptedFiles,
  rejectedFiles,
  fileType,
  projectName = ""
) => {
  try {
    if (fileType === "kml") {
      return new Promise(function (resolve) {
        let kmlFileData = null;
        let failStatus = null;
        let dataToReturn = {};
        if (rejectedFiles && rejectedFiles.length > 0) {
          failStatus = {
            status: "Error",
            message: `Invalid file format '${rejectedFiles[0].type}'. Only .kml files are allowed.`,
          };
          resolve(failStatus);
        }
        if (acceptedFiles && acceptedFiles.length > 1) {
          failStatus = {
            status: "Error",
            message: `You can only upload one single KML file..`,
          };
          resolve(failStatus);
        }
        if (acceptedFiles && acceptedFiles.length === 1) {
          const reader = new FileReader();

          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = async () => {
            kmlFileData = reader.result;
            const geoJson = kmlParser.toGeoJson(kmlFileData);

            let validKmls = [];
            const noValidKmls = [];
            if (!geoJson) {
              dataToReturn = {
                status: "Error",
                message: `We support KMLs containing either Linear/lines, Pinpoints/coordinates or actual polygons.`,
              };
            } else if (geoJson?.features?.length < 1) {
              dataToReturn = {
                status: "Error",
                message: `KML file doesn't have any locations in it.`,
              };
            } else {
              geoJson.features?.forEach((feature, i) => {
                if (
                  !feature?.geometry ||
                  !feature?.geometry?.coordinates ||
                  !feature?.geometry?.coordinates?.length
                ) {
                  if (feature?.geometry?.geometries?.length > 0) {
                    const properties = feature?.properties || null;
                    const type = feature?.type || "Feature";
                    const modifiedFeature = feature?.geometry?.geometries?.map(
                      (ftr, i) => {
                        return {
                          geometry: ftr,
                          missionName: projectName + " " + "order" + i,
                          id: getCurrentDateTimeMillis(),
                          properties: properties,
                          type: type,
                        };
                      }
                    );
                    validKmls = modifiedFeature;
                  }
                  noValidKmls.push(feature);
                } else {
                  // validKmls.push({
                  //   coordinates: feature.geometry.coordinates,
                  //   type: feature?.geometry?.type,
                  // });
                  if (feature?.properties?.name) {
                    feature.missionName = feature?.properties?.name;
                  } else {
                    const num = i + 1;
                    feature.missionName = projectName + " " + "order" + num;
                  }
                  validKmls.push(feature);
                }
              });

              dataToReturn = {
                status: "Success",
                file: acceptedFiles[0],
                rawData: kmlFileData,
                validKMLData: validKmls,
                notValidKMLData: noValidKmls,
              };
            }
            resolve(dataToReturn);
          };
          reader.readAsText(acceptedFiles[0]);
        }
      });
    } else {
      let failStatus;
      let csvFileRawData;
      if (rejectedFiles && rejectedFiles.length > 0) {
        failStatus = {
          status: "Error",
          message: `Invalid file format '${rejectedFiles[0].type}'. Only .kml files are allowed.`,
        };
        return failStatus;
      }
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        csvFileRawData = reader.result;
      };
      reader.readAsText(acceptedFiles);
      const parsedCSVData = await parseAFile(acceptedFiles);

      if (parsedCSVData?.status === "Success") {
        return {
          status: "Success",
          parsedCSVData: parsedCSVData.parsedData,
          file: acceptedFiles,
          rawData: csvFileRawData,
          columns: parsedCSVData.columns,
          // validData: validData,
          // error: error,
        };
      } else {
        return {
          status: "Error",
          message: "Error in parsing the csv file.",
        };
      }
    }
  } catch (err) {
    console.log("Error", err);
    return {
      status: "Error",
      message: err.message || "Error in parsing the kml/csv file.",
    };
  }
};

export const makeCsvRedable = (parsedCSVData, csvFieldsInfo, projectName) => {
  try {
    let validDataSet = [];
    let inValidDataSet = [];
    if (csvFieldsInfo?.latitude && csvFieldsInfo?.longitude) {
      parsedCSVData?.forEach((data, i) => {
        let geoObj = {
          geometry: {
            type: "Point",
            coordinates: [],
          },
          missionName: "",
          description: "",
        };
        if (
          data[csvFieldsInfo?.latitude] &&
          data[csvFieldsInfo?.longitude] &&
          !isNaN(Number(data[csvFieldsInfo?.longitude])) &&
          !isNaN(Number(data[csvFieldsInfo?.latitude]))
        ) {
          geoObj.geometry.coordinates = [
            Number(data[csvFieldsInfo?.longitude]),
            Number(data[csvFieldsInfo?.latitude]),
          ];
          if (csvFieldsInfo?.missionName && data[csvFieldsInfo?.missionName]) {
            geoObj.missionName = data[csvFieldsInfo?.missionName];
          } else {
            const num = i + 1;
            geoObj.missionName = projectName + " " + "order " + num;
          }
          if (csvFieldsInfo?.description) {
            geoObj.description = data[csvFieldsInfo?.description] || "";
          }
          validDataSet.push(geoObj);
        } else {
          if (!(data.length - 1 === i)) {
            inValidDataSet.push(data);
          }
        }
      });
      return {
        status: "Success",
        validData: validDataSet,
        invalidData: inValidDataSet,
      };
    } else {
      return {
        status: "Error",
        message: "Please select a field for latitude and longitude",
      };
    }
  } catch (err) {
    console.log("Error", err);
    return {
      status: "Error",
      message: err.message || "Error in validating the CSV file",
    };
  }
};

export const uploadAFile = async (firebase, file, path, setProgress) => {
  try {
    return new Promise(function (resolve) {
      const storageRef = firebase.storage().ref().child(path);
      const uploadFile = storageRef.put(file);
      uploadFile.on(
        "state_changed",
        (snapshot) => {
          const progress = snapshot.bytesTransferred / snapshot.totalBytes;
          const roundedProgress =
            progress < 0.01
              ? Math.round(progress * 10000) / 100
              : Math.round(progress * 1000) / 10;
          switch (snapshot.state) {
            case "running":
              setProgress(roundedProgress);
              break;
            case "canceled":
              break;
            case "paused":
              break;
            default:
              console.error("Unpexpected upload state", snapshot.state);
              break;
          }
        },
        (error) => {
          // on error
          console.error(error);
          let errorMessage = ";";
          switch (error.code) {
            case "storage/canceled":
              errorMessage = `Upload canceled`;
              break;
            default:
              errorMessage = `An unexpected error occurred. Make sure you're uploading a .kml file no larger than 1MB.`;
              break;
          }
          resolve({
            status: "Error",
            message: errorMessage,
          });
        },
        () => {
          // on success
          uploadFile.snapshot.ref
            .getDownloadURL()
            .then(async (downloadURL) => {
              resolve({
                status: "Success",
                fileURL: downloadURL,
              });
            })
            .catch((err) => {
              console.log("Error", err);
              resolve({
                status: "Error",
                message: err.message || "Error in uploading the file",
              });
            });
        }
      );
    });
  } catch (err) {
    console.log("Error", err);
    return {
      status: "Error",
      message: err.message || "Error in uploading the csv file.",
    };
  }
};

export const parseAFile = async (file) => {
  try {
    return new Promise((resolve) => {
      Papa.parse(file, {
        header: true,
        complete: async (results) => {
          // console.log("results", results);
          resolve({
            status: "Success",
            parsedData: results.data,
            columns: results.meta.fields,
          });
        },
      });
    });
  } catch (err) {
    console.log("Error", err);
    return {
      status: "Error",
      message: err.message || "Error in parsing the csv file.",
    };
  }
};

export const createQuote = async ({ projectId, quote }) => {
  try {
    if (!projectId) {
      return { status: "Error", message: "Project Id is missing" };
    }
    const currentTime = getCurrentDateTime();

    const quotesRef = firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection("quotes")
      .doc();

    const quoteSearchName = generateKeywordsForSearch(quote.quoteName);

    const data = {
      id: quotesRef.id,
      priceBeforeDiscount: Number(quote.priceBeforeDiscount),
      pricePerUnit: Number(quote.pricePerUnit),
      days: quote.days,
      deleted: false,
      discount: quote.discount,
      discountReason: quote.discount ? quote.discountReason : "",
      discountPercentage: quote.discount ? Number(quote.discountPercent) : 0,
      dateCreated: currentTime,
      dateUpdated: currentTime,
      status: quoteStatus.quoteSent,
      quoteName: quote.quoteName,
      quoteDescription: quote.quoteDescription,
      quoteSearchName: quoteSearchName,
      issuedBy: quote?.issuedBy || {},
      currency: quote?.currency || defaultCurrency,
      expiryDate: addDaystoTheDate({
        theDate: currentTime,
        days: quote?.expiryDate ? quote?.expiryDate : expireQuoteDays,
      }),
    };

    await quotesRef.set(data);
    return {
      status: "Success",
    };
  } catch (e) {
    console.log(e);
    return {
      status: "Error",
      message: e?.message || "Error in creating project quotes subcollection",
    };
  }
};

export const getQuotesList = async (firebase, projectId, role) => {
  let query = await firebase
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection("quotes");

  if (role == userRoles.client) {
    query = query.where("status", "!=", quoteStatus.deleted);
    query = query.orderBy("status", "desc");
  } else {
    query = query.orderBy("dateUpdated", "desc");
  }

  // if (lastLoadedQuote) {
  //   query = query.startAfter(lastLoadedQuote);
  // }

  // if (filter?.quoteName) {
  //   query = query.where("quoteSearchName", "array-contains", filter.quoteName);
  // }
  return query
    .get()
    .then((snapshot) => {
      const quotes = snapshot.docs?.map((ele, i) => {
        return {
          id: ele.id,
          quoteName: ele.data().quoteName || `${projectId}-${i}`,
          ...ele.data(),
        };
      });
      return {
        quotes,
      };
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSingleQuoteByStatus = ({ projectId, status }) => {
  try {
    if (!projectId) {
      throw { status: "Error", message: "Project Id is missing" };
    }
    return new Promise((resolve, reject) => {
      let quotesRef = firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .collection("quotes");

      quotesRef = quotesRef.where("status", "==", status);
      quotesRef = quotesRef.orderBy("dateUpdated", "desc");
      quotesRef = quotesRef.limit(1);

      quotesRef
        .get()
        .then((snapshot) => {
          if (snapshot?.docs?.[0]) {
            resolve({ id: snapshot.docs[0], ...snapshot.docs[0].data() });
          } else {
            reject({
              status: "Error",
              message: "No quote is accepted so far",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          reject({
            status: "Error",
            message: e?.message || "Error in fetching quote",
          });
        });
    });
  } catch (e) {
    console.log("Error", e);
    throw { status: "Error", message: e?.message || "Error in fetching quote" };
  }
};

export const updateQuote = async (projectId, quoteId, data) => {
  const currentTime = getCurrentDateTime();
  const quoteRef = await firebase
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection("quotes")
    .doc(quoteId);

  let objToUpdate = {
    dateUpdated: currentTime,
    ...data,
  };
  return await quoteRef
    .update(objToUpdate)
    .then(() => {
      return {
        status: "Success",
        message: "Quote updated successfully!",
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        status: "Error",
        message: err.message || "Error in updating quote!",
      };
    });
};

export const assignPilotToProjectMission = async (
  firebase,
  project,
  mission,
  pilotId,
  pilotName
) => {
  await assignPilotIdInMission(firebase, mission.id, pilotId, pilotName);
  await assignPilotToProject(firebase, project.id, pilotId);

  return {
    status: "Success",
    message: "Pilot Added SuccessFully",
  };
};

export const assignPilotToProject = (firebase, projectId, pilotId) => {
  const currentTime = getCurrentDateTime();
  return firebase
    .firestore()
    .collection("projects")
    .doc(projectId)
    .update({
      associatedDroneOperatorsIds: arrayUnion(pilotId),
      lastUpdated: currentTime,
    })
    .then(() => {
      return {
        status: "Success",
        message: "pilot added to project",
      };
    })
    .catch((err) => {
      console.log("Error", err);
      return {
        status: "Error",
        message: err.message || "Error in adding pilot to project.",
      };
    });
};

// Project logs api's.

export const createProjectLogs = async (firebase, projectId, data) => {
  const db = firebase.firestore();
  const currentTime = getCurrentDateTime();
  let dataToUpload = data;
  const createProjectLogRef = db
    .collection("projects")
    .doc(projectId)
    .collection("logs")
    .doc();
  dataToUpload.createdAt = currentTime;
  dataToUpload.projectId = projectId;
  dataToUpload.updatedAt = currentTime;

  return createProjectLogRef
    .set(dataToUpload)
    .then(() => {
      return {
        status: "Success",
        message: "Log created successfully!",
      };
    })
    .catch((err) => {
      console.log("Error", err);
      return {
        status: "Error",
        message: "Error in creating Logs.",
      };
    });
};

export const getLogsList = async (
  firebase,
  projectId,
  lastLoadedLog,
  limit,
  pilotId
) => {
  let query = await firebase
    .firestore()
    .collection("projects")
    .doc(projectId)
    .collection("logs");

  query = query.orderBy("createdAt", "desc");

  if (pilotId) {
    query = query.where("assignedPilot", "==", pilotId);
    // .orderBy("createdAt", "desc");
  }

  if (lastLoadedLog) {
    query = query.startAfter(lastLoadedLog);
  }

  return query
    .limit(limit)
    .get()
    .then((snapshot) => {
      const logs = snapshot.docs?.map((ele) => {
        return {
          id: ele.id,
          ...ele.data(),
        };
      });
      return {
        status: "Success",
        logs,
        lastVisible:
          snapshot.size > 0 ? snapshot.docs[snapshot.docs.length - 1] : null,
        last: snapshot.size < limit,
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        status: "Error",
        message: err.message || "Error in getting logs.",
      };
    });
};
export const updateProjectMissionStatus = async ({
  projectId,
  currentMissionStatus,
  toUpdateMissionStatus,
}) => {
  try {
    let query = firebase.firestore().collection("missions");

    if (Array.isArray(currentMissionStatus)) {
      query = query.where("status", "in", currentMissionStatus);
    } else {
      query = query.where("status", "==", currentMissionStatus);
    }
    query = query.where("projectIds", "==", projectId);
    const missions = await query.get();

    // const missions = await firebase
    //   .firestore()
    //   .collection("missions")
    //   .where("status", "==", currentMissionStatus)
    //   .where("projectIds", "==", projectId)
    //   .get();

    const db = firebase.firestore();
    const currentTime = getCurrentDateTime();
    const batchCount = 499;
    const batchArray = [];
    let operationCounter = 0;
    let batchIndex = 0;

    batchArray.push(db.batch());

    await missions.docs.forEach(async (mission) => {
      const missionDocRef = await firebase
        .firestore()
        .collection("missions")
        .doc(mission.id);
      await batchArray[batchIndex].update(missionDocRef, {
        status: toUpdateMissionStatus,
        lastUpdated: currentTime,
        deleted: toUpdateMissionStatus == missionStatus.deleted ? true : false,
      });

      operationCounter++;
      if (operationCounter === batchCount) {
        batchArray.push(db.batch());
        batchIndex++;
        operationCounter = 0;
      }
    });
    batchArray.forEach(async (batch) => await batch.commit());
    return;
  } catch (e) {
    console.log("Error", e);
  }
};

export const getProjectListForPilot = async (
  firebase,
  pilotId,
  sort,
  filter,
  lastLoaded,
  limit
) => {
  try {
    let query = await firebase
      .firestore()
      .collection("projects")
      .where("associatedDroneOperatorsIds", "array-contains", pilotId);
    if (
      !(
        filter &&
        Object.keys(filter)?.includes("projectId") &&
        filter["projectId"]
      )
    ) {
      query = query.orderBy(sort?.sortBy, sort?.sortValue);
    }

    if (filter) {
      if (
        Object.keys(filter)?.includes("projectNameSearch") &&
        filter["projectNameSearch"]
      ) {
        query = query.where("projectName", "==", filter["projectNameSearch"]);
      } else if (Object.keys(filter)?.includes("status") && filter["status"]) {
        query = query.where("status", "==", filter["status"]);
      } else if (
        Object.keys(filter)?.includes("projectId") &&
        filter["projectId"]
      ) {
        query = query.where("__name__", "in", [filter["projectId"]]);
      }
    }
    if (lastLoaded) {
      query = query.startAfter(lastLoaded);
    }

    return query
      .limit(limit)
      .get()
      .then(async (snapshot) => {
        const allData = await snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        return {
          status: "Success",
          projects: allData,
          isLast: allData.length < limit,
          lastLoaded:
            snapshot?.size > 0
              ? snapshot.docs[snapshot?.docs?.length - 1]
              : null,
        };
      })
      .catch((err) => {
        return {
          status: "Error",
          projects: [],
          message: err.message || "Error in getting project list",
        };
      });
  } catch (err) {
    console.log("Error", err);
  }
};

export const unAssignPilotFromProjectMission = async (
  firebase,
  projectId,
  pilotId,
  missionId
) => {
  try {
    const currentTime = getCurrentDateTime();
    const missionRef = firebase
      .firestore()
      .collection("missions")
      .doc(missionId);
    const missionData = missionRef.get();
    const missionCountInProjectForPilot = firebase
      .firestore()
      .collection("missions")
      .where("projectIds", "==", projectId)
      .where("assignedPilot", "==", pilotId)
      .get()
      .then((snapshot) => snapshot.docs);

    if (!(missionCountInProjectForPilot > 1)) {
      const projectRef = firebase
        .firestore()
        .collection("projects")
        .doc(missionId);
      await projectRef.update({
        associatedDroneOperatorsIds: arrayRemove(pilotId),
        lastUpdated: currentTime,
      });
    }
    await createNotification(
      firebase,
      pilotId,
      `You are Unassigned from a mission named ${
        missionData.data().missionName
      }.`,
      "You are Unassigned from a mission.",
      `/app/missions/${missionId}`
    );
    await missionRef.update({
      assignedPilot: null,
      // sentToPilots: [],
      declinedByPilots: [],
      status: missionStatus.unassigned,
      orderStatus: OrderStatus.pendingPilot,
    });
    await deleteProjectContract({
      missionId: missionId,
      pilotId: pilotId,
      projectId: projectId,
    });
    let pilotPackagesID = await (
      await getPilotPackages(firebase, missionId)
    ).filter((res) => {
      return pilotId == res.pilot;
    });
    const updatePilot = {
      dateUpdated: currentTime,
      isDeleted: true,
    };
    await updatePilotPackage(
      firebase,
      missionId,
      pilotPackagesID[0]?.id,
      updatePilot
    );
    return {
      status: "Success",
      message: "Pilot unassigned successfully!",
    };
  } catch (err) {
    console.log("Error", err);
    return {
      status: "Error",
      message: err.message || "Error in unassigning pilot from project mission",
    };
  }
};

export const createProjectContract = async ({ project, pilot, missions }) => {
  try {
    /**  Create or Edit the project contract while assigning pilot to project missions */
    const db = firebase.firestore();
    const query = db
      .collection("contracts")
      .where("pilotId", "==", pilot.id)
      .where("projectId", "==", project.id)
      .where("deleted", "==", false)
      .limit(1);
    const contracts = await query.get();

    let newAddedMissionsIds = [];
    let newAddedMissions = [];
    missions.map((mission) => {
      newAddedMissionsIds.push(mission.id);
      newAddedMissions.push({
        id: mission.id,
        missionName: mission.missionName,
      });
    });

    if (contracts?.docs?.length) {
      // contract already exists
      let contract = { ...contracts.docs[0]?.data(), id: contracts.docs[0].id };
      if (!contract?.newAddedMissions?.length) {
        contract.newAddedMissions = [];
      }

      if (!contract?.newAddedMissionsIds?.length) {
        contract.newAddedMissionsIds = [];
      }
      contract.newAddedMissionsIds = arrayUnion(...newAddedMissionsIds);
      contract.newAddedMissions = arrayUnion(...newAddedMissions);
      contract.status = contractStatusLabel.notSigned;
      const checks = getContractChecks();
      await contractDetailsUpdate({ ...contract, ...checks }, contract.id);
      return {
        status: "Success",
        message: "Contract updated successfully!",
      };
    } else {
      // create new contract
      console.log("New Contract");
      await creactContractForProject({
        project,
        pilot,
        newAddedMissionsIds,
        newAddedMissions,
      });
      return {
        status: "Success",
        message: "Contract updated successfully!",
      };
    }
  } catch (e) {
    console.log(e);
    return {
      status: "Error",
      message: e?.message || "Error in creating project quote!",
    };
  }
};

export const updateProject = async ({ projectId, updates }) => {
  try {
    const date = getCurrentDateTime();
    return new Promise((resolve) => {
      firebase
        .firestore()
        .collection("projects")
        .doc(projectId)
        .update({ ...updates, lastUpdated: date })
        .then(() => {
          resolve({
            status: "Success",
            message: "Project updated successfully!",
          });
        })
        .catch((e) => {
          console.log("Error", e);
          resolve({
            status: "Error",
            message: e.message || "Error in updating the project.",
          });
        });
    });
  } catch (error) {
    console.log("Error", error);
    throw {
      status: "Error",
      message: error.message || "Error in updating the project.",
    };
  }
};

export const getProjectRef = async () => {
  try {
    const db = firebase.firestore();
    const projectRef = await db.collection("projects").doc();
    return projectRef;
  } catch (e) {
    console.log("Error", e);
    return null;
  }
};

export const sendClientDeclineToAdmin = (data) => {
  try {
    const clientDeclineProjectQuote = firebase
      .functions()
      .httpsCallable("clientDeclineProjectQuote");

    clientDeclineProjectQuote(data);
  } catch (e) {
    console.log("Error in sending email to admin", e);
  }
};

export const uploadClientProjectInvoice = async (
  firebase,
  mission,
  invoiceUrl,
  invoiceType
) => {
  const projectID = mission.id;
  const currentUser = getUser("userProfile"); // Get current user id from firebase

  const userID = currentUser.id;
  const userName = currentUser.name;

  if (!userID) {
    return Promise.reject(Error("No user id"));
  }

  let invoiceData = {
    url: invoiceUrl,
    dateCreated: getCurrentDateTime(),
    uploadBy: userName,
    uploadById: userID,
    invoiceType: invoiceType || InvoiceText.singleInvoice,
    paid: true,
  };
  updateClientProjectInvoiceData(firebase, projectID, invoiceData);
};

export const updateClientProjectInvoiceData = async (
  firebase,
  projectID,
  invoiceData
) => {
  let query = firebase
    .firestore()
    .collection("projects")
    .doc(projectID)
    .collection("clientInvoice");

  const invoice = await query.get();

  var invoiceId = null;

  if (
    invoiceData.invoiceType == InvoiceText.firstInvoice ||
    invoiceData.invoiceType == InvoiceText.secondInvoice
  ) {
    const invoiceObj = invoice?.docs.find(
      (obj) => obj?.data()?.invoiceType == invoiceData.invoiceType
    );
    if (invoiceObj && invoiceObj.id) {
      invoiceId = invoiceObj.id;
    }
  } else {
    // for single invoice type
    const invoiceObj = invoice?.docs.find(
      (obj) =>
        obj?.data()?.invoiceType == invoiceData.invoiceType ||
        !obj?.data().invoiceType
    );
    if (invoiceObj && invoiceObj.id) {
      invoiceId = invoiceObj.id;
    }
  }
  let invoiceRef = firebase
    .firestore()
    .collection("projects")
    .doc(projectID)
    .collection("clientInvoice");

  if (invoiceId) {
    invoiceRef.doc(invoiceId).update(invoiceData);
  } else {
    invoiceRef.add(invoiceData);
  }
  return await invoiceRef;
};

export const getProjectInvoice = async (firebase, projectID) => {
  let docs = [];
  const docsSnap = await getDocs(
    collection(firebase.firestore(), `projects/${projectID}/clientInvoice`)
  );

  if (docsSnap && docsSnap.docs && docsSnap.docs[0]) {
    docsSnap?.docs?.map((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
  }
  return docs;
};

export const downloadUploadedFile = async (projectId) => {
  try {
    return await firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .collection("uploadedFiles")
      .where("status", "==", "completed")
      .get()
      .then((snapshot) => {
        let fileUrls = snapshot.docs.map((doc) => doc.data());
        return fileUrls;
      })
      .catch((err) => {
        console.log("Error", err);
      });
  } catch (error) {
    console.log("Error", error);
  }
};
