import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const Instruction = ({ data }) => {
  return (
    <ul>
      <li>
        <Typography variant="body1">{data}</Typography>
      </li>
    </ul>
  );
};
Instruction.propTypes = {
  data: PropTypes.string,
};
Instruction.defaultProps = {
  data: undefined,
};

export default Instruction;
