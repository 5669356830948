import { Box, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import PropTypes from "prop-types";
import React from "react";
import { getKMLFileNameFromUrl } from "../../../../lib/helpers";

const style = {
  fileIndicatorLeft: {
    display: "inline-flex",
    alignItems: "flex-end",
    width: "100%",
    margin: "20px 0px",
  },
  fileIndicatorCentered: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
  },
};

const KMLDownloadField = ({ url, centered }) => {
  return (
    <Box sx={centered ? style.fileIndicatorCentered : style.fileIndicatorLeft}>
      <a href={url} rel="noopener noreferrer" target="_blank">
        <DescriptionIcon fontSize="large" color="primary" sx={style.fileIcon} />
        <Typography className={style.kmlFileDescriptor}>
          {getKMLFileNameFromUrl(url)}
        </Typography>
        <Typography className={style.kmlFileDescriptor}>
          (Click to download)
        </Typography>
      </a>
    </Box>
  );
};

KMLDownloadField.propTypes = {
  url: PropTypes.string.isRequired,
  centered: PropTypes.bool,
};

KMLDownloadField.defaultProps = {
  centered: true,
};

export default KMLDownloadField;
