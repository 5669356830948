import React from "react";
import { projectStatus } from "../../../../lib/constants";
import ProjectTable from "./ProjectTable";

const DraftProjectClient = () => {
  const status = projectStatus.draftClient;

  return (
    <ProjectTable
      status={status}
      pageTitle={"Client pending submission"}
      tips={`Here you find unsubmitted multi-location or recurrent orders 
      by the client. They are saved here and are not submitted yet. Make 
      sure to ask client that they want to submit this order or not.
      Once client is ready, ask them to "go to the order and click on 'Submit 
      order'" or you can do it by yourself too.`}
    />
  );
};

export default DraftProjectClient;
