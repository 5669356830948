import { Box, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import firebase from "src/firebase";
import TextFieldInput from "../../../../global/FormInputComponents/TextFieldInput";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField as MuiTextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AppButton from "../../../../global/elements/AppButton";
import { invoiceDetailsUpdate } from "../../../../../services/invoices";
import { flexSpace, formInputField } from "../../../../../lib/styleConstants";
import PropTypes from "prop-types";
import { createNotification } from "../../../../../services/notification";
import { notificationType } from "../../../../../lib/constants";

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1em",
    padding: "2em 0.5em",
  },
};
const EditInvoiceForm = ({
  invoice,
  setShowDialogue,
  setShowSubmitLoader,
  showSubmitLoader,
  refreshMission,
  editAccess,
}) => {
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("Price is required"),
    due_date: Yup.string().required("Due date is required"),
    comment: Yup.string(),
  });
  const formInitialValues = {
    amount: invoice?.amount || 0,
    due_date: invoice?.due_date,
    comment: "",
  };

  const submitHandler = async (values) => {
    setShowSubmitLoader(true);
    values["status"] = "unpaid";
    try {
      await invoiceDetailsUpdate(values, invoice?.id)
        .then((status) => {
          if (status.status == "Success") {
            setShowSubmitLoader(false);
            createNotification(
              firebase,
              notificationType.adminNotification,
              `A new invoice was received from an operator.`,
              "A new invoice was received from an operator.",
              `/app/admin/dashboard/invoices/${invoice?.id}`
            );
            refreshMission();
          }
        })
        .catch((e) => {
          console.log(e);
          setShowSubmitLoader(false);
        });
    } catch (e) {
      console.log("Error", e);
      setShowSubmitLoader(false);
    }
  };
  return (
    <Box>
      <Box sx={{ textAlignLast: "center" }}>
        <Typography variant="h3">Edit Invoice</Typography>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ values, setFieldValue, touched, errors }) => {
            return (
              <Form id="clientForm" sx={{ marginTop: "20px" }}>
                <Box sx={styles.form}>
                  <TextFieldInput
                    setFieldValue={setFieldValue}
                    values={values}
                    name={"amount"}
                    placeholder="Price"
                    disabled={!editAccess}
                    type="text"
                    required
                    label="Amount"
                  />
                  {/* <TextFieldInput
                  setFieldValue={setFieldValue}
                  values={values}
                  name={"due_date"}
                  placeholder="Due date"
                  disabled={!invoice?.pilotEditAccess}
                  type="date"
                  required
                  label="Due Date"
                /> */}
                  <Box sx={formInputField}>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      fullWidth
                      disablePast
                      disabled={!editAccess}
                      onChange={(value) => {
                        setFieldValue("due_date", value);
                      }}
                      value={values.due_date}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          name="due_date"
                          label="Due date"
                          error={Boolean(touched.due_date && errors.due_date)}
                          helperText={touched.due_date && errors.due_date}
                        />
                      )}
                    />
                  </Box>

                  <TextFieldInput
                    setFieldValue={setFieldValue}
                    values={values}
                    name={"comment"}
                    placeholder="Comments"
                    disabled={false}
                    type="text"
                    label="Comments"
                    multiline={true}
                    rows={3}
                  />
                </Box>
                <Box sx={{ ...flexSpace }}>
                  <AppButton
                    look="inverted"
                    label="Cancel"
                    noIcon
                    onClick={() => setShowDialogue(false)}
                  />
                  <AppButton
                    type="submit"
                    form="clientForm"
                    look="green"
                    label="Save"
                    submittingState={showSubmitLoader}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </LocalizationProvider>
    </Box>
  );
};

EditInvoiceForm.propTypes = {
  invoice: PropTypes.obj,
  setShowDialogue: PropTypes.func,
  setShowSubmitLoader: PropTypes.func,
  showSubmitLoader: PropTypes.func,
  refreshMission: PropTypes.func,
  editAccess: PropTypes.bool,
};

EditInvoiceForm.defaultProps = {
  invoice: undefined,
  setShowDialogue: undefined,
  setShowSubmitLoader: undefined,
  showSubmitLoader: undefined,
  refreshMission: undefined,
  editAccess: false,
};
export default EditInvoiceForm;
