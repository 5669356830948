import { Box, Typography } from "@mui/material";
import React from "react";
import { MissionSpecs } from "../../../../lib/constants";

const StandardSpec = () => {
  return (
    <Box>
      <Typography variant="body1">
        If no advanced option is selected, the flight will proceed with the
        following standard specifications. However, if an advanced option is
        chosen, it will override the corresponding standard value.
      </Typography>
      <ul>
        {MissionSpecs.map((spec, i) => (
          <li key={i}>
            <Typography variant="body3">{spec}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default StandardSpec;
