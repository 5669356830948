import firebase from "src/firebase";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getMissions } from "src/services/missions";

export const useGetMissionList = (status, pilotID, missionType) => {
  let initalSortBy = ["dateCreated", "desc"];

  const [missions, setMissions] = useStateIfMounted([]);
  const [lastLoadedMission, setLastLoadedMission] = useStateIfMounted(null);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const [filteredMissionList, setFilteredMissionList] = useStateIfMounted([]);
  const [limit, setLimit] = useStateIfMounted(10);
  const [filter, setFilter] = useStateIfMounted({});
  const [loadHandler, setLoadHandler] = useStateIfMounted(false);
  const [allMission, setAllMission] = useStateIfMounted([]);
  const [pageData, setPagedata] = useStateIfMounted({
    currentPage: 1,
    isLast: false,
    lastPage: null,
  });
  const [sortBy, setSortBy] = useStateIfMounted(initalSortBy);
  const [filterOrSortTriggered, setFilterorSortTriggered] =
    useStateIfMounted(false);
  const [pageChanged, setPageChanged] = useStateIfMounted(false);
  const [previousPage, setPreviousPage] = useStateIfMounted(0);
  const [dataloadedTill, setDataLoadedTill] = useStateIfMounted(0);
  const [authorized, setAuthorized] = useStateIfMounted(null);
  useEffect(() => {
    if (!firebase) {
      return;
    }
    setIsLoading(true);
    if (
      dataloadedTill >= pageData.currentPage ||
      (pageData.currentPage <= previousPage && filterOrSortTriggered === false)
    ) {
      let prevPageMissionData = allMission.slice(
        (pageData.currentPage - 1) * limit,
        pageData.currentPage * limit
      );
      setMissions(prevPageMissionData);
      setPreviousPage(pageData.currentPage - 1);
      setIsLoading(false);
      setAuthorized(true);
    } else {
      if (filterOrSortTriggered) {
        setPagedata({
          currentPage: 1,
          isLast: false,
          lastPage: null,
        });
        setPreviousPage(0);
      }
      getMissions(
        firebase,
        status,
        lastLoadedMission,
        limit,
        pilotID,
        filter,
        sortBy[1],
        sortBy[0],
        missionType
      )
        .then((data) => {
          setMissions(data.missions);
          let allMissionData = allMission || [];
          setPreviousPage(pageData.currentPage - 1);
          setLastLoadedMission(data.lastVisible);
          setDataLoadedTill(pageData.currentPage);
          if (data.last) {
            let pageDataUpdated = pageData;
            pageDataUpdated["isLast"] = true;
            pageDataUpdated["lastPage"] = pageData.currentPage;
            setPagedata(pageDataUpdated);
          }
          if (filterOrSortTriggered) {
            setPreviousPage(0);
            setAllMission([...data.missions]);
          } else {
            setAllMission([...allMissionData, ...data.missions]);
          }
          setFilterorSortTriggered(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
          setAuthorized(true);
        });
    }
  }, [limit, status, loadHandler, sortBy, filter, pageChanged]);

  useEffect(() => {
    let newMissionList = [...missions];
    setFilteredMissionList(newMissionList);
  }, [missions, pageChanged, sortBy, loadHandler, limit]);

  return [
    authorized,
    filteredMissionList,
    isLoading,
    limit,
    setLimit,
    filter,
    setFilter,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllMission,
    pageChanged,
    setPageChanged,
    setDataLoadedTill,
    setLastLoadedMission,
    sortBy,
    setSortBy,
    setFilterorSortTriggered,
  ];
};
