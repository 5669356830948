const checkCountryAvailable = async (firebase, country) => {
  const countryAvailable = await firebase
    .firestore()
    .collection("countryProfile")
    .where("country", "==", country)
    .get();
  return countryAvailable.docs;
};

export const createCountryProfile = async (firebase, data) => {
  try {
    const countryObj = {
      country: data?.country,
      status: data?.countryStatus,
    };
    const countrydata = await checkCountryAvailable(
      firebase,
      countryObj.country
    );

    if (countrydata[0]?.id) {
      firebase
        .firestore()
        .collection("countryProfile")
        .doc(countrydata[0].id)
        .update({
          status: countryObj.status,
        });
      return {
        status: "Success",
        data: { data: countryObj },
      };
    } else {
      await firebase.firestore().collection("countryProfile").add(countryObj);

      return {
        status: "Success",
        data: { data: countryObj },
      };
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getCountryProfile = async (firebase) => {
  let query = firebase.firestore().collection("countryProfile");
  return query.get().then((snapshot) => {
    const countries = snapshot.docs.reduce((acc, doc) => {
      const data = doc.data();
      acc.push({ ...data, id: doc.id });
      return acc;
    }, []);
    return {
      countries,
    };
  });
};
