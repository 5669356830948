import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../theme";
import { Link } from "gatsby";

const styles = {
  welcomeBox: {
    display: { xs: "block", sm: "flex" },
    justifyContent: "space-between",
  },
  contactUsBox: {
    lineHeight: 1.5,
    padding: { xs: "20px 0", sm: "30px 0" },
  },
  contactText: {
    color: theme.palette.secondary.main,
  },
  textOneStyle: {
    lineHeight: 1,
  },
};

const OverviewHeader = ({
  headingTextOne,
  headingTextTwo,
  subText,
  actionText,
  actionLink,
}) => {
  return (
    <Box sx={styles.welcomeBox}>
      <Box>
        {headingTextOne && (
          <Typography variant="h4" sx={styles.textOneStyle}>
            {headingTextOne}
          </Typography>
        )}
        {headingTextTwo && (
          <Typography variant="h2">{headingTextTwo}</Typography>
        )}
      </Box>
      <Box sx={styles.contactUsBox}>
        <Typography variant="body2">{subText}</Typography>
        <Typography variant="body2" style={styles.contactText}>
          {actionLink ? (
            <Link to={actionLink}>{actionText}</Link>
          ) : (
            <>{actionText}</>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

OverviewHeader.propTypes = {
  headingTextOne: PropTypes.string.isRequired,
  headingTextTwo: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  actionLink: PropTypes.string,
};

OverviewHeader.defaultProps = {
  actionLink: undefined,
};

export default OverviewHeader;
