import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getBankDetail } from "../services/bank-details";

const useGetNews = (id) => {
  const [bankDetails, setBankDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    getBankDetail(id)
      .then((data) => {
        setBankDetails(data?.bank_details);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [bankDetails, loading];
};

export default useGetNews;
