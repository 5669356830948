import React, { useState } from "react";
import Layout from "../../../../Layout";
import PropTypes from "prop-types";
import { selfDeleteClient } from "../../../../services/client";
import firebase from "src/firebase";
import { getUser } from "../../../../services/auth";
import { userRoles } from "../../../../lib/constants";
import DeleteUserPopUp from "../../Client/Profile/DeleteUserPopUp";
import ClientProfileContainer from "./ClientProfileContainer";
import ArrowHeaderContainer from "../../NewDataLibrary/components/ArrowHeaderContainer";
import { navigate } from "gatsby";
import { useSnackbar } from "notistack";

const SinglePageClientInfo = ({ clientId }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const currentUser = getUser("user");

  const deleteUser = async () => {
    const status = await selfDeleteClient(firebase, clientId);
    if (status.status === "Success") {
      setDeleteModal(false);
      enqueueSnackbar(status.message, {
        variant: "success",
      });
    } else {
      setDeleteModal(false);
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  };

  return (
    <Layout hasSideMenu addBottomPadding>
      <ArrowHeaderContainer
        header={
          currentUser?.role === userRoles.admin
            ? "Client info"
            : "manage profile"
        }
      />

      <ClientProfileContainer
        clientId={clientId}
        setDeleteModal={setDeleteModal}
      />
      <DeleteUserPopUp
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        deleteUser={deleteUser}
      />
    </Layout>
  );
};

SinglePageClientInfo.propTypes = {
  clientId: PropTypes.string,
};
SinglePageClientInfo.defaultProps = {
  clientId: undefined,
};

export default SinglePageClientInfo;
