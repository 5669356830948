import React from "react";
import theme from "../../../theme";
import { Typography, Box, styled } from "@mui/material";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { fontWeightFive } from "../../../lib/styleConstants";
// import { claculateProgress } from "../../../services/clientProfile";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  width: "40%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const style = {
  hundred: {
    textAlign: "end",
    color: theme.palette.grey.white,
  },
  subProgress: {
    width: "100%",
    alignSelf: "center",
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.grey.white,
    },
  },
};

const ClientProfileProgressBar = ({ progress }) => {
  // useEffect(() => {
  //   let profilePercent = claculateProgress(userData);
  //   setProgress(profilePercent);
  // }, [userData]);

  return (
    <>
      <Box>
        <Typography sx={{ ...style.hundred, ...fontWeightFive }}>
          {Math.round(progress)} %
        </Typography>
        <BorderLinearProgress
          variant="determinate"
          value={progress}
          sx={style.subProgress}
        />
      </Box>
    </>
  );
};
ClientProfileProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  setProgress: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};

export default ClientProfileProgressBar;
