import PropTypes from "prop-types";
import React from "react";
import AppButton from "../../../../global/elements/AppButton";
import { navigate } from "gatsby";
import { contactSupportUrl } from "../../../../../lib/constants";
import DashboardBanner from "./DashboardBanner";

const AcceptTermsBanner = ({ onClick }) => {
  const contactSales = () => {
    navigate(contactSupportUrl);
  };

  return (
    <DashboardBanner
      heading="To fully use the GLOBHE marketplace please accept the terms and conditions. "
      subHeading="Contact us if you have questions or concerns."
    >
      <AppButton
        look="ghost"
        label="Contact Sales"
        noIcon
        onClick={contactSales}
      />
      <AppButton
        look="black"
        label="Accept Terms and Conditions"
        onClick={onClick}
      />
    </DashboardBanner>
  );
};

AcceptTermsBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AcceptTermsBanner;
