import { Grid, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import { flexible } from "../../../../lib/styleConstants";

const styles = {
  headingContainer: {
    display: "flex",
  },
  boxArray: {
    padding: "0px 5px",
    border: `2px solid ${theme.palette.grey.deepWarmGrey}`,
    borderRadius: "5px",
    margin: "2px 3px",
    color: theme.palette.grey.deepWarmGrey,
  },
};

const ProfileInfoHandler = ({
  icon,
  heading,
  value,
  valueArray,
  multiValue,
  boolValue,
  boolean,
}) => {
  return (
    <Grid container xs={12}>
      <Grid item sx={styles.headingContainer} xs={12}>
        {icon}
        <Typography variant="h6" ml={0.5}>
          {heading}
        </Typography>
      </Grid>
      {multiValue ? (
        <Grid item xs={12} ml={3} sx={flexible}>
          {valueArray && valueArray.length > 0 && typeof valueArray != "string"
            ? valueArray?.map((val, i) => (
                <Typography variant="body1" key={i} sx={styles.boxArray}>
                  {val}
                </Typography>
              ))
            : typeof valueArray == "string"
            ? valueArray
            : "_"}
        </Grid>
      ) : boolean ? (
        <Grid item xs={12} ml={3}>
          <Typography variant="body1">{boolValue ? "Yes" : "No"} </Typography>
        </Grid>
      ) : (
        <Grid item xs={12} ml={3}>
          <Typography variant="body1">{value}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

ProfileInfoHandler.propTypes = {
  multiValue: PropTypes.bool,
  valueArray: PropTypes.array,
  value: PropTypes.string,
  heading: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  boolValue: PropTypes.string,
  boolean: PropTypes.bool,
};

ProfileInfoHandler.defaultProps = {
  multiValue: false,
  valueArray: [],
  value: "",
  boolValue: undefined,
  boolean: false,
};
export default ProfileInfoHandler;
