import PropTypes from "prop-types";
import React from "react";
import firebase from "src/firebase";
import { Box, Typography } from "@mui/material";
import { useGetInterestList } from "../../../hooks/useGetInterestList";
import {
  interestInviteStatus,
  missionListSource,
  OrderStatus,
  missionStatus,
  PilotPackageStatus,
  orderLogStatus,
  pilotInviteType,
} from "../../../lib/constants";
import UniversalLoader from "../../global/elements/UniversalLoader";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import { updateMission } from "./../../../services/missions";
import {
  missionTypeConstant,
  statusAlertSeverity,
} from "../../../lib/constants";
import { createContract } from "../../../services/contracts";
import { updateInterest } from "../../../services/interest-invite";
// import { maskFirstAndLastChar } from "../../../lib/helpers";
import MissionPilotsList from "../missions/MissionPilotsList";
import { createOrderStatus } from "../../../services/orderStatus";
import { assignPilotToProject } from "../../../services/project";
import {
  getCurrentDateTime,
  getCurrentDateTimeMillis,
} from "../../../lib/helpers";

const styles = {
  missionContainer: {
    width: "100%",
    padding: "0px",
  },
  unAuthorized: {
    width: "100%",
    marginTop: "10rem",
  },
  loaderDiv: {
    paddingTop: "18%",
  },
};

const InterestInviteList = ({
  missionId,
  columnForPilot,
  mission,
  refreshPage,
  pilotID,
}) => {
  const [authorized, filterInterestList] = useGetInterestList(
    interestInviteStatus.pilotRequested,
    missionId,
    pilotID
  );

  const assignMission = async (data) => {
    try {
      const pilotId = data.pilot.id;
      const pilotName = data.pilot.pilotName;
      let sentToPilotsInfo = [];
      const currentDate = getCurrentDateTimeMillis();
      mission?.sentToPilotsInfo?.forEach((pilotInfo) => {
        if (pilotInfo.pilotId === data.pilot.id) {
          sentToPilotsInfo.push({
            invitedDate: pilotInfo?.invitedDate,
            pilotId: pilotInfo?.pilotId,
            responseDate: pilotInfo?.responseDate || null,
            adminResponseToAccept: getCurrentDateTime(),
            initiallyRejected: pilotInfo.initiallyRejected || false,
            status: pilotInviteType.accepted,
          });
        } else {
          sentToPilotsInfo.push(pilotInfo);
        }
      });

      let updates = {
        assignedPilot: pilotId,
        assignedPilotName: pilotName,
        status: missionStatus.active,
        orderStatus: OrderStatus.active,
        pilotPackageStatus: PilotPackageStatus.open,
        sentToPilotsInfo: sentToPilotsInfo,
        invitingPilotToMissionOn:
          mission.invitingPilotToMissionOn || currentDate,
        pilotAssignedToMissionOn: currentDate,
        contractCreatedForPilotOn: currentDate,
      };

      if (mission.allowPilotBidding) {
        updates.pilotPrice = data.price;
      }

      await updateMission(firebase, missionId, updates);

      //await assignPilotToMission(firebase, pilotId, pilotName, missionId);

      const interstUpdate = {
        status: interestInviteStatus.adminApproved,
      };
      await updateInterest(firebase, data.id, interstUpdate);

      let obj = {
        missionId: missionId,
        status: orderLogStatus.pilotAssigned,
      };
      createOrderStatus(firebase, obj)
        .then(() => {})
        .catch((e) => {
          console.log("Error", e);
        });

      if (mission.missionType === missionTypeConstant.clientOrder) {
        const contractData = {
          missionId: mission?.id,
          missionName: mission?.missionName,
          pilotId: pilotId,
          pilotName: pilotName,
          missionDeadline: mission?.deadline,
          pilotDeadline: mission?.deadline,
        };
        await createContract(contractData);
      }
      if (mission.missionType === missionTypeConstant.projectMission) {
        assignPilotToProject(firebase, mission?.projectIds, pilotId);
      }
      if (refreshPage) {
        refreshPage();
      }
    } catch (e) {
      console.log("Error", e);
    }
  };
  return (
    <section>
      {authorized == true ? (
        <Box sx={styles.missionContainer}>
          {filterInterestList && filterInterestList?.length > 0 ? (
            <MissionPilotsList
              missionData={filterInterestList}
              columnForPilot={columnForPilot}
              source={missionListSource.interest}
              assignMission={(data) => assignMission(data)}
              mission={mission}
            />
          ) : (
            <Box>
              <Typography align="center" variant="h5">
                No drone operator has shown interest yet
              </Typography>
            </Box>
          )}
        </Box>
      ) : authorized == false ? (
        <Box sx={styles.unAuthorized}>
          <StatusAlertContainer
            heading="Unauthorized access! Your admin type does not allow you to work with missions. 
            Please contact Napoleon to change your access rights."
            severity={statusAlertSeverity.error}
            coloredBackground
          />
        </Box>
      ) : (
        <Box sx={styles.loaderDiv}>
          <UniversalLoader />
        </Box>
      )}
    </section>
  );
};

InterestInviteList.propTypes = {
  missionId: PropTypes.string.isRequired,
  mission: PropTypes.object.isRequired,
  columnForPilot: PropTypes.array.isRequired,
  refreshPage: PropTypes.func,
  pilotID: PropTypes.string,
};

InterestInviteList.defaultProps = {
  refreshPage: undefined,
  pilotID: undefined,
};

export default InterestInviteList;
