import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { updatePilotNote, getPilotNote } from "src/services/pilots";
import SectionLoadingIndicator from "src/components/global/SectionLoadingIndicator";
import theme from "../../../theme";
import { Box, Typography } from "@mui/material";
import MultiLineTextArea from "../../global/elements/TypographyElements/MultiLineTextArea";

const style = {
  root: {
    width: "100%",
    height: "100%",
  },
  meta: {
    marginTop: "0.5rem",
    fontSize: "0.7rem",
    color: theme.palette.grey.warmGrey,
  },
  notesLabel: {
    display: "block",
    color: theme.palette.adminBlue.main,
  },
  left: {
    float: "left !important",
  },
};
const PilotNote = ({ pilotId }) => {
  const [savingNote, setSavingNote] = useState(false);
  const [note, setNote] = useState({ note: null, dateUpdated: null });
  const [loadingNote, setLoadingNote] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    getPilotNote(firebase, pilotId)
      .then((note) => {
        if (note) {
          setNote(note);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingNote(false));
  }, [pilotId]);

  const onNoteBlur = (e) => {
    const newNote = e.target.value;
    if (newNote === note.note) return;

    setSavingNote(true);
    updatePilotNote(firebase, pilotId, newNote)
      .then(() => {
        setNote({
          ...note,
          note: newNote,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setSavingNote(false));
  };
  return (
    <Box sx={style.root}>
      <Typography variant="h5" style={style.notesLabel}>
        Admin Notes
      </Typography>
      <br />
      {loadingNote ? (
        <SectionLoadingIndicator align={style.left} />
      ) : (
        <MultiLineTextArea
          onBlur={onNoteBlur}
          defaultValue={note.note}
          placeholder="Enter note..."
          variant="plain"
          color={theme.palette.adminBlue.paleBlue}
        />
      )}
      <Box sx={style.meta}>
        {savingNote && <Typography variant="body1">Saving...</Typography>}
      </Box>
    </Box>
  );
};

PilotNote.propTypes = {
  pilotId: PropTypes.string.isRequired,
};

export default PilotNote;
