import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import theme from "../../../../../theme";
import MissionContext from "./MissionContext";
import OrderMenu from "./OrderMenu";
import {
  getCountryName,
  getCurrentDateTimeMillis,
  getFileSizeInUnits,
  getOrderType,
  getOrderTypeName,
} from "../../../../../lib/helpers";
import { getTabBgColor } from "../../../../../lib/styleConstants";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EditRoadSharpIcon from "@mui/icons-material/EditRoadSharp";
import SingleMultipleCheckbox from "../../../../global/FormInputComponents/SingleMultipleCheckbox";
import {
  missionFeasibility,
  missionFeasibilityArr,
  missionPlanningType,
  missionTypeConstant,
  notificationType,
  siteNotFeasibleReason,
} from "../../../../../lib/constants";
import { Form, Formik } from "formik";
import { preventEnterSubmit } from "src/lib/form-helpers";
import TextFieldInput from "../../../../global/FormInputComponents/TextFieldInput";
import FileDropzone from "../../../../global/FormInputComponents/FileDropzone";
import { getUser } from "../../../../../services/auth";
import { uploadAFile } from "../../../../../services/project";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import AppButton from "../../../../global/elements/AppButton";
import UploadFileInfoContainer from "../../../../global/elements/UploadFileInfoContainer";
import * as Yup from "yup";
import {
  createMissionPlanning,
  createMissionPlanningObj,
  deleteMissionPlanningPhoto,
} from "../../../../../services/missions";
import AppContext from "../../../../../contexts/AppContext";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField as MuiTextField } from "@mui/material";
import SingleLocationMap from "../../../CreateOrder/Components/SingleLocationMap";
import AutoCompleteInput from "../../../../global/FormInputComponents/AutoCompleteInput";
import { createNotification } from "../../../../../services/notification";

const styles = {
  greyText: {
    color: theme.palette.grey.warmGrey,
  },
  number: {
    width: "fit-content !important",
  },
  text: {
    margin: "auto",
    marginLeft: "10px",
  },
  typeBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1em",
  },
  textMargin: {
    marginBottom: "0 !important",
  },
  checkBox: {
    "& svg": {
      fontSize: "xxx-large",
    },
  },
  uploadProofBtn: {
    width: "100% !important",
    justifyContent: "center",
  },
  adjustMargin: {
    margin: "auto",
  },
  contentPlacement: {
    textAlign: "right",
  },
  formBtn: {
    textAlign: "center",
    marginLeft: "20px",
  },
};

const MissionPlanningItems = ({ tabValue }) => {
  const [docLoading, setDocLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [showReschedule, setShowReschedule] = useState(false);
  const missionContextData = useContext(MissionContext);
  const [photoUrls, setPhotoUrls] = useState(
    missionContextData?.missionPlanning?.data?.photoProof || []
  );

  const { enqueueSnackbar } = useSnackbar();
  const appContext = useContext(AppContext);
  const missionPlanningData = missionContextData?.missionPlanning?.data;
  const missionData = missionContextData?.mission;
  // const missionPlanningCurrentStatus = missionContextData.missionPlanning.status;
  const isMissionFeasibleCurrent = missionPlanningData
    ? missionPlanningData?.missionFeasible == missionFeasibility.yes
    : true;

  const postFlightStatus =
    missionPlanningData &&
    missionPlanningData.type === missionPlanningType.postFlight
      ? missionPlanningData.ableToCaptureData
      : true;

  const onSameTab = missionPlanningData?.type === tabValue;
  const initialValues = {
    missionFeasibilityYes:
      tabValue === missionPlanningType.preFlight
        ? isMissionFeasibleCurrent
        : postFlightStatus,
    missionFeasibilityNo:
      tabValue === missionPlanningType.preFlight
        ? missionPlanningData?.missionFeasible == missionFeasibility.no
        : !postFlightStatus,
    detailedReason: onSameTab
      ? missionPlanningData?.detailedReason || null
      : null,
    selectedReason: onSameTab
      ? missionPlanningData?.selectedReason || null
      : null,
    currentFlightDate: onSameTab
      ? missionPlanningData?.currentFlightDate || null
      : null,
    photoProof: onSameTab ? missionPlanningData?.photoProof || [] : [],
    reschedulingReason: onSameTab
      ? missionPlanningData?.reschedulingReason || null
      : null,
  };

  // const postFlightIssueEmailAction = (dataToSend) => {
  //   const adminPostFlightIssueEmail = firebase
  //     .functions()
  //     .httpsCallable("adminPostFlightIssueEmail");

  //   adminPostFlightIssueEmail(dataToSend);

  //   const clientPostFlightIssueEmail = firebase
  //     .functions()
  //     .httpsCallable("clientPostFlightIssueEmail");

  //   clientPostFlightIssueEmail(dataToSend);
  // };

  // const preFlightIssueEmailAction = (dataToSend) => {
  //   const adminMissionNotFeasibleMail = firebase
  //     .functions()
  //     .httpsCallable("adminMissionNotFeasibleMail");

  //   adminMissionNotFeasibleMail(dataToSend);

  //   const clientMissionNotFeasibleMail = firebase
  //     .functions()
  //     .httpsCallable("clientMissionNotFeasibleMail");

  //   clientMissionNotFeasibleMail(dataToSend);
  // };

  const onSubmit = async (values) => {
    if (values?.missionFeasibilityYes || values?.missionFeasibilityNo) {
      const status = await createMissionPlanningObj(
        tabValue,
        values,
        missionPlanningData,
        missionData,
        showReschedule
      );
      console.log("Status", status);
      if (status.status === "Success") {
        missionContextData.refreshMission();
        enqueueSnackbar(`Response submitted successfully.`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`Error in submitting the response.`, {
          variant: "error",
        });
      }

      // const data = values;
      // if (tabValue == missionPlanningType.preFlight) {
      //   data.type = missionPlanningType.preFlight;
      // } else {
      //   data.type = missionPlanningType.postFlight;
      //   data.missionFeasible = missionPlanningData?.missionFeasible;
      //   data.currentFlightDate = missionPlanningData?.currentFlightDate;
      //   data.prevFlightDate = missionPlanningData?.prevFlightDate;
      // }
      // if (values.missionFeasibilityYes) {
      //   data.missionFeasible = missionFeasibility?.yes;
      //   data.detailedReason = null;
      //   data.selectedReason = null;
      //   data.photoProof = [];
      //   if (tabValue == missionPlanningType.postFlight) {
      //     data.ableToCaptureData = true;
      //     createNotification(
      //       firebase,
      //       notificationType.adminNotification,
      //       "Data has been captured by operator and will upload soon.",
      //       "Data has been captured by operator and will upload soon.",
      //       `/app/missions/${missionData.id}`
      //     );
      //     createNotification(
      //       firebase,
      //       missionData.clientId,
      //       "Data has been captured by operator and will upload soon.",
      //       "Data has been captured by operator and will upload soon.",
      //       `/app/client/orderDetails/${missionData.id}`
      //     );
      //   } else if (tabValue == missionPlanningType.preFlight) {
      //     createNotification(
      //       firebase,
      //       notificationType.adminNotification,
      //       "Order has been marked as feasible by the operator.",
      //       "Order has been marked as feasible by the operator.",
      //       `/app/missions/${missionData.id}`
      //     );
      //     createNotification(
      //       firebase,
      //       missionData.clientId,
      //       "Order has been marked as feasible by the operator.",
      //       "Order has been marked as feasible by the operator.",
      //       `/app/client/orderDetails/${missionData.id}`
      //     );
      //   }
      //   if (showReschedule) {
      //     data.prevFlightDate = missionPlanningData?.currentFlightDate || null;
      //     createNotification(
      //       firebase,
      //       notificationType.adminNotification,
      //       "Order has been rescheduled by the operator.",
      //       "Order has been rescheduled by the operator.",
      //       `/app/missions/${missionData.id}`
      //     );
      //     createNotification(
      //       firebase,
      //       missionData.clientId,
      //       "Order has been rescheduled by the operator.",
      //       "Order has been rescheduled by the operator.",
      //       `/app/client/orderDetails/${missionData.id}`
      //     );
      //   }
      // } else if (values.missionFeasibilityNo) {
      //   if (tabValue == missionPlanningType.preFlight) {
      //     data.missionFeasible = missionFeasibility?.no;
      //     data.currentFlightDate = null;
      //     if (missionData?.missionType === missionTypeConstant.clientOrder) {
      //       const dataToSend = {
      //         missionName: missionData?.missionName,
      //         missionId: missionData?.id,
      //         clientName: missionData?.clientName,
      //         operatorName: missionData?.assignedPilotName,
      //         issue: data?.selectedReason,
      //         detailedDescription: data?.detailedReason,
      //         email: missionData?.clientEmail,
      //       };
      //       preFlightIssueEmailAction(dataToSend);
      //     }
      //     createNotification(
      //       firebase,
      //       notificationType.adminNotification,
      //       "Order has marked as not feasible by the drone operator.",
      //       "Order has marked as not feasible by the drone operator.",
      //       `/app/missions/${missionData.id}`
      //     );
      //     createNotification(
      //       firebase,
      //       missionData.clientId,
      //       "Order has marked as not feasible by the drone operator.",
      //       "Order has marked as not feasible by the drone operator.",
      //       `/app/client/orderDetails/${missionData.id}`
      //     );
      //   } else {
      //     data.ableToCaptureData = false;
      //     if (missionData?.missionType === missionTypeConstant.clientOrder) {
      //       const dataToSend = {
      //         missionName: missionData?.missionName,
      //         id: missionData?.id,
      //         clientName: missionData?.clientName,
      //         operatorName: missionData?.assignedPilotName,
      //         issue: data?.selectedReason,
      //         detailedDescription: data?.detailedReason,
      //         email: missionData?.clientEmail,
      //       };
      //       postFlightIssueEmailAction(dataToSend);
      //     }
      //     createNotification(
      //       firebase,
      //       notificationType.adminNotification,
      //       "Operator is having issues in collecting data for the order.",
      //       "Operator is having issues in collecting data for the order",
      //       `/app/missions/${missionData.id}`
      //     );
      //     createNotification(
      //       firebase,
      //       missionData.clientId,
      //       "Operator is having issues in collecting data for the order.",
      //       "Operator is having issues in collecting data for the order.",
      //       `/app/client/orderDetails/${missionData.id}`
      //     );
      //   }
      // } else {
      //   data.missionFeasible = missionFeasibility?.review;
      // }
      // const status = await createMissionPlanning(
      //   data,
      //   appContext?.userData?.id,
      //   missionContextData?.mission?.id
      // );
      // if (status.status === "Success") {
      //   missionContextData.refreshMission();
      //   enqueueSnackbar(`Response submitted successfully.`, {
      //     variant: "success",
      //   });
      // } else {
      //   enqueueSnackbar(`Error in submitting the response.`, {
      //     variant: "error",
      //   });
      // }
    } else {
      enqueueSnackbar(`Please select atleast one option!`, {
        variant: "error",
      });
    }
  };

  const handleProofUpload = async (files, setFieldValue) => {
    try {
      setDocLoading(true);
      let userId = getUser("user")?.userID || "";
      // const file = files[0];
      if (!files) return;

      return Promise.all(
        files.map(async (file) => {
          return new Promise((resolve) => {
            let timeStamp = getCurrentDateTimeMillis();
            let path = `missionPlanningProof/${userId}/${timeStamp}/${file.name}`;
            resolve(
              uploadAFile(firebase, file, path, setPercent).then(
                (fileUploadStatus) => {
                  return {
                    id: timeStamp,
                    fileName: file.name,
                    fileSize: getFileSizeInUnits(file.size),
                    fileUrl: fileUploadStatus?.fileURL,
                  };
                }
              )
            );
          });
        })
      ).then((snapshot) => {
        setFieldValue("photoProof", [...photoUrls, ...snapshot]);
        setPhotoUrls([...photoUrls, ...snapshot]);
        setDocLoading(false);
      });
    } catch (err) {
      console.log("Error", err);
      enqueueSnackbar(`Error in uploading images.`, {
        variant: "error",
      });
    }
  };

  const deleteFile = async (fileId, setFieldValue) => {
    // let findPhoto = photoUrls.filter((photo) => photo.id === fileId);
    let remainingPhoto = photoUrls.filter((photo) => photo.id !== fileId);
    setPhotoUrls(remainingPhoto);
    setFieldValue("photoProof", remainingPhoto);
    if (missionPlanningData) {
      deleteMissionPlanningPhoto(
        missionPlanningData?.missionId,
        missionPlanningData?.id,
        remainingPhoto
      ).then((status) => {
        if (status?.status === "Success") {
          enqueueSnackbar(`Photo deleted successfully.`, {
            variant: "success",
          });
          missionContextData.refreshMission();
        } else {
          enqueueSnackbar(`Error in deleting photos.`, {
            variant: "error",
          });
        }
      });
    }
  };
  const validationSchema = Yup.object().shape({
    missionFeasibilityYes: Yup.bool(),
    missionFeasibilityNo: Yup.bool(),
    selectedReason: Yup.string()
      .nullable()
      .when("missionFeasibilityNo", {
        is: true,
        then: Yup.string().nullable().required("Please select an issue"),
      }),
    detailedReason: Yup.string().nullable(),
    photoProof: Yup.array().nullable(),
    currentFlightDate: Yup.date()
      .nullable()
      .when(["missionFeasibilityYes"], (missionFeasibilityYes) => {
        if (
          missionFeasibilityYes &&
          tabValue === missionPlanningType.preFlight
        ) {
          return Yup.date()
            .nullable()
            .required("Please enter a flying date")
            .max(
              missionData.deadline,
              "Flying date should not cross the deadline"
            );
        }
      }),
    reschedulingReason: Yup.string()
      .nullable()
      .when(["missionFeasibilityYes"], (missionFeasibilityYes) => {
        if (missionFeasibilityYes && showReschedule) {
          return Yup.string()
            .nullable()
            .required("Rescheduling reason is required");
        }
      }),
  });

  return (
    <Grid container xs={12} spacing={2}>
      <Grid container xs={12} md={6}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {tabValue == missionPlanningType.preFlight
              ? "Assess feasibility of the mission:"
              : "Post flight reporting"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={styles.greyText} variant="subtitle1Med">
            {tabValue == missionPlanningType.preFlight
              ? `Please review the mission and take action based on your review that
          this mission is feasible or not`
              : "Are you able to find the site and able to fly the drone."}
          </Typography>
        </Grid>

        <Box mt={2}>
          <OrderMenu
            title="Order Type"
            content={getOrderType(missionContextData?.mission)}
            contentRight={getOrderTypeName(missionContextData?.mission)}
          />
        </Box>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} mt={3}>
          <Box>
            <Typography variant="h6" sx={styles.text}>
              {missionContextData?.mission?.missionName}
            </Typography>
            <Typography variant="body1" sx={styles.text}>
              {missionContextData?.mission?.id}
            </Typography>
          </Box>
        </Grid>
        <Grid item container xs={12} mt={3}>
          <Grid item xs={6} sx={styles.typeBox}>
            <LocationOnOutlinedIcon />
            <Typography variant="body1" sx={styles.textMargin} ml={1}>
              {getCountryName(missionContextData?.mission)}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={styles.typeBox}>
            <EditRoadSharpIcon />
            <Typography variant="body1" sx={styles.textMargin} ml={1}>
              {missionContextData?.mission?.areaSize?.toFixed(2)} sq km
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} mt={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              // style={styles.formikWidth}
            >
              {({ values, setFieldValue, errors }) => {
                return (
                  <Form id="siteStatus" onKeyDown={preventEnterSubmit}>
                    <Grid item>
                      <Typography variant="h5">
                        {tabValue == missionPlanningType.preFlight
                          ? "Is this mission feasible?"
                          : "Were you able to access the site and fly?"}
                      </Typography>
                    </Grid>
                    <Grid xs={12} item sx={styles.checkBox}>
                      <SingleMultipleCheckbox
                        checkboxFields={missionFeasibilityArr}
                        values={values}
                        multiple={false}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    {values.missionFeasibilityNo && (
                      <>
                        <Grid item xs={12} mt={2}>
                          <AutoCompleteInput
                            // setFieldValue={setFieldValue}
                            options={siteNotFeasibleReason}
                            label={"Select the issue"}
                            name={"selectedReason"}
                            // type="text"
                            // autoComplete={true}
                            // autoCompleteArray={siteNotFeasibleReason}
                            required={true}
                            multiple={false}
                          />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          <TextFieldInput
                            setFieldValue={setFieldValue}
                            values={values}
                            label={"If necessary add explanation"}
                            name={"detailedReason"}
                            type="text"
                            rows={3}
                            multiline={3}
                          />
                        </Grid>
                        <Grid item container xs={12} mt={2}>
                          <Grid item xs={7} sx={styles.adjustMargin}>
                            <Typography variant="subtitle1Med">
                              Can you upload a proof of the issue?
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <FileDropzone
                              onDrop={(files) =>
                                handleProofUpload(files, setFieldValue)
                              }
                              acceptedFileTypes={[
                                "image/jpeg",
                                ".jpg",
                                ".png",
                                ".jpeg",
                                ".gif",
                                // "application/pdf",
                              ]}
                              name={"photoProof"}
                              label="Upload proof"
                              look="black"
                              addtionalStyle={styles.uploadProofBtn}
                              // fileUrl={clientInoviceUrl}
                              docLoading={docLoading}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} mt={3}>
                          {photoUrls?.length > 0 && (
                            <Grid item xs={12} mb={2}>
                              <Typography variant="h5">
                                Images Uploaded
                              </Typography>
                            </Grid>
                          )}

                          {photoUrls?.map((photoUrl, i) => {
                            return (
                              <UploadFileInfoContainer
                                key={i}
                                fileName={photoUrl.fileName}
                                fileUrl={photoUrl.fileUrl}
                                fileSize={photoUrl.fileSize}
                                fileId={photoUrl.id}
                                deleteFile={(fileId) =>
                                  deleteFile(fileId, setFieldValue)
                                }
                                title="Images uploaded"
                              />
                            );
                          })}
                        </Grid>
                      </>
                    )}
                    {values.missionFeasibilityYes &&
                      tabValue == missionPlanningType.preFlight && (
                        <>
                          {missionPlanningData?.missionFeasible ==
                            missionFeasibility.yes && !showReschedule ? (
                            <>
                              <Typography variant="h5" mt={3} mb={1}>
                                Flying date
                              </Typography>
                              <Grid container>
                                <Grid item xs={8}>
                                  <Typography variant="body1" mb={1} mt={2}>
                                    {missionPlanningData?.currentFlightDate}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sx={styles.contentPlacement}>
                                  <AppButton
                                    label="Reschedule"
                                    look="inverted"
                                    onClick={() => setShowReschedule(true)}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Typography variant="h5" mt={3} mb={1}>
                                Select your flying date
                              </Typography>
                              <DatePicker
                                inputFormat="dd-MM-yyyy"
                                fullWidth
                                disablePast
                                onChange={(value) => {
                                  setFieldValue("currentFlightDate", value);
                                }}
                                value={values.currentFlightDate}
                                renderInput={(params) => (
                                  <MuiTextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    name="currentFlightDate"
                                    label="Flying date"
                                    error={Boolean(errors.currentFlightDate)}
                                    helperText={errors.currentFlightDate}
                                  />
                                )}
                              />
                              {showReschedule && (
                                <>
                                  <Typography variant="h5" mt={3} mb={1}>
                                    Enter a reason for rescheduling
                                  </Typography>
                                  <TextFieldInput
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    label={"Enter a reason for rescheduling"}
                                    name={"reschedulingReason"}
                                    type="text"
                                    rows={3}
                                    required={true}
                                    multiline={3}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    <Grid item xs={12} mt={3}>
                      <Divider />
                    </Grid>
                    {(!(
                      missionPlanningData?.missionFeasible ===
                        missionFeasibility.yes &&
                      !showReschedule &&
                      tabValue === missionPlanningType.preFlight
                    ) ||
                      values.missionFeasibilityNo) && (
                      <Grid item xs={12} mt={3} sx={styles.contentPlacement}>
                        <AppButton
                          label="Cancel"
                          look="inverted"
                          noIcon
                          addtionalStyle={styles.formBtn}
                          onClick={() => setShowReschedule(false)}
                        />
                        <AppButton
                          label="Save"
                          look="purple"
                          type="submit"
                          form="siteStatus"
                          noIcon
                          addtionalStyle={styles.formBtn}
                        />
                      </Grid>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SingleLocationMap
          orderType={missionContextData?.mission?.orderType}
          locationMap={missionContextData?.mission?.locationMap}
          locationLabel={missionContextData?.mission?.locationLabel}
          areaSize={missionContextData?.mission?.areaSize}
          showOrderType={false}
        />
      </Grid>
    </Grid>
  );
};

MissionPlanningItems.propTypes = {
  tabValue: PropTypes.string.isRequired,
};

export default MissionPlanningItems;
