import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { Box, TextField, InputAdornment, Typography } from "@mui/material";
import PropTypes from "prop-types";
import {
  colorStatus,
  columnForProjectQuotesAdminNew,
  columnForProjectQuotesClientNew,
  projectStatus,
  quoteStatus,
  userRoles,
} from "../../../../lib/constants";
import theme from "../../../../theme";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash.debounce";
import AppButton from "../../../global/elements/AppButton";
import Dialogue from "../../../global/Dialogue";
import { useSnackbar } from "notistack";
import StatusBadge from "../../../global/elements/StatusBadge";
import { statusWrapper } from "../../../../lib/styleConstants";
import OrderQuotesTable from "../../admin/OrderQuotesTable";
import QuoteContext from "../projectDetail/QuotesTab/QuoteContext";
import RejectQuotePopup from "../../quotes/RejectQuotePopup";

const styles = {
  outerDivBackground: {
    backgroundColor: theme.palette.grey.white,
    padding: {
      xs: "20px 10px 20px 10px",
      sm: "20px 10px 20px 30px",
      md: "20px 30px 20px 40px",
    },
  },
  searchBoxOuterDiv: { display: "flex", width: "100%" },
  textFieldCls: {
    width: "100%",
    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: `${theme.palette.black.ferry} !important`,
    },
    "& fieldset": {
      border: `0.5px solid ${theme.palette.black.darkSeaBlack} !important`,
    },
  },
  btnDisplay: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    rowGap: "1em",
    columnGap: "1em",
  },
  confirmPopupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
};

const ProjectQuoteTable = ({ role, updateQuoteStatus, project }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(null);
  const [quote, setQuote] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [dataToShow, setDataToShow] = useState([]);
  const [allData, setAllData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(5);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPageNumber, setLastPageNumber] = useState(null);
  const [rejectQuotePopup, setRejectQuotePopup] = useState(false);

  const data = useContext(QuoteContext);

  useEffect(() => {
    setAllData(data);
  }, [data]);

  useEffect(() => {
    setInitialData();
  }, [limit, allData]);

  const setInitialData = () => {
    setDataToShow(allData?.slice(0, limit));
    let pageNumberNew = currentPageNumber;
    if (allData?.length <= pageNumberNew * limit) {
      setIsLastPage(true);
      setLastPageNumber(pageNumberNew);
    } else {
      setLastPageNumber(null);
    }
  };

  const valueChangeHandler = useCallback(
    (e) => {
      let searchText = e?.target?.value;

      if (searchText) {
        setCurrentPageNumber(1);

        const filteredRecords = allData.filter((record) => {
          const quoteName = record?.quoteName?.toLowerCase();
          return (
            record?.quoteSearchName?.some((name) =>
              name.includes(searchText.toLowerCase())
            ) || quoteName.includes(searchText.toLowerCase())
          );
        });
        const indexOfLastRecord = currentPageNumber * limit;
        const indexOfFirstRecord = indexOfLastRecord - limit;
        const currentRecords = filteredRecords.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        );
        if (filteredRecords?.length <= limit) {
          setIsLastPage(true);
          setLastPageNumber(1);
        } else {
          setLastPageNumber(null);
        }
        setDataToShow(currentRecords);
      } else {
        setInitialData();
      }
    },
    [allData, currentPageNumber, limit]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(valueChangeHandler, 500),
    [valueChangeHandler]
  );

  const paginationBtnClick = (type) => {
    let pageNumberNew = currentPageNumber;
    let createDataInstance = allData;
    if (type == "next") {
      if (!(lastPageNumber === currentPageNumber)) {
        pageNumberNew = pageNumberNew + 1;
        setDataToShow(
          createDataInstance.slice(
            (pageNumberNew - 1) * limit,
            limit * pageNumberNew
          )
        );
      }
    } else {
      if (pageNumberNew > 1) {
        pageNumberNew = pageNumberNew - 1;
        setDataToShow(
          createDataInstance.slice(
            (pageNumberNew - 1) * limit,
            limit * pageNumberNew
          )
        );
      }
    }
    if (allData?.length <= pageNumberNew * limit) {
      setIsLastPage(true);
      setLastPageNumber(pageNumberNew);
    } else {
      setIsLastPage(false);
      setLastPageNumber(null);
    }
    setCurrentPageNumber(pageNumberNew);
  };
  const limitHandler = (value) => {
    setIsLastPage(false);
    setLastPageNumber(null);
    setLimit(value);
  };

  const handlePopupOpen = ({ quote, status }) => {
    setStatus(status);
    setQuote(quote);
    setOpenPopup(true);
  };

  const updateQuote = (value) => {
    try {
      setSubmitting(true);
      let quoteNew = quote;
      if (status === quoteStatus.declined) {
        quoteNew.rejectReason = value.rejectReason;
      }
      updateQuoteStatus({ quote: quoteNew, status })
        .then((data) => {
          setSubmitting(false);
          setRejectQuotePopup(false);
          enqueueSnackbar(data?.message || "Quote added successfuly!", {
            variant: "success",
          });
        })
        .catch((err) => {
          setSubmitting(false);
          enqueueSnackbar(err?.message || "Error in adding quote!", {
            variant: "error",
          });
        });
    } catch (e) {
      console.log("Error", e);
    }
  };

  const ActiveProjectStatusBadge = ({ value }) => {
    ActiveProjectStatusBadge.propTypes = {
      value: PropTypes.object.isRequired,
    };
    return (
      <Box sx={statusWrapper}>
        <StatusBadge
          name={
            value.status == quoteStatus.quoteSent && role === userRoles.client
              ? "Declined"
              : value.status
          }
          status={
            value.status == quoteStatus.accepted
              ? colorStatus.green
              : value.status == quoteStatus.expired
              ? colorStatus.yellow
              : value.status == quoteStatus.declined
              ? colorStatus.red
              : role === userRoles.client &&
                value.status == quoteStatus.quoteSent
              ? colorStatus.red
              : colorStatus.neutral
          }
        />
      </Box>
    );
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "action":
        return (
          <>
            {value.status == quoteStatus.quoteSent &&
            project?.status == projectStatus.initial ? (
              <Box sx={styles.btnDisplay}>
                <AppButton
                  look="green"
                  onClick={() =>
                    handlePopupOpen({
                      quote: value,
                      status: quoteStatus.accepted,
                    })
                  }
                  label="Accept"
                />

                <AppButton
                  look="negative"
                  onClick={() => {
                    setRejectQuotePopup(true);
                    setStatus(quoteStatus.declined);
                    setQuote(value);
                  }}
                  label="Decline"
                />
              </Box>
            ) : (
              <ActiveProjectStatusBadge value={value} />
            )}
          </>
        );
      case "status":
        return (
          <>
            {project?.status == projectStatus.initial && (
              <Box sx={styles.statusWrapper}>
                <StatusBadge
                  name={value.status}
                  status={
                    value.status == quoteStatus.accepted
                      ? colorStatus.green
                      : value.status == quoteStatus.declined ||
                        value.status == quoteStatus.deleted
                      ? colorStatus.red
                      : value.status == quoteStatus.expired
                      ? colorStatus.yellow
                      : colorStatus.neutral
                  }
                />
              </Box>
            )}
            {project?.status != projectStatus.initial && (
              <ActiveProjectStatusBadge value={value} />
            )}
          </>
        );

      case "deleteQuote":
        return (
          <>
            {value["status"] === quoteStatus.quoteSent &&
            project?.status == projectStatus.initial ? (
              <Box sx={styles.btnDisplay}>
                <AppButton
                  look={"green"}
                  onClick={() =>
                    handlePopupOpen({
                      quote: value,
                      status: quoteStatus.deleted,
                    })
                  }
                  label="Delete"
                />
              </Box>
            ) : (
              <></>
            )}
          </>
        );

      default:
        return;
    }
  };

  const DialogBody = () => {
    return (
      <>
        {status == quoteStatus.accepted ? (
          <DialogBodyComp
            heading={"Are you sure you want to accept this quote?"}
            subHeading={
              "By clicking 'Yes', you confirm your acceptance of this quote. GLOBHE will proceed with engaging drone operators to capture your data as outlined."
            }
          />
        ) : status == quoteStatus.declined ? (
          <DialogBodyComp
            heading={"Decline Quotation"}
            subHeading={"Are you really sure to decline this quotation."}
          />
        ) : status == quoteStatus.deleted ? (
          <DialogBodyComp
            heading={"Delete Quote"}
            subHeading={
              "Once submitted, the quote will no longer available to client."
            }
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const DialogBodyComp = ({ heading, subHeading }) => {
    return (
      <Box sx={styles.confirmPopupCls}>
        {heading && <Typography variant="h6">{heading}</Typography>}
        {subHeading && (
          <Typography variant="subtitle1">{subHeading}</Typography>
        )}
      </Box>
    );
  };

  DialogBodyComp.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
  };

  DialogBodyComp.defaultProps = {
    heading: "",
    subHeading: "",
  };

  return (
    <Box sx={styles.outerDivBackground}>
      <Box sx={styles.searchBoxOuterDiv}>
        <TextField
          id="search"
          margin="normal"
          sx={styles.textFieldCls}
          placeholder={"Enter keyword to filter"}
          onChange={(e) => {
            e.persist();
            debouncedChangeHandler(e);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <OrderQuotesTable
        allData={dataToShow}
        columns={
          role == userRoles.admin
            ? columnForProjectQuotesAdminNew
            : columnForProjectQuotesClientNew
        }
        valueConvertHandler={valueConvertHandler}
        collapseFlag={true}
        goToPageFcn={() => {}}
        limitHandler={limitHandler}
        limit={limit}
        paginationBtnClick={paginationBtnClick}
        currentPage={currentPageNumber}
        lastPage={lastPageNumber}
        isProject={true}
        role={role}
      />

      {openPopup && (
        <Dialogue
          showDialogue={openPopup}
          setShowDialogue={setOpenPopup}
          DialogueHeadText={""}
          DialogueBody={<DialogBody />}
          showCancelButton={true}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Yes"}
          showSubmitLoader={submitting}
          setShowSubmitLoader={setSubmitting}
          onSubmit={() => updateQuote()}
          submitButtonLook={"primary"}
        />
      )}
      <RejectQuotePopup
        showRejectDialogue={rejectQuotePopup}
        setShowRejectDialogue={setRejectQuotePopup}
        onSubmit={updateQuote}
      />
    </Box>
  );
};

ProjectQuoteTable.propTypes = {
  role: PropTypes.string,
  updateQuoteStatus: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

ProjectQuoteTable.defaultProps = {
  role: undefined,
};

export default ProjectQuoteTable;
