import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { inviteErrorType } from "../../../lib/constants";
import { Box, Typography } from "@mui/material";
import Dialogue from "../../global/Dialogue";
import AppButton from "../../global/elements/AppButton";
import InvitationImg from "../../../assets/images/team_member_invitation.png";
import { inviteTeamMembers } from "../../../services/client";

const styles = {
  popupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
  errorCls: {
    display: "table",
    margin: "0 auto",
    textAlign: "left",
    marginTop: "20px",
  },
  btnCls: {
    marginTop: "30px",
  },
  centerCls: {
    textAlign: "center",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "left",
    paddingLeft: "25%",
  },
};

const ConfirmationPopup = ({
  openPopup,
  setOpenPopup,
  errors,
  emailSendTo,
  clientId,
  enterprise,
  onSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const unusedProfile = errors[inviteErrorType.unused]?.length
    ? errors[inviteErrorType.unused].join(", ")
    : null;

  const operatorProfile = errors[inviteErrorType.operator]?.length
    ? errors[inviteErrorType.operator].join(", ")
    : null;

  const sameEnterpriseProfile = errors[inviteErrorType.existSameEnterPrise]
    ?.length
    ? errors[inviteErrorType.existSameEnterPrise].join(", ")
    : null;

  const otherEnterPrise = errors[inviteErrorType.existOtherEnterPrise]?.length
    ? errors[inviteErrorType.existOtherEnterPrise].join(", ")
    : null;

  const sendInvitation = async () => {
    try {
      setLoading(true);
      const { data } = await inviteTeamMembers({
        members: emailSendTo,
        clientId,
        enterprise,
      });
      console.log("data", data);
      if (data.status == "Success") {
        // setInviteSent(true);
        onSuccess();
      } else {
        enqueueSnackbar("Error in sending an invite", {
          variant: "error",
        });
      }
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Error in processing", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  const DialougeBody = () => {
    return (
      <Box sx={styles.popupCls}>
        <Typography variant="h6">
          Below mentioned emails are already registed on the platform
        </Typography>
        <ul style={styles.errorCls}>
          {operatorProfile && (
            <li>
              {operatorProfile} registered as drone operator on the platform{" "}
            </li>
          )}
          {sameEnterpriseProfile && (
            <li>
              {sameEnterpriseProfile} already registered in your enterprise
            </li>
          )}
          {otherEnterPrise && (
            <li>{otherEnterPrise} registered with other enterprise </li>
          )}
        </ul>
        {unusedProfile ? (
          <Typography variant="h6">
            Do you want to continue send invitation to {unusedProfile} ?
          </Typography>
        ) : (
          <AppButton
            look="black"
            label="Cancel"
            onClick={() => setOpenPopup(false)}
            noIcon
            addtionalStyle={styles.btnCls}
          />
        )}
      </Box>
    );
  };

  const checkTableType = () => {
    if (unusedProfile) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {openPopup && (
        <Dialogue
          showDialogue={openPopup}
          setShowDialogue={setOpenPopup}
          DialogueHeadText={""}
          DialogueBody={<DialougeBody />}
          showCancelButton={true}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Yes"}
          showSubmitLoader={loading}
          setShowSubmitLoader={setLoading}
          onSubmit={() => {
            sendInvitation();
          }}
          tableType={checkTableType()}
        />
      )}
    </>
  );
};

ConfirmationPopup.propTypes = {
  openPopup: PropTypes.bool.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  goToDashboard: PropTypes.func.isRequired,
  errors: PropTypes.object,
  emailSendTo: PropTypes.array,
  enterprise: PropTypes.object,
  onSuccess: PropTypes.func,
};

ConfirmationPopup.defaultProps = {
  errors: {},
  emailSendTo: [],
  enterprise: null,
  onSuccess: undefined,
};

export default ConfirmationPopup;
