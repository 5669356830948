import React from "react";
import { Box, Typography } from "@mui/material";
import { contractWaiverLabels, missionStatus, userRoles } from "../../../../lib/constants";
import SignInContract from "./signInContract";

interface userProps {
  role:string
}
interface missionProps {
  status: string
  contractAcceptedViaAdmin:string
}
interface contractProps {
  id: string
  iUnderstandAll:boolean
}
interface EmptyMessageProps {
  currentUser:userProps
  contract:contractProps
  mission:missionProps
}
const styles ={
  container: { textAlignLast: "center",margin:"3em 0" }
}
const EmptyMessage = ({
  currentUser,
  contract,
  mission
}: EmptyMessageProps) => {
  const isAdmin = currentUser.role === userRoles.admin;
  const isClient = currentUser.role === userRoles.client;
  const isPilot = currentUser.role === userRoles.pilot;
  return (
  <Box>
      <Box sx={styles.container}>
        {isClient && <Typography variant="h4">Data from this area has been uploaded to your platform</Typography>}
        {isAdmin && <Typography variant="h4">This Order does not require data upload</Typography>}

        {isPilot && <Box>
          {!(
          contract?.iUnderstandAll
        ) &&
          !(
            mission?.contractAcceptedViaAdmin ===
            contractWaiverLabels.accepted
          ) &&
          !(mission?.status === missionStatus.completed) ? (
            <SignInContract contractId={contract?.id} />
          )
          :<Typography variant="h4">This Order do not require data upload in the mission page</Typography>
          }
          </Box>
          }

    </Box>
  </Box>
  );
};

export default EmptyMessage;
