//VERSION 1: date: pre 01-06-2023

import React from "react";
import { Box } from "@mui/material";
import ParagraphHeading from "../ParagraphHeading";
import Clause from "../Clause";
import theme from "../../../../theme";

const styles = {
  assignment_section: {
    marginTop: "30px",
    paddingTop: "20px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
};

const PilotContractVersion1 = () => {
  return (
    <Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="1. Assignment" />
        <Clause
          number="1.1"
          title={`The Operator must complete all agreed upon activities 
                following the guidelines and criteria provided by the Company for 
                this mission. This includes any standard operating procedures, 
                checklists, manuals, risk assessments or other documentation related 
                to the mission that GLOBHE provides to the Operator in support of 
                the mission.`}
        />
        <Clause
          number="1.2"
          title={`The Operator is expected to complete the 
              full scope of work within the designated timeframe as set forth by the 
              mission details with the exception of a Force Majeure event. 
              “Force Majeure” is defined as any unforeseeable event beyond a party’s 
              control, including any act of God, industrial action, riot, terrorist act, 
              war or requisition by act of government. If a Force Majeure event occurs 
              please inform GLOBHE immediately and provide a form of proof. If any 
              form of illness prevents The Operator from performing work as planned, 
              please contact GLOBHE immediately to coordinate a new plan. If weather 
              conditions prevent or delay the work from being completed as planned, 
              The Operator should inform GLOBHE and together coordinate a new plan.`}
        />
        <Clause
          number="1.3"
          title={`Delivery of data must be completed by the deadline set forth by the mission details. 
                Delivery of data is defined as the complete collection and upload of the 
                data to the Crowddroning platform to the assigned mission of The Operator. 
                The Company will review and verify the data once uploaded and provide 
                final approval to The Operator if no adjustments are necessary. It is the
                responsibility of The Operator to ensure access to sufficient internet 
                connectivity in order to upload the data to the platform.`}
          helperText={`Drone data taken in Sweden might need to 
                apply for a spreading permit (spridningstillstånd) at Lantmäteriet 
                depending on what it shows. It is the Drone Operators responsibility 
                to send in the data to Lantmäteriet and get the permit if required.`}
        />
        <Clause
          number="1.4"
          title={` The location of the assignment is restricted 
              to the area defined in Location under mission details.`}
        />
        <Clause
          number="1.5"
          title="The Company can press legal charges if the Operator fails to follow local laws and regulations and/or charges the Operator for loss of income if the Operator fails to upload the requested data by delivery date if it is not caused by a Force Majeure event which has been communicated to Globhe ahead of time and as stated in Clause 1.2."
        />
      </Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="2. Equipment" />
        <Clause
          number="2.1"
          title={`The Operator is using the drone platform plus any 
                additional required accessories as stated in the Mission Details`}
        />
        <Clause
          number="2.2"
          title={`The qualification requirements for the data to be accepted 
                is stated in the Mission Details as other requirements.`}
        />
      </Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="3. Payment" />
        <Clause
          number="3.1"
          title={`The Operator will receive the payment amount mentioned in the mission detail + include VAT 
                if applicable in total. This includes the preparation, execution and 
                delivery of the data and any associated transportation costs.`}
        />
        <Clause
          number="3.2"
          title={`The Company will transfer the payment to the Operator’s account 
                within 30 days of receiving invoice. Payment is dependent on The Operator 
                performing the work as outlined in this agreement and delivering the data 
                to GLOBHE on time. If the data is captured incorrectly or delivered past 
                the deadline The Company reserves the right to deduct a percentage of 
                payment depending on the severity or impact of the re-work needed to 
                complete the mission delivery. `}
        />
      </Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="4. Terms And Conditions" />
        <Clause
          title={
            <>
              Refer to the{" "}
              <a
                href="https://www.globhe.com/terms-and-conditions"
                target="_blank"
                style={styles.link}
                rel="noreferrer"
              >
                terms and conditions
              </a>{" "}
              page published on globhe website.
            </>
          }
        />
      </Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="5. Governing Law and Settlement of Disputes" />
        <Clause
          title={`The Agreement shall be governed by and construed in accordance 
                with the laws of Sweden (excluding its conflict of law provisions). All 
                disputes arising out of or relating to the Agreement or Services shall be 
                finally settled by arbitration under the Rules of Arbitration of the 
                International Chamber of Chamber of Commerce, in Stockholm, Sweden, by one 
                or more arbitrators appointed in accordance with the said rules. The 
                language for the arbitration proceedings shall be English. All awards may 
                if necessary be enforced by any court having jurisdiction in the same 
                manner as a judgment in such court. All arbitral proceedings, including any 
                information and documents disclosed therein shall be kept confidential and 
                only be used for those proceedings. `}
          helperText={`In witness whereof the parties have executed this Agreement in 
                two identical copies (of which the parties have received one copy each).`}
        />
      </Box>
    </Box>
  );
};

export default PilotContractVersion1;
