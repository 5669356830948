import { Create, PanTool, RotateLeft } from "@mui/icons-material";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import React from "react";
import DarkLogo from "src/assets/images/dark-logo.svg";
import AutoComplete from "./AutoComplete";
import Marker from "./Marker";
import { areaUnits, OrderTypeConstant } from "../../../lib/constants";
import theme from "../../../theme";
import {
  Box,
  Select,
  InputLabel,
  FormControl,
  Button,
  MenuItem,
} from "@mui/material";
import MissionOrderInformation from "./MissionOrderInformation";

const styles = {
  root: {
    width: "100%",
    height: "unset",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr auto",
  },
  topBar: {
    width: "100%",
    display: "grid",
    flexWrap: "wrap",
    columnGap: "10px",
    rowGap: "30px",
    padding: "20px 40px",
    alignItems: "center",
    gridAutoFlow: { xs: "row", sm: "column" },
    justifyContent: { xs: "center", sm: "space-between" },
  },
  logo: {
    height: 23,
    width: "auto",
  },
  map: {
    width: "100%",
    minHeight: "300px",
  },

  ctaButton: {
    "&.MuiButton-root": {
      textTransform: "none",
      width: 300,
      borderRadius: "0px",
      background: theme.palette.primary.main,
      color: theme.palette.grey.white,
      padding: "6px",
      "&.Mui-disabled": {
        background: theme.palette.grey.lightWarmGrey,
        color: theme.palette.grey.warmGrey,
      },
      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
  },
  ctaClearButton: {
    "&.MuiButton-root": {
      textTransform: "none",
      width: 300,
      borderRadius: "0px",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderColor: theme.palette.primary.main,
      padding: "6px",
      "&:hover": {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  inputField: {
    color: theme.palette.grey.white,
  },
  searchBox: {
    marginTop: 0,
  },
  toolButton: {
    height: 40,
    width: { xs: "100%", sm: "100px" },
    textTransform: "none",
    borderRadius: "0px",
  },

  toolClearButton: {
    "&.MuiButton-root": {
      height: 40,
      width: { xs: "100%", sm: "100px" },
      textTransform: "none",
      borderRadius: "0px",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "&:hover": {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  toolButtons: {
    display: "grid",
    columnGap: "10px",
    rowGap: "10px",
    flexWrap: "wrap",
    gridAutoFlow: { xs: "row", sm: "column" },
  },
  sizeField: {
    margin: "0px 20px",
    textAlign: "right",
  },
  topBarLeftSide: {
    display: "grid",
    alignItems: "center",
    rowGap: "30px",
    columnGap: "30px",
    flexWrap: "wrap",
    gridAutoFlow: { sm: "row", md: "column" },
  },
  changeUnitCls: {
    "&.MuiInput-underline:before": {
      borderBottom: `1px solid #000000`,
    },
  },
  addScrollBar: {
    overflowY: { xs: "scroll", sm: "hidden" },
  },
};

const MapDrawingTool = ({
  area,
  defaultLocation,
  defaultZoom,
  readonly,
  onSave,
  onCancel,
  minHeight,
  mapHeight,
  type,
}) => {
  const mapRef = React.useRef(null);
  const [mapApiLoaded, setMapApiLoaded] = React.useState(false);
  const [mapInstance, setMapInstance] = React.useState(null);
  const [mapApi, setMapApi] = React.useState(null);
  const [place, setPlace] = React.useState(null);
  const [inDrawMode, setInDrawMode] = React.useState(false);
  const [drawingManager, setDrawingManager] = React.useState(null);
  const [polygon, setPolygon] = React.useState(null);
  const [areaSize, setAreaSize] = React.useState(null);
  const [dragging, setDragging] = React.useState(false);
  const [geocoder, setGeocoder] = React.useState(null);
  const [infoWindow, setInfoWindow] = React.useState(null);
  const [selectedUnit, setSelectedUnit] = React.useState(areaUnits.sqkm);
  const [orderType, setOrderType] = React.useState(null);

  //CSS workaround for safari
  React.useEffect(() => {
    if (mapRef) {
      mapRef.current.googleMapDom_.style.minHeight = mapHeight;
    }
  }, [mapRef]);

  React.useEffect(() => {
    if (!mapApiLoaded) return;

    if (area) {
      loadArea(area);
    }
    const _geocoder = new mapApi.Geocoder();
    setGeocoder(_geocoder);
    let _infoWindow = new mapApi.InfoWindow({
      content: "Right Click the map to get info about the location!",
      position: null,
    });
    _infoWindow.open(mapInstance);
    setInfoWindow(_infoWindow);
    const clickInfoListener = mapApi.event.addListener(
      mapInstance,
      "rightclick",
      (mapsMouseEvent) =>
        getLocationOnClick(_geocoder, mapsMouseEvent, _infoWindow)
    );

    if (!readonly) {
      const _drawingManager = new mapApi.drawing.DrawingManager({
        drawingControl: false,
        // drawingMode: mapApi.drawing.OverlayType.POLYLINE,
        polygonOptions: {
          draggable: true,
          editable: true,
          strokeColor: theme.palette.primary.main,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: theme.palette.grey.white,
          fillOpacity: 0.35,
          geodesic: true,
        },
        polylineOptions: {
          draggable: true,
          editable: true,
          strokeColor: theme.palette.primary.main,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: theme.palette.grey.white,
          fillOpacity: 0.35,
          geodesic: true,
        },
      });
      setDrawingManager(_drawingManager);
    }
    return () => {
      mapApi.event.removeListener(clickInfoListener);
      //global cleanup
      mapApi.event.clearInstanceListeners(mapInstance);
    };
  }, [
    area,
    getLocationOnClick,
    loadArea,
    mapApi,
    mapApiLoaded,
    mapInstance,
    readonly,
  ]);

  React.useEffect(() => {
    if (!drawingManager) return;
    drawingManager.setMap(mapInstance);

    const listener2 = mapApi.event.addListener(
      drawingManager,
      "polylinecomplete",
      (pol) => {
        // console.log("polylinecomplete", pol);
        setInDrawMode(false);
        pol.setMap(mapInstance);
        setPolygon(pol);
      }
    );
    return () => {
      // mapApi.event.removeListener(listener);
      mapApi.event.removeListener(listener2);
    };
  }, [drawingManager, mapInstance]);

  React.useEffect(() => {
    if (!drawingManager) return;
    drawingManager.setDrawingMode(
      inDrawMode ? mapApi.drawing.OverlayType.POLYLINE : null
    );
  }, [inDrawMode, drawingManager]);

  React.useEffect(() => {
    const polyListeners = [];
    if (polygon) {
      // setAreaSize(getAreaSizeInSquareKiloMeters(polygon));
      setAreaSize(getAreaSize(polygon));
      polyListeners.push(
        mapApi.event.addListener(polygon.getPath(), "insert_at", updateSize)
      );
      polyListeners.push(
        mapApi.event.addListener(polygon.getPath(), "remove_at", updateSize)
      );
      polyListeners.push(
        mapApi.event.addListener(polygon.getPath(), "set_at", updateSize)
      );
      polyListeners.push(
        mapApi.event.addListener(polygon, "dragend", () => setDragging(false))
      );
      polyListeners.push(
        mapApi.event.addListener(polygon, "dragstart", () => setDragging(true))
      );
      polyListeners.push(
        mapApi.event.addListener(polygon, "rightclick", (mouseEvent) =>
          getLocationOnClick(geocoder, mouseEvent, infoWindow)
        )
      );
    }
    return () => {
      polyListeners.forEach((listener) =>
        mapApi.event.removeListener(listener)
      );
    };
  }, [
    geocoder,
    // getAreaSizeInSquareKiloMeters,
    getAreaSize,
    getLocationOnClick,
    infoWindow,
    polygon,
    updateSize,
  ]);

  const getLocationOnClick = (geocoder, mouseEvent, infoWindow) => {
    infoWindow.close();
    geocoder.geocode({ location: mouseEvent.latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          infoWindow.setPosition(mouseEvent.latLng);
          infoWindow.setContent(
            formatContent(results[0].formatted_address, mouseEvent)
          );
          infoWindow.open(mapInstance);
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  };

  const formatContent = (address, mouseEvent) => {
    const coordinates = JSON.stringify(mouseEvent.latLng.toJSON(), null, 2);
    return `
      <div>
        <p><b>${address}</b><br>${coordinates}</p>
      </div>`;
  };

  const updateSize = () => {
    if (polygon && !dragging) {
      // setAreaSize(getAreaSizeInSquareKiloMeters(polygon));
      setAreaSize(getAreaSize(polygon));
    }
  };

  const clearPolygon = () => {
    polygon?.setMap(null);
    setPolygon(null);
    setInDrawMode(true);
  };

  const setApiHasLoaded = (map, maps) => {
    // console.log("map, maps", map, maps);
    setMapInstance(map);
    setMapApi(maps);
    setMapApiLoaded(true);
  };

  const loadArea = (area) => {
    if (readonly && !type) {
      createPolygonFromArea(area);
    } else {
      createPolylineFromArea(area);
    }
    // createPolygonFromArea(area);
    createPolylineFromArea(area);
    const bounds = areaToBounds(area);
    mapInstance.setCenter(bounds.getCenter());
    mapInstance.fitBounds(bounds);
  };

  const polygonToArea = (polygon) => {
    const area = [];
    polygon.getPath().forEach((element) => area.push(element.toJSON()));
    // console.log("area", area);
    return area;
  };

  const areaToBounds = (area) => {
    const bounds = new mapApi.LatLngBounds();
    area.forEach((coord) => bounds.extend(new mapApi.LatLng(coord)));
    return bounds;
  };

  const getAreaSizeInSquareKiloMeters = (polygon) => {
    const areaSize = mapApi.geometry.spherical.computeArea(polygon.getPath());
    return areaSize / 1000000; //convert sqm to sqkm
  };

  const getAreaSize = (polygon) => {
    const coord = polygonToArea(polygon);
    const type = findOrderType(coord);
    let areaSize = 0;
    if (type == OrderTypeConstant.area) {
      // POLYGON
      areaSize = mapApi.geometry.spherical.computeArea(polygon.getPath());
      return areaSize / 1000000; //convert sqm to sqkm
    } else if (type == OrderTypeConstant.assets) {
      // ASSET
      return 0;
    } else {
      // LINEAR
      for (let i = 0; i < coord.length; i++) {
        if (i != coord.length - 1) {
          areaSize += mapApi.geometry.spherical.computeDistanceBetween(
            new mapApi.LatLng(coord[i]),
            new mapApi.LatLng(coord[i + 1])
          );
        }
      }

      // return distance in meters
      areaSize = areaSize / 1000; // Changing this to Km
      return areaSize;
    }
  };
  const displayAreaSizeInSelectedUnits = (areaSize) => {
    if (selectedUnit == areaUnits.sqm) {
      return (areaSize * 1000000).toFixed(2); //convert sqkm to sqm
    }
    if (selectedUnit == areaUnits.hectare) {
      return (areaSize * 100).toFixed(2); //convert sqkm to hectare
    }
    if (selectedUnit == areaUnits.sqmiles) {
      return (areaSize * 0.38610215854781257).toFixed(2); //convert sqkm to square miles
    }
    return areaSize?.toFixed(2); //keep it in sq km
  };

  const createPolygonFromArea = (area) => {
    const pol = new mapApi.Polygon({
      map: mapInstance,
      paths: area,
      strokeColor: theme.palette.primary.main,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: theme.palette.grey.white,
      fillOpacity: 0.35,
      draggable: !readonly,
      editable: !readonly,
      geodesic: true,
    });
    setPolygon(pol);
  };

  const createPolylineFromArea = (area) => {
    const coords = [];
    area.forEach((coordinate) =>
      coords.push(new mapApi.LatLng(coordinate.lat, coordinate.lng))
    );
    const pol = new mapApi.Polyline({
      map: mapInstance,
      path: coords,
      strokeColor: theme.palette.primary.main,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: theme.palette.grey.white,
      fillOpacity: 0.35,
      draggable: !readonly,
      editable: !readonly,
      geodesic: true,
    });
    setPolygon(pol);
  };

  const findOrderType = (area) => {
    let type = null;
    if (area) {
      if (area.length == 1) {
        // This is assest (point)
        setOrderType(OrderTypeConstant.assets);
        type = OrderTypeConstant.assets;
      } else {
        if (
          area[0]?.lat == area[area?.length - 1]?.lat &&
          area[0]?.lng == area[area?.length - 1]?.lng
        ) {
          // This is polygon
          setOrderType(OrderTypeConstant.area);
          type = OrderTypeConstant.area;
        } else {
          // This is linear
          setOrderType(OrderTypeConstant.linear);
          type = OrderTypeConstant.linear;
        }
      }
    }
    return type;
  };

  const save = () => {
    const area = polygonToArea(polygon);
    const type = findOrderType(area);
    let locationLabel;
    const bounds = new mapApi.LatLngBounds();
    area.forEach((coordinate) => bounds.extend(coordinate));
    geocoder.geocode({ location: bounds.getCenter() }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          locationLabel = results[0].formatted_address;
        }
      }
      onSave(area, areaSize, locationLabel, type);
    });
  };

  const CharacterDropDown = () => {
    return (
      <FormControl variant="standard" sx={styles.toolButton}>
        <InputLabel>Change unit</InputLabel>
        <Select
          label="unit"
          onChange={function (e) {
            setSelectedUnit(e.target.value);
          }}
          sx={styles.changeUnitCls}
          title="Enter a location"
          value={selectedUnit}
        >
          <MenuItem value={areaUnits.sqkm}>{areaUnits.sqkm}</MenuItem>
          <MenuItem value={areaUnits.sqm}>{areaUnits.sqm}</MenuItem>
          <MenuItem value={areaUnits.hectare}>{areaUnits.hectare}</MenuItem>
          <MenuItem value={areaUnits.sqmiles}>{areaUnits.sqmiles}</MenuItem>
        </Select>
      </FormControl>
    );
  };
  const areaSizeUnitNotation = () => {
    if (orderType == OrderTypeConstant.area) {
      if (selectedUnit == areaUnits.sqkm) {
        return ` ${areaUnits.sqkm}`;
      }
      if (selectedUnit == areaUnits.sqm) {
        return ` ${areaUnits.sqm}`;
      }
      if (selectedUnit == areaUnits.hectare) {
        return ` ${areaUnits.hectare}`;
      }
      if (selectedUnit == areaUnits.sqmiles) {
        return ` ${areaUnits.sqmiles}`;
      }
    } else if (orderType == OrderTypeConstant.linear) {
      return " km";
    } else {
      return "";
    }
  };

  const expectedTime = (areaSize) => {
    return Math.ceil(areaSize);
  };
  const expectedMbSize = (areaSize) => {
    return Math.ceil(areaSize * 100 * 163);
  };
  const expectedCost = (areaSize) => {
    var cost = areaSize * 100 * 163 * 0.05;
    if (cost < 815) {
      return 815;
    } else {
      return Math.ceil(cost);
    }
  };

  return (
    <Box
      sx={
        readonly
          ? { ...styles.root }
          : { ...styles.root, ...styles.addScrollBar }
      }
    >
      {!readonly && (
        <Box sx={styles.topBar}>
          <Box sx={styles.topBarLeftSide}>
            <img src={DarkLogo} alt="Logo" style={styles.logo} />
            {mapApiLoaded && (
              <AutoComplete
                map={mapInstance}
                mapApi={mapApi}
                addplace={(newPlace) => setPlace(newPlace)}
              />
            )}
          </Box>
          <Box sx={styles.toolButtons}>
            {areaSize && (
              <Box sx={styles.toolButtons}>{CharacterDropDown()}</Box>
            )}
            {!readonly && (
              <Box sx={styles.toolButtons}>
                <Button
                  variant="contained"
                  disableElevation
                  // color={!inDrawMode ? `${theme.palette.primary.main}` : `${theme.palette.primary.main}`}
                  onClick={() => setInDrawMode(false)}
                  startIcon={<PanTool />}
                  style={{
                    ...styles.toolButton,
                    background: !inDrawMode
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.grey.lightWarmGrey}`,
                    color: !inDrawMode
                      ? `${theme.palette.grey.white}`
                      : `${theme.palette.primary.main}`,
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  // color={inDrawMode ? "primary" : "default"}
                  onClick={() => setInDrawMode(true)}
                  startIcon={<Create />}
                  style={{
                    ...styles.toolButton,
                    background: inDrawMode
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.grey.lightWarmGrey}`,
                    color: polygon
                      ? `${theme.palette.grey.warmGrey}`
                      : !inDrawMode
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.grey.white}`,
                  }}
                  disabled={polygon}
                >
                  Draw
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => clearPolygon()}
                  startIcon={<RotateLeft />}
                  sx={{
                    ...styles.toolClearButton,
                    color: `${theme.palette.primary.main}`,
                  }}
                >
                  Clear
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          minHeight: minHeight,
          height: mapHeight,
          borderRadius: "0px",
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            libraries: ["drawing", "geometry", "places"],
            key: process.env.GATSBY_FIREBASE_API_KEY,
          }}
          defaultCenter={defaultLocation}
          defaultZoom={defaultZoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => setApiHasLoaded(map, maps)}
          options={{
            mapTypeControl: true,
            mapTypeId: "satellite",
          }}
          ref={mapRef}
        >
          {place && (
            <Marker
              key={place.id}
              text={place.name}
              lat={place.geometry.location.lat()}
              lng={place.geometry.location.lng()}
            />
          )}

          {area && orderType === OrderTypeConstant.assets && (
            <Marker lat={area[0].lat} lng={area[0].lng} />
          )}
        </GoogleMapReact>
      </Box>
      {!readonly && (
        <MissionOrderInformation
          areaSize={areaSize}
          polygon={polygon}
          expectedTime={expectedTime}
          displayAreaSizeInSelectedUnits={displayAreaSizeInSelectedUnits}
          areaSizeUnitNotation={areaSizeUnitNotation}
          expectedMbSize={expectedMbSize}
          expectedCost={expectedCost}
          onCancel={onCancel}
          save={save}
        />
      )}
    </Box>
  );
};

MapDrawingTool.propTypes = {
  area: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    })
  ),
  defaultLocation: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  defaultZoom: PropTypes.number,
  readonly: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  minHeight: PropTypes.string,
  mapHeight: PropTypes.object,
  type: PropTypes.string,
};

MapDrawingTool.defaultProps = {
  area: [],
  defaultLocation: { lat: 0, lng: 0 },
  defaultZoom: 0,
  readonly: false,
  onSave: undefined,
  onCancel: undefined,
  mapHeight: undefined,
  minHeight: undefined,
  type: undefined,
};

export default MapDrawingTool;

// google.maps.options = {
//   streetViewControl: false,
//   scaleControl: true,
//   fullscreenControl: false,
//   styles: [
//     {
//       featureType: "poi.business",
//       elementType: "labels",
//       stylers: [
//         {
//           visibility: "off",
//         },
//       ],
//     },
//   ],
//   gestureHandling: "greedy",
//   disableDoubleClickZoom: true,
//   minZoom: 11,
//   maxZoom: 18,

//   mapTypeControl: true,
//   mapTypeId: maps.MapTypeId.SATELLITE,
//   mapTypeControlOptions: {
//     style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
//     position: maps.ControlPosition.BOTTOM_CENTER,
//     mapTypeIds: [
//       maps.MapTypeId.ROADMAP,
//       maps.MapTypeId.SATELLITE,
//       maps.MapTypeId.HYBRID,
//     ],
//   },

//   zoomControl: true,
//   clickableIcons: false,
// };
