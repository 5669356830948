import React from "react";
import SectionHeading from "../../global/elements/SectionHeading";
import Layout from "../../../Layout";
import theme from "../../../theme";
import FormComponent from "./FormComponent";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const EditCreateNews = ({ pageTitle, news, update, newsId, loading }) => {
  const style = {
    form_container: {
      width: "100%",
      borderRadius: "10px",
      boxShadow: { xs: "none", md: "0px 0px 4px rgba(0, 0, 0, 0.25)" },
      marginTop: "40px",
      padding: { xs: "30px 0px", md: "30px 40px" },
    },
  };

  return (
    <Layout
      pageTitle={pageTitle || "Create News"}
      hasSideMenu
      loading={loading}
    >
      <Box sx={{ padding: 0 }}>
        <SectionHeading
          heading="News"
          backgroundColor={theme.palette.grey.warmGrey}
        />
        <Box sx={style.form_container}>
          <FormComponent news={news} update={update} newsId={newsId} />
        </Box>
      </Box>
    </Layout>
  );
};

EditCreateNews.propTypes = {
  pageTitle: PropTypes.object.isRequired,
  news: PropTypes.func,
  update: PropTypes.bool.isRequired,
  newsId: PropTypes.func,
  loading: PropTypes.func,
};

EditCreateNews.propTypes = {
  news: () => {},
  newsId: () => {},
  loading: () => {},
};

export default EditCreateNews;
