import firebase from "src/firebase";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getOperatorMissionList } from "../services/pilots";

export const useGetOperatorListedData = ({ pilotId }) => {
  let initalSortBy = { sortBy: "dateCreated", sortValue: "desc" };
  const [missions, setMissions] = useStateIfMounted([]);
  const [allMission, setAllMission] = useStateIfMounted([]);
  const [lastLoadedMission, setLastLoadedMission] = useStateIfMounted(null);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const [filteredMissionList, setFilteredMissionList] = useStateIfMounted([]);
  const [limit, setLimit] = useStateIfMounted(10);
  const [filter, setFilter] = useStateIfMounted({});
  const [loadHandler, setLoadHandler] = useStateIfMounted(false);
  // const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [sortBy, setSortBy] = useStateIfMounted(initalSortBy);
  const [reloadTriggered, setReloadTriggered] = useStateIfMounted(false);
  const [previousPage, setPreviousPage] = useStateIfMounted(0);
  const [dataloadedTill, setDataLoadedTill] = useStateIfMounted(0);
  const [pageChanged, setPageChanged] = useStateIfMounted(false);
  const [pageData, setPagedata] = useStateIfMounted({
    currentPage: 1,
    isLast: false,
    lastPage: null,
  });

  useEffect(() => {
    if (!firebase) {
      return;
    }
    setIsLoading(true);
    if (
      dataloadedTill >= pageData.currentPage ||
      (pageData.currentPage <= previousPage && reloadTriggered === false)
    ) {
      let prevPageMissionData = allMission.slice(
        (pageData.currentPage - 1) * limit,
        pageData.currentPage * limit
      );
      setMissions(prevPageMissionData);
      setPreviousPage(pageData.currentPage - 1);
      setIsLoading(false);
    } else {
      if (reloadTriggered) {
        setPagedata({
          currentPage: 1,
          isLast: false,
          lastPage: null,
        });
        setPreviousPage(0);
      }
      getOperatorMissionList(
        firebase,
        pilotId,
        lastLoadedMission,
        limit,
        filter,
        sortBy
      )
        .then((data) => {
          setMissions(data.missions);
          let allProjectData = allMission || [];
          setPreviousPage(pageData.currentPage - 1);
          setLastLoadedMission(data.lastLoaded);
          setDataLoadedTill(pageData.currentPage);
          if (data.isLast) {
            let pageDataUpdated = pageData;
            pageDataUpdated["isLast"] = true;
            pageDataUpdated["lastPage"] = pageData.currentPage;
            setPagedata(pageDataUpdated);
          }
          if (reloadTriggered) {
            setPreviousPage(0);
            setAllMission([...data.missions]);
          } else {
            setAllMission([...allProjectData, ...data.missions]);
          }
          setReloadTriggered(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [loadHandler, pageChanged]);

  useEffect(() => {
    let newProjectList = [...missions];
    setFilteredMissionList(newProjectList);
  }, [missions, loadHandler, pageChanged]);

  return [
    filteredMissionList,
    isLoading,
    limit,
    setLimit,
    filter,
    setFilter,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllMission,
    setDataLoadedTill,
    setLastLoadedMission,
    sortBy,
    setSortBy,
    pageChanged,
    setPageChanged,
  ];
};
