import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../../theme";
import { OrderTypeConstant } from "../../../lib/constants";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TooltipInfo from "../../global/elements/TooltipInfo";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { boxShadowStyle } from "../../../lib/styleConstants";

const styles = {
  cardWrapper: {
    padding: "2em",
  },
  cardContainer: { display: "flex", flexDirection: "column", rowGap: "0.5em" },
  cardheadWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardheadContainer: {
    textTransform: "uppercase",
  },
  cardMissionName: {
    cursor: "pointer",
  },
  dataMetricHead: {
    marginBottom: "10px",
  },
  cardSubDetailBox: {
    padding: "1em",
    border: `1px solid ${theme.palette.black.stoneSilver}`,
  },
  cardDetailInner: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    rowGap: "0.5em",
  },
  detailHead: {},
  description: { wordBreak: "break-all" },
  detailsBottom: {
    fontWeight: 600,
  },
  smallscreenBox: {
    display: { xs: "flex", sm: "block" },
    justifyContent: { xs: "space-between", sm: "unset" },
    width: { xs: "100%", sm: "unset" },
    alignItems: { xs: "center", sm: "unset" },
    borderBottom: {
      xs: `1px solid ${theme.palette.primary.main}`,
      sm: "unset",
    },
    paddingBottom: { xs: "10px", sm: "unset" },
    paddingTop: "5px",
  },
  toolInfo: {
    cursor: "pointer",
  },
  toolInfoContainer: { display: { xs: "none", sm: "block" } },
  deleteIcon: { color: theme.palette.status.errorLight, cursor: "pointer" },
  ferryText: {
    color: theme.palette.black.coolGrey,
  },
  weight5: {
    fontWeight: 500,
  },
};
const CartBox = ({ data, id, deleteItem }) => {
  const checkOrderType = (data) => {
    switch (data.orderType) {
      case OrderTypeConstant.linear:
        return "km";
      case OrderTypeConstant.area:
        return "km2";
      case OrderTypeConstant.assets:
        return "";
      default:
        return "km2";
    }
  };
  const AreaComp = () => {
    return (
      <span>
        km<sup>2</sup>
      </span>
    );
  };
  return (
    <Box sx={{ ...styles.cardWrapper, ...boxShadowStyle }}>
      <Box sx={styles.cardContainer}>
        <Box sx={styles.cardheadWrapper}>
          <Typography
            variant="subtitle1"
            sx={{ ...styles.cardheadContainer, ...styles.ferryText }}
          >
            {data.locationLabel}
          </Typography>
          <Box>
            <DeleteOutlineOutlinedIcon
              sx={styles.deleteIcon}
              onClick={() => deleteItem(data, id)}
            />
          </Box>
        </Box>
        <Typography
          variant="h5"
          sx={styles.cardMissionName}
          onClick={() => navigate(`/app/client/data-library/${data.id}`)}
        >
          {data.missionName}
        </Typography>
        <Typography
          variant="body1"
          sx={{ ...styles.ferryText, ...styles.description }}
        >
          {data.dataLibrary?.description
            ? data.dataLibrary?.description
            : data.description}
        </Typography>
        <Typography variant="subtitle1Med" sx={styles.dataMetricHead}>
          data metrics overview
        </Typography>
        <Box sx={styles.cardSubDetailBox}>
          <Box sx={styles.cardDetailInner}>
            {data.orderType != OrderTypeConstant.assets && (
              <Box sx={styles.smallscreenBox}>
                <Typography variant="subtitle1Med" sx={styles.ferryText}>
                  Geographic Area
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ ...styles.detailsBottom, ...styles.ferryText }}
                >
                  {data.areaSize ? parseFloat(data.areaSize).toFixed(2) : 0}{" "}
                  {checkOrderType(data) === "km2" ? (
                    <AreaComp />
                  ) : (
                    checkOrderType(data)
                  )}
                </Typography>
              </Box>
            )}
            <Box sx={styles.smallscreenBox}>
              <Typography variant="subtitle1Med" sx={styles.ferryText}>
                Download Size
              </Typography>
              <Typography
                variant="h5"
                sx={{ ...styles.detailsBottom, ...styles.ferryText }}
              >
                {/* {data.filesSize ? parseFloat(data?.filesSize).toFixed(2) : 0} MB */}
                {data?.fileSizeString}
              </Typography>
            </Box>
            <Box sx={{ ...styles.smallscreenBox, borderBottom: "unset" }}>
              <Typography variant="subtitle1Med" sx={styles.ferryText}>
                Total cost (USD)
              </Typography>
              <Typography
                variant="h5"
                sx={{ ...styles.detailsBottom, ...styles.ferryText }}
              >
                {data.orderPrice ? data.orderPrice.toFixed(2) : 0}
              </Typography>
            </Box>
            <Box sx={styles.toolInfoContainer}>
              <TooltipInfo
                info="Details"
                additionalStyle={{ ...styles.toolInfo, ...styles.ferryText }}
                type="outlined"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CartBox.propTypes = {
  data: PropTypes.shape({
    dataLibrary: PropTypes.shape({
      description: PropTypes.string,
    }),
    locationLabel: PropTypes.string,
    missionName: PropTypes.string,
    description: PropTypes.string,
    areaSize: PropTypes.parseFloat,
    filesSize: PropTypes.parseFloat,
    orderType: PropTypes.string,
    orderPrice: PropTypes.string,
    fileSizeString: PropTypes.string,
  }),
  deleteItem: PropTypes.func,
  id: PropTypes.number,
};

CartBox.defaultProps = {
  data: PropTypes.shape({
    dataLibrary: PropTypes.shape({
      description: undefined,
    }),
    locationLabel: undefined,
    missionName: undefined,
    description: undefined,
    areaSize: undefined,
    filesSize: undefined,
    orderType: undefined,
    orderPrice: undefined,
    fileSizeString: undefined,
  }),
  deleteItem: undefined,
  id: undefined,
};

export default CartBox;
