import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import theme from "../theme";
import GlobheLogo from "src/assets/logos/globhe_logo/Globhe_symbol_2022_black.png";
import FeedbackDialogue from "./invitePopup/feedbackDialogue";
const styles = {
  root: {
    background: theme.palette.secondary.paleLavender,
    position: "fixed",
    padding: "10px",
    transformOrigin: "100% 0%",
    transform: "rotate(270deg)",
    transition: "all 0.5s ease-out 0s",
    top: "70%",
    height: "80px",
    borderRadius: "2px",
    cursor: "pointer",
    zIndex: "99",
    "&:hover": {
      right: "-16px",
    },
  },
  logo: {
    width: "20px",
    height: "20px",
    borderRadius: "0px",
  },
  boxStyle: {
    display: "flex",
    width: "8em",
    justifyContent: "space-between",
    fontWeight: "500",
    color: "#5A6166",
  },
  text: {
    color: theme.palette.grey.white,
  },
};
// hover -16px normally -32
const Feedback = () => {
  const [show, setShow] = useState(false);
  const [popupOpen, setpopupOpen] = useState(false);

  const handleClickOpen = () => {
    setShow(!show);
    setpopupOpen(true);
  };
  const handleClose = () => {
    setpopupOpen(false);
    setShow(!show);
  };
  let stx = {
    right: show ? "50px" : "40px",
  };
  return (
    <Box
      style={{
        ...styles.root,
        ...stx,
      }}
    >
      <FeedbackDialogue
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        popupOpen={popupOpen}
      />
      <Box style={styles.boxStyle} onClick={handleClickOpen}>
        <Typography>FEEDBACK</Typography>
        <Box
          src={GlobheLogo}
          component="img"
          sx={styles.logo}
          alt="Globhe Logo"
        />
      </Box>
    </Box>
  );
};
export default Feedback;
