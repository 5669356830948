import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import theme from "../../../../theme";
import { orderLocationTypeLabel } from "../../../../lib/constants";
import { greyHeadingColorCls } from "../../../../lib/styleConstants";

const styles = {
  subHeadingColorCls: { color: theme.palette.black.coolGrey },
  decriptionCls: {
    width: { xs: "100%", md: "50%" },
  },
};

const QuotationHeaderComp = ({ subHeading, heading, bodyText }) => {
  QuotationHeaderComp.propTypes = {
    subHeading: PropTypes.string,
    heading: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
  };

  QuotationHeaderComp.defaultProps = {
    subHeading: undefined,
  };
  return (
    <>
      {subHeading && (
        <Typography variant="h6" sx={greyHeadingColorCls}>
          {subHeading}
        </Typography>
      )}
      <Typography variant="h3">{heading}</Typography>
      <Typography variant="body2" sx={styles.decriptionCls}>
        {bodyText}
      </Typography>
    </>
  );
};

const SummaryOrderHeader = ({ orderTypeLocation, cost, summary }) => {
  const desc = `Please note that this is an estimated price and does not include any advanced
    options you may have selected. The final price may be affected if you have chosen
    any advanced options. You will receive a detailed quote shortly via email for you to
    review and accept or decline. Once you accept the quote, we will proceed with acquiring
    the requested data.`;
  const mutiOrderDesc = `Our algorithms are working diligently to generate a quote for you. 
  Rest assured that we will provide you with a response within 24 hours of placing the order. You will receive a 
  notification as soon as your quote is ready.`;

  return (
    <Box sx={{ marginBottom: "10%" }}>
      <QuotationHeaderComp
        subHeading={
          orderTypeLocation == orderLocationTypeLabel.multiLocationOrder
            ? undefined
            : orderTypeLocation == orderLocationTypeLabel.singleLocationOrder &&
              summary?.recurrence
            ? undefined
            : `${!isNaN(cost) ? "Estimated Total" : ""}`
        }
        heading={
          orderTypeLocation == orderLocationTypeLabel.multiLocationOrder
            ? ""
            : orderTypeLocation == orderLocationTypeLabel.singleLocationOrder &&
              summary?.recurrence
            ? ""
            : `${!isNaN(cost) ? `$ ${cost}` : `${cost}`}`
        }
        bodyText={
          orderTypeLocation == orderLocationTypeLabel.multiLocationOrder
            ? mutiOrderDesc
            : orderTypeLocation == orderLocationTypeLabel.singleLocationOrder &&
              summary?.recurrence
            ? mutiOrderDesc
            : `${!isNaN(cost) ? desc : ""}`
        }
      />
    </Box>
  );
};

SummaryOrderHeader.propTypes = {
  orderTypeLocation: PropTypes.string.isRequired,
  cost: PropTypes.number,
  summary: PropTypes.object.isRequired,
};

SummaryOrderHeader.defaultProps = {
  cost: undefined,
};

export default SummaryOrderHeader;
