import { Box, CircularProgress } from "@mui/material";
import firebase from "src/firebase";
// import { SnackbarProvider } from "notistack";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import theme from "../../../../theme";
import kmlParser from "js-kml-parser";
import { getCenter, getCountryCode } from "../../../../lib/helpers";
import {
  computeArea,
  LatLng,
  computeDistanceBetween,
} from "spherical-geometry-js";
import { OrderTypeConstant } from "../../../../lib/constants";
import UploadButton from "../../../global/elements/UploadButton";
import { userRoles } from "../../../../lib/constants";

const style = {
  root: {
    color: theme.palette.grey.white,
    fontSize: "18px",
    fontWeight: 400,
  },
  title: {
    marginBottom: "20px",
  },
  spinnerContainer: {
    position: "relative",
    width: "100%",
  },
  spinner: {
    marginLeft: "calc(50% - 20px)",
  },
};

const KMLFileDropZone = ({ onUploaded, disabled, user }) => {
  const [progress, setProgress] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [origFileName, setOrigFileName] = useState(null);
  const providerRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = user === userRoles.admin;
  const isClient = user === userRoles.client;
  const isPilot = user === userRoles.pilot;

  const getArea = (locCoords, type) => {
    if (type == OrderTypeConstant.assets) {
      return 0;
    } else if (type == OrderTypeConstant.area) {
      var latLngs = locCoords.map(function (coord) {
        return new LatLng(coord.lat, coord.lng);
      });

      return computeArea(latLngs) / 1000000; //convert sqm to sqkm;
    } else {
      let distance = 0;

      for (let i = 0; i < locCoords.length; i++) {
        if (i != locCoords.length - 1) {
          distance += computeDistanceBetween(
            new LatLng(locCoords[i].lat, locCoords[i].lng),
            new LatLng(locCoords[i + 1].lat, locCoords[i + 1].lng)
          );
        }
      }
      distance = distance / 1000;
      return distance / 1000;
    }
  };

  const getLocCoordinate = async ({ geoJson }) => {
    return new Promise((resolve, reject) => {
      try {
        let locMap = null;
        let area = {
          locMap: null,
          areaSize: null,
          locationLabel: null,
        };
        locMap = [];
        let coordinates = [];
        let type = null;
        if (geoJson.features[0].geometry.type === "Point") {
          type = OrderTypeConstant.assets;
          geoJson.features?.map((feature) => {
            coordinates.push(feature.geometry.coordinates);
          });
        } else if (geoJson.features[0].geometry.type === "Polygon") {
          type = OrderTypeConstant.area;
          coordinates = geoJson.features[0].geometry.coordinates[0];
        } else {
          type = OrderTypeConstant.linear;
          coordinates = geoJson.features[0].geometry.coordinates;
        }
        for (let i = 0; i < coordinates.length; i++) {
          locMap.push({
            lng: coordinates[i][0],
            lat: coordinates[i][1],
          });
        }
        area.locMap = locMap;
        area.type = type;

        area.areaSize = getArea(locMap, type);
        let center = getCenter(locMap);
        if (!center[0] || !center[1]) {
          reject(false);
        }
        let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${center[1]},${center[0]}&key=${process.env.GATSBY_FIREBASE_API_KEY}`;

        fetch(url)
          .then((response) => response.json())
          .then((responseJson) => {
            let code = null;
            if (
              responseJson &&
              responseJson.results &&
              responseJson.results[0] &&
              responseJson.results[0].formatted_address
            ) {
              code = getCountryCode(responseJson.results);
              area.locationLabel = responseJson.results[0].formatted_address;
              area.countryCode = code;
            }
            resolve(area);
          })
          .catch((e) => {
            console.log("Error", e);
            reject(false);
          });
      } catch (e) {
        console.log("Error", e);
        reject(false);
      }
    });
  };

  const handleFileDrop = (acceptedFiles, rejectedFiles) => {
    let kmlFileData = null;
    if (rejectedFiles && rejectedFiles.length > 0) {
      console.log("File type not allowed", rejectedFiles);
      providerRef.current.enqueueSnackbar(
        `Invalid file format '${rejectedFiles[0].type}'. Only .kml files are allowed.`,
        { variant: "error" }
      );
      return;
    }
    if (acceptedFiles && acceptedFiles.length > 1) {
      providerRef.current.enqueueSnackbar(
        `You can only upload one single KML file.`,
        {
          variant: "error",
        }
      );
      return;
    }
    if (acceptedFiles && acceptedFiles.length === 1) {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          kmlFileData = reader.result;
          handleFileUpload(acceptedFiles, kmlFileData);
        };
        reader.readAsText(file);
      });
    }
  };

  const handleFileUpload = async (acceptedFiles, kmlFileData) => {
    try {
      const geoJson = kmlParser.toGeoJson(kmlFileData);
      if (
        !geoJson ||
        !geoJson.features ||
        !geoJson.features[0] ||
        !geoJson.features[0].geometry ||
        !geoJson.features[0].geometry.coordinates ||
        !geoJson.features[0].geometry.coordinates.length
      ) {
        providerRef.current.enqueueSnackbar(`KML is not valid `, {
          variant: "error",
        });
        return;
      }
      const area = await getLocCoordinate({ geoJson });
      if (!area) {
        return;
      }
      const file = acceptedFiles[0];
      const uploadFileName = `${+new Date()}-${file.name}`;
      setOrigFileName(file.name);

      const storageRef = firebase
        .storage()
        .ref()
        .child(`kmlFiles/${uploadFileName}`);

      const uploadFile = storageRef.put(file);

      setUploadInProgress(true);

      uploadFile.on(
        "state_changed",
        (snapshot) => {
          const progress = snapshot.bytesTransferred / snapshot.totalBytes;
          const roundedProgress =
            progress < 0.01
              ? Math.round(progress * 10000) / 100
              : Math.round(progress * 1000) / 10;

          switch (snapshot.state) {
            case "running":
              setProgress(roundedProgress);
              break;
            case "canceled":
              setUploadInProgress(false);
              break;
            case "paused":
              break;
            default:
              console.error("Unpexpected upload state", snapshot.state);
              break;
          }
        },
        (error) => {
          // on error
          setUploadInProgress(false);
          console.error(error);
          switch (error.code) {
            case "storage/canceled":
              // providerRef.current.enqueueSnackbar(`Upload canceled`);
              enqueueSnackbar("Upload canceled", {
                variant: "error",
              });
              break;
            default:
              // providerRef.current.enqueueSnackbar(
              //   `An unexpected error occurred. Make sure you're uploading a .kml file no larger than 1MB.`,
              //   {
              //     variant: "error",
              //   }
              // );
              enqueueSnackbar(
                `An unexpected error occurred. Make sure you're uploading a .kml file no larger than 1MB.`,
                {
                  variant: "error",
                }
              );
              break;
          }
        },
        () => {
          // on success
          uploadFile.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              onUploaded(downloadURL, area, origFileName);
            })
            .then(() => {
              // providerRef.current.enqueueSnackbar(`Upload completed`, {
              //   variant: "success",
              // });
              enqueueSnackbar("Upload completed", {
                variant: "success",
              });
              setUploadInProgress(false);
            })
            .catch((e) => {
              setUploadInProgress(false);
              console.log(e);
              // providerRef.current.enqueueSnackbar(`Upload failed. `, {
              //   variant: "error",
              // });
              enqueueSnackbar("Upload failed.", {
                variant: "error",
              });
            });
        }
      );
    } catch (e) {
      console.log("Error", e);
      // providerRef.current.enqueueSnackbar(`KML is not valid `, {
      //   variant: "error",
      // });
      enqueueSnackbar("KML is not valid", {
        variant: "error",
      });
    }
  };

  const disabledColors = () => {
    if (isAdmin && disabled) {
      return "blackDisabled";
    } else if (isAdmin && !disabled) {
      return "blue";
    } else if (isClient && disabled) {
      return "greenDisabled";
    } else if (isPilot && disabled) {
      return "greenDisabled";
    } else if (isPilot && !disabled) {
      return "purple";
    } else {
      return "green";
    }
  };

  return (
    // <SnackbarProvider ref={providerRef} maxSnack={4}>
    <Box sx={style.root}>
      {uploadInProgress ? (
        <Box sx={style.spinnerContainer}>
          <CircularProgress
            variant="determinate"
            value={progress}
            sx={style.spinner}
          />
        </Box>
      ) : disabled ? (
        <UploadButton
          text="upload a kml file"
          look={disabledColors()}
          isDisabled={disabled}
        />
      ) : (
        <Dropzone accept={[".kml"]} onDrop={handleFileDrop}>
          {({ getRootProps, getInputProps }) => (
            <Box {...getRootProps()}>
              <input {...getInputProps()} id="inputUploadKML" />
              <UploadButton
                text="upload a kml file"
                look={disabledColors()}
                isDisabled={disabled}
              />
            </Box>
          )}
        </Dropzone>
      )}
    </Box>
    // </SnackbarProvider>
  );
};

KMLFileDropZone.propTypes = {
  onUploaded: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  user: PropTypes.string,
};
KMLFileDropZone.defaultProps = {
  disabled: false,
  user: undefined,
};

export default KMLFileDropZone;
