import React, { useRef } from "react";
import { Box, Typography, Autocomplete } from "@mui/material";
import Layout from "../../../../Layout";
import Tips from "../../../global/elements/Tips";
import countries from "src/lib/countries";
import { availabilityData } from "../../../global/PilotData";
import {
  InitialRequirements,
  ObjectTypes,
  drones,
  sensorsCamerasList,
  missionMapTypes,
  UploadTo,
  Services,
  whoWillDoThis,
  InitialRequirementsMessages,
  TypeMessages,
  SizeMessages,
  CountryMessages,
  DroneTypeMessages,
  SensorsCamerasMessages,
  TimeOfTheDay,
  Weather,
  Altitude,
  DroneOtherRequest,
  ProductTypeMessages,
  ProcessDataMessages,
  ResolutionMessages,
  UploadTomessages,
  SpecificAcceptanceMessages,
  OneTimeMessages,
  RecAreaMessages,
  RecInternal,
  DeadlineMessages,
  ServicesMessages,
  WhoWillMessages,
  estimatedPriceMessages,
  InitialStateMessage,
  PilotMessages,
  SalesAssistantStatusName,
  colorStatus,
} from "../../../../lib/constants";
import { TextField } from "formik-mui";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import theme from "../../../../theme";
import AppButton from "../../../global/elements/AppButton";
import StatusBadge from "../../../global/elements/StatusBadge";
import { useReactToPrint } from "react-to-print";
import PropTypes from "prop-types";
import TooltipInfo from "../../../global/elements/TooltipInfo";
import { boxShadowStyle } from "../../../../lib/styleConstants";
import SwitchSelect from "../../../global/elements/SwitchSelect";

const style = {
  BoxOutline: {
    padding: { xs: "20px 10px", md: "20px 30px" },
    margin: "20px 0px",
    backgroundColor: theme.palette.grey.white,
  },
  ComplexityLevelBox: {
    background: theme.palette.secondary.lavender,
    padding: {
      xs: "20px 15px",
      md: "20px 5px",
      lg: "17px 30px",
    },
    paddingBottom: { md: "1px", lg: "3px" },
  },
  complexity: {
    color: theme.palette.grey.white,
  },
  complexityHelpers: {
    paddingLeft: "10px",
    width: "55%",
    display: "flex",
    flexDirection: "column",
  },
  vertical_line: {
    height: { sm: "15vh", md: "18vh", lg: "20vh", xl: "12vh" },
    background: theme.palette.grey.white,
    width: "1px",
  },
  ComplexityLevelSection: {
    width: { xs: "100%", lg: "50%" },
    marginLeft: { lg: "20px" },
    margin: "20px 0",
  },
  estimatedPriceSection: {
    width: { xs: "100%", lg: "50%" },
    paddingBottom: "5px",
  },
  input_cls: {
    width: "100%",
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      color: theme.palette.grey.warmGrey,
    },
    "& .MuiChip-root": {
      color: theme.palette.black.darkSeaBlack,
      background: theme.palette.grey.lightWarmGrey,
    },
  },
  label_text: {
    marginBottom: "5px",
    paddingLeft: "3px",
  },
  switchRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "15px",
  },
  estimatedPriceBox: {
    display: { xs: "block", lg: "flex" },
  },
  estimatedPrice: {
    width: "100%",
    alignItems: "center",
    display: "block",
  },
  ButtonSection: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", sm: "row" },
  },
  feasibleGreen: {
    color: theme.palette.status.successDark,
  },
  complicatedYellow: {
    color: theme.palette.status.warningDark,
  },
  notFeasibleRed: {
    color: theme.palette.status.errorLight,
  },
  statusSpace: {
    display: { xs: "block", sm: "flex" },
    justifyContent: "space-between",
    marginTop: "20px",
    alignItems: "center",
  },
  inputAndStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: { xs: "100%", sm: "40%" },
  },
  statusBadgeAndText: {
    marginRight: "20px",
    marginTop: { xs: "10px", sm: 0 },
  },
  messageText: {
    width: { xs: "95%", sm: "50%" },
    marginTop: { xs: "10px", sm: 0 },
  },
  pilotCnt: {
    marginLeft: "20px",
    marginTop: "12px",
    width: "40%",
  },
  usdText: {
    marginLeft: "10px",
  },
};

const SalesAssistant = ({ loading, pageTitle }) => {
  const Ref = useRef();
  let recAreaPrevValue = useRef("");
  let intervalRef = useRef("");
  let pilotCount = null;
  let nFCnt = 0;
  let CCnt = 0;
  let fCnt = 0;
  let flag = 0;
  let objectTypeFlag = 0;
  let sizeOrLengthFlag = 0;
  let countryComplicatedFlag = 0;
  let countryNotFeasibleFlage = 0;
  let PilotCntComplicated = 0;
  let pilotCntNotFeasible = 0;
  let droneTypeFlag = 0;
  let sensorsCamerasFlag = 0;
  let timeOfDayFlag = 0;
  let productTypeFlag = 0;
  let weatherFlag = 0;
  let altitudeFlag = 0;
  let otherReqFlag = 0;
  let resolutionFlag = 0;
  let uploadToFlag = 0;
  let specificAcceptanceFlag = 0;
  let deadlineFlag = 0;
  let servicesFlag = 0;
  let whoWillDoThisFlag = 0;
  let recurrentOrderFlag = 0;

  const formInitialValues = {
    initialRequirements: "",
    objectTypes: "",
    sizeOrLength: "",
    country: "",
    drone: "",
    sensorsCameras: "",
    timeOfDay: "",
    weather: "",
    altitude: "",
    otherReq: "",
    missionMapType: "",
    processedData: false,
    oneTime: false,
    interval: false,
    recArea: false,
    resolution: "",
    uploadTo: "",
    specificAcceptance: "",
    service: "",
    whoWillDoThis: "",
    PricePerMB: "",
    deadline: "",
  };

  const validationSchema = Yup.object().shape({
    initialRequirements: Yup.string(),
    objectTypes: Yup.string(),
    sizeOrLength: Yup.number().positive().typeError("Only number allowed"),
    country: Yup.string(),
    drone: Yup.string(),
    sensorsCameras: Yup.string(),
    timeOfDay: Yup.number().positive().typeError("Only number allowed"),
    weather: Yup.string().typeError("Only string allowed"),
    altitude: Yup.number().positive().typeError("Only number allowed"),
    otherReq: Yup.string().typeError("Only string allowed"),
    missionMapType: Yup.string(),
    resolution: Yup.number().positive().typeError("Only number allowed"),
    uploadTo: Yup.string(),
    specificAcceptance: Yup.string().typeError("Only string allowed"),
    deadline: Yup.number().positive().typeError("Only number allowed"),
    service: Yup.string(),
    whoWillDoThis: Yup.string(),
    PricePerMB: Yup.number().positive().typeError("Only number allowed"),
    processedData: Yup.bool(),
    oneTime: Yup.bool(),
    recArea: Yup.bool(),
    interval: Yup.bool(),
  });

  const ShowStatus = ({ status, name }) => {
    return (
      <Box>
        <StatusBadge name={name} status={status} />
      </Box>
    );
  };

  const handlePrint = useReactToPrint({
    content: () => Ref.current,
  });

  const resetForm = () => {
    CCnt = 0;
    nFCnt = 0;
    fCnt = 0;
    document.getElementById("salesAssistantId").reset();
  };

  const MessageComponent = ({ msgText, status }) => {
    switch (status) {
      case "feasible": {
        return (
          <Typography variant="body1" sx={style.feasibleGreen}>
            {msgText}
          </Typography>
        );
      }
      case "complicated":
        return (
          <Typography variant="body1" sx={style.complicatedYellow}>
            {msgText}
          </Typography>
        );
      case "not feasible":
        return (
          <Typography variant="body1" sx={style.notFeasibleRed}>
            {msgText}
          </Typography>
        );
      case "PriceMsg":
        return <Typography variant="body1">{msgText}</Typography>;

      case "":
        return <></>;
      default:
        return;
    }
  };
  const Title = ({ title }) => <Typography variant="h6">{title}</Typography>;
  Title.propTypes = {
    title: PropTypes.string.isRequired,
  };
  const handleSubmit = (values) => {
    console.log(values);
  };

  const InitialFunction = (value) => {
    let status = null;
    if (value == "other") {
      status = "complicated";
    } else {
      status = "feasible";
    }

    if (status == "complicated") {
      if (flag == 0) {
        CCnt++;
        flag = 1;
      }
    }
    if (flag == 1 && status == "feasible") {
      CCnt--;
      flag = 0;
    }
  };

  const objectType = (value) => {
    let status = null;
    if (value != "Area") {
      status = "complicated";
    } else {
      status = "feasible";
    }

    if (status == "complicated") {
      if (objectTypeFlag == 0) {
        CCnt++;
        objectTypeFlag = 1;
      }
    }
    if (objectTypeFlag == 1 && status == "feasible") {
      CCnt--;
      objectTypeFlag = 0;
    }
  };

  const sizeOrLengthFun = (objectType, value) => {
    let status = null;
    if (
      (objectType === "Area" && value < 1) ||
      (objectType === "Linear" && value < 300) ||
      (objectType === "Individual Assests" && value < 300)
    ) {
      status = "feasible";
    } else {
      status = "complicated";
    }
    if (status == "complicated") {
      if (sizeOrLengthFlag == 0) {
        CCnt++;
        sizeOrLengthFlag = 1;
      }
    }
    if (sizeOrLengthFlag == 1 && status == "feasible") {
      CCnt--;
      sizeOrLengthFlag = 0;
    }
  };

  const countryStatusCnt = (value) => {
    let status = null;
    if (
      value === "Egypt" ||
      value === "Morocco" ||
      value === "Antarctica" ||
      value === "Tunisia" ||
      value === "Senegal" ||
      value === "Madagascar" ||
      value === "Ivory Coast" ||
      value === "Barbados"
    ) {
      status = "complicated";
    } else if (
      value === "Ukraine" ||
      value === "Iraq" ||
      value === "Korea (the Democratic People's Republic of)" ||
      value === "Syrian Arab Republic" ||
      value === "Lebanon" ||
      value === "Iran (Islamic Republic of)" ||
      value === "Bahrain" ||
      value === "Uzbekistan" ||
      value === "Nicaragua" ||
      value === "Kuwait" ||
      value === "Kyrgyzstan" ||
      value === "Cuba" ||
      value === "Brunei Darussalam" ||
      value === "Bhutan" ||
      value === "Algeria" ||
      value === "Russian Federation (the)"
    ) {
      status = "not feasible";
    } else {
      status = "feasible";
    }
    if (status == "complicated") {
      if (countryComplicatedFlag == 0) {
        CCnt++;
        countryComplicatedFlag = 1;
      }
    }
    if (status == "not feasible") {
      if (countryNotFeasibleFlage == 0) {
        nFCnt++;
        countryNotFeasibleFlage = 1;
      }
    }
    if (countryComplicatedFlag == 1 && status == "feasible") {
      CCnt--;
      countryComplicatedFlag = 0;
    }
    if (countryNotFeasibleFlage == 1 && status == "feasible") {
      nFCnt--;
      countryNotFeasibleFlage = 0;
    }
  };

  const pilotCountStatus = (value) => {
    let status = null;
    if (value < 10) {
      status = "complicated";
    } else if (value == 0) {
      status = "not feasible";
    } else {
      status = "feasible";
    }
    if (status == "complicated") {
      if (PilotCntComplicated == 0) {
        CCnt++;
        PilotCntComplicated = 1;
      }
    } else if (status == "not feasible") {
      if (pilotCntNotFeasible == 0) {
        nFCnt++;
        pilotCntNotFeasible = 1;
      }
    }

    if ((PilotCntComplicated = 1 && status == "feasible")) {
      CCnt--;
      PilotCntComplicated = 0;
    }

    if ((pilotCntNotFeasible = 1 && status == "feasible")) {
      CCnt--;
      pilotCntNotFeasible = 0;
    }
  };

  const droneType = (value) => {
    let status = null;
    if (value) {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated" && droneTypeFlag == 0) {
      CCnt++;
      droneTypeFlag = 1;
    }
    if (status == "feasible" && droneTypeFlag == 1) {
      CCnt--;
      droneTypeFlag = 0;
    }
  };
  const sensorsCamerasFun = (value) => {
    let status = null;
    if (value) {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated") {
      CCnt++;
      sensorsCamerasFlag = 1;
    }
    if (status == "feasible" && sensorsCamerasFlag == 1) {
      CCnt--;
      sensorsCamerasFlag = 0;
    }
  };

  const timeOfDayFun = (value) => {
    let status = null;
    if (value) {
      status = "complicated";
    } else if (value == "") {
      status = "feasible";
    }
    if (status == "complicated" && timeOfDayFlag == 0) {
      CCnt++;
      timeOfDayFlag = 1;
    }
    if (status == "feasible" && timeOfDayFlag == 1) {
      CCnt--;
      timeOfDayFlag = 0;
    }
  };

  const weatherFun = (value) => {
    let status = null;
    if (value) {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated" && weatherFlag == 0) {
      CCnt++;
      weatherFlag = 1;
    }
    if (status == "feasible" && weatherFlag == 1) {
      CCnt--;
      weatherFlag = 0;
    }
  };

  const altitudeFun = (value) => {
    let status = null;
    if (value) {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated" && altitudeFlag == 0) {
      CCnt++;
      altitudeFlag = 1;
    }
    if (status == "feasible" && altitudeFlag == 1) {
      CCnt--;
      altitudeFlag = 0;
    }
  };

  const otherReqFun = (value) => {
    let status = null;
    if (value) {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated" && otherReqFlag == 0) {
      CCnt++;
      otherReqFlag = 1;
    }
    if (status == "feasible" && otherReqFlag == 1) {
      CCnt--;
      otherReqFlag = 0;
    }
  };

  const productType = (value) => {
    let status = null;
    if (value) {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated") {
      CCnt++;
      productTypeFlag = 1;
    }
    if (status == "feasible" && productTypeFlag == 1) {
      CCnt--;
      productTypeFlag = 0;
    }
  };

  const resolutionFun = (value) => {
    let status = null;
    if (value > 1.5) {
      status = "complicated";
    } else if (value < 1.5 || value == "" || value.length != value.length) {
      status = "feasible";
    }
    if (status == "complicated") {
      CCnt++;
      resolutionFlag = 1;
    }
    if (status == "feasible" && resolutionFlag == 1) {
      CCnt--;
      resolutionFlag = 0;
    }
  };

  const uploadToFun = (value) => {
    let status = null;
    if (value == "Another platform") {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated" && uploadToFlag == 0) {
      CCnt++;
      uploadToFlag = 1;
    }
    if (status == "feasible" && uploadToFlag == 1) {
      CCnt--;
      uploadToFlag = 0;
    }
  };

  const specificAcceptanceFun = (value) => {
    let status = null;
    if (value) {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated") {
      CCnt++;
      specificAcceptanceFlag = 1;
    }
    if (status == "feasible" && specificAcceptanceFlag == 1) {
      CCnt--;
      specificAcceptanceFlag = 0;
    }
  };

  const deadlineFun = (value) => {
    let status = null;
    if (value < 2) {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated" && deadlineFlag == 0) {
      CCnt++;
      deadlineFlag = 1;
    }
    if (status == "feasible" && deadlineFlag == 1) {
      CCnt--;
      deadlineFlag = 0;
    }
  };

  const servicesFun = (value) => {
    let status = null;
    if (value == "Just data" || value == "") {
      status = "feasible";
    } else {
      status = "complicated";
    }
    if (status == "complicated" && servicesFlag == 0) {
      CCnt++;
      servicesFlag = 1;
    }
    if (status == "feasible" && servicesFlag == 1) {
      CCnt--;
      servicesFlag = 0;
    }
  };

  const whoWillDoThisFun = (value) => {
    let status = null;
    if (value) {
      status = "complicated";
    } else {
      status = "feasible";
    }
    if (status == "complicated") {
      CCnt++;
      whoWillDoThisFlag = 1;
    }
    if (status == "feasible" && whoWillDoThisFlag == 1) {
      CCnt--;
      whoWillDoThisFlag = 0;
    }
  };

  const recurrentOrderFun = (value) => {
    let status = null;

    if (value == true) {
      status = "complicated";
    } else if (value == false) {
      status = "feasible";
    }
    if (status == "complicated") {
      CCnt = CCnt + 3;
      recurrentOrderFlag = 1;
    }
    if (status == "feasible") {
      let deductCnt = 3;
      if (recAreaPrevValue.current == true) {
        deductCnt--;
      }
      if (intervalRef.current == true) {
        deductCnt--;
      }
      CCnt = CCnt - deductCnt;
    }
  };

  const recAreaFun = (value, oneTime) => {
    recAreaPrevValue.current = value;
    let status = null;
    if (value == true) {
      status = "feasible";
    } else {
      status = "complicated";
    }
    if (status == "feasible" && oneTime === true) {
      CCnt--;
    }
  };

  const intervalFun = (value, oneTime) => {
    let status = null;
    intervalRef.current = value;
    if (value == true) {
      status = "feasible";
    }
    if (status == "feasible" && oneTime === true) {
      CCnt--;
    }
  };

  const pilotsByCountry = (countryName) => {
    if (countryName) {
      const noOfPilot = availabilityData.filter(
        (item) => countryName === item.country
      );
      pilotCount = noOfPilot[0]?.pilots;
      if (pilotCount === undefined) {
        pilotCount = 0;
      }
      pilotCountStatus(pilotCount);
    } else {
      pilotCount = null;
    }
  };

  return (
    <Layout
      backButtonPageName={pageTitle || "Sales Assistant"}
      hasSideMenu
      loading={loading}
      addBottomPadding
      backButton
    >
      <Box ref={Ref}>
        <Tips content="Use this tool to evaluate if projects are feasible in the sales pipeline. Connect with operations in case you have questions." />
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form id="salesAssistantId" sx={{ marginTop: "20px" }}>
                <Box sx={{ ...style.BoxOutline, ...boxShadowStyle }}>
                  <Title title="Functional need" />
                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Autocomplete
                        value={values.initialRequirements}
                        options={InitialRequirements}
                        onInputChange={(e, value) => {
                          setFieldValue("initialRequirements", value);
                          InitialFunction(value);
                        }}
                        sx={{ "&": style.input_cls }}
                        variant="outlined"
                        renderInput={(params) => (
                          <Field
                            {...params}
                            type="text"
                            name="initialRequirements"
                            component={TextField}
                            label="Initial Requirements"
                            variant="outlined"
                          />
                        )}
                      />
                      <TooltipInfo info="What does the client want from the project? Do they just want you to capture data or something else?" />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          values.initialRequirements === "other"
                            ? SalesAssistantStatusName.complicated
                            : !values.initialRequirements
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.feasible
                        }
                        status={
                          values.initialRequirements === "other"
                            ? colorStatus.red
                            : !values.initialRequirements
                            ? colorStatus.green
                            : colorStatus.neutral
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          values.initialRequirements === "other"
                            ? InitialRequirementsMessages.Other
                            : !values.initialRequirements
                            ? InitialStateMessage.NoSelect
                            : InitialRequirementsMessages.JustDataCapture
                        }
                        status={
                          values.initialRequirements === "other"
                            ? "complicated"
                            : "feasible"
                        }
                      />
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ ...style.BoxOutline, ...boxShadowStyle }}>
                  <Title title="Object type" />
                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Autocomplete
                        value={values.objectTypes}
                        options={ObjectTypes}
                        onInputChange={(e, value) => {
                          setFieldValue("objectTypes", value);
                          objectType(value);
                        }}
                        sx={{ "&": style.input_cls }}
                        variant="outlined"
                        renderInput={(params) => (
                          <Field
                            {...params}
                            type="text"
                            name="objectTypes"
                            component={TextField}
                            label="What type of object"
                            variant="outlined"
                          />
                        )}
                      />
                      <TooltipInfo info="Select here the type of object that the client wants to scan. Is it an area delimited by a polygon, a linear object such as a road, an asset such as a house or a telecome tower, or something else?" />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          values.objectTypes === "Area"
                            ? SalesAssistantStatusName.feasible
                            : !values.objectTypes
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          values.objectTypes === "Area"
                            ? colorStatus.green
                            : !values.objectTypes
                            ? colorStatus.green
                            : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          values.objectTypes === "Area"
                            ? TypeMessages.Area
                            : !values.objectTypes
                            ? InitialStateMessage.NoSelect
                            : values.objectTypes === "Linear"
                            ? TypeMessages.Linear
                            : values.objectTypes === "Individual Assests"
                            ? TypeMessages.IndividualAssets
                            : TypeMessages.Other
                        }
                        status={
                          values.objectTypes === "Area"
                            ? "feasible"
                            : !values.objectTypes
                            ? "feasible"
                            : "complicated"
                        }
                      />
                    </Box>
                  </Box>
                  {values.objectTypes && (
                    <Box sx={style.statusSpace}>
                      <Box sx={style.inputAndStatus}>
                        <Field
                          type="number"
                          name="sizeOrLength"
                          value={values.sizeOrLength}
                          component={TextField}
                          label="Insert size or length (in km2 or m)"
                          variant="outlined"
                          onChange={(e) => {
                            setFieldValue("sizeOrLength", e.target.value);
                            sizeOrLengthFun(values.objectTypes, e.target.value);
                          }}
                          sx={{ "&": style.input_cls }}
                        />
                        <TooltipInfo info="Insert the area size in km2 or the the length of the linear object in meters. Areas less than 1 km2 are considered feasible while linear objects less than 300 meters are considered feasible. The price calculation below will depend on what you insert here." />
                      </Box>

                      <Box sx={style.statusBadgeAndText}>
                        <ShowStatus
                          name={
                            values.objectTypes === "Area" &&
                            values.sizeOrLength <= 1
                              ? SalesAssistantStatusName.feasible
                              : values.objectTypes === "Linear" &&
                                values.sizeOrLength <= 300
                              ? SalesAssistantStatusName.feasible
                              : values.objectTypes === "Individual Assests" &&
                                values.sizeOrLength <= 300
                              ? SalesAssistantStatusName.feasible
                              : !values.sizeOrLength
                              ? SalesAssistantStatusName.feasible
                              : SalesAssistantStatusName.complicated
                          }
                          status={
                            values.objectTypes === "Area" &&
                            values.sizeOrLength <= 1
                              ? colorStatus.green
                              : values.objectTypes === "Linear" &&
                                values.sizeOrLength <= 300
                              ? colorStatus.green
                              : values.objectTypes === "Individual Assests" &&
                                values.sizeOrLength <= 300
                              ? colorStatus.green
                              : !values.sizeOrLength
                              ? colorStatus.green
                              : colorStatus.red
                          }
                        />
                      </Box>
                      <Box sx={style.messageText}>
                        <MessageComponent
                          msgText={
                            values.objectTypes === "Area" &&
                            values.sizeOrLength <= 1
                              ? SizeMessages.AreaSizeFeasible
                              : values.objectTypes === "Area" &&
                                values.sizeOrLength > 1
                              ? SizeMessages.AreaSizeComplicated
                              : values.objectTypes === "Linear" &&
                                values.sizeOrLength <= 300
                              ? SizeMessages.LinearSizeFeasible
                              : values.objectTypes === "Linear" &&
                                values.sizeOrLength > 300
                              ? SizeMessages.LinearSizeComplicated
                              : values.objectTypes === "Individual Assests" &&
                                values.sizeOrLength <= 300
                              ? SizeMessages.IndividualAssetsSizeFeasible
                              : values.objectTypes === "Individual Assests" &&
                                values.sizeOrLength > 300
                              ? SizeMessages.IndividualAssetsSizeComplicated
                              : !values.sizeOrLength
                              ? InitialStateMessage.NoSelect
                              : ""
                          }
                          status={
                            values.objectTypes === "Area" &&
                            values.sizeOrLength <= 1
                              ? "feasible"
                              : values.objectTypes === "Linear" &&
                                values.sizeOrLength <= 300
                              ? "feasible"
                              : values.objectTypes === "Individual Assests" &&
                                values.sizeOrLength <= 300
                              ? "feasible"
                              : !values.sizeOrLength
                              ? "feasible"
                              : "complicated"
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box sx={{ ...style.BoxOutline, ...boxShadowStyle }}>
                  <Title title="Location and pilot availability" />
                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Autocomplete
                        value={values.country}
                        options={countries}
                        onInputChange={(e, value) => {
                          setFieldValue("country", value);
                          pilotsByCountry(value);
                          countryStatusCnt(value);
                        }}
                        sx={{ "&": style.input_cls }}
                        variant="outlined"
                        renderInput={(params) => (
                          <Field
                            {...params}
                            type="text"
                            name="country"
                            component={TextField}
                            label="Country"
                            variant="outlined"
                          />
                        )}
                      />
                      <TooltipInfo info="Select the country of the project to see if it is straightforward to fly in this country or not." />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          values.country === "Egypt" ||
                          values.country === "Morocco" ||
                          values.country === "Antarctica" ||
                          values.country === "Tunisia" ||
                          values.country === "Senegal" ||
                          values.country === "Madagascar" ||
                          values.country === "Ivory Coast" ||
                          values.country === "Barbados"
                            ? SalesAssistantStatusName.complicated
                            : values.country === "Ukraine" ||
                              values.country === "Iraq" ||
                              values.country ===
                                "Korea (the Democratic People's Republic of)" ||
                              values.country === "Syrian Arab Republic" ||
                              values.country === "Lebanon" ||
                              values.country === "Iran (Islamic Republic of)" ||
                              values.country === "Bahrain" ||
                              values.country === "Uzbekistan" ||
                              values.country === "Nicaragua" ||
                              values.country === "Kuwait" ||
                              values.country === "Kyrgyzstan" ||
                              values.country === "Cuba" ||
                              values.country === "Brunei Darussalam" ||
                              values.country === "Bhutan" ||
                              values.country === "Algeria" ||
                              values.country === "Russian Federation (the)" ||
                              pilotCount === 0
                            ? SalesAssistantStatusName.notFeasible
                            : !values.country && pilotCount == null
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.feasible
                        }
                        status={
                          values.country === "Egypt" ||
                          values.country === "Morocco" ||
                          values.country === "Antarctica" ||
                          values.country === "Tunisia" ||
                          values.country === "Senegal" ||
                          values.country === "Madagascar" ||
                          values.country === "Ivory Coast" ||
                          values.country === "Barbados"
                            ? colorStatus.red
                            : values.country === "Ukraine" ||
                              values.country === "Iraq" ||
                              values.country ===
                                "Korea (the Democratic People's Republic of)" ||
                              values.country === "Syrian Arab Republic" ||
                              values.country === "Lebanon" ||
                              values.country === "Iran (Islamic Republic of)" ||
                              values.country === "Bahrain" ||
                              values.country === "Uzbekistan" ||
                              values.country === "Nicaragua" ||
                              values.country === "Kuwait" ||
                              values.country === "Kyrgyzstan" ||
                              values.country === "Cuba" ||
                              values.country === "Brunei Darussalam" ||
                              values.country === "Bhutan" ||
                              values.country === "Algeria" ||
                              values.country === "Russian Federation (the)" ||
                              pilotCount === 0
                            ? colorStatus.renderInput
                            : !values.country && pilotCount == null
                            ? colorStatus.green
                            : colorStatus.green
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          values.country === "Egypt" ||
                          values.country === "Morocco" ||
                          values.country === "Antarctica" ||
                          values.country === "Tunisia" ||
                          values.country === "Senegal" ||
                          values.country === "Madagascar" ||
                          values.country === "Ivory Coast" ||
                          values.country === "Barbados"
                            ? CountryMessages.CountryComplicated
                            : values.country === "Ukraine" ||
                              values.country === "Iraq" ||
                              values.country ===
                                "Korea (the Democratic People's Republic of)" ||
                              values.country === "Syrian Arab Republic" ||
                              values.country === "Lebanon" ||
                              values.country === "Iran (Islamic Republic of)" ||
                              values.country === "Bahrain" ||
                              values.country === "Uzbekistan" ||
                              values.country === "Nicaragua" ||
                              values.country === "Kuwait" ||
                              values.country === "Kyrgyzstan" ||
                              values.country === "Cuba" ||
                              values.country === "Brunei Darussalam" ||
                              values.country === "Bhutan" ||
                              values.country === "Algeria" ||
                              values.country === "Russian Federation (the)" ||
                              pilotCount === 0
                            ? CountryMessages.CountryNotFeasible
                            : !values.country && pilotCount == null
                            ? InitialStateMessage.NoSelect
                            : ""
                        }
                        status={
                          values.country === "Egypt" ||
                          values.country === "Morocco" ||
                          values.country === "Antarctica" ||
                          values.country === "Tunisia" ||
                          values.country === "Senegal" ||
                          values.country === "Madagascar" ||
                          values.country === "Ivory Coast" ||
                          values.country === "Barbados"
                            ? "complicated"
                            : values.country === "Ukraine" ||
                              values.country === "Iraq" ||
                              values.country ===
                                "Korea (the Democratic People's Republic of)" ||
                              values.country === "Syrian Arab Republic" ||
                              values.country === "Lebanon" ||
                              values.country === "Iran (Islamic Republic of)" ||
                              values.country === "Bahrain" ||
                              values.country === "Uzbekistan" ||
                              values.country === "Nicaragua" ||
                              values.country === "Kuwait" ||
                              values.country === "Kyrgyzstan" ||
                              values.country === "Cuba" ||
                              values.country === "Brunei Darussalam" ||
                              values.country === "Bhutan" ||
                              values.country === "Algeria" ||
                              values.country === "Russian Federation (the)" ||
                              pilotCount === 0
                            ? "not feasible"
                            : !values.country && pilotCount == null
                            ? "feasible"
                            : ""
                        }
                      />
                    </Box>
                  </Box>
                  {values.country && (
                    <Box sx={style.statusSpace}>
                      <Typography variant="body1" sx={style.pilotCnt}>
                        Pilot Count: {pilotCount}
                      </Typography>
                      {
                        <Box sx={style.statusBadgeAndText}>
                          <ShowStatus
                            name={
                              pilotCount > 0 && pilotCount < 10
                                ? SalesAssistantStatusName.complicated
                                : pilotCount === 0
                                ? SalesAssistantStatusName.notFeasible
                                : SalesAssistantStatusName.feasible
                            }
                            status={
                              pilotCount > 0 && pilotCount < 10
                                ? colorStatus.red
                                : pilotCount === 0
                                ? colorStatus.red
                                : colorStatus.green
                            }
                          />
                        </Box>
                      }
                      {
                        <Box
                          sx={{
                            ...style.statusBadgeAndText,
                            ...style.messageText,
                          }}
                        >
                          <MessageComponent
                            msgText={
                              pilotCount > 0 && pilotCount < 10
                                ? PilotMessages.PilotComplicated
                                : pilotCount === 0
                                ? PilotMessages.PilotNotFeasible
                                : PilotMessages.PilotFeasible
                            }
                            status={
                              pilotCount > 0 && pilotCount < 10
                                ? "complicated"
                                : pilotCount === 0
                                ? "not feasible"
                                : "feasible"
                            }
                          />
                        </Box>
                      }
                    </Box>
                  )}
                </Box>

                <Box sx={{ ...style.BoxOutline, ...boxShadowStyle }}>
                  <Title title="Equipment and data constraints" />
                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Autocomplete
                        value={values.drone}
                        options={drones}
                        onInputChange={(e, value) => {
                          setFieldValue("drone", value);
                          droneType(value);
                        }}
                        sx={{ "&": style.input_cls }}
                        variant="outlined"
                        renderInput={(params) => (
                          <Field
                            {...params}
                            type="text"
                            name="drone"
                            component={TextField}
                            label="Drone Type"
                            variant="outlined"
                          />
                        )}
                      />
                      <TooltipInfo info="Does the client wants you to use a specific drone or any drone is enough? Leave blank if no drone is required." />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          !values.drone
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          !values.drone ? colorStatus.green : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          !values.drone
                            ? DroneTypeMessages.DroneFeasible
                            : DroneTypeMessages.DroneComplicated
                        }
                        status={!values.drone ? "feasible" : "complicated"}
                      />
                    </Box>
                  </Box>

                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Autocomplete
                        value={values.sensorsCameras}
                        options={sensorsCamerasList}
                        onInputChange={(e, value) => {
                          setFieldValue("sensorsCameras", value);
                          sensorsCamerasFun(value);
                        }}
                        sx={{ "&": style.input_cls }}
                        variant="outlined"
                        renderInput={(params) => (
                          <Field
                            {...params}
                            type="text"
                            name="sensorsCameras"
                            component={TextField}
                            label="Sensors / Cameras"
                            variant="outlined"
                          />
                        )}
                      />
                      <TooltipInfo info="Does the client wants you to use a specific sensor or any sensor is enough? Leave blank if none is required." />
                    </Box>

                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          !values.sensorsCameras
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          !values.sensorsCameras
                            ? colorStatus.green
                            : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          !values.sensorsCameras
                            ? SensorsCamerasMessages.SensorFeasible
                            : SensorsCamerasMessages.SensorComplicated
                        }
                        status={
                          !values.sensorsCameras ? "feasible" : "complicated"
                        }
                      />
                    </Box>
                  </Box>

                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Field
                        type="text"
                        name="timeOfDay"
                        value={values.timeOfDay}
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue("timeOfDay", e.target.value);
                          timeOfDayFun(e.target.value);
                        }}
                        label="Time of the day"
                        variant="outlined"
                        sx={{ "&": style.input_cls }}
                      />
                      <TooltipInfo info="Does the client wants at a specific time of the day? Leave blank if none is required." />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          !values.timeOfDay
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          !values.timeOfDay
                            ? colorStatus.green
                            : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          !values.timeOfDay
                            ? TimeOfTheDay.TimeDayFeasible
                            : TimeOfTheDay.TimeDayComplicated
                        }
                        status={!values.timeOfDay ? "feasible" : "complicated"}
                      />
                    </Box>
                  </Box>
                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Field
                        type="text"
                        name="weather"
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue("weather", e.target.value);
                          weatherFun(e.target.value);
                        }}
                        label="Weather"
                        variant="outlined"
                        sx={{ "&": style.input_cls }}
                      />
                      <TooltipInfo info="Does the client wants data captured in a specific weather? Leave blank if none is required." />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          !values.weather
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          !values.weather ? colorStatus.green : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          !values.weather
                            ? Weather.WeatherFeasible
                            : Weather.WeatherComplicated
                        }
                        status={!values.weather ? "feasible" : "complicated"}
                      />
                    </Box>
                  </Box>

                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Field
                        type="number"
                        name="altitude"
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue("altitude", e.target.value);
                          altitudeFun(e.target.value);
                        }}
                        label="Altitude in meter"
                        variant="outlined"
                        sx={{ "&": style.input_cls }}
                      />
                      <TooltipInfo info="Does the client wants data captured at a specific height? Leave blank if no height is required." />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          values.altitude >= 110 && values.altitude <= 120
                            ? SalesAssistantStatusName.feasible
                            : !values.altitude
                            ? SalesAssistantStatusName.feasible
                            : values.altitude > 120
                            ? SalesAssistantStatusName.notFeasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          values.altitude >= 110 && values.altitude <= 120
                            ? colorStatus.green
                            : !values.altitude
                            ? colorStatus.green
                            : values.altitude > 120
                            ? colorStatus.red
                            : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          values.altitude >= 110 && values.altitude <= 120
                            ? Altitude.AltitudeFeasible
                            : !values.altitude
                            ? InitialStateMessage.NoSelect
                            : values.altitude > 120
                            ? Altitude.AltitudeNotFeasible
                            : Altitude.AltitudeComplicated
                        }
                        status={
                          values.altitude >= 110 && values.altitude <= 120
                            ? "feasible"
                            : !values.altitude
                            ? "feasible"
                            : values.altitude > 120
                            ? "not feasible"
                            : "complicated"
                        }
                      />
                    </Box>
                  </Box>

                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      {" "}
                      <Field
                        type="text"
                        name="otherReq"
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue("otherReq", e.target.value);
                          otherReqFun(e.target.value);
                        }}
                        label="Other Request"
                        variant="outlined"
                        sx={{ "&": style.input_cls }}
                      />
                      <TooltipInfo info="Does the client have any other request not mentioned above? Leave blank if none is required." />
                    </Box>

                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          !values.otherReq
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          !values.otherReq ? colorStatus.green : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          !values.otherReq
                            ? InitialStateMessage.NoSelect
                            : DroneOtherRequest.OtherComplicated
                        }
                        status={!values.otherReq ? "feasible" : "complicated"}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ ...style.BoxOutline, ...boxShadowStyle }}>
                  <Title title="Deliverables" />
                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Autocomplete
                        value={values.missionMapType}
                        options={missionMapTypes.map(({ label }) => label)}
                        onInputChange={(e, value) => {
                          setFieldValue("missionMapType", value);
                          productType(value);
                        }}
                        sx={{ "&": style.input_cls }}
                        variant="outlined"
                        renderInput={(params) => (
                          <Field
                            {...params}
                            type="text"
                            name="missionMapType"
                            component={TextField}
                            label="Product Type"
                            variant="outlined"
                          />
                        )}
                      />
                      <TooltipInfo info="What type of product does the client require?" />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={SalesAssistantStatusName.feasible}
                        status={colorStatus.green}
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={ProductTypeMessages.ProductFeasible}
                        status="feasible"
                      />
                    </Box>
                  </Box>

                  <Box sx={{ paddingTop: "20px" }}>
                    <Title title="Processed Data" />

                    <Box sx={style.statusSpace}>
                      <Box sx={style.inputAndStatus}>
                        <Box sx={style.switchRow}>
                          <SwitchSelect
                            name="processedData"
                            checked={values.processedData}
                            handleChange={(e) =>
                              setFieldValue("processedData", e.target.checked)
                            }
                          />
                        </Box>
                        <TooltipInfo info="Does the client requires processed data or raw imagery? Select yes for processed data, and no for raw imagery." />
                      </Box>
                      <Box sx={style.statusBadgeAndText}>
                        <ShowStatus
                          name={SalesAssistantStatusName.feasible}
                          status={colorStatus.green}
                        />
                      </Box>
                      <Box sx={style.messageText}>
                        <MessageComponent
                          msgText={ProcessDataMessages.ProcessDataFeasible}
                          status="feasible"
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Field
                        type="number"
                        name="resolution"
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue("resolution", e.target.value);
                          resolutionFun(e.target.value);
                        }}
                        label="Resolution in cm"
                        variant="outlined"
                        sx={{ "&": style.input_cls }}
                      />
                      <TooltipInfo info="Does the client requires data in a specific resolution? Leave blank if none is required and standard 115 cm will be applied. A higher number corresponds to a lower resolution." />
                    </Box>

                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          values.resolution < 1.5 && values.resolution > 0
                            ? SalesAssistantStatusName.complicated
                            : !values.resolution
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.feasible
                        }
                        status={
                          values.resolution < 1.5 && values.resolution > 0
                            ? colorStatus.red
                            : !values.resolution
                            ? colorStatus.green
                            : colorStatus.green
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          values.resolution < 1.5 && values.resolution > 0
                            ? ResolutionMessages.ResolutionComplicated
                            : !values.resolution
                            ? InitialStateMessage.NoSelect
                            : ResolutionMessages.ResolutionFeasible
                        }
                        status={
                          values.resolution < 1.5 && values.resolution > 0
                            ? "complicated"
                            : !values.resolution
                            ? "feasible"
                            : "feasible"
                        }
                      />
                    </Box>
                  </Box>

                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Autocomplete
                        options={UploadTo}
                        value={values.uploadTo}
                        onInputChange={(e, value) => {
                          setFieldValue("uploadTo", value);
                          uploadToFun(value);
                        }}
                        sx={{ "&": style.input_cls }}
                        variant="outlined"
                        renderInput={(params) => (
                          <Field
                            {...params}
                            type="text"
                            name="uploadTo"
                            component={TextField}
                            label="Upload To"
                            variant="outlined"
                          />
                        )}
                      />
                      <TooltipInfo info="Select where the client wants the data to be uploaded. If left blank, the default answer is the Crowddroning app." />
                    </Box>

                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          values.uploadTo === "Crowddroning API"
                            ? SalesAssistantStatusName.feasible
                            : !values.uploadTo
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          values.uploadTo === "Crowddroning API"
                            ? colorStatus.green
                            : !values.uploadTo
                            ? colorStatus.green
                            : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          values.uploadTo === "Crowddroning API"
                            ? UploadTomessages.UploadToFeasible
                            : !values.uploadTo
                            ? InitialStateMessage.NoSelect
                            : UploadTomessages.UploadToComplicated
                        }
                        status={
                          values.uploadTo === "Crowddroning API"
                            ? "feasible"
                            : !values.uploadTo
                            ? "feasible"
                            : "complicated"
                        }
                      />
                    </Box>
                  </Box>

                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      {" "}
                      <Field
                        type="text"
                        name="specificAcceptance"
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue("specificAcceptance", e.target.value);
                          specificAcceptanceFun(e.target.value);
                        }}
                        label="Specific Acceptance Criteria"
                        variant="outlined"
                        sx={{ "&": style.input_cls }}
                      />
                      <TooltipInfo info="Are there any other specific acceptance criteria by the client that are not mentioned above? Leave blank if none is required." />
                    </Box>

                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          !values.specificAcceptance
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          !values.specificAcceptance
                            ? colorStatus.green
                            : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          !values.specificAcceptance
                            ? InitialStateMessage.NoSelect
                            : SpecificAcceptanceMessages.SpecificComplicated
                        }
                        status={
                          !values.specificAcceptance
                            ? "feasible"
                            : "complicated"
                        }
                      />
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ ...style.BoxOutline, ...boxShadowStyle }}>
                  <Title title="Recurrent Order (One Time?)" />
                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Box sx={style.switchRow}>
                        <SwitchSelect
                          name="oneTime"
                          checked={values.oneTime}
                          handleChange={(e, value) => {
                            setFieldValue("oneTime", e.target.checked);
                            recurrentOrderFun(value);
                          }}
                        />
                      </Box>
                      <TooltipInfo info="Does the client want recurrent order? Select yes if so." />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          values.oneTime === false
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          values.oneTime === false
                            ? colorStatus.green
                            : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          values.oneTime === false
                            ? OneTimeMessages.OneTimeFeasible
                            : OneTimeMessages.OneTimeComplicated
                        }
                        status={
                          values.oneTime === false ? "feasible" : "complicated"
                        }
                      />
                    </Box>
                  </Box>
                  {values.oneTime == true && (
                    <Box sx={{ paddingTop: "20px" }}>
                      <Title title="Same area?" />
                      <Box sx={style.statusSpace}>
                        <Box sx={style.inputAndStatus}>
                          <Box sx={style.switchRow}>
                            <SwitchSelect
                              name="recArea"
                              checked={values.recArea}
                              handleChange={(e, value) => {
                                setFieldValue("recArea", e.target.checked);
                                recAreaFun(value, values.oneTime);
                              }}
                              ref={recAreaPrevValue}
                            />
                          </Box>
                          <TooltipInfo info="Does the client want the recurrent data to be in the same geographic area?" />
                        </Box>
                        <Box sx={style.statusBadgeAndText}>
                          <ShowStatus
                            name={
                              values.recArea === true
                                ? SalesAssistantStatusName.feasible
                                : SalesAssistantStatusName.complicated
                            }
                            status={
                              values.recArea === true
                                ? colorStatus.green
                                : colorStatus.red
                            }
                          />
                        </Box>
                        <Box sx={style.messageText}>
                          <MessageComponent
                            msgText={
                              values.recArea === true
                                ? RecAreaMessages.RecAreaFeasible
                                : RecAreaMessages.RecAreaComplicated
                            }
                            status={
                              values.recArea === true
                                ? "feasible"
                                : "complicated"
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {values.oneTime === true && (
                    <Box sx={{ paddingTop: "20px" }}>
                      <Title title="Same time interval?" />

                      <Box sx={style.statusSpace}>
                        <Box sx={style.inputAndStatus}>
                          <Box sx={style.switchRow}>
                            <SwitchSelect
                              name="interval"
                              checked={values.interval}
                              handleChange={(e, value) => {
                                setFieldValue("interval", e.target.checked);
                                intervalFun(value, values.oneTime);
                              }}
                            />
                          </Box>
                          <TooltipInfo info="Does the client want the recurrent data to be taken at regular time intervals? For example, does the client want to have data every week for the next year, or every week for the first month, then every month till the rest of the year?" />
                        </Box>

                        <Box sx={style.statusBadgeAndText}>
                          <ShowStatus
                            name={
                              values.interval === true
                                ? SalesAssistantStatusName.feasible
                                : SalesAssistantStatusName.complicated
                            }
                            status={
                              values.interval === true
                                ? colorStatus.green
                                : colorStatus.red
                            }
                          />
                        </Box>
                        <Box sx={style.messageText}>
                          <MessageComponent
                            msgText={
                              values.interval === true
                                ? RecInternal.RecInternalFeasible
                                : RecInternal.RecInternalComplicated
                            }
                            status={
                              values.interval === true
                                ? "feasible"
                                : "complicated"
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Field
                        type="number"
                        name="deadline"
                        component={TextField}
                        onChange={(e) => {
                          setFieldValue("deadline", e.target.value);
                          deadlineFun(e.target.value);
                        }}
                        label="Deadline in days"
                        variant="outlined"
                        sx={{ "&": style.input_cls }}
                      />
                      <TooltipInfo info="When does the client want the data?" />
                    </Box>
                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          values.deadline > 14
                            ? SalesAssistantStatusName.feasible
                            : !values.deadline
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          values.deadline > 14
                            ? colorStatus.green
                            : !values.deadline
                            ? colorStatus.green
                            : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          values.deadline > 14
                            ? DeadlineMessages.DeadlineFeasible
                            : !values.deadline
                            ? InitialStateMessage.NoSelect
                            : DeadlineMessages.DeadlineComplicated
                        }
                        status={
                          values.deadline > 14
                            ? "feasible"
                            : !values.deadline
                            ? "feasible"
                            : "complicated"
                        }
                      />
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ ...style.BoxOutline, ...boxShadowStyle }}>
                  <Title title=" Other services from GLOBHE" />
                  <Box sx={style.statusSpace}>
                    <Box sx={style.inputAndStatus}>
                      <Autocomplete
                        options={Services}
                        value={values.service}
                        onInputChange={(e, value) => {
                          setFieldValue("service", value);
                          servicesFun(value);
                        }}
                        sx={{ "&": style.input_cls }}
                        variant="outlined"
                        renderInput={(params) => (
                          <Field
                            {...params}
                            type="text"
                            name="service"
                            component={TextField}
                            label="Services"
                            variant="outlined"
                          />
                        )}
                      />
                      <TooltipInfo info="Is the client expecting more services from GLOBHE or just the data capture?" />
                    </Box>

                    <Box sx={style.statusBadgeAndText}>
                      <ShowStatus
                        name={
                          values.service === "Just data"
                            ? SalesAssistantStatusName.feasible
                            : !values.service
                            ? SalesAssistantStatusName.feasible
                            : SalesAssistantStatusName.complicated
                        }
                        status={
                          values.service === "Just data"
                            ? colorStatus.green
                            : !values.service
                            ? colorStatus.green
                            : colorStatus.red
                        }
                      />
                    </Box>
                    <Box sx={style.messageText}>
                      <MessageComponent
                        msgText={
                          values.service === "Just data"
                            ? ServicesMessages.ServiceFeasible
                            : !values.service
                            ? InitialStateMessage.NoSelect
                            : ServicesMessages.ServiceComplicated
                        }
                        status={
                          values.service === "Just data"
                            ? "feasible"
                            : !values.service
                            ? "feasible"
                            : "complicated"
                        }
                      />
                    </Box>
                  </Box>

                  {values.service && values.service != "Just data" && (
                    <Box sx={style.statusSpace}>
                      <Box sx={style.inputAndStatus}>
                        {" "}
                        <Autocomplete
                          options={whoWillDoThis}
                          value={values.whoWillDoThis}
                          onInputChange={(e, value) => {
                            setFieldValue("whoWillDoThis", value);
                            whoWillDoThisFun(value);
                          }}
                          sx={{ "&": style.input_cls }}
                          variant="outlined"
                          renderInput={(params) => (
                            <Field
                              {...params}
                              type="text"
                              name="whoWillDoThis"
                              component={TextField}
                              label="Who will do this?"
                              variant="outlined"
                            />
                          )}
                        />
                        <TooltipInfo info="Who will be in charge of applying these services?" />
                      </Box>
                      <Box sx={style.statusBadgeAndText}>
                        <ShowStatus
                          name={SalesAssistantStatusName.complicated}
                          status={colorStatus.red}
                        />
                      </Box>
                      <Box sx={style.messageText}>
                        <MessageComponent
                          msgText={WhoWillMessages.WhoWillComplicated}
                          status="complicated"
                        />
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box sx={style.estimatedPriceBox}>
                  {/* <Box
                    sx={{
                      ...style.BoxOutline,
                      ...style.estimatedPriceSection,
                    }}
                  > */}
                  <Box
                    sx={{
                      ...style.BoxOutline,
                      ...style.estimatedPriceSection,
                      ...boxShadowStyle,
                    }}
                  >
                    <Title title="Estimated Price" />
                    <Box
                      sx={{
                        ...style.statusSpace,
                        ...style.estimatedPrice,
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Field
                          type="text"
                          name="PricePerMB"
                          component={TextField}
                          label="USD Price per MB"
                          variant="outlined"
                          sx={{ "&": style.input_cls }}
                        />
                        <Box sx={{ paddingTop: "3%" }}>
                          {" "}
                          <TooltipInfo info="For areas, the price per MB is calculated based on the your input here * 100 * the area size inserted above. For linear objects, the price equals your input here * the length per meter." />
                        </Box>
                      </Box>
                      {values.PricePerMB && (
                        <>
                          <Box>
                            {values.PricePerMB && (
                              <Typography
                                variant="h6"
                                sx={{
                                  display: "flex",
                                  // color: theme.palette.primary.main,
                                  marginTop: "8px",
                                }}
                              >
                                $
                                {values.objectTypes === "Linear"
                                  ? values.sizeOrLength * values.PricePerMB
                                  : (values.sizeOrLength * 100).toFixed(2) *
                                    values.PricePerMB}
                                <span style={style.usdText}>USD</span>
                              </Typography>
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                    <Box>
                      <MessageComponent
                        msgText={
                          values.PricePerMB
                            ? estimatedPriceMessages.PriceMsg
                            : ""
                        }
                        status={values.PricePerMB ? "PriceMsg" : ""}
                      />
                    </Box>
                  </Box>

                  <Box sx={style.ComplexityLevelSection}>
                    <Box
                      sx={{ ...style.ComplexityLevelBox, ...boxShadowStyle }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            paddingRight: "10px",
                            width: "45%",
                          }}
                        >
                          <Typography sx={style.complexity} variant="h5">
                            Complexity Level
                          </Typography>
                          {fCnt == 0 && CCnt == 0 && nFCnt == 0 && (
                            <Typography variant="h2" sx={style.complexity}>
                              1
                            </Typography>
                          )}
                          {CCnt < 4 && CCnt > 0 && nFCnt == 0 && (
                            <Typography variant="h2" sx={style.complexity}>
                              2
                            </Typography>
                          )}
                          {(CCnt > 3 || nFCnt > 0) && (
                            <Typography variant="h2" sx={style.complexity}>
                              3
                            </Typography>
                          )}
                        </Box>
                        <Box sx={style.vertical_line} />
                        <Box sx={style.complexityHelpers}>
                          <Typography
                            variant="subtitle1Med"
                            sx={{ color: theme.palette.primary.main }}
                          >
                            Complexity 1: All fields are green
                          </Typography>
                          <Typography
                            variant="subtitle1Med"
                            sx={{ color: theme.palette.status.warningDark }}
                          >
                            Complexity 2: 1 to 3 fields are yellow
                          </Typography>
                          <Typography
                            variant="subtitle1Med"
                            sx={{ color: theme.palette.status.errorDark }}
                          >
                            Complexity 3: more than 3 fields are yellow or 1
                            field is red
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box sx={style.ButtonSection}>
                  <AppButton
                    label="Reset"
                    look="negative"
                    type="reset"
                    noIcon
                    onClick={resetForm}
                  />
                  <AppButton
                    label="Download as PDF"
                    look="black"
                    onClick={handlePrint}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Layout>
  );
};

SalesAssistant.propTypes = {
  loading: PropTypes.bool,
  pageTitle: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string,
  msgText: PropTypes.string,
};
SalesAssistant.defaultProps = {
  pageTitle: undefined,
  loading: false,
  status: undefined,
  name: undefined,
  msgText: undefined,
};

export default SalesAssistant;
