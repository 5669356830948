import React from "react";
import PropTypes from "prop-types";
import AppButton from "../../../global/elements/AppButton";

const OrderButton = ({ link, name, look }) => {
  return <AppButton url={link} internalLink look={look} label={name} />;
};

OrderButton.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string,
  look: PropTypes.string,
};

OrderButton.defaultProps = {
  name: "Task a drone",
  look: "green",
};
export default OrderButton;
