import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import theme from "../../../theme";
import AppButton from "../../global/elements/AppButton";
import { useSelector, useDispatch } from "react-redux";
import {
  ClearCart,
  deleteCartItem,
} from "../../../redux/actions/cart-detail-action";
import firebase from "src/firebase";
import { getUser } from "../../../services/auth";
import { loadStripe } from "@stripe/stripe-js";
import { dataLibraryPaymentStatus, users } from "../../../lib/constants";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { useSnackbar } from "notistack";
import { convertFileSizeUnit, getCurrentDateTime } from "../../../lib/helpers";
import CartBox from "./CartBox";
import Dialogue from "../../global/Dialogue";
import BackNav from "../../global/elements/BackNav";
import MenuBar from "../../global/elements/MenuBar";
import {
  singlePageOuterContainer,
  singlePageMainContainer,
} from "../../../lib/styleConstants";
import TablePagination from "../../global/Table/TablePagination";

const stripeSecretKey = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY;

const styles = {
  checkoutContainer: {
    textAlignLast: "right",
    paddingBottom: "4em",
    paddingTop: "3em",
  },
  bottomContainer: {
    paddingTop: "3em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    width: { md: "fit-content", sm: "fit-content", xs: "100%" },
    fontSize: "20px",
    color: theme.palette.grey.deepWarmGrey,
  },
  ItemBodyContainer: {
    marginTop: "3em",
    display: "flex",
    flexDirection: "column",
    rowGap: "2em",
  },
  loaderContainer: {
    position: "fixed",
    top: "40vh",
    left: "50vw",
    zIndex: 10,
  },
  detailsContainer: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    justifyContent: "space-between",
    paddingBottom: "1em",
    columnGap: { sm: "6em" },
    color: theme.palette.black.darkSeaBlack,
  },
  costDetailsBox: {
    paddingTop: "1em",
    color: theme.palette.black.darkSeaBlack,
  },

  totalDetails: {
    textAlign: "-webkit-right",
  },
  flex: {
    display: "flex",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexSpace: {
    display: "flex",
    justifyContent: "space-between",
  },
  ferryText: { color: theme.palette.black.coolGrey },
  dialogBody: { padding: "1em 5px" },
};

const Cart = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allData, setallData] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(rowsPerPage);
  const [tableData, settableData] = useState([]);
  const [lastPage, setLastPage] = useState();
  // Math.floor(Data.length / rowsPerPage)
  const [currentPage, setCurrentPage] = useState(1);
  const [deletion, setDeletion] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [userData, setUserData] = useState({});
  const [role, setRole] = useState(null);
  const [showDialogue, setShowDialogue] = useState(false);
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);

  const cartsData = useSelector((state) => state.CartReducer);
  useEffect(() => {
    const userInfo = getUser(users.userProfile);
    setUserData(userInfo);
    setRole(userInfo.role);
  }, [role]);

  useEffect(() => {
    setCartData(cartsData.carts);
    setallData(cartsData.carts);
  }, [cartsData]);
  useEffect(() => {
    setLastPage(Math.ceil(cartsData.carts.length / rowsPerPage));
    if (deletion) {
      setStart(0);
      setEnd(rowsPerPage);
      setCurrentPage(1);
      setDeletion(false);
      settableData(cartsData.carts?.slice(0, rowsPerPage));
    } else {
      settableData(cartsData.carts?.slice(start, start + rowsPerPage));
    }
  }, [allData]);

  let dispatch = useDispatch();

  const TotalDownloadSize = () => {
    let size = 0;
    // cartsData.carts.map((data) => {
    //   size = size + (data.filesSize ? Number(data.filesSize) : 0);
    // });
    cartsData.carts.map((data) => {
      size = size + (data.fileSizeByte ? Number(data.fileSizeByte) : 0);
    });
    let sizeString = convertFileSizeUnit(size);
    return sizeString;
  };
  const TotalPrice = () => {
    let price = 0;
    cartsData.carts.map((data) => {
      price = price + (data.orderPrice || 0);
    });
    return price.toFixed(2);
  };
  const paginationBtnClick = (type) => {
    if (type == "next") {
      let startvar = start + parseInt(rowsPerPage);
      setStart(startvar);
      let endvar = end + parseInt(rowsPerPage);
      setEnd(endvar);
      const ar2 = cartData?.slice(startvar, endvar);
      settableData(ar2);
      setCurrentPage(currentPage + 1);
    } else {
      let endvar = end - parseInt(rowsPerPage);
      setEnd(endvar);
      let startvar = start - parseInt(rowsPerPage);
      setStart(startvar);
      const ar2 = cartData?.slice(startvar, endvar);
      settableData(ar2);
      setCurrentPage(currentPage - 1);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
    let startvar = 0;
    setStart(startvar);
    let endvar = parseInt(event.target.value);
    setEnd(endvar);
    const ar2 = allData?.slice(startvar, endvar);
    settableData(ar2);
    setLastPage(Math.ceil(allData.length / event.target.value));
  };
  const DeleteDialogueBody = () => {
    return (
      <Box sx={styles.dialogBody}>
        <Typography variant="body1">
          Are you sure you want to remove all the items from your cart ?
        </Typography>
      </Box>
    );
  };
  const removeAllFromCart = () => {
    dispatch(ClearCart());
    setShowDialogue(false);
    setShowSubmitLoader(false);
  };
  const deleteItem = (data) => {
    if (tableData.length === 1) {
      setDeletion(true);
    }
    dispatch(deleteCartItem(data));
  };
  const stripeCheckoutFcn = async () => {
    setShowLoader(true);
    const currentTime = getCurrentDateTime();
    const missionIdList = [];
    let totalPrice = 0;
    let totalDataSize = 0;
    let allMissionsDataToUpload;
    if (cartData.length < 100) {
      allMissionsDataToUpload = cartData.map((msn) => {
        const price = msn.orderPrice.toFixed(2) || 0;
        totalPrice = totalPrice + Number(price);
        totalDataSize = totalDataSize + Number(msn?.fileSizeMb);
        missionIdList.push(msn.id);
        return {
          price_data: {
            currency: "usd",
            product_data: {
              name: `${msn.missionName}`,
            },
            // description: "testing description",
            unit_amount: parseInt(price * 100),
          },
          quantity: 1,
        };
      });
    } else {
      cartData.map((msn) => {
        const price = msn.orderPrice.toFixed(2) || 0;
        totalPrice = totalPrice + Number(price);
        totalDataSize = totalDataSize + Number(msn?.fileSizeMb);
        missionIdList.push(msn.id);
      });
      allMissionsDataToUpload = [
        {
          price_data: {
            currency: "usd",
            product_data: {
              name: `Globhe drone data`, // Globhe drone data
            },
            unit_amount: parseInt(totalPrice * 100),
          },
          quantity: 1,
        },
      ];
    }
    const clientDataLibPurRef = firebase
      .firestore()
      .collection("clientDataLibraryPurchaseList")
      .doc();
    const allDataLibraryItemIds = [];

    cartData.forEach(async (msn) => {
      const singlePrice = Number(msn.orderPrice).toFixed(2) || 0;
      const dataLibraryItemRef = await firebase
        .firestore()
        .collection("clientDataLibraryPurchaseItems")
        .doc();
      allDataLibraryItemIds.push(dataLibraryItemRef.id);

      const dataLibraryItemBasicData = {
        missionId: msn.id,
        missionName: msn.missionName,
        dataSize: Number(msn?.fileSizeMb).toFixed(2),
        dataUnit: "MB",
        price: singlePrice,
        dateCreated: currentTime,
        id: dataLibraryItemRef.id,
        dataLibraryPurchaseId: clientDataLibPurRef.id,
        clientId: userData.id,
        dateUpdated: currentTime,
        paymentStatus: dataLibraryPaymentStatus.pending,
      };

      if (userData?.enterPriseId) {
        dataLibraryItemBasicData.enterPriseId = userData.enterPriseId;
      }

      await dataLibraryItemRef.set(dataLibraryItemBasicData);
    });

    await clientDataLibPurRef.set({
      id: clientDataLibPurRef.id,
      numberOfItemPurchased: missionIdList?.length,
      totalPrice: totalPrice.toFixed(2),
      totalDataSize: totalDataSize,
      dataSizeUnit: "MB",
      paymentStatus: dataLibraryPaymentStatus.pending,
      dateCreated: currentTime,
      purchaseDate: currentTime,
      clientId: userData.id,
      purchasedMissionIds: missionIdList,
      dateUpdated: currentTime,
    });
    const docRef = await firebase
      .firestore()
      .collection("clients")
      .doc(userData.id)
      .collection("checkout_sessions")
      .doc();
    await docRef.set({
      line_items: allMissionsDataToUpload,
      mode: "payment",
      success_url: `${window.location.origin}/app/client/data-library/purchased?payment=success`,
      cancel_url: `${window.location.origin}/app/client/data-library/wishlist`,
      metadata: {
        name: clientDataLibPurRef.id,
      },
    });

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        console.log("error", error);
        allDataLibraryItemIds.map(async (dataLibraryItemId) => {
          await firebase
            .firestore()
            .collection("clientDataLibraryPurchaseItems")
            .doc(dataLibraryItemId)
            .update({
              dateUpdated: currentTime,
              purchaseDate: currentTime,
              paymentStatus: dataLibraryPaymentStatus.cancel,
            });
        });
        await firebase
          .firestore()
          .collection("clientDataLibraryPurchaseList")
          .doc(clientDataLibPurRef.id)
          .update({
            dateUpdated: currentTime,
            purchaseDate: currentTime,
            paymentStatus: dataLibraryPaymentStatus.cancel,
          });
        setShowLoader(false);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
        return {
          status: "error",
          message: error.message,
        };
      }
      if (sessionId) {
        const stripe = await loadStripe(stripeSecretKey);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  return (
    <Box sx={singlePageOuterContainer}>
      <Box sx={styles.loaderContainer}>{showLoader && <UniversalLoader />}</Box>
      <Box sx={singlePageMainContainer}>
        <Box>
          <MenuBar
            country={userData?.location || ""}
            profileImage={userData?.profileImageURL || ""}
          />
          <BackNav pageName="Cart" />
        </Box>
        <Box sx={styles.bodyContainer}>
          <Box sx={styles.flexSpace}>
            <Box>
              <Typography variant="subtitl1">
                One more step before taking impactful actions
              </Typography>
            </Box>
            {allData.length > 0 && (
              <Box>
                <AppButton
                  look="negative"
                  label="Empty your cart"
                  onClick={() => setShowDialogue(true)}
                />
              </Box>
            )}
          </Box>
          {showDialogue && (
            <Dialogue
              showDialogue={showDialogue}
              setShowDialogue={setShowDialogue}
              DialogueHeadText={"Remove"}
              DialogueBody={DeleteDialogueBody()}
              showCancelButton={true}
              cancelButtonText={"Cancel"}
              sumitButtonText={"Remove"}
              showSubmitLoader={showSubmitLoader}
              setShowSubmitLoader={setShowSubmitLoader}
              onSubmit={() => removeAllFromCart()}
            />
          )}
          <Box>
            <Box sx={styles.ItemBodyContainer}>
              {tableData.length
                ? tableData.map((data, i) => {
                    return (
                      <Box key={i}>
                        <CartBox data={data} id={i} deleteItem={deleteItem} />
                      </Box>
                    );
                  })
                : ""}
            </Box>
            <Box sx={styles.tablePagination}>
              {allData.length ? (
                <TablePagination
                  currentPage={currentPage}
                  lastPage={lastPage}
                  onClickNext={() => paginationBtnClick("next")}
                  onClickPrevious={() => paginationBtnClick("prev")}
                  limitOnChange={(e) => {
                    handleChangeRowsPerPage(e);
                  }}
                  limit={rowsPerPage}
                />
              ) : (
                <Box sx={{ ...styles.flexCenter }}>
                  <Typography variant="h6" sx={styles.ferryText}>
                    Cart is empty
                  </Typography>
                </Box>
              )}
            </Box>
            {allData.length ? (
              <Box>
                <Box sx={styles.totalDetails}>
                  <Box sx={{ ...styles.bottomContainer, ...styles.ferryText }}>
                    <Box
                      sx={{ ...styles.detailsContainer, ...styles.flexSpace }}
                    >
                      <Typography variant="h6">Total download size:</Typography>
                      <Typography variant="h5">
                        {TotalDownloadSize()}
                      </Typography>
                    </Box>
                    <Box sx={{ ...styles.costDetailsBox, ...styles.flexSpace }}>
                      <Typography variant="h6">Total cost (USD):</Typography>
                      <Typography variant="h5">{TotalPrice()}$</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={styles.checkoutContainer}>
                  <AppButton
                    look="black"
                    label="Checkout"
                    isDisabled={cartsData.carts.length ? false : true}
                    onClick={() => {
                      stripeCheckoutFcn();
                    }}
                    submittingState={showLoader}
                  />
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Cart;
