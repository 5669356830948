import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import CommunicationBasic from "../CommunicationBasic";
import { userRoles } from "../../../../lib/constants";

const ClientCommunication = ({ projectDetails }) => {
  return (
    <Box>
      <CommunicationBasic
        mission={projectDetails}
        talkingTo={userRoles.admin}
        isProject={true}
      />
    </Box>
  );
};

ClientCommunication.propTypes = {
  projectDetails: PropTypes.object.isRequired,
};

export default ClientCommunication;
