import firebase from "src/firebase";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getClientPurchaseHistory } from "../services/missions";
import { getUser } from "../services/auth";

export const useGetPurchasedList = (clientId, enterPriseId) => {
  let initalSortBy = ["dateCreated", "desc"];

  const [missions, setMissions] = useStateIfMounted([]);
  const [lastLoadedMission, setLastLoadedMission] = useStateIfMounted(null);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const [filteredMissionList, setFilteredMissionList] = useStateIfMounted([]);
  const [limit, setLimit] = useStateIfMounted(10);
  const [loadHandler, setLoadHandler] = useStateIfMounted(false);
  const [allMission, setAllMission] = useStateIfMounted([]);
  const [pageData, setPagedata] = useStateIfMounted({
    currentPage: 1,
    isLast: false,
    lastPage: null,
  });
  const [sortBy, setSortBy] = useStateIfMounted(initalSortBy);
  const [filterOrSortTriggered, setFilterorSortTriggered] =
    useStateIfMounted(false);
  const [pageChanged, setPageChanged] = useStateIfMounted(false);
  const [previousPage, setPreviousPage] = useStateIfMounted(0);
  const [dataloadedTill, setDataLoadedTill] = useStateIfMounted(0);
  const [authorized, setAuthorized] = useStateIfMounted(null);
  const [reload, setReload] = useStateIfMounted(false);

  useEffect(() => {
    if (!firebase) {
      return;
    }
    const user = getUser("user");
    setIsLoading(true);

    if (user?.isAnonymous) {
      setIsLoading(false);
    } else {
      if (
        dataloadedTill >= pageData.currentPage ||
        (pageData.currentPage <= previousPage &&
          filterOrSortTriggered === false)
      ) {
        let prevPageMissionData = allMission.slice(
          (pageData.currentPage - 1) * limit,
          pageData.currentPage * limit
        );
        setMissions(prevPageMissionData);
        setPreviousPage(pageData.currentPage - 1);
        setIsLoading(false);
        setAuthorized(true);
      } else {
        if (filterOrSortTriggered) {
          setPagedata({
            currentPage: 1,
            isLast: false,
            lastPage: null,
          });
          setPreviousPage(0);
        }
        getClientPurchaseHistory(firebase, {
          lastVisible: lastLoadedMission,
          limit: limit,
          clientId,
          sortType: sortBy[1],
          sortBy: sortBy[0],
          status: "success",
          enterPriseId,
        })
          .then((data) => {
            setMissions(data.purchaseMissions);
            let allMissionData = allMission || [];
            setPreviousPage(pageData.currentPage - 1);
            setLastLoadedMission(data.lastVisible);
            setDataLoadedTill(pageData.currentPage);
            if (data.last) {
              let pageDataUpdated = pageData;
              pageDataUpdated["isLast"] = true;
              pageDataUpdated["lastPage"] = pageData.currentPage;
              setPagedata(pageDataUpdated);
            }
            if (filterOrSortTriggered) {
              setPreviousPage(0);
              setAllMission([...data.purchaseMissions]);
            } else {
              setAllMission([...allMissionData, ...data.purchaseMissions]);
            }
            setFilterorSortTriggered(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          })
          .finally(() => {
            setIsLoading(false);
            setAuthorized(true);
          });
      }
    }
  }, [limit, loadHandler, sortBy, pageChanged, reload]);

  useEffect(() => {
    let newMissionList = [...missions];
    setFilteredMissionList(newMissionList);
  }, [missions, pageChanged, sortBy, loadHandler, limit]);

  return [
    filteredMissionList,
    isLoading,
    setIsLoading,
    limit,
    setLimit,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllMission,
    pageChanged,
    setPageChanged,
    setDataLoadedTill,
    setLastLoadedMission,
    setFilterorSortTriggered,
    reload,
    setReload,
  ];
};
