import { Box, Typography } from "@mui/material";
import React from "react";
import { flexColumn } from "../../../lib/styleConstants";

const styles = {
  wrapper: {
    padding: "2em 0",
  },
  container: {
    textAlign: "center",
    rowGap: "1em",
  },
};
const GuestUserDialogueBody = () => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={{ ...styles.container, ...flexColumn }}>
        <Typography variant="h4">Please sign in</Typography>
        <Typography variant="h6">
          To perform this action you need to sign in first.
        </Typography>
      </Box>
    </Box>
  );
};

export default GuestUserDialogueBody;
