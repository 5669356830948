import React from "react";
import PilotProjects from "./PilotProjects";
import Layout from "../../../../../Layout";
const PilotProjectTableLayout = () => {
  return (
    <Layout pageTitle="All Projects" hasSideMenu={true}>
      <PilotProjects />
    </Layout>
  );
};

export default PilotProjectTableLayout;
