import { Box } from "@mui/material";
import React, { useContext } from "react";
import InterestInviteList from "../../../interestInvite/interestInviteList";
import {
  columnForAlreadyIntrestedPilot,
  columnForInterestWithoutPrice,
  columnForIntrestedPilot,
  missionListSource,
} from "../../../../../lib/constants";
import MissionContext from "./MissionContext";
import TextStructures from "../../../../global/elements/TypographyElements/TextStructures";

const InvitedPilotInterestTable = () => {
  const data = useContext(MissionContext);
  return (
    <Box>
      <TextStructures
        text="Interested drone operators"
        customVariant="h3"
        marginBottom
      />
      <InterestInviteList
        missionId={data?.mission?.id}
        columnForPilot={
          data?.mission?.assignedPilot
            ? columnForAlreadyIntrestedPilot
            : data?.mission?.allowPilotBidding
            ? columnForIntrestedPilot
            : columnForInterestWithoutPrice
        }
        mission={data?.mission}
        refreshPage={data?.refreshMission}
        source={missionListSource.interest}
      />
    </Box>
  );
};

export default InvitedPilotInterestTable;
