import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getInvoiceList, getInvoiceListAdmin } from "../services/invoices";
import { getUser } from "src/services/auth";
import { checkAdmintype } from "../services/auth";
import { admintype } from "../lib/constants";

const useGetInvoiceList = () => {
  const [allInvoices, setAllInvoices] = useState(null);
  const [invoiceTotal, setInvoiceTotal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPage, setlastPage] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const [lastLoadedinvoice, setlastLoadedinvoice] = useState(null);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);
  const [authorized, setAuthorized] = useState(null);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    const userRole = getUser("user").role;
    checkAdmintype(firebase)
      .then((snapshot) => {
        if (
          userRole === "admin" &&
          (snapshot.adminType === admintype.financeAdmin ||
            snapshot.adminType === admintype.dataAdmin ||
            snapshot.adminType === admintype.superAdmin)
        ) {
          if (currentPage <= lastLoadedPage) {
            let newDataForInvoice = invoiceTotal.slice(
              (currentPage - 1) * limit,
              currentPage * limit
            );
            setAllInvoices(newDataForInvoice);
            setLoading(false);
          } else {
            getInvoiceListAdmin(lastLoadedinvoice, limit, filter)
              .then((data) => {
                if (data.last) {
                  setlastPage(currentPage);
                  setIsLastPage(true);
                }
                setAllInvoices(data?.invoices);
                setLastLoadedPage(currentPage);
                setlastLoadedinvoice(data.lastVisible);
                setInvoiceTotal([...invoiceTotal, ...data.invoices]);
              })
              .catch((err) => console.log(err))
              .finally(() => {
                setAuthorized(true);
              });
          }
        } else {
          if (userRole == "pilot") {
            if (currentPage <= lastLoadedPage) {
              let newDataForInvoice = invoiceTotal.slice(
                (currentPage - 1) * limit,
                currentPage * limit
              );
              setAllInvoices(newDataForInvoice);
              setLoading(false);
            } else {
              getInvoiceList(lastLoadedinvoice, limit)
                .then((data) => {
                  if (data.last) {
                    setlastPage(currentPage);
                    setIsLastPage(true);
                  }
                  setAllInvoices(data?.invoices);
                  setLastLoadedPage(currentPage);
                  setlastLoadedinvoice(data.lastVisible);
                  setInvoiceTotal([...invoiceTotal, ...data.invoices]);
                })
                .catch((err) => console.log(err))
                .finally(() => {
                  setAuthorized(true);
                });
            }
          } else {
            setAuthorized(false);
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadData, limit, filter]);

  return [
    allInvoices,
    loading,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    lastPage,
    loadData,
    setLoadData,
    authorized,
    setInvoiceTotal,
    setLastLoadedPage,
    setlastLoadedinvoice,
    filter,
    setFilter,
  ];
};

export default useGetInvoiceList;
