import React from "react";
import { getUser } from "../../../../services/auth";
import Layout from "../../../../Layout";
import EditClientProfile from "./EditClientProfile";

const ProfileEditContainer = () => {
  const currentUser = getUser("user");
  return (
    <Layout
      pageTitle={"Edit profile"}
      hasSideMenu
      loading={false}
      addBottomPadding
    >
      <EditClientProfile clientId={currentUser.userID} />
    </Layout>
  );
};

export default ProfileEditContainer;
