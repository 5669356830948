import React from "react";
import { AppBar, Box } from "@mui/material";
import { StyledTab, StyledTabs, appBar } from "../../../../lib/styleConstants";
import AdminEnterPrise from "./AdminEnterPrise";
import AllClientsTable from "../../Profiles/Client/AllClientsTable";
import PropTypes from "prop-types";

const styles = {
  tabBox: {
    borderBottom: 1,
    borderColor: "divider",
    // paddingLeft: { xs: "0px", sm: "50px" },
    justifyContent: { xs: "center", sm: "left" },
    paddingBottom: "30px",
    "& button": {
      fontSize: { xs: "10px", sm: "13px" },
    },
    margin: "40px 0",
  },
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const AdminClientEnterPriseMain = ({ tab, setTab }) => {
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={styles.tabBox}>
      <AppBar position="static" sx={appBar} elevation={0}>
        <StyledTabs
          value={tab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <StyledTab key="enterprise" label="Enterprise" {...a11yProps(0)} />
          <StyledTab key="clients" label="Clients" {...a11yProps(0)} />
        </StyledTabs>
      </AppBar>
      <Box>
        {tab === 0 ? (
          <Box mt={4}>
            <AdminEnterPrise />
          </Box>
        ) : (
          <Box mt={4}>
            <AllClientsTable />
          </Box>
        )}
      </Box>
    </Box>
  );
};

//tab,

AdminClientEnterPriseMain.propTypes = {
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
};

export default AdminClientEnterPriseMain;
