import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import Dialogue from "../../../../global/Dialogue";

const styles = {
  popupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
};

const DialogueBody = () => {
  return (
    <Box sx={styles.popupCls}>
      <Typography variant="h6">Cancel Order ?</Typography>
      <Typography variant="subtitle1">
        Once submitted, this order can no longer available.
      </Typography>
    </Box>
  );
};

const CancelOrder = ({
  showDialog,
  setShowDialog,
  onSubmit,
  loading,
  setLoading,
}) => {
  return (
    <>
      <Dialogue
        showDialogue={showDialog}
        setShowDialogue={setShowDialog}
        DialogueHeadText={""}
        DialogueBody={DialogueBody()}
        showCancelButton={true}
        cancelButtonText={"Cancel"}
        sumitButtonText={"Submit"}
        showSubmitLoader={loading}
        setShowSubmitLoader={setLoading}
        onSubmit={() => onSubmit()}
        submitButtonLook={"primary"}
      />
    </>
  );
};

CancelOrder.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default CancelOrder;
