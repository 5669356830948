import firebase from "src/firebase";
import { useEffect, useState } from "react";
import { getFeedbacks } from "../services/feedback";

export const useGetFeedbacksList = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [lastLoadedFeedbacks, setLastLoadedFeedbacks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [lastLoadedPageNumber, setLastLoadedPageNumber] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPageNumber, setLastPageNumber] = useState(null);
  const [loadDataHandler, setLoadDataHandler] = useState(false);
  const [allFeedbackData, setAllFeedbackData] = useState([]);

  useEffect(() => {
    if (!firebase) {
      return;
    }
    setIsLoading(true);
    if (pageNumber <= lastLoadedPageNumber) {
      let newDataForQuestions = allFeedbackData.slice(
        (pageNumber - 1) * limit,
        pageNumber * limit
      );
      setFeedbacks(newDataForQuestions);
      setIsLoading(false);
    } else {
      getFeedbacks(firebase, lastLoadedFeedbacks, limit)
        .then((data) => {
          setFeedbacks(data.feedbackDatas);
          setLastLoadedFeedbacks(data.lastVisible);
          setLastLoadedPageNumber(pageNumber);
          if (data.last) {
            setIsLastPage(true);
            setLastPageNumber(pageNumber);
          }
          setAllFeedbackData([...allFeedbackData, ...data.feedbackDatas]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [loadDataHandler]);

  return {
    feedbacks,
    isLoading,
    isLastPage,
    setLimit,
    setPageNumber,
    setIsLastPage,
    setLastLoadedPageNumber,
    setLastPageNumber,
    setLastLoadedFeedbacks,
    pageNumber,
    lastPageNumber,
    loadDataHandler,
    setLoadDataHandler,
    setAllFeedbackData,
  };
};
