import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getPilotStatistics } from "../services/statistics";

export const useGetPilotNumber = () => {
  const [pilotNumber, setPilotNumber] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    getPilotStatistics(firebase)
      .then((stats) => {
        setPilotNumber(stats.pilots);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return [pilotNumber, loading];
};

export const useGetCountrytNumber = () => {
  const [countryNumber, setCountryNumber] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    getPilotStatistics(firebase)
      .then((stats) => {
        setCountryNumber(stats.countries);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return [countryNumber, loading];
};

export const useGetCountryByAvailabilitytNumber = () => {
  const [countryNumber, setCountryNumber] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    getPilotStatistics(firebase)
      .then((stats) => {
        setCountryNumber(stats.countriesAvailability);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return [countryNumber, loading];
};

export const useGetCompaniesNumber = () => {
  const [companiesNumber, setCompaniesNumber] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    getPilotStatistics(firebase)
      .then((stats) => {
        setCompaniesNumber(stats.registeredCompanies);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return [companiesNumber, loading];
};
