import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import theme from "../../theme";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { navigate } from "gatsby";

const styles = {
  rightArrowStyles: {
    border: `1px solid ${theme.palette.grey.warmGrey}`,
    borderRadius: "50%",
    fontSize: "2rem",
    padding: "5px",
    color: theme.palette.black.darkSeaBlack,
  },
  arrowBox: { cursor: "pointer" },
};

const RightArrowButton = ({ link }) => {
  const resolveBackNavUrl = () => {
    navigate(link);
  };
  return (
    <>
      <Box onClick={resolveBackNavUrl} sx={styles.arrowBox}>
        <ArrowForwardIcon sx={styles.rightArrowStyles} />
      </Box>
    </>
  );
};

RightArrowButton.propTypes = {
  link: PropTypes.string.isRequired,
};

export default RightArrowButton;
