import firebase from "src/firebase";
import { useEffect, useState } from "react";
import { getApiKeys } from "../services/apiKeys";

export const useGetApiKeyList = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [lastLoadedApiKey, setLastLoadedApiKey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [lastLoadedPageNumber, setLastLoadedPageNumber] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPageNumber, setLastPageNumber] = useState(null);
  const [loadDataHandler, setLoadDataHandler] = useState(false);
  const [allApiKeyData, setAllApiKeyData] = useState([]);

  useEffect(() => {
    if (!firebase) {
      return;
    }

    setIsLoading(true);
    // setDoLoadApiKeys(false);
    if (pageNumber <= lastLoadedPageNumber) {
      let newDataForApiKey = allApiKeyData.slice(
        (pageNumber - 1) * limit,
        pageNumber * limit
      );
      setApiKeys(newDataForApiKey);
      setIsLoading(false);
    } else {
      getApiKeys(firebase, lastLoadedApiKey, limit)
        .then((data) => {
          if (data.last) {
            setLastPageNumber(pageNumber);
            setIsLastPage(true);
          }
          setLastLoadedPageNumber(pageNumber);
          setApiKeys(data.keys);
          setLastLoadedApiKey(data.lastVisible);
          setAllApiKeyData([...allApiKeyData, ...data.keys]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
          // setDoLoadApiKeys(false);
        });
    }
  }, [loadDataHandler]);

  return {
    apiKeys,
    isLoading,
    limit,
    pageNumber,
    isLastPage,
    setLimit,
    setPageNumber,
    setIsLastPage,
    setLastLoadedPageNumber,
    setLastPageNumber,
    setAllApiKeyData,
    setLastLoadedApiKey,
    lastPageNumber,
    loadDataHandler,
    setLoadDataHandler,
  };
};
