import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import React, { useState, Fragment } from "react";
import useGetPilot from "src/hooks/useGetPilot";
import { getUser } from "src/services/auth";
import {
  mapFormDataToAccount,
  mapFormDataToPilot,
} from "../../../lib/form-helpers";
import { deletePilot, updatePilot } from "../../../services/pilots";
import ErrorMessage from "../../global/errorMessage";
import PilotForm from "../pilot/PilotForm";
import Layout from "../../../Layout";
import { navigate } from "gatsby";
import Tips from "../../global/elements/Tips";
import { activeMissionCount } from "../../../services/dashboard";

const EditProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = getUser("user");
  const [pilot, loading] = useGetPilot(currentUser.userID);

  const [hasError, showErrorMessage] = useState(false);
  const [errorMessageLabel, setErrorMessageLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [deletingPilot, setDeletingPilot] = useState(false);

  const isCurrentUser = currentUser?.userID === pilot?.id;

  const closeTriggered = () => {
    // Remove ErrorMessage component; Delay the removal because of the fade-out animation
    setTimeout(function () {
      showErrorMessage(false);
    }, 300);
  };

  const submitHandler = (values, setSubmitting, cb) => {
    const data = mapFormDataToPilot(values);
    const accountData = mapFormDataToAccount(values);

    if (!isCurrentUser) {
      if (!window.confirm("Are you sure you want to update this user?")) {
        return;
      }
    }

    const newEmail =
      accountData.email === pilot.email ? null : accountData.email;
    const newPassword = accountData.password;
    updatePilot(firebase, pilot.id, data, newEmail, newPassword)
      .then(() => {
        enqueueSnackbar("Information updated", { variant: "success" });
        cb(null, { success: true });
        navigate("/app/my-profile");
        return;
      })
      .catch((err) => {
        setSubmitting(false);
        errorHandler(err);
        cb({ error: err });
        return;
      });
  };

  const errorHandler = (error) => {
    if (error.code === "auth/weak-password") {
      showErrorMessage(true);
      setErrorMessageLabel("Weak password");
      setErrorMessage("Password should be at least 6 characters.");
    }

    if (error.code === "auth/email-already-in-use") {
      showErrorMessage(true);
      setErrorMessageLabel("Email already in use");
      setErrorMessage(
        "The email address is already in use by another account."
      );
    }

    if (error.code === "auth/requires-recent-login") {
      showErrorMessage(true);
      setErrorMessageLabel("Requires recent login");
      setErrorMessage(
        "Please sign out and sign in again in order to update your email/password"
      );
    }
  };

  const onDeletePilotClickByPilot = async () => {
    try {
      let activeCount = await activeMissionCount(currentUser?.userID);

      if (activeCount > 0) {
        let missionLabel = activeCount == 1 ? "mission" : "missions";
        enqueueSnackbar(
          `You have ${activeCount} active ${missionLabel}, you can't delete your profile`,
          { variant: "error" }
        );
      } else {
        if (
          !window.confirm(
            "Do you want to delete your profile? This action is irriversible."
          )
        ) {
          return;
        }
        setDeletingPilot(true);
        deletePilot(firebase, currentUser?.userID)
          .then(() => {
            localStorage.clear();
            navigate("/");
          })
          .catch((err) => console.log(err))
          .finally(() => setDeletingPilot(false));
      }
    } catch (e) {
      console.log("Error in deleting pilot profile");
    }
  };

  return (
    <React.Fragment>
      <Layout
        pageTitle="My Profile"
        hasSideMenu
        loading={pilot ? false : true}
        addBottomPadding
      >
        <Tips
          content={
            <Fragment>
              To ensure a 100% filled profile, make sure that you fill all the
              fields that are marked with
              <span style={{ color: "red" }}> **</span>
            </Fragment>
          }
        />
        <br />
        {!loading && (
          <PilotForm
            pilot={pilot}
            errorHandler={errorHandler}
            submitHandler={submitHandler}
            expanded
            showPassword={isCurrentUser}
            deletePilot={onDeletePilotClickByPilot}
            deletePilotLoading={deletingPilot}
          />
        )}
      </Layout>
      {hasError ? (
        <ErrorMessage
          show={true}
          errorMessageLabel={errorMessageLabel}
          errorMessage={errorMessage}
          closeTriggered={closeTriggered}
        />
      ) : null}
    </React.Fragment>
  );
};

export default EditProfile;
