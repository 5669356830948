/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import CommunicationBasic from "./CommunicationBasic";
import { Box, Grid, Typography } from "@mui/material";
import { useStateIfMounted } from "use-state-if-mounted";
import MissionContext from "../../app/missions/NewMissionDetails/components/MissionContext";
import { missionTypeConstant, userRoles } from "../../../lib/constants";
import SideChatMenu from "./SideChatMenu";
import globhePng from "../../../assets/logos/globhe_logo/Globhe_symbol_2022_Dark_sea_black.png";

const styles = {
  appBar: {
    width: "100%",
    backgroundColor: "transparent",
    borderColor: "green",
  },
  noPilotBox: {
    minHeight: "400px",
    height: "400px",
    padding: "20px 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const TwoWayCommunicationAdmin = () => {
  const [messagingTo, setMessagingTo] = useStateIfMounted(0);
  const [personsList, setPersonsList] = useStateIfMounted([]);
  const missionContext = useContext(MissionContext);

  useEffect(() => {
    // Setting a list of person to chat
    setPersonsList([
      {
        name: "Client",
        id: missionContext.mission.clientName,
        role: userRoles.client,
        profilePicture: missionContext?.clientInfo?.profileImageURL || "",
      },
      {
        name: "Drone operator",
        id: missionContext.mission.assignedPilotName || "Not assigned",
        role: userRoles.pilot,
        profilePicture: missionContext?.operatorInfo?.profileImageURL || "",
      },
      {
        name: "Group chat",
        id: "Client , Operator",
        role: userRoles.all,
        profilePicture: globhePng,
      },
    ]);
    //redirecting to the pilot chat if we recieve a pilot chat in notication.
    if (window.location.search === "?pilot") {
      setMessagingTo(1);
    } else if (window.location.search === "?all") {
      setMessagingTo(2);
    } else {
      setMessagingTo(0);
    }
  }, []);

  // Below function is responsible for changing a person to chat.
  const changePersonToChat = (chatTo) => {
    if (chatTo === userRoles.client) {
      setMessagingTo(0);
    } else if (chatTo === userRoles.pilot) {
      setMessagingTo(1);
    } else {
      setMessagingTo(2);
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4} lg={2.5}>
          {/* Side Menu for all the persons who is in active chat list. */}
          <SideChatMenu
            personToChat={personsList}
            onPersonClickFcn={changePersonToChat}
            active={
              messagingTo === 0
                ? userRoles.client
                : messagingTo === 1
                ? userRoles.pilot
                : userRoles.all
            }
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9.5}>
          {messagingTo === 0 ? (
            <CommunicationBasic
              mission={missionContext?.mission}
              talkingTo={userRoles.client}
            />
          ) : messagingTo === 1 && missionContext?.mission?.assignedPilot ? (
            <CommunicationBasic
              mission={missionContext?.mission}
              talkingTo={userRoles.pilot}
            />
          ) : messagingTo === 2 &&
            missionContext?.mission?.missionType ===
              missionTypeConstant.clientOrder &&
            missionContext?.mission?.assignedPilot ? (
            <CommunicationBasic
              mission={missionContext?.mission}
              talkingTo={userRoles.all}
            />
          ) : (
            <Box sx={styles.noPilotBox}>
              <Typography variant="h5">
                Please add a drone operator to the mission first.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TwoWayCommunicationAdmin;
