import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import Dialogue from "../../global/Dialogue";
import AppButton from "../../global/elements/AppButton";
import InvitationImg from "../../../assets/images/team_member_invitation.png";
import theme from "../../../theme";

const styles = {
  popupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
  errorCls: {
    display: "table",
    margin: "0 auto",
    textAlign: "left",
    marginTop: "20px",
  },
  btnCls: {
    marginTop: "30px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "left",
  },
  textContainer: {
    marginLeft: { xs: "0px", md: "20%" },
    width: { xs: "100%", md: "60%" },
  },
  innerContainer: {
    textAlign: "center",
  },
  textCls: {
    color: theme.palette.black.coolGrey,
  },
};

const AfterInvitationPopup = ({
  openPopup,
  setOpenPopup,
  goToDashboard,
  text,
}) => {
  const DialogueBody = () => {
    return (
      <Box>
        <Box sx={styles.innerContainer}>
          <Box component="img" src={InvitationImg} />
        </Box>
        <Box sx={styles.textContainer}>
          <Typography variant="h4">Request has been sent</Typography>
          <Typography variant="h6" style={styles.textCls}>
            {text}
          </Typography>
          <Box sx={styles.btnContainer}>
            <AppButton
              look="green"
              label="Go to Dashbard"
              onClick={() => goToDashboard()}
              addtionalStyle={styles.btnCls}
            />
          </Box>
        </Box>

        {/* </Box> */}
      </Box>
    );
  };

  return (
    <>
      <Dialogue
        showDialogue={openPopup}
        setShowDialogue={setOpenPopup}
        DialogueHeadText={""}
        DialogueBody={<DialogueBody />}
        showCancelButton={true}
        cancelButtonText={"Cancel"}
        sumitButtonText={"Yes"}
        tableType={true}
      />
    </>
  );
};

AfterInvitationPopup.propTypes = {
  openPopup: PropTypes.bool.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  goToDashboard: PropTypes.func.isRequired,
  text: PropTypes.string,
};

AfterInvitationPopup.defaultProps = {
  text: undefined,
};

export default AfterInvitationPopup;
