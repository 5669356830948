import React, { useContext } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import AppButton from "./elements/AppButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DashboardContext from "../app/Client/Overview/Components/DashboardContext";

const CardButton = ({ btnText, buttonlabel, redirectUrl }) => {
  const context = useContext(DashboardContext);
  const disable = context?.disableButton ? true : false;
  return (
    <>
      <Typography variant="h6">{btnText}</Typography>
      <AppButton
        label={buttonlabel}
        endIcon={<ArrowForwardIcon />}
        look={disable ? "greenDisabled" : "green"}
        url={redirectUrl}
        internalLink
        isDisabled={disable}
      />
    </>
  );
};

CardButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  buttonlabel: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

export default CardButton;
