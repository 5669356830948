import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";

const GridContainer = ({
  styles,
  children,
  gutters,
  limited,
  centerContent,
}) => {
  const style = {
    root: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      width: limited ? containerWidth : "100%",
      maxWidth: "100%",
      padding: gutters ? "0 10px" : "0",
      margin: "0px auto",
      justifyContent: centerContent && "center;",
    },
  };

  const containerWidth = "68rem";

  return <Box sx={{ ...style.root, ...styles }}>{children}</Box>;
};

export default GridContainer;

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
  gutters: PropTypes.bool,
  limited: PropTypes.bool,
  centerContent: PropTypes.bool,
  styles: PropTypes.object,
};

GridContainer.defaultProps = {
  gutters: false,
  limited: false,
  centerContent: false,
  styles: {},
};
