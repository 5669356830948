import { Box, Typography } from "@mui/material";
import React from "react";
import Dialogue from "../../../global/Dialogue";
import PropTypes from "prop-types";
import AddDocForm from "./AddDocForm";

const styles = {};

const AddDocPopup = ({
  openAddDocPopup,
  setOpenAddDocPopup,
  submitDoc,
  enterpriseId,
}) => {
  const addDocumentBody = () => {
    return (
      <Box>
        <Typography variant="h3">Add a document</Typography>
        <Box sx={styles.documentPopup}>
          <AddDocForm
            submitDoc={submitDoc}
            setOpenAddDocPopup={setOpenAddDocPopup}
            enterpriseId={enterpriseId}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Dialogue
        showDialogue={openAddDocPopup}
        setShowDialogue={setOpenAddDocPopup}
        DialogueBody={addDocumentBody()}
        // showCancelButton={true}
        // cancelButtonText={"Cancel"}
        // sumitButtonText={"Add document +"}
        // onSubmit={() => {
        //   addMemberFcn();
        // }}
        submitButtonLook="green"
        noSubmitButtonIcon={true}
        tableType={true}
      />
    </Box>
  );
};

AddDocPopup.propTypes = {
  openAddDocPopup: PropTypes.bool.isRequired,
  setOpenAddDocPopup: PropTypes.func.isRequired,
  submitDoc: PropTypes.func.isRequired,
  enterpriseId: PropTypes.string.isRequired,
};

export default AddDocPopup;
