import firebase from "src/firebase";
import { generateKeywordsForSearch, getCurrentDateTime } from "../lib/helpers";

export const createDocument = async (data) => {
  try {
    const docData = data;
    const currentTime = getCurrentDateTime();
    const searchDocName = generateKeywordsForSearch(data?.docName);
    const docRef = await firebase.firestore().collection("documents").doc();
    docData.id = docRef.id;
    docData.lastUpdated = currentTime;
    docData.createdAt = currentTime;
    docData.searchDocName = searchDocName || null;
    docData.deleted = false;
    return docRef
      .set(docData)
      .then(() => {
        return {
          status: "Success",
          data: docData,
          message: "Document created successfully!",
        };
      })
      .catch((err) => {
        return {
          status: "Error",
          message: err.message || "Error in uploading document!",
        };
      });
  } catch (err) {
    console.log("Error", err.message);
    return {
      status: "Error",
      message: err.message || "Error in uploading document!",
    };
  }
};

export const documentsList = (enterpriseId, limit, lastVisible, filter) => {
  try {
    console.log(Number(limit), lastVisible);
    let query = firebase
      .firestore()
      .collection("documents")
      .where("enterPriseId", "==", enterpriseId)
      .where("deleted", "==", false)
      .orderBy("createdAt", "desc");

    if (lastVisible) {
      query = query.startAfter(lastVisible);
    }
    if (filter && filter.docName) {
      const searchDocName = generateKeywordsForSearch(filter?.docName);
      query = query.where("searchDocName", "array-contains-any", searchDocName);
    }

    return query
      .limit(Number(limit) + 1)
      .get()
      .then((snapshot) => {
        const res = {
          documents: [],
          lastVisible:
            snapshot.size == 0
              ? null
              : snapshot.docs.length > Number(limit)
              ? snapshot.docs[snapshot.docs.length - 2]
              : snapshot.docs[snapshot.docs.length - 1],
          last: snapshot.size < Number(limit) + 1,
        };
        let getworkableData = snapshot.docs.map((dt) => {
          return dt.data();
        });
        res.documents =
          snapshot.docs.length == 0
            ? []
            : snapshot.docs.length > Number(limit)
            ? getworkableData.slice(0, getworkableData.length - 1)
            : getworkableData.slice(0, getworkableData.length);
        return res;
      })
      .catch((e) => {
        return {
          status: "Error",
          message: e.message || "Error in getting documents list",
        };
      });
  } catch (err) {
    console.log("Error", err.message);
    return {
      status: "Error",
      message: err.message || "Error in getting documents list",
    };
  }
};

export const getSingleDocuments = (docId) => {
  try {
    return firebase
      .firestore()
      .collection("documents")
      .doc(docId)
      .get()
      .then((snapshot) => {
        return { status: "Success", document: snapshot.data() };
      })
      .catch((e) => {
        return {
          status: "Error",
          message: e.message || "Error in getting document",
        };
      });
  } catch (err) {
    return {
      status: "Error",
      message: err.message || "Error in getting document",
    };
  }
};

export const deleteDocument = (docId) => {
  try {
    return firebase
      .firestore()
      .collection("documents")
      .doc(docId)
      .update({ deleted: true, lastUpdated: getCurrentDateTime() })
      .then(() => {
        return { status: "Success", message: "Document deleted successfully!" };
      })
      .catch((e) => {
        return {
          status: "Error",
          message: e.message || "Error in deleting the document!",
        };
      });
  } catch (err) {
    console.log("Error", err.message);
    return {
      status: "Error",
      message: err.message || "Error in deleting the document!",
    };
  }
};
