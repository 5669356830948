import { call, fork, put, take } from "redux-saga/effects";
import * as actions from "../actions/user-action";
import firebase from "src/firebase";
import { getPilot } from "src/services/pilots";

function* fetchUserProfile({ userId }) {
  try {
    const result = yield call(getPilot, firebase, userId);
    yield put(
      actions.fetchUserProfileSuccess({
        data: result,
      })
    );
  } catch (e) {
    console.log("login error", e);
    yield put(
      actions.fetchUserProfileError({
        error: "An error occured while trying to get user profile",
      })
    );
  }
}

function* watchFetchUser() {
  while (true) {
    const action = yield take(actions.TYPES.GET_USER);
    yield call(fetchUserProfile, {
      userId: action.payload.userId,
    });
  }
}

const userSaga = [fork(watchFetchUser)];

export default userSaga;
