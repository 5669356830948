import React from "react";
import theme from "../../../../theme";
import { Box, Typography } from "@mui/material";
import { useStateIfMounted } from "use-state-if-mounted";
import { useSnackbar } from "notistack";
import AppButton from "../../../global/elements/AppButton";
import Dialogue from "../../../global/Dialogue";
import PropTypes from "prop-types";
import {
  updateProject,
  updateProjectMissionStatus,
} from "../../../../services/project";
import { missionStatus, projectStatus } from "../../../../lib/constants";

const styles = {
  dialogBody: { marginBottom: "30px", textAlign: "center" },
  dialogueContent: { color: theme.palette.grey.dark },
};

const DeleteProjectDialogueBody = () => {
  return (
    <Box sx={styles.dialogBody}>
      <Typography variant="h5" sx={{ marginBottom: "20px" }}>
        Are you sure you want to delete your project?
      </Typography>

      <Typography variant="body1">
        This action is permanent and all associated information will be lost.
        Please confirm your decision before proceeding.
      </Typography>
    </Box>
  );
};

const DeleteProject = ({ project, setTriggerReload, triggerReload }) => {
  const [loading, setLoading] = useStateIfMounted(false);
  const [showDialogue, setShowDialogue] = useStateIfMounted(false);

  const { enqueueSnackbar } = useSnackbar();

  const deleteProject = () => {
    try {
      setLoading(true);
      let updates = { status: projectStatus.deleted };

      updateProject({ projectId: project.id, updates })
        .then(async (data) => {
          if (data && data.status == "Success") {
            await updateProjectMissionStatus({
              projectId: project.id,
              currentMissionStatus: missionStatus.draft,
              toUpdateMissionStatus: missionStatus.deleted,
            });
            setLoading(false);
            setShowDialogue(false);
            setTriggerReload(!triggerReload);
            enqueueSnackbar("Project is deleted", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Error in deleted the project", {
              variant: "error",
            });
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          enqueueSnackbar("Error in changing project status", {
            variant: "error",
          });
          console.log("Error", e);
        });
    } catch (e) {
      setLoading(false);
      console.log("Error", e);
    }
  };

  return (
    <>
      <AppButton
        look="negative"
        label={"Delete project"}
        noIcon
        onClick={() => setShowDialogue(true)}
        submittingState={loading}
      />

      {showDialogue && (
        <Dialogue
          showDialogue={showDialogue}
          setShowDialogue={setShowDialogue}
          DialogueHeadText={""}
          DialogueBody={DeleteProjectDialogueBody()}
          showCancelButton={true}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Delete"}
          showSubmitLoader={loading}
          setShowSubmitLoader={setLoading}
          onSubmit={() => deleteProject()}
          submitButtonLook={"danger"}
        />
      )}
    </>
  );
};

DeleteProject.propTypes = {
  project: PropTypes.object.isRequired,
  triggerReload: PropTypes.bool.isRequired,
  setTriggerReload: PropTypes.func.isRequired,
};

export default DeleteProject;
