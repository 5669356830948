import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getSingleNews } from "../services/news-inspiration";

const useGetNews = (id) => {
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    getSingleNews(id)
      .then((data) => {
        setNews(data?.news);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [news, loading];
};

export default useGetNews;
