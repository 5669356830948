import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";

const GridColumn = ({ children, styles, widthLarge, noFlex }) => {
  const style = {
    root: {
      display: noFlex ? "block" : "flex",
      padding: { xs: 0, md: "0 10px" },
      position: { lg: "relative" },
      width: { xs: "100%", lg: widthLarge },
      boxSizing: { xl: "border-box" },
    },
  };

  return <Box sx={{ ...style.root, ...styles }}>{children}</Box>;
};

GridColumn.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
  widthLarge: PropTypes.string,
  noFlex: PropTypes.bool,
};

GridColumn.defaultProps = {
  styles: {},
  noFlex: false,
  widthLarge: "100%",
};

export default GridColumn;
