import { Box, Typography } from "@mui/material";
import React from "react";
import MissionMap from "../../../global/elements/missionsMap";
import PropTypes from "prop-types";
import theme from "../../../../theme";

const styles = {
  locationContainer: { textAlign: "center", width: "100%" },
  emptyMapText: { paddingTop: "10%", color: theme.palette.secondary.main },
};
const LocationsPins = ({ uploadValidData, hint, mapHeight }) => {
  return (
    <Box sx={styles.locationContainer}>
      {uploadValidData && uploadValidData?.length > 0 ? (
        <Box sx={{ height: mapHeight }}>
          <MissionMap missionData={uploadValidData} />
        </Box>
      ) : (
        <Typography variant="h5" sx={styles.emptyMapText}>
          {hint}
        </Typography>
      )}
    </Box>
  );
};

LocationsPins.propTypes = {
  uploadValidData: PropTypes.array,
  hint: PropTypes.string,
  mapHeight: PropTypes.string,
};
LocationsPins.defaultProps = {
  uploadValidData: [],
  hint: "Please upload a kml/csv file to view the missions.",
  mapHeight: "302px",
};

export default LocationsPins;
