import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../../../theme";

const styles = {
  mainContainer: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  btnCls: {
    display: "flex",
    justifyContent: "end",
  },
  text: {
    marginBottom: "20px",
  },
};

const DashboardBanner = ({
  heading,
  subHeading,
  children,
  headingVariant,
  subHeadingVariant,
  bannerBgColor,
}) => {
  return (
    <Box sx={{ ...styles.mainContainer, background: bannerBgColor }}>
      <Grid container>
        <Grid item xs={12} sx={styles.text}>
          <Typography variant={headingVariant}>{heading}</Typography>
          {subHeading && (
            <Typography variant={subHeadingVariant}>{subHeading}</Typography>
          )}
        </Grid>
        <Grid item xs={12} sx={styles.btnCls}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

DashboardBanner.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  children: PropTypes.object.isRequired,
  headingVariant: PropTypes.string,
  subHeadingVariant: PropTypes.string,
  bannerBgColor: PropTypes.string,
};

DashboardBanner.defaultProps = {
  subHeading: undefined,
  headingVariant: "h6",
  subHeadingVariant: "body1",
  bannerBgColor: theme.palette.adminBlue.lightBlue,
};

export default DashboardBanner;
