import React from "react";
import GlobalTable from "../admin/GlobalTable";
import {
  columnForInvoice,
  invoicesStatus,
  columnForInvoiceAdmin,
  invoicesStatusAdmin,
  userRoles,
  statusAlertSeverity,
} from "../../../lib/constants";
import useGetInvoiceList from "../../../hooks/useGetInvoiceList";
import { navigate } from "gatsby";
import { Box } from "@mui/material";
import { getUser } from "../../../services/auth";
import StatusBadge from "../../global/elements/StatusBadge";
import Tips from "../../global/elements/Tips";
import UniversalLoader from "../../global/elements/UniversalLoader";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import FilterInvoice from "./FilterInvoice";
import { unauthorizedStatusAlert } from "../../../lib/styleConstants";

const styles = {
  loaderDiv: {
    paddingTop: "18%",
  },
  filter: {},
};
const InvoicePage = () => {
  const [
    allInvoices,
    loading,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    lastPage,
    loadData,
    setLoadData,
    authorized,
    setInvoiceTotal,
    setLastLoadedPage,
    setlastLoadedinvoice,
    filter,
    setFilter,
  ] = useGetInvoiceList();
  const userRole = getUser("user").role;

  const status =
    userRole == userRoles.admin ? invoicesStatusAdmin : invoicesStatus;

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "status": {
        return (
          <Box sx={{ display: "flex" }}>
            <StatusBadge
              name={status[value.status][0]}
              status={status[value.status][1]}
            />
          </Box>
        );
      }
      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    if (userRole == userRoles.admin) {
      navigate(`/app/admin/dashboard/invoices/${value.id}`);
    } else {
      navigate(`/app/my-profile/invoices/${value.id}`);
    }
  };

  const limitHandler = (value) => {
    setLimit(value);
    setLoadData(!loadData);
    setInvoiceTotal([]);
    setCurrentPage(1);
    setLastLoadedPage(0);
    setlastLoadedinvoice(null);
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = currentPage;
    if (type == "next") {
      if (!(lastPage === currentPage)) {
        pageNumberNew = pageNumberNew + 1;
        setLoadData(!loadData);
      }
    } else {
      pageNumberNew = pageNumberNew - 1;
      setLoadData(!loadData);
    }
    setCurrentPage(pageNumberNew);
  };

  const valueChangeHandler = (value) => {
    setFilter({ ...filter, "Payment Status": value });

    setLoadData(!loadData);
    setInvoiceTotal([]);
    setCurrentPage(1);
    setLastLoadedPage(0);
    setlastLoadedinvoice(null);
  };

  return (
    <Box>
      {authorized == true ? (
        <>
          {userRole == userRoles.admin ? (
            <Tips
              content={`Invoices are automatically created when the operator submission is approved and the data is sent to the client. 
              The operators should only approve and send their invoice. `}
            />
          ) : (
            <Tips
              content={`Here you will see a list of all your invoices with GLOBHE. Invoices are automatically created when your submission is approved and the data is sent to the client. 
              You should fill  your bank details first before the invoice is automatically generated.`}
            />
          )}
          <br />
          {userRole == userRoles.admin && (
            <Box sx={styles.filter}>
              <FilterInvoice valueChangeHandler={valueChangeHandler} />
            </Box>
          )}
          <GlobalTable
            columns={
              userRole == userRoles.admin
                ? columnForInvoiceAdmin
                : columnForInvoice
            }
            allData={allInvoices}
            valueConvertHandler={valueConvertHandler}
            goToPageFcn={goToPageFcn}
            loading={loading}
            limitHandler={limitHandler}
            limit={limit}
            paginationBtnClick={paginationBtnClick}
            currentPage={currentPage}
            isLastPage={isLastPage}
            lastPage={lastPage}
          />
        </>
      ) : authorized == false ? (
        <Box sx={unauthorizedStatusAlert}>
          <StatusAlertContainer
            heading="Unauthorized access! Your admin type does not allow you to work with invoices. 
            Please contact Napoleon to change your access rights."
            severity={statusAlertSeverity.error}
            coloredBackground
          />
        </Box>
      ) : (
        <Box sx={styles.loaderDiv}>
          <UniversalLoader />
        </Box>
      )}
    </Box>
  );
};
export default InvoicePage;
