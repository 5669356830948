import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import AppButton from "../../global/elements/AppButton";
import theme from "../../../theme";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  DialogTitle,
  Dialog,
  Checkbox,
  Typography,
} from "@mui/material";
import {
  confirmClientOrder,
  updateMission,
  updateQuote,
} from "../../../services/missions";
import { createOrderStatus } from "../../../services/orderStatus";
import firebase from "src/firebase";
import {
  notificationType,
  quoteStatus,
  orderLogStatus,
  missionStatus,
} from "../../../lib/constants";
import { createNotification } from "../../../services/notification";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { getUser } from "../../../services/auth";
import { getCurrentDateTimeMillis } from "../../../lib/helpers";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CustomizedDialogs = ({
  popupOpen,
  handlePopupClose,
  // missionID,
  mission,
  setRefreshMission,
  clickedQuoteId,
  // clientId,
  client,
}) => {
  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleButtonClick = async () => {
    let obj = {
      clientId: client.id,
      missionId: mission.id,
    };
    let dataForMail = {
      clientName: client?.name,
      clientCompany: client?.companyName,
      orderName: mission?.missionName,
      orderId: mission?.id,
    };

    createOrderStatus(firebase, {
      ...obj,
      status: orderLogStatus.quoteAccepted,
    });
    updateQuote(
      firebase,
      mission.id,
      clickedQuoteId,
      {
        ...obj,
        status: quoteStatus.accepted,
        acceptedOrRecjectedByClient: getUser("user").userID,
      },
      dataForMail
    );
    const updates = {
      quoteAcceptedByClientOn: getCurrentDateTimeMillis(),
    };
    updateMission(firebase, mission?.id, updates);
    // setLoadingMission(true);
    await createNotification(
      firebase,
      notificationType.adminNotification,
      "A quote is accepted by the client.",
      "A quote is accepted by the client.",
      `/app/missions/${mission.id}`
    );
    if (mission?.enterPriseId) {
      await createNotification(
        firebase,
        mission.enterPriseId,
        "Quote is accepted by a team member.",
        "Quote is accepted by a team member.",
        `/app/client/orderDetails/${mission.id}`
      );
    }
    if (mission.status == missionStatus.initialClientOrder) {
      confirmClientOrder(firebase, mission.id).then(() => {});
    }
    handlePopupClose();
    setRefreshMission(true);

    // setLoadingMission(false);
  };

  const styles = {
    bootstrapStyles: {
      border: `1px solid ${theme.palette.status.successDark}`,
    },
    dialogContentStyle: { padding: "3em", textAlign: "center" },
    dialogBox: { display: "flex", justifyContent: "end" },
    cancelIcon: {
      color: theme.palette.status.errorDark,
      cursor: "pointer",
    },
    textStyle: {
      textAlign: "center",
    },
    paraStyles: {
      margin: "2em 0 2em 0",
      color: theme.palette.grey.deepWarmGrey,
    },
    checkBoxStyle: { color: theme.palette.grey.greyBold },
    btnStyles: {
      display: "flex",
      marginTop: "2em",
      marginBottom: "4em",
      columnGap: "20px",
      justifyContent: "center",
      flexDirection: { sm: "row", xs: "column" },
    },
    linkStyles: { color: theme.palette.grey.deepWarmGrey },
    cancelBtn: { borderRadius: "20px" },
    confirmBtn: { borderRadius: "20px" },
  };

  return (
    <Box>
      <BootstrapDialog
        onClose={handlePopupClose}
        aria-labelledby="customized-dialog-title"
        open={popupOpen}
        fullWidth
        width="lg"
        sx={styles.bootstrapStyles}
      >
        <DialogContent dividers sx={styles.dialogContentStyle}>
          <Box sx={styles.dialogBox} onClick={handlePopupClose}>
            <CancelRoundedIcon sx={styles.cancelIcon} />
          </Box>
          <Typography variant="h4" sx={styles.textStyle}>
            Your acceptance to this price quote is legally binding
          </Typography>
          <Typography variant="h6" sx={styles.paraStyles}>
            Are you sure you want to accept this price quote?
            <span>This action cannot be undone</span>
          </Typography>
          <Box sx={styles.checkBoxStyle}>
            {" "}
            <Checkbox
              checked={acceptTerms}
              size="small"
              onChange={() => setAcceptTerms(!acceptTerms)}
              color="secondary"
            />
            I confirm that I have read the
            <a
              href="https://www.globhe.com/terms-and-conditions"
              rel="noreferrer"
              target="_blank"
              style={styles.linkStyles}
            >
              Terms and Conditions
            </a>
          </Box>
          <Box sx={styles.btnStyles}>
            <AppButton
              look="inverted"
              onClick={handlePopupClose}
              label="Cancel"
              noIcon
            />
            <AppButton
              look="black"
              onClick={handleButtonClick}
              label="Confirm"
              isDisabled={acceptTerms ? false : true}
            />
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
};

CustomizedDialogs.propTypes = {
  popupOpen: PropTypes.func,
  handlePopupClose: PropTypes.func,
  mission: PropTypes.object.isRequired,
  setRefreshMission: PropTypes.func,
  clickedQuoteId: PropTypes.string,
  setClickedQuoteId: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};
CustomizedDialogs.defaultProps = {
  popupOpen: undefined,
  handlePopupClose: undefined,
  setRefreshMission: undefined,
  clickedQuoteId: undefined,
};

export default CustomizedDialogs;
