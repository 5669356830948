import firebase from "src/firebase";
import { useEffect, useState } from "react";
import { isBrowser } from "../services/auth";
import { getSingleFeedback } from "../services/feedback";

const useGetFeedback = (id) => {
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    getSingleFeedback(firebase, id)
      .then((feedback) => {
        setFeedback(feedback);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return [feedback, loading];
};

export default useGetFeedback;
