import React, { useState, useEffect } from "react";
import Layout from "../../../Layout";
import TeamMainPage from "./TeamMainPage";
import { getClientById, getEnterPrise } from "../../../services/client";
import firebase from "src/firebase";
import { getUser } from "../../../services/auth";

const styles = {
  mTop: {
    marginTop: "20px",
  },
};

const EnterPriseTeamLayout = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [enterprise, setEnterprise] = useState(null);
  const currentUser = getUser("user");

  useEffect(() => {
    async function fetchData() {
      const memberData = await getClientById(firebase, currentUser?.userID);
      if (memberData?.enterPriseId) {
        const enterpriseInfo = await getEnterPrise(memberData?.enterPriseId);
        setEnterprise(enterpriseInfo);
      }
      setUserInfo(memberData);
    }
    fetchData();
  }, []);

  return (
    <Layout
      pageTitle={"Team"}
      hasSideMenu
      loading={false}
      className={styles.mTop}
    >
      {userInfo && (
        <TeamMainPage
          enterpriseInfo={enterprise || null}
          currentUser={userInfo}
        />
      )}
    </Layout>
  );
};

export default EnterPriseTeamLayout;
