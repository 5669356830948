import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import StatusBadge from "../../../global/elements/StatusBadge";
import {
  colorStatus,
  apiKeyStatus,
  defaultCurrency,
} from "../../../../lib/constants";
import LabelValuePair from "../../../global/elements/LabelValuePair";
import TitleAndDivider from "../../../global/elements/TypographyElements/TitleAndDivider";
import BottomDivider from "../../../global/elements/TypographyElements/BottomDivider";
import AppButton from "../../../global/elements/AppButton";
import BugIcon from "../../../../assets/images/bug.svg";

const styles = {
  infoContainer: {
    padding: { xs: "20px 0" },
  },
  boxContainer: {
    paddingTop: "20px",
  },
  actionContainer: {
    marginTop: "40px",
    float: "right",
  },
  noteIcon: {
    width: "45px",
    margin: "10px",
    marginLeft: 0,
  },
  apikeyBox: {
    display: "flex",
    alignItems: "center",
  },
};
const ClientInfoContainer = ({
  profile,
  apiStatus,
  enterprise,
  showEdit,
  isAdmin,
}) => {
  const getCurrency = () => {
    let str = "";

    if (profile?.enterPriseId) {
      str = enterprise?.currency;
    } else if (profile?.currency) {
      str = profile?.currency;
    }

    return str || defaultCurrency;
  };

  return (
    <Box sx={styles.infoContainer}>
      <Grid container spacing={2} sx={styles.boxContainer}>
        <LabelValuePair newDesign label="Name" value={profile?.name || "n/a"} />
        <BottomDivider isVisible />

        <LabelValuePair
          newDesign
          label="Company name"
          value={profile?.companyName || "n/a"}
        />
        <BottomDivider />

        <LabelValuePair
          newDesign
          label="Job title"
          value={profile?.jobTitle || "n/a"}
        />
        <BottomDivider />

        <LabelValuePair
          newDesign
          label="Email address"
          value={profile?.email || "n/a"}
        />
        <BottomDivider />

        <LabelValuePair
          newDesign
          label="Registration number"
          value={profile?.registrationNumber || "n/a"}
        />
        <BottomDivider isVisible />

        <LabelValuePair newDesign label="Currency" value={getCurrency()} />
        <BottomDivider />

        {profile?.vatNumber && (
          <>
            <LabelValuePair
              newDesign
              label="Vat number"
              value={profile?.vatNumber || "Vat number"}
            />
            <BottomDivider />
          </>
        )}
        {/* ---------- Address --------- */}
        <TitleAndDivider title="Address" />

        <LabelValuePair
          newDesign
          label="Address"
          value={profile?.companyAddress || "n/a"}
        />
        <BottomDivider isVisible />

        <LabelValuePair newDesign label="City" value={profile?.city || "n/a"} />
        <BottomDivider />

        <LabelValuePair
          newDesign
          label="Country"
          value={profile?.location || "n/a"}
        />
        <BottomDivider isVisible />

        <LabelValuePair
          newDesign
          label="Post code"
          value={profile?.postalCode || "n/a"}
        />
        <BottomDivider />

        <LabelValuePair
          newDesign
          label="Phone number"
          value={profile?.phone || "n/a"}
        />

        <LabelValuePair
          newDesign
          label="Activate Premium Membership"
          value={"http://www.globhe.com/premium-membership"}
          link
        />
        <BottomDivider />
        {showEdit && !isAdmin && (
          <Grid item xs={12}>
            <Box sx={styles.actionContainer}>
              <AppButton
                look="black"
                label="Edit profile"
                url={"/app/client/setting/profile/edit"}
                internalLink
                marginLeft
              />
            </Box>
          </Grid>
        )}

        {/* ---------- API --------- */}

        <TitleAndDivider title="Your API access" />
        <Grid item xs={2} md={1}>
          <Box sx={styles.noteIcon} component="img" src={BugIcon} />
        </Grid>
        <Grid item xs={10} md={11}>
          <Typography>
            Your API key grants you access to seamlessly place orders, retrieve
            your data, and explore our extensive data library. For further
            guidance, visit our developer&apos;s page. Upon signup, you&apos;ll
            receive an API key to access to our services. However, please note
            that this key requires activation by an admin before use. Kindly
            reach out to GLOBHE to initiate activation.
          </Typography>
        </Grid>

        <Grid item xs={12} />

        <LabelValuePair
          newDesign
          label="API key"
          value={profile?.apiKey || "n/a"}
        />
        <BottomDivider isVisible />

        <Grid item xs={12} md={6}>
          <Grid container sx={styles.apikeyBox}>
            <Grid item xs={2}>
              <Typography variant="subtitle1Med">API key status :</Typography>
            </Grid>
            <Grid item xs={10}>
              <StatusBadge
                status={
                  apiStatus === apiKeyStatus.granted
                    ? colorStatus.green
                    : apiStatus === apiKeyStatus.revoked
                    ? colorStatus.red
                    : colorStatus.yellow
                }
                name={
                  apiStatus === apiKeyStatus.requested ? "inactive" : apiStatus
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} />
        {isAdmin ? (
          <Grid item xs={12} md={6}>
            <AppButton
              label="Go to API key approval page"
              internalLink
              url={`/app/admin/api-keys/${profile?.apiKey}`}
              look="green"
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <AppButton
                label={
                  apiStatus === apiKeyStatus.granted
                    ? "Questions? contact support"
                    : "Activate your API key"
                }
                internalLink
                url="/app/support"
                look="green"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AppButton
                label="Check the developers page"
                externalLink
                targetNewWidow
                url="https://developers.globhe.com/integrations/mapbox"
                look="black"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

ClientInfoContainer.propTypes = {
  profile: PropTypes.object,
  role: PropTypes.string,
  apiStatus: PropTypes.string,
  enterprise: PropTypes.object,
  showEdit: PropTypes.bool,
  isAdmin: PropTypes.bool,
};
ClientInfoContainer.defaultProps = {
  profile: {},
  role: undefined,
  apiStatus: undefined,
  enterprise: {},
  showEdit: false,
  isAdmin: false,
};

export default ClientInfoContainer;
