import React from "react";
import { projectStatus } from "../../../../lib/constants";
import ProjectTable from "./ProjectTable";

const DraftProject = () => {
  const status = projectStatus.draft;

  return (
    <ProjectTable
      status={status}
      pageTitle={"Pending submission"}
      tips="Here you find your unsubmitted multi-location or recurrent orders. They are saved here and are not submitted yet. Make sure you have inserted all your sites and filled all your details. Once you are ready, go to the order and click on 'Submit order'"
    />
  );
};

export default DraftProject;
