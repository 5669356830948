import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getNews } from "../services/news-inspiration";

const useGetNewsList = () => {
  const [allNews, setAllNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(12);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    getNews(limit)
      .then((data) => {
        setAllNews(data?.news);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [allNews, loading];
};

export default useGetNewsList;
