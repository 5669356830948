import firebase from "src/firebase";
import { useEffect } from "react";
import { isBrowser } from "../services/auth";
import { useStateIfMounted } from "use-state-if-mounted";
import { documentsList } from "../services/documents";

const useGetDocumentsList = (enterpriseId) => {
  const [documents, setDocuments] = useStateIfMounted(null);
  const [allDocuments, setAllDocuments] = useStateIfMounted([]);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);
  const [lastLoadedDocument, setLastLoadedDocument] = useStateIfMounted(null);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [dataLoadedTill, setDataLoadedTill] = useStateIfMounted(null);
  const [isLastPage, setIslastPage] = useStateIfMounted(false);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [limit, setLimit] = useStateIfMounted(10);
  const [filter, setFilter] = useStateIfMounted({
    docName: null,
  });

  useEffect(() => {
    if (!firebase || !isBrowser() || !enterpriseId) return;
    try {
      setLoading(true);
      if (dataLoadedTill >= currentPage) {
        let prevPageMissions = allDocuments.slice(
          (currentPage - 1) * limit,
          currentPage * limit
        );
        setDocuments(prevPageMissions);
        setLoading(false);
      } else {
        documentsList(enterpriseId, limit, lastLoadedDocument, filter).then(
          (data) => {
            setDocuments(data.documents);
            setDataLoadedTill(currentPage);
            setLastLoadedDocument(data.lastVisible);
            setAllDocuments([...allDocuments, ...data.documents]);
            if (data.last) {
              setLastPageNumber(currentPage);
              setIslastPage(true);
            }
            setLoading(false);
          }
        );
      }
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  }, [triggerReload]);

  return {
    documents,
    setDocuments,
    allDocuments,
    setAllDocuments,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedDocument,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  };
};

export default useGetDocumentsList;
