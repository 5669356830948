import React, { useEffect, useState } from "react";
import theme from "../../../theme";
import { Typography, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { claculateProgress } from "../../../services/profile";
import StatusAlertContainer from "./StatusAlertContainer";
import { statusAlertSeverity } from "../../../lib/constants";
import { boxShadowStyle } from "../../../lib/styleConstants";

const style = {
  progress_bar_main: {
    width: "100%",
    padding: "10px 15px",
    position: "relative",
  },
  progress_text_main: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "8px",
  },
  infoIcon: {
    height: "24px",
    marginRight: "3px",
    color: theme.palette.secondary.main,
    fontSize: "20px",
    paddingBottom: "5px",
  },
  progress_bar: {
    color: theme.palette.secondary.lightLavender,
  },
  note_div: {
    display: { xs: "block", md: "flex" },
    margin: "15px 0px",
  },
  profileLink: {
    marginLeft: { md: "5px" },
    textDecoration: " none",
    color: theme.palette.secondary.main,
  },
  profileLinkBox: {
    display: { xs: "none", lg: "flex" },
    marginLeft: "10px",
  },
  percentageDiv: {
    marginTop: "15px",
    position: "absolute",
    right: { xs: 15, sm: 10 },
    top: { xs: 0, lg: "50%" },
  },
  hundred: {
    display: { xs: "none", lg: "inline" },
  },
  percentageChar: {
    display: { xs: "inline", lg: "none" },
  },
  percentage: {
    color: theme.palette.grey.warmGrey,
  },
  progress_text: {
    margin: "auto 5px",
    paddingLeft: "12px",
    paddingBottom: "8px",
    // "&.MuiTypography-h5": {
    //   color: theme.palette.grey.warmGrey,
    // },
  },
  profileLinkBlock: {
    display: "flex",
    justifyContent: "space-between",
  },
  success: {
    border: `2px solid ${theme.palette.status.successDark}`,
    borderRadius: "10px",
    marginBottom: { xs: "0px", sm: "10px" },
    width: "100%",
    padding: { xs: "20px 25px 40px", sm: "30px 35px" },
    boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
    background: theme.palette.primary.palePistachio,
  },
  MsgContainer: {
    color: theme.palette.primary.main,
    paddingTop: { xs: "10px", sm: "15px" },
  },
  ProfileCompleteText: {
    color: theme.palette.status.successDark,
    fontWeight: 500,
  },
  DoneAllIconStyle: {
    paddingLeft: "10px",
  },
};

const ProfileProgressBar = ({ userData }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    let profilePercent = claculateProgress(userData);
    setProgress(profilePercent);
  }, [userData]);

  return (
    <>
      {progress < 100 ? (
        <Box sx={{ ...style.progress_bar_main, ...boxShadowStyle }}>
          <Box sx={style.progress_text_main}>
            <InfoOutlinedIcon sx={style.infoIcon} />
            <Typography variant="subtitle1Med" sx={style.progress_text}>
              Profile completion
            </Typography>
          </Box>

          <LinearProgress
            color="inherit"
            variant="determinate"
            value={progress}
            style={style.progress_bar}
            sx={{ height: "5px" }}
          />

          <Box sx={style.profileLinkBlock}>
            <Box sx={style.note_div}>
              <Typography variant="body1">
                Complete your profile and you can qualify to be assigned for a
                mission.
                <Link style={style.profileLink} to="/app/my-profile/edit">
                  {" "}
                  Click here to complete
                </Link>
              </Typography>
            </Box>

            <Box component="span" sx={style.percentageDiv}>
              <Typography variant="h4" sx={style.percentage}>
                {Math.round(progress)}
                <Box component="span" sx={style.percentageChar}>
                  %
                </Box>
                <Box component="span" sx={style.hundred}>
                  / 100%
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <StatusAlertContainer
          heading="Profile is complete! You are now eligible for missions when clients put an order in
            your availability area."
          severity={statusAlertSeverity.success}
        />
      )}
    </>
  );
};
ProfileProgressBar.propTypes = {
  userData: PropTypes.object.isRequired,
};

export default ProfileProgressBar;
