import { Box, Grid } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import ClientInfoHeader from "../Profiles/components/ClientInfoHeader";
import firebase from "src/firebase";
import { deleteEnterpriseUser, getClientById } from "../../../services/client";
import AppButton from "../../global/elements/AppButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import theme from "../../../theme";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ProfileInfoHandler from "./Components/ProfileInfoHandler";
import { navigate } from "gatsby";
import {
  enterPriseRoleType,
  enterPriseUserRole,
  userRoles,
} from "../../../lib/constants";
import DeleteEnterpriseUser from "./Components/DeleteEnterpriseUser";
import { useSnackbar } from "notistack";
import TransparentBlackSavedScreen from "../../global/elements/TransparentBlackSavedScreen";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { getUser } from "../../../services/auth";
import AppContext from "../../../contexts/AppContext";

const styles = {
  actionBox: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "auto",
  },
  deleteIcon: {
    display: "flex",
    margin: "auto",
    marginRight: "30px",
    border: `1px solid ${theme.palette.black.darkSeaBlack}`,
    padding: "10px",
    width: "50px  !important",
    height: "50px !important",
    cursor: "pointer",
  },
  loaderDiv: {
    paddingTop: "18%",
  },
  disabledDelete: {
    color: `${theme.palette.black.darkSeaBlack}${66}`,
    border: `1px solid ${theme.palette.black.darkSeaBlack}${66}`,
    cursor: "not-allowed",
  },
};

const MemberMainPage = ({ memberId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUserId = getUser("user").userID;
  const [memberInfo, setMemberInfo] = useState({});
  const [deletePopup, setDeletePopup] = useState(false);
  const [removedUserConfirmation, setRemovedUserConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);
  const appContext = useContext(AppContext);

  useEffect(() => {
    //Client api call
    async function fetchData() {
      setLoading(true);
      if (memberId) {
        const memberData = await getClientById(
          firebase,
          memberId || currentUserId
        );
        setMemberInfo(memberData);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  const deleteUserFcn = async () => {
    setDeletePopup(false);
    setRemovedUserConfirmation(!removedUserConfirmation);
    const status = await deleteEnterpriseUser(
      memberInfo.id,
      memberInfo.email,
      memberInfo.enterPriseId
    );
    if (status.status === "Success") {
      setTimeout(() => {
        setRemovedUserConfirmation(false);
        navigate("/app/client/team");
      }, 2000);
    } else {
      enqueueSnackbar("Fail to remove user!", { variant: "danger" });
    }
  };

  return (
    <Box>
      {!loading ? (
        <Grid container mt={3} mb={3}>
          <Grid item xs={12} lg={9} xl={8}>
            <ClientInfoHeader profile={memberInfo} />
          </Grid>
          <Grid item xs={12} lg={3} xl={4} sx={styles.actionBox}>
            <DeleteForeverIcon
              sx={
                appContext?.userData?.role === userRoles.admin ||
                appContext?.userData?.roleType === enterPriseUserRole.general ||
                (appContext?.userData?.roleType === enterPriseUserRole.admin &&
                  appContext?.userData.id === memberId)
                  ? { ...styles.deleteIcon, ...styles.disabledDelete }
                  : styles.deleteIcon
              }
              onClick={() =>
                !(
                  appContext?.userData?.role === userRoles.admin ||
                  appContext?.userData?.roleType ===
                    enterPriseUserRole.general ||
                  (appContext?.userData?.roleType ===
                    enterPriseUserRole.admin &&
                    appContext?.userData.id === memberId)
                ) && setDeletePopup(true)
              }
            />
            <AppButton
              look={
                appContext?.userData?.role === userRoles.admin ||
                (appContext?.userData?.roleType ===
                  enterPriseUserRole.general &&
                  appContext?.userData.id !== memberId)
                  ? "blackDisabled"
                  : "black"
              }
              label="Edit"
              noIcon
              onClick={() =>
                navigate(`/app/client/team/member/edit/${memberId}`)
              }
              isDisabled={
                appContext?.userData?.role === userRoles.admin ||
                (appContext?.userData?.roleType ===
                  enterPriseUserRole.general &&
                  appContext?.userData.id !== memberId)
              }
            />
          </Grid>
          <Grid container xs={12} mt={5} spacing={3}>
            <Grid item xs={12} sm={6}>
              <ProfileInfoHandler
                icon={<InfoOutlinedIcon />}
                heading="Email"
                value={memberInfo?.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ProfileInfoHandler
                icon={<InfoOutlinedIcon />}
                heading="Permision"
                value={
                  enterPriseRoleType[
                    memberInfo?.roleType || enterPriseUserRole.general
                  ]
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ProfileInfoHandler
                icon={<InfoOutlinedIcon />}
                heading="Address"
                value={memberInfo?.companyAddress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ProfileInfoHandler
                icon={<InfoOutlinedIcon />}
                heading="Team"
                multiValue={true}
                valueArray={memberInfo?.type || []}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box sx={styles.loaderDiv}>
          <UniversalLoader />
        </Box>
      )}
      <DeleteEnterpriseUser
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        deleteUserFcn={deleteUserFcn}
      />
      <TransparentBlackSavedScreen
        open={removedUserConfirmation}
        textToShow="Member been removed"
      />
    </Box>
  );
};

MemberMainPage.propTypes = {
  memberId: PropTypes.string.isRequired,
};

export default MemberMainPage;
