import React, { useContext } from "react";
import Layout from "../../../Layout";
import ArrowHeaderContainer from "../NewDataLibrary/components/ArrowHeaderContainer";
import { Box, Typography } from "@mui/material";
import EnterpriseTabLayout from "./Components/EnterpriseTabLayout";
import AppContext from "../../../contexts/AppContext";

const styles = {
  mTop: {
    marginTop: "20px",
  },
  universalLoader: {
    paddingTop: "25%",
  },
};
const EnterpriseClientLayout = (props) => {
  const appContext = useContext(AppContext);

  return (
    <Layout hasSideMenu loading={false} className={styles.mTop}>
      <ArrowHeaderContainer
        header={appContext?.enterPrise?.name || "My enterprise account"}
        redirectURL={"/app/client/my-account"}
      >
        <Box sx={styles.mTop}>
          <Typography variant="subtitle1Med">
            Our enterprise account is designed to enhance your company’s
            ordering experience. Easily add members to your organisation, track
            orders in real-time, and provide seamless access to final products
            for all team members.
          </Typography>
          <EnterpriseTabLayout props={props} />
        </Box>
      </ArrowHeaderContainer>
    </Layout>
  );
};

export default EnterpriseClientLayout;
