import { Box, Button, Grid, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { preventEnterSubmit } from "src/lib/form-helpers";
import TextFieldInput from "../../global/FormInputComponents/TextFieldInput";
import { autocompletStyle, formInputField } from "../../../lib/styleConstants";
import ProfileImage from "../../global/ProfileImage";
import Dropzone from "react-dropzone";
import addProfileImage from "src/assets/images/addProfileImage.png";
import UploadButton from "../../global/elements/UploadButton";
import {
  updateEnterpriseUser,
  uploadProfileImageClient,
} from "../../../services/client";
import firebase from "src/firebase";
import { Cancel } from "@mui/icons-material";
import AppButton from "../../global/elements/AppButton";
import { navigate } from "gatsby";
import theme from "../../../theme";
import * as Yup from "yup";
import AutoCompleteInput from "../../global/FormInputComponents/AutoCompleteInput";
import {
  enterPriseRoleType,
  enterPriseUserRole,
  enterpriseClientRoles,
  typeOfDepartments,
} from "../../../lib/constants";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  profileImgContainer: {
    display: "block",
    width: "fit-content",
    position: "relative",
  },
  profileImage: {
    margin: "auto",
  },
  uploadImageButton: {
    marginTop: "20px",
  },
  btnRemove: {
    position: "absolute",
    top: { xs: "0px", md: "0px" },
    right: { xs: "10%", sm: 0, xl: "-20px" },
    background: "none",
    fontWeight: 500,
    fontSize: "25px",
    color: theme.palette.status.errorDark,
    boxShadow: "none",
    width: 0,
    "&:hover": {
      color: theme.palette.status.errorLight,
      background: "none",
    },
  },
};

const TeamEditForm = ({ memberId, memberInfo }) => {
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const editTeamMemberProfile = async (values) => {
    console.log("Edited", values);
    if (values.roleType === enterPriseRoleType.admin) {
      values.roleType = enterPriseUserRole.admin;
    } else if (values.roleType === enterPriseRoleType.general) {
      values.roleType = enterPriseUserRole.general;
    } else {
      values.roleType = enterPriseUserRole.general;
    }
    let status = await updateEnterpriseUser(memberId, values);
    if (status.status === "Success") {
      enqueueSnackbar("Details updated!", { variant: "success" });
      navigate(`/app/client/team/member/${memberId}`);
    } else {
      enqueueSnackbar("Fail to update the details!", { variant: "danger" });
    }
  };

  const selectedRole = enterpriseClientRoles?.filter(
    (role) => role.value === memberInfo?.roleType
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    roleType: Yup.string().required("Permission is required").nullable(),
    type: Yup.array(),
  });

  const formInitialValues = {
    profileImageURL: memberInfo?.profileImageURL || null,
    name: memberInfo?.name || null,
    roleType: selectedRole?.length > 0 ? selectedRole[0].label : null,
    type: memberInfo?.type || [],
    email: memberInfo?.email || null,
  };
  console.log("profileImageURL", memberInfo?.profileImageURL);

  const onProfileImageDropHandler = (acceptedFiles, setFieldValue) => {
    setProfileImageLoading(true);
    if (acceptedFiles) {
      uploadProfileImageClient(firebase, acceptedFiles[0], memberId)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((downloadURL) => {
          setFieldValue("profileImageURL", downloadURL);
        })
        .catch((err) => console.log(err))
        .finally(() => setProfileImageLoading(false));
    } else {
      console.log("incorrect image");
    }
  };
  return (
    <>
      {memberInfo && (
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={editTeamMemberProfile}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form onKeyDown={preventEnterSubmit} id="memberForm">
                <Grid container sx={styles.profileImgContainer} mt={5}>
                  <Grid item sx={styles.profileImage}>
                    <ProfileImage
                      imageURL={values.profileImageURL || addProfileImage}
                      side="190px"
                      loading={profileImageLoading}
                    />
                    <Dropzone
                      accept="image/jpeg, image/jpg, image/png"
                      onDrop={(acceptedFiles) =>
                        onProfileImageDropHandler(acceptedFiles, setFieldValue)
                      }
                      maxSize={5000000}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Box sx={styles.uploadImageButton}>
                            <UploadButton
                              text="Upload profile picture"
                              onClick={() =>
                                setFieldValue("profileImageURL", null)
                              }
                            />
                          </Box>
                        </div>
                      )}
                    </Dropzone>
                    {values.profileImageURL && (
                      <Tooltip title="Delete profile pic" placement="top-end">
                        <Button
                          sx={styles.btnRemove}
                          size="small"
                          onClick={() => setFieldValue("profileImageURL", null)}
                          flat
                        >
                          <Cancel />
                        </Button>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={3} mt={3}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={formInputField}>
                      <TextFieldInput
                        setFieldValue={setFieldValue}
                        values={values}
                        name={"name"}
                        placeholder="Full Name"
                        disabled={false}
                        type="text"
                        required
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={formInputField}>
                      <AutoCompleteInput
                        label="Permision"
                        options={enterpriseClientRoles?.map(
                          ({ label }) => label
                        )}
                        name="roleType"
                        placeholder={"Permision"}
                        additionalStyle={autocompletStyle}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={formInputField}>
                      <AutoCompleteInput
                        label="Type name of the department"
                        options={typeOfDepartments}
                        name="type"
                        placeholder={"Type name of the department"}
                        additionalStyle={autocompletStyle}
                        multiple
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={formInputField}>
                      <TextFieldInput
                        setFieldValue={setFieldValue}
                        values={values}
                        name={"email"}
                        placeholder="Email"
                        disabled={true}
                        type="text"
                        required
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
                <Grid container sx={styles.buttonContainer} spacing={3}>
                  <Grid item>
                    <AppButton
                      label="Cancel"
                      noIcon
                      look={"inverted"}
                      onClick={() =>
                        navigate(`/app/client/team/member/${memberId}`)
                      }
                    />
                  </Grid>
                  <Grid item mr={3}>
                    <AppButton
                      label="Edit"
                      noIcon
                      type="submit"
                      form="memberForm"
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

TeamEditForm.propTypes = {
  memberId: PropTypes.string.isRequired,
  memberInfo: PropTypes.object.isRequired,
};

export default TeamEditForm;
