import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Dialogue from "../../../global/Dialogue";
import PropTypes from "prop-types";

const styles = {
  popupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
};

const DialogueBody = () => {
  return (
    <Box sx={styles.popupCls}>
      <Typography variant="h6">
        Do you want to delete this agreement?
      </Typography>
      <Typography variant="subtitle1">
        Once deleted, the agreement will not be available anymore.
      </Typography>
    </Box>
  );
};

const DeleteClientAgreement = ({ open, setOpen, onSubmit }) => {
  const [loading, setLoading] = useState(false);

  const deleteAgreement = async () => {
    setLoading(true);
    onSubmit();
  };

  return (
    <Dialogue
      showDialogue={open}
      setShowDialogue={setOpen}
      DialogueHeadText={""}
      DialogueBody={DialogueBody()}
      showCancelButton={true}
      cancelButtonText={"Cancel"}
      sumitButtonText={"Submit"}
      showSubmitLoader={loading}
      setShowSubmitLoader={setLoading}
      onSubmit={() => deleteAgreement()}
      submitButtonLook={"primary"}
    />
  );
};

DeleteClientAgreement.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeleteClientAgreement;
