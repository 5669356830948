import { TYPES } from "../types";

const getCartDetail = (missionData) => {
  return {
    type: TYPES.GET_CART_DETAIL,
    payload: {
      missionData,
    },
  };
};

const addAllToCartAction = (missionData) => {
  return {
    type: TYPES.ADD_ALL_TO_CART,
    payload: {
      ...missionData,
    },
  };
};

const ClearCart = () => {
  return {
    type: TYPES.CLEAR_CART,
  };
};

const getCartDetailSuccess = ({ data }) => {
  return {
    type: TYPES.GET_CART_DETAIL_SUCCESS,
    payload: {
      data,
    },
  };
};
const addAllItemsSuccess = ({ data }) => {
  return {
    type: TYPES.ADD_ALL_CART_SUCCESS,
    payload: {
      data,
    },
  };
};
const deleteCartItem = (id) => {
  return {
    type: TYPES.DELETE_CART_ITEM,
    data: {
      id,
    },
  };
};
const deleteItemSuccess = (data) => {
  return {
    type: TYPES.DELETE_CART_ITEM_SUCCESS,
    payload: {
      data,
    },
  };
};
const getCartDetailError = () => {
  return { type: TYPES.GET_CART_DETAIL_ERROR };
};

export {
  getCartDetail,
  getCartDetailSuccess,
  getCartDetailError,
  deleteCartItem,
  deleteItemSuccess,
  ClearCart,
  addAllToCartAction,
  addAllItemsSuccess,
};
