import PropTypes from "prop-types";
import React from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Tooltip,
  CardContent,
} from "@mui/material";
import { Link } from "gatsby";
import useGetPilotsByIds from "../../../hooks/useGetPilotsByIds";
import firebase from "src/firebase";
import {
  countryListAlpha2,
  missionTypeConstant,
  contractStatusLabel,
  missionStatus,
} from "../../../lib/constants";
import {
  missionListSource,
  contractWaiverLabels,
} from "../../../lib/constants";
import theme from "../../../theme";
import useGetDeclinedFeedback from "../../../hooks/useGetDeclinedFeedback";
const { DateTime } = require("luxon");
import defaultImage from "src/assets/images/profileImage.jpg";
import ReactCountryFlag from "react-country-flag";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import {
  confirmPilotClicked,
  confirmSignedContractClicked,
} from "../../../services/pilots";
import { getMissionContract } from "../../../services/contracts";
import { updateMission } from "../../../services/missions";
import { createNotification } from "../../../services/notification";
import SwitchSelect from "../../global/elements/SwitchSelect";
import StatusBadge from "../../global/elements/StatusBadge";
import AppButton from "../../global/elements/AppButton";

const style = {
  rejectPilot: {
    color: theme.palette.status.errorLight,
  },
  bold_info: {
    cursor: "pointer",
  },
  label: {
    lineHeight: "normal",
    color: theme.palette.black.darkSeaBlack,
    fontSize: "15px",
  },
  table_cell: {
    minWidth: 110,
    wordWrap: "break-word",
    padding: { md: 5 },
  },
  table_head: {
    display: "none",
  },
  link_row: {
    cursor: "pointer",
  },
  single_field_div: {
    display: "flex",
    padding: "15px",
    borderBottom: `1px solid ${theme.palette.grey.lightWarmGrey}`,
  },
  single_field_heading_div: {
    width: "40%",
  },
  single_field_heading: {
    color: theme.palette.black.coolGrey,
    float: "left",
    wordWrap: "break-word",
  },
  single_field_data_div: {
    width: "55%",
  },
  cursorChange: {
    cursor: "pointer",
  },
  avtarBox: {
    display: "flex",
  },
  avtarImage: {
    width: "50px",
    height: "50px",
    border: "3px solid",
    borderColor: theme.palette.primary.main,
    "& img": {
      padding: "2px",
      borderRadius: "50%",
    },
  },
  // chipBoxActive: {
  //   backgroundColor: theme.palette.status.successDark,
  //   fontSize: "10px",
  //   color: theme.palette.grey.white,
  //   fontWeight: 500,
  //   borderRadius: "10px",
  // },
  // chipBoxAlert: {
  //   backgroundColor: theme.palette.status.errorLight,
  //   fontSize: "10px",
  //   color: theme.palette.status.errorDark,
  //   fontWeight: 500,
  //   borderRadius: "10px",
  // },
  // chipBoxWarning: {
  //   backgroundColor: theme.palette.status.warningDark,
  //   fontSize: "10px",
  //   color: theme.palette.black.darkSeaBlack,
  //   fontWeight: 500,
  //   borderRadius: "10px",
  // },
  mainCard: {
    maxWidth: "100%",
    height: "100%",
    borderRadius: "20px",
    padding: "30px",
    boxShadow: "0 8px 40px -12px rgba(0,248,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,248,0,0.3)",
    },
  },
};

const MissionPilotsList = ({
  missionData,
  columnForPilot,
  unassignPilotHandler,
  uninvitePilotHandler,
  source,
  assignMission,
  submitMissionHandler,
  onEditingFinished,
  mission,
}) => {
  const [feedbacks] = useGetDeclinedFeedback(missionData?.id);
  //since we can't use different hooks on a conditional base, we'll use the getPilotsByIds() hook both for sentToPilots[] and assignedPilot (in this case delivered as array with one element).
  const removeAssignPilot = missionData.assignedPilot
    ? missionData.sentToPilots.filter((id) => id !== missionData.assignedPilot)
    : missionData.sentToPilots;

  const [pilots] = useGetPilotsByIds(
    source == missionListSource.invite
      ? removeAssignPilot || []
      : [missionData?.assignedPilot],
    missionData?.id,
    source
  );

  const [invoice, setInvoice] = useStateIfMounted(
    missionData?.mayPilotEditInvoice
      ? { allowInvoice: true }
      : { allowInvoice: false }
  );
  const [waive, setWaive] = useStateIfMounted(
    missionData?.contractAcceptedViaAdmin == contractWaiverLabels.accepted
      ? { contractAcceptedViaAdmin: true }
      : { contractAcceptedViaAdmin: false }
  );

  const getInvitedDate = (missionData, id) => {
    let date = "";
    if (
      missionData &&
      missionData.sentToPilotsInfo &&
      missionData.sentToPilotsInfo.length
    ) {
      let pilotObj = missionData.sentToPilotsInfo.filter(
        (msn) => msn.pilotId === id
      );

      if (pilotObj && pilotObj[0] && pilotObj[0].invitedDate) {
        date = DateTime.fromISO(pilotObj[0].invitedDate).toISODate();
      }
    }
    return date;
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "date": {
        return (
          <Typography variant="body1">
            {DateTime.fromISO(value["dateCreated"]).toISODate()}
          </Typography>
        );
      }
      case "dateInvited": {
        return (
          <Typography variant="body1">
            {getInvitedDate(missionData, value?.id)}{" "}
          </Typography>
        );
      }
      case "array":
        return (
          <Typography variant="body1">
            {value["equipment"] && value["equipment"].length > 0
              ? value["equipment"].toString()
              : "n/a"}
          </Typography>
        );
      default:
        return;
    }
  };

  const getCountryFlag = (countryName) => {
    let countryCode = Object.keys(countryListAlpha2).filter(
      (key) => countryListAlpha2[key] == countryName
    );
    return (
      <ReactCountryFlag
        countryCode={countryCode[0] || "SE"}
        aria-label={countryName}
        svg
        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
        cdnSuffix="svg"
        style={{
          position: "relative",
          top: "2px",
          fontSize: "22px",
          borderRadius: "100%",
        }}
        title={countryName}
      />
    );
  };

  const getStatus = (value) => {
    if (missionData?.declinedByPilots?.find((ele) => ele === value)) {
      return <StatusBadge name="Rejected" status="red" />;
    } else {
      return <StatusBadge name={"Invited" || "N/A"} status="yellow" />;
    }
  };
  const handleWaiveContract = (event) => {
    setWaive({ ...waive, [event.target.name]: event.target.checked });
    onSubmitMission();
  };
  const handleMayEditInvoice = (event) => {
    setInvoice({ ...waive, [event.target.name]: event.target.checked });
    onSubmitEditInvoiceMission();
  };
  const onUnassignPilotClicked = async (pilot) => {
    const pilotId = pilot.id;
    if (missionData?.missionType === missionTypeConstant.clientOrder) {
      let contract = await getMissionContract(pilotId, missionData?.id);
      if (contract?.status === contractStatusLabel.signed) {
        if (confirmSignedContractClicked()) unassignPilotHandler(pilot);
      } else {
        if (confirmPilotClicked("unassign")) unassignPilotHandler(pilot);
      }
    } else {
      if (confirmPilotClicked("unassign")) unassignPilotHandler(pilot);
    }
  };
  const onUninvitePilotClicked = async (id) => {
    if (confirmPilotClicked("uninvite")) uninvitePilotHandler(id);
  };

  const getDeclinedReason = (pilotId) => {
    var str = "";
    if (!missionData?.declinedByPilots?.some((id) => id === pilotId) || false) {
      str = "N/A";
    } else {
      const declinedFeedback = feedbacks?.find((f) => f.pilotId == pilotId);
      if (declinedFeedback) {
        if (declinedFeedback && declinedFeedback?.declinedReason?.length > 0) {
          str = declinedFeedback?.declinedReason?.join(" , ");
        }
        if (declinedFeedback && declinedFeedback?.otherDeclinedReason) {
          str = str + ", " + declinedFeedback?.otherDeclinedReason;
        }
      } else {
        str = "N/A";
      }
    }
    return str;
  };
  const onSubmitMission = () => {
    updateMission(firebase, missionData.id, {
      contractAcceptedViaAdmin: waive?.contractAcceptedViaAdmin
        ? contractWaiverLabels.notAccepted
        : contractWaiverLabels.accepted,
    })
      .then(() => {
        if (!waive?.contractAcceptedViaAdmin) {
          createNotification(
            firebase,
            missionData.assignedPilot,
            `Your contract has been waived.`,
            "Your contract has been waived.",
            `/app/missions/${missionData.id}`
          );
        }
        submitMissionHandler(true);
      })
      .catch((err) => submitMissionHandler(false, err.message))
      .finally(onEditingFinished());
  };

  const onSubmitEditInvoiceMission = () => {
    updateMission(firebase, missionData.id, {
      mayPilotEditInvoice: invoice.allowInvoice ? false : true,
    })
      .then(() => {
        submitMissionHandler(true);
      })
      .catch((err) => submitMissionHandler(false, err.message))
      .finally(onEditingFinished());
  };

  const onApproveHandler = async (row) => {
    assignMission(row);
    if (
      mission.clientId &&
      mission.missionType == missionTypeConstant.clientOrder
    ) {
      await createNotification(
        firebase,
        mission.enterPriseId || mission.clientId,
        "An operator is assigned to your mission.",
        "An operator is assigned to your mission.",
        `/app/client/orderDetails/${mission.id}`
      );
    }
  };
  console.log(missionData.length > 0, source, pilots);

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        margin: "auto",
        boxShadow: { xs: "none", sm: "0px 0px 4px rgba(0, 0, 0, 0.25)" },
        padding: { xs: 0, sm: "2%" },
        border: "none",
      }}
    >
      <TableContainer
        sx={{
          maxHeight: 620,
          display: { xs: "none", sm: "block", md: "block" },
          borderColor: theme.palette.primary.pistachio,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead id={style.table_head}>
            <TableRow>
              {columnForPilot?.map((column, i) => (
                <TableCell
                  key={`first-${i}` || column.id}
                  align={column.align}
                  style={
                    column.sort
                      ? { ...style.cursorChange, ...style.table_cell }
                      : style.table_cell
                  }
                >
                  <Box
                    sx={style.label}
                    // onClick={() => {
                    //   if (column.sort) {
                    //     sortByHandler(column.id);
                    //   }
                    // }}
                  >
                    {column.label}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {missionData.length > 0
              ? missionData &&
                missionData?.map((row, index) => {
                  return (
                    <TableRow
                      key={`second-${index}`}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={{
                        "& > *": {
                          height: 120,
                          paddingTop: 3,
                          paddingBottom: 3,
                        },
                      }}
                      style={style.link_row}
                    >
                      {columnForPilot?.map((column) => {
                        const value = row[column?.id] || "n/a";
                        return (
                          <TableCell
                            sx={{ overflowWrap: "anywhere" }}
                            key={`third-${column.id}`}
                            align={column.align}
                          >
                            {column.field === "date" ? (
                              valueConvertHandler("date", row)
                            ) : column.field === "capital" ? (
                              <Typography
                                style={style.bold_info}
                                variant="body1"
                              >
                                {value || "N/A"}
                              </Typography>
                            ) : column.field == "status" ? (
                              <Box>
                                {
                                  <StatusBadge
                                    name={"Interested" || "N/A"}
                                    status="green"
                                  />
                                }
                              </Box>
                            ) : column.field == "flag" ? (
                              <Box sx={style.avtarBox}>
                                {getCountryFlag(value)}
                                <Typography variant="body1" marginLeft={1}>
                                  {value || "N/A"}
                                </Typography>
                              </Box>
                            ) : column.field == "avtar" ? (
                              <Link
                                to={
                                  row?.pilotId
                                    ? `/app/pilots/${row.pilotId}`
                                    : row.id && `/app/pilots/${row.id}`
                                }
                                style={{ textDecoration: "none" }}
                              >
                                <Box sx={style.avtarBox}>
                                  <Avatar
                                    alt="Operator's avatar"
                                    src={row?.profileImageURL || defaultImage}
                                    sx={style.avtarImage}
                                  />{" "}
                                  <Typography
                                    variant="body1"
                                    marginTop={1}
                                    marginLeft={1}
                                  >
                                    {value || "N/A"}
                                  </Typography>
                                </Box>
                              </Link>
                            ) : column.field == "approve" ? (
                              <AppButton
                                look="blue"
                                noIcon
                                small
                                label="Approve"
                                onClick={() => onApproveHandler(row)}
                              />
                            ) : column.field == "price" ? (
                              <Typography variant="body1">${value}</Typography>
                            ) : column.field == "normal" ? (
                              <Typography variant="body1">
                                {value || "N/A"}
                              </Typography>
                            ) : column.field === "dateInvited" ? (
                              valueConvertHandler("dateInvited", row)
                            ) : (
                              <>
                                <Tooltip title="Uninvite pilot">
                                  <CancelIcon
                                    lIcon
                                    onClick={() =>
                                      onUninvitePilotClicked(row.id)
                                    }
                                    sx={style.rejectPilot}
                                  />
                                </Tooltip>
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              : pilots?.map((row) => {
                  return (
                    <TableRow
                      key={`fifth-${row.id}`}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={{
                        "& > *": {
                          height: 120,
                          paddingTop: 3,
                          paddingBottom: 3,
                        },
                      }}
                      style={style.link_row}
                    >
                      {columnForPilot?.map((column, i) => {
                        const value = row[column.id] || "n/a";
                        return (
                          <TableCell
                            sx={{ overflowWrap: "anywhere" }}
                            key={`fourth-${i}`}
                            align={column.align}
                          >
                            {column.field === "date" ? (
                              valueConvertHandler("date", row)
                            ) : column.field === "capital" ? (
                              <Typography
                                style={style.bold_info}
                                variant="body1"
                              >
                                {value || "N/A"}
                              </Typography>
                            ) : column.field == "status" ? (
                              <Box>{getStatus(row.id)}</Box>
                            ) : column.field == "flag" ? (
                              <Box sx={style.avtarBox}>
                                {getCountryFlag(value)}
                                <Typography variant="body1" marginLeft={1}>
                                  {value || "N/A"}
                                </Typography>
                              </Box>
                            ) : column.field == "avtar" ? (
                              <Link
                                to={row?.id && `/app/pilots/${row.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Box sx={style.avtarBox}>
                                  <Avatar
                                    alt="Operator's avatar"
                                    src={row?.profileImageURL || defaultImage}
                                    sx={style.avtarImage}
                                  />{" "}
                                  <Typography
                                    variant="body1"
                                    marginTop={1}
                                    marginLeft={1}
                                  >
                                    {value || "N/A"}
                                  </Typography>
                                </Box>
                              </Link>
                            ) : column.field == "waiveButton" ? (
                              missionData.status != missionStatus.completed ? (
                                <SwitchSelect
                                  defaultChecked
                                  checked={waive.contractAcceptedViaAdmin}
                                  handleChange={handleWaiveContract}
                                  name="contractAcceptedViaAdmin"
                                />
                              ) : (
                                <Typography variant="body1">
                                  {missionData.contractAcceptedViaAdmin}
                                </Typography>
                              )
                            ) : column.field == "editInvoicebutton" ? (
                              missionData.status != missionStatus.completed ? (
                                <SwitchSelect
                                  defaultChecked
                                  checked={invoice.allowInvoice}
                                  handleChange={(e) =>
                                    handleMayEditInvoice(e, row.id)
                                  }
                                  name="allowInvoice"
                                />
                              ) : (
                                <Typography variant="body1">N/A</Typography>
                              )
                            ) : column.field == "unassign" &&
                              missionData.status != missionStatus.completed ? (
                              <AppButton
                                look="negative"
                                noIcon
                                small
                                label="Unassign"
                                onClick={() => onUnassignPilotClicked(row)}
                              />
                            ) : column.field == "reasonForRejection" ? (
                              <Typography variant="body1">
                                {getDeclinedReason(row && row?.id)}
                              </Typography>
                            ) : column.field == "price" ? (
                              <Typography variant="body1">${value}</Typography>
                            ) : column.field == "normal" ? (
                              <Typography variant="body1">
                                {value || "N/A"}
                              </Typography>
                            ) : column.field === "dateInvited" ? (
                              valueConvertHandler("dateInvited", row)
                            ) : (
                              <>
                                {missionData.status !=
                                  missionStatus.completed && (
                                  <Tooltip title="Uninvite pilot">
                                    <CancelIcon
                                      onClick={() =>
                                        onUninvitePilotClicked(row.id)
                                      }
                                      sx={style.rejectPilot}
                                    />
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: { sm: "none", md: "none" },
          padding: "5px",
        }}
      >
        {missionData.length > 0
          ? missionData &&
            missionData?.map((row, i) => {
              return (
                <Box
                  sx={{
                    margin: "15px 0px",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  }}
                  key={`sixth-${i}`}
                >
                  <CardContent>
                    {columnForPilot?.map((column, key) => {
                      const value = row[column.id] || "n/a";
                      return (
                        <Box sx={style.single_field_div} key={`seventh-${key}`}>
                          <Box sx={style.single_field_heading_div}>
                            <Typography
                              variant="h5"
                              style={style.single_field_heading}
                            >
                              {column.label}
                            </Typography>
                          </Box>
                          <Box sx={style.single_field_data_div}>
                            <Box style={style.single_field_data}>
                              {column.field === "date" ? (
                                valueConvertHandler("date", row)
                              ) : column.field === "capital" ? (
                                <Typography
                                  style={style.bold_info}
                                  variant="h5"
                                >
                                  {value || "N/A"}
                                </Typography>
                              ) : column.field == "status" ? (
                                <Box>
                                  {
                                    <StatusBadge
                                      name={"Interested" || "N/A"}
                                      status="green"
                                    />
                                  }
                                </Box>
                              ) : column.field == "flag" ? (
                                <Box sx={style.avtarBox}>
                                  {getCountryFlag(value)}
                                  <Typography variant="h5" marginLeft={1}>
                                    {value || "N/A"}
                                  </Typography>
                                </Box>
                              ) : column.field == "avtar" ? (
                                <Box sx={style.avtarBox}>
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={
                                      row?.pilot?.profileImageURL ||
                                      defaultImage
                                    }
                                    sx={style.avtarImage}
                                  />{" "}
                                  <Typography
                                    variant="h5"
                                    marginTop={1}
                                    marginLeft={1}
                                  >
                                    {value || "N/A"}
                                  </Typography>
                                </Box>
                              ) : column.field == "approve" ? (
                                <AppButton
                                  look="blue"
                                  noIcon
                                  small
                                  label="Approve"
                                  onClick={() => assignMission(row)}
                                />
                              ) : column.field == "price" ? (
                                <Typography variant="h5">${value}</Typography>
                              ) : column.field == "normal" ? (
                                <Typography variant="h5">
                                  {value || "N/A"}
                                </Typography>
                              ) : (
                                <>
                                  <CancelPresentationOutlinedIcon
                                    onClick={() =>
                                      onUninvitePilotClicked(row.id)
                                    }
                                  />
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </CardContent>
                </Box>
              );
            })
          : pilots?.map((row, i) => {
              return (
                <Box
                  sx={{
                    margin: "15px 0px",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  }}
                  key={`seventh-${i}`}
                >
                  <CardContent>
                    {columnForPilot?.map((column, key) => {
                      const value = row[column.id] || "n/a";
                      return (
                        <Box sx={style.single_field_div} key={`eight-${key}`}>
                          <Box sx={style.single_field_heading_div}>
                            <Typography
                              variant="h5"
                              style={style.single_field_heading}
                            >
                              {column.label}
                            </Typography>
                          </Box>
                          <Box sx={style.single_field_data_div}>
                            <Box style={style.single_field_data}>
                              {column.field === "dateInvited" ? (
                                valueConvertHandler("dateInvited", row)
                              ) : column.field === "capital" ? (
                                <Typography
                                  style={style.bold_info}
                                  variant="h5"
                                >
                                  {value || "N/A"}
                                </Typography>
                              ) : column.field == "status" ? (
                                <Box>{getStatus(row.id)}</Box>
                              ) : column.field == "flag" ? (
                                <Box sx={style.avtarBox}>
                                  {getCountryFlag(value)}
                                  <Typography variant="h5" marginLeft={1}>
                                    {value || "N/A"}
                                  </Typography>
                                </Box>
                              ) : column.field == "avtar" ? (
                                <Box sx={style.avtarBox}>
                                  <Avatar
                                    alt="Operator's avatar"
                                    src={row?.profileImageURL || defaultImage}
                                    sx={style.avtarImage}
                                  />{" "}
                                  <Typography
                                    variant="h5"
                                    marginTop={1}
                                    marginLeft={1}
                                  >
                                    {value || "N/A"}
                                  </Typography>
                                </Box>
                              ) : column.field == "waiveButton" ? (
                                missionData.status !=
                                missionStatus.completed ? (
                                  <SwitchSelect
                                    defaultChecked
                                    checked={waive.contractAcceptedViaAdmin}
                                    handleChange={handleWaiveContract}
                                    name="contractAcceptedViaAdmin"
                                  />
                                ) : (
                                  <Typography variant="h5">
                                    {missionData.contractAcceptedViaAdmin}
                                  </Typography>
                                )
                              ) : column.field == "editInvoicebutton" ? (
                                missionData.status !=
                                missionStatus.completed ? (
                                  <SwitchSelect
                                    defaultChecked
                                    checked={invoice.allowInvoice}
                                    handleChange={(e) =>
                                      handleMayEditInvoice(e, row.id)
                                    }
                                    name="allowInvoice"
                                  />
                                ) : (
                                  <Typography variant="h5">{"N/A"}</Typography>
                                )
                              ) : column.field == "unassign" &&
                                missionData.status !=
                                  missionStatus.completed ? (
                                <AppButton
                                  look="negative"
                                  noIcon
                                  small
                                  label="Unassign"
                                  onClick={() => onUnassignPilotClicked(row)}
                                />
                              ) : column.field == "reasonForRejection" ? (
                                <Typography variant="h5">
                                  {getDeclinedReason(row && row?.id)}
                                </Typography>
                              ) : column.field == "price" ? (
                                <Typography variant="h5">${value}</Typography>
                              ) : column.field == "normal" ? (
                                <Typography variant="h5">
                                  {value || "N/A"}
                                </Typography>
                              ) : (
                                <>
                                  <CancelPresentationOutlinedIcon
                                    onClick={() =>
                                      onUninvitePilotClicked(row.id)
                                    }
                                  />
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </CardContent>
                </Box>
              );
            })}
      </Box>
    </Box>
  );
};

MissionPilotsList.propTypes = {
  missionData: PropTypes.object.isRequired,
  unassignPilotHandler: PropTypes.func,
  uninvitePilotHandler: PropTypes.func,
  assignMission: PropTypes.func,
  submitMissionHandler: PropTypes.func,
  onEditingFinished: PropTypes.func,
  columnForPilot: PropTypes.array.isRequired,
  source: PropTypes.string,
  mission: PropTypes.object,
};

MissionPilotsList.defaultProps = {
  unassignPilotHandler: null,
  uninvitePilotHandler: null,
  assignMission: null,
  submitMissionHandler: null,
  onEditingFinished: null,
  source: null,
  mission: null,
};

export default MissionPilotsList;
