import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import { getMapTypeImage, displayLabel } from "../../../../lib/helpers";
import IconBaseData from "../../../../assets/images/icon_base_data.svg";
import { jpgFileInfo, userRoles } from "../../../../lib/constants";
import { getUser } from "../../../../services/auth";
import ImageSelector from "./ImageSelector";

const styles = {
  mapIcons: {
    height: "75px",
    width: "75px",
    margin: "auto",
  },
  jpgIcon: {
    display: "grid",
    height: "75px",
    width: "40px",
    margin: "auto",
  },
  mapTypeContainer: {
    display: "inline-flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  dividerCls: {
    borderColor: theme.palette.grey.dividersColor,
    width: "inherit",
  },
  mapTypeContainerBox: {
    padding: "10px",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  labelField: {
    width: "100%",
  },
  IconBaseBox: { margin: "auto" },
};
const MapTypes = ({
  mapTypes,
  colMdSpacing,
  colXsSpacing,
  clickable,
  selectedMapTypes,
  // onClick,
  // setHoveredMap,
  onRemove,
  onSelect,
  summaryFlag,
  setJpgFlag,
  hideJpg,
}) => {
  const currentUser = getUser("user");
  const isAdmin = currentUser.role === userRoles.admin;
  const isClient = currentUser.role === userRoles.client;

  const selectedMapColor = {
    backgroundColor: isClient
      ? theme.palette.primary.palePistachio
      : isAdmin
      ? theme.palette.adminBlue.paleBlue
      : theme.palette.secondary.lightPurple,
  };

  const getMapIcon = (mapId) => {
    const mapTypeImage = getMapTypeImage(mapId);
    return <Box component="img" src={mapTypeImage} sx={styles.mapIcons} />;
  };

  const checkItemSelected = (item) => {
    return selectedMapTypes?.includes(item?.label);
  };

  const selectMapType = (item) => {
    if (clickable) {
      let selectedItems = [];
      console.log("item", item, selectedMapTypes);

      if (checkItemSelected(item)) {
        // Remove this item
        selectedItems = selectedMapTypes.filter((map) => map !== item?.label);
        onRemove(selectedItems);
      } else {
        // Add this item
        selectedItems = selectedMapTypes
          ? [...selectedMapTypes, item.label]
          : [item.label];
        onSelect(item?.label);
      }
    }
    return;
  };

  return (
    <Grid container spacing={2}>
      {!hideJpg && (
        <Grid
          item
          xs={colXsSpacing}
          md={colMdSpacing}
          onClick={() => {
            if (!summaryFlag) {
              selectMapType(jpgFileInfo);
              setJpgFlag(true);
            }
          }}
          sx={{ cursor: summaryFlag ? "default" : "pointer" }}
        >
          <Box
            sx={
              !summaryFlag
                ? { ...styles.mapTypeContainer, ...selectedMapColor }
                : { ...styles.mapTypeContainer }
            }
          >
            <Box sx={styles.mapTypeContainerBox}>
              <Box component="img" src={IconBaseData} sx={styles.jpgIcon} />
              <Typography variant="body3">JPG files</Typography>
            </Box>
          </Box>
        </Grid>
      )}

      <ImageSelector
        colXsSpacing={colXsSpacing}
        colMdSpacing={colMdSpacing}
        clickable={clickable}
        summaryFlag={summaryFlag}
        selectMapType={selectMapType}
        setJpgFlag={setJpgFlag}
        checkItemSelected={checkItemSelected}
        itemList={mapTypes}
        getMapIcon={getMapIcon}
        displayLabel={displayLabel}
      />
    </Grid>
  );
};

MapTypes.propTypes = {
  mapTypes: PropTypes.array,
  colMdSpacing: PropTypes.number,
  colXsSpacing: PropTypes.number,
  clickable: PropTypes.bool,
  selectedMapTypes: PropTypes.array,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  summaryFlag: PropTypes.bool,
  setJpgFlag: PropTypes.func,
  hideJpg: PropTypes.bool,
};

MapTypes.defaultProps = {
  mapTypes: [],
  colMdSpacing: 2,
  colXsSpacing: 12,
  clickable: false,
  selectedMapTypes: [],
  onRemove: undefined,
  onSelect: undefined,
  summaryFlag: false,
  setJpgFlag: undefined,
  hideJpg: false,
};

export default MapTypes;
