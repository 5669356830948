import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  root: {
    marginBottom: "20px",
  },
};

const ParagraphHeading = ({ text }) => {
  return (
    <Box sx={styles.root}>
      <Typography variant="subtitle1Med">{text}</Typography>
    </Box>
  );
};
ParagraphHeading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ParagraphHeading;
