import React from "react";
import useGetInspiration from "../../../hooks/useGetInspiration";
import EditCreateInspiration from "./EditCreateInspiration";
import PropTypes from "prop-types";

const UpdateInspiration = ({ inspirationId }) => {
  const [inspiration, loading] = useGetInspiration(inspirationId);

  return (
    <div>
      <EditCreateInspiration
        pageTitle="Edit Inspiration"
        inspiration={inspiration}
        update={true}
        inspirationId={inspirationId}
        loading={loading}
      />
    </div>
  );
};
UpdateInspiration.propTypes = {
  inspirationId: PropTypes.string,
};

UpdateInspiration.defaultProps = {
  inspirationId: undefined,
};

export default UpdateInspiration;
