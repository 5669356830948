import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";
import { fontWeightFive } from "../../../lib/styleConstants";

const styles = {
  missionOrderCls: { border: "1px solid black", padding: "20px" },
};

const InfoBox = ({ title, subText, additionalStyle }) => {
  return (
    <Box sx={{ ...styles.missionOrderCls, ...additionalStyle }}>
      <Typography variant="body2" sx={fontWeightFive}>
        {title}
      </Typography>
      <Typography variant="body3">{subText}</Typography>
    </Box>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  additionalStyle: PropTypes.object,
};

InfoBox.defaultProps = {
  additionalStyle: {},
};

export default InfoBox;
