import React from "react";
import Layout from "../../../Layout";
import Tips from "../../global/elements/Tips";
import { Grid } from "@mui/material";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import theme from "../../../theme";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ToolsCard from "./ToolsCard";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ArticleIcon from "@mui/icons-material/Article";
const styles = {
  iconStyle: {
    color: theme.palette.black.darkSeaBlack,
    fontSize: "35px",
  },
};

const Tools = () => {
  return (
    <>
      <Layout pageTitle="Tools" hasSideMenu>
        <Tips content="These tools will help you plan your flights, talk to customers, and evaluate the complexity of a project" />
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <ToolsCard
              icon={<ArchitectureIcon sx={styles.iconStyle} />}
              buttonUrl="/app/admin/tools/sales"
              title="Sales assistant & project planning"
              subtitle="Measure the complexity of a project and calculate the price
                    that the client should pay"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <ToolsCard
              icon={<TravelExploreIcon sx={styles.iconStyle} />}
              buttonUrl="/app/admin/tools/country-profile"
              title="Country profiles"
              subtitle="Quick reference to drone regulations globally and
              visualizing where drones are available or not"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <ToolsCard
              icon={<QueryStatsIcon sx={styles.iconStyle} />}
              buttonUrl="/app/admin/tools/statistics"
              title="App statistics"
              subtitle="Check the operators, clients, and mission statistics, all in one place for your convenience."
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <ToolsCard
              icon={<PersonAddAlt1Icon sx={styles.iconStyle} />}
              buttonUrl="/app/admin/tools/addClients"
              title="Add clients"
              subtitle="Add clients that are not in the platform so you can easily created orders for them."
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <ToolsCard
              icon={<ArticleIcon sx={styles.iconStyle} />}
              buttonUrl="/app/admin/tools/documents-and-guides"
              title="Documents and guides"
              subtitle="Check pricing guidelines and T&C docs for both operators and clients."
            />
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default Tools;
