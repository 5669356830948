import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Avatar } from "@mui/material";
import { navigate } from "gatsby";
import React from "react";
import theme from "../../theme";
import PropTypes from "prop-types";
import { getUser } from "../../services/auth";
import { userRoles } from "../../lib/constants";

const styles = {
  root: {
    // width: "100%",
    display: "block",
    "& .MuiBox-root.css-1p5055z": {
      marginLeft: "63px",
    },
  },
  link: {
    color: theme.palette.black.coolGrey,
    "&:hover": {
      color: theme.palette.grey.lightWarmGrey,
      cursor: "pointer",
    },
  },
  label: {
    marginLeft: "0px",
    marginTop: "34px",
  },
  id: {
    color: theme.palette.black.darkSeaBlack,
    fontSize: { xs: "12px", sm: "14px", md: "16px" },
    lineHeight: 17 / 13,
    fontWeight: 500,
    paddingLeft: "4px",
  },
  idLabel: {
    color: theme.palette.black.darkSeaBlack,
    fontSize: { xs: "12px", sm: "14px", md: "16px" },
    lineHeight: 17 / 13,
  },
  ArrowBackIconStyle: {
    color: theme.palette.black.coolGrey,
    fontSize: "2rem",
  },
  iconContainer: {
    columnGap: { xs: "10px" },
    alignItems: "center",
  },
};

const BackNav = ({ routeTo, additionalStyles }) => {
  const currentUser = getUser("user");

  const isAdmin = currentUser?.role === userRoles.admin;
  const isClient = currentUser?.role === userRoles.client;
  const isPilot = currentUser?.role === userRoles.pilot;

  const avatar = {
    backgroundColor: isAdmin
      ? theme.palette.adminBlue.main
      : isPilot
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
    "&.MuiAvatar-root": {
      width: { xs: "24px", sm: "30px", md: "40px" },
      height: { xs: "24px", sm: "30px", md: "40px" },
    },
  };
  const ArrowBackIconStyle = {
    color: isClient ? theme.palette.black.coolGrey : theme.palette.grey.white,
    fontSize: "2rem",
  };
  const resolveBackNavUrl = (path) => {
    if (path) {
      navigate(path);
    } else {
      window.history.back();
    }
  };
  return (
    <Box sx={styles.root}>
      <Box onClick={() => resolveBackNavUrl(routeTo)} sx={styles.link}>
        <Box sx={styles.iconContainer}>
          <Avatar sx={avatar}>
            <ArrowBackIcon
              sx={{ ...ArrowBackIconStyle, ...additionalStyles }}
            />
          </Avatar>
        </Box>
      </Box>
    </Box>
  );
};

BackNav.propTypes = {
  routeTo: PropTypes.string,
  additionalStyles: PropTypes.object,
};

BackNav.defaultProps = {
  routeTo: null,
  additionalStyles: {},
};

export default BackNav;
