import React from "react";
import Layout from "../../../Layout";
import SingleOrderDataLibrary from "./SingleOrderDataLibrary";
import PropTypes from "prop-types";
import ArrowHeaderContainer from "./components/ArrowHeaderContainer";
import { getUser } from "../../../services/auth";
import { userRoles } from "../../../lib/constants";

const SingleOrderDataLibraryLayout = ({ missionId }) => {
  const currentUser = getUser("user");
  return (
    <Layout
    // backButtonPageName="Data library"
    // // loading={loading}
    // backButton
    // backNavUrl={"/app/client/data-library"}
    // addBottomPadding
    >
      <ArrowHeaderContainer
        header="Data library"
        redirectURL={
          currentUser.role == userRoles.client
            ? "/app/client/data-library"
            : "/app/pilot/data-library"
        }
      >
        <SingleOrderDataLibrary missionId={missionId} />
      </ArrowHeaderContainer>
    </Layout>
  );
};

SingleOrderDataLibraryLayout.propTypes = {
  missionId: PropTypes.string.isRequired,
};

export default SingleOrderDataLibraryLayout;
