import React from "react";
import { Box, Typography } from "@mui/material";
import UploadInvoice from "./UploadInvoice";
import AppButton from "../../global/elements/AppButton";
import { flexColumn, flexible, flexSpace } from "../../../lib/styleConstants";
import theme from "../../../theme";
import DownloadIcon from "@mui/icons-material/Download";
import Notes from "src/assets/images/notes.png";
import { InvoiceText, paymentStatus } from "../../../lib/constants";
import PropTypes from "prop-types";
import StatusBadge from "../../global/elements/StatusBadge";
import QuotesTermsAndConditions from "../Invoices/QuotesTermsAndConditions";

const styles = {
  InvoiceDetailBox: {
    padding: "1.4em",
    rowGap: "0.6em",
    boxShadow: `0px 0px 20px ${theme.palette.grey.lightWarmGrey}E6`,
  },
  uploadInvoiceHeaderInnerImg: {
    maxWidth: "40px",
    maxHeight: "40px",
    marginRight: "10px",
  },
  paymentStatus: {
    padding: "0.3em 1em",
    background: theme.palette.status.errorLight,
  },
  termsAndCondition: {
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
  },
  buttonContainer: {
    alignItems: "center",
    flexDirection: { xs: "column-reverse", sm: "row" },
    justifyContent: "space-between",
  },
  uploadContainer: {
    flexDirection: { xs: "column", sm: "row" },
    rowGap: "10px",
  },
  downloadButton: {
    whiteSpace: "nowrap",
  },
};

const PaidStatus = () => {
  return <StatusBadge status="green" name="Paid" />;
};
const UnpaidStatus = () => {
  return <StatusBadge status="red" name="Unpaid" />;
};

const InvoiceDetails = ({
  headText,
  bodyText,
  mission,
  invoiceType,
  isDisabled,
  clientInoviceUrl,
  isAdmin,
  refreshMission,
  clientInvoiceReady,
  isProject,
}) => {
  const checkIfSingle = () => {
    return invoiceType === InvoiceText.singleInvoice ? true : false;
  };

  return (
    <Box
      sx={{
        ...styles.InvoiceDetailBox,
        ...flexColumn,
        opacity: mission.breakInvoice ? (isDisabled ? 0.2 : 1) : "unset",
      }}
    >
      <Box sx={{ ...flexSpace }}>
        <Box>
          <Typography variant="h5">{headText}</Typography>
          <Typography variant="body2">{bodyText}</Typography>
        </Box>
        <Box>
          <Box
            src={Notes}
            component="img"
            sx={styles.uploadInvoiceHeaderInnerImg}
          />
        </Box>
      </Box>
      <Box sx={{ ...flexible, columnGap: "10px" }}>
        {(invoiceType === InvoiceText.singleInvoice ||
          invoiceType === InvoiceText.secondInvoice) &&
          (clientInvoiceReady === false ? (
            ""
          ) : mission.paymentStatusClient === paymentStatus.paid ? (
            <PaidStatus />
          ) : (
            <UnpaidStatus />
          ))}
        {invoiceType === InvoiceText.firstInvoice &&
          (clientInvoiceReady === false ? (
            ""
          ) : mission.preDeliverypaymentStatusClient === paymentStatus.paid ? (
            <PaidStatus />
          ) : (
            <UnpaidStatus />
          ))}
      </Box>
      <Box
        sx={{
          ...flexSpace,
          ...styles.buttonContainer,
        }}
      >
        <QuotesTermsAndConditions />
        <Box
          sx={{
            ...flexible,
            ...styles.uploadContainer,
            width: checkIfSingle()
              ? { xs: "100%", sm: "unset" }
              : { xs: "100%", sm: "max-content" },
            columnGap: checkIfSingle() ? "6em" : "10px",
          }}
        >
          {isAdmin && (
            <UploadInvoice
              mission={mission}
              invoiceType={invoiceType}
              isDisabled={isDisabled}
              clientInoviceUrl={clientInoviceUrl}
              refreshMission={refreshMission}
              checkIfSingle={checkIfSingle}
              isProject={isProject}
            />
          )}
          <AppButton
            label={checkIfSingle() ? "Download invoice" : "Download"}
            look={
              clientInvoiceReady && isAdmin
                ? "blue"
                : clientInvoiceReady && !isAdmin
                ? "green"
                : "blackDisabled"
            }
            customIcon={<DownloadIcon />}
            addtionalStyle={{
              ...styles.downloadButton,
              width: checkIfSingle() ? "100%" : { xs: "100%", sm: "120px" },
            }}
            onClick={() => window.open(clientInoviceUrl)}
            isDisabled={!clientInoviceUrl?.length ? true : false}
          />
        </Box>
      </Box>
    </Box>
  );
};

InvoiceDetails.propTypes = {
  mission: PropTypes.object,
  refreshMission: PropTypes.func,
  isDisabled: PropTypes.bool,
  clientInoviceUrl: PropTypes.string,
  headText: PropTypes.string,
  bodyText: PropTypes.string,
  invoiceType: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  clientInvoiceReady: PropTypes.bool,
  isProject: PropTypes.bool,
};
InvoiceDetails.defaultProps = {
  mission: undefined,
  refreshMission: undefined,
  isDisabled: false,
  clientInoviceUrl: undefined,
  headText: undefined,
  bodyText: undefined,
  invoiceType: InvoiceText.singleInvoice,
  clientInvoiceReady: false,
  isProject: false,
};
export default InvoiceDetails;
