import { Box, TextField, Autocomplete } from "@mui/material";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import React from "react";
import { useEffect, useState, useCallback } from "react";
import {
  ClientPage,
  FilterCollaborationsOrderList,
  FilterFieldsClientOrders,
  FilterFieldsClientPlans,
  FilterFieldsGroupedOrder,
  groupOrderMissionTypeConstant,
  groupOrderStatus,
  userRoles,
} from "../../../../lib/constants";
import AutoCompleteFilter from "../../../global/elements/AutoCompleteFilter";
import { getInitialFilters } from "../../../../lib/helpers";
import { ErrorHelperText } from "../../../../lib/styleConstants";

const styles = {
  filterFieldsCss: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill, 240px)",
    margin: "10px 0 10px 10px",
  },
  errorCls: {
    color: "red !important",
  },
};
const FilterOrders = ({ valueChangeHandler, role, missionTypeData }) => {
  const [filterField, setFilterField] = useState([]);
  const [changedFilterMonitor, setchangedFilterMonitor] = useState(false);
  const [filterData, setFilterData] = useState({
    "Order Id": "",
    "Order Name": "",
  });
  const [filterOptions, setFilterOptions] = useState([]);
  const [errorMultipleArrayField, setErrorMultipleFieldError] = useState(false);

  const arrayFilterField = ["Order Name", "Status"];

  useEffect(() => {
    let options = getFilterOption();
    setFilterOptions(options);
  }, []);

  useEffect(() => {
    let filterOptions = getFilterOption();
    let filterObj = getInitialFilters(filterOptions);

    setFilterField(filterObj?.filterField || []);
    setFilterData(filterObj?.filters || {});
  }, [changedFilterMonitor]);

  const getFilterOption = () => {
    if (role === userRoles.client) {
      if (missionTypeData === ClientPage.Collaboration) {
        return FilterCollaborationsOrderList;
      } else if (missionTypeData === ClientPage.Orders) {
        return FilterFieldsClientOrders;
      } else if (missionTypeData === ClientPage.Plans) {
        return FilterFieldsClientPlans;
      } else if (missionTypeData === ClientPage.GroupOrder) {
        return FilterFieldsGroupedOrder;
      }
    } else {
      return FilterFieldsClientOrders;
    }
  };

  const restrictFilter = (filter) => {
    return arrayFilterField.every((element) => filter.includes(element));
  };
  const onChangeFilterField = (e, newValue) => {
    let selectedFilterOption = newValue;
    if (typeof selectedFilterOption == "string") {
      selectedFilterOption = [newValue];
    }
    if (restrictFilter([...selectedFilterOption, ...filterField])) {
      setErrorMultipleFieldError(true);
    } else {
      setErrorMultipleFieldError(false);
      if (selectedFilterOption) setFilterField(selectedFilterOption);
      setchangedFilterMonitor(!changedFilterMonitor);
      if (selectedFilterOption?.length > 0) {
        localStorage.setItem("filterField", selectedFilterOption);
      } else {
        localStorage.setItem("filterField", []);
      }
    }

    // valueChangeHandler("", "", selectedFilterOption);
  };

  const debounceHandler = useCallback(
    debounce((value, field) => {
      valueChangeHandler(value, field, filterField);
    }, 500),
    [valueChangeHandler, filterField]
  );

  const handleInputChange = useCallback(
    (e) => {
      const fieldName = e.target.name;
      const value = e.target.value;
      setFilterData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
      debounceHandler(value, fieldName);
    },
    [debounceHandler]
  );

  return (
    <Box sx={styles.filterContainer}>
      <AutoCompleteFilter
        filterOptions={filterOptions}
        filterField={filterField}
        onChangeFilterField={onChangeFilterField}
        helperText={
          !errorMultipleArrayField
            ? "Select the fields to filter your orders with"
            : `You can not merge ${arrayFilterField.join(", ")} filters`
        }
        multiple={true}
        helperTextStyle={errorMultipleArrayField ? ErrorHelperText : {}}
      />
      <Box sx={styles.filterFieldsCss}>
        {filterField?.includes("Order Id") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Order Id"]}
              // onChange={(e) => {
              //   let newData = filterData;
              //   newData["Order Id"] = e.target.value;
              //   setFilterData(newData);
              //   valueChangeHandler(e.target.value, "Order Id", filterField);
              // }}
              onChange={handleInputChange}
              label="Order Id"
              name="Order Id"
              sx={styles.field}
            />
          </Box>
        )}
        {filterField?.includes("Order Name") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              name="Order Name"
              value={filterData["Order Name"]}
              // onChange={(e, value) => {
              //   let newData = filterData;
              //   newData["Order Name"] = e.target.value;
              //   setFilterData(newData);
              //   valueChangeHandler(e.target.value, "Order Name", filterField);
              // }}
              onChange={handleInputChange}
              label="Order Name"
              sx={styles.field}
            />
          </Box>
        )}
        {filterField?.includes("Id") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Id"]}
              // onChange={(e, value) => {
              //   let newData = filterData;
              //   newData["Id"] = e.target.value;
              //   setFilterData(newData);
              //   valueChangeHandler(e.target.value, "Id", filterField);
              // }}
              onChange={handleInputChange}
              name="Id"
              label="Id"
              sx={styles.field}
            />
          </Box>
        )}
        {filterField?.includes("Quote") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={["RECEIVED", "NOT RECIEVED"]}
              value={filterData["Quote"]}
              onChange={(e, value) => {
                valueChangeHandler(value, "Quote", filterField);
                let newData = filterData;
                newData["Quote"] = value;
                setFilterData(newData);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Quote" />
              )}
            />
          </Box>
        )}

        {filterField?.includes("Order Type") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={Object.values(groupOrderMissionTypeConstant)}
              value={filterData["Order Type"]}
              onChange={(e, value) => {
                valueChangeHandler(value, "Order Type", filterField);
                let newData = filterData;
                newData["Order Type"] = value;
                setFilterData(newData);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Order Type" />
              )}
            />
          </Box>
        )}

        {filterField?.includes("Status") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={Object.values(groupOrderStatus)}
              value={filterData["Status"]}
              onChange={(e, value) => {
                valueChangeHandler(value, "Status", filterField);
                let newData = filterData;
                newData["Status"] = value;
                setFilterData(newData);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Status" />
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
FilterOrders.propTypes = {
  valueChangeHandler: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  missionTypeData: PropTypes.string.isRequired,
};
export default FilterOrders;
