import { Box, Backdrop, Stack, Typography } from "@mui/material";
import React from "react";
import ClockLoader from "react-spinners/ClockLoader";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  textColor: {
    color: theme.palette.grey.white,
    padding: "20px",
  },
};

const Clockloader = ({ text }) => {
  return (
    <Box>
      <Backdrop open={open}>
        <Stack gap={1} justifyContent="center" alignItems="center">
          <ClockLoader
            color={theme.palette.grey.white}
            speedMultiplier={0.1}
            cssOverride={{
              border: `2px solid ${theme.palette.grey.white}`,
            }}
            size={100}
          />
          {text && (
            <Box sx={{ width: "50%" }}>
              <Typography variant="h4" sx={styles.textColor}>
                {text}
              </Typography>
            </Box>
          )}
        </Stack>
      </Backdrop>
    </Box>
  );
};

Clockloader.propTypes = {
  text: PropTypes.string,
};

Clockloader.defaultProps = {
  text: "",
};

export default Clockloader;
