import React from "react";
import PropTypes from "prop-types";
import { SocialIcon } from "react-social-icons";

const style = {
  social_icon: {
    width: "25px",
    height: "25px",
    margin: "8px",
  },
};

const SocialMediaIconHolder = ({ socialData, iconType }) => {
  return (
    <React.Fragment>
      <SocialIcon
        url={socialData}
        style={style.social_icon}
        network={iconType || "instagram"}
        target="_blank"
      />
    </React.Fragment>
  );
};

SocialMediaIconHolder.propTypes = {
  socialData: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
};

export default SocialMediaIconHolder;
