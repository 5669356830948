import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { getUser } from "../../../../services/auth";
import { userRoles } from "../../../../lib/constants";
import theme from "../../../../theme";
import PropTypes from "prop-types";

const styles = {
  mapTypeContainer: {
    display: "inline-flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  mapTypeContainerBox: {
    padding: "10px",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  labelField: {
    width: "100%",
  },
};

function ImageSelector({
  colXsSpacing,
  colMdSpacing,
  clickable,
  summaryFlag,
  selectMapType,
  setJpgFlag,
  checkItemSelected,
  itemList,
  getMapIcon,
  displayLabel,
}) {
  const currentUser = getUser("user");
  const isAdmin = currentUser.role === userRoles.admin;
  const isClient = currentUser.role === userRoles.client;

  const selectedMapColor = {
    backgroundColor: isClient
      ? theme.palette.primary.palePistachio
      : isAdmin
      ? theme.palette.adminBlue.paleBlue
      : theme.palette.secondary.lightPurple,
  };
  return (
    <>
      {itemList?.map((item, i) => {
        return (
          <>
            <Grid
              item
              xs={colXsSpacing}
              md={colMdSpacing}
              key={i}
              sx={{ cursor: clickable ? "pointer" : "default" }}
              onClick={() => {
                if (!summaryFlag) {
                  selectMapType(item);
                  setJpgFlag(false);
                }
              }}
            >
              <Box
                sx={
                  checkItemSelected(item)
                    ? { ...styles.mapTypeContainer, ...selectedMapColor }
                    : { ...styles.mapTypeContainer }
                }
              >
                <Box sx={styles.mapTypeContainerBox}>
                  {getMapIcon(item.label ? item.label : item)}
                  <Typography variant="body3" sx={styles.labelField}>
                    {item.label ? displayLabel(item.label) : displayLabel(item)}
                  </Typography>
                </Box>

                {/* {checkItemSelected(item) && <Divider sx={styles.dividerCls} />} */}
              </Box>
            </Grid>
          </>
        );
      })}
    </>
  );
}

ImageSelector.propTypes = {
  itemList: PropTypes.array,
  colMdSpacing: PropTypes.number,
  colXsSpacing: PropTypes.number,
  clickable: PropTypes.bool,
  summaryFlag: PropTypes.bool,
  setJpgFlag: PropTypes.func,
  hideJpg: PropTypes.bool,
  selectMapType: PropTypes.object,
  getMapIcon: PropTypes.func,
  checkItemSelected: PropTypes.array.isRequired,
  displayLabel: PropTypes.func.isRequired,
};

ImageSelector.defaultProps = {
  itemList: [],
  colMdSpacing: 2,
  colXsSpacing: 12,
  clickable: false,
  selectMapType: {},
  getMapIcon: undefined,
  summaryFlag: false,
  setJpgFlag: undefined,
  hideJpg: false,
};

export default ImageSelector;
