import React, { useState } from "react";
import theme from "../../../theme";
import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { preventEnterSubmit } from "src/lib/form-helpers";
import {
  createNews,
  singleNewsUpdate,
} from "../../../services/news-inspiration";
import { getUser } from "../../../services/auth";
import { navigate } from "gatsby";
import { singleNewsDelete } from "../../../services/news-inspiration";
import AppButton from "../../global/elements/AppButton";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import { createNotification } from "../../../services/notification";
import firebase from "src/firebase";
import { notificationType, statusAlertSeverity } from "../../../lib/constants";

const FormComponent = ({ news, update, newsId }) => {
  const style = {
    label_text: {
      marginBottom: "5px",
      color: theme.palette.grey.warmGrey,
      paddingLeft: "3px",
    },
    input_box: {
      width: "100%",
      margin: "5px",
      boxShadow: `0px 0px 4px ${theme.palette.black.coolGrey}`,
      borderRadius: "10px",
      padding: "5px 20px",
      color: theme.palette.grey.warmGrey,
      fontSize: "16px",
      fontWeight: 450,
    },
  };
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    status: false,
    message: "",
    type: statusAlertSeverity.success,
  });
  const [btn_loading, setBtn_loading] = useState(false);

  const newsDeleteHandler = async () => {
    setBtn_loading(true);
    const status = await singleNewsDelete(newsId);
    if (status.status == "Success") {
      setBtn_loading(false);
      navigate("/app/admin/news");
    }
  };

  const formSubmitHandler = async (values, { setSubmitting }) => {
    setLoading(true);
    if (values?.title?.trim() != "" && values?.description?.trim() != "") {
      let userId = getUser("user")?.userID || "";
      if (!update) {
        createNews(values, userId).then((status) => {
          if (status.status == "Success") {
            // createNotification(
            //   firebase,
            //   notificationType.clientNotification,
            //   "Published a new news for you.",
            //   "Published a new news for you.",
            //   `/app/client/news/${status.newsId}`
            // );
            createNotification(
              firebase,
              notificationType.pilotNotification,
              "Published a new news for you.",
              "Published a new news for you.",
              `/app/admin/news/${status.newsId}`
            );
            setStatusMessage({
              status: true,
              message: "News Created Successfully!",
              type: statusAlertSeverity.success,
            });
            setLoading(false);
          } else {
            setStatusMessage({
              status: true,
              message: "Something is wrong!",
              type: statusAlertSeverity.error,
            });
            setLoading(false);
          }
        });
      } else {
        singleNewsUpdate(values, newsId).then((status) => {
          if (status.status == "Success") {
            setStatusMessage({
              status: true,
              message: "News Updated Successfully!",
              type: statusAlertSeverity.success,
            });
            setLoading(false);
          } else {
            setStatusMessage({
              status: true,
              message: "Something is wrong!",
              type: statusAlertSeverity.error,
            });
            setLoading(false);
          }
        });
      }
    }
  };

  const formInitialValues = {
    title: news?.title || "",
    description: news?.description || "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  });

  return (
    <React.Fragment>
      {statusMessage.status && (
        <React.Fragment>
          <StatusAlertContainer
            severity={statusMessage?.type}
            heading={statusMessage?.message}
          />
        </React.Fragment>
      )}
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ isSubmitting, errors, values, setFieldValue, submitCount }) => {
          return (
            <Form onKeyDown={preventEnterSubmit} id="newsForm">
              <Typography variant="h5" style={style.label_text}>
                Title
              </Typography>
              <Field
                style={style.input_box}
                type="text"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                name="title"
                component={TextField}
              />
              <Typography variant="h5" style={style.label_text}>
                Body Text
              </Typography>
              <Field
                style={style.input_box}
                multiline
                type="text"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                minRows={8}
                name="description"
                component={TextField}
              />
              <br />
              <br />
              <Box sx={style.ButtonContainer}>
                <AppButton
                  type="submit"
                  label={update ? "Update" : "Publish"}
                  submittingState={loading}
                  addtionalStyle={{ float: "right" }}
                  look="black"
                  form="newsForm"
                />

                {update && (
                  <AppButton
                    onClick={newsDeleteHandler}
                    label="Delete"
                    submittingState={btn_loading}
                    addtionalStyle={{
                      float: "right",
                    }}
                    marginRight
                    look="negative"
                  />
                )}

                <AppButton
                  url="/app/admin/news"
                  internalLink
                  label={update ? "Cancel" : "Back"}
                  look="inverted"
                  noIcon
                />
              </Box>
              <br />
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

FormComponent.propTypes = {
  news: PropTypes.object,
  update: PropTypes.bool,
  newsId: PropTypes.string,
};
FormComponent.defaultProps = {
  news: {},
  update: false,
  newsId: undefined,
};

export default FormComponent;
