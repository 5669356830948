import { Box, Typography } from "@mui/material";
import React from "react";
import Dialogue from "../../../global/Dialogue";
import PropTypes from "prop-types";
import { getUser } from "../../../../services/auth";
import EnterPriseMemberForm from "../../../forms/client-form/EnterPriseMemberForm";

const styles = {
  addMemberPopup: {
    margin: "50px 0",
    textAlign: "center",
  },
  subHeadingCls: {
    marginTop: "30px",
  },
};

const AddMembers = ({ addMemberPopup, setAddMembersPopup, onInviteSent }) => {
  const currentUser = getUser("userProfile");

  const addMemberBody = () => {
    return (
      // <Box sx={styles.addMemberPopup}>
      //   <Typography variant="h5">
      //     Lorem ipsum dolor sit amet consectetur. Ultrices sapien amet facilisi
      //     id lectus nulla nibh nibh at.
      //   </Typography>
      // </Box>
      <Box>
        <Typography variant="h3">Add a member</Typography>
        <Typography variant="h6" sx={styles.subHeadingCls}>
          To add members, write their name and email. They will receive an
          invitation email and will be added automatically to your team after
          registration
        </Typography>
        <Box sx={styles.addMemberPopup}>
          <EnterPriseMemberForm
            client={currentUser}
            showSkipBtn={false}
            onClose={() => setAddMembersPopup(false)}
            onInviteSent={onInviteSent}
            useAsPopup={true}
          />
        </Box>
      </Box>
    );
  };

  const addMemberFcn = () => {
    console.log("Members added successfully");
  };

  return (
    <Dialogue
      showDialogue={addMemberPopup}
      setShowDialogue={setAddMembersPopup}
      DialogueBody={addMemberBody()}
      showCancelButton={true}
      cancelButtonText={"Cancel"}
      sumitButtonText={"Add member +"}
      onSubmit={() => {
        addMemberFcn();
      }}
      submitButtonLook="green"
      noSubmitButtonIcon={true}
      tableType={true}
    />
  );
};

AddMembers.propTypes = {
  addMemberPopup: PropTypes.bool.isRequired,
  setAddMembersPopup: PropTypes.func.isRequired,
  onInviteSent: PropTypes.func.isRequired,
};

export default AddMembers;
