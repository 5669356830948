import React, {
  useContext,
  useState,
  Children,
  cloneElement,
  useEffect,
  isValidElement,
} from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import firebase from "src/firebase";
import { Form, Formik } from "formik";
import ShowCompanyInfoDetails from "./Components/ShowCompanyInfoDetails";
import AppContext from "../../../contexts/AppContext";
import EditCompanyInfo from "./Components/EditCompanyInfo";
import theme from "../../../theme";
import AppButton from "../../global/elements/AppButton";
import { updateEnterPrise } from "../../../services/enterprise";
import { useSnackbar } from "notistack";
import { navigate } from "gatsby";
import {
  EUCountriesWithCommonVATrule,
  defaultCurrency,
  enterPriseUserRole,
} from "../../../lib/constants";
import { uploadProfileImageClient } from "../../../services/client";
import { randomString } from "../../../lib/helpers";

const styles = {
  companyBoxContainer: {
    paddingTop: "30px",
  },
  imageContainer: {
    position: "relative",
    display: "inline-block",
  },
  profileImage: {
    width: { xs: "75px", sm: "100px", lg: "140px" },
    height: { xs: "75px", sm: "100px", lg: "140px" },
    margin: "auto",
    marginLeft: { xs: 0, sm: "auto" },
    "& img": {
      padding: { xs: "3px", sm: "4px", lg: "7px" },
      borderRadius: "50%",
    },
  },
  locationSubText: {
    color: theme.palette.grey.warmGrey,
  },
  centerContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
  },
  overlayIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    padding: "4px",
    cursor: "pointer",
    fontSize: "50px !important",
  },

  whiteColor: {
    color: theme.palette.grey.white,
  },
  greyColor: {
    color: theme.palette.black.darkSeaBlack,
  },
};

const CompanyInfoMainPage = () => {
  const [editCompanyInfo, setEditCompanyInfo] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const appContext = useContext(AppContext);
  const enterprise = appContext?.enterPrise;

  const formInitialValues = {
    companyImageUrl: enterprise?.companyImageUrl || "",
    companyAddress: enterprise?.companyAddress || "",
    city: enterprise?.city || "",
    postalCode: enterprise?.postalCode || "",
    location: enterprise?.location || "",
    phone: enterprise?.phone || "",
    email: enterprise?.email || "",
    registrationNumber: enterprise?.registrationNumber || "",
    vatNumber: enterprise?.vatNumber || "",
    name: enterprise?.name || "",
    industry: enterprise?.industry || "",
    currency: enterprise?.currency || defaultCurrency,
  };

  const validationSchema = Yup.object().shape({
    companyAddress: Yup.string().required("Company  address is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Postal Code is required"),
    location: Yup.string().required("Country is required"),
    industry: Yup.string().required("Industry is required"),
    phone: Yup.string().nullable().required("Phone number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    registrationNumber: Yup.string().required(
      "Company registration number (CRN) is required"
    ),
    vatNumber: Yup.string().when("location", {
      is: (location) => EUCountriesWithCommonVATrule?.includes(location),
      then: Yup.string().required("Vat number is required"),
      otherwise: Yup.string(),
    }),
  });

  const getEnterpriseLocation = () => {
    let str = "";
    if (enterprise?.city) {
      str = enterprise.city + ", ";
    }

    if (enterprise?.location) {
      str = str + enterprise.location;
    }
    return str;
  };

  const onSubmit = async (values, setSubmitting) => {
    try {
      let resp = await updateEnterPrise(enterprise?.id, values);
      if (resp.status == "Success") {
        setSubmitting(false);
        enqueueSnackbar("Company profile is updated!!", { variant: "Success" });
        appContext?.setEnterPrise({ ...appContext.enterPrise, ...values });
        navigate("/app/client/enterprise#company");
        setEditCompanyInfo(false);
      } else {
        enqueueSnackbar("Error in updating company profile", {
          variant: "Error",
        });
      }
    } catch (e) {
      console.log("Error in updating!!", e);
      enqueueSnackbar("Error in processing", {
        variant: "Error",
      });
    }
  };

  const onProfileImageDropHandler = (acceptedFiles, setFieldValue) => {
    if (acceptedFiles) {
      let path = `enterpriseProfileImages/${enterprise?.id}/${randomString(7)}`;
      uploadProfileImageClient(firebase, acceptedFiles[0], enterprise?.id, path)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        })
        .then((downloadURL) => {
          setFieldValue("companyImageUrl", downloadURL);
          // setProfileImage(downloadURL);
        })
        .catch((err) => console.log(err));
    } else {
      console.log("incorrect image");
    }
  };

  const CompanyImageComp = ({ values, setFieldValue }) => {
    return (
      <>
        {editCompanyInfo ? (
          <Dropzone
            accept="image/jpeg, image/jpg, image/png"
            onDrop={(acceptedFiles) =>
              onProfileImageDropHandler(acceptedFiles, setFieldValue)
            }
            maxSize={5000000}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} id="companyImageUrl" />

                <Box sx={styles.imageContainer}>
                  <Avatar
                    src={values?.companyImageUrl}
                    sx={styles.profileImage}
                  />
                  <CameraAltOutlinedIcon
                    sx={
                      values?.companyImageUrl
                        ? {
                            ...styles.overlayIcon,
                            ...styles.whiteColor,
                          }
                        : {
                            ...styles.overlayIcon,
                            ...styles.greyColor,
                          }
                    }
                    fontSize="large"
                  />
                </Box>
              </div>
            )}
          </Dropzone>
        ) : (
          <Avatar src={values?.companyImageUrl} sx={styles.profileImage} />
        )}
      </>
    );
  };

  CompanyImageComp.propTypes = {
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  };

  const CompanyProfileContainer = ({ children }) => {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            return new Promise(() => {
              onSubmit(values, setSubmitting);
            });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => {
            return (
              <Form id="enterpriseForm">
                <Grid container spacing={2} mb={3}>
                  <Grid item sx={styles.imageContainer} xs={12} md={2}>
                    <CompanyImageComp
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sx={styles.centerContainer}>
                    <Typography variant="h4">{enterprise?.name}</Typography>
                    <Typography variant="subtitle1" sx={styles.locationSubText}>
                      {getEnterpriseLocation()}
                    </Typography>
                  </Grid>
                  {appContext?.userData?.roleType ==
                    enterPriseUserRole.admin && (
                    <Grid item xs={12} md={2} sx={styles.centerContainer}>
                      {!editCompanyInfo ? (
                        <AppButton
                          look={"inverted"}
                          label={"Edit"}
                          noIcon
                          small
                          onClick={() => setEditCompanyInfo(true)}
                        />
                      ) : (
                        <AppButton
                          type="submit"
                          form="enterpriseForm"
                          look={"black"}
                          label={"Save"}
                          noIcon
                          small
                          submittingState={isSubmitting}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
                {/* Pass 'values' to children */}
                {Children.map(children, (child) => {
                  if (isValidElement(child)) {
                    return cloneElement(child, { values, setFieldValue });
                  }
                  return child;
                })}
              </Form>
            );
          }}
        </Formik>
      </>
    );
  };

  CompanyProfileContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <Box sx={styles.companyBoxContainer}>
      <CompanyProfileContainer>
        {!editCompanyInfo ? (
          <ShowCompanyInfoDetails enterprise={enterprise} />
        ) : (
          <EditCompanyInfo />
        )}
      </CompanyProfileContainer>
    </Box>
  );
};

export default CompanyInfoMainPage;
