import React, { useContext } from "react";
import MissionContext from "./MissionContext";
import AppButton from "../../../../global/elements/AppButton";
import { Box, Grid, Typography } from "@mui/material";
import {
  declineMissionReason,
  interestInviteStatus,
  notificationType,
  rejectFeedBackString,
} from "../../../../../lib/constants";
import { boxShadowStyle } from "../../../../../lib/styleConstants";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  pilotDeclinedFeedback,
  pilotRejectMission,
} from "../../../../../services/missions";
import { findAndUpdateInterest } from "../../../../../services/interest-invite";
import { createNotification } from "../../../../../services/notification";
import theme from "../../../../../theme";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import TextFieldInput from "../../../../global/FormInputComponents/TextFieldInput";
import AutoCompleteInput from "../../../../global/FormInputComponents/AutoCompleteInput";

const styles = {
  rejectionBox: {
    padding: "20px",
    marginTop: "20px",
    width: "100%",
  },
  input_cls: {
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      color: theme.palette.grey.warmGrey,
    },
    "& .MuiChip-root": {
      color: theme.palette.black.darkSeaBlack,
      background: theme.palette.grey.lightWarmGrey,
    },
  },
  declineBoxCls: {
    marginTop: "15px",
    display: "flex",
  },
};

const DeclineByPilot = () => {
  const data = useContext(MissionContext);
  const { enqueueSnackbar } = useSnackbar();

  const declinedformInitialValues = {
    declinedReason: [],
    otherDeclinedReason: "",
  };

  const validationSchema = Yup.object().shape({
    declinedReason: Yup.array()
      .min(1, "Decline reason required")
      .required("required"),
  });

  const handleRejectInquiryV2 = (values, { setSubmitting }) => {
    let declinedObj = {
      pilotId: data?.currentUser?.id,
      missionID: data?.mission?.id,
      declinedFeedback: {
        ...values,
        pilotId: data?.currentUser?.id,
      },
    };
    setSubmitting(true);
    pilotRejectMission(firebase, data?.currentUser?.id, data?.mission)
      .then(() => {
        pilotDeclinedFeedback(firebase, declinedObj)
          .then(() => {
            findAndUpdateInterest(
              firebase,
              data?.mission?.id,
              data?.currentUser?.id,
              {
                status: interestInviteStatus.pilotRejected,
              }
            );
            createNotification(
              firebase,
              notificationType.adminNotification,
              `An operator has declined a mission invite`,
              `An operator has declined a mission invite`,
              `/app/missions/${data?.mission?.id}`
            );
            setSubmitting(false);
            data?.setDeclined(false);
            data?.refreshMission();
            enqueueSnackbar("You have rejected the mission");
          })
          .catch((err) => {
            setSubmitting(false);
            data?.setDeclined(false);
            enqueueSnackbar("Error in submitting feedback ", err);
          });
      })
      .catch((err) => {
        setSubmitting(false);
        data?.setDeclined(false);
        enqueueSnackbar("Error in rejecting ", err);
      });
  };

  const cancelDecline = () => {
    data?.setDeclined(false);
  };

  return (
    <>
      <Box sx={{ ...styles.rejectionBox, ...boxShadowStyle }}>
        <Typography variant="body1">{rejectFeedBackString}</Typography>
        <Formik
          initialValues={declinedformInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleRejectInquiryV2}
        >
          {({ values, isSubmitting, setFieldValue }) => {
            return (
              <Form id="missionDeclineForm">
                <Grid container spacing={2}>
                  <Grid item sm={4} xs={12}>
                    <AutoCompleteInput
                      multiple
                      options={declineMissionReason}
                      setFieldValue={setFieldValue}
                      values={values}
                      name="declinedReason"
                      label="Reasons for declining"
                      required
                    />
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <TextFieldInput
                      name="otherDeclinedReason"
                      label="Other comments or more information"
                      type="text"
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Box sx={styles.declineBoxCls}>
                      <AppButton
                        label="Cancel"
                        look="inverted"
                        noIcon
                        onClick={cancelDecline}
                      />
                      <AppButton
                        label="Decline"
                        look="negative"
                        type="submit"
                        form="missionDeclineForm"
                        submittingState={isSubmitting}
                        addtionalStyle={{
                          marginLeft: "20px",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default DeclineByPilot;
