import React, { useState } from "react";
import { Box } from "@mui/material";
import { useStateIfMounted } from "use-state-if-mounted";
import { getCurrentDateTimeMillis } from "../../../lib/helpers";
import { getUser } from "../../../services/auth";
import firebase from "src/firebase";
import { uploadClientInvoice } from "../../../services/missions";
import {
  uploadAFile,
  uploadClientProjectInvoice,
} from "../../../services/project";
import { useSnackbar } from "notistack";
import FileDropzone from "../../global/FormInputComponents/FileDropzone";
import PropTypes from "prop-types";

const UploadInvoice = ({
  mission,
  invoiceType,
  isDisabled,
  clientInoviceUrl,
  refreshMission,
  checkIfSingle,
  isProject,
}) => {
  const [docLoading, setDocLoading] = useStateIfMounted(false);
  const [percent, setPercent] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleInvoiceUpload = async (files) => {
    setDocLoading(true);
    let timeStamp = getCurrentDateTimeMillis();
    let userId = getUser("user")?.userID || "";
    const file = files[0];
    if (!file) return;
    let path = `ClientInvoices/${userId}/${timeStamp}/${file.name}`;
    const fileUploadStatus = await uploadAFile(
      firebase,
      file,
      path,
      setPercent
    );
    if (fileUploadStatus.status === "Success") {
      console.log("file uploaded");
      setDocLoading(false);
      if (isProject) {
        uploadClientProjectInvoice(
          firebase,
          mission,
          fileUploadStatus?.fileURL,
          invoiceType
        ).then(() => {
          // refreshMission();
        });
      } else {
        uploadClientInvoice(
          firebase,
          mission,
          fileUploadStatus?.fileURL,
          invoiceType
        ).then(() => {
          refreshMission();
        });
      }
    } else {
      enqueueSnackbar(`${fileUploadStatus.message}.`, {
        variant: "error",
      });
    }
  };
  return (
    <Box>
      <FileDropzone
        onDrop={handleInvoiceUpload}
        acceptedFileTypes={["image/jpeg", ".pdf", "application/pdf"]}
        isDisabled={isDisabled}
        name={"invoiceURL"}
        label={
          clientInoviceUrl?.length > 0
            ? checkIfSingle()
              ? "Replace invoice"
              : "Replace"
            : checkIfSingle()
            ? "Upload invoice"
            : "Upload"
        }
        look={isDisabled ? "invertedDisabled" : "inverted"}
        addtionalStyle={{
          width: checkIfSingle() ? { xs: "100%", sm: "max-content" } : "100%",
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        fileUrl={clientInoviceUrl}
        docLoading={docLoading}
      />
    </Box>
  );
};

UploadInvoice.propTypes = {
  mission: PropTypes.object,
  invoiceType: PropTypes.string,
  isDisabled: PropTypes.bool,
  clientInoviceUrl: PropTypes.string,
  setClientInoviceUrl: PropTypes.func,
  refreshMission: PropTypes.func,
  checkIfSingle: PropTypes.bool.isRequired,
  isProject: PropTypes.bool,
};
UploadInvoice.defaultProps = {
  mission: undefined,
  invoiceType: undefined,
  isDisabled: false,
  clientInoviceUrl: undefined,
  setClientInoviceUrl: undefined,
  refreshMission: undefined,
  isProject: false,
};
export default UploadInvoice;
