import React from "react";
import Dropzone from "react-dropzone";
import { Box, CircularProgress } from "@mui/material";
import AppButton from "../elements/AppButton";
import UploadIcon from "@mui/icons-material/Upload";
import PropTypes from "prop-types";

const FileDropzone = ({
  onDrop,
  acceptedFileTypes,
  isDisabled,
  name,
  label,
  //   endIcon,
  look,
  addtionalStyle,
  fileUrl,
  docLoading,
}) => {
  return (
    <Dropzone
      accept={acceptedFileTypes}
      onDrop={(acceptedFiles) => {
        onDrop(acceptedFiles);
      }}
      name={name}
      noClick={isDisabled}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} name={name} />
            <Box>
              {!docLoading ? (
                <AppButton
                  look={look}
                  isDisabled={isDisabled}
                  addtionalStyle={addtionalStyle}
                  label={label}
                  customIcon={<UploadIcon />}
                />
              ) : (
                <CircularProgress />
              )}
            </Box>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

FileDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.array,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  look: PropTypes.string,
  addtionalStyle: PropTypes.object,
  fileUrl: PropTypes.string,
  docLoading: PropTypes.bool,
};

FileDropzone.defaultProps = {
  client: {},
  acceptedFileTypes: [],
  isDisabled: false,
  name: undefined,
  label: undefined,
  look: "primary",
  addtionalStyle: {},
  fileUrl: undefined,
  docLoading: false,
};

export default FileDropzone;
