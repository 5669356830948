import React from "react";
import Layout from "../../../../Layout";
import OrderButton from "../../Client/Orders/OrderButton";
import { Grid, Box } from "@mui/material";
import OperatorListedData from "./OperatorListedData";
import StatusAlertContainer from "../../../global/elements/StatusAlertContainer";
const styles = {
  addCreateContainer: {
    paddingTop: "1em",
  },
  tableBox: {},
  button: {
    borderRadius: "22px",
  },
  buttonContainer: {
    display: "flex",
    columnGap: "1em",
  },
};
const OperatorListIndex = () => {
  return (
    <Layout pageTitle={"my listed data"} hasSideMenu loading={false}>
      <Grid sx={styles.wrapper}>
        <Grid sx={styles.container}>
          <StatusAlertContainer
            showClose
            coloredBackground
            severity="warning"
            heading="for data submitted to the crowddroning platform after 1/1/2023, the drone operator who uploaded this dataset will only be paid when a client purchases this data."
          />
          <Grid sx={styles.addCreateContainer}>
            <Box>
              <OrderButton
                name="List my data"
                link="/app/missions/mission-globhe"
                look="purple"
              />
            </Box>
          </Grid>
          <Grid sx={styles.tableBox}>
            <Grid sx={styles.tableSearchContainer}></Grid>
            <Grid sx={styles.tableContainer}>
              <OperatorListedData />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default OperatorListIndex;
