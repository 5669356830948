import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../theme";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { userRoles } from "../../lib/constants";
import { getUser } from "../../services/auth";

const styles = {
  leftQuotaStyles: {
    color: theme.palette.grey.warmGrey,
  },
  atBottom: {
    alignSelf: "flex-end",
  },

  circularProgressStyle: {
    width: { xs: "100px", sm: "100px" },
    position: "relative",
  },

  leftQuotaStyle: { paddingLeft: "10px" },
};

const CircularProgressBar = ({
  leftQuota,
  reversed,
  smallerText,
  additionalStyle,
  strokeWidth,
  percentTextNeed,
}) => {
  const currentUser = getUser("user");
  const isAdmin = currentUser?.role === userRoles.admin;
  const isPilot = currentUser?.role === userRoles.pilot;

  return (
    <>
      <Box
        sx={{
          ...styles.circularProgressStyle,
          ...styles.atBottom,
          ...additionalStyle,
        }}
      >
        <CircularProgressbar
          value={leftQuota}
          strokeWidth={strokeWidth ? strokeWidth : 15}
          styles={buildStyles({
            strokeLinecap: "butt",
            pathTransitionDuration: 0.5,
            pathColor: `${
              reversed
                ? isAdmin
                  ? theme.palette.grey.warmGrey
                  : isPilot
                  ? theme.palette.secondary.paleLavender
                  : theme.palette.black.darkSeaBlack
                : isAdmin
                ? theme.palette.adminBlue.main
                : isPilot
                ? theme.palette.secondary.main
                : theme.palette.primary.main
            }`,
            trailColor: `${
              reversed
                ? isAdmin
                  ? theme.palette.adminBlue.main
                  : isPilot
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main
                : isAdmin
                ? theme.palette.grey.warmGrey
                : isPilot
                ? theme.palette.secondary.paleLavender
                : theme.palette.black.darkSeaBlack
            }`,

            // width: "71px",
          })}
        />
      </Box>
      {percentTextNeed && (
        <Box sx={styles.atBottom}>
          <Typography
            variant={smallerText ? "h2" : "h1"}
            sx={styles.leftQuotaStyle}
          >
            {leftQuota}%
          </Typography>
        </Box>
      )}
    </>
  );
};

CircularProgressBar.propTypes = {
  leftQuota: PropTypes.number,
  reversed: PropTypes.bool,
  smallerText: PropTypes.bool,
  additionalStyle: PropTypes.object,
  strokeWidth: PropTypes.number,
  percentTextNeed: PropTypes.bool,
};

CircularProgressBar.defaultProps = {
  leftQuota: undefined,
  reversed: false,
  smallerText: false,
  additionalStyle: {},
  strokeWidth: 0,
  percentTextNeed: true,
};

export default CircularProgressBar;
