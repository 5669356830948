import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  childrenComponent: {
    paddingTop: { xs: "20px", sm: "30px" },
    paddingBottom: { xs: "25px", sm: "50px" },
  },
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={styles.childrenComponent}>{children}</Box>}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
