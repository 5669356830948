import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getQuotesList } from "../services/missions";

const useGetQuotesList = (missionId, role, setTotalQuotesCount) => {
  const [allQuotes, setAllQuotes] = useState(null);
  const [quotesTotal, setQuotesTotal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPage, setlastPage] = useState(null);
  // const [loadData, setLoadData] = useState(false);
  const [lastLoadedQuotes, setLastLoadedQuotes] = useState(null);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);
  const [filter, setFilter] = useState({});
  const [triggerReload, setTriggerReload] = useState(false);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);

    if (currentPage <= lastLoadedPage && triggerReload === false) {
      let newDataForQuotes = quotesTotal.slice(
        (currentPage - 1) * limit,
        currentPage * limit
      );
      setAllQuotes(newDataForQuotes);
      setTotalQuotesCount(newDataForQuotes?.length || 0);
      setLoading(false);
    } else {
      getQuotesList(firebase, missionId, lastLoadedQuotes, limit, filter, role)
        .then((data) => {
          if (data.last) {
            setlastPage(currentPage);
            setIsLastPage(true);
          } else {
            setlastPage(null);
            setIsLastPage(false);
          }
          setTotalQuotesCount(data?.quotes?.length || 0);
          setAllQuotes(data?.quotes);
          setLastLoadedPage(currentPage);
          setLastLoadedQuotes(data.lastVisible);
          setQuotesTotal([...quotesTotal, ...data.quotes]);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [triggerReload, filter]);

  return [
    allQuotes,
    loading,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    lastPage,
    setQuotesTotal,
    setLastLoadedPage,
    setLastLoadedQuotes,
    triggerReload,
    setFilter,
    setTriggerReload,
    setLoading,
  ];
};

export default useGetQuotesList;
