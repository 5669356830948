import React from "react";
import theme from "../../../theme";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Link } from "gatsby";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  carausal_main: {
    "& .react-multi-carousel-item": {
      paddingBottom: "40px",
    },
  },
  carousel: {
    padding: theme.spacing(0, 0, 4, 0),
  },
  carousal_single: {
    margin: "5px 10px",
    padding: "10px 20px",
    // minHeight: { xs: "35vh", sm: "40vh", md: "33vh", lg: "33vh" },
    position: "relative",
    height: "100%",
    backgroundColor: `${theme.palette.black.darkSeaBlack}0A`,
  },
  carousal_heading: {
    margin: "10px 0",
  },
  container: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  carousal_content: {
    wordBreak: "break-all",
  },
  carousal_button: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    fontWeight: 450,
  },
  carausal_container: {},
  readBtn: {
    border: "1px solid",
    borderRadius: "7px",
    color: theme.palette.grey.white,
    background: theme.palette.primary.main,
    fontSize: "14px",
    padding: "5px 20px",
    textDecoration: "none",
    position: "absolute",
    bottom: 10,
  },
  readBtnBox: {
    display: { xs: "inline", md: "none" },
    marginBottom: { xs: "0px" },
  },
  linkBtn: {
    display: "flex",
    marginTop: "auto",
    justifyContent: "end",
  },
  carousal_img: {
    width: "100%",
    height: "120px",
    objectFit: "cover",
  },
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1150 },
    items: 3,
    paritialVisibilityGutter: 5,
  },
  tablet: {
    breakpoint: { max: 1149, min: 850 },
    items: 2,
    paritialVisibilityGutter: 5,
  },
  mediumTab: {
    breakpoint: { max: 849, min: 600 },
    items: 1,
    paritialVisibilityGutter: 20,
  },
  bigMobile: {
    breakpoint: { max: 599, min: 500 },
    items: 2,
    paritialVisibilityGutter: 5,
  },
  mobile: {
    breakpoint: { max: 499, min: 0 },
    items: 1,
    paritialVisibilityGutter: 20,
  },
};

const TextAndMediaCard = ({ data, type }) => {
  const Text = (props) => {
    return (
      <Box sx={{ ...styles.carousal_single }}>
        <Box sx={styles.container}>
          <Box sx={styles.carousal_heading}>
            <Typography variant="subtitle1Med">
              {props?.news?.title.slice(0, 60)}
              {props?.news?.title?.length > 60 && "..."}
            </Typography>
          </Box>

          <Typography variant="body1" sx={styles.carousal_content}>
            {props?.news?.description?.slice(0, 150) + "..."}
          </Typography>

          <Box sx={styles.linkBtn}>
            <Link
              to={`/app/admin/news/${props?.news?.id}`}
              style={styles.carousal_button}
            >
              Read More
            </Link>
          </Box>
        </Box>
      </Box>
    );
  };
  const WithMedia = (props) => {
    return (
      <Link
        to={`/app/admin/inspirations/${props?.news?.id}`}
        style={{ textDecoration: "none" }}
      >
        <Box sx={{ ...styles.carousal_single, padding: 0 }}>
          <img
            src={props?.news?.blogImage}
            style={{ height: "20vh", ...styles.carousal_img }}
          />
          <br />
          <Typography
            variant="subtitle1Med"
            sx={{ ...styles.carousal_content, padding: "10px 20px" }}
          >
            {props?.news?.title?.slice(0, 100)}
            {props?.news?.title?.length > 100 && "..."}
          </Typography>
        </Box>
      </Link>
    );
  };

  return (
    <>
      <Box sx={styles.carausal_main}>
        {data && (
          <Carousel
            ssr
            partialVisbile
            deviceType="desktop"
            responsive={responsive}
            showDots={true}
            autoPlay={false}
            autoPlaySpeed={5000}
          >
            {data?.map((item, i) =>
              type === "text" ? (
                <Text key={i} news={item} />
              ) : (
                <WithMedia key={i} news={item} />
              )
            )}
          </Carousel>
        )}
      </Box>
    </>
  );
};

TextAndMediaCard.propTypes = {
  data: PropTypes.func,
  news: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
};

TextAndMediaCard.defaultProps = {
  data: undefined,
  news: PropTypes.shape({
    title: undefined,
    id: undefined,
    description: undefined,
  }),
};

export default TextAndMediaCard;
