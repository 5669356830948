import { Box, Typography } from "@mui/material";
import React from "react";
import FilterComponent from "../../../global/elements/FilterComponent";
import { navigate } from "gatsby";
import GlobalTable from "../../admin/GlobalTable";
import {
  FilterFieldsForListingAllClients,
  columnForListingAllClients,
  termAcceptStatus,
} from "../../../../lib/constants";
import { useGetClientList } from "../../../../hooks/useGetClientList";
import theme from "../../../../theme";

const styles = {
  redText: {
    color: theme.palette.status.errorDark,
    border: `1px solid ${theme.palette.status.errorDark}`,
    width: "fit-content",
    borderRadius: "2px",
    padding: "2px",
  },
  greenText: {
    color: theme.palette.status.successDark,
    border: `1px solid ${theme.palette.status.successDark}`,
    width: "fit-content",
    borderRadius: "2px",
    padding: "2px 4px",
  },
};

const AllClientsTable = () => {
  const {
    clients,
    setAllClients,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedClient,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  } = useGetClientList();

  // const goToPageFcn = (value) => {
  //   navigate(`/app/admin/manage-client/${value.id}`);
  // };
  const clearDataAndTriggerReload = () => {
    setLastLoadedClient(null);
    setCurrentPage(1);
    setAllClients([]);
    setLastPageNumber(null);
    setDataLoadedTill(null);
    setIslastPage(false);
    setTriggerReload(!triggerReload);
  };

  const limitHandler = (value) => {
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const paginationBtnClick = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (!(currentPage === 1)) {
        setCurrentPage(currentPage - 1);
        setIslastPage(null);
        setTriggerReload(!triggerReload);
      }
    }
  };

  const valueConvertHandler = (type, value) => {
    switch (type) {
      case "statusClient":
        return (
          <Typography
            sx={value?.termsOfUse ? styles.greenText : styles.redText}
            variant="body2"
          >
            {value?.termsOfUse
              ? termAcceptStatus.registered
              : termAcceptStatus.locked}
          </Typography>
        );
    }
  };

  return (
    <>
      <FilterComponent
        filterFields={FilterFieldsForListingAllClients}
        multiple={true}
        filterFormName={"mainFilterFields"}
        filter={filter}
        setFilter={setFilter}
        clearDataAndTriggerReload={clearDataAndTriggerReload}
        look={"standard"}
      />
      <Box mt={2}>
        <GlobalTable
          columns={columnForListingAllClients}
          allData={clients}
          // goToPageFcn={goToPageFcn}
          loading={loading}
          limitHandler={limitHandler}
          limit={limit}
          paginationBtnClick={paginationBtnClick}
          currentPage={currentPage}
          isLastPage={isLastPage}
          lastPage={lastPageNumber}
          valueConvertHandler={valueConvertHandler}
        />
      </Box>
    </>
  );
};

export default AllClientsTable;
