import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { createFeedback } from "../../services/feedback";
import useGetPilot from "src/hooks/useGetPilot";
import { getUser } from "../../services/auth";
import GlobheLogo from "src/assets/images/feedback-success.png";
import UniversalLoader from "../global/elements/UniversalLoader";
import theme from "../../theme";
import { boxShadowStyle } from "../../lib/styleConstants";
import AppButton from "../global/elements/AppButton";

const styles = {
  dialogueContent: {
    padding: "0 20px 0 20px",
  },
  requiredRating: {
    color: theme.palette.status.errorDark,
    padding: "0 0 5px 0",
    fontSize: "10px",
  },
  textArea: {
    width: "100%",
    padding: "1em",
  },
  radioButton: {
    "&, &.Mui-checked": {
      color: theme.palette.secondary.main,
    },
    "& .MuiSvgIcon-root": {
      height: "0.8em",
      width: "0.8em",
    },
    fontSize: "12px",
  },
  radioGroupBox: {
    padding: "0 0 15px 0",
    fontWeight: "500",
    color: theme.palette.grey.warmGrey,
  },
  radioGroup: {
    justifyContent: "space-between",
    "& .MuiFormControlLabel-label": {
      fontSize: "13px",
      fontWeight: "500",
      color: theme.palette.grey.warmGrey,
    },
  },
  contentBox: {
    padding: "4em",
  },
  successHead1: {
    textAlign: "center",
  },
  successHead2: {
    fontSize: "12px",
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  imageBox: {
    textAlign: "center",
    padding: "20px 0 20px 0",
  },
  logo: {
    width: "100px",
    borderRadius: "0px",
  },
  closeIcons: {
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.grey.warmGrey,
  },
  colored: {
    paddingTop: "15px",
    color: theme.palette.secondary.main,
  },
  ratingBox: {
    padding: "30px 0 15px 0",
  },
  ratingColor: {
    color: theme.palette.grey.warmGrey,
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} sx={styles.closeIcons}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CustomizedDialogs = (props) => {
  const [rating, setRating] = useState(3);
  const [typeValue, settypeValue] = useState("Comments");
  const [description, setDescription] = useState("");
  const [pilot, loading, authentication] = useGetPilot(getUser("user").userID);
  const [success, setSuccess] = useState(false);
  const [showLoader, setshowLoader] = useState(false);

  const handleChange = (event) => {
    settypeValue(event.target.value);
  };
  const handleSubmit = async () => {
    setshowLoader(true);
    const feed = {
      rating: rating,
      FeedbackType: typeValue,
      description: description,
      email: pilot?.email,
      name: pilot?.name,
      company: pilot?.company ? pilot?.company : null,
      phone: pilot?.phone ? pilot?.phone : null,
      id: pilot?.id,
      feedbackBy: "pilot",
    };
    const data = await createFeedback(feed);
    if (data) {
      setshowLoader(false);
      setSuccess(true);
    }
  };
  const Close = () => {
    props.handleClose();
    setTimeout(() => {
      setSuccess(false);
    }, 300);
    settypeValue("Comments");
    setDescription("");
    setRating(3);
  };
  return (
    <Box>
      <BootstrapDialog
        onClose={Close}
        fullWidth
        maxWidth="sm"
        maxHeight="80vh"
        aria-labelledby="customized-dialog-title"
        open={props.popupOpen}
        PaperProps={boxShadowStyle}
      >
        {!success ? (
          <Box>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              sx={{
                color: theme.palette.grey.warmGrey,
              }}
              onClose={Close}
            >
              <Typography variant="h3">Feedback</Typography>
            </BootstrapDialogTitle>
            <Box>
              <Box sx={styles.dialogueContent}>
                <Typography variant="body1">
                  {pilot?.name}, do you have ideas for features you want to see,
                  things you want to improve, or have simply issues while using
                  Crowddroning?
                </Typography>
                <Typography variant="h5" sx={styles.colored}>
                  We would love to hear your feedback{" "}
                </Typography>
                <Box sx={styles.ratingBox}>
                  {showLoader && <UniversalLoader />}
                  <Typography variant="subtitle1Med" sx={styles.ratingColor}>
                    How would you rate your Globhe Experience?
                  </Typography>
                  <Box>
                    <Rating
                      name="simple-controlled"
                      value={rating}
                      onChange={(event, newValue) => {
                        if (newValue === null) {
                          setRating(0);
                        } else {
                          setRating(newValue);
                        }
                      }}
                      precision={1}
                    />
                    <Box style={styles.requiredRating}>
                      {rating ? "" : "*Rating is required"}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={styles.radioGroupBox}>
                    <FormControl sx={{ width: "100%" }}>
                      <Typography
                        variant="subtitle1Med"
                        sx={styles.ratingColor}
                      >
                        Type of feedback...
                      </Typography>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        row
                        name="row-radio-buttons-group"
                        value={typeValue}
                        onChange={handleChange}
                        sx={styles.radioGroup}
                      >
                        <FormControlLabel
                          value="Comments"
                          control={<Radio sx={styles.radioButton} />}
                          label="Comments"
                        />
                        <FormControlLabel
                          value="Suggestions"
                          control={<Radio sx={styles.radioButton} />}
                          label="Suggestions"
                        />
                        <FormControlLabel
                          value="Question"
                          control={<Radio sx={styles.radioButton} />}
                          label="Questions"
                        />
                        <FormControlLabel
                          value="Other"
                          control={<Radio sx={styles.radioButton} />}
                          label="Other Concerns"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
                <Typography variant="subtitle1Med" sx={styles.ratingColor}>
                  Describe your feedback
                  <span style={{ color: theme.palette.status.errorDark }}>
                    *
                  </span>
                </Typography>
                <Box>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    minRows={4}
                    style={styles.textArea}
                    value={description}
                    onChange={(evt) => setDescription(evt.target.value)}
                  />
                </Box>
              </Box>
            </Box>
            <DialogActions
              sx={{ justifyContent: "space-between", padding: "20px" }}
            >
              <AppButton
                onClick={Close}
                marginRight
                look="inverted"
                label="Cancel"
                noIcon
              />
              <AppButton
                onClick={handleSubmit}
                look="purple"
                label="Submit"
                isDisabled={description && rating ? false : true}
              />
            </DialogActions>
          </Box>
        ) : (
          <Box sx={styles.contentBox}>
            <Typography variant="h3" sx={styles.successHead1}>
              Thank You!
            </Typography>
            <Typography variant="h5" sx={styles.successHead1}>
              Your feedback was successfully submitted.
            </Typography>
            <Box sx={styles.imageBox}>
              <Box
                src={GlobheLogo}
                sx={styles.logo}
                component="img"
                alt="Globhe Logo"
              />
            </Box>
          </Box>
        )}
      </BootstrapDialog>
    </Box>
  );
};

export default CustomizedDialogs;
