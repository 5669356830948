import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../../theme";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import FormErrors from "../../global/forms/FormErrors";
import { DateTime } from "luxon";
import { navigate } from "gatsby";
import AppButton from "../../global/elements/AppButton";
import PropTypes from "prop-types";
import { getCurrentDateTime } from "../../../lib/helpers";
import { useSnackbar } from "notistack";
import { userRoles } from "../../../lib/constants";
import { boxShadowStyle } from "../../../lib/styleConstants";

const styles = {
  form_heading: {
    marginTop: "40px",
  },
  form_container: {
    marginTop: "10px",
    width: { xs: "100%", lg: "65%" },
  },
  check_box: {
    fontSize: { xs: "12px", md: "14px" },
    color: theme.palette.grey.warmGrey,
  },
  button_container: {
    width: { md: "60%", lg: "35%" },
  },
  signed_container: {
    width: { md: "60%", lg: "35%" },
    //background: theme.palette.black.paleSilver,
    maxHeight: "5rem",
    // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    marginTop: { xs: "2rem", lg: "5rem" },
    display: "flex",
  },
  signedBackground: {
    color: theme.palette.grey.white,
    textAlign: "center",
    marginBottom: "0.5rem",
  },
  signedBackgroundAdmin: {
    background: theme.palette.adminBlue.main,
  },
  signedBackgroundPilot: {
    background: theme.palette.secondary.lightPurple,
  },
  signedColor: {
    color: theme.palette.grey.white,
  },
  submit_button: {
    marginTop: "30px",
  },
  back_button: {
    marginTop: "20px",
  },
  status_container: {
    width: "50%",
    padding: { xs: "5px", sm: "10px" },
    paddingTop: { xs: "1rem" },
    textAlign: "center",
  },
  button_box: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", md: "row" },
  },
};

const FormComponent = ({
  submitHandler,
  contract,
  role,
  signed,
  setSigned,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  // const [downloadUrl, setDownloadUrl] = contract.signedContractPdf
  //   ? useState(contract.signedContractPdf)
  //   : useState(null);
  const isAdmin = role === userRoles.admin;

  const formStyle = {
    color: theme.palette.black.coolGrey,
    transition: "all 0.3s",
    fontSize: { xs: "14px", md: "16px" },
    " &:hover": {
      cursor: "pointer",
    },
    "& >input[type=checkbox]": {
      cursor: "pointer",
      marginRight: "10px",
      WebkitAppearance: "none",
      background: theme.palette.grey.white,
      border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      borderRadius: "3px",
      fontSize: { xs: "12px", md: "14px" },
      boxShadow: "none",
      height: "15px",
      width: "15px",
      float: "left",
      position: "relative",
      transition: "all 0.3s",
      opacity: 1,
      "&:active": {},
      "&:checked:active": {},
      "&:after": {
        content: '""',
        background: isAdmin
          ? theme.palette.adminBlue.main
          : theme.palette.secondary.lightPurple,
        width: "9px",
        height: "9px",
        borderRadius: "1px",
        position: "absolute",
        top: "2px",
        left: "2px",
        transition: "all 0.3s",
        opacity: 0,
      },

      "&:checked": {
        border: `1px solid ${
          isAdmin
            ? theme.palette.adminBlue.main
            : theme.palette.secondary.lightPurple
        }`,
        position: "relative",
        transition: " all 0.3s",
        opacity: 1,

        "&:after": {
          opacity: 1,
        },
      },
    },
    "& > input[type=radio]": {
      marginRight: "10px",
      WebkitAppearance: "none",
      background: theme.palette.grey.white,
      border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      borderRadius: "3px",
      boxShadow: "none",
      height: "15px",
      width: "15px",
      float: "left",
      position: "relative",
      transition: "all 0.3s",
      opacity: 1,

      "&:active": {},
      "&:checked:active": {},

      "&:after": {
        content: '""',
        background: isAdmin
          ? theme.palette.adminBlue.main
          : theme.palette.secondary.lightPurple,
        width: "9px",
        height: "9px",
        borderRadius: "1px",
        position: "absolute",
        top: "2px",
        left: "2px",
        transition: "all 0.3s",
        opacity: 0,
      },

      "&:checked": {
        border: `1px solid ${
          isAdmin
            ? theme.palette.adminBlue.main
            : theme.palette.secondary.lightPurple
        }`,
        position: "relative",
        transition: " all 0.3s",
        opacity: 1,

        "&:after": {
          opacity: 1,
        },
      },
    },
  };
  const validationSchema = Yup.object().shape({
    rightEquipmentCondition: Yup.bool()
      .required("Use the right equipment must be accepted")
      .oneOf([true], "Use the right equipment must be accepted"),
    deliverBeforeDueDate: Yup.bool()
      .required("Deliver the data before the due date must be accepted")
      .oneOf([true], "Deliver the data before the due dat must be accepted"),
    followWorkInstruction: Yup.bool()
      .required(
        "Follow the work instructions as specified in this contract must be accepted"
      )
      .oneOf(
        [true],
        "Follow the work instructions as specified in this contract must be accepted"
      ),
    deliverTheDeliverables: Yup.bool()
      .required(
        "Deliver the deliverables as specified in this contract must be accepted"
      )
      .oneOf(
        [true],
        "Deliver the deliverables as specified in this contract must be accepted"
      ),
    // onlyUploadOnCrowddroning: Yup.bool()
    //   .oneOf(
    //     [true],
    //     "Upload all data to the Crowddroning platform and nowhere else must be accepted"
    //   ),
    haveDroneCertificate: Yup.bool()
      .required("Have valid permits must be accepted")
      .oneOf([true], "Have the right drone certificate must be accepted"),
    droneLicenseValid: Yup.bool()
      .required("Valid drone license must be accepted")
      .oneOf([true], "Valid drone license must be accepted"),
    insuranceValidCurrent: Yup.bool()
      .required("Valid and current insurance must be accepted")
      .oneOf([true], "Valid and current insurance must be accepted"),
    registrationValidCurrent: Yup.bool()
      .required("Valid and current business registration must be accepted")
      .oneOf(
        [true],
        "Valid and current business registration must be accepted"
      ),
    "readT&C": Yup.bool()
      .required("Read the terms and conditions must be accepted")
      .oneOf([true], "Read the terms and conditions must be accepted"),
    iUnderstandAll: Yup.bool()
      .required(
        "Won’t be possible to change after signing the contract must be accepted"
      )
      .oneOf(
        [true],
        "Won’t be possible to change after signing the contract must be accepted"
      ),
  });

  const initialValues = {
    rightEquipmentCondition: contract?.rightEquipmentCondition || false,
    deliverBeforeDueDate: contract?.deliverBeforeDueDate || false,
    followWorkInstruction: contract?.followWorkInstruction || false,
    deliverTheDeliverables: contract?.deliverTheDeliverables || false,
    //onlyUploadOnCrowddroning: contract?.onlyUploadOnCrowddroning || false,
    haveDroneCertificate: contract?.haveDroneCertificate || false,
    droneLicenseValid: contract?.droneLicenseValid || false,
    insuranceValidCurrent: contract?.insuranceValidCurrent || false,
    registrationValidCurrent: contract?.registrationValidCurrent || false,
    "readT&C": (contract && contract["readT&C"]) || false,
    iUnderstandAll: contract?.iUnderstandAll || false,
  };

  const navigateBack = () => {
    if (isAdmin) {
      navigate("/app/admin/dashboard/contracts");
    } else {
      navigate(-1);
    }
  };

  const SubmitData = async (values, { setSubmitting }) => {
    try {
      const submitStatus = await submitHandler(values, setSubmitting);
      setSubmitting(false);
      if (submitStatus) {
        setSigned(true);
      } else {
        enqueueSnackbar("Error in signing the contract.");
        setSigned(false);
      }
    } catch (err) {
      console.log("Error", err.message);
      setSigned(false);
      enqueueSnackbar("Error in signing the contract.");
    }
  };

  // const downloadContract = () => {
  //   window.open(downloadUrl);
  // };
  const CheckBox = ({ label, name }) => {
    return (
      <Box component="label" sx={formStyle}>
        <Field
          style={styles.check_box}
          type="checkbox"
          name={name}
          disabled={signed || isAdmin}
        />
        {label}
      </Box>
    );
  };
  CheckBox.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  };

  return (
    <Box>
      {contract && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={SubmitData}
        >
          {({ isSubmitting, errors, submitCount }) => {
            return (
              <Form id="contractForm">
                <FormErrors
                  className={styles.formErrorsAlert}
                  show={submitCount > 0}
                  errors={Object.values(errors)}
                />
                <br />
                <Typography
                  variant="subtitle1Med"
                  id="checkbox-group"
                  sx={styles.form_heading}
                >
                  I confirm that:
                </Typography>
                <Box sx={{ display: { xs: "block", lg: "flex" } }}>
                  <Box
                    role="group"
                    aria-labelledby="checkbox-group"
                    sx={styles.form_container}
                  >
                    <CheckBox
                      name="rightEquipmentCondition"
                      label="I will use the right equipment"
                    />
                    <br />
                    <CheckBox
                      name="deliverBeforeDueDate"
                      label="I will deliver the data before the due date"
                    />
                    <br />
                    <CheckBox
                      name="followWorkInstruction"
                      label="I will follow the work instructions as specified in this
                      contract"
                    />
                    <br />
                    <CheckBox
                      name="deliverTheDeliverables"
                      label="I will deliver the deliverables as specified in this
                      contract"
                    />
                    {/* <br />
                    <CheckBox
                      name="onlyUploadOnCrowddroning"
                      label="I will upload all data to the Crowddroning platform and
                      nowhere else"
                    />
                   */}
                    <br />
                    <CheckBox
                      name="haveDroneCertificate"
                      label="My permits are valid"
                    />
                    <br />
                    <CheckBox
                      name="droneLicenseValid"
                      label="My current drone license is valid"
                    />
                    <br />
                    <CheckBox
                      name="insuranceValidCurrent"
                      label="My insurance is valid and is current"
                    />
                    <br />
                    <CheckBox
                      name="registrationValidCurrent"
                      label="My business registration is valid and is current"
                    />
                    <br />
                    <CheckBox
                      name="readT&C"
                      label="I have read and accepted the terms and conditions"
                    />
                    <br />
                  </Box>
                  {!signed && role === userRoles.pilot ? (
                    <Box sx={styles.button_container}>
                      <Typography
                        variant="body1"
                        id="checkbox-group"
                        sx={styles.form_heading}
                      >
                        The contract will go in effect once you sign it and
                        won’t be possible to change after that.
                      </Typography>
                      <br />
                      <CheckBox name="iUnderstandAll" label="I understand" />
                      <br />
                      <AppButton
                        label="Sign contract"
                        addtionalStyle={styles.submit_button}
                        type="submit"
                        look="purple"
                        form="contractForm"
                        submittingState={isSubmitting}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ ...styles.signed_container, ...boxShadowStyle }}>
                      <Box sx={styles.status_container}>
                        <Box
                          sx={{
                            ...styles.signedBackground,
                            ...(isAdmin
                              ? styles.signedBackgroundAdmin
                              : styles.signedBackgroundPilot),
                          }}
                        >
                          <Typography sx={styles.signedColor} variant="body2">
                            SIGNED BY GLOBHE
                          </Typography>
                        </Box>
                        <Typography variant="body2">
                          {"On " +
                            DateTime.fromISO(
                              contract?.signedByGlobhe
                            ).toISODate()}
                        </Typography>
                      </Box>
                      <Box sx={styles.status_container}>
                        {contract?.iUnderstandAll || signed ? (
                          <Box
                            sx={{
                              ...styles.signedBackground,
                              ...(isAdmin
                                ? styles.signedBackgroundAdmin
                                : styles.signedBackgroundPilot),
                            }}
                          >
                            <Typography sx={styles.signedColor} variant="body2">
                              SIGNED BY OPERATOR
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              ...styles.single_operator_signed,
                              background: theme.palette.status.errorDark,
                            }}
                          >
                            <Typography sx={styles.signedColor} variant="body2">
                              UNSIGNED BY OPERATOR
                            </Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            color: theme.palette.black.coolGrey,
                          }}
                        >
                          {(contract?.iUnderstandAll || signed) &&
                          contract.signedByOperator
                            ? "On " +
                              DateTime.fromISO(
                                contract.signedByOperator
                              ).toISODate()
                            : "On " +
                              DateTime.fromISO(
                                getCurrentDateTime()
                              ).toISODate()}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                <br />
                {(signed || isAdmin) && (
                  <Box sx={styles.button_box}>
                    <AppButton
                      label="Back"
                      addtionalStyle={styles.back_button}
                      look="inverted"
                      noIcon
                      onClick={navigateBack}
                    />
                    {/* 
                  {downloadUrl && (
                    <AppButton
                      label="Download contract"
                      addtionalStyle={styles.back_button}
                      look="black"
                      onClick={downloadContract}
                    />
                  )} */}
                  </Box>
                )}
                <br />
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

FormComponent.propTypes = {
  signed: PropTypes.bool,
  setSigned: PropTypes.bool.isRequired,
  id: PropTypes.string,
  contract: PropTypes.object.isRequired,
  role: PropTypes.string,
  submitHandler: PropTypes.func.isRequired,
};

FormComponent.defaultProps = {
  id: undefined,
  role: undefined,
  signed: false,
};

export default FormComponent;
