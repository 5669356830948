import firebase from "src/firebase";
import { useEffect, useState } from "react";
import { getDailyReports } from "../services/handle-operations";

export const useGetDailyReportsList = () => {
  const [dailyReports, setDailyReports] = useState([]);
  const [lastLoadedDailyReport, setLastLoadedDailyReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [lastLoadedPageNumber, setLastLoadedPageNumber] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPageNumber, setLastPageNumber] = useState(null);
  const [loadDataHandler, setLoadDataHandler] = useState(false);
  const [allDailyReportData, setAllDailyReportData] = useState([]);

  useEffect(() => {
    if (!firebase) {
      return;
    }
    setIsLoading(true);
    if (pageNumber <= lastLoadedPageNumber) {
      let newDataForReport = allDailyReportData.slice(
        (pageNumber - 1) * limit,
        pageNumber * limit
      );
      setDailyReports(newDataForReport);
      setIsLoading(false);
    } else {
      getDailyReports(firebase, lastLoadedDailyReport, limit)
        .then((data) => {
          setDailyReports(data.reports);
          if (data.last) {
            setIsLastPage(true);
            setLastPageNumber(pageNumber);
          }
          setLastLoadedPageNumber(pageNumber);
          setLastLoadedDailyReport(data.lastVisible);
          setAllDailyReportData([...allDailyReportData, ...data.reports]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [loadDataHandler]);

  return {
    dailyReports,
    isLoading,
    isLastPage,
    setLimit,
    setPageNumber,
    setIsLastPage,
    setLastLoadedPageNumber,
    setLastPageNumber,
    setDailyReports,
    setLastLoadedDailyReport,
    pageNumber,
    lastPageNumber,
    loadDataHandler,
    setLoadDataHandler,
  };
};
