import { users } from "../lib/constants";
import { getCurrentDateTime, getCurrentURL } from "../lib/helpers";
import { getUser } from "./auth";

const globheUrl =
  "https://hooks.slack.com/services/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";

const LogsHandling = (error, errorInfo) => {
  let errorMessage = "";
  if (error) {
    if (error.message) {
      errorMessage = JSON.stringify(error.message);
    } else {
      errorMessage = JSON.stringify(error);
    }
  }
  let user = getUser(users.user);
  fetch(process.env.GATSBY_GLOBHE_SLACK_URL, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      text: `*Error occured on client page at ${getCurrentDateTime()} *\n> *Location*\n>${
        window.location.href
      }\n>*User Details*\n>Role: ${user?.role} — Email: ${
        user?.email
      } — UserId: ${
        user?.userID
      }\n>*Error*\n>${errorMessage}\n>*Error Stack *\n>${JSON.stringify(
        errorInfo
      )}\n>`,
    }),
  })
    .then(() => {})
    .catch(() => {});
};

export default LogsHandling;
