import { Box } from "@mui/material";
import React from "react";
import useGetProjectSitesList from "../../../../hooks/useGetProjectSitesList";
import {
  columnForProjectSites,
  filterFieldsForProjectSites,
} from "../../../../lib/constants";
import GlobalTable from "../../admin/GlobalTable";
import FilterComponent from "../../../global/elements/FilterComponent";
import { navigate } from "gatsby";

const ProjectMissionsTable = () => {
  const [
    projectSites,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    setIsLastPage,
    lastPageNumber,
    setLastPageNumber,
    filter,
    setFilter,
    sort,
    setSort,
    setLastLoaded,
    setLastLoadedPageNumber,
    setProjectSitesAll,
    loading,
    triggerReload,
    setTriggerReload,
  ] = useGetProjectSitesList();

  const goToPageFcn = (value) => {
    // console.log("Redirect to mission page", value);
    navigate(`/app/missions/${value.id}`);
  };
  const clearDataAndTriggerReload = () => {
    setLastLoaded(null);
    setCurrentPage(1);
    setProjectSitesAll([]);
    setLastPageNumber(null);
    setLastLoadedPageNumber(null);
    setIsLastPage(false);
    setTriggerReload(!triggerReload);
  };

  const limitHandler = (value) => {
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const paginationBtnClick = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (!(currentPage === 1)) {
        setCurrentPage(currentPage - 1);
        setLastLoaded(null);
        setTriggerReload(!triggerReload);
      }
    }
  };
  const sortByHandler = (data) => {
    let sortValue = "desc";
    if (sort.sortValue === "desc") {
      sortValue = "asc";
    }

    setSort({
      sortBy: data,
      sortValue: sortValue,
    });
    clearDataAndTriggerReload();
  };

  // const filterFields = [
  //   //Label dataBaseField typeofFieldinDataBase
  //   { label: "Mission Name", value: "searchMissionName", type: "array" },
  //   { label: "Project Name", value: "projectNameSearch", type: "array" },
  //   { label: "Project Id", value: "projectId", type: "normalQuery" },
  //   { label: "Mission Id", value: "missionId", type: "id" },
  // ];

  return (
    <Box>
      <Box>
        <FilterComponent
          filterFields={filterFieldsForProjectSites}
          multiple={true}
          filterFormName={"mainFilterFields"}
          filter={filter}
          setFilter={setFilter}
          clearDataAndTriggerReload={clearDataAndTriggerReload}
        />
      </Box>
      <GlobalTable
        columns={columnForProjectSites}
        allData={projectSites}
        goToPageFcn={goToPageFcn}
        loading={loading}
        limitHandler={limitHandler}
        limit={limit}
        paginationBtnClick={paginationBtnClick}
        currentPage={currentPage}
        isLastPage={isLastPage}
        lastPage={lastPageNumber}
        sortByHandler={sortByHandler}
      />
    </Box>
  );
};

export default ProjectMissionsTable;
