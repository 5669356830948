import React from "react";
import { Grid, Divider } from "@mui/material";
import theme from "../../../../theme";
import PropTypes from "prop-types";

const xsVisible = {
  display: { xs: "block", md: "none" },
};

const BottomDivider = ({ isVisible }) => {
  return (
    <Grid sx={isVisible ? xsVisible : null} xs={12} ml={2} mt={1}>
      <Divider color={theme.palette.grey.lightWarmGrey} />
    </Grid>
  );
};

BottomDivider.propTypes = {
  isVisible: PropTypes.bool,
};
BottomDivider.defaultProps = {
  isVisible: false,
};

export default BottomDivider;
