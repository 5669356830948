import React from "react";
import Textarea from "@mui/joy/Textarea";
import PropTypes from "prop-types";
import {
  multiLineTextAreaStyle,
  multiLineTextAreaStyleDisplay,
} from "../../../../lib/styleConstants";
import { Field } from "formik";
import styled from "styled-components";
import { Box, Tooltip, Typography } from "@mui/material";
import { copyTextToClipBoard } from "../../../../lib/helpers";
import { useSnackbar } from "notistack";
import { urlPattern, userRoles } from "../../../../lib/constants";
import { getUser } from "../../../../services/auth";
import theme from "../../../../theme";

const styles = {
  multilineText: {
    whiteSpace: "pre-wrap",
  },
  multilineTextClient: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  multilineTextAdmin: {
    color: theme.palette.adminBlue.main,
    textDecoration: "none",
  },
  multilineTextPilot: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
};

const StyledTextarea = styled(Textarea)(() => ({
  fontSize: "0.875rem",
  fontFamily: "NeueHaasGroteskDisp WO2",
}));

const StyledTextareaToDisplay = styled(Textarea)(() => ({
  color: "#101820",
  // lineHeight: "125%",
  letterSpacing: "-0.02em",
  fontWeight: 400,
}));

const MultiLineTextArea = ({
  isFormik,
  name,
  label,
  onBlur,
  defaultValue,
  placeholder,
  variant,
  isDisabled,
  minRows,
  required,
  setFieldValue,
  copyText,
  displayText,
  multilineText,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isClient = getUser("user").role === userRoles.client;
  const isAdmin = getUser("user").role === userRoles.admin;

  const copyTextFcn = (text) => {
    if (copyText) {
      copyTextToClipBoard(text);
      enqueueSnackbar("Text copied!", { variant: "success" });
    }
  };
  return isFormik ? (
    <Field name={name} label={label} setFieldValue={setFieldValue}>
      {({ field }) => (
        <StyledTextarea
          {...field}
          sx={multiLineTextAreaStyle}
          minRows={minRows}
          required={required}
          placeholder={placeholder}
          variant={variant}
        />
      )}
    </Field>
  ) : copyText ? (
    <Tooltip title={"Click on the text to copy."} placement="top-start">
      <Box onClick={() => copyTextFcn(defaultValue)}>
        <StyledTextarea
          sx={multiLineTextAreaStyle}
          minRows={minRows}
          defaultValue={defaultValue}
          onBlur={onBlur}
          placeholder={placeholder}
          variant={variant}
          disabled={isDisabled}
          required={required}
        />
      </Box>
    </Tooltip>
  ) : displayText ? (
    <StyledTextareaToDisplay
      sx={multiLineTextAreaStyleDisplay}
      minRows={minRows}
      defaultValue={defaultValue}
      onBlur={onBlur}
      placeholder={placeholder}
      variant={variant}
      disabled={isDisabled}
      required={required}
    />
  ) : multilineText ? (
    defaultValue?.split(urlPattern).map((part, index) => {
      if (urlPattern.test(part)) {
        // Add "https://" if the link starts with "www."
        const href = part.startsWith("www.") ? `https://${part}` : part;
        return (
          <Box
            component={"a"}
            href={href}
            key={index}
            target="_blank"
            rel="noopener noreferrer"
            sx={
              isClient
                ? styles.multilineTextClient
                : isAdmin
                ? styles.multilineTextAdmin
                : styles.multilineTextPilot
            }
          >
            {part}
          </Box>
        );
      } else {
        // Render plain text parts
        return (
          <Typography key={index} variant="body1" sx={styles.multilineText}>
            {part}
          </Typography>
        );
      }
    })
  ) : (
    <StyledTextarea
      sx={multiLineTextAreaStyle}
      minRows={minRows}
      defaultValue={defaultValue}
      onBlur={onBlur}
      placeholder={placeholder}
      variant={variant}
      disabled={isDisabled}
      required={required}
    />
  );
};
MultiLineTextArea.propTypes = {
  isFormik: PropTypes.bool,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  setFieldValue: PropTypes.func,
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  minRows: PropTypes.number,
  required: PropTypes.bool,
  copyText: PropTypes.bool,
  displayText: PropTypes.bool,
  multilineText: PropTypes.bool,
};
MultiLineTextArea.defaultProps = {
  isFormik: false,
  onBlur: undefined,
  placeholder: undefined,
  name: undefined,
  label: undefined,
  setFieldValue: undefined,
  variant: "outlined",
  isDisabled: false,
  minRows: 5,
  defaultValue: undefined,
  required: false,
  copyText: false,
  displayText: false,
  multilineText: false,
};
export default MultiLineTextArea;
