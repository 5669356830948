import { Box, Typography, Slider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import MuiInput from "@mui/material/Input";

const Input = styled(MuiInput)`
  width: 42px;
`;
const OverlapSlider = ({
  title,
  // values,
  variant,
  setFieldValue,
  name,
  defaultValue,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue === value) {
      setFieldValue(name, null);
    } else {
      setFieldValue(name, value);
    }
  }, [value]);

  const handleSliderChange = (e, newValue) => {
    setValue(newValue);
    // setFieldValue(name, e.target.value);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <Box sx={{ width: 350 }}>
      <Typography id="input-slider" gutterBottom variant={variant}>
        {title}
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Slider
            value={typeof value === "number" ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            name={name}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
        <Grid item xs={1}>
          %
        </Grid>
      </Grid>
    </Box>
  );
};

OverlapSlider.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  // values: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.number,
};

OverlapSlider.defaultProps = {
  variant: "h6",
  title: undefined,
  // values: undefined,
  defaultValue: 0,
};

export default OverlapSlider;
