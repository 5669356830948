import React, { useEffect, useState } from "react";
import {
  missionTypeConstant,
  missionStatus,
  columnForAdminUnassignedMission,
  columnForAdminClientActiveMission,
  columnForAdminActiveMission,
  columnForPilotActiveMission,
  columnForAdminGwrMission,
  columnForAdminTestMission,
  columnForAdminOperatoeSubCompletedMission,
  columnForAdminOperatorSubActiveMission,
  columnForAdminRejectedMission,
  columnForAdminCompletedMission,
  columnForPilotCompletedMission,
  columnForPilotMissedMission,
  columnForPilotRejectedMission,
  columnForPilotInquiriesMission,
  clientOrderStatus,
  columnForClientReviewingMission,
  columnForClientCompletedMission,
  columnForClientCancelledMission,
  columnForClientRejectedMission,
  columnForClientInProcessMission,
  userRoles,
  columnForClientGroupOrder,
  users,
} from "../../../lib/constants";
import MissionPage from "./MissionPage";
import { useGetMissionList } from "../../../hooks/useGetMissionList";
import Tips from "../../global/elements/Tips";
import Layout from "../../../Layout";
import PropTypes from "prop-types";
import { getUser } from "../../../services/auth";
import { Box } from "@mui/material";
import OrderButton from "../Client/Orders/OrderButton";
import OrdersTable from "../Client/Orders/OrdersTable";
import GuestStatusAlert from "../AnonymousUser/GuestStatusAlert";
import GroupOrderTable from "../Client/Orders/GroupOrderTable";

const userRole = getUser("user");
const isAdmin = userRole.role === userRoles.admin;
const isPilot = userRole.role === userRoles.pilot;

const ClientOrderComponent = ({ status, tips, pageTitle, columns }) => {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    let userProfile = getUser(users.userProfile);
    setUserData(userProfile);
  }, []);

  return (
    <Layout pageTitle={pageTitle} hasSideMenu loading={false} addBottomPadding>
      {userRole?.isAnonymous && <GuestStatusAlert />}
      {(userData || userRole?.isAnonymous) && (
        <>
          <Box sx={{ paddingTop: "1em" }}>
            <OrderButton link="/app/order/create-order" />
          </Box>
          {tips && <Tips content={tips} />}
          <OrdersTable
            status={status}
            columns={columns}
            enterpriseId={userData?.enterPriseId || null}
          />
        </>
      )}
    </Layout>
  );
};
ClientOrderComponent.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  tips: PropTypes.string,
  columns: PropTypes.array.isRequired,
};
ClientOrderComponent.defaultProps = {
  tips: undefined,
};

const ClientGroupOrderComponent = ({ tips, pageTitle, columns }) => {
  return (
    <Layout pageTitle={pageTitle} hasSideMenu loading={false} addBottomPadding>
      {userRole?.isAnonymous && <GuestStatusAlert />}
      <Box sx={{ paddingTop: "1em" }}>
        <OrderButton link="/app/order/create-order" />
      </Box>
      {tips && <Tips content={tips} />}
      <GroupOrderTable columns={columns} />
    </Layout>
  );
};
ClientGroupOrderComponent.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  tips: PropTypes.string,
  columns: PropTypes.array.isRequired,
};
ClientGroupOrderComponent.defaultProps = {
  tips: undefined,
};
const MissionsComponent = ({
  status,
  missionType,
  tips,
  pageTitle,
  columns,
  noButton,
}) => {
  const [authorized, filteredMissionList, isLoading] = useGetMissionList(
    status,
    null
  );

  return (
    <Layout
      loading={isLoading && filteredMissionList.length === 0}
      pageTitle={pageTitle}
      hasSideMenu
      addBottomPadding
    >
      {tips && <Tips content={tips} />}
      {!noButton && (
        <Box sx={{ paddingTop: "1em" }}>
          <OrderButton link="/app/order/create-order" look="blue" />
        </Box>
      )}
      <MissionPage
        status={status}
        columns={columns}
        authorized={authorized}
        missionType={missionType}
      />
    </Layout>
  );
};
MissionsComponent.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  missionType: PropTypes.string.isRequired,
  tips: PropTypes.string,
  columns: PropTypes.array.isRequired,
  addOrderButton: PropTypes.bool,
  noButton: PropTypes.bool,
};
MissionsComponent.defaultProps = {
  tips: undefined,
  addOrderButton: false,
  noButton: false,
};

/////// CHECK THIS ////////
//////////////////////////

//----------------------------//
//-----CLIENT'S ORDERS -------//
//----------------------------//

//--1.Reviewing
export const ClientOrderReviewing = () => (
  <ClientOrderComponent
    pageTitle="Reviewing orders"
    status={clientOrderStatus.reviewing}
    columns={columnForClientReviewingMission}
    //tips="Active missions mean that a drone operator has been assigned to the mission. All mission types are in this folder as long as they are active. Once an operator signs the contract for Client Missions, they can upload data."
  />
);
//--2.Active
export const ClientOrderActive = () => (
  <ClientOrderComponent
    pageTitle="Active orders"
    status={clientOrderStatus.inProcess}
    columns={columnForClientInProcessMission}
    //tips="Active missions mean that a drone operator has been assigned to the mission. All mission types are in this folder as long as they are active. Once an operator signs the contract for Client Missions, they can upload data."
  />
);
//--3.Compeleted
export const ClientOrderCompleted = () => (
  <ClientOrderComponent
    pageTitle="Completed orders"
    status={clientOrderStatus.completed}
    columns={columnForClientCompletedMission}
    //tips="Active missions mean that a drone operator has been assigned to the mission. All mission types are in this folder as long as they are active. Once an operator signs the contract for Client Missions, they can upload data."
  />
);
//--4.Cancelled
export const ClientOrderCancelled = () => (
  <ClientOrderComponent
    pageTitle="Cancelled orders"
    status={clientOrderStatus.cancelled}
    columns={columnForClientCancelledMission}
    //tips="Active missions mean that a drone operator has been assigned to the mission. All mission types are in this folder as long as they are active. Once an operator signs the contract for Client Missions, they can upload data."
  />
);
//--5.Rejected
export const ClientOrderRejected = () => (
  <ClientOrderComponent
    pageTitle="Rejected orders"
    status={clientOrderStatus.rejected}
    columns={columnForClientRejectedMission}
    //tips="Active missions mean that a drone operator has been assigned to the mission. All mission types are in this folder as long as they are active. Once an operator signs the contract for Client Missions, they can upload data."
  />
);

//--6. Grouped Orders
export const ClientGroupOrders = () => (
  <ClientGroupOrderComponent
    pageTitle="Group orders"
    columns={columnForClientGroupOrder}
    //tips="Active missions mean that a drone operator has been assigned to the mission. All mission types are in this folder as long as they are active. Once an operator signs the contract for Client Missions, they can upload data."
  />
);

//----------------------------//
//-----------ADMINS-----------//
//----------------------------//

//--1.Initial client order
export const AdminInitialClientOrder = () => (
  <MissionsComponent
    pageTitle="Initial orders"
    status={missionStatus.initialClientOrder}
    missionType={missionTypeConstant.clientOrder}
    columns={columnForAdminUnassignedMission}
    tips="Here you find the initial client orders. They didn't accept the quote yet. Once they accept the quote or you confirm this order, the mission will be moved to the 'Confirmed' folder."
  />
);
//--2.Confirmed mission
export const AdminUnassignedClientOrder = () => (
  <MissionsComponent
    pageTitle="Confirmed Missions"
    status={missionStatus.unassigned}
    missionType={missionTypeConstant.clientOrder}
    columns={columnForAdminUnassignedMission}
    tips="These missions have been confirmed by a client as the latter has accepted the price quote. You can go ahead and start inviting operators. Once you assign an operator, the mission will move to the 'Active' folder."
  />
);
//--2.Active
export const AdminActiveClientOrder = () => (
  <MissionsComponent
    pageTitle="Active orders"
    status={missionStatus.active}
    missionType={missionTypeConstant.clientOrder}
    columns={columnForAdminClientActiveMission}
    tips="Active missions mean that a drone operator has been assigned to the mission. All mission types are in this folder as long as they are active. Once an operator signs the contract for Client Missions, they can upload data."
  />
);
//--3.Completed
export const AdminCompletedClientOrder = () => (
  <MissionsComponent
    pageTitle="Completed orders"
    status={missionStatus.completed}
    missionType={missionTypeConstant.clientOrder}
    columns={columnForAdminCompletedMission}
    tips="Here you find all the missions that were approved and completed. You can filter by mission type, name, ID, and payment status. Payment status will help you see when GLOBHE or the operator was paid."
  />
);
//--4.Archive
export const AdminArchivedClientOrder = () => (
  <MissionsComponent
    pageTitle="Archived orders"
    status={missionStatus.archive}
    missionType={missionTypeConstant.clientOrder}
    columns={columnForAdminUnassignedMission}
    tips="Here you see a list of all archived mission. A mission can only be archived if a pilot is not assigned to it, or if it is still in an unconfirmed order."
  />
);
//--5.Cancelled
export const AdminCancelledClientOrder = () => (
  <MissionsComponent
    pageTitle="Cancelled orders"
    status={missionStatus.cancelled}
    missionType={missionTypeConstant.clientOrder}
    columns={columnForAdminUnassignedMission}
    tips="These are the orders that you placed and were cancelled by you before accepting the quote."
  />
);
//--6.Rejected
export const AdminRejectedClientOrder = () => (
  <MissionsComponent
    pageTitle="Rejected orders"
    status={missionStatus.rejected}
    missionType={missionTypeConstant.clientOrder}
    columns={columnForAdminRejectedMission}
    tips="Here you see a list of all rejected mission. A mission can only be rejected by admin."
  />
);

//----Other missions admins ---
//--1. Test missions
export const AdminTestMission = () => (
  <MissionsComponent
    pageTitle="Test missions"
    //status={missionStatus.active}
    missionType={missionTypeConstant.testMission}
    columns={columnForAdminTestMission}
  />
);
//--2. Challenges
export const AdminChallengeMission = () => (
  <MissionsComponent
    pageTitle="Challenge missions"
    //status={missionStatus.active}
    missionType={missionTypeConstant.challengeMission}
    columns={columnForAdminTestMission}
  />
);
//--3. GWR
export const AdminGwrMission = () => (
  <MissionsComponent
    pageTitle="GWR missions"
    //status={missionStatus.active}
    missionType={missionTypeConstant.guinnessWorldRecord}
    columns={columnForAdminGwrMission}
  />
);
//--4. Create test mission
//--5. Create challenge mission
//--6. Archive
export const AdminMissionArchive = () => (
  <MissionsComponent
    pageTitle="Archived other missions"
    status={missionStatus.archive}
    columns={columnForAdminUnassignedMission}
    //tips="Here you see a list of all archived mission. A mission can only be archived if a pilot is not assigned to it, or if it is still in an unconfirmed order."
  />
);

//----Operator submissions ---
//--1. active
export const OperatorSubmissionActive = () => (
  <MissionsComponent
    pageTitle="Active submissions"
    status={missionStatus.active}
    missionType={missionTypeConstant.operatorSubmission}
    columns={columnForAdminOperatorSubActiveMission}
    tips="Active missions mean that a drone operator has submitted their data but the data is not approved yet. Once you approve their submission, it will move to completed."
    noButton
  />
);
//--2. completed
export const OperatorSubmissionCompleted = () => (
  <MissionsComponent
    pageTitle="Completed submissions"
    status={missionStatus.completed}
    missionType={missionTypeConstant.operatorSubmission}
    columns={columnForAdminOperatoeSubCompletedMission}
    tips="Here you find all the missions that were approved and completed. You can filter by mission type, name, ID, and payment status."
    noButton
  />
);

//----------------------------//
//----------OPERATORS---------//
//----------------------------//

//--1. Inquiries
export const OperatorMissionsInquiries = () => (
  <MissionsComponent
    pageTitle="Mission inquiries"
    status={missionStatus.unassigned}
    //   missionType={missionTypeConstant.operatorSubmission}
    columns={columnForPilotInquiriesMission}
    tips="Here you find the missions you were invited to. You can accept or reject these missions as you may. Remember that other drone operators are also invited, so be quick in deciding."
    noButton
  />
);

//--2. Active
export const OperatorMissionsActive = () => (
  <MissionsComponent
    pageTitle="Active missions"
    status={missionStatus.active}
    // missionType={missionTypeConstant.clientOrder}
    columns={
      isPilot ? columnForPilotActiveMission : columnForAdminActiveMission
    }
    tips="Here you find your active missions that were assigned to you. Make sure you sign your contract in case of a client mission before you upload your data."
    noButton
  />
);

//--3. Completed
export const OperatorMissionsCompleted = () => (
  <MissionsComponent
    pageTitle="Completed missions"
    status={missionStatus.completed}
    //   missionType={missionTypeConstant.operatorSubmission}
    columns={
      isAdmin ? columnForAdminCompletedMission : columnForPilotCompletedMission
    }
    tips="Here you find all the missions that were approved and completed. You can also see is your invoice was paid or not."
    noButton
  />
);
//--4. Missed
export const OperatorMissionsMissed = () => (
  <MissionsComponent
    pageTitle="Missed missions"
    status={missionStatus.missed}
    //   missionType={missionTypeConstant.operatorSubmission}
    columns={columnForPilotMissedMission}
    tips="Here you find the missions that you missed. You might have been slow in reacting or have suggested a high price so another operator was assigned to this mission."
    noButton
  />
);
//--5. Rejected
export const OperatorMissionsRejected = () => (
  <MissionsComponent
    pageTitle="Rejected missions"
    status={missionStatus.rejected}
    //   missionType={missionTypeConstant.operatorSubmission}
    tips="Here you find the missions that you rejected. You can always change your decision if the mission is not yet assigned to another operator."
    columns={
      isAdmin ? columnForAdminRejectedMission : columnForPilotRejectedMission
    }
    noButton
  />
);
//--6. Sell your data
