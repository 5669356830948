import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getPilot } from "src/services/pilots";
import { admintype } from "../lib/constants";
import { checkAdmintype, getUser } from "../services/auth";

const useGetPilot = (pilotId) => {
  const [pilot, setPilot] = useStateIfMounted(null);
  const [loading, setLoading] = useStateIfMounted(true);
  const [authentication, setAuthetication] = useStateIfMounted(null);
  const [reload, setReload] = useStateIfMounted(false);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    getPilot(firebase, pilotId)
      .then((pilot) => {
        setPilot(pilot);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pilotId, reload]);

  useEffect(() => {
    checkAdmintype(firebase).then((snapshot) => {
      let loginUserId = getUser("user").userID;
      if (
        snapshot.adminType == admintype.superAdmin ||
        snapshot.adminType == admintype.dataAdmin ||
        loginUserId == pilotId
      ) {
        setAuthetication(true);
      } else {
        setAuthetication(false);
      }
    });
  }, [pilotId, reload]);

  return [pilot, loading, authentication, reload, setReload];
};

export default useGetPilot;
