import firebase from "src/firebase";
import { useEffect } from "react";
import { isBrowser } from "../services/auth";
import { useStateIfMounted } from "use-state-if-mounted";
import { getAllEnterprise } from "../services/enterprise";

const useGetEnterpriseList = () => {
  const [enterprises, setEnterprises] = useStateIfMounted(null);
  const [allEnterprises, setAllEnterprises] = useStateIfMounted([]);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);
  const [lastLoadedEnterprise, setLastLoadedEnterprise] =
    useStateIfMounted(null);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [dataLoadedTill, setDataLoadedTill] = useStateIfMounted(null);
  const [isLastPage, setIslastPage] = useStateIfMounted(false);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [limit, setLimit] = useStateIfMounted(10);
  const [filter, setFilter] = useStateIfMounted({
    name: null,
    location: null,
  });

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    try {
      setLoading(true);
      if (dataLoadedTill >= currentPage) {
        let prevPageMissions = allEnterprises.slice(
          (currentPage - 1) * limit,
          currentPage * limit
        );
        setEnterprises(prevPageMissions);
        setLoading(false);
      } else {
        getAllEnterprise(filter, lastLoadedEnterprise, limit).then((data) => {
          setEnterprises(data.enterprises);
          setDataLoadedTill(currentPage);
          setLastLoadedEnterprise(data.lastVisible);
          setAllEnterprises([...allEnterprises, ...data.enterprises]);
          if (data.last) {
            setLastPageNumber(currentPage);
            setIslastPage(true);
          }
          setLoading(false);
        });
      }
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  }, [triggerReload]);

  return {
    enterprises,
    setAllEnterprises,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedEnterprise,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  };
};

export default useGetEnterpriseList;
