import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../../theme";

const styles = {
  overlineCls: {
    textTransform: "none",
  },
  containerCls: {
    padding: "10px",
    paddingBottom: "0px",
    "&:hover": {
      backgroundColor: theme.palette.primary.palePistachio,
    },
  },
  btnCls: {
    display: "flex",
    alignItems: "center",
  },
};

const EnterPriseMember = ({ member, showDivider, onRemove }) => {
  const { firstName, lastName, email } = member;
  const GetLabelValue = ({ label, value }) => {
    return (
      <>
        <Typography variant="overline" sx={styles.overlineCls}>
          {label}
        </Typography>{" "}
        &nbsp;&nbsp;
        <Typography variant="body3">{value}</Typography>
      </>
    );
  };
  return (
    <Box>
      <Box sx={styles.containerCls}>
        <Grid container>
          <Grid
            item
            xs={11}
            container
            textAlign={"initial"}
            rowSpacing={0.1}
            columnSpacing={0.5}
          >
            <Grid item>
              <GetLabelValue label={"First name"} value={firstName} />
            </Grid>
            <Grid item>
              <GetLabelValue label={"Last name"} value={lastName} />
            </Grid>
            <Grid item xs={12}>
              <GetLabelValue label={"Email"} value={email} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={1} sx={styles.btnCls}>
            <IconButton onClick={() => onRemove(member)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      {showDivider && <Divider />}
    </Box>
  );
};

EnterPriseMember.propTypes = {
  member: PropTypes.object.isRequired,
  showDivider: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
};

EnterPriseMember.defaultProps = {
  showDivider: false,
};

export default EnterPriseMember;
