import React from "react";
import theme from "../../../theme";
import SectionHeading from "../../global/elements/SectionHeading";
import Layout from "../../../Layout";
import InspirationTable from "./InspirationTable";
import useGetInspiration from "../../../hooks/useGetInspirationList";
import { Box } from "@mui/material";
import UniversalLoader from "../../global/elements/UniversalLoader";
import AppButton from "../../global/elements/AppButton";

const styles = {
  tableContainer: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: { xs: "center", md: "flex-start" },
    marginTop: "20px",
  },
  loaderDiv: {
    paddingTop: "18%",
  },
};

const InspirationPage = () => {
  const [allInspiration, loading] = useGetInspiration();

  return (
    <Layout pageTitle="Inspirations" hasSideMenu loading={loading}>
      {loading ? (
        <Box sx={styles.loaderDiv}>
          <UniversalLoader />
        </Box>
      ) : (
        <Box>
          <SectionHeading
            heading="Inspirations"
            backgroundColor={theme.palette.grey.warmGrey}
          />
          <br />
          <AppButton
            label="Add Inspiration"
            url="/app/admin/inspirations/add"
            internalLink
            look="black"
          />
          <br />
          <Box sx={styles.tableContainer}>
            {allInspiration?.map((item, i) => {
              return (
                <InspirationTable
                  key={i}
                  title={item?.title}
                  blogImage={item?.blogImage}
                  width="250px"
                  height="250px"
                  link={`/app/admin/inspirations/edit/${item?.id}`}
                  id={item?.id}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default InspirationPage;
