import {
  BarChart,
  LinearXAxis,
  LinearYAxis,
  LinearYAxisTickSeries,
  BarSeries,
  Bar,
  BarLabel,
} from "reaviz";
import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const styles = {
  root: {
    width: "100%",
    paddingLeft: "5rem",
    "& .stop": {
      backgroundColor: "red",
    },
  },
};

const CountryBarChart = ({ data }) => {
  const formattedData = data
    .map((item) => {
      let newArray = {
        key: item.country,
        data: parseInt(item.pilots),
      };
      return newArray;
    })
    .sort((c1, c2) => (c1.data < c2.data ? 1 : c1.data > c2.data ? -1 : 0));

  return (
    <Box sx={styles.root}>
      <BarChart
        width={"80%"}
        height={2600}
        gridlines={null}
        data={formattedData.reverse()}
        xAxis={<LinearXAxis type="value" />}
        yAxis={
          <LinearYAxis
            type="category"
            tickSeries={<LinearYAxisTickSeries tickSize={20} />}
          />
        }
        series={
          <BarSeries
            layout="horizontal"
            bar={<Bar label={<BarLabel position={"top"} />} />}
          />
        }
      />
    </Box>
  );
};

CountryBarChart.propTypes = {
  data: PropTypes.array.isRequired,
};
export default CountryBarChart;
