import React from "react";
import HeaderBox from "../../../../global/elements/HeaderBox";
import { Grid, Typography } from "@mui/material";
import AppButton from "../../../../global/elements/AppButton";
import PropTypes from "prop-types";

const AddToCartComp = ({ user, setShowDialogue, totalDataCount }) => {
  return (
    <HeaderBox
      titleVariant={"h4"}
      title={"All data in one click"}
      background="none"
    >
      <Grid container spacing={2} mt={2}>
        <Grid item xs={8} md={10}>
          <Typography variant="h5">{`You are going to add ${
            totalDataCount || 0
          } datasets to your cart by clicking on " Add all to cart " button`}</Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <AppButton
            label="Add all to cart"
            size="100%"
            onClick={() => setShowDialogue(true)}
            look={user?.isAnonymous ? "greenDisabled" : "green"}
            isDisabled={user?.isAnonymous ? true : false}
          />
        </Grid>
      </Grid>
    </HeaderBox>
  );
};

AddToCartComp.propTypes = {
  setShowDialogue: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  totalDataCount: PropTypes.number,
};
AddToCartComp.defaultProps = {
  totalDataCount: 0,
};

export default AddToCartComp;
