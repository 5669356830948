import React from "react";
import PilotSearch from "./PilotSearch";
import Layout from "../../../Layout";

const PilotTable = () => {
  return (
    <Layout pageTitle={"Drone Operators"} hasSideMenu addBottomPadding>
      <PilotSearch />
    </Layout>
  );
};

export default PilotTable;
