import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Layout from "../../../../Layout";
import {
  getCollaborationRecord,
  getMission,
} from "../../../../services/missions";
import theme from "../../../../theme";
import MissionBackNav from "../../missions/MissionBackNav";
import firebase from "src/firebase";
import { AppBar } from "@mui/material";
import { getDeliveryPackages } from "../../../../services/delivery-packages";
import OrderNotFound from "../Order/OrderNotFound";
import { getUser } from "../../../../services/auth";
import DeliveryPackagesTab from "../../missions/FilesPackages/DeliveryPackagesTab";
import { packageTypeConstant } from "../../../../lib/constants";

const styles = {
  topNav: {
    padding: "3rem 2rem 4rem 2rem",
    display: "flex",
    justifyContent: { xs: "left", md: "space-between" },
    flexFlow: { sm: "row", xs: "wrap" },
    background: theme.palette.grey.white,
    alignItems: "flex-end",
  },
  mainContent: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "auto",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  },
  sectionContainer: {
    background: theme.palette.grey.white,
    paddingLeft: { xs: 0, md: "2rem" },
  },
  missioNDetails: {
    width: "100%",
    // padding: "30px 0px 30px 0px",
    background: `linear-gradient(180deg, ${theme.palette.secondary.seaGreen}19 0%, rgba(255,255,255,1) 30px)`,
    padding: "30px",
  },
};

const CollaboratorOrder = ({ missionID }) => {
  const [mission, setMission] = useState(null);
  const [loadingMission, setLoadingMission] = useState(false);
  const [showError, setShowError] = useState(null);
  const [deliveryPackage, setDeliveryPackages] = useState([]);
  const currentUser = getUser("userProfile");

  useEffect(() => {
    setLoadingMission(true);
    checkCollaborator();
  }, []);

  const checkCollaborator = async () => {
    try {
      let collaborator = await getCollaborationRecord({
        missionId: missionID,
        emailId: currentUser?.email,
      });
      if (collaborator) {
        getMission(firebase, missionID)
          .then((data) => {
            setMission(data);
            getDeliveryPackages(firebase, missionID)
              .then((data) => {
                setDeliveryPackages(
                  data.sort(function (a, b) {
                    return new Date(a.dateCreated) - new Date(b.dateCreated);
                  })
                );
                setLoadingMission(false);
              })
              .catch((e) => {
                console.error(e);
                setShowError("Error in fetching order!!");
                setLoadingMission(false);
              });
          })
          .catch((e) => {
            console.log("Error", e);
            setShowError("Error in fetching order!");
            setLoadingMission(false);
          });
      } else {
        console.log("You are no longer collaborator to this order");
        setShowError("You are no longer collaborator to this order");
        setLoadingMission(false);
      }
    } catch (e) {
      console.log("Error", e);
      let errStr = e?.msg || "Error in fetching order";
      setShowError(errStr);
      setLoadingMission(false);
    }
  };

  return (
    <Layout loading={loadingMission}>
      {!loadingMission && mission ? (
        <>
          <Box sx={styles.topNav}>
            <MissionBackNav
              missionData={mission}
              redirectURL={"/app/client/shared-data"}
            />
          </Box>

          <Box sx={styles.mainContent}>
            <Box sx={styles.sectionContainer}>
              <AppBar
                position="static"
                sx={styles.appBar}
                elevation={0}
              ></AppBar>
            </Box>
            <Box sx={{ ...styles.missioNDetails }}>
              {/* <DropzoneGallery
                key="1"
                mission={mission}
                isEditable={false}
                isDeliveryPackage={true}
                mediaPackage={deliveryPackage[0]}
              /> */}
              <DeliveryPackagesTab
                packageId={deliveryPackage[0]?.id}
                mission={mission}
                packageType={packageTypeConstant.deliveryPackage}
                mediaPackage={deliveryPackage[0]}
                isEditable={false}
              />
            </Box>
          </Box>
        </>
      ) : showError ? (
        <>
          <OrderNotFound
            title={showError}
            description={"It might have been removed"}
          />
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};

CollaboratorOrder.propTypes = {
  missionID: PropTypes.string.isRequired,
};
export default CollaboratorOrder;
