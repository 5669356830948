import PropTypes from "prop-types";
import React from "react";
import useGetFeedback from "../../../hooks/useGetFeedback";
import theme from "../../../theme";
import Layout from "../../../Layout";
import { Box, Typography } from "@mui/material";
import LabelDataSet from "../../global/elements/LabelDataSet";
import Rating from "@mui/material/Rating";
import { DateTime } from "luxon";
import { boxShadowStyle } from "../../../lib/styleConstants";

const styles = {
  infoActionContainer: {
    display: { xs: "block", md: "flex" },
    width: "100%",
    padding: "1rem",
    justifyContent: { xs: "none", md: "space-between" },
  },
  singleContainer: {
    padding: "20px 30px",
    width: { md: "48%" },
    marginBottom: { xs: "2rem", md: "1rem" },
    backgroundColor: theme.palette.adminBlue.lightBlue,
  },
  questionContainer: {
    margin: "1rem",
    padding: "30px",
    paddingBottom: "3rem",
    marginTop: { xs: 0, md: "1rem" },
  },
  content: {
    marginTop: "1rem",
    "& .MuiRating-iconFilled": {
      color: theme.palette.adminBlue.main,
    },
    "& .MuiRating-iconEmpty": {
      color: theme.palette.black.coolGrey,
    },
  },
};

const SingleFeedback = ({ feedbackId }) => {
  const [feedback, loading] = useGetFeedback(feedbackId);
  return (
    <Layout
      backButtonPageName="Feedback"
      loading={loading}
      backButton
      hasSideMenu
      backNavUrl="/app/admin/feedbacks"
      addBottomPadding
    >
      {!loading && (
        <>
          <Box sx={styles.infoActionContainer}>
            <Box sx={styles.singleContainer}>
              <Typography variant="h4">{feedback?.name || "n/a"}</Typography>
              <Box sx={{ display: "flex" }}>
                <LabelDataSet
                  heading="Company"
                  value={feedback?.company || "n/a"}
                  type="feedback"
                />
                <LabelDataSet
                  heading="Email"
                  value={feedback?.email || "n/a"}
                  type="feedback"
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <LabelDataSet
                  heading="Date Created"
                  value={
                    DateTime.fromISO(feedback?.dateCreated).toISODate() || "n/a"
                  }
                  type="feedback"
                />
                <LabelDataSet
                  heading="Telephone"
                  value={feedback?.phone || "n/a"}
                  type="feedback"
                />
              </Box>
            </Box>
            <Box sx={styles.singleContainer}>
              <Typography variant="h4">Ratings</Typography>
              <Box sx={styles.content}>
                <Rating defaultValue={feedback?.rating} readOnly />
                <br />
              </Box>

              <br />
              <br />
            </Box>
          </Box>
          <Box sx={{ ...styles.questionContainer, ...boxShadowStyle }}>
            <Typography variant="h4">Feedback</Typography>
            <Typography sx={styles.content} variant="body1">
              {feedback?.description || "n/a"}
            </Typography>
          </Box>
        </>
      )}
    </Layout>
  );
};
SingleFeedback.propTypes = {
  feedbackId: PropTypes.string,
};

SingleFeedback.defaultProps = {
  feedbackId: undefined,
};

export default SingleFeedback;
