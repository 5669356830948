import { Grid } from "@mui/material";
import React, { useContext } from "react";
import AssignedPilotTable from "./AssignedPilotTable";
import InvitedPilotInterestTable from "./InvitedPilotInterestTable";
import InvitedPilotsTable from "./InvitedPilotsTable";
import { missionStatus } from "../../../../../lib/constants";
import MissionContext from "./MissionContext";

const MissionPilotsTable = () => {
  const orderDetails = useContext(MissionContext);
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
          {(orderDetails?.mission?.status === missionStatus.active ||
            orderDetails?.mission?.status === missionStatus.completed) &&
            !orderDetails?.mission?.archive && <AssignedPilotTable />}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
          {orderDetails?.mission?.status !== missionStatus.completed &&
            orderDetails?.mission?.status !== missionStatus.archive &&
            orderDetails?.mission?.sentToPilots &&
            orderDetails?.mission?.sentToPilots.length > 0 && (
              <InvitedPilotInterestTable />
            )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
          {(orderDetails?.mission?.status != missionStatus.unassigned ||
            orderDetails?.mission?.status != missionStatus.initialClientOrder ||
            orderDetails?.mission?.status != missionStatus.confirmedMission) &&
            orderDetails?.mission?.sentToPilots?.length > 0 && (
              <InvitedPilotsTable />
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default MissionPilotsTable;
