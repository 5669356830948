import React, { useState } from "react";
import Layout from "../../../../Layout";
import AdminClientEnterPriseMain from "./AdminClientEnterPriseMain";
// import theme from "../../../../theme";

const styles = {
  mTop: {
    marginTop: "30px",
    // borderBottom: `2px solid ${theme.palette.grey.dividersColor}`,
  },
};
const EnterpriseClientAdminLayout = () => {
  const [tab, setTab] = useState(0);

  return (
    <Layout
      pageTitle={tab === 0 ? "Enterprise clients" : "Clients"}
      hasSideMenu
      loading={false}
      className={styles.mTop}
    >
      <AdminClientEnterPriseMain tab={tab} setTab={setTab} />
    </Layout>
  );
};

export default EnterpriseClientAdminLayout;
