import { useEffect, useState } from "react";
import { getMissionListForProject } from "../services/project";
import firebase from "src/firebase";
import { useStateIfMounted } from "use-state-if-mounted";
import { isBrowser } from "src/services/auth";

const useGetMissionListForProject = (projectId) => {
  const [allMission, setAllMission] = useStateIfMounted([]);
  const [missions, setMissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPageNumber, setlastPageNumber] = useState(null);
  const [lastLoadedMissions, setLastLoadedMissions] = useState(null);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);
  const [triggerReload, setTriggerReload] = useState(false);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    try {
      setLoading(true);
      if (lastLoadedPage >= currentPage) {
        let allMissionRef = allMission;
        let prevPageMissions = allMissionRef.slice(
          (currentPage - 1) * limit,
          currentPage * limit
        );
        setMissions(prevPageMissions);
        setLoading(false);
      } else {
        getMissionListForProject(firebase, projectId, lastLoadedMissions, limit)
          .then((data) => {
            setMissions(data?.missions);
            setLastLoadedPage(currentPage);
            setLastLoadedMissions(data.lastLoaded);
            setAllMission([...allMission, ...data.missions]);
            if (data.isLast) {
              setlastPageNumber(currentPage);
              setIsLastPage(true);
            }
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      }
    } catch (err) {
      console.log("Error", err);
    }
  }, [triggerReload]);

  return {
    missions,
    setAllMission,
    loading,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    setIsLastPage,
    lastPageNumber,
    setlastPageNumber,
    setLastLoadedPage,
    setLastLoadedMissions,
    triggerReload,
    setTriggerReload,
  };
};

export default useGetMissionListForProject;
