import React from "react";
import Layout from "../../../Layout";
import OrderMainContainer from "./OrderMainContainer";
import { getUser } from "../../../services/auth";
import { userRoles, formTypeConstant } from "../../../lib/constants";

const CreateOrder = () => {
  const currentUser = getUser("user");

  const backNavUrl =
    currentUser?.role == userRoles.admin
      ? "/app/missions/client-order/active"
      : currentUser?.role == userRoles.client
      ? "/app/client/my-account"
      : "";
  return (
    <Layout
      backButtonPageName="Tasking a drone"
      backButton
      backNavUrl={backNavUrl}
    >
      <OrderMainContainer formType={formTypeConstant.create} />
    </Layout>
  );
};

export default CreateOrder;
