import React from "react";
import Layout from "../../../../Layout";
import CollabListPage from "./CollabListPage";
import { columnForClientCollaberatedOrders } from "../../../../lib/constants";
import { useGetCollaboratedOrderList } from "../../../../hooks/useGetCollaboratedOrderList";
const CollaborationList = () => {
  const [authorized] = useGetCollaboratedOrderList();

  return (
    <>
      <Layout pageTitle={"Shared data"} hasSideMenu addBottomPadding>
        <CollabListPage
          columns={columnForClientCollaberatedOrders}
          authorized={authorized}
        />
      </Layout>
    </>
  );
};
export default CollaborationList;
