import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Formik, Form } from "formik";
import { preventEnterSubmit } from "src/lib/form-helpers";
import * as Yup from "yup";
import AppButton from "../../../global/elements/AppButton";
import theme from "../../../../theme";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useStateIfMounted } from "use-state-if-mounted";
import PropTypes from "prop-types";
import {
  addCollaborator,
  getMissionCollaborations,
  removeCollaboration,
} from "../../../../services/missions";
import { useSnackbar } from "notistack";
import { getUser } from "../../../../services/auth";
import { sendCollabNotification } from "../../../../services/clients";
import { collaborationStatus } from "../../../../lib/constants";
import CollabCard from "./CollabCard";
import { shadowBox } from "../../../../lib/styleConstants";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";

const styles = {
  inputField: {
    width: "100%",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1em 2em",
  },
  avatarBox: {
    width: "18px",
    height: "18px",
    background: theme.palette.status.errorLight,
  },
  cursorCls: {
    cursor: "pointer",
  },
  dialogueHead: {
    color: theme.palette.black.dark,
    fontSize: "17px",
    fontWeight: 500,
  },
  formWrapper: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    columnGap: "12px",
    rowGap: "12px",
    width: "100%",
  },
  inputContainer: {
    flex: 2,
  },
  dialogContentCls: {
    alignSelf: "center",
    overflowY: "initial",
    width: "100%",
  },
  closeIconCls: { fontSize: "14px !important" },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1em",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: { sm: "space-between" },
    paddingTop: "14px",
  },
  copyLinkButton: {
    boxShadow: "unset",
    border: `1px solid ${theme.palette.black.sandyBlack}`,
    borderRadius: 0,
  },
  cardWrapper: {
    height: "125px",
    overflowY: "auto",
    marginTop: "8px",
  },
  dialogContent: {
    padding: { xs: "24px 10px", sm: "24px 22px" },
  },
  collabText: {
    fontWeight: 500,
    margin: "10px 0",
  },
  copyURLDesc: { paddingTop: "20px" },
};

const AddCollaboratorPopup = ({
  openPopup,
  setPopupOpen,
  missionId,
  clientId,
  mission,
  clientName,
}) => {
  const [loading, setLoading] = useState(false);
  const [allCollabs, setAllCollabs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [missionDownloadUrl, setMissionDownloadUrl] = useStateIfMounted(null);
  const [copied, setCopied] = useState(false);
  const user = getUser("user");
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    getMissionCollaborations({ missionId })
      .then((data) => {
        setAllCollabs(data);
        setRefresh(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    createLink();
  }, [refresh]);

  const createLink = () => {
    let missionName = "";
    let mapTypes = "";
    try {
      missionName = mission.missionName
        ? encodeURI(window.btoa(mission.missionName))
        : "";
      if (mission.mapTypes && mission.mapTypes.length) {
        mapTypes = encodeURI(window.btoa(mission.mapTypes.join()));
      }
    } catch (e) {
      setCopied(false);
      console.log("error in encoding mission name", e);
    }
    setMissionDownloadUrl(
      `${window.location.href.split("app/")[0]}downloads/${mission?.id}/${
        mission.accessToken
      }/${missionName}/${mapTypes}`
    );
  };
  const AddCollaboratorSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .test(
        "Unique Email",
        "You cannot add yourself as collaborator",
        function (value) {
          if (user.email === value) {
            return false;
          }
          return true;
        }
      ),
  });

  const initialValues = {
    email: "",
  };

  const addCollaboratorInMission = ({ email }) => {
    let obj = {
      emailId: email,
      missionId: missionId,
      clientId: clientId,
      accessToken: mission?.accessToken,
      missionName: mission?.missionName,
      clientName: clientName,
    };
    addCollaborator(obj)
      .then(async () => {
        setRefresh(true);
        await sendCollabNotification({
          notificationType: collaborationStatus.active,
          email,
          redirect: `/app/collaborator/order/${mission.id}`,
        });
        enqueueSnackbar("Collaboration record is created", {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar(e, { variant: "error" });
        setLoading(false);
      });
  };
  const removeCollaboratorInMission = ({ email, colloborationId }) => {
    let obj = {
      emailId: email,
      missionId: missionId,
      colloborationId: colloborationId,
    };
    removeCollaboration(obj)
      .then(async () => {
        setRefresh(true);
        await sendCollabNotification({
          notificationType: collaborationStatus.inactive,
          email,
          redirect: null,
        });
        enqueueSnackbar("Collaboration record is deleted", {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar(e, { variant: "error" });
        setLoading(false);
      });
  };

  const copyLink = () => {
    setCopied(true);
  };

  return (
    <Dialog
      open={openPopup}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <Box sx={styles.headerContainer}>
        <Typography sx={styles.dialogueHead}>
          Enter details to share data
        </Typography>
        <Box>
          <Avatar
            sx={
              !loading
                ? { ...styles.avatarBox, ...styles.cursorCls }
                : { ...styles.avatarBox }
            }
            onClick={() => setPopupOpen(false)}
          >
            <CloseOutlinedIcon color="white" sx={styles.closeIconCls} />
          </Avatar>
        </Box>
      </Box>
      <Box sx={shadowBox}></Box>

      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.contentContainer}>
          <Box sx={styles.dialogContentCls}>
            <Formik
              initialValues={initialValues}
              validationSchema={AddCollaboratorSchema}
              onSubmit={(values, { resetForm }) => {
                setLoading(true);
                addCollaboratorInMission({ email: values.email });
                resetForm({ email: "" });
              }}
            >
              {({ values, setFieldValue }) => (
                <Form id="fileSubmitForm" onKeyDown={preventEnterSubmit}>
                  <Box sx={styles.formWrapper}>
                    <Box sx={styles.inputContainer}>
                      <TextFieldInput
                        setFieldValue={setFieldValue}
                        values={values}
                        name="email"
                        placeholder="Enter Email Id"
                        disabled={false}
                        type="text"
                        required
                      />
                    </Box>
                    <AppButton
                      label="Send invitation"
                      type="submit"
                      look="green"
                      form="fileSubmitForm"
                      submittingState={loading}
                    />
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Box>
            <Box sx={{ display: allCollabs.length > 0 ? "block" : "none" }}>
              <Typography variant="h5" sx={styles.collabText}>
                People with access
              </Typography>
              <Box sx={styles.cardWrapper}>
                {allCollabs.length > 0 ? (
                  allCollabs.map((users, i) => {
                    return (
                      <CollabCard
                        data={users}
                        key={i}
                        removeCollaboratorInMission={
                          removeCollaboratorInMission
                        }
                      />
                    );
                  })
                ) : (
                  <Typography>No Data</Typography>
                )}
              </Box>
            </Box>
            <Typography sx={styles.copyURLDesc}>
              You can also copy this URL and share it with other people. This is
              not recommended as anybody with the URL can access and download
              the data
            </Typography>

            <Box sx={styles.buttonContainer}>
              <AppButton
                look="inverted"
                noIcon
                label="Close"
                onClick={() => setPopupOpen(false)}
              />
              <CopyToClipboard text={missionDownloadUrl}>
                <AppButton
                  look={"ButtonAsLink"}
                  startIcon={<InsertLinkIcon />}
                  label={copied ? "Copied" : "Copy URL"}
                  addtionalStyle={styles.copyLinkButton}
                  onClick={() => copyLink()}
                />
              </CopyToClipboard>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

AddCollaboratorPopup.propTypes = {
  openPopup: PropTypes.bool.isRequired,
  setPopupOpen: PropTypes.func.isRequired,
  missionId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  mission: PropTypes.object.isRequired,
  clientName: PropTypes.string,
};

AddCollaboratorPopup.defaultProps = {
  clientName: "Client",
};

export default AddCollaboratorPopup;
