import React, { useState } from "react";
import theme from "../../../theme";
import {
  Box,
  Autocomplete,
  Typography,
  Chip,
  Grid,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import LoadingEllipsis from "src/components/loading-ellipsis";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { preventEnterSubmit } from "src/lib/form-helpers";
import { getUser } from "src/services/auth";
import LineHeading from "../../global/elements/LineHeading";
import {
  createBankDetails,
  bankDetailsUpdate,
} from "../../../services/bank-details";
import countries from "src/lib/countries";
import { alternativeBankOption } from "../../../lib/constants";
import FormErrors from "../../global/forms/FormErrors";
import AppButton from "../../global/elements/AppButton";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import { Cancel, AddCircle } from "@mui/icons-material";
import { formInputField, submitCancelBox } from "../../../lib/styleConstants";
import { statusAlertSeverity } from "../../../lib/constants";
import { checkIfEUCountry } from "../../../lib/helpers";
import SwitchSelect from "../../global/elements/SwitchSelect";

const styles = {
  label_text: {
    marginBottom: "5px",
    color: theme.palette.grey.warmGrey,
    paddingLeft: "3px",
    fontSize: "13px",
  },
  addAltBankDetails: {
    display: "flex",
    cursor: "pointer",
    marginTop: "10px",
    marginLeft: "40px",
  },
  selectDivAltBank: {
    marginLeft: "70px",
    padding: "5px 10px",
    width: "fit-content",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
  },
  formErrorsAlert: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  switchRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  fieldWidth: {
    width: "100%",
  },
  gridContainer: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  cancelSoMeButton: {
    color: theme.palette.status.errorDark,
    marginLeft: "20px",
    "& svg": {
      width: "1.5em",
      height: "1.5em",
    },
  },
};

const BankForm = ({ bankDetails, bankId }) => {
  const userProfile = getUser("userProfile");

  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    status: false,
    message: "",
    type: "Success",
  });

  const [editMode, setEditMode] = useState(false);
  const [openAltBankDetails, setOpenAltBankDetails] = useState(false);
  const [alternativeOpt, setAlternativeOpt] =
    bankDetails?.alternative_payments_methods &&
    bankDetails?.alternative_payments_methods?.length
      ? useState(bankDetails.alternative_payments_methods)
      : useState([]);

  const formSubmitHandler = (values) => {
    setLoading(true);
    setOpenAltBankDetails(false);
    const pilotId = getUser("userProfile")?.id || "";
    values["pilotId"] = pilotId;
    values["pilotName"] = getUser("userProfile")?.name || "";
    values["alternative_payments_methods"] =
      createAlternativeBankdetails(values);
    if (!bankDetails) {
      createBankDetails(values)
        .then((status) => {
          if (status.status == "Success") {
            setStatusMessage({
              status: true,
              message: "Bank details added successfully!",
              type: "Success",
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("ERROR :- ", err);
        });
    } else {
      bankDetailsUpdate(values, bankId).then((status) => {
        if (status.status == "Success") {
          setStatusMessage({
            status: true,
            message: "Bank details updated successfully!",
            type: "Success",
          });
          setLoading(false);
        }
      });
    }
  };

  const formInitialValues = {
    company_name: bankDetails?.company_name || "",
    businessAccount: bankDetails?.businessAccount || false,
    accountHolderName: bankDetails?.accountHolderName || "",
    companyCity: bankDetails?.companyCity || "",
    companyPostalCode: bankDetails?.companyPostalCode || "",
    companyAddress: bankDetails?.companyAddress || "",
    registration_number: bankDetails?.registration_number || "",
    email: bankDetails?.email || "",
    country: bankDetails?.country || "",
    account_number: bankDetails?.account_number || "",
    account_type: bankDetails?.account_type || "",
    SWIFT: bankDetails?.SWIFT || "",
    IBAN: bankDetails?.IBAN || "",
    routing_number: bankDetails?.routing_number || "",
    bank_name: bankDetails?.bank_name || "",
    bank_address: bankDetails?.bank_address || "",
    bank_postal_address: bankDetails?.bank_postal_address || "",
    vat_number: bankDetails?.vat_number || "",
    isEUCountry: checkIfEUCountry() || false,
  };
  if (bankDetails?.alternative_payments_methods?.length) {
    bankDetails.alternative_payments_methods.forEach((data) => {
      if (data.label && data.id) {
        formInitialValues[data.label] = data.id;
      }
    });
  }

  const validationSchema = Yup.object().shape({
    company_name: Yup.string(),
    businessAccount: Yup.bool(),
    accountHolderName: Yup.string().required(
      "Account Holder's name is required"
    ),
    companyCity: Yup.string(),
    companyPostalCode: Yup.string(),
    companyAddress: Yup.string(),
    registration_number: Yup.string(),
    email: Yup.string(),
    country: Yup.string().required("Country is required"),
    account_number: Yup.string().required("Account number is required"),
    account_type: Yup.string().required("Account type is required"),
    SWIFT: Yup.string().required("SWIFT code is required"),
    IBAN: Yup.string().required(
      "IBAN is required. Please write N/A if it is not applicable in your country"
    ),
    routing_number: Yup.string().required(
      "Routing number is required. Please write N/A if it is not applicable in your country"
    ),
    bank_name: Yup.string().required("Bank name is required"),
    bank_address: Yup.string().required("Bank address is required"),
    bank_postal_address: Yup.string().required(
      "Bank postal address is required"
    ),
    isEUCountry: Yup.bool(),
    vat_number: Yup.string().when("isEUCountry", {
      is: true,
      then: Yup.string().required("VAT number is required"),
      otherwise: Yup.string().nullable(),
    }),
  });

  const renderChips = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip key={index} label={option} {...getTagProps({ index })} />
    ));

  const createField = (option) => {
    let value = alternativeOpt.find((opt) => opt.label == option.value);
    if (!value) {
      let obj = {
        label: option.value,
        id: "",
      };
      setAlternativeOpt([...alternativeOpt, obj]);
    }
    return;
  };

  const createAlternativeBankdetails = (values) => {
    let alternativeBankDetails = [];

    alternativeBankOption.forEach((opt) => {
      if (values[opt.value]) {
        alternativeBankDetails.push({
          label: opt.value,
          id: values[opt.value],
        });
      }
    });

    return alternativeBankDetails;
  };

  const getLabelNameFromValue = (val) => {
    let option = alternativeBankOption.find((opt) => opt.value === val);
    return option && option.label ? option.label : "";
  };

  const onEdit = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
  };

  const onCancel = () => {
    setOpenAltBankDetails(false);
    setEditMode(false);
  };

  const onRemoveAltBank = (option) => {
    let index = alternativeOpt.findIndex((opt) => opt.label === option.label);
    if (index > -1) {
      let copyArr = [...alternativeOpt];
      copyArr.splice(index, 1);
      setAlternativeOpt(copyArr);
    }
  };

  return (
    <>
      {statusMessage.status && (
        <Box sx={{ marginTop: "20px" }}>
          <StatusAlertContainer
            severity={statusAlertSeverity.success}
            heading="Bank Details Updated Successfully! These updates will now be seen in your future invoices"
          />
        </Box>
      )}
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, setFieldValue, errors, submitCount }) => {
          return (
            <Form onKeyDown={preventEnterSubmit} id="bankForm">
              <Grid container spacing={2}>
                <Grid item xs={12} sx={styles.gridContainer}>
                  <Box sx={styles.fieldWidth}>
                    <Typography variant="h5" style={styles.label_text}>
                      Is this a business account?
                    </Typography>
                    <Box sx={styles.switchRow}>
                      {editMode ? (
                        <SwitchSelect
                          name="businessAccount"
                          handleChange={(e, value) =>
                            setFieldValue(
                              "businessAccount",
                              value ? true : false
                            )
                          }
                          checked={values.businessAccount}
                        />
                      ) : (
                        <SwitchSelect
                          name="businessAccount"
                          disabled
                          checked={values.businessAccount}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name="company_name"
                      component={TextField}
                      label="Company Name"
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="Account holder name"
                      name="accountHolderName"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="Registration Number"
                      name="registration_number"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="Company Email Address"
                      name="email"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="Company Address"
                      name="companyAddress"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label=" Postal Code"
                      name="companyPostalCode"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="City"
                      name="companyCity"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={countries}
                    onChange={(e, value) => setFieldValue("country", value)}
                    defaultValue={values.country}
                    renderTags={renderChips}
                    sx={formInputField}
                    variant="outlined"
                    disabled={!editMode}
                    renderInput={(params) => (
                      <Field
                        {...params}
                        type="text"
                        label="Country"
                        name="location"
                        component={TextField}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {checkIfEUCountry() && (
                    <Box sx={formInputField}>
                      <Field
                        type="text"
                        variant="outlined"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        label="VAT Number"
                        name="vat_number"
                        component={TextField}
                        disabled={!editMode}
                      />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sx={styles.gridContainer}>
                  <LineHeading title="Bank  Details" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name="account_number"
                      label="Account Number"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="Account Type"
                      name="account_type"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="SWIFT code"
                      name="SWIFT"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name="IBAN"
                      label="IBAN"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="Routing Number"
                      name="routing_number"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="Bank Name"
                      name="bank_name"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="Bank Address"
                      name="bank_address"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={formInputField}>
                    <Field
                      type="text"
                      variant="outlined"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label="Bank Postal Address"
                      name="bank_postal_address"
                      component={TextField}
                      disabled={!editMode}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sx={styles.gridContainer}>
                  <LineHeading title="Alternative payment method" />
                </Grid>
                {alternativeOpt &&
                  alternativeOpt.length > 0 &&
                  alternativeOpt.map((opt) => {
                    return (
                      <>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            sx={styles.cancelSoMeButton}
                            disabled={!editMode}
                          >
                            <Cancel
                              fontSize="inherit"
                              onClick={() => {
                                setFieldValue(opt.label, "");
                                onRemoveAltBank(opt);
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Box sx={formInputField}>
                            <Field
                              type="text"
                              variant="outlined"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              name="alt_payment_method"
                              component={TextField}
                              value={getLabelNameFromValue(opt.label)}
                              disabled={true}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box sx={formInputField}>
                            <Field
                              type="email"
                              variant="outlined"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              label={`${getLabelNameFromValue(
                                opt.label
                              )} Email`}
                              name={opt.label}
                              component={TextField}
                              disabled={!editMode}
                            />
                          </Box>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>

              <br />

              {editMode && (
                <Box
                  onClick={() =>
                    editMode && setOpenAltBankDetails(!openAltBankDetails)
                  }
                  sx={styles.addAltBankDetails}
                  disabled={!editMode}
                >
                  <AddCircle color="secondary" sx={{ marginRight: "10px" }} />
                  <Typography sx={{ color: theme.palette.black.darkSeaBlack }}>
                    Add alternative payment options
                  </Typography>
                </Box>
              )}

              {openAltBankDetails && editMode && (
                <Box sx={styles.selectDivAltBank}>
                  {alternativeBankOption.map((option, i) => {
                    return (
                      <Typography
                        sx={{
                          padding: "3px",
                          color: theme.palette.grey.warmGrey,
                          cursor: "pointer",
                        }}
                        onClick={() => createField(option)}
                        key={i}
                        variant="h5"
                      >
                        {option.label}
                      </Typography>
                    );
                  })}
                </Box>
              )}

              <br />
              <br />
              <Box sx={submitCancelBox}>
                {editMode && (
                  <AppButton
                    onClick={onCancel}
                    label="Back"
                    noIcon
                    look="inverted"
                    addtionalStyle={styles.buttonStyle}
                  />
                )}
                {editMode ? (
                  <AppButton
                    type="submit"
                    form="bankForm"
                    look="black"
                    label={
                      loading ? (
                        <LoadingEllipsis />
                      ) : bankDetails ? (
                        "Update"
                      ) : (
                        "Submit"
                      )
                    }
                  />
                ) : (
                  <AppButton
                    onClick={onEdit}
                    label="Edit"
                    look="black"
                    addtionalStyle={{
                      float: "right",
                    }}
                  />
                )}
              </Box>
              <br />
              <br />
              <br />
              <FormErrors
                style={styles.formErrorsAlert}
                show={submitCount > 0}
                errors={Object.values(errors)}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

BankForm.propTypes = {
  bankDetails: PropTypes.shape({
    company_name: PropTypes.string,
    registration_number: PropTypes.string,
    email: PropTypes.string,
    country: PropTypes.string,
    account_number: PropTypes.string,
    accountHolderName: PropTypes.string,
    companyAddress: PropTypes.string,
    companyCity: PropTypes.string,
    companyPostalCode: PropTypes.string,
    account_type: PropTypes.string,
    SWIFT: PropTypes.string,
    IBAN: PropTypes.string,
    routing_number: PropTypes.string,
    bank_name: PropTypes.string,
    bank_address: PropTypes.string,
    bank_postal_address: PropTypes.string,
    status: PropTypes.string,
    declinedByPilots: PropTypes.string,
    sentToPilots: PropTypes.string,
    deadline: PropTypes.string,
    alternative_payments_methods: PropTypes.string,
    businessAccount: PropTypes.bool,
  }),
  bankId: PropTypes.string,
};

BankForm.defaultProps = {
  bankDetails: undefined,
  bankId: undefined,
};

export default BankForm;
