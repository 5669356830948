import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback } from "react";
import MissionContext from "./MissionContext";
import { userRoles } from "../../../../../lib/constants";
import TextStructures from "../../../../global/elements/TypographyElements/TextStructures";
import PropTypes from "prop-types";
import AppButton from "../../../../global/elements/AppButton";
import Tips from "../../../../global/elements/Tips";

const styles = {
  infoBox: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
  },
  text: {},
  clientBtn: {
    marginBottom: "10px",
  },
};
const InfoBox = ({ title, label }) => {
  return (
    <Box sx={styles.infoBox}>
      <Typography variant="subtitle1Med">{title}</Typography>
      <Typography variant="subtitle1" ml={2}>
        {label}
      </Typography>
    </Box>
  );
};
InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const ClientInfo = () => {
  const data = useContext(MissionContext);
  const [clientInfo, setClientInfo] = useState(null);
  const [apiRequestClientName, setApiRequestClientName] = useState(null);
  const [apiRequestClientEmail, setApiRequestClientEmail] = useState(null);

  const [isApi, setIsApi] = useState(false);

  const getClientData = useCallback(() => {
    if (data?.currentUser?.role === userRoles.client) {
      setClientInfo(data?.currentUser);
    } else if (data?.currentUser?.role === userRoles.admin) {
      setClientInfo(data?.clientInfo);
      setApiRequestClientName(data?.mission?.clientName);
      setApiRequestClientEmail(data?.mission?.clientEmail);
      if (data?.mission?.clientApiKey) {
        setIsApi(true);
      } else {
        setIsApi(false);
      }
    }
  }, [
    data?.currentUser,
    data?.clientInfo,
    data?.mission?.clientName,
    data?.mission?.clientEmail,
    data?.mission?.clientApiKey,
  ]);

  useEffect(() => {
    getClientData();
  }, [getClientData]);

  return (
    <Box>
      <TextStructures
        text="Client information"
        customVariant="h3"
        marginBottom
        //---------We should add the edit functionality once admins are able to edit client profiles
        // edit
        // editUrl={"/app/client/setting/profile/edit"}
      />

      <Grid container>
        {isApi && (
          <Grid item xs={12} sx={{ marginBottom: "20px" }}>
            {console.log(
              apiRequestClientName,
              clientInfo?.name,
              apiRequestClientEmail,
              clientInfo?.email
            )}
            {apiRequestClientName != clientInfo?.name ||
            apiRequestClientEmail != clientInfo?.email ? (
              <Tips
                content="This order has been placed through an API. The owner of the API key is written below but in the request the client is:"
                subContent={`${apiRequestClientName}: ${apiRequestClientEmail}`}
                isAPI
              />
            ) : (
              <Tips
                content="This order has been placed through an API. "
                isAPI
              />
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <InfoBox title="Name:" label={clientInfo?.name} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBox title="Company:" label={clientInfo?.companyName} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} mb={2}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <InfoBox title="Email:" label={clientInfo?.email} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBox title="Phone number:" label={clientInfo?.phone} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} mb={2}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <InfoBox
                title="GLOBHE representative:"
                label={data?.mission?.missionOwnerName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InfoBox
                title="GLOBHE contact email:"
                label={data?.mission?.missionOwner}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} mb={2}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={styles.clientBtn}>
          <AppButton
            label="Check client page"
            look="blue"
            internalLink
            url={`/app/admin/manage-client/${data?.clientInfo?.id}`}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientInfo;
