import { TYPES } from "../types";

const getProjectDetail = (projectId) => {
  return {
    type: TYPES.GET_PROJECT_DETAIL,
    payload: {
      projectId,
    },
  };
};

const getProjectDetailSuccess = ({ data }) => ({
  type: TYPES.GET_PROJECT_DETAIL_SUCCESS,
  payload: {
    data,
  },
});

const getProjectDetailError = () => {
  return { type: TYPES.GET_PROJECT_DETAIL_ERROR };
};

export { getProjectDetail, getProjectDetailSuccess, getProjectDetailError };
