import { Grid, Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import {
  missionMapTypes,
  missionStatus,
  userRoles,
  users,
} from "../../../../lib/constants";
import {
  fetchMissionsForDataLibrary,
  getDeliveryPackagesFiles,
} from "../../../../services/missions";
import firebase from "src/firebase";
import {
  displayLabel,
  generateFeatureObj,
  getPriceAccordingToZone,
  getZoneFromLocation,
} from "../../../../lib/helpers";
import DataLibraryMap from "./DataLibraryMap";
import { getUser } from "src/services/auth";
import { useSelector, useDispatch } from "react-redux";
import Dialogue from "../../../global/Dialogue";
import { addAllToCartAction } from "../../../../redux/actions/cart-detail-action";
import { useSnackbar } from "notistack";
import GuestStatusAlert from "../../AnonymousUser/GuestStatusAlert";
import SearchOptionsDataLib from "./Components/SearchOptionsDataLib";
import ChooseProducts from "../../CreateOrder/Components/ChooseProducts";
import AddToCartComp from "./Components/AddToCartComp";
import ChipWithCrossIcon from "../../../global/elements/ChipWithCrossIcon";

const styles = {
  chipStyle: {
    direction: "rtl",
    borderRadius: 0,
    paddingRight: "12px",
  },
  rootStyle: { minHeight: "90vh", marginBpttom: "40px" },
  cardContentBox: {
    padding: "14px",
  },

  mapClass: {
    height: { xs: "400px", sm: "400px", md: "600px" },
    marginTop: { xs: "0px", md: "45px" },
  },
  gridContainer: {
    marginBottom: "20px",
  },
  dialogBody: { padding: "1em 0" },
  adjustedPadding: { padding: "30px 20px 30px 0px !important" },
};

const ShowDataLibrary = () => {
  const [totalMissions, setTotalMission] = useState([]);
  const [missionToShow, setMissionsToShow] = useState([]);
  const [countryFilter, setCountryFilter] = useState([]);
  const [mapTypesFilter, setMapTypesFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [missionNameSearch, setMissionNameSearch] = useState(null);
  const [triggerReload, setTriggerReload] = useState(false);
  const [mapMissions, setMapMissions] = useState([]);
  // const [freeMissions, setFreeMissions] = useState([]);
  const [showDialogue, setShowDialogue] = useState(false);
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { CartReducer } = useSelector((state) => state);

  const categoryDataFilter = (allSelectedCat, dataCats) => {
    let data = dataCats?.filter((dataCat) =>
      allSelectedCat.includes(dataCat.label)
    );
    return data?.length > 0;
  };

  const stringHasAll = (locStr, countryFilters) => {
    let checkAvailable = countryFilters.filter((cntr) => locStr === cntr);
    return checkAvailable?.length > 0;
  };

  const nameFilter = (msnNameArr, dataToSearch) => {
    let checkAvailable = msnNameArr?.filter(
      (msnName) => msnName === dataToSearch.toLowerCase()
    );
    return checkAvailable?.length > 0;
  };

  const mapTypesFilterFcn = (allSelectedMaptypes, dataMapTypes) => {
    const allSearchFilterField = [];
    allSelectedMaptypes.forEach((mapTyp) => {
      let currentMapType = missionMapTypes.filter(
        (missionMapTyp) => missionMapTyp.label === mapTyp
      );
      allSearchFilterField.push(currentMapType[0]?.label);
      allSearchFilterField.push(currentMapType[0].value);
    });
    let data = dataMapTypes?.filter((dataCat) =>
      allSearchFilterField?.includes(dataCat)
    );
    return data?.length > 0;
  };

  const filterDataFromSearch = () => {
    let currentDatas = totalMissions;
    if (
      countryFilter?.length > 0 ||
      mapTypesFilter?.length > 0 ||
      categoryFilter?.length > 0 ||
      missionNameSearch
    ) {
      if (missionNameSearch) {
        currentDatas = currentDatas.filter((currentData) =>
          nameFilter(currentData.searchMissionName, missionNameSearch)
        );
      }
      if (countryFilter?.length > 0) {
        currentDatas = currentDatas.filter(
          (currentData) =>
            // countryFilter.filter((q) =>
            stringHasAll(currentData?.location, countryFilter)
          // )
        );
      }
      if (categoryFilter?.length > 0) {
        currentDatas = currentDatas.filter((currentData) =>
          categoryDataFilter(
            categoryFilter,
            currentData?.dataLibrary?.categories
          )
        );
      }
      if (mapTypesFilter?.length > 0) {
        currentDatas = currentDatas.filter((currentData) =>
          mapTypesFilterFcn(mapTypesFilter, currentData?.mapTypes)
        );
      }

      setMissionsToShow(currentDatas);
      setMapMissionData(currentDatas);
    } else {
      setMissionsToShow(totalMissions);
      setMapMissionData(totalMissions);
      return;
    }
  };

  useEffect(() => {
    filterDataFromSearch();
  }, [triggerReload]);

  const user = getUser(users.user);
  const userInfo = getUser(users.userProfile);

  // buy all option to be added
  useEffect(() => {
    if (user?.isAnonymous) {
      setRole(user.role);
    } else {
      setRole(userInfo.role);
    }
    fetchMissions();
  }, []);

  // useEffect(() => {
  //   fetchFreeMission();
  // }, []);

  // const fetchFreeMission = async () => {
  //   const getData = await getFreeMissionForDataLibrary();
  //   if (getData.status === "Success") {
  //     setFreeMissions(getData.data || []);
  //   }
  // };

  const DialogueBody = () => {
    return (
      <Box sx={styles.dialogBody}>
        <Typography variant="body1">
          Are you sure you want to add all these datasets to your cart?
        </Typography>
      </Box>
    );
  };

  const checkClientIsMissionOwner = (data) => {
    if (data.clientId == user.userID) {
      return true; // Client has created this mission.
    } else if (data.dataPurchasedBy?.includes(user.userID)) {
      return true; // Client has already purchased this mission.
    } else if (
      userInfo?.enterPriseId &&
      data?.dataPurchasedByEnterPrise?.includes(userInfo.enterPriseId)
    ) {
      return true; // This is an enterprise client, and enterprise already purchased this mission.
    } else if (
      userInfo?.enterPriseId &&
      data?.enterPriseId == userInfo.enterPriseId
    ) {
      return true; // This is an enterprise client, and this mission belongs to this user's enterprise.
    } else {
      false;
    }
  };

  const addAllToCart = async () => {
    setShowSubmitLoader(true);
    if (missionToShow.length) {
      new Promise((resolve) => {
        let FilteredData = missionToShow.filter((data) => {
          return (
            !CartReducer.carts.find((cart) => data.id === cart.id) &&
            // !data.dataPurchasedBy?.includes(user.userID) &&
            !data.notShowInDataLibrary &&
            // data.clientId !== user.userID &&
            // data?.enterPriseId !== userInfo?.enterPriseId &&
            // !data?.dataPurchasedByEnterPrise?.includes(
            //   userInfo?.enterPriseId
            // ) &&
            data.locationMap &&
            !checkClientIsMissionOwner(data)
          );
        });
        if (FilteredData.length) {
          var promises = FilteredData.map(async (mission) => {
            let missionData = {
              ...mission,
            };
            if (mission) {
              let data = await Promise.all([
                getZoneFromLocation({ mission }),
                await getDeliveryPackagesFiles({
                  missionId: mission.id,
                }),
              ]);
              // missionData.filesSize = data[1].totalSize;
              missionData.fileSizeMb = data[1]?.fileSizeMb;
              missionData.fileSizeByte = data[1]?.fileSizeByte;
              missionData.fileSizeString = data[1]?.fileSizeString;
              missionData.orderPrice = getPriceAccordingToZone({
                fileSize: data[1]?.fileSizeMb,
                zone: data[0],
              });
            }
            return missionData;
          });
          return Promise.all(promises).then((data) => {
            resolve(data);
          });
        } else {
          enqueueSnackbar(
            "Either you have already purchased or they are already in cart",
            {
              variant: "error",
            }
          );
          setShowDialogue(false);
          setShowSubmitLoader(false);
          resolve();
        }
      }).then((AllMissionData) => {
        if (AllMissionData && AllMissionData.length) {
          dispatch(addAllToCartAction(AllMissionData));
          setShowSubmitLoader(false);
          enqueueSnackbar("All orders are successfully added to your cart", {
            variant: "success",
          });
        }
        setShowDialogue(false);
      });
    } else {
      enqueueSnackbar("No data available", {
        variant: "error",
      });
      setShowSubmitLoader(false);
      setShowDialogue(false);
    }
  };

  const fetchMissions = () => {
    if (user?.isAnonymous) {
      setMapMissionData([]);
      return;
    }
    fetchMissionsForDataLibrary(firebase, { status: missionStatus.completed })
      .then((data) => {
        let filtered = data.filter(
          (item) =>
            !item.allowEmptyData &&
            item.locationMap &&
            !item.notShowInDataLibrary
        );
        setTotalMission(filtered);
        setMissionsToShow(filtered);
        setMapMissionData(filtered);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const setMapMissionData = (missions) => {
    let featuresArray = [];
    missions.map((missionData) => {
      if (missionData.locationMap && !missionData.notShowInDataLibrary) {
        const featureObj = generateFeatureObj(missionData);
        featuresArray.push({ ...featureObj, missionData: missionData });
      }
    });
    setMapMissions({ features: featuresArray, type: "FeatureCollection" });
  };

  const crossChipClicked = (
    selectedKeyword,
    clickedField,
    values,
    setFieldValue,
    filterToUpdate
  ) => {
    setFieldValue(
      clickedField,
      values[clickedField]?.filter((val) => val !== selectedKeyword)
    );
    filterToUpdate(
      values[clickedField]?.filter((val) => val !== selectedKeyword)
    );
    setTriggerReload(!triggerReload);
  };

  return (
    <Box sx={styles.rootStyle}>
      {showDialogue && (
        <Dialogue
          showDialogue={showDialogue}
          setShowDialogue={setShowDialogue}
          DialogueHeadText={"Add all data to cart"}
          DialogueBody={DialogueBody()}
          showCancelButton={true}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Yes"}
          showSubmitLoader={showSubmitLoader}
          setShowSubmitLoader={setShowSubmitLoader}
          onSubmit={() => addAllToCart()}
        />
      )}
      <Formik
        initialValues={{
          productTypes: [],
          countries: [],
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form id="form1">
              <Grid container spacing={2} sx={styles.gridContainer}>
                {user?.isAnonymous && (
                  <Grid item xs={12}>
                    <GuestStatusAlert statusMessage="To see our extensive data library, please sign-in to to your profile" />
                  </Grid>
                )}
                <Grid item xs={12} mt={5}>
                  <SearchOptionsDataLib
                    setFieldValue={setFieldValue}
                    values={values}
                    setCountryFilter={setCountryFilter}
                    setCategoryFilter={setCategoryFilter}
                    setMissionNameSearch={setMissionNameSearch}
                    triggerReload={triggerReload}
                    setTriggerReload={setTriggerReload}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Grid container xs={12} spacing={1}>
                    {values?.productTypes?.length > 0 &&
                      values?.productTypes?.map((selectedKeyword, i) => {
                        return (
                          <Grid
                            key={"productTypes" + i}
                            item
                            xs={4}
                            sm={3}
                            md={2.4}
                            lg={1.4}
                          >
                            <ChipWithCrossIcon
                              keyword={selectedKeyword}
                              crossClick={() =>
                                crossChipClicked(
                                  selectedKeyword,
                                  "productTypes",
                                  values,
                                  setFieldValue,
                                  setCategoryFilter
                                )
                              }
                            />
                          </Grid>
                        );
                      })}
                    {values?.countries?.length > 0 &&
                      values?.countries?.map((selectedKeyword, i) => {
                        return (
                          <Grid
                            key={"countries" + i}
                            item
                            xs={4}
                            sm={3}
                            md={2.4}
                            lg={1.5}
                          >
                            <ChipWithCrossIcon
                              keyword={selectedKeyword}
                              crossClick={() =>
                                crossChipClicked(
                                  selectedKeyword,
                                  "countries",
                                  values,
                                  setFieldValue,
                                  setCountryFilter
                                )
                              }
                            />
                          </Grid>
                        );
                      })}
                    {values?.mapTypes?.length > 0 &&
                      values?.mapTypes?.map((selectedKeyword, i) => {
                        return (
                          <Grid
                            key={"mapTypes" + i}
                            item
                            xs={4}
                            sm={3}
                            md={2.5}
                            lg={2}
                          >
                            <ChipWithCrossIcon
                              keyword={displayLabel(selectedKeyword)}
                              crossClick={() =>
                                crossChipClicked(
                                  selectedKeyword,
                                  "mapTypes",
                                  values,
                                  setFieldValue,
                                  setMapTypesFilter
                                )
                              }
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Grid container>
                    <Grid item xs={12} md={5}>
                      <ChooseProducts
                        mapTypes={values.mapTypes}
                        setFieldValue={setFieldValue}
                        hideJpg
                        colMdSpacing={4}
                        colXsSpacing={6}
                        setMapTypesFilter={setMapTypesFilter}
                        triggerReload={triggerReload}
                        setTriggerReload={setTriggerReload}
                        additionalStyle={styles.adjustedPadding}
                      />
                    </Grid>
                    <Grid item xs={12} md={7} sx={styles.mapClass}>
                      <DataLibraryMap
                        geoJson={mapMissions}
                        role={role}
                        user={user}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                  {user?.role != userRoles.pilot && (
                    <AddToCartComp
                      user={user}
                      setShowDialogue={setShowDialogue}
                      totalDataCount={mapMissions?.features?.length}
                    />
                  )}
                </Grid>

                {/* </Box> */}
              </Grid>
            </Form>
          );
        }}
      </Formik>

      {/* <Typography sx={styles.headingFreeDownload} variant="h6">
        USE CASES: FREE DOWNLOADS
      </Typography>
      <Grid container sx={styles.freeDataBox}>
        {freeMissions?.map((freemission, i) => {
          return (
            <Grid
              key={i}
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={2}
              spacing={1}
              sx={styles.mediaWidth}
            >
              {!freemission.allowEmptyData && <MediaCard data={freemission} />}
            </Grid>
          );
        })}
      </Grid> */}

      {/* <Box sx={{ marginTop: "30px" }}>
          <FreeDownloadUseCase />
        </Box> */}
    </Box>
  );
};

export default ShowDataLibrary;
