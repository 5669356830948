import React from "react";
import { projectStatus } from "../../../../lib/constants";
import ProjectTable from "./ProjectTable";

const CompletedProject = () => {
  const status = projectStatus.completed;
  return <ProjectTable status={status} pageTitle={"Completed"} />;
};

export default CompletedProject;
