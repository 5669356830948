import React from "react";
import { useGetApiKeyList } from "../../../../../hooks/useGetApiKeyList";
import { missionStatus } from "../../../../../lib/constants";
import { getUser } from "../../../../../services/auth";
import NoMissionsMessage from "../../../missions/shared/NoMissionsMessage";
import ApiKeysTable from "./ApiKeysTable";
import Layout from "../../../../../Layout";
import { Box } from "@mui/material";
import UniversalLoader from "../../../../global/elements/UniversalLoader";

const style = {
  root: {
    position: "relative",
    padding: { xs: "31px 0 26px 0", lg: "55px 0 61px 0" },
  },
  loaderDiv: {
    paddingTop: "18%",
  },
};

const ApiKeysPage = () => {
  const status = missionStatus.active;
  const apiKeySet = useGetApiKeyList();
  const currentUser = getUser("user");

  if (
    apiKeySet.apiKeys.length !== 0 ||
    apiKeySet.isLoading === true ||
    currentUser.role === "admin"
  ) {
    return (
      <Layout
        pageTitle={"API Keys"}
        hasSideMenu
        loading={apiKeySet.apiKeys.length === 0 && apiKeySet.isLoading === true}
      >
        {apiKeySet.apiKeys.length === 0 && apiKeySet.isLoading === true ? (
          <Box sx={style.loaderDiv}>
            <UniversalLoader />
          </Box>
        ) : (
          <Box sx={style.root}>
            <ApiKeysTable
              status={status}
              keys={apiKeySet.apiKeys}
              loadMoreKeysHandler={apiKeySet.loadApiKeys}
              loading={apiKeySet.isLoading}
              hideLoadMoreButton={
                !apiKeySet.hasMoreApiKeys || apiKeySet.isLoading
              }
              apiKeySet={apiKeySet}
            />
          </Box>
        )}
      </Layout>
    );
  } else {
    return (
      <Layout pageTitle={"API Keys"} hasSideMenu>
        <NoMissionsMessage
          title="No API Keys requested :("
          description="Currently there are no requested API Keys."
          actionButton={null}
        />
      </Layout>
    );
  }
};

export default ApiKeysPage;
