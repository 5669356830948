import React from "react";
import ProjectMissionsTable from "./ProjectMissionsTable";
import Layout from "../../../../Layout";
const ProjectSites = () => {
  return (
    <Layout pageTitle={"Project Sites"} loading={false} hasSideMenu={true}>
      <ProjectMissionsTable />
    </Layout>
  );
};

export default ProjectSites;
