import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getAllTeamMemberOfEnterprise } from "../services/client";

// const dataSet = [
//   // {
//   //   name: "Client 1",
//   //   email: "client1@globhe.com",
//   //   id: "zfHUKkqNGlRSVzBQ8cRokiWrCUP2",
//   //   location: "India",
//   //   team: ["marketing", "sales"],
//   //   roleType: "admin",
//   //   profileUrl: "https://freepngimg.com/download/man/22654-6-man.png",
//   //   viewUrl: "/app/client/team/member/zfHUKkqNGlRSVzBQ8cRokiWrCUP2",
//   // },
//   // {
//   //   name: "Client 2",
//   //   email: "client2@globhe.com",
//   //   id: "zfHUKkqNGlRSVzBQ8cRokiWrCUP",
//   //   location: "India",
//   //   team: ["marketing"],
//   //   roleType: "member",
//   //   profileUrl: "https://freepngimg.com/download/man/22654-6-man.png",
//   //   viewUrl: "/app/client/team/member/zfHUKkqNGlRSVzBQ8cRokiWrCUP2",
//   // },
//   // {
//   //   name: "Client 3",
//   //   email: "client3@globhe.com",
//   //   id: "zfHUKkqNGlRSVzBQ8cRokiWrCU",
//   //   location: "India",
//   //   team: ["sales"],
//   //   roleType: "member",
//   //   profileUrl: "https://freepngimg.com/download/man/22654-6-man.png",
//   //   viewUrl: "/app/client/team/member/zfHUKkqNGlRSVzBQ8cRokiWrCUP2",
//   // },
// ];

const useGetAllTeamMembers = (enterpriseId) => {
  const [teamMembers, setTeamMembers] = useStateIfMounted([]);
  const [allMembers, setAllMembers] = useStateIfMounted([]);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);
  const [lastLoadedMembers, setLastLoadedMembers] = useStateIfMounted(null);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [dataLoadedTill, setDataLoadedTill] = useStateIfMounted(null);
  const [isLastPage, setIslastPage] = useStateIfMounted(false);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [limit, setLimit] = useStateIfMounted(10);
  const [filter, setFilter] = useStateIfMounted({
    nameEmail: "",
  });
  const [sort, setSort] = useStateIfMounted({
    sortBy: "dateCreated",
    sortValue: "desc",
  });

  useEffect(() => {
    if (!firebase || !isBrowser() || !enterpriseId) return;
    try {
      setLoading(true);
      if (dataLoadedTill >= currentPage) {
        let prevPageMissions = allMembers.slice(
          (currentPage - 1) * limit,
          currentPage * limit
        );
        setTeamMembers(prevPageMissions);
        setLoading(false);
      } else {
        getAllTeamMemberOfEnterprise(
          enterpriseId,
          filter,
          lastLoadedMembers,
          limit
        ).then((data) => {
          setTeamMembers(data.teamMembers);
          setDataLoadedTill(currentPage);
          setLastLoadedMembers(data.lastVisible);
          setAllMembers([...allMembers, ...data.teamMembers]);
          if (data.last) {
            setLastPageNumber(currentPage);
            setIslastPage(true);
          }
          setLoading(false);
        });
      }
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  }, [triggerReload]);

  return {
    teamMembers,
    allMembers,
    setAllMembers,
    setTeamMembers,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    sort,
    setSort,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedMembers,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  };
};

export default useGetAllTeamMembers;
