import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../../theme";
import { columnsForFeedbacks } from "../../../../src/lib/constants";
import GlobalTable from "../admin/GlobalTable";
import { navigate } from "gatsby";
import { Box } from "@mui/material";
import Rating from "@mui/material/Rating";

const styles = {
  column: {
    float: "left",
    clear: "none",
    display: "flex",
    alignItems: "flex-start",
    padding: "0 15px",
    marginBottom: "1rem",
  },
  bold: {
    fontWeight: "500",
    fontSize: "1rem",
  },
  row: {
    position: "relative",
    width: "100%",
    display: "flex",
  },
  loadMore: {
    textAlign: "center",
  },
  loadMoreRow: {
    position: "relative",
    maxWidth: "100%",
  },
  countBadge: {
    float: "left",
    background: theme.palette.primary.pistachio,
    borderRadius: "50%",
    color: theme.palette.grey.white,
    textAlign: "center",
    padding: "5px 8.5px",
    fontWeight: "500",
    fontSize: "0.6rem",
    minWidth: "28px",
    marginTop: "7px",
    marginLeft: "12px",
    [theme.breakpoints.up("lg")]: {
      minWidth: "36px",
      fontSize: "1rem",
    },
  },
  button: {
    color: theme.palette.grey.white,
    fontWeight: "500",
    padding: "15px",
    width: "200px",
    background: theme.palette.secondary.main,
  },
  statusWrapper: {
    maxWidth: { sm: "45%", xs: "100%" },
    display: { xs: "flex", sm: "block" },
  },
  statusIndicator: {
    float: "left",
    width: "8px",
    height: "8px",
    margin: "5px",
    borderRadius: "50%",
  },
  bold_info: {
    fontSize: "17px",
    fontWeight: "500",
    cursor: "pointer",
  },
};

const FeedbackTable = ({ feedbacks, loading, feedbacksList }) => {
  const noDataMessage = "No Feedbacks";
  const goToPageFcn = (value) => {
    navigate(`/app/admin/feedbacks/${value.id}`);
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "ratings": {
        return (
          <Box sx={styles.statusWrapper}>
            <Rating defaultValue={value["rating"]} readOnly />
          </Box>
        );
      }
      default:
        return;
    }
  };
  const limitHandler = (value) => {
    feedbacksList.setLimit(value);
    feedbacksList.setLoadDataHandler(!feedbacksList.loadDataHandler);
    feedbacksList.setPageNumber(1);
    feedbacksList.setIsLastPage(false);
    feedbacksList.setLastLoadedPageNumber(0);
    feedbacksList.setLastPageNumber(null);
    feedbacksList.setLastLoadedFeedbacks(null);
    feedbacksList.setAllFeedbackData([]);
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = feedbacksList.pageNumber;
    if (type == "next") {
      if (!(feedbacksList.lastPageNumber === feedbacksList.pageNumber)) {
        pageNumberNew = pageNumberNew + 1;
        feedbacksList.setLoadDataHandler(!feedbacksList.loadDataHandler);
      }
    } else {
      pageNumberNew = pageNumberNew - 1;
      feedbacksList.setLoadDataHandler(!feedbacksList.loadDataHandler);
    }
    feedbacksList.setPageNumber(pageNumberNew);
  };

  if (loading) return null;

  return (
    <div>
      <GlobalTable
        columns={columnsForFeedbacks}
        allData={feedbacks}
        loading={loading}
        noDataMessage={noDataMessage}
        valueConvertHandler={valueConvertHandler}
        goToPageFcn={goToPageFcn}
        limit={feedbacksList.limit}
        limitHandler={limitHandler}
        paginationBtnClick={paginationBtnClick}
        currentPage={feedbacksList.pageNumber}
        isLastPage={feedbacksList.isLastPage}
        lastPage={feedbacksList.lastPageNumber}
      />
    </div>
  );
};

FeedbackTable.propTypes = {
  loading: PropTypes.bool,
  feedbacks: PropTypes.array,
  feedbacksList: PropTypes.object,
};

FeedbackTable.defaultProps = {
  loading: false,
  feedbacks: [],
  feedbacksList: {},
};

export default FeedbackTable;
