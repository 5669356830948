import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getPilotsV2 } from "../services/pilots";

const useGetPilotsListV2 = () => {
  const [pilots, setPilots] = useStateIfMounted([]);
  const [allPilots, setAllPilots] = useStateIfMounted([]);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);
  const [lastLoadedPilot, setLastLoadedPilot] = useStateIfMounted(null);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [dataLoadedTill, setDataLoadedTill] = useStateIfMounted(null);
  const [isLastPage, setIslastPage] = useStateIfMounted(false);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [limit, setLimit] = useStateIfMounted(5);
  const [filter, setFilter] = useStateIfMounted({
    equipment: "",
    sensorsCameras: "",
    nameEmail: "",
    insurance: "",
    permissionToFly: "",
    registeredBusiness: "",
    megapixels: "",
  });
  const [sort, setSort] = useStateIfMounted({
    sortBy: "dateCreated",
    sortValue: "desc",
  });

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    try {
      setLoading(true);
      if (dataLoadedTill >= currentPage) {
        let prevPageMissions = allPilots.slice(
          (currentPage - 1) * limit,
          currentPage * limit
        );
        setPilots(prevPageMissions);
        setLoading(false);
      } else {
        getPilotsV2(firebase, sort, filter, lastLoadedPilot, limit).then(
          (data) => {
            setPilots(data.pilots);
            setDataLoadedTill(currentPage);
            setLastLoadedPilot(data.lastLoaded);
            setAllPilots([...allPilots, ...data.pilots]);
            if (data.isLast) {
              setLastPageNumber(currentPage);
              setIslastPage(true);
            }
            setLoading(false);
          }
        );
      }
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  }, [triggerReload]);

  return {
    pilots,
    setAllPilots,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    sort,
    setSort,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedPilot,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  };
};

export default useGetPilotsListV2;
