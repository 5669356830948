import React, { useEffect } from "react";
import HeaderWithSideInfo from "../../../global/elements/HeaderWithSideInfo";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import { missionStatus, userRoles } from "../../../../lib/constants";
import BoxLayout from "../../../global/BoxLayout";
import MissionMap from "../../../global/elements/missionsMap";
import { getPercentage } from "../../../../lib/helpers";
import { useStateIfMounted } from "use-state-if-mounted";
import AppButton from "../../../global/elements/AppButton";
import { downloadUploadedFile } from "../../../../services/project";
import Dialogue from "../../../global/Dialogue";
import theme from "../../../../theme";
const styles = {
  countContainer: {
    marginTop: "20px",
  },
  boxLayout: {
    padding: "10px",
  },
  fullHeight: { height: "92%" },
};

const ProjectStatusAndMap = ({ role, projectDetails, missionData }) => {
  const [totalValidMissionCount, setTotalValidMissionCount] =
    useStateIfMounted(null);
  const [status, setStatus] = useStateIfMounted("Waiting to start download...");
  const [showDownload, setShowDownload] = useStateIfMounted(false);

  const fileDownload = async () => {
    const urlsData = await downloadUploadedFile(projectDetails.id);
    setStatus("Downloading...");
    setShowDownload(true);
    try {
      const downloadPromises = urlsData.map((urlDt, index) =>
        downloadFile(urlDt, index)
      );
      await Promise.all(downloadPromises);
      setStatus("All files downloaded successfully");
    } catch (error) {
      setStatus(`Error downloading files: ${error.message}`);
    }
  };
  const downloadFile = async (urlDt, index) => {
    const response = await fetch(urlDt.fileUrl);
    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = urlBlob;
    a.download =
      urlDt.fileName ||
      `downloaded_file_${index + 1}.${urlDt.uploadedFileType}`; // Change the file extension as needed
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(urlBlob);
  };

  useEffect(() => {
    let totalCnt = missionData?.filter(
      (data) =>
        data.status ===
        (missionStatus.deleted ||
          missionStatus.archive ||
          missionStatus.rejected ||
          missionStatus.cancelled)
    );
    setTotalValidMissionCount(
      projectDetails?.allMissionCount - totalCnt?.length
    );
  }, []);

  const notStartedPercent = () => {
    return getPercentage(
      projectDetails?.allUnassignedMissionCount,
      totalValidMissionCount
    );
  };
  const inProgressPercent = () => {
    return getPercentage(
      projectDetails?.allActiveMissionCount,
      totalValidMissionCount
    );
  };
  const uploadedPercent = () => {
    return getPercentage(
      projectDetails?.dataUploadedMissionCount,
      totalValidMissionCount
    );
  };
  const completedPercent = () => {
    return getPercentage(
      projectDetails?.allCompletedMissionCount,
      totalValidMissionCount
    );
  };
  const invoicedPercentage = () => {
    return getPercentage(
      projectDetails?.invoicedMissionCount,
      projectDetails?.associatedPilots
    );
  };
  const paidPercentage = () => {
    return getPercentage(
      projectDetails?.pilotPaidCount,
      projectDetails?.associatedPilots
    );
  };

  return (
    <Box>
      <HeaderWithSideInfo
        title="Status and KPIs"
        sideTitle1="Total missions"
        sideTitle2="Deadline"
        value1={projectDetails?.allMissionCount}
        value2={projectDetails?.deadline}
      />
      <Grid container sx={styles.countContainer} spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={6}>
              <BoxLayout
                text="Not started"
                subText={new Date().toLocaleDateString()}
                subPlanFlag
                leftQuota={notStartedPercent() || 0}
                noQuotaText
                // reversedColor
                smallerText
                additionalValue={
                  projectDetails?.allUnassignedMissionCount +
                    ` of ${totalValidMissionCount}` || 0
                }
                showRightArrow={false}
                additionalStyle={styles.boxLayout}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <BoxLayout
                text="In progress"
                subText={new Date().toLocaleDateString()}
                subPlanFlag
                leftQuota={inProgressPercent() || 0}
                noQuotaText
                smallerText
                additionalValue={
                  projectDetails?.allActiveMissionCount +
                    ` of ${totalValidMissionCount}` || 0
                }
                showRightArrow={false}
                additionalStyle={styles.boxLayout}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <BoxLayout
                text="Completed"
                subText={new Date().toLocaleDateString()}
                subPlanFlag
                leftQuota={completedPercent() || 0}
                noQuotaText
                smallerText
                additionalValue={
                  projectDetails?.allCompletedMissionCount +
                    ` of ${totalValidMissionCount}` || 0
                }
                showRightArrow={false}
                additionalStyle={styles.boxLayout}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <BoxLayout
                text="Uploaded"
                subText={new Date().toLocaleDateString()}
                subPlanFlag
                leftQuota={uploadedPercent() || 0}
                noQuotaText
                smallerText
                additionalValue={
                  projectDetails?.dataUploadedMissionCount +
                    ` of ${totalValidMissionCount}` || 0
                }
                showRightArrow={false}
                additionalStyle={styles.boxLayout}
              />
            </Grid>

            {role === userRoles.admin && (
              <>
                <Grid item xs={12} md={4} lg={6}>
                  <BoxLayout
                    text="Invoiced (Pilot)"
                    subText={new Date().toLocaleDateString()}
                    subPlanFlag
                    leftQuota={invoicedPercentage() || 0}
                    noQuotaText
                    smallerText
                    additionalValue={
                      projectDetails?.invoicedMissionCount +
                        ` of ${projectDetails?.associatedPilots}` || 0
                    }
                    showRightArrow={false}
                    additionalStyle={styles.boxLayout}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={6}>
                  <BoxLayout
                    text="Paid (Pilot)"
                    subText={new Date().toLocaleDateString()}
                    subPlanFlag
                    leftQuota={paidPercentage() || 0}
                    noQuotaText
                    smallerText
                    additionalValue={
                      projectDetails?.pilotPaidCount +
                        ` of ${projectDetails?.associatedPilots}` || 0
                    }
                    showRightArrow={false}
                    additionalStyle={styles.boxLayout}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {role === userRoles.admin ? (
          <Grid container item xs={12} lg={6}>
            <Grid item xs={12} sx={styles.fullHeight}>
              <MissionMap missionData={missionData} />
            </Grid>
            <Grid item xs={12}>
              <AppButton
                label={"Download the file uploaded by the client"}
                fullWidth
                onClick={() => fileDownload()}
                submittingState={showDownload}
                loaderColor={theme.palette.grey.white}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} lg={6}>
            <MissionMap missionData={missionData} />
          </Grid>
        )}

        {/* <Grid item xs={12}>
            <AppButton label={"Download uploaded file"} />
          </Grid> */}
      </Grid>
      <Dialogue
        showDialogue={showDownload}
        setShowDialogue={setShowDownload}
        DialogueHeadText={status}
        sumitButtonText={"Ok"}
        onSubmit={() => {
          setShowDownload(false);
          setStatus("Waiting to start download...");
        }}
        submitButtonLook={"primary"}
        bottomDeviderLine={true}
      />
    </Box>
  );
};

ProjectStatusAndMap.propTypes = {
  role: PropTypes.string.isRequired,
  projectDetails: PropTypes.object,
  missionData: PropTypes.array,
};

ProjectStatusAndMap.defaultProps = {
  projectDetails: {},
  missionData: [],
};

export default ProjectStatusAndMap;
