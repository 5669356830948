import firebase from "src/firebase";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { isBrowser } from "src/services/auth";
import { getSingleProject, getBarChartData } from "../services/project";

export const useGetProjectInfo = ({ projectId, role, pilotId }) => {
  const [loading, setLoading] = useStateIfMounted(false);
  const [projectDetails, setProjectDetails] = useStateIfMounted(null);
  const [projectMissions, setProjectMissions] = useStateIfMounted(null);
  const [allPilotsData, setAllPilotsData] = useStateIfMounted(null);
  const [allAdminsData, setAllAdminsData] = useStateIfMounted(null);
  const [barchartData, setBarChartData] = useStateIfMounted(null);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    getSingleProject(firebase, projectId, role, pilotId)
      .then((snapshot) => {
        if (snapshot.status === "Success") {
          setProjectDetails(snapshot?.projectData || {});
          setProjectMissions(snapshot?.missionData || []);
          setAllPilotsData(snapshot?.allPilotsData || []);
          setAllAdminsData(snapshot?.allAdminsData || []);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error", err);
        setLoading(false);
      });
  }, [projectId, triggerReload]);

  useEffect(() => {
    getBarChartData(firebase, projectId, "completed", pilotId).then(
      (snapshot) => {
        if (snapshot?.status === "Success") {
          setBarChartData(snapshot || {});
        }
      }
    );
  }, [projectId, pilotId, triggerReload]);

  return [
    projectDetails,
    projectMissions,
    allPilotsData,
    allAdminsData,
    barchartData,
    loading,
    triggerReload,
    setTriggerReload,
  ];
};
