import React from "react";
import DashboardBanner from "./DashboardBanner";
import AppButton from "../../../../global/elements/AppButton";
import { useCookies } from "react-cookie";
import theme from "../../../../../theme";

const AddEnterpriseBanner = () => {
  const [cookie, setCookie, removeCookie] = useCookies(["addEnterPrise"]);

  const skipFunc = () => {
    removeCookie("addEnterPrise", { path: "/" });
  };

  return (
    <DashboardBanner
      heading={"Want to collaborate with your colleagues on drone data orders?"}
      headingVariant={"h4"}
      subHeading={
        "Upgrade to an enterprise account for free. With enterprise, you can invite your colleagues to collaborate on your organisation's orders and boost productivity. Add members now or skip this for later."
      }
      subHeadingVariant={"h6"}
      bannerBgColor={theme.palette.primary.palePistachio}
    >
      <AppButton
        look="ghost"
        label="Skip for the moment"
        noIcon
        onClick={skipFunc}
      />
      <AppButton
        look="black"
        label="Lets do that!"
        url="/app/client/enterprise"
        internalLink
      />
    </DashboardBanner>
  );
};

export default AddEnterpriseBanner;
