import React from "react";
import useGetPilotsListV2 from "../../../../hooks/useGetPilotsListV2";
import {
  assignPilotForMissionColumn,
  filterFieldsForPilotList,
} from "../../../../lib/constants";
import Dialogue from "../../../global/Dialogue";
import GlobalTable from "../../admin/GlobalTable";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import FilterComponent from "../../../global/elements/FilterComponent";
import PropTypes from "prop-types";

const AssignPilotToMissionModal = ({
  showDialogue,
  setShowDialogue,
  valueConvertHandler,
}) => {
  const {
    pilots,
    setAllPilots,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedPilot,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  } = useGetPilotsListV2();

  const clearDataAndTriggerReload = () => {
    setLastLoadedPilot(null);
    setCurrentPage(1);
    setAllPilots([]);
    setLastPageNumber(null);
    setDataLoadedTill(null);
    setIslastPage(false);
    setTriggerReload(!triggerReload);
  };

  const limitHandler = (value) => {
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const paginationBtnClick = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        setLastLoadedPilot(null);
        setTriggerReload(!triggerReload);
      }
    }
  };

  const globalTableBody = () => {
    return (
      <>
        <FilterComponent
          filterFields={filterFieldsForPilotList}
          multiple={true}
          filterFormName={"mainFilterFields"}
          filter={filter}
          setFilter={setFilter}
          clearDataAndTriggerReload={clearDataAndTriggerReload}
        />
        {!loading ? (
          <GlobalTable
            columns={assignPilotForMissionColumn}
            allData={pilots}
            valueConvertHandler={valueConvertHandler}
            goToPageFcn={() => {}}
            limitHandler={limitHandler}
            limit={limit}
            paginationBtnClick={paginationBtnClick}
            currentPage={currentPage}
            isLastPage={isLastPage}
            lastPage={lastPageNumber}
          />
        ) : (
          <UniversalLoader />
        )}
      </>
    );
  };

  return (
    <Dialogue
      showDialogue={showDialogue}
      setShowDialogue={setShowDialogue}
      DialogueHeadText={"Operators"}
      DialogueBody={globalTableBody()}
      tableType={true}
      dividerLine={true}
      maxWidth="md"
      hideIcon={true}
    />
  );
};

AssignPilotToMissionModal.propTypes = {
  showDialogue: PropTypes.bool.isRequired,
  setShowDialogue: PropTypes.func.isRequired,
  valueConvertHandler: PropTypes.func,
};

AssignPilotToMissionModal.defaultProps = {
  valueConvertHandler: () => {},
};

export default AssignPilotToMissionModal;
