import React from "react";
import Paper from "@mui/material/Paper";
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  styleGraph: {
    width: "100%",
    "&.MuiPaper-root": {
      width: "100%",
      height: { xs: "174px", sm: "250px", md: "250px" },
    },
    "& .jss1": {
      height: { xs: "174px", sm: "250px", md: "250px" },
    },
    "& .Component-root-1": {
      height: { xs: "174px", sm: "250px", md: "250px" },
    },
    "& .center-container": {
      height: { xs: "174px", sm: "250px", md: "250px" },
    },
  },
};

const Graph = ({ barData, valueField, argumentField }) => {
  return (
    <Paper sx={styles.styleGraph}>
      {barData && (
        <Chart data={barData} height={"30%"}>
          <ArgumentAxis />
          <ValueAxis max={7} />
          <BarSeries
            valueField={valueField}
            argumentField={argumentField}
            color={theme.palette.secondary.lavender}
          />
          {/* <Title text="World population" /> */}
          <Animation />
        </Chart>
      )}
    </Paper>
  );
};

Graph.propTypes = {
  barData: PropTypes.object.isRequired,
  valueField: PropTypes.string.isRequired,
  argumentField: PropTypes.string.isRequired,
};

export default Graph;
