import firebase from "src/firebase";
import { useCallback, useEffect, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getMission } from "src/services/missions";
import { getPilot } from "src/services/pilots";
import { getUser } from "../services/auth";
import { userRoles } from "../lib/constants";

export const useGetMission = (id) => {
  const [mission, setMission] = useStateIfMounted();
  const [pilot, setPilot] = useStateIfMounted();
  const [error, setError] = useStateIfMounted();
  const [loading, setLoading] = useStateIfMounted(false);
  const [refresh, setRefresh] = useStateIfMounted(true);
  const currentUser = getUser("user");

  useEffect(() => {
    if (!firebase || !refresh) {
      return;
    }

    if (refresh && loading) {
      setRefresh(false);
      return;
    }
    setRefresh(false);
    setLoading(true);

    getMission(firebase, id)
      .then((data) => {
        setMission(data);
        if (
          (currentUser.userID == data?.assignedPilot ||
            currentUser.role == userRoles.admin) &&
          data.assignedPilot
        ) {
          getPilot(firebase, data.assignedPilot).then((pilot) => {
            setPilot(pilot);
            setLoading(false);
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setError(e);
      });
  }, [id, loading, refresh, refreshMission]);

  const refreshMission = useCallback(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    if (!mission) {
      setLoading(true);
    } else if (mission.assignedPilot && pilot === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [mission, pilot]);

  return { mission, pilot, loading, refreshMission, error };
};
