import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Dialogue from "../../global/Dialogue";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MultiLineTextArea from "../../global/elements/TypographyElements/MultiLineTextArea";
import AppButton from "../../global/elements/AppButton";

const styles = {
  popupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
  // leftAlign: {
  //   textAlign: "start",
  // },
  // rightAlign: {
  //   textAlign: "end",
  // },
};

const RejectQuotePopup = ({
  showRejectDialogue,
  setShowRejectDialogue,
  onSubmit,
}) => {
  const DialogueBody = () => {
    const formInitialValues = {
      rejectReason: "",
    };

    const validationSchemaData = Yup.object().shape({
      rejectReason: Yup.string().required(
        "Reason to reject quote is required!"
      ),
    });

    return (
      <Box sx={styles.popupCls}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchemaData}
          onSubmit={onSubmit}
        >
          {({ setFieldValue }) => {
            return (
              <Form id="quoteRejectForm">
                <Grid container>
                  <Grid item xs={12} mb={3}>
                    <Typography variant="h5">Decline quotation</Typography>
                  </Grid>
                  <Grid item xs={12} mb={3}>
                    <MultiLineTextArea
                      isFormik
                      name="rejectReason"
                      label="Quote rejection reason"
                      setFieldValue={setFieldValue}
                      minRows={3}
                      required
                      placeholder="Write some info to explain to the globhe why you want to reject this quote."
                    />
                  </Grid>
                  <Grid item xs={6} sx={styles.leftAlign}>
                    <AppButton
                      label={"Cancel"}
                      look={"negative"}
                      onClick={() => setShowRejectDialogue(false)}
                    />
                  </Grid>
                  <Grid item xs={6} sx={styles.rightAlign}>
                    <AppButton
                      label={"Decline"}
                      type={"submit"}
                      form={"quoteRejectForm"}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    );
  };
  return (
    <Box>
      <Dialogue
        showDialogue={showRejectDialogue}
        // setShowDialogue={setShowRejectDialogue}
        DialogueHeadText={""}
        DialogueBody={DialogueBody()}
        // showCancelButton={true}
        // cancelButtonText={"Cancel"}
        // sumitButtonText={"Submit"}
        // showSubmitLoader={loading}
        // onSubmit={() => onSubmit()}
        // submitButtonLook={"primary"}
        tableType={true}
      />
    </Box>
  );
};

RejectQuotePopup.propTypes = {
  showRejectDialogue: PropTypes.bool.isRequired,
  setShowRejectDialogue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RejectQuotePopup;
