import React, { useContext } from "react";
import { Grid } from "@mui/material";
import ButtonCards from "../../../../global/ButtonCards";
import { globheMainURL } from "../../../../../lib/constants";
import DashboardContext from "./DashboardContext";

const styles = {
  mTop: {
    marginTop: "25px !important",
  },
};

const BottomCards = () => {
  const context = useContext(DashboardContext);
  const disable = context?.disableButton ? true : false;
  return (
    <Grid container sx={styles.mTop}>
      <Grid item m={0.9} xs={12} sm={12} md={12} lg={3.7}>
        <ButtonCards
          heading="Task a Drone"
          subText="Need a personalized solution? Get started quickly and easily on your next custom order."
          btnLabel="Place an Order"
          link="/app/order/create-order"
          disabled={disable}
        />
      </Grid>
      <Grid item m={0.9} xs={12} sm={12} md={12} lg={3.7}>
        <ButtonCards
          heading="Data Library"
          subText="Shop our growing database of existing imagery uploaded by our network of drone operators."
          btnLabel="Data Library"
          link="/app/client/data-library"
          disabled={disable}
        />
      </Grid>
      <Grid item m={0.9} xs={12} sm={12} md={12} lg={3.7}>
        <ButtonCards
          heading="Explore Our Products"
          subText="Discover the multiple types of data products that our drone operators can collect for you."
          btnLabel="Learn more"
          link={`${globheMainURL}/products`}
          exploreProductFlag={true}
        />
      </Grid>
    </Grid>
  );
};

export default BottomCards;
