import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import ArrowHeaderContainer from "../../../NewDataLibrary/components/ArrowHeaderContainer";
import MissionContext from "./MissionContext";
import BannerAndStepperClient from "../../../Client/Order/BannerAndStepperClient";
import { missionStatus, userRoles } from "../../../../../lib/constants";
import SmallStatusBanner from "../../../../global/elements/SmallStatusBanner";
import BannerAndStepperAdminsPilots from "../../BannerAndStepperAdminsPilots";

const styles = {
  statusBanner: {
    marginTop: "80px",
  },
};
const OrderTopNavComponent = () => {
  const data = useContext(MissionContext);
  const isAdmin = data?.currentUser?.role === userRoles.admin;
  const isClient = data?.currentUser?.role === userRoles.client;
  const isPilot = data?.currentUser?.role === userRoles.pilot;

  const getRedirectURL = () => {
    // const missionUrl = window.localStorage.getItem("missionUrl");
    let status = data?.mission?.status;
    // if (missionUrl) {
    //   if (isAdmin) {
    //     return "/app/missions/client-order/initial-client-order";
    //   } else if (isPilot) {
    //     return "/app/missions/inquiries";
    //   } else if (isClient) {
    //     return "/app/client/orders/reviewing";
    //   }
    // }
    if (data?.mission?.projectIds) {
      if (isClient) {
        return `/app/client/projects/${data?.mission?.projectIds}`;
      } else if (isAdmin) {
        return `/app/admin/projects/${data?.mission?.projectIds}`;
      } else {
        return `/app/pilots/project/${data?.mission?.projectIds}`;
      }
    } else if (isClient) {
      if (status === missionStatus.initialClientOrder) {
        return "/app/client/orders/reviewing";
      } else if (
        status === missionStatus.unassigned ||
        status === missionStatus.confirmedMission ||
        status === missionStatus.active
      ) {
        return "/app/client/orders/inProgress";
      } else if (status === missionStatus.completed) {
        return "/app/client/orders/completed";
      } else if (status === missionStatus.cancelled) {
        return "/app/client/orders/cancelled";
      } else if (status === missionStatus.rejected) {
        return "/app/client/orders/rejected";
      }
    }
  };

  return (
    <Box>
      <ArrowHeaderContainer
        header={data?.mission?.missionName}
        redirectURL={getRedirectURL()}
      >
        <Grid container>
          {data?.currentUser?.role == userRoles.client && (
            <Grid item xs={12} mt={4}>
              <BannerAndStepperClient />
            </Grid>
          )}
          {(isAdmin || isPilot) &&
            data?.mission?.status != missionStatus.archive &&
            data?.mission.status !== missionStatus.cancelled && (
              <Grid item xs={12} mt={4}>
                <BannerAndStepperAdminsPilots
                  progressStatus={
                    isAdmin
                      ? data?.adminClientProgressStatus
                      : data?.pilotProgressStatus
                  }
                />
              </Grid>
            )}
          {data?.mission?.status === missionStatus.cancelled && (
            <Grid item xs={12} sx={styles.statusBanner}>
              <SmallStatusBanner
                title="this order is cancelled"
                content="your data will not be captured and you will not be invoiced. Place a new order in case you want data from this region again."
              />
            </Grid>
          )}
          {data?.mission?.status === missionStatus.rejected && (
            <Grid item xs={12} sx={styles.statusBanner}>
              <SmallStatusBanner
                title="this order has been rejected"
                content={
                  isClient
                    ? data?.reason
                    : isAdmin
                    ? data?.mission?.reason?.rejectedReason
                    : ""
                }
              />
            </Grid>
          )}
        </Grid>
      </ArrowHeaderContainer>
    </Box>
  );
};

export default OrderTopNavComponent;
