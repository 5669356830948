import React from "react";
import { DateTime } from "luxon";
import firebase from "src/firebase";
import { Box, Typography, Grid } from "@mui/material";
import Layout from "../../../../Layout";
import AppButton from "../../../global/elements/AppButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import theme from "../../../../theme";
import { getPilotsAsCSV } from "../../../../services/pilots";
import { getClientsAsCSV } from "../../../../services/clients";
import { getMissionsAsCSV } from "../../../../services/missions";
import { useSnackbar } from "notistack";
import { saveAs } from "file-saver";
import Tips from "../../../global/elements/Tips";
import { getUser } from "../../../../services/auth";
import { userRoles } from "../../../../lib/constants";

const styles = {
  exportPilots: {
    padding: { xs: "10px 20px" },
    marginBottom: "30px",
    background: theme.palette.grey.white,
    marginTop: "10px",
    width: "100%",
  },
  adminTitle: { paddingBottom: "10px" },
};

const AppStatistics = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userRole = getUser("user").role;
  const isAdmin = userRole === userRoles.admin;

  const downloadPilotsCsv = async () => {
    await getPilotsAsCSV(firebase)
      .then((csv) => {
        var data = new Blob([csv], { type: "text/csv" });
        var url = window.URL.createObjectURL(data);
        saveAs(url, `pilots_export_${DateTime.local().toISODate()}.csv`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const downloadClientsCsv = async () => {
    await getClientsAsCSV(firebase)
      .then((csv) => {
        var data = new Blob([csv], { type: "text/csv" });
        var url = window.URL.createObjectURL(data);
        saveAs(url, `clients_export_${DateTime.local().toISODate()}.csv`);
      })
      .catch((error) => {
        enqueueSnackbar("No Client Found", {
          variant: "error",
        });
        console.log(error);
      });
  };
  const downloadMissionsCsv = async () => {
    await getMissionsAsCSV(firebase)
      .then((csv) => {
        var data = new Blob([csv], { type: "text/csv" });
        var url = window.URL.createObjectURL(data);
        saveAs(url, `missions_export_${DateTime.local().toISODate()}.csv`);
      })
      .catch((error) => {
        enqueueSnackbar("No missions Found", {
          variant: "error",
        });
        console.log(error);
      });
  };

  return (
    <Layout
      backButtonPageName={"App statistics"}
      hasSideMenu
      addBottomPadding
      backButton
    >
      {isAdmin ? (
        <>
          <Tips content="Here you can get data from our operators, clients, and all missions. The data will be downloaded as a csv and can be analysed in either excel, Tableau, or Power BI" />
          <Box sx={styles.exportPilots}>
            <Typography variant="h5" style={styles.adminTitle}>
              Available data to download
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <AppButton
                  look="blue"
                  onClick={downloadPilotsCsv}
                  label="Drone operators"
                  customIcon={<CloudDownloadIcon />}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography>
                  Export all drone operator data in a csv format. This includes
                  all operators in the database, including deleted users.
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <AppButton
                  look="blue"
                  onClick={downloadClientsCsv}
                  label="Client Data"
                  customIcon={<CloudDownloadIcon />}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography>Export all client data in a csv format.</Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <AppButton
                  look="blue"
                  onClick={downloadMissionsCsv}
                  label="All missions data"
                  customIcon={<CloudDownloadIcon />}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography>
                  Export all mission data in a csv format. This does not include
                  multi-location orders, but includes all sites that are part of
                  the multi-location order.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Tips content="Here you can see the data about operators, clients, and orders, in a Tableau dashboard. It will open in a new window." />

          <Box sx={styles.exportPilots}>
            <Typography variant="h5" style={styles.adminTitle}>
              Available data dashboards
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <AppButton
                  look="blue"
                  label="Drone operators"
                  externalLink
                  url="https://public.tableau.com/app/profile/globhe/viz/Book1_17042058333340/PilotDashboard?publish=yes"
                  targetNewWidow
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography>
                  See all the data related to drone operators, registration
                  date, available equipments, and country distribution.
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <AppButton
                  look="blue"
                  label="Client and user data"
                  externalLink
                  url="https://public.tableau.com/app/profile/globhe/viz/Book1_17042058333340/ClientDashboard?publish=yes"
                  targetNewWidow
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography>
                  See the client distribution, conversion rate (% of clients
                  placing at least 1 order - either multi- or single site),
                  returning rate (% of clients placing at least 2 orders -
                  either multi or single site), and acquisition statistics.
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <AppButton
                  look="blue"
                  label="All orders data"
                  externalLink
                  url="https://public.tableau.com/app/profile/globhe/viz/Book1_17042058333340/Orders?publish=yes"
                  targetNewWidow
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography>
                  For orders, you can see the total amount of orders, the total
                  amount of multi-site orders, the conversion rate, returning
                  rate, the number of single sites placed, the number of
                  multi-sites placed, and the average duration between orders
                  for each client
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Typography
          variant="h4"
          sx={{ marginTop: "20vh", textAlign: "center" }}
        >
          This page is not available at the moment
        </Typography>
      )}
    </Layout>
  );
};

export default AppStatistics;
