import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import ErrorBoundary from "./index";

const ErrorBoundaryParent = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location?.pathname]);

  return (
    /**
     * NEW: The class component error boundary is now
     *      a child of the functional component.
     */
    <ErrorBoundary hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryParent;
