import { AppBar, Box } from "@mui/material";
import React, { useContext, useState } from "react";
import { StyledTab, StyledTabs, appBar } from "../../../../lib/styleConstants";
import TeamMainPage from "../TeamMainPage";
import DocumentMainPage from "../DocumentMainPage";
import CompanyInfoMainPage from "../CompanyInfoMainPage";
import AppContext from "../../../../contexts/AppContext";
import PropTypes from "prop-types";
import { userRoles } from "../../../../lib/constants";

const styles = {
  tabBox: {
    borderBottom: 1,
    borderColor: "divider",
    // paddingLeft: { xs: "0px", sm: "50px" },
    justifyContent: { xs: "center", sm: "left" },
    paddingBottom: "30px",
    "& button": {
      fontSize: { xs: "10px", sm: "13px" },
    },
    margin: "40px 0",
  },
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const EnterpriseTabLayout = ({ props, enterpriseData, userData }) => {
  let tabConstant =
    props?.location?.hash === "#company"
      ? 2
      : props?.location?.hash == "#doc"
      ? 1
      : 0;
  const [tabValue, setTabValue] = useState(tabConstant);
  const appContext = useContext(AppContext);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box sx={styles.tabBox}>
      <AppBar position="static" sx={appBar} elevation={0}>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <StyledTab key="team" label="Teams" {...a11yProps(0)} />
          <StyledTab key="documents" label="Documents" {...a11yProps(0)} />
          {appContext?.enterPrise && userData?.role !== userRoles.admin && (
            <StyledTab
              key="companyInfo"
              label="Company info"
              {...a11yProps(0)}
            />
          )}
        </StyledTabs>
      </AppBar>
      <Box>
        {tabValue === 0 ? (
          <>
            <TeamMainPage
              enterpriseInfo={
                userData?.role === userRoles.admin
                  ? enterpriseData
                  : appContext?.enterPrise || null
              }
              currentUser={appContext?.userData}
            />
          </>
        ) : tabValue === 1 ? (
          <>
            <DocumentMainPage
              enterpriseId={
                userData?.role === userRoles.admin
                  ? enterpriseData?.id || null
                  : appContext?.enterPrise?.id || null
              }
              userData={appContext?.userData}
            />
          </>
        ) : (
          <>
            <CompanyInfoMainPage />
          </>
        )}
      </Box>
    </Box>
  );
};

EnterpriseTabLayout.propTypes = {
  enterpriseData: PropTypes.object,
  userData: PropTypes.object,
  props: PropTypes.object,
  location: PropTypes.object,
};

EnterpriseTabLayout.defaultProps = {
  enterpriseData: undefined,
  userData: undefined,
  props: undefined,
  location: undefined,
};

export default EnterpriseTabLayout;
