import PropTypes from "prop-types";
import {
  Avatar,
  Menu,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { countryListAlpha2, users } from "../../../lib/constants";
import React from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import Notification from "./Notification";
import theme from "../../../theme";
import { Link } from "gatsby";
import AddCart from "./AddCart";
import { useSelector } from "react-redux";
import { getUser } from "../../../services/auth";
import { userRoles } from "../../../lib/constants";
import MenuIcon from "@mui/icons-material/Menu";
import SideMenuDrawer from "../SideMenu/SideMenuDrawer";
import Currency from "./Currency";
import MyProfile from "./MyProfile";
import LanguageIcon from "@mui/icons-material/Language";

const styles = {
  pageHeight: {
    height: { xs: 0, md: "64px" },
  },
  iconContainer: {
    display:
      "inline-block" /* or any other display property that suits your layout */,
    borderRadius: "50%" /* to make the div element rounded */,
    overflow: "hidden" /* to mask the icon with the rounded border */,
    marginRight: {
      xs: 0,
      md: "10px",
    },
    verticalAlign:
      "middle" /* to align the icon with the text on the same line */,
    width:
      "30px !important" /* set a fixed width for the container to avoid resizing issues */,
    height:
      "30px !important" /* set a fixed height for the container to avoid resizing issues */,
    marginLeft: { xs: 0, md: "10px" },
  },
  flexGrowStyle: {
    flexGrow: 1,
  },
  appBarStyle: {
    backgroundColor: {
      xs: "transparent",
      md: theme.palette.grey.white,
    },
    boxShadow: "none",
  },
  iconBtnStyle: { display: { xs: "none", md: "flex" }, alignItems: "center" },
  showMoreBtn: { display: { xs: "flex", md: "none" } },
  avatar: {
    width: "1.5em !important",
    height: "auto !important",
  },
  mobile: {
    "& .MuiPaper-root": {
      overflowY: "unset",
      overflowX: "visible",
    },
  },
  currencyContainer: { marginLeft: { xs: 0, md: "10px" } },
  iconStyle: {
    width: "100%" /* to make sure the image fills the container */,
    height: "100%" /* to make sure the image fills the container */,
    objectFit: "cover" /* to avoid stretching the image */,
  },
};

const MenuBar = ({ profileImage, country, enterpriseId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const cartData = useSelector((state) => state.CartReducer);
  const currentUser = getUser("user");
  const currentUserRole = currentUser.role;
  let termsOfUse = true;
  let enterpriseClient = false;

  if (currentUserRole == userRoles.client && !currentUser.isAnonymous) {
    let userProfile = getUser(users.userProfile);
    if (!userProfile?.termsOfUse) {
      termsOfUse = false;
    }
    if (userProfile.enterPriseId) {
      enterpriseClient = true;
    }
  }

  ///---
  ///---
  const [sideMenuMobileAnchorEl, setSideMenuMobileAnchorEl] =
    React.useState(null);
  const isSideMenuMobileOpen = Boolean(sideMenuMobileAnchorEl);

  const handleSideMenuMobileClose = () => {
    setSideMenuMobileAnchorEl(null);
  };
  const handleSideMenuMobileOpen = (event) => {
    setSideMenuMobileAnchorEl(event.currentTarget);
  };

  ///---
  ///---

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const getCountryFlag = (countryName) => {
    let countryCode = Object.keys(countryListAlpha2).filter(
      (key) => countryListAlpha2[key] == countryName
    );
    return (
      <>
        {countryCode[0] ? (
          <ReactCountryFlag
            countryCode={countryCode[0] || "UN"}
            aria-label={countryName}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            style={styles.iconStyle}
            title={countryName}
          />
        ) : (
          <LanguageIcon sx={styles.iconStyle} />
        )}
      </>
    );
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to={
          currentUserRole === userRoles.client || currentUser.isAnonymous
            ? `/app/client/my-profile/${currentUser?.userID}`
            : "/app/my-profile"
        }
      >
        My profile
      </MenuItem>
      {termsOfUse && (
        <>
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={`/app/client/enterprise`}
          >
            My enterprise account
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={`/app/client/enterprise#doc`}
          >
            My documents
          </MenuItem>
        </>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={styles.mobile}
    >
      {termsOfUse && (
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show new notifications"
            color="inherit"
            sx={{ paddingLeft: "0px" }}
          >
            <Notification enterpriseId={enterpriseId} />
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
      )}

      {currentUserRole === userRoles.client && termsOfUse && (
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show selected currency"
            color="inherit"
            aria-haspopup="true"
          >
            <Currency />
          </IconButton>
          <p>Currency</p>
        </MenuItem>
      )}
      <MenuItem>
        <IconButton size="large" aria-label="Country" color="inherit">
          <Box sx={styles.iconContainer}>{getCountryFlag(country)}</Box>
        </IconButton>
        <p>Country</p>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {profileImage ? (
            <Avatar src={profileImage} />
          ) : (
            <AccountCircle sx={styles.avatar} />
          )}
        </IconButton>{" "}
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const sideMenuMobileId = "side-menu-mobile";
  const renderSideMobileMenu = (
    <Menu
      anchorEl={sideMenuMobileAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      id={sideMenuMobileId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={isSideMenuMobileOpen}
      onClose={handleSideMenuMobileClose}
    >
      <SideMenuDrawer currentUser={currentUser} />
    </Menu>
  );

  return (
    <Box sx={{ ...styles.flexGrowStyle, ...styles.pageHeight }}>
      <AppBar sx={styles.appBarStyle}>
        <Toolbar>
          <Box sx={styles.showMoreBtn}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleSideMenuMobileOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={styles.flexGrowStyle} />
          <Box sx={styles.iconBtnStyle}>
            {currentUserRole === userRoles.client && termsOfUse && (
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <AddCart cartData={cartData} />
              </IconButton>
            )}
            {termsOfUse && (
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                aria-haspopup="true"
              >
                <Notification enterpriseId={enterpriseId} />
              </IconButton>
            )}
            {currentUserRole === userRoles.client && termsOfUse && (
              <IconButton
                size="large"
                aria-label="show selected currency"
                color="inherit"
                aria-haspopup="true"
                sx={styles.currencyContainer}
              >
                <Currency />
              </IconButton>
            )}
            <Box sx={styles.iconContainer}>{getCountryFlag(country)}</Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {/* {profileImage ? (
                <Avatar src={profileImage} />
              ) : (
                <AccountCircle sx={styles.avatar} />
              )} */}
              <MyProfile
                profileImage={profileImage}
                isAnonymous={currentUser.isAnonymous}
              />
            </IconButton>
          </Box>
          <Box sx={styles.showMoreBtn}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderSideMobileMenu}
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
};

MenuBar.propTypes = {
  profileImage: PropTypes.string,
  country: PropTypes.string,
  enterpriseId: PropTypes.string,
};
MenuBar.defaultProps = {
  profileImage: undefined,
  country: undefined,
  enterpriseId: null,
};

export default MenuBar;
