import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getSingleInspiration } from "../services/news-inspiration";

const useGetInspiration = (id) => {
  const [inspiration, setInspiration] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    getSingleInspiration(id)
      .then((data) => {
        setInspiration(data?.inspiration);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [inspiration, loading];
};

export default useGetInspiration;
