import React from "react";
import { userRoles } from "../../../lib/constants";
import { getUser } from "../../../services/auth";
import PilotSingleMissionPage from "./NewMissionDetails/PilotSingleMissionPage";
import AdminSingleMissionPage from "./NewMissionDetails/AdminSingleMissionPage";

const SingleMission = (props) => {
  const currentUser = getUser("user");
  const currentUserRole = currentUser.role;
  if (currentUserRole === userRoles.admin) {
    return <AdminSingleMissionPage {...props} />;
  }

  if (currentUserRole === userRoles.pilot) {
    return <PilotSingleMissionPage {...props} />;
  }
};

export default SingleMission;
