import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import theme from "../../../theme";
import NoTeam from "./Components/NoTeam";
import EnterpriseContext from "./EnterpriseContext";
import GlobalTable from "../admin/GlobalTable";
import { useSnackbar } from "notistack";
import {
  enterPriseUserRole,
  enterpriseClientRoles,
  filterFiledForTeam,
  teamsTableClient,
  teamsTableAdmin,
  users,
  userRoles,
} from "../../../lib/constants";
import useGetAllTeamMembers from "../../../hooks/useGetAllTeamMembers";
import FilterComponent from "../../global/elements/FilterComponent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteEnterpriseUser from "./Components/DeleteEnterpriseUser";
import AddMembers from "./Components/AddMembers";
import AfterInvitationPopup from "../../forms/client-form/AfterInvitationPopup";
import { navigate } from "gatsby";
import AppButton from "../../global/elements/AppButton";
import {
  changePermission,
  deleteEnterpriseUser,
} from "../../../services/client";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { getUser } from "../../../services/auth";
import { fetchEnterPrise } from "../../../services/enterprise";
import AppContext from "../../../contexts/AppContext";
import { useCookies } from "react-cookie";

const styles = {
  mainRoot: {
    // borderTop: `2px solid ${theme.palette.black.darkSeaBlack}`,
    paddingTop: "50px",
    zIndex: 0,
  },
  menuBar: {
    display: "flex",
    position: "relative",
    flexDirection: "row-reverse",
  },
  menuBarStyle: {
    width: "140px",
    height: "auto",
    padding: "10px",
    marginLeft: "15px",
    background: theme.palette.grey.lightWarmGrey,
    position: "absolute",
    right: "25px",
    top: "-35px",
  },
  paddingText: {
    padding: "10px 5px",
  },
  selectBar: {
    width: "140px",
    height: "40px",
  },
  disabledText: {
    cursor: "not-allowed",
    padding: "10px 5px",
  },
  loaderDiv: {
    paddingTop: "18%",
  },
  btnCls: {
    width: "100%",
  },
};

const TeamMainPage = ({ enterpriseInfo, currentUser }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    teamMembers,
    allMembers,
    setAllMembers,
    setTeamMembers,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedMembers,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  } = useGetAllTeamMembers(enterpriseInfo?.id || null);

  const [addMemberPopup, setAddMembersPopup] = useState(false);
  const [menuOpenId, setMenuOpenId] = useState(null);

  //Delete User
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);
  const [showInviteSentPopup, setShowInviteSent] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();

  const noDataMessage = "No members available.";
  const appContext = useContext(AppContext);

  const openAddMemberPopup = () => {
    setAddMembersPopup(true);
  };

  const onInviteSent = () => {
    setAddMembersPopup(false);
    setShowInviteSent(true);
  };

  const deleteUserFcn = async () => {
    setDeletePopup(false);
    const status = await deleteEnterpriseUser(
      deleteUser.id,
      deleteUser.email,
      deleteUser.enterPriseId
    );
    if (status.status === "Success") {
      let leftAllMember = allMembers.filter((obj) => obj.id !== deleteUser.id);
      let leftCurrentMember = teamMembers.filter(
        (obj) => obj.id !== deleteUser.id
      );
      setTeamMembers(leftCurrentMember);
      setAllMembers(leftAllMember);
      enqueueSnackbar("Team member removed successfully!", {
        variant: "Success",
      });
    } else {
      enqueueSnackbar("Fail to remove user!", { variant: "danger" });
    }
  };

  const clearDataAndTriggerReload = () => {
    setLastLoadedMembers(null);
    setCurrentPage(1);
    setAllMembers([]);
    setLastPageNumber(null);
    setDataLoadedTill(null);
    setIslastPage(false);
    setTriggerReload(!triggerReload);
  };

  const limitHandler = (value) => {
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const paginationBtnClick = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        setLastLoadedMembers(null);
        setTriggerReload(!triggerReload);
      }
    }
  };

  const handleRoleChange = async (id, prevValue, updatedValue) => {
    console.log(prevValue, updatedValue);
    let status = await changePermission(id, updatedValue);
    if (status.status === "Success") {
      enqueueSnackbar("Permission updated!", { variant: "success" });
      let objIndexforAll = allMembers.findIndex((obj) => obj.id == id);
      allMembers[objIndexforAll].roleType = updatedValue;
      let objIndexforCurrent = teamMembers.findIndex((obj) => obj.id == id);
      teamMembers[objIndexforCurrent].roleType = updatedValue;
      setTeamMembers(teamMembers);
    } else {
      enqueueSnackbar("Fail to update permission!", { variant: "danger" });
    }
  };

  const valueConvertHandler = (field, row) => {
    switch (field) {
      case "menuBar": {
        return (
          <Box sx={styles.menuBar}>
            <MoreVertIcon
              onClick={() =>
                menuOpenId === row.id
                  ? setMenuOpenId(null)
                  : setMenuOpenId(row.id)
              }
            />
            {menuOpenId === row.id && (
              <Box sx={styles.menuBarStyle}>
                <Typography
                  variant="body1"
                  sx={
                    // row.id === currentUser.id ||
                    currentUser.roleType === enterPriseUserRole.general
                      ? styles.disabledText
                      : styles.paddingText
                  }
                  onClick={() => {
                    if (
                      !(
                        // row.id === currentUser.id ||
                        (currentUser.roleType === enterPriseUserRole.general)
                      )
                    ) {
                      navigate(`/app/client/team/member/edit/${row.id}`);
                    }
                  }}
                >
                  Edit profile
                </Typography>
                <Typography
                  variant="body1"
                  sx={
                    row.id === currentUser.id ||
                    currentUser.roleType === enterPriseUserRole.general
                      ? styles.disabledText
                      : styles.paddingText
                  }
                  onClick={() => {
                    if (
                      !(
                        row.id === currentUser.id ||
                        currentUser.roleType === enterPriseUserRole.general
                      )
                    ) {
                      setDeletePopup(true);
                      setDeleteUser(row);
                    }
                  }}
                >
                  Delete profile
                </Typography>
              </Box>
            )}
          </Box>
        );
      }
      case "selector": {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            sx={styles.selectBar}
            defaultValue={row.roleType}
            onChange={(e) =>
              handleRoleChange(row.id, row.roleType, e.target.value)
            }
            disabled={
              row.id === appContext.userData.id ||
              appContext.userData?.roleType === enterPriseUserRole.general ||
              appContext.userData?.role === userRoles.admin
            }
          >
            {enterpriseClientRoles?.map((role, i) => {
              return (
                <MenuItem key={i} value={role.value}>
                  {role.label}
                </MenuItem>
              );
            })}
          </Select>
        );
      }
    }
  };

  const goToDashboard = async () => {
    try {
      let userProfile = getUser(users.userProfile);
      if (userProfile?.enterPriseId) {
        let enterprise = await fetchEnterPrise(userProfile?.enterPriseId);
        appContext?.setEnterPrise(enterprise);
        appContext?.setUserData({
          ...appContext?.userData,
          enterPriseId: enterprise?.id,
          roleType: enterPriseUserRole.admin,
        });
        setCookie("enterpriseAccount", enterprise?.id, { path: "/" });
        removeCookie("addEnterPrise", { path: "/" });
        navigate("/app/client/my-account");
      }
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Error in fetching enterprise", { variant: "error" });
    }
  };

  return (
    <EnterpriseContext.Provider value={teamMembers}>
      {enterpriseInfo ? (
        <Box sx={styles.mainRoot}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={currentUser?.roleType == enterPriseUserRole.admin ? 8 : 12}
              md={currentUser?.roleType == enterPriseUserRole.admin ? 9 : 12}
            >
              <FilterComponent
                filterFields={filterFiledForTeam}
                multiple={true}
                filterFormName={"mainFilterFields"}
                filter={filter}
                setFilter={setFilter}
                clearDataAndTriggerReload={clearDataAndTriggerReload}
              />
            </Grid>
            {currentUser?.roleType == enterPriseUserRole.admin && (
              <Grid item xs={4} md={3}>
                <AppButton
                  label={"Add members +"}
                  look={"green"}
                  onClick={() => openAddMemberPopup()}
                  noIcon
                  addtionalStyle={styles.btnCls}
                />
              </Grid>
            )}
          </Grid>
          <br />
          {!loading ? (
            <GlobalTable
              columns={
                appContext.userData?.role === userRoles.admin
                  ? teamsTableAdmin
                  : teamsTableClient
              }
              allData={teamMembers}
              loading={loading}
              noDataMessage={noDataMessage}
              valueConvertHandler={valueConvertHandler}
              // goToPageFcn={goToPageFcn}
              limit={limit}
              limitHandler={limitHandler}
              paginationBtnClick={paginationBtnClick}
              currentPage={currentPage}
              isLastPage={isLastPage}
              lastPage={lastPageNumber}
              // checkbox={true}
            />
          ) : (
            <Box sx={styles.loaderDiv}>
              <UniversalLoader />
            </Box>
          )}
        </Box>
      ) : (
        <NoTeam openPopup={openAddMemberPopup} />
      )}
      <DeleteEnterpriseUser
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        deleteUserFcn={deleteUserFcn}
      />
      <AddMembers
        addMemberPopup={addMemberPopup}
        setAddMembersPopup={setAddMembersPopup}
        onInviteSent={onInviteSent}
      />
      {showInviteSentPopup && (
        <AfterInvitationPopup
          openPopup={showInviteSentPopup}
          setOpenPopup={setShowInviteSent}
          goToDashboard={goToDashboard}
          text={"Your invited members have now received an invitation email"}
        />
      )}
    </EnterpriseContext.Provider>
  );
};

TeamMainPage.propTypes = {
  enterpriseInfo: PropTypes.object,
  currentUser: PropTypes.object,
};

TeamMainPage.defaultProps = {
  enterpriseInfo: {},
  currentUser: {},
};

export default TeamMainPage;
