import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import CommunicationBasic from "../CommunicationBasic";
import { userRoles } from "../../../../lib/constants";
import SideChatMenu from "../SideChatMenu";
import { useStateIfMounted } from "use-state-if-mounted";
import { getChatListForMultiLocationOrder } from "../../../../services/communication";
import UniversalLoader from "../../elements/UniversalLoader";
import { getPilot } from "../../../../services/pilots";
import firebase from "src/firebase";
import PropTypes from "prop-types";

const styles = {
  appBar: {
    width: "100%",
    backgroundColor: "transparent",
    borderColor: "green",
  },
  noPilotBox: {
    minHeight: "400px",
    height: "400px",
    padding: "20px 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderDiv: {
    paddingTop: "18%",
  },
};

const AdminCommunication = ({ projectDetails }) => {
  const [messagingTo, setMessagingTo] = useStateIfMounted(userRoles.client);
  const [personsList, setPersonsList] = useStateIfMounted([]);
  const [talkingTo, setTalkingTo] = useStateIfMounted(userRoles.client);
  const [currentPilot, setCurrentPilot] = useStateIfMounted(null);

  useEffect(() => {
    // Setting a list of person to chat

    async function getUsersToChatList() {
      const usersToChat = await getChatListForMultiLocationOrder(
        projectDetails.clientId,
        projectDetails.associatedDroneOperatorsIds
      );
      setPersonsList(usersToChat);
    }

    getUsersToChatList();
    //redirecting to the pilot chat if we recieve a pilot chat in notication.
    if (window.location.search === "?client" || !window.location.search) {
      setMessagingTo(userRoles.client);
      setTalkingTo(userRoles.client);
    } else {
      setMessagingTo(window.location.search.substring(1));
      setTalkingTo(userRoles.pilot);
    }
  }, [projectDetails]);

  const changePersonToChat = async (chatTo) => {
    setMessagingTo(chatTo);
    if (chatTo === userRoles.client) {
      setTalkingTo(userRoles.client);
    } else {
      setTalkingTo(userRoles.pilot);
      const getPilotInfo = await getPilot(firebase, chatTo);
      setCurrentPilot(getPilotInfo);
    }
  };
  return (
    <>
      {personsList?.length > 0 ? (
        <Grid container>
          <Grid item xs={12} md={4} lg={2.5}>
            {/* Side Menu for all the persons who is in active chat list. */}
            <SideChatMenu
              personToChat={personsList}
              onPersonClickFcn={changePersonToChat}
              active={messagingTo}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={9.5}>
            <CommunicationBasic
              mission={projectDetails}
              talkingTo={talkingTo}
              isProject={true}
              messagingTo={messagingTo}
              currentPilot={currentPilot}
            />
          </Grid>
        </Grid>
      ) : (
        <Box sx={styles.loaderDiv}>
          <UniversalLoader />
        </Box>
      )}
    </>
  );
};
AdminCommunication.propTypes = {
  projectDetails: PropTypes.object.isRequired,
};

export default AdminCommunication;
