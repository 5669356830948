import firebase from "src/firebase";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getClientOrdersList } from "src/services/client";
import { getUser } from "../services/auth";

export const useGetClientOrdersList = (status, missionType, enterpriseId) => {
  let initalSortBy = { sortBy: "dateCreated", sortValue: "desc" };
  const [projects, setProjects] = useStateIfMounted([]);
  const [allProject, setAllProject] = useStateIfMounted([]);
  const [lastLoadedProject, setLastLoadedProject] = useStateIfMounted(null);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const [filteredProjectList, setFilteredProjectList] = useStateIfMounted([]);
  const [limit, setLimit] = useStateIfMounted(10);
  const [filter, setFilter] = useStateIfMounted({});
  const [loadHandler, setLoadHandler] = useStateIfMounted(false);
  // const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [sortBy, setSortBy] = useStateIfMounted(initalSortBy);
  const [reloadTriggered, setReloadTriggered] = useStateIfMounted(false);
  const [previousPage, setPreviousPage] = useStateIfMounted(0);
  const [dataloadedTill, setDataLoadedTill] = useStateIfMounted(0);
  const [pageChanged, setPageChanged] = useStateIfMounted(false);
  const [initialLoad, setInitialLoad] = useStateIfMounted(false);
  const [pageData, setPagedata] = useStateIfMounted({
    currentPage: 1,
    isLast: false,
    lastPage: null,
  });
  useEffect(() => {
    if (!firebase) {
      return;
    }
    if (!initialLoad) {
      setInitialLoad(true);
      return;
    }
    const user = getUser("user");
    setIsLoading(true);

    if (user?.isAnonymous) {
      setIsLoading(false);
      setPagedata({
        currentPage: 1,
        isLast: true,
        lastPage: 1,
      });
    } else {
      if (
        dataloadedTill >= pageData.currentPage ||
        (pageData.currentPage <= previousPage && reloadTriggered === false)
      ) {
        let prevPageMissionData = allProject.slice(
          (pageData.currentPage - 1) * limit,
          pageData.currentPage * limit
        );
        setProjects(prevPageMissionData);
        setPreviousPage(pageData.currentPage - 1);
        setIsLoading(false);
      } else {
        if (reloadTriggered) {
          setPagedata({
            currentPage: 1,
            isLast: false,
            lastPage: null,
          });
          setPreviousPage(0);
        }
        getClientOrdersList(
          firebase,
          status,
          missionType,
          lastLoadedProject,
          limit,
          filter,
          sortBy,
          enterpriseId
        )
          .then((data) => {
            setProjects(data.projects);
            let allProjectData = allProject || [];
            setPreviousPage(pageData.currentPage - 1);
            setLastLoadedProject(data.lastLoaded);
            setDataLoadedTill(pageData.currentPage);
            if (data.isLast) {
              let pageDataUpdated = pageData;
              pageDataUpdated["isLast"] = true;
              pageDataUpdated["lastPage"] = pageData.currentPage;
              setPagedata(pageDataUpdated);
            }
            if (reloadTriggered) {
              setPreviousPage(0);
              setAllProject([...data.projects]);
            } else {
              setAllProject([...allProjectData, ...data.projects]);
            }
            setReloadTriggered(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [loadHandler, pageChanged, filter]);

  useEffect(() => {
    let newProjectList = [...projects];
    setFilteredProjectList(newProjectList);
  }, [projects, loadHandler, pageChanged]);

  return [
    filteredProjectList,
    isLoading,
    limit,
    setLimit,
    filter,
    setFilter,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllProject,
    setDataLoadedTill,
    setLastLoadedProject,
    sortBy,
    setSortBy,
    pageChanged,
    setPageChanged,
  ];
};
