import firebase from "src/firebase";
import { useEffect, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { isBrowser } from "src/services/auth";
import { getPilotsByIds } from "src/services/pilots";
import { missionListSource, interestInviteStatus } from "../lib/constants";
import { getInterestedPilotIds } from "../services/interest-invite";

const useGetPilots = (pilotIds, missionId, source) => {
  const [pilots, setPilots] = useStateIfMounted([]);
  const [loading, setLoading] = useStateIfMounted(true);

  useEffect(() => {
    if (!firebase || !isBrowser() || !missionId) return;
    getPilotIds((err, ids) => {
      getPilotsByIds(firebase, ids, (results) => {
        setPilots(results);
        setLoading(false);
      });
    });
  }, []);

  const getPilotIds = (cb) => {
    if (source === missionListSource.assign) {
      cb(null, pilotIds);
      return;
    } else {
      getInterestedPilotIds(
        firebase,
        missionId,
        interestInviteStatus.pilotRequested
      )
        .then((data) => {
          const ids =
            data && data.pilotIds && data.pilotIds.length ? data.pilotIds : [];
          let newPilotIds = pilotIds.filter((id) => ids.indexOf(id) === -1);
          cb(null, newPilotIds);
          return;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return [pilots, loading];
};

export default useGetPilots;
