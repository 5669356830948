import React from "react";
import { getUser } from "../../../services/auth";
import NoMissionsMessage from "../missions/shared/NoMissionsMessage";
import FeedbackTable from "./FeedbackTable";
import { useGetFeedbacksList } from "../../../hooks/useGetFeedbacksList";
import Layout from "../../../Layout";
import { Box } from "@mui/material";
import UniversalLoader from "../../global/elements/UniversalLoader";

const styles = {
  root: {
    position: "relative",
    padding: { xs: "31px 0 26px 0", lg: "55px 0 61px 0" },
  },
  loaderDiv: {
    paddingTop: "18%",
  },
};

const FeedBackPage = () => {
  const feedbacksList = useGetFeedbacksList();
  const currentUser = getUser("user");

  if (
    feedbacksList.feedbacks.length !== 0 ||
    feedbacksList.isLoading === true ||
    currentUser.role === "admin"
  ) {
    return (
      <Layout
        pageTitle="Feedbacks"
        hasSideMenu
        loading={
          feedbacksList.feedbacks.length === 0 &&
          feedbacksList.isLoading === true
        }
      >
        {feedbacksList.feedbacks.length === 0 &&
        feedbacksList.isLoading === true ? (
          <Box sx={styles.loaderDiv}>
            <UniversalLoader />
          </Box>
        ) : (
          <Box sx={styles.root}>
            <FeedbackTable
              feedbacks={feedbacksList.feedbacks}
              loading={feedbacksList.isLoading}
              feedbacksList={feedbacksList}
            ></FeedbackTable>
          </Box>
        )}
      </Layout>
    );
  } else {
    return (
      <Layout pageTitle="Feedbacks" hasSideMenu>
        <NoMissionsMessage
          title="No feedbacks yet :("
          description="Currently, there are no feedbacks from users yet"
          actionButton={null}
        />
      </Layout>
    );
  }
};

export default FeedBackPage;
