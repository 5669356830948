import React from "react";
import theme from "../../../../theme";
import FilterComponent from "../../../global/elements/FilterComponent";
import { Box, Grid } from "@mui/material";
import GlobalTable from "../../admin/GlobalTable";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import {
  enterpriseTable,
  filterFiledForEnterprises,
} from "../../../../lib/constants";
import useGetEnterpriseList from "../../../../hooks/useGetEnterpriseList";

const styles = {
  menuBar: {
    display: "flex",
    position: "relative",
    flexDirection: "row-reverse",
  },
  menuBarStyle: {
    width: "140px",
    height: "auto",
    padding: "10px",
    marginLeft: "15px",
    background: theme.palette.grey.lightWarmGrey,
    position: "absolute",
    right: "25px",
    top: "-35px",
  },
  paddingText: {
    padding: "10px 5px",
    "&:hover": {
      background: theme.palette.primary.palePistachio,
    },
  },
  linkStyle: {
    textDecoration: "none",
  },
  mtop: { marginTop: "40px" },
  btnCls: {
    width: "100%",
  },
};

const AdminEnterPrise = () => {
  //   const { enqueueSnackbar } = useSnackbar();

  const {
    enterprises,
    setAllEnterprises,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedEnterprise,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  } = useGetEnterpriseList();

  const clearDataAndTriggerReload = () => {
    setLastLoadedEnterprise(null);
    setCurrentPage(1);
    setAllEnterprises([]);
    setLastPageNumber(null);
    setDataLoadedTill(null);
    setIslastPage(false);
    setTriggerReload(!triggerReload);
  };

  const limitHandler = (value) => {
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const paginationBtnClick = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        setLastLoadedEnterprise(null);
        setTriggerReload(!triggerReload);
      }
    }
  };
  return (
    <Box sx={styles.mtop}>
      <Grid container>
        <Grid item xs={12}>
          <FilterComponent
            filterFields={filterFiledForEnterprises}
            multiple={true}
            filterFormName={"mainFilterFields"}
            filter={filter}
            setFilter={setFilter}
            clearDataAndTriggerReload={clearDataAndTriggerReload}
          />
        </Grid>
      </Grid>
      <br />
      {!loading ? (
        <GlobalTable
          columns={enterpriseTable}
          allData={enterprises}
          //   valueConvertHandler={valueConvertHandler}
          goToPageFcn={() => {}}
          limitHandler={limitHandler}
          limit={limit}
          paginationBtnClick={paginationBtnClick}
          currentPage={currentPage}
          isLastPage={isLastPage}
          lastPage={lastPageNumber}
        />
      ) : (
        <UniversalLoader />
      )}
    </Box>
  );
};

export default AdminEnterPrise;
