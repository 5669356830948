import React from "react";
import { Box, Typography } from "@mui/material";
import Dialogue from "../../global/Dialogue";
import PropTypes from "prop-types";

const styles = {
  popupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
};

const DialogueBody = () => {
  return (
    <Box sx={styles.popupCls}>
      <Typography variant="h6">Cancel Quote</Typography>
      <Typography variant="subtitle1">
        Do you want to cancel this quote ?
      </Typography>
    </Box>
  );
};

const CancelAcceptedQuote = ({
  showDialogue,
  setShowDialogue,
  onSubmit,
  loading,
}) => {
  return (
    <Box>
      <Dialogue
        showDialogue={showDialogue}
        setShowDialogue={setShowDialogue}
        DialogueHeadText={""}
        DialogueBody={DialogueBody()}
        showCancelButton={true}
        cancelButtonText={"Cancel"}
        sumitButtonText={"Submit"}
        showSubmitLoader={loading}
        onSubmit={() => onSubmit()}
        submitButtonLook={"primary"}
      />
    </Box>
  );
};

CancelAcceptedQuote.propTypes = {
  showDialogue: PropTypes.bool.isRequired,
  setShowDialogue: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CancelAcceptedQuote;
