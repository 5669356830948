import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import CollapsableBox from "../../../../../components/global/elements/CollapsableBox";
import { useStateIfMounted } from "use-state-if-mounted";
import MissionContext from "./MissionContext";
import {
  getOrderStatusLogs,
  clientOrderStatus,
  clientOrderStatusReassign,
} from "../../../../../services/orderStatus";
import { orderLogStatus } from "../../../../../lib/constants";
import firebase from "src/firebase";
import theme from "../../../../../theme";
import PropTypes from "prop-types";
import { userRoles } from "../../../../../lib/constants";

const styles = {
  gridCs: {
    margin: "0px !important",
    paddingTop: "10px !important",
    paddingLeft: "15px !important",
    position: "relative",
  },
  gridNav: {
    borderBottom: theme.palette.grey.dividersColor,
    paddingBottom: "10px",
    width: "100%",
  },
  titleText: {
    fontWeight: 500,
    color: theme.palette.grey.deepWarmGrey,
  },
  titleHint: {
    color: theme.palette.grey.deepWarmGrey,
  },
  dateText: {
    textAlign: "right",
    color: theme.palette.black.coolGrey,
  },
  timeText: {
    textAlign: "right",
    color: theme.palette.grey.warmGrey,
  },
  roundDot: {
    position: "absolute",
    left: "0px",
    top: "50%",
    translate: "-50% -50%",
    border: "1px solid",
    height: "20px",
    width: "20px",
    borderRadius: "50%",
  },
  valueBox: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    marginTop: "6px",
  },
  clickText: {
    cursor: "pointer",
    marginTop: { xs: "10px", md: 0 },
    paddingLeft: { xs: 0, md: "10px" },
  },

  // infoBox: {
  //   width: "150px",
  //   backgroundColor: theme.palette.adminBlue.paleBlue,
  // },
};

const DetailedOrderLog = ({ userType }) => {
  const [logs, setLogs] = useStateIfMounted([]);
  const [dateLog, setDateLog] = useStateIfMounted([]);
  const isAdmin = userType === userRoles.admin;
  const isClient = userType === userRoles.client;
  const isPilot = userType === userRoles.pilot;
  const actionData = useContext(MissionContext);

  const goToQuote = () => {
    actionData?.handleTabChange("e", 1);
  };
  const goToDelivery = () => {
    actionData?.handleTabChange("e", 3);
  };

  const order = useContext(MissionContext);
  const missionId = order?.mission?.id;
  let baseData = [];
  let dataReassign = [];
  for (let item of Object.keys(clientOrderStatus)) {
    if (order?.mission?.subscriptionId) {
      if (item != "quoteAdded" && item != "quoteAccepted") {
        baseData.push({ ...clientOrderStatus[item], status: item });
      }
    } else {
      baseData.push({ ...clientOrderStatus[item], status: item });
    }
  }
  for (let item of Object.keys(clientOrderStatusReassign)) {
    if (order?.mission?.subscriptionId) {
      if (item != "quoteAdded" && item != "quoteAccepted") {
        dataReassign.push({ ...clientOrderStatusReassign[item], status: item });
      }
    } else {
      dataReassign.push({ ...clientOrderStatusReassign[item], status: item });
    }
  }
  useEffect(() => {
    getOrderStatusLogs(firebase, { missionId })
      .then((data) => {
        setDateLog(data);
        let doneStates = [];
        data.map((order) => {
          doneStates.push(order.status);
        });
        setLogs(doneStates);
      })
      .catch((e) => {
        console.log("Error", e);
        setLogs([]);
      });
  }, []);

  const getDate = (log) => {
    if (log) {
      for (let i = 0; i < dateLog.length; i++) {
        if (dateLog[i].status == log) {
          const dateObj = new Date(dateLog[i].date);
          const formattedDate = dateObj.toLocaleString("en-GB", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          });
          return formattedDate;
        }
      }
    } else {
      return;
    }
  };
  const getTime = (log) => {
    if (log) {
      for (let i = 0; i < dateLog.length; i++) {
        if (dateLog[i].status == log) {
          const dateObj = new Date(dateLog[i].date);
          const formattedTime = `(CET) ${dateObj
            .toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })
            .replace(/ /, "")}`;
          return formattedTime;
        }
      }
    } else {
      return;
    }
  };
  const OrderLogGrid = ({ isLog, log }) => {
    const showSeeMore =
      isLog === orderLogStatus.quoteAdded ||
      isLog === orderLogStatus.completed ||
      isLog === orderLogStatus.quoteAccepted;

    return (
      <Grid
        container
        mt={1}
        sx={{
          borderLeft:
            isLog && isAdmin
              ? `2px solid ${theme.palette.adminBlue.main}`
              : isLog && isClient
              ? `2px solid ${theme.palette.primary.main}`
              : isLog && isPilot
              ? `2px solid ${theme.palette.secondary.main}`
              : `2px solid ${theme.palette.grey.lightWarmGrey}`,
          ...styles.gridCs,
        }}
      >
        <Box
          sx={{
            ...styles.roundDot,
            backgroundColor:
              isLog && isAdmin
                ? theme.palette.adminBlue.paleBlue
                : isLog && isClient
                ? theme.palette.primary.main
                : isLog && isPilot
                ? theme.palette.secondary.main
                : theme.palette.grey.white,
          }}
        />
        <Box sx={styles.gridNav}>
          <Box sx={{ display: "flex" }}>
            <Grid item xs={10}>
              <Typography
                variant="subtitle1Med"
                sx={{
                  ...styles.titleText,
                  opacity: isLog ? "1" : "0.5",
                  color:
                    isLog && isAdmin
                      ? `${theme.palette.adminBlue.main} !important`
                      : isLog && isClient
                      ? `${theme.palette.black.darkSeaBlack} !important`
                      : isLog && isPilot
                      ? `${theme.palette.secondary.main} !important`
                      : `${theme.palette.grey.deepWarmGrey} !important`,
                }}
              >
                {log.title}
              </Typography>
              <Box sx={styles.valueBox}>
                <Typography
                  variant="body2"
                  sx={{
                    ...styles.titleHint,
                    opacity: isLog ? "1" : "0.5",
                  }}
                >
                  {log.value}
                </Typography>
                {showSeeMore && !isPilot && (
                  <Typography
                    variant="body2"
                    onClick={
                      isLog === orderLogStatus.quoteAdded ||
                      isLog === orderLogStatus.quoteAccepted
                        ? goToQuote
                        : goToDelivery
                    }
                    sx={{
                      ...styles.clickText,
                      color: isAdmin
                        ? `${theme.palette.adminBlue.main} !important`
                        : isClient
                        ? `${theme.palette.black.darkSeaBlack} !important`
                        : `${theme.palette.secondary.main} !important`,
                    }}
                  >
                    See more
                  </Typography>
                )}
              </Box>
              {/* {isLog && (
                <Box sx={styles.infoBox}>
                  <Typography>Placeholder for the action box</Typography>
                </Box>
              )} */}
            </Grid>
            {isLog && (
              <Grid item xs={2}>
                <Typography variant="body2" sx={styles.dateText}>
                  {getDate(logs.find((item) => item == log.status))}
                </Typography>
                <Typography variant="body2" sx={styles.timeText}>
                  {getTime(logs.find((item) => item == log.status))}
                </Typography>
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>
    );
  };
  OrderLogGrid.propTypes = {
    isLog: PropTypes.string,
    log: PropTypes.shape({
      status: PropTypes.string,
      value: PropTypes.string,
      title: PropTypes.string,
    }),
  };
  OrderLogGrid.defaultProps = {
    isLog: undefined,
    log: undefined,
  };
  return (
    <Box>
      <CollapsableBox
        title="Detailed order log"
        customVariant={"h3"}
        background={"none"}
        userType={userType}
      >
        {logs && logs.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {logs.includes(orderLogStatus.pilotReassigned)
                ? dataReassign.map((log, i) => {
                    const isLog = logs.find((item) => item == log.status);
                    return (
                      <Box key={i}>
                        <OrderLogGrid isLog={isLog} log={log} />
                      </Box>
                    );
                  })
                : baseData.map((log, i) => {
                    const isLog = logs.find((item) => item == log.status);
                    return (
                      <Box key={i}>
                        <OrderLogGrid isLog={isLog} log={log} />
                      </Box>
                    );
                  })}
            </Grid>
          </Grid>
        )}
      </CollapsableBox>
    </Box>
  );
};

DetailedOrderLog.propTypes = {
  userType: PropTypes.string,
  log: PropTypes.shape({
    status: PropTypes.string,
  }),
};
DetailedOrderLog.defaultProps = {
  userType: undefined,
  log: undefined,
};
export default DetailedOrderLog;
