import React, { useContext } from "react";
import { Box, Typography, Popover, Grid, Backdrop } from "@mui/material";
import theme from "../../../theme";
import PropTypes from "prop-types";
import AppContext from "../../../contexts/AppContext";
import { userRoles, users } from "../../../lib/constants";
import { navigate } from "gatsby";
import {
  popoverBox,
  popoverGrid,
  popoverGridItem,
} from "../../../lib/styleConstants";
import { getUser } from "../../../services/auth";

const styles = {
  selectedCurrencyBgColor: {
    backgroundColor: theme.palette.primary.palePistachio,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.primary.palePistachio,
    },
  },
  menuContainer: {
    display: "flex",
  },
  paperPropsCls: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: 0,
    zIndex: 9999999,
    width: "200px",
    maxWidth: "300px",
  },
};

const MyProfilePopup = ({ anchorElProfile, id, handleClose }) => {
  let openBackdrop = Boolean(anchorElProfile);
  const appContext = useContext(AppContext);
  const currentUser = getUser(users.user);

  const isClient = appContext?.userData?.role === userRoles.client;
  const isAnonymousClient = currentUser?.isAnonymous;

  let menus = [];

  switch (true) {
    case isAnonymousClient:
      menus = {
        "My profile": `/app/client/my-profile/${currentUser?.userID}`,
      };
      break;

    case isClient:
      menus = {
        "My Profile": `/app/client/my-profile/${appContext?.userData?.id}`,
      };
      if (appContext?.userData?.termsOfUse) {
        menus["My enterprise account"] = `/app/client/enterprise`;
        menus["My documents"] = `/app/client/enterprise#doc`;
      }
      menus["Sign out"] = `/sign-out`;
      break;

    default:
      menus = {
        "My Profile": "/app/my-profile",
        "Sign out": "/sign-out",
      };
  }

  const onSelect = (e, url) => {
    e.stopPropagation();
    navigate(url);
  };

  return (
    <Box>
      <Backdrop open={openBackdrop} onClick={handleClose}>
        <Popover
          id={id}
          open={openBackdrop}
          anchorEl={anchorElProfile}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: styles.paperPropsCls,
          }}
          sx={{ top: "3%" }}
        >
          <Box sx={popoverBox} id={id} component={"div"} />

          <Grid container sx={popoverGrid}>
            <Grid item container xs={12} spacing={1} sx={styles.menuContainer}>
              {Object.keys(menus).map((menuItem) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      mt={menuItem === "Sign out" ? 2 : 0}
                      key={menuItem}
                      sx={{ ...popoverGridItem, ...styles.menuItem }}
                      onClick={(e) => onSelect(e, menus[menuItem])}
                    >
                      <Typography
                        sx={{
                          color:
                            menuItem === "Sign out"
                              ? theme.palette.status.errorDark
                              : "",
                        }}
                        variant="subtitle1Med"
                      >
                        {menuItem}
                      </Typography>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Popover>
      </Backdrop>
    </Box>
  );
};

MyProfilePopup.propTypes = {
  anchorElProfile: PropTypes.object,
  id: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

MyProfilePopup.defaultProps = {
  anchorElProfile: null,
};

export default MyProfilePopup;
