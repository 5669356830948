//VERSION 2: date: 01-06-2023

import React from "react";
import { Box } from "@mui/material";
import ParagraphHeading from "../ParagraphHeading";
import Clause from "../Clause";
import theme from "../../../../theme";

const styles = {
  assignment_section: {
    marginTop: "30px",
    paddingTop: "20px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
};

const PilotContractVersion2 = () => {
  return (
    <Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="1. Assignment" />
        <Clause
          number="1.1"
          title="The Drone Operator must complete all agreed upon activities following 
          the guidelines and criteria provided by Globhe for this mission. This includes 
          any standard operating procedures, checklists, manuals, risk assessments or other 
          documentation related to the mission that Globhe provides to the  Operator 
          in support of the mission."
        />
        <Clause
          number="1.2"
          title="No Party shall be liable or responsible to the other Party, nor be deemed 
          to have defaulted under or breached this Agreement, for any failure or delay in 
          fulfilling or performing any term of this Agreement (except for any obligations 
          to make previously owed payments to the other Party hereunder) when and to the 
          extent such failure or delay is caused by or results from acts beyond the impacted 
          Party’s (“Impacted Party”) reasonable control, including, without limitation, the 
          following force majeure events (“Force Majeure Event(s)”) that frustrates the 
          purpose of this Agreement: ; (a) flood, fire, earthquake or explosion; (b) war, 
          invasion, hostilities (whether war is declared or not), terrorist threats or acts, 
          riot or other civil unrest; (c) government order or law; (d) actions, embargoes or 
          blockades in effect on or after the date of this Agreement; (e) action by any 
          governmental authority; (f) national or regional emergency; (g) strikes, labor 
          stoppages or slowdowns or other industrial disturbances; (h) epidemic, pandemic or 
          similar influenza or bacterial infection (which is defined by the World Health 
          Organization as virulent human influenza or infection that may cause global 
          outbreak, or pandemic, or serious illness); (i) emergency state; (j) shortage of             
          adequate medical supplies and equipment; (l) shortage of power or transportation 
          facilities; and (m) other similar events beyond the reasonable control of the 
          Impacted Party."
          paragraph2="The  Operator is expected to complete the full scope of work within the 
          designated timeframe as set forth by the mission details with the exception of a 
          Force Majeure event. If a Force Majeure event occurs please inform Globhe
          immediately and provide proof thereof. If any form of illness prevents the 
          Operator from performing work as planned, please contact Globhe
          immediately for mitigation actions. If weather conditions prevent or delay the
          work from being completed as planned, the Drone Operator should inform Globhe
           and together coordinate a new plan."
        />
        <Clause
          number="1.3"
          title="Data delivery must be completed by the deadline set forth by the mission 
          details. Delivery of data is defined as the complete collection, processing, and 
          upload of the data to the Platform to the assigned mission of the Drone Operator. 
          Globhe will review and verify the data once uploaded and provide final 
          approval to the Drone Operator through the pPlatform if no adjustments are necessary. It 
          is the responsibility of the Drone Operator to ensure access to sufficient internet 
          connectivity in order to upload the data to the pPlatform. In case the data delivery 
          is not according to set requirements, the Drone Operator will need to remedy the 
          problem (e.g. re-fly) on own expense. The qualification requirements for the data to 
          be accepted is stated in the mission details as other requirements."
          paragraph2="Should the Drone Operator fail to make delivery by deadline as stipulated 
          in the mission details (with exception of Force Majeure causes specified in Clause 1.2 
          of this Contract) and without informing Globhe ahead of the deadline, the 
          Company is entitled to withhold payment to the Drone Operator."
        />
        <Clause
          number="1.4"
          title="The location of the assignment is restricted to the area defined in Location 
          under mission details."
        />
        <Clause
          number="1.5"
          title="Globhe is not liable for any action by the Drone Operator during 
          the performance of the Service. The Drone Operator is solely responsible for any flights 
          and associated missions conducted in connection with the Drone Operator's use of the services and agree 
          to indemnify Globhe for any claims related to the Drone Operator's performance. 
          The Drone Operator must obtain all permissions needed for the Drone Operator's activities 
          under a mission before conducting any flights in connection with the services and the 
          performance and use of the services must be conducted in coherence with local laws and 
          regulations of the country where the service is conducted and all drone flying is to adhere 
          to the Humanitarian UAV Code of Conduct."
          helperText="Note! The following is only applicable in Sweden:
          In the case that drone data is collected in Sweden, the data might need to apply for a 
          distribution permission (spridningstillstånd) from the Lantmäteriet depending on the data 
          content. It is the Operator´s responsibility to send in the data to Lantmäteriet and 
          get the permit when applicable."
        />
      </Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="2. Equipment" />
        <Clause
          number="2.1"
          title="The Drone Operator must use the required drone model plus any additional required 
          accessories as stated in the mission details"
        />
      </Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="3. Payment" />
        <Clause
          number="3.1"
          title="The Drone Operator will receive the payment amount mentioned in the mission 
          details (excluding VAT if applicable) in total. This is an all inclusive price (e.g. includes 
          the preparation, execution and delivery of the data and any associated transportation costs)."
        />
        <Clause
          number="3.2"
          title="After the Drone Operator has successfully delivered the data to Globhe, Globhe will 
          transfer the payment to the Operator’s account within 30 days of receiving the invoice. Payment 
          is dependent on the Drone Operator performing the work as outlined in this agreement and delivering the 
          data to Globhe on time."
        />
      </Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="4. Terms And Conditions" />
        <Clause
          number="4.1"
          title={
            <>
              This contract complements the{" "}
              <a
                href="https://www.globhe.com/terms-and-conditions"
                target="_blank"
                style={styles.link}
                rel="noreferrer"
              >
                terms and conditions
              </a>{" "}
              page published on Globhe&apos;s website.
            </>
          }
        />
      </Box>
      <Box sx={styles.assignment_section}>
        <ParagraphHeading text="5. Governing Law and Settlement of Disputes" />
        <Clause
          title="The Agreement shall be governed by and construed in accordance with the laws of Sweden 
          (excluding its conflict of law provisions). All disputes arising out of or relating to the 
          Agreement or Services shall be finally settled by arbitration under the Rules of Arbitration 
          of the International Chamber of Chamber of Commerce, in Stockholm, Sweden, by one or more 
          arbitrators appointed in accordance with the said rules. The language for the arbitration 
          proceedings shall be English. All awards may if necessary be enforced by any court having 
          jurisdiction in the same manner as a judgment in such court. All arbitral proceedings, 
          including any information and documents disclosed therein shall be kept confidential and 
          only be used for those proceedings."
          paragraph2="In witness whereof the parties have executed this Agreement in two identical copies 
          (of which the parties have received one copy each)."
        />
      </Box>
    </Box>
  );
};

export default PilotContractVersion2;
