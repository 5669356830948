import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import theme from "../../../theme";
import AppButton from "../../global/elements/AppButton";
import PropTypes from "prop-types";

const styles = {
  cardStyle: {
    width: "300px",
    padding: "10px",
    marginRight: "20px",
  },
  primaryBackground: {
    background: theme.palette.adminBlue.veryPaleBlue,
  },

  secondaryText: {
    color: theme.palette.adminBlue.main,
  },
  primarySubText: {
    color: theme.palette.black.darkSeaBlack,
  },

  subText: { paddingTop: "20px" },
  cardTitle: {
    color: theme.palette.black.darkSeaBlack,
    fontWeight: 500,
    fontSize: "16px",
    marginTop: "12px",
  },
};
const ToolsCard = ({ icon, buttonUrl, title, subtitle }) => {
  return (
    <Card sx={{ ...styles.cardStyle, ...styles.primaryBackground }}>
      <CardContent>
        {icon}
        <Box>
          <Typography sx={{ ...styles.cardTitle, ...styles.secondaryText }}>
            {title}
          </Typography>
          <Typography sx={{ ...styles.subText, ...styles.primarySubText }}>
            {subtitle}
          </Typography>
          <AppButton
            look="blue"
            label="Get started"
            url={buttonUrl}
            internalLink
            addtionalStyle={{ marginTop: "3em" }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

ToolsCard.propTypes = {
  icon: PropTypes.element.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ToolsCard;
