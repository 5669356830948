import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import Dropzone from "react-dropzone";
import { preventEnterSubmit } from "src/lib/form-helpers";
import * as Yup from "yup";
import { Box, Grid, Typography } from "@mui/material";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import AppButton from "../../../global/elements/AppButton";
import {
  getCurrentDateTimeMillis,
  uploadFileToStorage,
} from "../../../../lib/helpers";
import firebase from "src/firebase";
import AppContext from "../../../../contexts/AppContext";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { userRoles } from "../../../../lib/constants";

const styles = {
  uploadBtnStyle: {
    width: "100%",
  },
  submitBtnStyle: {
    textAlignLast: "right",
  },
  errorMsg: {
    color: "red",
  },
};

const AddDocForm = ({ submitDoc, setOpenAddDocPopup, enterpriseId }) => {
  const formInitialValues = {
    docName: "",
    docUrl: "",
  };
  const { enqueueSnackbar } = useSnackbar();
  const appcontext = useContext(AppContext);
  const [percent, setPercent] = useState(0);
  const [fileName, setFileName] = useState(null);

  const validationSchema = Yup.object().shape({
    docName: Yup.string().required("Document name is required").nullable(),
    docUrl: Yup.string().required("Please upload a document").nullable(),
  });

  const onProfileImageDropHandler = async (acceptedFiles, setFieldValue) => {
    if (acceptedFiles && (appcontext?.enterPrise?.id || enterpriseId)) {
      setFileName(null);
      if (acceptedFiles.length > 1) {
        enqueueSnackbar("Please upload one document at a time!", {
          variant: "warning",
        });
      } else {
        let timeStamp = getCurrentDateTimeMillis();
        let file = acceptedFiles[0];
        let path = `EnterpriseDocuments/${
          appcontext?.enterPrise?.id || enterpriseId
        }/${timeStamp}/${file.name}`;
        const fileUploadStatus = await uploadFileToStorage(
          firebase,
          file,
          path,
          setPercent
        );
        if (fileUploadStatus.status === "Success") {
          setFieldValue("docUrl", fileUploadStatus?.fileURL);
          setFieldValue("fileName", file.name);
          setFileName(file.name);
          setFieldValue("fileSize", file.size);
          setFieldValue("uploadedBy", appcontext?.userData?.id);
          setFieldValue(
            "uploadedByEnterprise",
            appcontext?.userData?.role === userRoles.client
          );
          setFieldValue(
            "docPath",
            `EnterpriseDocuments/${
              appcontext?.enterPrise?.id || enterpriseId
            }/${timeStamp}/${file.name}`
          );
        }
      }
    } else {
      enqueueSnackbar("Only a enterprise member can upload documents !", {
        variant: "warning",
      });
    }
  };
  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={submitDoc}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form onKeyDown={preventEnterSubmit} id="addDocForm">
            <br />
            <TextFieldInput
              setFieldValue={setFieldValue}
              values={values}
              name={"docName"}
              placeholder="Choose a name for your document"
              disabled={false}
              type="text"
              required
            />
            <br />
            <Dropzone
              accept=".pdf"
              onDrop={(acceptedFiles) =>
                onProfileImageDropHandler(acceptedFiles, setFieldValue)
              }
              maxSize={5000000}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Box sx={styles.uploadImageButton}>
                    <AppButton
                      label="Upload your document"
                      onClick={() => setFieldValue("docUrl", null)}
                      addtionalStyle={styles.uploadBtnStyle}
                    />
                  </Box>
                </div>
              )}
            </Dropzone>
            {errors.docUrl && (
              <Typography sx={styles.errorMsg} variant="body3">
                {errors.docUrl}
              </Typography>
            )}
            {fileName && <Typography variant="body3">{fileName}</Typography>}
            <br />
            {percent > 0 && percent < 100 && (
              <Typography variant="body3" sx={styles.uploadingText}>
                Uploading...
              </Typography>
            )}
            <br />
            <br />
            <Grid container>
              <Grid item xs={6}>
                <AppButton
                  label="Cancel"
                  noIcon
                  look={"inverted"}
                  onClick={() => {
                    setOpenAddDocPopup(false);
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={styles.submitBtnStyle}>
                <AppButton
                  label="Add document"
                  noIcon
                  type="submit"
                  form="addDocForm"
                  look={"green"}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

AddDocForm.propTypes = {
  setOpenAddDocPopup: PropTypes.func.isRequired,
  submitDoc: PropTypes.func.isRequired,
  enterpriseId: PropTypes.func.isRequired,
};
export default AddDocForm;
