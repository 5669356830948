import React from "react";
import { Box, Typography } from "@mui/material";
import { fontWeightFive } from "../../lib/styleConstants";
import theme from "../../theme";
import PropTypes from "prop-types";

const styles = {
  nexStepStyle: { display: "flex", justifyContent: "space-between" },
  pagesNumber: { display: "flex" },
  skipText: {
    paddingRight: "10px",
    cursor: "pointer",
  },
  skipTextColor: {
    color: theme.palette.secondary.main,
  },
};

const OnboardingHeader = ({ showSkip, count, skipFcn }) => {
  return (
    <>
      <Box sx={{ ...styles.nexStepStyle, ...fontWeightFive }}>
        <Typography variant="h6">Your Next Step:</Typography>
        <Box sx={styles.pagesNumber}>
          {showSkip && (
            <Typography
              variant="body1"
              sx={{
                ...styles.skipText,
                ...styles.skipTextColor,
                ...fontWeightFive,
              }}
              onClick={() => skipFcn()}
            >
              Skip
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{ ...styles.skipTextColor, ...fontWeightFive }}
          >
            {count} of 3
          </Typography>
        </Box>
      </Box>
    </>
  );
};

OnboardingHeader.propTypes = {
  skipFcn: PropTypes.func,
  count: PropTypes.number.isRequired,
  showSkip: PropTypes.bool,
};

OnboardingHeader.defaultProps = {
  skipFcn: undefined,
  showSkip: false,
};

export default OnboardingHeader;
