import mapboxgl from "!mapbox-gl";
import React, { useEffect } from "react";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ2xvYmhlIiwiYSI6ImNrdXptNjlqdDM1dGwzMHM3bWt6ZHVkd20ifQ.IlPFrxSzNDeDB3BQUusayw";

const styles = {
  mapContainer: {
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "visible",
  },
};

const CountryProfileMap = () => {
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/light-v10",
      //   style: "mapbox://styles/mapbox/streets-v12", // style URL
      //   projection: "mercator",
      // style: "mapbox://styles/mapbox/dark-v11",
      center: [30.2176, 12.8628],
      zoom: 2,
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Clean up on unmount
    return () => map.remove();
  }, []);

  return <div style={styles.mapContainer} id="map" />;
};
export default CountryProfileMap;
