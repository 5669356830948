import React from "react";
import ReactCountryFlag from "react-country-flag";
import { countryListAlpha2 } from "../../../lib/constants";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

const styles = {
  flagBox: {
    display: "flex",
    margin: "auto",
    marginTop: { xs: "10px", lg: "20px" },
  },
  country: {
    marginLeft: "10px",
    marginTop: "3px",
  },
};

const CountryWithFlag = ({ user_data, marginTop }) => {
  const getCountryFlag = (countryName) => {
    let countryCode = Object.keys(countryListAlpha2).filter(
      (key) => countryListAlpha2[key] == countryName
    );
    return (
      <ReactCountryFlag
        countryCode={countryCode[0] || "SE"}
        aria-label={countryName}
        svg
        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
        cdnSuffix="svg"
        style={{
          fontSize: "18px",
          borderRadius: "100%",
        }}
        title={countryName}
      />
    );
  };
  return (
    <Box sx={styles.flagBox} style={{ marginTop: marginTop && 0 }}>
      {getCountryFlag(user_data?.location)}
      <Typography style={styles.country} variant="body2">
        {user_data?.location}
      </Typography>
    </Box>
  );
};

CountryWithFlag.propTypes = {
  user_data: PropTypes.object,
  marginTop: PropTypes.bool,
};
CountryWithFlag.defaultProps = {
  user_data: {},
  marginTop: undefined,
};
export default CountryWithFlag;
