import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getLogsList } from "../services/project";

const useGetLogs = ({ projectId, pilotId }) => {
  const limit = 3;
  const [triggerReloadLogs, setTriggerReloadLogs] = useStateIfMounted(false);
  const [logs, setLogs] = useStateIfMounted([]);
  const [allLogs, setAllLogs] = useStateIfMounted([]);
  const [lastLoadedLog, setLastLoadedLog] = useStateIfMounted(null);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [dataLoadedTill, setDataLoadedTill] = useStateIfMounted(null);
  const [loading, setLoading] = useStateIfMounted(false);

  useEffect(() => {
    try {
      if (!firebase || !isBrowser() || !projectId) return;
      setLoading(true);
      if (dataLoadedTill >= currentPage) {
        const updatedLogsData = allLogs.slice(
          limit * (currentPage - 1),
          limit * currentPage
        );
        setLogs(updatedLogsData);
        setLoading(false);
      } else {
        getLogsList(firebase, projectId, lastLoadedLog, limit, pilotId).then(
          (snapshot) => {
            if (snapshot?.status === "Success") {
              setLogs(snapshot?.logs || []);
              setAllLogs([...allLogs, ...snapshot.logs]);
              setLastLoadedLog(snapshot?.lastVisible);
              setDataLoadedTill(currentPage);
              if (snapshot.last) {
                setLastPageNumber(currentPage);
              } else {
                setLastPageNumber(null);
              }
            }
            setLoading(false);
          }
        );
      }
    } catch (err) {
      console.log("Error", err);
    }
  }, [projectId, triggerReloadLogs]);

  return [
    logs,
    triggerReloadLogs,
    currentPage,
    setCurrentPage,
    lastPageNumber,
    setTriggerReloadLogs,
    loading,
    allLogs,
  ];
};

export default useGetLogs;
