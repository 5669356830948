import React from "react";
import { projectStatus } from "../../../../lib/constants";
import ProjectTable from "./ProjectTable";

const ProjectArchive = () => {
  const status = projectStatus.archived;

  return <ProjectTable status={status} pageTitle={"Archived projects"} />;
};

export default ProjectArchive;
