import React from "react";
import { useGetMissionList } from "src/hooks/useGetMissionList";
import { getUser } from "../../../services/auth";
import NoMissionsMessage from "./shared/NoMissionsMessage";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../theme";
import FilterMission from "./FilterMission";
import GlobalTable from "../admin/GlobalTable";
import {
  getClientOrderStatusLabelData,
  getMissionStatusLabelData,
  getPaymentStatusLabelData,
} from "../../../lib/helpers";
import {
  invoicePaymentStatus,
  missionStatusLabels,
  missionTypeConstant,
  colorStatus,
  statusAlertSeverity,
  missionStatus,
} from "../../../lib/constants";
import { navigate } from "gatsby";
import StatusBadge from "../../global/elements/StatusBadge";
import UniversalLoader from "../../global/elements/UniversalLoader";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import { unauthorizedStatusAlert } from "../../../lib/styleConstants";

const styles = {
  columns: {
    float: "left",
    clear: "none",
    display: "flex",
    alignItems: "flex-start",
    padding: "0 15px",
    marginBottom: "1rem",
  },
  filter: {
    marginTop: "10px",
  },
  missionContainer: {
    width: "100%",
    padding: "0px",
  },
  info_message: {
    fontSize: { xs: "12px", md: ".73864rem" },
    textAlign: "center",
    color: theme.palette.status.errorDark,
  },
  paymentStatusRow: {
    display: "flex",
    width: "90px",
    marginBottom: "2px",
    "& h6": {
      width: "auto !important",
    },
  },
  loaderDiv: {
    paddingTop: "18%",
  },
  fontWeightStyle: {},
};

const MissionPage = ({ status, columns, missionType }) => {
  const getStatus = status;
  const currentUser = getUser("user");
  const [
    authorized,
    filteredMissionList,
    isLoading,
    limit,
    setLimit,
    filter,
    setFilter,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllMission,
    pageChanged,
    setPageChanged,
    setDataLoadedTill,
    setLastLoadedMission,
    sortBy,
    setSortBy,
    setFilterorSortTriggered,
  ] = useGetMissionList(getStatus, null, missionType);

  const limitHandler = (value) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    setFilterorSortTriggered(true);
    setLimit(value);
    setDataLoadedTill(0);
    setAllMission([]);
    setPreviousPage(0);
    setLoadHandler(!loadHandler);
    setAllMission([]);
    setLastLoadedMission(null);
    setPagedata(pageDataNew);
  };

  const paginationBtnClick = (type) => {
    let pageDataNew = pageData;
    if (type == "next") {
      if (!(pageDataNew.lastPage == pageDataNew["currentPage"])) {
        pageDataNew["currentPage"] = pageDataNew["currentPage"] + 1;
      }
    } else {
      pageDataNew["currentPage"] = pageDataNew["currentPage"] - 1;
    }
    setPageChanged(!pageChanged);
    setPagedata(pageDataNew);
  };

  const sortByHandler = (sort) => {
    setLoadHandler(!loadHandler);
    setAllMission([]);
    setDataLoadedTill(0);
    setLastLoadedMission(null);
    setPreviousPage(0);
    if (sort === sortBy[0]) {
      const order = sortBy[1] === "asc" ? "desc" : "asc";
      setSortBy([sort, order]);
      setFilterorSortTriggered(true);
    } else {
      setSortBy([sort, "asc"]);
      setFilterorSortTriggered(true);
    }
  };

  const valueChangeHandler = (value, field, filterFields) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    let filterDataNew = filter;
    if ((value == "") & (field == "")) {
      let changedField = Object.keys(filter).filter(
        (fld) => filterFields.indexOf(fld) == -1
      );
      changedField.forEach((chgFld) => {
        if (chgFld == "Mission Type") {
          filterDataNew[chgFld] = [];
        } else {
          filterDataNew[chgFld] = "";
        }
      });
    } else if ((value == "InitialLoad") & (field == "InitialLoad")) {
      let filterDataSaved = JSON.parse(localStorage.getItem("filter"));
      if (
        (getStatus == missionStatus.unassigned) &
        (currentUser.role == "pilot")
      ) {
        filterDataSaved["Mission Name"] = "";
        filterDataNew = filterDataSaved;
      } else {
        filterDataNew = filterDataSaved;
      }
    } else {
      filterDataNew[field] = value;
    }

    localStorage.setItem("filter", JSON.stringify(filterDataNew));

    let applyFilterOn = {};
    filterFields.forEach((opt) => {
      if (filterDataNew[opt]) {
        applyFilterOn[opt] = filterDataNew[opt];
      }
    });

    setPreviousPage(0);
    setPagedata(pageDataNew);
    setFilter(applyFilterOn);
    setLoadHandler(!loadHandler);
    setAllMission([]);
    setDataLoadedTill(0);
    setLastLoadedMission(null);
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "paymentStatus": {
        if (
          value.missionType !== missionTypeConstant.clientOrder &&
          value.allowInvoice
        ) {
          const statusLabel = getPaymentStatusLabelData(value, "pilot");
          return (
            <Box>
              <Box sx={styles.paymentStatusRow}>
                <StatusBadge
                  name={statusLabel.label}
                  status={statusLabel.statusColor}
                  small
                />
              </Box>
            </Box>
          );
        } else if (value.missionType === missionTypeConstant.clientOrder) {
          const statusLabel = getMissionStatusLabelData(value, currentUser);
          const paymentPilotStatusLabel = getPaymentStatusLabelData(
            value,
            "pilot"
          );
          const paymentClientStatusLabel = getPaymentStatusLabelData(
            value,
            "client"
          );
          const label =
            value["paymentStatusPilot"] == invoicePaymentStatus.require_edit
              ? paymentPilotStatusLabel.label
              : "Pilot " + value["paymentStatusPilot"];
          return (
            <Box>
              <Box>
                <Box>
                  <Box sx={styles.paymentStatusRow}>
                    <StatusBadge
                      name={label || "n/a"}
                      status={paymentPilotStatusLabel.statusColor}
                      small
                    />
                  </Box>
                  {value["paymentStatusPilot"] !== "paid" && (
                    <Box
                      sx={{ ...styles.info_message, ...styles.status_text }}
                      dangerouslySetInnerHTML={{
                        __html: statusLabel.info,
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box>
                <Box>
                  <Box sx={styles.paymentStatusRow}>
                    {value.subscriptionId ? (
                      <StatusBadge
                        name={"SUBSCRIPTION"}
                        status={
                          paymentClientStatusLabel.statusColor ===
                          colorStatus.green
                            ? colorStatus.green
                            : paymentClientStatusLabel.statusColor ===
                              colorStatus.red
                            ? colorStatus.red
                            : colorStatus.yellow
                        }
                      />
                    ) : (
                      <StatusBadge
                        name={"Client " + value["paymentStatusClient"] || "n/a"}
                        status={paymentClientStatusLabel.statusColor}
                        small
                      />
                    )}
                  </Box>
                  {value["paymentStatusClient"] !== "paid" && (
                    <Box
                      sx={{ ...styles.info_message, ...styles.status_text }}
                      dangerouslySetInnerHTML={{
                        __html: statusLabel.info,
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          );
        } else {
          return (
            <Box sx={styles.paymentStatusRow}>
              <StatusBadge name="not applicable" status={colorStatus.neutral} />
            </Box>
          );
        }
      }
      case "status": {
        const statusLabel = getMissionStatusLabelData(value, currentUser);
        return (
          <Box>
            <Box>
              <StatusBadge
                name={
                  statusLabel.label === missionStatusLabels.correction
                    ? missionStatusLabels.inProgress
                    : statusLabel.label
                }
                status={statusLabel.statusColor}
                small
              />
              <Box
                dangerouslySetInnerHTML={{
                  __html: statusLabel.info,
                }}
                sx={styles.info_message}
              />
            </Box>
          </Box>
        );
      }
      case "clientOrderStatus": {
        const statusLabel = getClientOrderStatusLabelData({
          missionData: value,
        });
        return (
          <Box>
            <Box>
              <StatusBadge
                name={
                  statusLabel.label === missionStatusLabels.correction
                    ? missionStatusLabels.inProgress
                    : statusLabel.label
                }
                status={statusLabel.statusColor}
                small
              />
              <Box
                dangerouslySetInnerHTML={{
                  __html: statusLabel.info,
                }}
                sx={styles.info_message}
              />
            </Box>
          </Box>
        );
      }
      case "clientPaymentStatus": {
        if (
          value.missionType === missionTypeConstant.clientOrder ||
          value.allowInvoice
        ) {
          const statusLabel = getPaymentStatusLabelData(value, "pilot");
          return (
            <Box>
              <Box>
                <StatusBadge
                  name={statusLabel.label}
                  status={statusLabel.statusColor}
                  small
                />
              </Box>
            </Box>
          );
        } else {
          return <StatusBadge name="not applicable" status={undefined} />;
        }
      }
      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    navigate(`/app/missions/${value.id}`);
  };

  if (filteredMissionList.length !== 0 || isLoading === true || filter != {}) {
    return (
      <section>
        {authorized == true ? (
          <Box sx={styles.missionContainer}>
            <Box sx={styles.filter}>
              <FilterMission
                valueChangeHandler={valueChangeHandler}
                role={currentUser.role}
                missionTypeData={getStatus}
                missionType={missionType}
              />
            </Box>
            <GlobalTable
              columns={columns}
              allData={filteredMissionList}
              valueConvertHandler={valueConvertHandler}
              goToPageFcn={goToPageFcn}
              loading={isLoading}
              limitHandler={limitHandler}
              limit={limit}
              paginationBtnClick={paginationBtnClick}
              currentPage={pageData.currentPage}
              isLastPage={pageData.isLast}
              lastPage={pageData.lastPage}
              sortByHandler={sortByHandler}
            />
          </Box>
        ) : authorized == false ? (
          <Box sx={unauthorizedStatusAlert}>
            <StatusAlertContainer
              heading="Unauthorized access! Your admin type does not allow you to work with missions. 
              Please contact Napoleon to change your access rights."
              severity={statusAlertSeverity.error}
              coloredBackground
            />
          </Box>
        ) : (
          <Box sx={styles.loaderDiv}>
            <UniversalLoader />
          </Box>
        )}
      </section>
    );
  } else {
    return (
      <>
        {(currentUser.role === "pilot") & (getStatus == "unassigned") ? (
          <NoMissionsMessage
            title={`You have no mission inquiries :(`}
            description={`Missions that you get invited to will be available here.`}
            actionButton={null}
          />
        ) : (
          <NoMissionsMessage
            title={`No ${getStatus} missions :(`}
            description={`Currently there are no ${getStatus} missions.`}
            actionButton={null}
          />
        )}
      </>
    );
  }
};

MissionPage.propTypes = {
  status: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  missionType: PropTypes.any,
};

MissionPage.defaultProps = {
  missionType: undefined,
};

export default MissionPage;
