import React from "react";
import FileUploadTab from "./FileUploadTab/FileUploadTab";
import ProjectCreateEditTab from "./ProjectCreateEditTab/ProjectCreateEditTab";
import { Box, AppBar } from "@mui/material";
import TabPanel from "../../../global/elements/TabPanel";
import { useStateIfMounted } from "use-state-if-mounted";
import PropTypes from "prop-types";
import ProjectHeaders from "../Components/ProjectHeaders";
import { userRoles } from "../../../../lib/constants";
import ProjectMissionEditTab from "./ProjectMissionsEditTab/ProjectMissionEditTab";
import { projectStatus } from "../../../../lib/constants";
import { archiveProject } from "../../../../services/project";
import { useSnackbar } from "notistack";
import firebase from "src/firebase";
import Layout from "../../../../Layout";
import { StyledTab, StyledTabs, appBar } from "../../../../lib/styleConstants";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CreateEditProjectMainLayout({
  projectData,
  currentUser,
  setReloadProject,
  reloadProject,
}) {
  const [archived, setArchived] = useStateIfMounted(false);
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useStateIfMounted(0);

  const handleTabChange = (e, newTabValue) => {
    setTabValue(newTabValue);
    // setRefreshMission(true);
  };

  const onArchive = () => {
    setArchived(true);
    if (projectData && projectData.id) {
      archiveProject(firebase, projectData.id)
        .then((data) => {
          if (data && data.status == "Success") {
            setArchived(false);
            enqueueSnackbar("Project archived successfully!", {
              variant: "success",
            });
          } else {
            setArchived(false);
            enqueueSnackbar(
              data && data.message
                ? data.message
                : "Error in archiving project",
              { variant: "error" }
            );
          }
          setReloadProject(!reloadProject);
        })
        .catch((e) => {
          setArchived(false);
          enqueueSnackbar("Error in archiving project", {
            variant: "error",
          });
          console.log("error", e);
          setReloadProject(!reloadProject);
        });
    }
  };

  return (
    <Layout>
      <Box sx={{ width: "100%" }}>
        {projectData ? (
          <ProjectHeaders
            role={currentUser?.role}
            projectId={projectData?.id}
            projectName={projectData?.projectName}
            editFlag={true}
            backNavPath={
              currentUser?.role === userRoles.admin
                ? `/app/admin/projects/${projectData.id}`
                : `/app/client/projects/${projectData.id}`
            }
          />
        ) : (
          <ProjectHeaders
            role={currentUser?.role}
            createFlag={true}
            backNavPath={
              currentUser?.role === userRoles.admin
                ? "/app/admin/projects/initial-project"
                : "/app/client/projects/initial-project"
            }
          />
        )}

        <br />
        <AppBar position="static" sx={appBar} elevation={0}>
          <StyledTabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <StyledTab
              key="project details"
              label="project Details"
              {...a11yProps(0)}
            />
            {projectData && (
              <StyledTab key="sites" label="sites" {...a11yProps(0)} />
            )}
            {!projectData?.recurrence &&
              (projectData?.status === projectStatus.draft ||
                projectData?.status === projectStatus.initial) && (
                <StyledTab
                  key="upload new sites"
                  label="upload new sites"
                  {...a11yProps(0)}
                />
              )}
          </StyledTabs>
        </AppBar>
      </Box>
      {/* project edit */}
      <TabPanel value={tabValue} index={0}>
        <ProjectCreateEditTab
          projectData={projectData}
          currentUser={currentUser}
          onArchive={onArchive}
          archived={archived}
        />
      </TabPanel>
      {projectData && (
        <>
          {/* sites */}
          <TabPanel value={tabValue} index={1}>
            <ProjectMissionEditTab
              projectData={projectData}
              onArchive={onArchive}
              archived={archived}
              reloadProject={reloadProject}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <FileUploadTab
              projectId={projectData?.id}
              projectName={projectData?.projectName}
            />
          </TabPanel>
        </>
      )}
    </Layout>
  );
}

CreateEditProjectMainLayout.propTypes = {
  projectData: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  setReloadProject: PropTypes.func.isRequired,
  reloadProject: PropTypes.bool,
};

CreateEditProjectMainLayout.defaultProps = {
  projectData: undefined,
  reloadProject: false,
};

export default CreateEditProjectMainLayout;
