import React, { useState } from "react";
import Layout from "../../../Layout";
import SectionHeading from "../../global/elements/SectionHeading";
import theme from "../../../theme";
import { Typography, Box } from "@mui/material";
import useGetNews from "../../../hooks/useGetNews";
import { singleNewsDelete } from "../../../services/news-inspiration";
import { navigate } from "gatsby";
import { getUser } from "../../../services/auth";
import AppButton from "../../global/elements/AppButton";
import PropTypes from "prop-types";
import UniversalLoader from "../../global/elements/UniversalLoader";

const SingleNewsPage = ({ newsId }) => {
  const user = getUser("user");

  const style = {
    main: {
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "15px",
      padding: { xs: "24px 19px 50px 19px", md: "50px" },
      paddingBottom: "80px",
      minHeight: "70vh",
      position: "relative",
    },
    title: {
      paddingTop: "30px",
    },
    description: {
      fontWeight: 380,
      fontSize: "18px",
      wordSpacing: "3px",
      minHeight: "37vh",
    },
    button: {
      position: "absolute",
      right: "15px",
      top: "15px",
    },
    DashDelContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: { xs: "20px" },
      textAlign: { xs: "center" },
    },
    descContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: 500,
      overflow: "hidden",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        outline: "0px solid slategrey",
      },
    },
    loaderDiv: {
      paddingTop: "18%",
    },
  };
  const [news, loading] = useGetNews(newsId);
  const [btn_loading, setBtn_loading] = useState(false);

  const newsDeleteHandler = async () => {
    setBtn_loading(true);
    const status = await singleNewsDelete(newsId);
    if (status.status == "Success") {
      setBtn_loading(false);
      navigate("/app/admin/news");
    }
  };

  return (
    <Layout pageTitle="News" hasSideMenu loading={loading}>
      {loading ? (
        <Box sx={style.loaderDiv}>
          <UniversalLoader />
        </Box>
      ) : (
        <>
          <SectionHeading
            heading={
              news?.title?.length > 50
                ? news?.title?.slice(0, 50) + "..."
                : news?.title
            }
            backgroundColor={theme.palette.grey.warmGrey}
          />
          <br />
          <Box sx={style.main}>
            <Typography variant="h5" style={style.title}>
              {news?.title}
            </Typography>
            {user?.role == "admin" && (
              <AppButton
                look="black"
                label="Edit"
                url={`/app/admin/news/edit/${newsId}`}
                internalLink
                addtionalStyle={style.button}
              />
            )}
            <br />

            <Box sx={style.descContainer}>
              <Typography variant="h5" style={style.description}>
                {news?.description}
              </Typography>
            </Box>

            <Box sx={{ ...style.DashDelContainer, paddingLeft: "0px" }}>
              <Box>
                {user?.role == "admin" ? (
                  <AppButton
                    look="inverted"
                    label="Back"
                    noIcon
                    url={`/app/admin/news`}
                    internalLink
                  />
                ) : (
                  <AppButton
                    look="inverted"
                    noIcon
                    label="Back"
                    url={`/app/dashboard/info`}
                    internalLink
                  />
                )}
              </Box>
              <Box>
                {user?.role == "admin" && (
                  <AppButton
                    look="negative"
                    label="Delete"
                    onClick={newsDeleteHandler}
                    submittingState={btn_loading}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Layout>
  );
};
SingleNewsPage.propTypes = {
  newsId: PropTypes.func,
};
SingleNewsPage.defaultProps = {
  newsId: () => {},
};
export default SingleNewsPage;
