import { Hidden, LinearProgress } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import Seo from "src/components/Seo";
import { getUser } from "./services/auth";
import SideMenu from "./components/global/SideMenu/SideMenu";
import theme from "./theme";
import { Box, GlobalStyles, Container, Typography } from "@mui/material";
import Feedback from "./components/Feedback";
import { userRoles, users } from "./lib/constants";
import BackNav from "./components/global/elements/BackNav";
import { paddingBottomBigPages } from "./lib/styleConstants";
import MenuBar from "./components/global/elements/MenuBar";

const styles = {
  root: {
    display: "flex",
    overflowX: "hidden !important",
    "& .pac-container": {
      display: "none !important",
    },
  },
  loadingIndicator: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
  },
  // necessary for content to be below app bar
  toolbarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflowX: "hidden",
    background: theme.palette.grey.white,
    paddingTop: 0,
  },
  container: {
    background: theme.palette.grey.white,
    overflowY: "auto",
  },
  bodyContainer: {
    width: "100%",
    padding: { xs: "0px", md: "10px 10px 0px 0px" },
  },
};
const Layout = ({
  children,
  pageTitle,
  className,
  description,
  hasSideMenu,
  loading,
  backButton,
  backNavUrl,
  addBottomPadding,
  backButtonPageName,
}) => {
  const [sideMenuMobileOpen, setSideMenuMobileOpen] = useStateIfMounted(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    let userProfile = getUser(users.userProfile);
    // setUserData(JSON.parse(localStorage.getItem("userProfile")));
    if (userProfile) {
      setUserData(userProfile);
    }
  }, []);

  useEffect(() => {
    if (userData && userData?.id) {
      window.userGuiding.identify(userData.id, {
        name: userData?.name || "not inserted",
        email: userData?.email || "not inserted",
      });
    }
  }, [userData, userData?.id]);

  const handleSideMenuToggle = () => {
    setSideMenuMobileOpen(!sideMenuMobileOpen);
  };

  const PrivateLayout = () => (
    <Box sx={styles.root}>
      {getUser("user")?.role === userRoles.pilot && <Feedback />}
      {loading && <LinearProgress sx={styles.loadingIndicator} />}
      {hasSideMenu && (
        <>
          {/* <Hidden mdUp>
            <MobileAppBar handleDrawerToggle={handleSideMenuToggle} />
          </Hidden> */}
          <SideMenu
            mobileOpen={sideMenuMobileOpen}
            handleDrawerToggle={handleSideMenuToggle}
          />
        </>
      )}
      <main style={{ ...styles.content, ...className }}>
        {hasSideMenu && (
          <Hidden mdUp>
            <Box sx={styles.toolbarSpacer} />
          </Hidden>
        )}
        <MenuBar
          profileImage={userData?.profileImageURL || ""}
          country={userData?.location || ""}
          enterpriseId={userData.enterPriseId}
        />

        <Container
          sx={
            addBottomPadding
              ? { ...paddingBottomBigPages, ...styles.container }
              : styles.container
          }
          maxWidth="xl"
        >
          {backButton && (
            <BackNav pageName={backButtonPageName} routeTo={backNavUrl} />
          )}
          {pageTitle && !backButtonPageName && (
            <Typography variant="h3">{pageTitle}</Typography>
          )}

          <Box sx={styles.bodyContainer}>{children}</Box>
        </Container>
      </main>
    </Box>
  );

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            ".pac-container": {
              zIndex: "100000 !important",
            },
          },
        }}
      />
      <Seo
        title={pageTitle}
        description={description} //image={blogsImage}
      />

      {/* OLD TO KEEP FOR NOW */}
      {/* <Script
        id="userGuiding"
        dangerouslySetInnerHTML={{
          __html: `
          (function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','515983749ID'); 
          `,
        }}
      />
      {userData && userData?.id && (
        <script
          id="unique-id"
          dangerouslySetInnerHTML={{
            __html: `window.userGuiding.identify("${userData.id}")`,
          }}
        ></script>
      )} */}
      <PrivateLayout />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.object,
  pageTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  hasSideMenu: PropTypes.bool,
  loading: PropTypes.bool,
  backButton: PropTypes.bool,
  backNavUrl: PropTypes.string,
  addBottomPadding: PropTypes.bool,
  backButtonPageName: PropTypes.string,
};

Layout.defaultProps = {
  description: undefined,
  className: {},
  children: undefined,
  loading: false,
  hasSideMenu: false,
  backButton: false,
  backNavUrl: "",
  addBottomPadding: false,
  backButtonPageName: undefined,
};

export default Layout;
