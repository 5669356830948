import { generateKeywordsForSearch, getCurrentDateTime } from "../lib/helpers";
import firebase from "src/firebase";
import { updateClient } from "./client";
import { getUser, setUserData } from "./auth";
import { defaultCurrency, enterPriseUserRole, users } from "../lib/constants";
import { updateApiKey } from "./apiKeys";

export const updateEnterPrise = (enterPriseId, updates) => {
  const date = getCurrentDateTime();
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("enterprise")
      .doc(enterPriseId)
      .update({
        ...updates,
        lastUpdated: date,
      })
      .then(() => {
        resolve({ status: "Success" });
      })
      .catch((e) => {
        console.log("error", e);
        reject("Errior in updating");
      });
  });
};

export const createEnterPrise = async (clientId, currency, clientApiKey) => {
  const date = getCurrentDateTime();
  const currentUser = getUser("userProfile");
  const searchName = generateKeywordsForSearch(currentUser?.companyName || "");
  try {
    const enterPriseInfo = {
      name: currentUser?.companyName || null,
      searchName: searchName || [],
      companyAddress: currentUser?.companyAddress || "",
      deleted: false,
      teamMembers: [],
      teamMemberInfo: [],
      invitationList: [],
      createdBy: clientId,
      createdAt: date,
      currency: currency || defaultCurrency,
      apiKey: clientApiKey || null,
      city: currentUser?.city || "",
      postalCode: currentUser?.postalCode || "",
      location: currentUser?.location || "",
      phone: currentUser?.phone || "",
      email: currentUser?.email || "",
      registrationNumber: currentUser?.registrationNumber || "",
      vatNumber: currentUser?.vatNumber || "",
      industry: currentUser?.industry || "",
    };

    const enterprise = await firebase
      .firestore()
      .collection("enterprise")
      .add(enterPriseInfo);

    if (enterprise && enterprise.id) {
      await updateClient(clientId, {
        enterPriseId: enterprise.id,
        enterPrise: true,
        roleType: enterPriseUserRole.admin,
      });
      await updateApiKey(firebase, clientApiKey, {
        enterPriseId: enterprise.id,
      });
      currentUser.enterPriseId = enterprise.id;
      currentUser.roleType = enterPriseUserRole.admin;
      currentUser.enterPrise = true;
      setUserData(currentUser, users.userProfile);
    }
    return {
      status: "Success",
      enterprise: { id: enterprise.id, ...enterPriseInfo },
    };
  } catch (e) {
    console.log("Error", e);
    return { status: "Error" };
  }
};

export const fetchEnterPrise = async (enterPriseId) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("enterprise")
      .doc(enterPriseId)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({ id: snapshot.id, ...snapshot.data() });
        } else {
          reject(null);
        }
      })
      .catch((e) => {
        console.log("Error in fetching enterprise", e);
        reject(null);
      });
  });
};

export const getAllEnterprise = (filter, lastLoadedEnterprise, limit) => {
  try {
    let query = firebase
      .firestore()
      .collection("enterprise")
      .where("deleted", "==", false);

    if (filter && filter["name"]) {
      if (filter["name"]?.includes("@")) {
        query = query.where("email", "==", filter["name"]);
      } else {
        const searchDocName = generateKeywordsForSearch(filter?.name);
        query = query.where("searchName", "array-contains-any", searchDocName);
      }
    }
    if (filter && filter["location"]) {
      query = query.where("location", "==", filter["location"]);
    }
    if (lastLoadedEnterprise) {
      query = query.startAfter(lastLoadedEnterprise);
    }
    return query
      .limit(Number(limit))
      .get()
      .then(async (snapshot) => {
        let enterprises = [];
        snapshot.docs?.forEach((doc) => {
          const data = doc.data();
          enterprises.push({
            ...data,
            id: doc.id,
            viewUrl: `/app/admin/client-enterprise/enterprise/${doc.id}`,
            noOfMembers: data?.teamMembers?.length || 0,
          });
        });
        return {
          status: "Success",
          enterprises: enterprises,
          lastVisible:
            snapshot.size > 0 ? snapshot.docs[snapshot.docs.length - 1] : null,
          last: snapshot.size < Number(limit),
        };
      });
  } catch (err) {
    console.log("Error", err);
  }
};
