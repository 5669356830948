import { call, fork, put, take, select } from "redux-saga/effects";
import * as actions from "../actions/cart-detail-action";
import { TYPES } from "../types";

function* addAllToCart(missions) {
  try {
    const result = missions;
    yield put(
      actions.addAllItemsSuccess({
        data: result.missions,
      })
    );
  } catch (e) {
    console.log("Error", e);
    yield put(
      actions.getCartDetailError({
        error: "An error occured while trying to add cart",
      })
    );
  }
}

function* watchAddAllToCart() {
  while (true) {
    const action = yield take(TYPES.ADD_ALL_TO_CART);
    yield call(addAllToCart, {
      missions: action.payload,
    });
  }
}

function* fetchCartDetails(missionData) {
  try {
    const result = missionData;
    yield put(
      actions.getCartDetailSuccess({
        data: result.missionData,
      })
    );
  } catch (e) {
    console.log("Error", e);
    yield put(
      actions.getCartDetailError({
        error: "An error occured while trying to add cart",
      })
    );
  }
}

function* watchFetchCart() {
  while (true) {
    const action = yield take(TYPES.GET_CART_DETAIL);
    yield call(fetchCartDetails, {
      missionData: action.payload.missionData,
    });
  }
}

function* watchdeleteItem() {
  while (true) {
    const action = yield take(TYPES.DELETE_CART_ITEM);
    yield call(deleteCartItem, {
      id: action.data.id,
    });
  }
}
function* deleteCartItem({ id }) {
  try {
    if (id) {
      const state = yield select();
      const newArray = [...state.CartReducer.carts];

      state.CartReducer.carts.map((data, i) => {
        if (data.id === id.id) {
          newArray.splice(i, 1);
        }
      });
      yield put(
        actions.deleteItemSuccess({
          data: newArray,
        })
      );
    }
  } catch (e) {
    console.log("Error", e);
    yield put(
      actions.getCartDetailError({
        error: "An error occured while trying to deleting from the cart",
      })
    );
  }
}

const CartDetailSaga = [
  fork(watchFetchCart),
  fork(watchdeleteItem),
  fork(watchAddAllToCart),
];

export default CartDetailSaga;
