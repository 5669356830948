import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import firebase from "src/firebase";
// import { useSnackbar } from "notistack";
// import UploadClientInvoice from "../../../Invoices/UploadClientInvoice";
import AddQuotes from "../../Components/AddQuotes";
import {
  createQuote,
  sendClientDeclineToAdmin,
  updateProject,
  updateProjectMissionStatus,
  updateQuote,
} from "../../../../../services/project";
import PropTypes from "prop-types";
import ProjectQuoteTable from "../../Components/ProjectQuoteTable";
import {
  missionStatus,
  notificationType,
  projectStatus,
  quoteStatus,
  userRoles,
} from "../../../../../lib/constants";
import {
  clientProjectQuoteEmail,
  projectEmail,
} from "../../../../../services/clientEmail";
import { createNotification } from "../../../../../services/notification";
import { boxShadowStyle } from "../../../../../lib/styleConstants";
import { getUser } from "../../../../../services/auth";
import {
  getQuoteTotalCost,
  setQuoteModifyBy,
  getQuoteCurrency,
  getCurrentDateTimeMillis,
} from "../../../../../lib/helpers";
import QuoteContext from "./QuoteContext";
import { getQuotesList } from "../../../../../services/project";
import QuoteBoxesAndCreate from "../../../quotes/QuoteBoxesAndCreate";
import QuoteInvoiceSection from "../../../quotes/QuoteInvoiceSection";

const styles = {
  quoteDetailsCls: {
    marginTop: "20px",
    marginBottom: "20px",
  },
};

const InnerCompHeader = ({ label }) => {
  InnerCompHeader.propTypes = {
    label: PropTypes.string.isRequired,
  };
  return (
    <Box sx={styles.quoteDetailsCls}>
      <Typography variant="body1">{label}</Typography>
    </Box>
  );
};

const QuotesTab = ({
  projectId,
  project,
  role,
  triggerReload,
  setTriggerReload,
  currency,
}) => {
  // const [clientInvoiceUrl, setClientInvoiceUrl] = useState(
  //   project?.clientInvoiceUrl
  // );
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [acceptedQuote, setAcceptedQuote] = useState(null);
  const [acceptedQuoteTotalCost, setAcceptedQuoteTotalCost] = useState(null);
  const [acceptedQuoteCurrency, setAcceptedQuoteCurrency] = useState(null);
  // const { enqueueSnackbar } = useSnackbar();
  const [quoteInvoiceData, setQuoteInvoiceData] = useState(null);
  const [showNoQuoteDialogue, setShowNoQuoteDialogue] = useState(false);

  const user = getUser("userProfile");
  const isAdmin = user.role === userRoles.admin;
  const isClient = user.role === userRoles.client;

  useEffect(() => {
    if (
      project?.status == projectStatus.initial ||
      project?.status == projectStatus.draft
    ) {
      setDisableUploadButton(true);
    }
    fetchQuotes();
  }, []);

  const fetchQuotes = async () => {
    try {
      getQuotesList(firebase, projectId, role)
        .then((data) => {
          if (data?.quotes) {
            setQuotes(data?.quotes);
          }
        })
        .catch((e) => {
          console.log("Error", e);
        });
    } catch (error) {
      console.error("Error fetching quote:", error);
    }
  };

  useEffect(() => {
    if (quotes.length) {
      const acceptedQuote = quotes.find(
        (rec) => rec.status == quoteStatus.accepted
      );
      if (acceptedQuote) {
        setAcceptedQuote(acceptedQuote);
        let price = getQuoteTotalCost(acceptedQuote);
        let currency = getQuoteCurrency(acceptedQuote);
        setAcceptedQuoteCurrency(currency);
        setAcceptedQuoteTotalCost(price);
      }
    }
  }, [quotes]);
  useEffect(() => {
    if (project?.status != projectStatus.active) {
      setShowNoQuoteDialogue(true);
    }
  }, [project.status]);

  // const uploadInvoiceURL = async (url) => {
  //   try {
  //     if (url) {
  //       let updates = {
  //         clientInvoiceUrl: url,
  //         id: projectId,
  //       };
  //       const resp = await updateProject({ projectId, updates });
  //       if (resp.status == "Success") {
  //         setClientInvoiceUrl(url);
  //         enqueueSnackbar("Client Invoice uploaded successfuly!", {
  //           variant: "success",
  //         });
  //         setTriggerReload(!triggerReload);
  //       } else {
  //         enqueueSnackbar("Error in uploading client invoice", {
  //           variant: "error",
  //         });
  //       }
  //     } else {
  //       enqueueSnackbar("Error in uploading client invoice!!", {
  //         variant: "error",
  //       });
  //     }
  //   } catch (e) {
  //     console.log("Error", e);
  //     enqueueSnackbar("Internal Error : Error in uploading client invoice!!", {
  //       variant: "error",
  //     });
  //   }
  // };

  const addQuote = async (values) => {
    try {
      // let projectId = project?.id;
      let issuedBy = setQuoteModifyBy(user);

      return new Promise((resolve, reject) => {
        createQuote({
          projectId,
          quote: { ...quoteInvoiceData, issuedBy, currency },
        })
          .then((data) => {
            if (data.status == "Success") {
              resolve({
                status: "Success",
                message: "Quote Added successfully",
              });
              setReloadTable(!reloadTable);
              fetchQuotes();
              // Send quote email to client & create notification for client
              if (project.clientId) {
                clientProjectQuoteEmail({ project, quote: values });
                createNotification(
                  firebase,
                  project.enterPriseId || project.clientId,
                  "A quote for your order is now available.",
                  "A quote for your order is now available.",
                  `/app/client/projects/${projectId}`
                );
              }
            } else {
              reject({
                status: "Error",
                message: data?.message || "Error in adding quote",
              });
            }
          })
          .catch((e) => {
            reject({
              status: "Error",
              message: e?.message || "Error in adding Quote to the mission",
            });
          });
      });
    } catch (e) {
      console.log("Error", e);
      throw { status: "Error", message: e?.message || "Error in adding quote" };
    }
  };

  const sendEmailsAndNotifications = () => {
    try {
      // projectQuoteAcceptedByClient({ project });
      projectEmail({
        project,
        functionName: "sendEmailOnProjectQuoteAcceptedByClient",
        errorMessage: "Error in sending project quote accepting emails",
      });
      if (project.enterPriseId) {
        createNotification(
          firebase,
          project.enterPriseId,
          "Quote is accepted by a team member",
          "Quote is accepted by a team member",
          `/app/client/projects/${project.id}`
        );
      }
      createNotification(
        firebase,
        notificationType.adminNotification,
        "A project quote is accepted by client",
        "A project quote is accepted by client",
        `/app/admin/projects/${project.id}`
      );
    } catch (e) {
      console.log("error", e);
    }
  };

  const updateQuoteStatus = ({ quote, status }) => {
    let updates = {
      status: status,
      acceptedOrRecjectedByClient: isClient ? user.id : null,
    };
    if (quoteStatus.deleted == status) {
      let cancelBy = setQuoteModifyBy(user);
      updates.cancelBy = cancelBy;
      updates.deleted = true;
    }
    if (status === quoteStatus.declined) {
      updates.rejectReason = quote.rejectReason;
    }
    try {
      return new Promise((resolve, reject) => {
        updateQuote(projectId, quote?.id, updates)
          .then((data) => {
            if (data.status == "Success") {
              resolve({
                status: "Success",
                message: "Quote Updated successfully",
              });
              // Change the project status to active
              if (status == quoteStatus.accepted) {
                let updates = {
                  status: projectStatus.active,
                  id: projectId,
                  quoteAcceptedByClientOn: getCurrentDateTimeMillis(),
                };
                updateProject({
                  projectId,
                  updates,
                });
                updateProjectMissionStatus({
                  projectId,
                  currentMissionStatus: [
                    missionStatus.initialProjectOrder,
                    missionStatus.draft,
                  ],
                  toUpdateMissionStatus: missionStatus.confirmedMission,
                });
                sendEmailsAndNotifications();
              } else if (status == quoteStatus.declined) {
                if (project.enterPriseId) {
                  createNotification(
                    firebase,
                    project.enterPriseId,
                    "Quote is rejected by a team member",
                    "Quote is rejected by a team member",
                    `/app/client/projects/${project.id}`
                  );
                }
                let dataForMail = {
                  clientName: user?.name,
                  clientCompany: user?.companyName,
                  country: user?.location,
                  email: user?.email,
                  price: getQuoteTotalCost(quote),
                  url: `/app/admin/projects/${projectId}`,
                };
                sendClientDeclineToAdmin(dataForMail);
              }
              setTriggerReload(!triggerReload);
              setReloadTable(!reloadTable);
            } else {
              reject({
                status: "Error",
                message: data?.message || "Error in updating quote status",
              });
            }
          })
          .catch((e) => {
            reject({
              status: "Error",
              message: e?.message || "Error in upadating Quote",
            });
          });
      });
    } catch (e) {
      console.log("Error", e);
    }
  };
  const reloadProject = () => {
    setTriggerReload(!triggerReload);
    setReloadTable(!reloadTable);
  };
  return (
    <Box>
      <QuoteContext.Provider value={quotes}>
        <Box sx={{ marginBottom: "20px" }}>
          <QuoteInvoiceSection
            missionDetails={project}
            isAdmin={isAdmin}
            isClient={isClient}
            isProject
            refreshMission={reloadProject}
          />
        </Box>
        {/** If project is active then show accepted quote and hide the add quote component */}
        <QuoteBoxesAndCreate
          showNoQuoteDialogue={showNoQuoteDialogue}
          currency={currency}
          isProject
          projectMissionCount={project?.allMissionCount}
          singleProjectMissionPrice={acceptedQuote?.pricePerUnit}
          totalProjectMissionsCost={acceptedQuoteTotalCost}
          projectCurrency={acceptedQuoteCurrency}
        />
        {project?.status == projectStatus.initial && isAdmin && (
          <>
            <InnerCompHeader label={"QUOTE DETAILS"} />
            <AddQuotes
              addQuote={addQuote}
              submitBtnFlag={project?.allMissionIds.length > 0 ? false : true}
              project={project}
              setQuoteInvoiceData={setQuoteInvoiceData}
              projectId={projectId}
              currency={currency}
              reloadTable={reloadTable}
              setReloadTable={setReloadTable}
              setDisableUploadButton={setDisableUploadButton}
              role={role}
              setQuotes={setQuotes}
              reloadProject={reloadProject}
            />
          </>
        )}

        <InnerCompHeader label={"ADDED QUOTES"} />
        <Box sx={boxShadowStyle}>
          <ProjectQuoteTable
            projectId={projectId}
            reloadTable={reloadTable}
            role={role}
            updateQuoteStatus={updateQuoteStatus}
            project={project}
          />
        </Box>
      </QuoteContext.Provider>
    </Box>
  );
};

QuotesTab.propTypes = {
  projectId: PropTypes.string.isRequired,
  role: PropTypes.string,
  project: PropTypes.object.isRequired,
  triggerReload: PropTypes.bool.isRequired,
  setTriggerReload: PropTypes.func.isRequired,
  currency: PropTypes.string,
};

QuotesTab.defaultProps = {
  role: undefined,
  currency: null,
};

export default QuotesTab;
