import React from "react";
import InvoicePage from "./InvoicePage";
import Layout from "../../../Layout";

const InvoicePagePilot = () => {
  return (
    <Layout pageTitle={"Invoices"} hasSideMenu addBottomPadding>
      <InvoicePage />
    </Layout>
  );
};

export default InvoicePagePilot;
