import React from "react";
import theme from "../../../../theme";
import { Box, Typography } from "@mui/material";
import { useStateIfMounted } from "use-state-if-mounted";
import { useSnackbar } from "notistack";
import AppButton from "../../../global/elements/AppButton";
import Dialogue from "../../../global/Dialogue";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import {
  missionStatus,
  notificationType,
  projectStatus,
  userRoles,
} from "../../../../lib/constants";
import {
  updateProject,
  updateProjectMissionStatus,
} from "../../../../services/project";
import {
  projectCreatedByAdmin,
  projectCreatedByClient,
} from "../../../../services/clientEmail";
import { createNotification } from "../../../../services/notification";

const styles = {
  dialogBody: { marginBottom: "30px", textAlign: "center" },
  dialogueContent: { color: theme.palette.grey.dark },
};

const ConfirmDraftDialogueBody = () => {
  return (
    <Box sx={styles.dialogBody}>
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        Confirm submitting your order
      </Typography>
      <Typography variant="body1">
        Once you submit this order, you can expect to receive a quote within 24
        hours. You may continue to make adjustments and add additional sites to
        your order until you have accepted the quote.
      </Typography>
    </Box>
  );
};

const ConfirmDraftProject = ({
  project,
  setTriggerReload,
  triggerReload,
  currentUser,
}) => {
  const [loading, setLoading] = useStateIfMounted(false);
  const [showDialogue, setShowDialogue] = useStateIfMounted(false);

  const { enqueueSnackbar } = useSnackbar();

  const sendEmailsAndNotifications = () => {
    if (currentUser?.role == userRoles.client) {
      // Project is created by client
      projectCreatedByClient({ project });
      createNotification(
        firebase,
        notificationType.adminNotification,
        "A project is created by client",
        "A project is created by client",
        `/app/admin/projects/${project.id}`
      );
      if (project?.enterPriseId) {
        createNotification(
          firebase,
          project?.enterPriseId,
          "A project is placed by a team member",
          "A project is placed by a team member",
          `/app/client/projects/${project.id}`
        );
      }
    } else {
      // Project is created by admin
      createNotification(
        firebase,
        project?.enterPriseId || project.clientId,
        "A project is created for you",
        "A project is created for you",
        `/app/client/projects/${project.id}`
      );
      projectCreatedByAdmin({ project });
    }
  };

  const confirmDraftProject = () => {
    try {
      setLoading(true);
      let updates = { status: projectStatus.initial };

      updateProject({ projectId: project.id, updates })
        .then(async (data) => {
          if (data && data.status == "Success") {
            await updateProjectMissionStatus({
              projectId: project.id,
              currentMissionStatus: missionStatus.draft,
              toUpdateMissionStatus: missionStatus.initialProjectOrder,
            });
            sendEmailsAndNotifications();
            setLoading(false);
            setShowDialogue(false);
            setTriggerReload(!triggerReload);
            enqueueSnackbar("Project is confirmed", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Error in confirming the project", {
              variant: "error",
            });
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          enqueueSnackbar("Error in changing project status", {
            variant: "error",
          });
          console.log("Error", e);
        });
    } catch (e) {
      setLoading(false);
      console.log("Error", e);
    }
  };

  return (
    <>
      <AppButton
        look="green"
        label="Submit order"
        onClick={() => setShowDialogue(true)}
        submittingState={loading}
      />

      {showDialogue && (
        <Dialogue
          showDialogue={showDialogue}
          setShowDialogue={setShowDialogue}
          DialogueHeadText={""}
          DialogueBody={ConfirmDraftDialogueBody()}
          showCancelButton={true}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Submit order"}
          showSubmitLoader={loading}
          setShowSubmitLoader={setLoading}
          onSubmit={() => confirmDraftProject()}
          submitButtonLook={"green"}
        />
      )}
    </>
  );
};

ConfirmDraftProject.propTypes = {
  project: PropTypes.object.isRequired,
  triggerReload: PropTypes.bool.isRequired,
  setTriggerReload: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default ConfirmDraftProject;
