import { CircularProgress, Typography, Box } from "@mui/material";
import firebase from "src/firebase";
import { SnackbarProvider } from "notistack";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import Dropzone from "react-dropzone";
import { Close } from "@mui/icons-material";
import theme from "../../../theme";
import UploadButton from "../../global/elements/UploadButton";

const styles = {
  spinnerContainer: {
    width: "100%",
  },
  spinner: {
    marginLeft: "calc(50% - 20px)",
  },
  closeIcon: {
    color: "black",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cvData: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    position: "relative",
    width: "100%",
    border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
  },
  inputField: {
    marginTop: "10px",
  },
};

const DroneCvUpload = ({ onUploaded, onRemove, currentFileName }) => {
  const [progress, setProgress] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [origFileName, setOrigFileName] = useState(currentFileName);
  const providerRef = useRef(null);

  const handleFileDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      providerRef.current.enqueueSnackbar(
        `Invalid file format '${rejectedFiles[0].type}'. Only .pdf files are allowed.`,
        { variant: "error" }
      );
      return;
    }
    if (acceptedFiles && acceptedFiles.length > 1) {
      providerRef.current.enqueueSnackbar(
        `You can only upload one single .pdf.`,
        {
          variant: "error",
        }
      );
      return;
    }
    if (acceptedFiles && acceptedFiles.length === 1) {
      handleFileUpload(acceptedFiles);
    }
  };

  const handleFileUpload = (acceptedFiles) => {
    setUploadInProgress(true);
    const file = acceptedFiles[0];
    const uploadFileName = `${+new Date()}-${file.name}`;
    setOrigFileName(file.name);

    const storageRef = firebase
      .storage()
      .ref()
      .child(`pilotCV/${uploadFileName}`);

    const uploadFile = storageRef.put(file);

    uploadFile.on(
      "state_changed",
      (snapshot) => {
        const progress = snapshot.bytesTransferred / snapshot.totalBytes;
        const roundedProgress =
          progress < 0.01
            ? Math.round(progress * 10000) / 100
            : Math.round(progress * 1000) / 10;

        switch (snapshot.state) {
          case "running":
            setProgress(roundedProgress);
            break;
          case "canceled":
            setUploadInProgress(false);
            break;
          case "paused":
            break;
          default:
            console.error("Unpexpected upload state", snapshot.state);
            break;
        }
      },
      (error) => {
        // on error
        setUploadInProgress(false);
        console.error(error);
        switch (error.code) {
          case "storage/canceled":
            providerRef.current.enqueueSnackbar(`Upload canceled`);
            break;
          default:
            providerRef.current.enqueueSnackbar(
              `An unexpected error occurred. Make sure you're uploading a .pdf file no larger than 1MB.`,
              {
                variant: "error",
              }
            );
            break;
        }
      },
      () => {
        // on success
        uploadFile.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            onUploaded(downloadURL, file.name);
          })
          .then(() => {
            providerRef.current.enqueueSnackbar(`Upload completed`, {
              variant: "success",
            });
            setUploadInProgress(false);
          })
          .catch((e) => {
            setUploadInProgress(false);
            console.log(e);
            providerRef.current.enqueueSnackbar(`Upload failed. `, {
              variant: "error",
            });
          });
      }
    );
  };

  return (
    <SnackbarProvider ref={providerRef} maxSnack={4}>
      {!currentFileName ? (
        <Box>
          {uploadInProgress ? (
            <Box sx={styles.spinnerContainer}>
              <CircularProgress
                variant="determinate"
                value={progress}
                sx={styles.spinner}
              />
            </Box>
          ) : (
            <Dropzone
              accept={[
                ".pdf",
                "application/pdf",
                "image/jpeg",
                ".jpg",
                ".png",
                ".jpeg",
              ]}
              onDrop={handleFileDrop}
            >
              {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps()} sx={{ position: "relative" }}>
                  <input {...getInputProps()} />
                  <Box sx={styles.inputField}>
                    <UploadButton text="Upload your CV" />
                  </Box>
                </Box>
              )}
            </Dropzone>
          )}
        </Box>
      ) : (
        <Box sx={styles.cvData}>
          <Typography variant="subtitle1">{origFileName}</Typography>
          <Close
            sx={{ "&.MuiSvgIcon-root": styles.closeIcon }}
            onClick={onRemove}
          ></Close>
        </Box>
      )}
    </SnackbarProvider>
  );
};

DroneCvUpload.propTypes = {
  onUploaded: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  currentFileName: PropTypes.string,
};

DroneCvUpload.defaultProps = {
  currentFileName: undefined,
};

export default DroneCvUpload;
