import React from "react";
import { Box } from "@mui/material";
// import SdgModal from "./SdgModal";
import PropTypes from "prop-types";
import sdg1 from "../../../assets/images/sdg_goals/sdg1.png";
import sdg2 from "../../../assets/images/sdg_goals/sdg2.png";
import sdg3 from "../../../assets/images/sdg_goals/sdg3.png";
import sdg4 from "../../../assets/images/sdg_goals/sdg4.png";
import sdg5 from "../../../assets/images/sdg_goals/sdg5.png";
import sdg6 from "../../../assets/images/sdg_goals/sdg6.png";
import sdg7 from "../../../assets/images/sdg_goals/sdg7.png";
import sdg8 from "../../../assets/images/sdg_goals/sdg8.png";
import sdg9 from "../../../assets/images/sdg_goals/sdg9.png";
import sdg10 from "../../../assets/images/sdg_goals/sdg10.png";
import sdg11 from "../../../assets/images/sdg_goals/sdg11.png";
import sdg12 from "../../../assets/images/sdg_goals/sdg12.png";
import sdg13 from "../../../assets/images/sdg_goals/sdg13.png";
import sdg14 from "../../../assets/images/sdg_goals/sdg14.png";
import sdg15 from "../../../assets/images/sdg_goals/sdg15.png";
import sdg16 from "../../../assets/images/sdg_goals/sdg16.png";
import sdg17 from "../../../assets/images/sdg_goals/sdg17.png";

const styles = {
  sdgGoals: {
    paddingTop: "15px",
    display: "flex",
    flexFlow: "wrap",
  },
  sdgGoalsWrapper: {
    flexDirection: "row",
    textAlign: "center",
    verticalAlign: "top",
    display: "inline-block",
    marginRight: "25px",
  },
  sdgOuterBox: {
    height: "auto",
    borderRadius: "15px",
    width: "fit-content",
  },
  sdgImage: { width: "92px", height: "93px", padding: { xs: "3%", sm: "6%" } },
};

const SdgImg = ({ sdgGoals }) => {
  // const [openSdg, setOpenSdg] = useState(false);
  // const [sdgId, setSdgId] = useState(null);

  const getSdgImg = (sdgId) => {
    switch (sdgId) {
      case "goal1":
        return <Box component="img" src={sdg1} sx={styles.sdgImage} />;
      case "goal2":
        return <Box component="img" src={sdg2} sx={styles.sdgImage} />;
      case "goal3":
        return <Box component="img" src={sdg3} sx={styles.sdgImage} />;
      case "goal4":
        return <Box component="img" src={sdg4} sx={styles.sdgImage} />;
      case "goal5":
        return <Box component="img" src={sdg5} sx={styles.sdgImage} />;
      case "goal6":
        return <Box component="img" src={sdg6} sx={styles.sdgImage} />;
      case "goal7":
        return <Box component="img" src={sdg7} sx={styles.sdgImage} />;
      case "goal8":
        return <Box component="img" src={sdg8} sx={styles.sdgImage} />;
      case "goal9":
        return <Box component="img" src={sdg9} sx={styles.sdgImage} />;
      case "goal10":
        return <Box component="img" src={sdg10} sx={styles.sdgImage} />;
      case "goal11":
        return <Box component="img" src={sdg11} sx={styles.sdgImage} />;
      case "goal12":
        return <Box component="img" src={sdg12} sx={styles.sdgImage} />;
      case "goal13":
        return <Box component="img" src={sdg13} sx={styles.sdgImage} />;
      case "goal14":
        return <Box component="img" src={sdg14} sx={styles.sdgImage} />;
      case "goal15":
        return <Box component="img" src={sdg15} sx={styles.sdgImage} />;
      case "goal16":
        return <Box component="img" src={sdg16} sx={styles.sdgImage} />;
      case "goal17":
        return <Box component="img" src={sdg17} sx={styles.sdgImage} />;
    }
  };
  // const handleOpenSdg = () => setOpenSdg(true);

  return (
    <Box sx={styles.sdgOuterBox}>
      <Box sx={styles.sdgGoals}>
        {sdgGoals?.map((item, i) => {
          return (
            <Box
              key={i}
              sx={styles.sdgGoalsWrapper}
              // onClick={() => {
              //   setSdgId(item);
              //   handleOpenSdg();
              // }}
            >
              {getSdgImg(item.id)}
            </Box>
          );
        })}
      </Box>
      {/* <SdgModal open={openSdg} setOpen={setOpenSdg} sdgId={sdgId} /> */}
    </Box>
  );
};

SdgImg.propTypes = {
  sdgGoals: PropTypes.object.isRequired,
};

export default SdgImg;
