import React, { useState } from "react";
import Layout from "../../../../Layout";
import { Grid, Typography } from "@mui/material";
import ContactSupportForm from "./ContactSupportForm";
import { navigate } from "gatsby";
import AfterInvitationPopup from "../../../forms/client-form/AfterInvitationPopup";
import { sendEmailToSales } from "../../../../services/client";
import { getUser } from "../../../../services/auth";
import { users } from "../../../../lib/constants";
import { useSnackbar } from "notistack";

const styles = {
  subHeadingCls: { marginTop: "30px", marginBottom: "20px" },
  formCls: { marginTop: "40px" },
};
const ContactSupport = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (values, setSubmitting) => {
    const currentUser = getUser(users.userProfile);
    let data = {
      clientName: currentUser?.name,
      clientCompany: currentUser?.companyName,
      country: currentUser?.location,
      email: currentUser?.email,
      type: values?.reasons?.join(",") || "",
      description: values?.description,
    };
    sendEmailToSales(data)
      .then(() => {
        setOpenPopup(true);
        enqueueSnackbar("Email is send successfully", {
          variant: "success",
        });
      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar("Error in sending email", {
          variant: "error",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <Layout hasSideMenu addBottomPadding>
      <Grid container>
        <Grid item container xs={12} md={6}>
          <Grid item xs={12}>
            <Typography variant="h2">
              Contact our <br />
              support team
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" sx={styles.subHeadingCls}>
              How can we help you?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Please select what you need help with
            </Typography>
          </Grid>
          <Grid item xs={12} sx={styles.formCls}>
            <ContactSupportForm onSubmit={onSubmit} />
          </Grid>
        </Grid>
      </Grid>

      {openPopup && (
        <AfterInvitationPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          goToDashboard={() => navigate("/app/client/my-account")}
          text={"A member of our team will get back to you quickly"}
        />
      )}
    </Layout>
  );
};

export default ContactSupport;
