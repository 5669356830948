import { Box } from "@mui/material";
import React, { useContext } from "react";
// import GlobheContact from "./GlobheContact";
import ClientInfo from "./ClientInfo";
import DetailedOrderLog from "./DetailedOrderLog";
import OrderDetails from "./OrderDetails";
import MissionContext from "./MissionContext";
import { missionTypeConstant, userRoles } from "../../../../../lib/constants";

const OrderDetailsComponent = () => {
  const orderData = useContext(MissionContext);
  return (
    <Box>
      {/* {orderData?.currentUser?.role != userRoles.admin && <GlobheContact />} */}
      {orderData?.currentUser?.role === userRoles.admin &&
        (orderData?.missionType === missionTypeConstant.clientOrder ||
          orderData?.missionType === missionTypeConstant.projectMission) && (
          <ClientInfo />
        )}
      {orderData?.currentUser?.role != userRoles.pilot &&
        orderData?.mission?.missionType === missionTypeConstant.clientOrder && (
          <DetailedOrderLog userType={orderData?.currentUser?.role} />
        )}

      <OrderDetails />
    </Box>
  );
};

export default OrderDetailsComponent;
