import {
  Box,
  Dialog,
  DialogActions,
  CircularProgress,
  Autocomplete,
  Typography,
  Chip,
} from "@mui/material";
import React, { useContext } from "react";
import AppButton from "../../../../global/elements/AppButton";
import { submitCancelBox } from "../../../../../lib/styleConstants";
import CancelIcon from "@mui/icons-material/Cancel";
import theme from "../../../../../theme";
import { Field, Form, Formik } from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { createNotification } from "../../../../../services/notification";
import firebase from "src/firebase";
import {
  rejectMission,
  updateSubscription,
} from "../../../../../services/missions";
import {
  rejectMissionReason,
  subsMissionUpdateStatus,
} from "../../../../../lib/constants";
import MissionContext from "./MissionContext";
import PropTypes from "prop-types";

const styles = {
  missionRejectFormikOuter: {
    width: "80%",
    margin: "auto",
    marginTop: "10px",
  },
  missionRejectFormik: {
    width: "100%",
  },
  missionRejectFormBox: {
    width: "100%",
    marginTop: "35px",
  },
  rejectReasonBox: {
    width: "90%",
    margin: "auto",
    marginBottom: "8px",
  },
  rejectError: {
    color: `${theme.palette.status.errorLight} !important`,
    width: "90%",
    margin: "auto",
  },
  rejectInputReason: {
    width: "100%",
    outline: "none",
    border: `0.5px solid ${theme.palette.black.coolGrey}`,
    "& fieldset": { border: "none" },
    marginBottom: "30px",
  },
  rejectDesc: {
    width: "100%",
    marginBottom: "8px",
  },
  rejectDescTextField: {
    width: "100%",
  },
  rejectDialogAction: {
    marginTop: { xs: "20px", sm: "35px" },
    marginBottom: { xs: "20px", sm: "25px" },
    display: {
      xs: "block !important",
      sm: "flex !important",
    },
    justifyContent: { sm: "center !important" },
    paddingLeft: {
      xs: "30px",
      sm: "0px",
    },
  },
  rejectDialogActionCancel: {
    marginBottom: { xs: "20px", sm: "0px" },
  },
  rejectDialogActionReject: {
    width: {
      xs: "90%",
      sm: "25%",
      xl: "26%",
    },
    display: {
      xs: "block !important",
    },
    borderRadius: "27px",
    color: `${theme.palette.grey.white} !important`,
    letterSpacing: "0.16px",
    marginLeft: {
      xs: "0px !important",
      sm: "30px !important",
    },
    background: `${theme.palette.status.errorDark} 0% 0% no-repeat padding-box`,
    "&:hover": {
      background: `${theme.palette.status.errorDark} 0% 0% no-repeat padding-box !important`,
    },
  },
};

const RejectModal = ({ showRejectDialog, setShowRejectDialog }) => {
  const data = useContext(MissionContext);

  const renderChips = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip key={index} label={option} {...getTagProps({ index })} />
    ));
  const rejectedformInitialValues = {
    rejectedReason: [],
    rejectDescription: "",
  };
  const validationSchema = Yup.object().shape({
    rejectedReason: Yup.array()
      .min(1, "Rejected reason required")
      .required("required"),
  });

  const rejectMissionHandler = async (values) => {
    await rejectMission(firebase, data?.mission?.id, values);
    await createNotification(
      firebase,
      data?.mission?.clientId,
      `Your Mission got rejected by the admin.`,
      `Your Mission got rejected by the admin`,
      `/app/client/orderDetails/${data?.mission?.id}`
    );
    if (data?.mission && data?.mission?.subscriptionId) {
      updateSubscription(firebase, {
        mission: data?.mission,
        subscriptionId: data?.mission?.subscriptionId,
        status: subsMissionUpdateStatus.rejectOrder,
      })
        .then(() => {})
        .catch((e) => {
          console.log("Error", e);
        });
    }

    data?.refreshMission();
    setShowRejectDialog(false);
  };

  return (
    <Box>
      <Dialog
        open={showRejectDialog}
        onClose={() => setShowRejectDialog(false)}
        aria-describedby="submit-packages-dialog-description"
        maxWidth="xl"
      >
        <Box>
          <Box
            sx={{
              textAlign: "right",
              paddingRight: "20px",
              marginTop: "10px",
            }}
          >
            <CancelIcon
              style={{
                color: theme.palette.status.errorLight,
                cursor: "pointer",
              }}
              onClick={() => setShowRejectDialog(false)}
            />
          </Box>
          <Box sx={styles.missionRejectFormikOuter}>
            <Typography variant="h5" sx={styles.missionRejectFormik}>
              When rejecting a client order, please select the reason. The
              client will be able to see why this order was rejected
            </Typography>

            <Box>
              <Formik
                initialValues={rejectedformInitialValues}
                validationSchema={validationSchema}
                onSubmit={rejectMissionHandler}
              >
                {({ errors, isSubmitting, setFieldValue }) => {
                  return (
                    <Form id="missionRejectForm">
                      <Box sx={styles.missionRejectFormBox}>
                        <Typography
                          variant="subtitle1Med"
                          sx={styles.rejectReasonBox}
                        >
                          Reasons for Rejecting :
                        </Typography>
                        {errors?.rejectedReason?.length > 0 ? (
                          <Typography variant="bodyy1" sx={styles.rejectError}>
                            {errors?.rejectedReason}
                          </Typography>
                        ) : (
                          ""
                        )}
                        <Autocomplete
                          multiple
                          options={rejectMissionReason}
                          onChange={(e, value) => {
                            setFieldValue("rejectedReason", value);
                          }}
                          renderTags={renderChips}
                          sx={styles.rejectInputReason}
                          variant="outlined"
                          renderInput={(params) => (
                            <Field
                              {...params}
                              type="text"
                              name="rejectedReason"
                              component={TextField}
                              variant="outlined"
                              placeholder="Select your reasons"
                            />
                          )}
                        />
                        <Typography
                          variant="subtitle1Med"
                          sx={styles.rejectDesc}
                        >
                          Other Comments or More Information
                        </Typography>
                        <Box
                          style={{
                            width: "100%",
                          }}
                        >
                          <TextField
                            placeholder="Enter Text"
                            name="rejectDescription"
                            onChange={(e) => {
                              setFieldValue(
                                "rejectDescription",
                                e.target.value
                              );
                            }}
                            multiline
                            id="standard-textarea"
                            rows="6"
                            sx={styles.rejectDescTextField}
                          />
                        </Box>
                      </Box>
                      <DialogActions
                        sx={{
                          ...submitCancelBox,
                          ...{ marginBottom: "20px" },
                        }}
                      >
                        <AppButton
                          isDisabled={isSubmitting}
                          look="inverted"
                          label="Cancel"
                          noIcon
                          onClick={() => setShowRejectDialog(false)}
                          marginRight
                          sx={styles.rejectDialogActionCancel}
                        />
                        <AppButton
                          isDisabled={isSubmitting}
                          type="submit"
                          look="negative"
                          label={
                            isSubmitting ? (
                              <CircularProgress size={18} />
                            ) : (
                              "Yes reject"
                            )
                          }
                          id="missionRejectForm"
                          form={"missionRejectForm"}
                        />
                      </DialogActions>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

RejectModal.propTypes = {
  showRejectDialog: PropTypes.bool.isRequired,
  setShowRejectDialog: PropTypes.func.isRequired,
};

export default RejectModal;
