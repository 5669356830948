import React, { useState, useEffect, Fragment } from "react";
import AutoCompleteInput from "../../../global/FormInputComponents/AutoCompleteInput";
import PropTypes from "prop-types";
import { userRoles } from "../../../../lib/constants";
import { autocompletStyle } from "../../../../lib/styleConstants";
import theme from "../../../../theme";
import { Typography, Grid, Box } from "@mui/material";
import firebase from "src/firebase";
import {
  getAllClients,
  getClientProfilePercent,
  getAdminCreatedClients,
} from "../../../../services/client";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import { getAdminDocs } from "../../../../services/handle-users";
import SwitchSelect from "../../../global/elements/SwitchSelect";
import Tips from "../../../global/elements/Tips";
import { Link } from "gatsby";

const styles = {
  clientError: {
    color: theme.palette.status.errorLight,
  },
  switchValue: { display: "flex" },
  link: {
    color: theme.palette.adminBlue.main,
    textDecoration: "none",
  },
};

const AssignClient = ({
  setFieldValue,
  currentUser,
  values,
  orderCollection,
  setClientInfo,
}) => {
  const [clientArray, setClientArray] = useState(null);
  const [clientErr, setClientErr] = useState(false);
  const [missionOwners, setmissionOwners] = useState([]);
  const [isClientInPlatform, setIsClientInPlatform] = useState(true);

  useEffect(() => {
    const getClientDetails = (userId) => {
      try {
        getClientProfilePercent({ userId })
          .then((data) => {
            if (data) {
              setClientInfo(data);
              setFieldValue("clientId", userId);
              setFieldValue("clientName", data.name);
              setFieldValue("clientEmail", data.email);
              setFieldValue("clientPhone", data.phone || "");
              setFieldValue("clientCompanyName", data.companyName || "");
              setFieldValue("enterPriseId", data.enterPriseId || null);
            }
          })
          .catch((e) => {
            console.log("Error", e);
          });
      } catch (e) {
        console.log("Error", e);
      }
    };

    if (!firebase) return;
    if (currentUser?.isAnonymous) return;
    getAdminDocs(firebase)
      .then((emails) => {
        setmissionOwners(emails);
      })
      .catch((err) => {
        console.log("Error", err);
      });
    if (currentUser?.role == userRoles.admin) {
      if (isClientInPlatform) {
        try {
          getAllClients(firebase).then((data) => {
            if (data) {
              setClientArray(data);
            }
          });
        } catch (e) {
          console.log("error", e);
        }
      } else {
        try {
          getAdminCreatedClients(firebase).then((data) => {
            if (data) {
              setClientArray(data);
            }
          });
        } catch (e) {
          console.log("error", e);
        }
      }
    } else if (currentUser?.role == userRoles.client) {
      getClientDetails(currentUser.userID);
      if (orderCollection === "projects") {
        setClientArray([
          {
            label:
              (values?.clientCompanyName
                ? values?.clientCompanyName + "-"
                : "") + values?.clientEmail,
            id: values.clientId,
            clientName: values?.clientName || "client",
            email: values?.clientEmail,
            phone: values?.clientPhone,
            clientCompanyName: values?.clientCompanyName,
          },
        ]);
      }
    }
  }, [
    currentUser?.isAnonymous,
    currentUser?.role,
    currentUser.userID,
    isClientInPlatform,
    orderCollection,
    setClientInfo,
    setFieldValue,
    values?.clientCompanyName,
    values?.clientEmail,
    values.clientId,
    values?.clientName,
    values?.clientPhone,
  ]);

  const clientDetailHandler = (value, setFieldValue) => {
    let obj = clientArray?.filter((item) => item.label === value);
    if (value == "undefined") {
      setClientErr(false);
    }

    if (obj?.length > 0) {
      setClientErr(false);
      setFieldValue("clientId", obj[0].id);
      setFieldValue("clientName", obj[0].clientName);
      setFieldValue("clientEmail", obj[0].email);
      setFieldValue("clientPhone", obj[0].phone || "");
      setFieldValue("clientCompanyName", obj[0].companyName || "");
      setFieldValue("enterPriseId", obj[0].enterPriseId || null);
    } else {
      if (value && value !== "undefined") {
        setClientErr(true);
      }
      setFieldValue("clientId", null);
      setFieldValue("clientName", "");
      setFieldValue("clientEmail", "");
      setFieldValue("clientPhone", "");
      setFieldValue("clientCompanyName", "");
      setFieldValue("enterPriseId", null);
    }
  };

  const missionOwnerFnc = (value, setFieldValue) => {
    let admin = missionOwners.filter((admin) => admin.name == value);
    if (admin?.length) {
      setFieldValue("missionOwnerName", admin[0]?.name);
      setFieldValue("missionOwner", admin[0]?.email);
      setFieldValue("missionOwnerId", admin[0]?.docId);
    }
  };
  const handleSwitchChange = (event) => {
    setIsClientInPlatform(event.target.checked);
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <Typography>Is the client registered in the platform?</Typography>
        <Box sx={styles.switchValue}>
          <SwitchSelect
            checked={isClientInPlatform}
            handleChange={handleSwitchChange}
            name="isClientInPlatform"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Tips
          content={
            <Fragment>
              If the client is not in the platform, make sure you add their
              details in tools and resources&nbsp;
              <Link style={styles.link} to="/app/admin/tools/addClients">
                here
              </Link>
            </Fragment>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutoCompleteInput
          options={clientArray?.map(({ label }) => label)}
          name="clientName"
          label="Select Client"
          // disabled={formType === formTypeConstant.edit ? true : false}
          additionalStyle={autocompletStyle}
          customOnChangeHandlerFcn={(value) => {
            clientDetailHandler(value, setFieldValue);
          }}
          customOnChangeHandler={true}
        />
        {clientErr && (
          <Typography variant="overline" sx={styles.clientError}>
            Please select client
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldInput
          setFieldValue={setFieldValue}
          values={values}
          label={"Client Name"}
          name={"clientName"}
          type="text"
          // disabled={formType === formTypeConstant.edit ? true : false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldInput
          setFieldValue={setFieldValue}
          values={values}
          label={"Client Email"}
          name={"clientEmail"}
          type="email"
          required
          disabled={!isClientInPlatform ? true : false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldInput
          setFieldValue={setFieldValue}
          values={values}
          label={"Client Phone"}
          name={"clientPhone"}
          type="text"
          // disabled={formType === formTypeConstant.edit ? true : false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldInput
          setFieldValue={setFieldValue}
          values={values}
          label={"Company Name"}
          name={"clientCompanyName"}
          type="text"
          // disabled={formType === formTypeConstant.edit ? true : false}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Mission owner</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          The mission owner is the client&apos;s account owner. They know this
          client and their requirements very well.
        </Typography>
        <AutoCompleteInput
          options={missionOwners?.map(({ name }) => name)}
          name="missionOwnerName"
          label="Mission Owner"
          //disabled={formType === formTypeConstant.edit ? true : false}
          additionalStyle={autocompletStyle}
          customOnChangeHandlerFcn={(value) => {
            missionOwnerFnc(value, setFieldValue);
          }}
          customOnChangeHandler={true}
        />
      </Grid>
    </>
  );
};

AssignClient.propTypes = {
  formType: PropTypes.string,
  values: PropTypes.object,
  currentUser: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  orderCollection: PropTypes.string,
  setClientInfo: PropTypes.func,
};
AssignClient.defaultProps = {
  formType: undefined,
  values: {},
  currentUser: {},
  setClientInfo: undefined,
  orderCollection: undefined,
};

export default AssignClient;
