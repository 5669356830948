import { Box, Typography } from "@mui/material";
import React from "react";

const styles = {
  dialogBody: { marginBottom: "30px", textAlign: "center" },
};

const CompleteProfilePopupBody = () => {
  return (
    <Box sx={styles.dialogBody}>
      <Typography variant="h6">Complete Profile</Typography>
      <Typography variant="body1">
        To place an order please complete you profile
      </Typography>
    </Box>
  );
};

export default CompleteProfilePopupBody;
