import firebase from "src/firebase";
import { useEffect, useState } from "react";
import { isBrowser } from "../services/auth";
import { getSingleDailyReport } from "../services/handle-operations";

const useGetDailyReport = (documentId) => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    getSingleDailyReport(firebase, documentId)
      .then((report) => {
        setReport(report);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [documentId]);

  return [report, loading];
};

export default useGetDailyReport;
