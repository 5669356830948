import React from "react";
import { Box, Typography, Link } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../theme";

const styles = {
  root: {
    marginTop: { xs: "10px", sm: 0 },
  },
  termsConditionHeading: {
    marginTop: "40px",
  },
  quoteSendText: {
    color: theme.palette.primary.main,
    letterSpacing: "0.8px",
    fontWeight: "400",
    marginRight: "5px",
    marginTop: "30px",
  },
  conditionBox: {
    color: theme.palette.status.successDark,
    textAlign: { xs: "center", sm: "" },
    fontSize: { xs: "20px", sm: "15px", md: "16px" },
    fontWeight: 500,
    display: { md: "flex" },
    paddingTop: { md: "8px" },
  },
};

const QuotesTermsAndConditions = ({ showLabel }) => {
  return (
    <Box sx={styles.root}>
      {showLabel && (
        <Typography variant="h6" sx={styles.termsConditionHeading}>
          Terms & conditions
        </Typography>
      )}
      <Box
        sx={{
          ...styles.conditionBox,
          width: { xs: "100%", sm: "auto" },
          paddingRight: { xs: "8px", md: "15px" },
        }}
      >
        <Link
          href="https://www.globhe.com/terms-and-conditions"
          target="_blank"
          rel="noreferrer"
          sx={{
            color: theme.palette.secondary.main,
            textDecorationColor: theme.palette.secondary.main,
          }}
        >
          <Typography variant="body2">See our terms & conditions</Typography>
        </Link>
      </Box>
    </Box>
  );
};

QuotesTermsAndConditions.propTypes = {
  showLabel: PropTypes.bool,
};

QuotesTermsAndConditions.defaultProps = {
  showLabel: false,
};

export default QuotesTermsAndConditions;
