import React from "react";
import { SocialIcon } from "react-social-icons";
import { Box } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import PropTypes from "prop-types";
import { formInputField } from "src/lib/styleConstants";
const styles = {
  root: {
    display: "flex",
  },
  social_media_div: {
    maxWidth: "150px",
    padding: "10px",
    display: "flex",
  },
  social_icon: {
    width: "28px",
    height: "28px",
  },
};

const PilotFormSocial = ({ name }) => {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.social_media_div}>
        <SocialIcon style={styles.social_icon} network={name} />
      </Box>

      <Box sx={{ ...formInputField, marginBottom: 0 }}>
        <Field
          type="text"
          name={name}
          component={TextField}
          variant="outlined"
          label={name.charAt(0).toUpperCase() + name.slice(1) + " Username"}
        />
      </Box>
    </Box>
  );
};

PilotFormSocial.propTypes = {
  name: PropTypes.string.isRequired,
};

export default PilotFormSocial;
