import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import GuestStatusAlert from "../../AnonymousUser/GuestStatusAlert";
import AppContext from "../../../../contexts/AppContext";
import ClientInfoHeader from "../components/ClientInfoHeader";
import ClientInfoContainer from "../components/ClientInfoContainer";
import { userRoles } from "../../../../lib/constants";
import SingleOrderByClient from "./SingleOrderByClient";
import AllClientsProjects from "./AllClientsProject";
import AppButton from "../../../global/elements/AppButton";
import PropTypes from "prop-types";
import { getClientById } from "../../../../services/client";
import firebase from "src/firebase";
import { getApiKeyStatus } from "../../../../services/apiKeys";
import { fetchEnterPrise } from "../../../../services/enterprise";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import ClientAgreement from "../components/ClientAgreement";
import DangerZone from "../../../global/elements/DangerZone";
import { getUser } from "../../../../services/auth";
import theme from "../../../../theme";

const styles = {
  marginCss: { margin: "30px 0" },
  infoContainer: {
    marginTop: { xs: "30px" },
  },
  actionContainer: {
    marginTop: "40px",
    float: "right",
  },
  mtop: {
    marginTop: "20%",
  },
  error: {
    padding: "30px",
    backgroundColor: theme.palette.status.errorLight,
    marginTop: "30px",
    textAlign: "center",
  },
};

const ClientProfileContainer = ({ clientId, setDeleteModal }) => {
  const appContext = useContext(AppContext);
  const [clientProfileInfo, setClientProfileInfo] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const [apiStatus, setApiStatus] = useState(null);
  const [enterprise, setEnterPrise] = useState(null);
  const [loading, setLoading] = useState(false);

  const isAdmin = getUser("user").role === userRoles.admin;
  const isClient = getUser("user").role === userRoles.client;

  useEffect(() => {
    fetchClientData();
  }, [reloadData]);

  const getEnterPrise = (enterPriseId) => {
    fetchEnterPrise(enterPriseId)
      .then((data) => {
        if (data) {
          setEnterPrise(data);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  const fetchClientData = async () => {
    try {
      setLoading(true);
      const profile = await getClientById(firebase, clientId);
      if (profile) {
        setClientProfileInfo(profile);
        fetchApiKeyStatus(profile.apiKey);
        if (profile?.enterPriseId) {
          if (appContext?.userData?.role == userRoles.admin) {
            // fetch enterprise from db
            getEnterPrise(profile?.enterPriseId);
          } else if (appContext?.userData?.role == userRoles.client) {
            // get enterprise from appContext
            if (appContext?.enterPrise) {
              setEnterPrise(appContext?.enterPrise);
            }
          }
          setLoading(false);
        }
        setLoading(false);
      }
    } catch (e) {
      console.log("Error", e);
      setLoading(false);
    }
  };

  const fetchApiKeyStatus = async (apiKey) => {
    try {
      const status = await getApiKeyStatus(firebase, apiKey);

      if (status) {
        setApiStatus(status);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <>
      {!loading ? (
        <Box>
          {!appContext?.userData && <GuestStatusAlert />}
          <Box p={1}>
            <ClientInfoHeader
              profile={clientProfileInfo}
              clientId={clientId}
              showClientStatus={
                appContext?.userData?.role === userRoles.admin ? true : false
              }
            />
            <Box mt={2} p={1}>
              <ClientInfoContainer
                profile={clientProfileInfo}
                role={appContext?.userData?.role}
                reloadData={reloadData}
                setReloadData={setReloadData}
                apiStatus={apiStatus}
                enterprise={enterprise}
                showEdit={appContext?.userData?.termsOfUse}
                isAdmin={appContext?.userData?.role === userRoles.admin}
              />
            </Box>
            {(clientProfileInfo?.agreement?.agreementUrl ||
              appContext?.userData?.role === userRoles.admin) && (
              <ClientAgreement
                client={clientProfileInfo}
                reloadData={reloadData}
                setReloadData={setReloadData}
              />
            )}

            {appContext?.userData?.role === userRoles.admin && (
              <>
                <Box sx={styles.marginCss}>
                  <Typography variant="h6">Single location orders</Typography>
                  <SingleOrderByClient clientId={clientId} />
                </Box>
                <Box sx={styles.marginCss}>
                  <Typography variant="h6">Multi location orders</Typography>
                  <AllClientsProjects
                    clientId={clientId}
                    role={appContext?.userData?.role}
                  />
                </Box>
              </>
            )}
            {(isClient &&
              appContext?.userData?.termsOfUse &&
              !appContext?.userData?.deleted) ||
            (isAdmin &&
              !clientProfileInfo?.deleted &&
              appContext?.userData?.termsOfUse) ? (
              <DangerZone>
                <Box sx={styles.actionContainer}>
                  {appContext?.userData && (
                    <AppButton
                      look="negative"
                      label="Delete profile"
                      onClick={() => setDeleteModal(true)}
                      noIcon
                    />
                  )}
                </Box>
              </DangerZone>
            ) : isClient && appContext?.userData?.deleted ? (
              <Box sx={styles.error}>
                <Typography variant="h4">User is deleted</Typography>
              </Box>
            ) : isAdmin && clientProfileInfo?.deleted ? (
              <Box sx={styles.error}>
                <Typography variant="h4">User is deleted</Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : (
        <Box sx={styles.mtop}>
          <UniversalLoader />
        </Box>
      )}
    </>
  );
};

ClientProfileContainer.propTypes = {
  clientId: PropTypes.string.isRequired,
  setDeleteModal: PropTypes.func.isRequired,
};

export default ClientProfileContainer;
