import { Box, Typography } from "@mui/material";
import React from "react";
import AppButton from "../../../global/elements/AppButton";
import PropTypes from "prop-types";
import { userRoles } from "../../../../lib/constants";

const styles = {
  noDocRoot: {
    textAlign: "center",
    padding: { xs: "40px 80px", md: "100px 150px" },
  },
};

const NoDocuments = ({ setOpenAddDocPopup, userRole }) => {
  return (
    <>
      <Box sx={styles.noDocRoot}>
        <Typography variant="h3">Nothing here at the moment</Typography>
        {userRole === userRoles.client ? (
          <Typography variant="body1">
            Here you find documents signed between GLOBHE and your organisation,
            such as agreements or contracts. They will be automatically added
            here once they are signed.
          </Typography>
        ) : (
          <Typography variant="body1">
            There is no document added by the enterprise at this moment.
          </Typography>
        )}

        <br />
        <AppButton
          label={"Add document"}
          onClick={() => setOpenAddDocPopup(true)}
          look={"green"}
        />
      </Box>
    </>
  );
};
NoDocuments.propTypes = {
  setOpenAddDocPopup: PropTypes.func.isRequired,
  userRole: PropTypes.string,
};
NoDocuments.defaultProps = {
  userRole: userRoles.client,
};
export default NoDocuments;
