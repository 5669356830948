import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  drones,
  FilterFields,
  sensorsCamerasList,
} from "../../../lib/constants";
import countries from "src/lib/countries";
import { Box, TextField, Autocomplete } from "@mui/material";
import AutoCompleteFilter from "../../global/elements/AutoCompleteFilter";
import { ErrorHelperText } from "../../../lib/styleConstants";

const style = {
  root: {
    maxWidth: "550px",
    padding: "2rem 2rem 6rem 2rem",
    margin: "0 auto",
  },
  field: {
    marginBottom: "15px",
    width: "100%",
  },
  filterFieldsCss: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill, 250px)",
    marginTop: "10px",
  },
  filterContainer: {
    margin: "20px 10px",
  },
  showError: {
    border: "2px solid red",
    color: "red",
  },
  morethan10ItemError: {
    color: "red",
    fontSize: "0.75rem",
    fontWeight: 400,
  },
};

const filterItems = [
  { label: "All", value: "all" },
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const FilterPilotsForm = ({ valueChangeHandler }) => {
  const [filterField, setFilterField] = useState([]);
  const [arrayFieldAvailable, setArrayFieldAvailable] = useState(false);
  const [errorMultipleArrayField, setErrorMultipleFieldError] = useState(false);
  const [error10ItemSelect, setError10ItemSelect] = useState(false);

  const [filterData, setFilterData] = useState({
    "Name/Email": "",
    Country: "",
    Drone: [],
    "Available Locations": [],
    "Sensors/Cameras": [],
    "Minimum Megapixels": 0,
    City: "",
    Insurance: "all",
    License: "all",
    "Registered Company": "all",
  });
  const [changedFilterMonitor, setchangedFilterMonitor] = useState(false);

  useEffect(() => {
    const arrayFilterField = [
      "Name/Email",
      "Drone",
      "Available Locations",
      "Sensors/Cameras",
    ];
    let filterFieldData = localStorage.getItem("filterFieldsPilot");
    if (filterFieldData) {
      arrayFilterField.map((fldData) => {
        if (
          (fldData !== [] || fldData !== "" || fldData !== null) &&
          filterFieldData.includes(fldData)
        ) {
          setArrayFieldAvailable(true);
        }
      });
      let getAllFilters = filterFieldData?.split(",");
      if (getAllFilters?.length > 0) {
        setFilterField(getAllFilters || []);
        let dataToSave = JSON.parse(localStorage.getItem("filterData"))[0];
        if (dataToSave["Insurance"] == "true") {
          dataToSave["Insurance"] = { label: "Yes", value: "true" };
        } else if (dataToSave["Insurance"] == "false") {
          dataToSave["Insurance"] = { label: "No", value: "false" };
        } else {
          dataToSave["Insurance"] = { label: "All", value: "all" };
        }

        if (dataToSave["License"] == "true") {
          dataToSave["License"] = { label: "Yes", value: "true" };
        } else if (dataToSave["License"] == "false") {
          dataToSave["License"] = { label: "No", value: "false" };
        } else {
          dataToSave["License"] = { label: "All", value: "all" };
        }

        if (dataToSave["Registered Company"] == "true") {
          dataToSave["Registered Company"] = { label: "Yes", value: "true" };
        } else if (dataToSave["Registered Company"] == "false") {
          dataToSave["Registered Company"] = { label: "No", value: "false" };
        } else {
          dataToSave["Registered Company"] = { label: "All", value: "all" };
        }
        setFilterData(dataToSave);
        valueChangeHandler("InitialLoad", "InitialLoad", getAllFilters);
      }
    }
  }, [changedFilterMonitor]);

  const onInsuranceChangeHandler = (e, newValue) => {
    let newData = filterData;
    newData["Insurance"] = newValue;
    setFilterData(newData);
    valueChangeHandler(newValue?.value, "Insurance", filterField);
  };

  const onLicenseChangeHandler = (e, newValue) => {
    let newData = filterData;
    newData["License"] = newValue;
    setFilterData(newData);
    valueChangeHandler(newValue?.value, "License", filterField);
  };

  const onRegisteredCompanyChangeHandler = (e, newValue) => {
    let newData = filterData;
    newData["Registered Company"] = newValue;
    setFilterData(newData);
    valueChangeHandler(newValue?.value, "Registered Company", filterField);
  };

  const onChangeFilterField = (e, newValue) => {
    setError10ItemSelect(false);
    setchangedFilterMonitor(!changedFilterMonitor);
    const arrayFilterField = [
      "Name/Email",
      "Drone",
      "Available Locations",
      "Sensors/Cameras",
    ];
    let arrayFieldAvailableLocal = arrayFieldAvailable;
    let currentFilterFieldInUse = [];
    if (newValue?.length > 0) {
      newValue.forEach((filter) => {
        if (!filterField.includes(filter)) {
          if (arrayFilterField.includes(filter) & arrayFieldAvailableLocal) {
            setErrorMultipleFieldError(true);
            arrayFieldAvailableLocal = true;
            setArrayFieldAvailable(true);
          } else if (arrayFilterField.includes(filter)) {
            arrayFieldAvailableLocal = true;
            setArrayFieldAvailable(true);
            currentFilterFieldInUse.push(filter);
            setErrorMultipleFieldError(false);
          } else {
            currentFilterFieldInUse.push(filter);
            arrayFieldAvailableLocal = arrayFieldAvailable;
            setErrorMultipleFieldError(false);
          }
        } else {
          let changedField = arrayFilterField.filter((fld) => {
            return newValue.indexOf(fld) > -1;
          });
          if (changedField.length < 1) {
            arrayFieldAvailableLocal = false;
            setArrayFieldAvailable(false);
          }
          currentFilterFieldInUse.push(filter);
          arrayFieldAvailableLocal = arrayFieldAvailable;
          setErrorMultipleFieldError(false);
        }
      });
    } else {
      setArrayFieldAvailable(false);
      setErrorMultipleFieldError(false);
      currentFilterFieldInUse = newValue;
    }
    setFilterField(currentFilterFieldInUse);
    valueChangeHandler("", "", currentFilterFieldInUse);
  };

  const checkMultiAutoComplete = (e, value, mutiselectName) => {
    /* If user is trying to select more than 10 items, 
    don't allow them to add and return error */

    if (value.length > 10) {
      setError10ItemSelect(true);
      return;
    }

    setError10ItemSelect(false);
    let newData = filterData;
    newData[mutiselectName] = value;
    setFilterData(newData);
    valueChangeHandler(value, mutiselectName, filterField);
  };

  const showErrorMessage = () => {
    return `* You can't select more than 10 
    ${filterField && filterField[0] ? filterField[0] : ""}`;
  };

  return (
    <Box>
      <AutoCompleteFilter
        filterOptions={FilterFields}
        filterField={filterField}
        onChangeFilterField={onChangeFilterField}
        helperText={
          !errorMultipleArrayField
            ? "Select the fields to filter the operators with"
            : "You can not merge Name/Drone/Available Location/sensors field together."
        }
        helperTextStyle={{
          // border: errorMultipleArrayField && "2px solid red",
          color: errorMultipleArrayField ? ErrorHelperText : {},
        }}
      />
      <Box sx={style.filterFieldsCss}>
        {filterField?.includes("Name/Email") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Name/Email"]}
              onChange={(e) => {
                let newData = filterData;
                newData["Name/Email"] = e.target.value;
                setFilterData(newData);
                valueChangeHandler(e.target.value, "Name/Email", filterField);
              }}
              label="Name/Email"
              style={style.field}
            />
          </Box>
        )}
        {filterField?.includes("Country") && (
          <Box>
            <Autocomplete
              style={style.field}
              options={countries}
              freeSolo
              value={filterData["Country"]}
              onChange={(e, value) => {
                let newData = filterData;
                newData["Country"] = value;
                setFilterData(newData);
                valueChangeHandler(value, "Country", filterField);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Country" />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Available Locations") && (
          <Box>
            <Autocomplete
              multiple
              style={style.field}
              options={countries}
              value={filterData["Available Locations"]}
              freeSolo
              onChange={(e, value) => {
                checkMultiAutoComplete(e, value, "Available Locations");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Available Locations"
                />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Drone") && (
          <Box>
            <Autocomplete
              multiple
              freeSolo
              style={style.field}
              options={drones}
              value={filterData["Drone"]}
              disableClearable={true}
              onChange={(e, value) => {
                checkMultiAutoComplete(e, value, "Drone");
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Drone" />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Sensors/Cameras") && (
          <Box>
            <Autocomplete
              multiple
              freeSolo
              style={style.field}
              options={sensorsCamerasList}
              value={filterData["Sensors/Cameras"]}
              disableClearable={true}
              onChange={(e, value) => {
                checkMultiAutoComplete(e, value, "Sensors/Cameras");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Sensors/Cameras"
                />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Minimum Megapixels") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Minimum Megapixels"]}
              onChange={(e) => {
                let newData = filterData;
                newData["Minimum Megapixels"] = e.target.value;
                setFilterData(newData);
                valueChangeHandler(
                  parseInt(e.target.value),
                  "Minimum Megapixels",
                  filterField
                );
              }}
              label="Minimum Megapixels"
              style={style.field}
            />
          </Box>
        )}
        {filterField?.includes("City") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["City"]}
              onChange={(e) => {
                let newData = filterData;
                newData["City"] = e.target.value;
                setFilterData(newData);
                valueChangeHandler(
                  e.target.value.toLowerCase(),
                  "City",
                  filterField
                );
              }}
              label="City"
              style={style.field}
            />
          </Box>
        )}
        {filterField?.includes("Insurance") && (
          <Box>
            <Autocomplete
              style={style.field}
              disablePortal
              disableClearable={true}
              options={filterItems}
              value={filterData["Insurance"]}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => onInsuranceChangeHandler(e, value)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Insurance" />
              )}
            />
          </Box>
        )}
        {filterField?.includes("License") && (
          <Box>
            <Autocomplete
              style={style.field}
              disablePortal
              disableClearable={true}
              options={filterItems}
              value={filterData["License"]}
              getOptionLabel={(option) => option.label}
              onChange={onLicenseChangeHandler}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="License" />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Registered Company") && (
          <Box>
            <Autocomplete
              style={style.field}
              disablePortal
              disableClearable={true}
              options={filterItems}
              value={filterData["Registered Company"]}
              getOptionLabel={(option) => option.label}
              onChange={onRegisteredCompanyChangeHandler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Registered Company"
                />
              )}
            />
          </Box>
        )}
      </Box>
      {filterField && filterField.length > 0 && error10ItemSelect && (
        <Box component="span" sx={style.morethan10ItemError}>
          {showErrorMessage()}
        </Box>
      )}
    </Box>
  );
};

FilterPilotsForm.propTypes = {
  valueChangeHandler: PropTypes.func.isRequired,
};

export default FilterPilotsForm;
