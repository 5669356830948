import React from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { Box, Typography, Popover, Grid, Backdrop } from "@mui/material";
import theme from "../../../theme";
import PropTypes from "prop-types";
import { currencyConstant } from "../../../lib/constants";
import {
  popoverBox,
  popoverGrid,
  popoverGridItem,
} from "../../../lib/styleConstants";

const styles = {
  selectedCurrencyBgColor: {
    backgroundColor: theme.palette.primary.palePistachio,
  },
  currencyContainer: {
    display: "flex",
    marginTop: "10px",
  },
  paperPropsCls: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: 0,
    zIndex: 9999999,
    width: "250px",
    maxWidth: "300px",
  },
};

const CurrencyPopup = ({
  anchorElCurrency,
  id,
  handleClose,
  selectedCurrency,
  onSelectCurrency,
}) => {
  let openBackdrop = Boolean(anchorElCurrency);

  const onSelect = (e, currency) => {
    e.stopPropagation();
    onSelectCurrency(currency);
  };

  return (
    <Box>
      <Backdrop open={openBackdrop} onClick={handleClose}>
        <Popover
          id={id}
          open={openBackdrop}
          anchorEl={anchorElCurrency}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          PaperProps={{
            style: styles.paperPropsCls,
          }}
          sx={{ top: "5%" }}
        >
          <Box sx={popoverBox} id={id} component={"div"} />

          <Grid container sx={popoverGrid}>
            <Grid item xs={2}>
              <Typography variant="h5">
                {getSymbolFromCurrency(selectedCurrency)}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1Med">
                Select your currency
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              spacing={1}
              sx={styles.currencyContainer}
            >
              {Object.keys(currencyConstant).map((currency) => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={currency}
                    sx={
                      selectedCurrency == currency
                        ? {
                            ...styles.selectedCurrencyBgColor,
                            ...popoverGridItem,
                          }
                        : popoverGridItem
                    }
                    onClick={(e) => onSelect(e, currency)}
                  >
                    <Typography variant="subtitle1Med">
                      {currency} - {currencyConstant[currency]}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Popover>
      </Backdrop>
    </Box>
  );
};

CurrencyPopup.propTypes = {
  anchorElCurrency: PropTypes.object,
  id: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedCurrency: PropTypes.string.isRequired,
  onSelectCurrency: PropTypes.func.isRequired,
};

CurrencyPopup.defaultProps = {
  anchorElCurrency: null,
};

export default CurrencyPopup;
