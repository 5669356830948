import firebase from "src/firebase";
import { useEffect, useState } from "react";
import { getSingleApiKey } from "../services/apiKeys";
import { isBrowser } from "../services/auth";

const useGetApiKey = (documentId) => {
  const [key, setKey] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    getSingleApiKey(firebase, documentId)
      .then((apiKey) => {
        setKey(apiKey);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [documentId]);

  return [key, loading];
};

export default useGetApiKey;
