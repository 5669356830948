import firebase from "src/firebase";
import { getCurrentDateTime } from "../lib/helpers";

const newsSchema = async (data, adminId) => {
  const date = getCurrentDateTime();
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .add({
      title: data?.title || "",
      description: data?.description || "",
      type: "news",
      cretedBy: adminId || "",
      dateCreated: date,
      deleted: data.deleted || false,
      lastUpdated: data.lastUpdated || date,
      archive: data?.archive || false,
    })
    .then((docRef) => {
      return {
        status: "Success",
        news: docRef,
        newsId: docRef.id,
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
      return {
        status: "Error",
        error: err,
      };
    });
};

const inspirationSchema = (data, adminId) => {
  const date = getCurrentDateTime();
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .add({
      title: data?.title || "",
      description: data?.description || "",
      blogImage: data?.blogImage || "",
      type: "inspiration",
      cretedBy: adminId || "",
      dateCreated: date,
      deleted: data?.deleted || false,
      lastUpdated: data?.lastUpdated || date,
      archive: data?.archive || false,
    })
    .then((docRef) => {
      return {
        status: "Success",
        docRef: docRef,
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
      return {
        status: "Error",
        error: err,
      };
    });
};

export const createNews = async (data, adminId) => {
  let status = await newsSchema(data, adminId);
  return status;
};

export const createInspiration = async (data, adminId) => {
  let status = await inspirationSchema(data, adminId);

  return status;
};

export const getNews = (limit = 12) => {
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .where("type", "==", "news")
    .where("archive", "==", false)
    .where("deleted", "==", false)
    .orderBy("dateCreated", "desc")
    .limit(limit)
    .get()
    .then((snapshot) => {
      const news = snapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        acc.push({ ...data, id: doc.id });
        return acc;
      }, []);
      return {
        news,
        lastVisible:
          snapshot.size > 0 ? snapshot.docs[snapshot.docs.length - 1] : null,
        last: snapshot.size < limit,
      };
    });
};

export const getInspirations = (limit = 12) => {
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .where("type", "==", "inspiration")
    .where("archive", "==", false)
    .where("deleted", "==", false)
    .orderBy("dateCreated", "desc")
    .limit(limit)
    .get()
    .then((snapshot) => {
      const inspirations = snapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        acc.push({ ...data, id: doc.id });
        return acc;
      }, []);
      return {
        inspirations,
        lastVisible:
          snapshot.size > 0 ? snapshot.docs[snapshot.docs.length - 1] : null,
        last: snapshot.size < limit,
      };
    });
};

export const getSingleInspiration = (id) => {
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .doc(id)
    .get()
    .then((snapshot) => {
      const inspiration = snapshot.data();
      return {
        inspiration,
      };
    });
};

export const getSingleNews = (id) => {
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .doc(id)
    .get()
    .then((snapshot) => {
      const news = snapshot.data();
      return {
        news,
      };
    });
};

export const singleNewsUpdate = (data, id) => {
  const date = getCurrentDateTime();
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .doc(id)
    .update({
      title: data?.title || "",
      description: data?.description || "",
      lastUpdated: date,
    })
    .then((docRef) => {
      return {
        status: "Success",
        news: data,
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
      return {
        status: "Error",
        error: err,
      };
    });
};

export const singleInspirationUpdate = (data, id) => {
  const date = getCurrentDateTime();
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .doc(id)
    .update({
      title: data?.title || "",
      description: data?.description || "",
      blogImage: data?.blogImage || "",
      lastUpdated: date,
    })
    .then((docRef) => {
      return {
        status: "Success",
        news: data,
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
      return {
        status: "Error",
        error: err,
      };
    });
};

export const singleInspirationDelete = (id) => {
  const date = getCurrentDateTime();
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .doc(id)
    .update({
      deleted: true,
      lastUpdated: date,
    })
    .then((docRef) => {
      return {
        status: "Success",
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
      return {
        status: "Error",
        error: err,
      };
    });
};

export const singleNewsDelete = (id) => {
  const date = getCurrentDateTime();
  return firebase
    .firestore()
    .collection("newsAndInspirations")
    .doc(id)
    .update({
      deleted: true,
      lastUpdated: date,
    })
    .then((docRef) => {
      return {
        status: "Success",
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
      return {
        status: "Error",
        error: err,
      };
    });
};
