export const availabilityData = [
  { pilots: "1", ISO3: "AFG", country: "Afghanistan" },
  { pilots: "3", ISO3: "ALB", country: "Albania" },
  { pilots: "2", ISO3: "DZA", country: "Algeria" },
  { pilots: "2", ISO3: "ASM", country: "American Samoa" },
  { pilots: "1", ISO3: "AND", country: "Andorra" },
  { pilots: "6", ISO3: "AGO", country: "Angola" },
  { pilots: "1", ISO3: "AIA", country: "Anguilla" },
  { pilots: "1", ISO3: "ATA", country: "Antarctica" },
  { pilots: "2", ISO3: "ATG", country: "Antigua and Barbuda" },
  { pilots: "12", ISO3: "ARG", country: "Argentina" },
  { pilots: "2", ISO3: "ARM", country: "Armenia" },
  { pilots: "1", ISO3: "ABW", country: "Aruba" },
  { pilots: "14", ISO3: "AUS", country: "Australia" },
  { pilots: "8", ISO3: "AUT", country: "Austria" },
  { pilots: "1", ISO3: "AZE", country: "Azerbaijan" },
  { pilots: "2", ISO3: "BHS", country: "Bahamas" },
  { pilots: "1", ISO3: "BHR", country: "Bahrain" },
  { pilots: "3", ISO3: "BGD", country: "Bangladesh" },
  { pilots: "4", ISO3: "BRB", country: "Barbados" },
  { pilots: "11", ISO3: "BEL", country: "Belgium" },
  { pilots: "2", ISO3: "BLZ", country: "Belize" },
  { pilots: "8", ISO3: "BEN", country: "Benin" },
  { pilots: "1", ISO3: "BMU", country: "Bermuda" },
  { pilots: "3", ISO3: "BTN", country: "Bhutan" },
  { pilots: "6", ISO3: "BOL", country: "Bolivia" },
  { pilots: "8", ISO3: "BIH", country: "Bosnia Herzegovina" },
  { pilots: "14", ISO3: "BWA", country: "Botswana" },
  { pilots: "15", ISO3: "BRA", country: "Brazil" },
  { pilots: "5", ISO3: "BGR", country: "Bulgaria" },
  { pilots: "4", ISO3: "BFA", country: "Burkina Faso" },
  { pilots: "4", ISO3: "BDI", country: "Burundi" },
  { pilots: "1", ISO3: "KHM", country: "Cambodia" },
  { pilots: "4", ISO3: "CMR", country: "Cameroon" },
  { pilots: "14", ISO3: "CAN", country: "Canada" },
  { pilots: "1", ISO3: "CYM", country: "Cayman Islands" },
  { pilots: "2", ISO3: "CAF", country: "Central African Republic" },
  { pilots: "2", ISO3: "TCD", country: "Chad" },
  { pilots: "10", ISO3: "CHL", country: "Chile" },
  { pilots: "1", ISO3: "CHN", country: "China" },
  { pilots: "5", ISO3: "COL", country: "Colombia" },
  { pilots: "3", ISO3: "COG", country: "Congo" },
  { pilots: "6", ISO3: "COD", country: "Congo CDR" },
  { pilots: "3", ISO3: "CRI", country: "Costa Rica" },
  { pilots: "7", ISO3: "CIV", country: "Cote d'Ivoire" },
  { pilots: "11", ISO3: "HRV", country: "Croatia" },
  { pilots: "2", ISO3: "CUB", country: "Cuba" },
  { pilots: "5", ISO3: "CYP", country: "Cyprus" },
  { pilots: "2", ISO3: "CZE", country: "Czech Republic" },
  { pilots: "24", ISO3: "DNK", country: "Denmark" },
  { pilots: "1", ISO3: "DJI", country: "Djbouti" },
  { pilots: "4", ISO3: "DOM", country: "Dominican Republic" },
  { pilots: "2", ISO3: "ECU", country: "Ecuador" },
  { pilots: "2", ISO3: "EGY", country: "Egypt" },
  { pilots: "1", ISO3: "SLV", country: "El Salvador" },
  { pilots: "6", ISO3: "EST", country: "Estonia" },
  { pilots: "5", ISO3: "SWZ", country: "Eswatini" },
  { pilots: "5", ISO3: "ETH", country: "Ethiopia" },
  { pilots: "4", ISO3: "FJI", country: "Fiji" },
  { pilots: "9", ISO3: "FIN", country: "Finland" },
  { pilots: "23", ISO3: "FRA", country: "France" },
  { pilots: "3", ISO3: "GMB", country: "Gambia" },
  { pilots: "1", ISO3: "GEO", country: "Georgia" },
  { pilots: "27", ISO3: "DEU", country: "Germany" },
  { pilots: "14", ISO3: "GHA", country: "Ghana" },
  { pilots: "1", ISO3: "GIB", country: "Gibraltar" },
  { pilots: "10", ISO3: "GRC", country: "Greece" },
  { pilots: "1", ISO3: "GRL", country: "Greenland" },
  { pilots: "2", ISO3: "GTM", country: "Guatemala" },
  { pilots: "2", ISO3: "GIN", country: "Guinea" },
  { pilots: "2", ISO3: "HKG", country: "Hong Kong" },
  { pilots: "1", ISO3: "HND", country: "Honduras" },
  { pilots: "6", ISO3: "HUN", country: "Hungary" },
  { pilots: "3", ISO3: "ISL", country: "Iceland" },
  { pilots: "29", ISO3: "IND", country: "India" },
  { pilots: "12", ISO3: "IDN", country: "Indonesia" },
  { pilots: "1", ISO3: "IRN", country: "Iran" },
  { pilots: "1", ISO3: "IRL", country: "Ireland" },
  { pilots: "13", ISO3: "ITA", country: "Italy" },
  { pilots: "1", ISO3: "JAM", country: "Jamaica" },
  { pilots: "5", ISO3: "JPN", country: "Japan" },
  { pilots: "4", ISO3: "JOR", country: "Jordan" },
  { pilots: "1", ISO3: "KAZ", country: "Kazakhstan" },
  { pilots: "20", ISO3: "KEN", country: "Kenya" },
  { pilots: "1", ISO3: "KIR", country: "Kiribati" },
  { pilots: "1", ISO3: "LAO", country: "Laos" },
  { pilots: "2", ISO3: "LVA", country: "Latvia" },
  { pilots: "1", ISO3: "LBN", country: "Lebanon" },
  { pilots: "9", ISO3: "LSO", country: "Lesotho" },
  { pilots: "2", ISO3: "LBR", country: "Liberia" },
  { pilots: "2", ISO3: "LIE", country: "Lichtenstein" },
  { pilots: "4", ISO3: "LTU", country: "Lithuania" },
  { pilots: "5", ISO3: "LUX", country: "Luxembourg" },
  { pilots: "2", ISO3: "MKD", country: "Macedonia" },
  { pilots: "2", ISO3: "MDG", country: "Madagascar" },
  { pilots: "44", ISO3: "MWI", country: "Malawi" },
  { pilots: "4", ISO3: "MYS", country: "Malaysia" },
  { pilots: "1", ISO3: "MDV", country: "Maldives" },
  { pilots: "5", ISO3: "MLI", country: "Mali" },
  { pilots: "1", ISO3: "MLT", country: "Malta" },
  { pilots: "1", ISO3: "MRT", country: "Mauritania" },
  { pilots: "2", ISO3: "MUS", country: "Mauritius" },
  { pilots: "17", ISO3: "MEX", country: "Mexico" },
  { pilots: "1", ISO3: "MCO", country: "Monaco" },
  { pilots: "2", ISO3: "MNE", country: "Montenegro" },
  { pilots: "3", ISO3: "MAR", country: "Morocco" },
  { pilots: "18", ISO3: "MOZ", country: "Mozambique" },
  { pilots: "1", ISO3: "MMR", country: "Myanmar" },
  { pilots: "10", ISO3: "NAM", country: "Namibia" },
  { pilots: "4", ISO3: "NPL", country: "Nepal" },
  { pilots: "10", ISO3: "NLD", country: "Netherlands" },
  { pilots: "4", ISO3: "NZL", country: "New Zealand" },
  { pilots: "6", ISO3: "NER", country: "Niger" },
  { pilots: "18", ISO3: "NGA", country: "Nigeria" },
  { pilots: "17", ISO3: "NOR", country: "Norway" },
  { pilots: "1", ISO3: "OMN", country: "Oman" },
  { pilots: "2", ISO3: "PAK", country: "Pakistan" },
  { pilots: "3", ISO3: "OMN", country: "Panama" },
  { pilots: "2", ISO3: "PNG", country: "Papua New Guinea" },
  { pilots: "4", ISO3: "PRY", country: "Paraguay" },
  { pilots: "7", ISO3: "PER", country: "Peru" },
  { pilots: "7", ISO3: "PHL", country: "Philippines" },
  { pilots: "10", ISO3: "POL", country: "Poland" },
  { pilots: "10", ISO3: "PRT", country: "Portugal" },
  { pilots: "8", ISO3: "PRI", country: "Puerto Rico" },
  { pilots: "1", ISO3: "REU", country: "La Réunion" },
  { pilots: "6", ISO3: "ROU", country: "Romania" },
  { pilots: "4", ISO3: "RUS", country: "Russia" },
  { pilots: "7", ISO3: "RWA", country: "Rwanda" },
  { pilots: "2", ISO3: "LCA", country: "Saint Lucia" },
  { pilots: "1", ISO3: "KNA", country: "Saint Kits and Nevis" },
  { pilots: "1", ISO3: "VCT", country: "Saint Vincent and the Grenadines" },
  { pilots: "1", ISO3: "SMR", country: "San Marino" },
  { pilots: "1", ISO3: "SAU", country: "Saudi Arabia" },
  { pilots: "9", ISO3: "SEN", country: "Senegal" },
  { pilots: "4", ISO3: "SRB", country: "Serbia" },
  { pilots: "1", ISO3: "SYC", country: "Seychelles" },
  { pilots: "3", ISO3: "SLE", country: "Sierra Leone" },
  { pilots: "3", ISO3: "SGP", country: "Singapore" },
  { pilots: "3", ISO3: "SVK", country: "Slovakia" },
  { pilots: "3", ISO3: "SVN", country: "Slovenia" },
  { pilots: "3", ISO3: "SOM", country: "Somalia" },
  { pilots: "30", ISO3: "ZAF", country: "South Africa" },
  { pilots: "2", ISO3: "KOR", country: "South Korea" },
  { pilots: "2", ISO3: "SSD", country: "South Sudan" },
  { pilots: "24", ISO3: "ESP", country: "Spain" },
  { pilots: "4", ISO3: "LKA", country: "Sri Lanka" },
  { pilots: "1", ISO3: "SDN", country: "Sudan" },
  { pilots: "1", ISO3: "SUR", country: "Suriname" },
  { pilots: "121", ISO3: "SWE", country: "Sweden" },
  { pilots: "10", ISO3: "CHE", country: "Switzerland" },
  { pilots: "2", ISO3: "TWN", country: "Taiwan" },
  { pilots: "1", ISO3: "TJA", country: "Tajikistan" },
  { pilots: "18", ISO3: "TZA", country: "Tanzania" },
  { pilots: "7", ISO3: "THA", country: "Thailand" },
  { pilots: "1", ISO3: "TMP", country: "East Timor" },
  { pilots: "6", ISO3: "TGO", country: "Togo" },
  { pilots: "2", ISO3: "TTO", country: "Trinidad & Tobago" },
  { pilots: "1", ISO3: "TUN", country: "Tunisia" },
  { pilots: "3", ISO3: "TUR", country: "Turkey" },
  { pilots: "1", ISO3: "TKM", country: "Turkmenistan" },
  { pilots: "2", ISO3: "TCA", country: "Turks and Caicos island" },
  { pilots: "4", ISO3: "ARE", country: "United Arab Emirates" },
  { pilots: "5", ISO3: "UGA", country: "Uganda" },
  { pilots: "18", ISO3: "GBR", country: "United Kingdom" },
  { pilots: "11", ISO3: "UKR", country: "Ukraine" },
  { pilots: "114", ISO3: "USA", country: "United States" },
  { pilots: "4", ISO3: "URY", country: "Uruguay" },
  { pilots: "3", ISO3: "VEN", country: "Venezuela" },
  { pilots: "2", ISO3: "VNM", country: "Vietnam" },
  { pilots: "2", ISO3: "VGB", country: "Virgin Islands British" },
  { pilots: "1", ISO3: "VIR", country: "Virgin Islands US" },
  { pilots: "11", ISO3: "ZMB", country: "Zambia" },
  { pilots: "16", ISO3: "ZWE", country: "Zimbabwe" },
];
//check PilotDashboard to edit the Total Drone Operators

export const countryData = [
  { pilots: "2", ISO3: "DZA", country: "Algeria" },
  { pilots: "5", ISO3: "AGO", country: "Angola" },
  { pilots: "26", ISO3: "ARG", country: "Argentina" },
  { pilots: "2", ISO3: "ARM", country: "Armenia" },
  { pilots: "31", ISO3: "AUS", country: "Australia" },
  { pilots: "12", ISO3: "AUT", country: "Austria" },
  { pilots: "1", ISO3: "BHS", country: "Bahamas" },
  { pilots: "7", ISO3: "BGD", country: "Bangladesh" },
  { pilots: "5", ISO3: "BRB", country: "Barbados" },
  { pilots: "14", ISO3: "BEL", country: "Belgium" },
  { pilots: "1", ISO3: "BLZ", country: "Belize" },
  { pilots: "9", ISO3: "BEN", country: "Benin" },
  { pilots: "3", ISO3: "BTN", country: "Bhutan" },
  { pilots: "2", ISO3: "BOL", country: "Bolivia" },
  { pilots: "5", ISO3: "BWA", country: "Botswana" },
  { pilots: "24", ISO3: "BRA", country: "Brazil" },
  { pilots: "2", ISO3: "BGR", country: "Bulgaria" },
  { pilots: "2", ISO3: "BFA", country: "Burkina Faso" },
  { pilots: "1", ISO3: "BDI", country: "Burundi" },
  { pilots: "2", ISO3: "KHM", country: "Cambodia" },
  { pilots: "4", ISO3: "CMR", country: "Cameroon" },
  { pilots: "36", ISO3: "CAN", country: "Canada" },
  { pilots: "2", ISO3: "CYM", country: "Cayman Islands" },
  { pilots: "15", ISO3: "CHL", country: "Chile" },
  { pilots: "26", ISO3: "COL", country: "Colombia" },
  { pilots: "3", ISO3: "COG", country: "Congo" },
  { pilots: "9", ISO3: "COD", country: "Congo CDR" },
  { pilots: "2", ISO3: "CRI", country: "Costa Rica" },
  { pilots: "8", ISO3: "CIV", country: "Cote d'Ivoire" },
  { pilots: "13", ISO3: "HRV", country: "Croatia" },
  { pilots: "3", ISO3: "CYP", country: "Cyprus" },
  { pilots: "4", ISO3: "CZE", country: "Czech Republic" },
  { pilots: "13", ISO3: "DNK", country: "Denmark" },
  { pilots: "1", ISO3: "DJI", country: "Djibouti" },
  { pilots: "7", ISO3: "DOM", country: "Dominican Republic" },
  { pilots: "9", ISO3: "ECU", country: "Ecuador" },
  { pilots: "2", ISO3: "EGY", country: "Egypt" },
  { pilots: "7", ISO3: "SLV", country: "El Salvador" },
  { pilots: "6", ISO3: "EST", country: "Estonia" },
  { pilots: "2", ISO3: "SWZ", country: "Eswatini" },
  { pilots: "21", ISO3: "ETH", country: "Ethiopia" },
  { pilots: "4", ISO3: "FJI", country: "Fiji" },
  { pilots: "11", ISO3: "FIN", country: "Finland" },
  { pilots: "34", ISO3: "FRA", country: "France" },
  { pilots: "1", ISO3: "GMB", country: "Gambia" },
  { pilots: "2", ISO3: "GEO", country: "Georgia" },
  { pilots: "31", ISO3: "DEU", country: "Germany" },
  { pilots: "26", ISO3: "GHA", country: "Ghana" },
  { pilots: "1", ISO3: "GIB", country: "Gibraltar" },
  { pilots: "12", ISO3: "GRC", country: "Greece" },
  { pilots: "2", ISO3: "GTM", country: "Guatemala" },
  { pilots: "1", ISO3: "GUY", country: "Guyana" },
  { pilots: "1", ISO3: "HTI", country: "Haiti" },
  { pilots: "1", ISO3: "HND", country: "Honduras" },
  { pilots: "1", ISO3: "HKG", country: "Hong Kong" },
  { pilots: "3", ISO3: "HUN", country: "Hungary" },
  { pilots: "3", ISO3: "ISL", country: "Iceland" },
  { pilots: "45", ISO3: "IND", country: "India" },
  { pilots: "24", ISO3: "IDN", country: "Indonesia" },
  { pilots: "1", ISO3: "IRN", country: "Iran" },
  { pilots: "9", ISO3: "IRL", country: "Ireland" },
  { pilots: "29", ISO3: "ITA", country: "Italy" },
  { pilots: "6", ISO3: "JAM", country: "Jamaica" },
  { pilots: "4", ISO3: "JPN", country: "Japan" },
  { pilots: "2", ISO3: "JOR", country: "Jordan" },
  { pilots: "2", ISO3: "KAZ", country: "Kazakhstan" },
  { pilots: "31", ISO3: "KEN", country: "Kenya" },
  { pilots: "2", ISO3: "KIR", country: "Kiribati" },
  { pilots: "1", ISO3: "XKX", country: "Kosovo" },
  { pilots: "1", ISO3: "LAO", country: "Laos" },
  { pilots: "7", ISO3: "LVA", country: "Latvia" },
  { pilots: "1", ISO3: "LBN", country: "Lebanon" },
  { pilots: "6", ISO3: "LSO", country: "Lesotho" },
  { pilots: "5", ISO3: "LBR", country: "Liberia" },
  { pilots: "2", ISO3: "LTU", country: "Lithuania" },
  { pilots: "1", ISO3: "LUX", country: "Luxembourg" },
  { pilots: "17", ISO3: "MDG", country: "Madagascar" },
  { pilots: "102", ISO3: "MWI", country: "Malawi" },
  { pilots: "9", ISO3: "MYS", country: "Malaysia" },
  { pilots: "1", ISO3: "MDV", country: "Maldives" },
  { pilots: "4", ISO3: "MLI", country: "Mali" },
  { pilots: "2", ISO3: "MRT", country: "Mauritania" },
  { pilots: "3", ISO3: "MUS", country: "Mauritius" },
  { pilots: "27", ISO3: "MEX", country: "Mexico" },
  { pilots: "1", ISO3: "MDA", country: "Moldova" },
  { pilots: "4", ISO3: "MNE", country: "Montenegro" },
  { pilots: "10", ISO3: "MAR", country: "Morocco" },
  { pilots: "34", ISO3: "MOZ", country: "Mozambique" },
  { pilots: "1", ISO3: "NAM", country: "Namibia" },
  { pilots: "35", ISO3: "NPL", country: "Nepal" },
  { pilots: "13", ISO3: "NLD", country: "Netherlands" },
  { pilots: "5", ISO3: "NZL", country: "New Zealand" },
  { pilots: "1", ISO3: "NIC", country: "Nicaragua" },
  { pilots: "3", ISO3: "NER", country: "Niger" },
  { pilots: "82", ISO3: "NGA", country: "Nigeria" },
  { pilots: "5", ISO3: "NOR", country: "Norway" },
  { pilots: "1", ISO3: "OMN", country: "Oman" },
  { pilots: "30", ISO3: "PAK", country: "Pakistan" },
  { pilots: "3", ISO3: "OMN", country: "Panama" },
  { pilots: "1", ISO3: "PNG", country: "Papua New Guinea" },
  { pilots: "8", ISO3: "PRY", country: "Paraguay" },
  { pilots: "13", ISO3: "PER", country: "Peru" },
  { pilots: "34", ISO3: "PHL", country: "Philippines" },
  { pilots: "18", ISO3: "POL", country: "Poland" },
  { pilots: "23", ISO3: "PRT", country: "Portugal" },
  { pilots: "18", ISO3: "PRI", country: "Puerto Rico" },
  { pilots: "8", ISO3: "MKD", country: "Republic of North Macedonia" },
  { pilots: "10", ISO3: "ROU", country: "Romania" },
  { pilots: "8", ISO3: "RUS", country: "Russia" },
  { pilots: "8", ISO3: "RWA", country: "Rwanda" },
  { pilots: "3", ISO3: "SAU", country: "Saudi Arabia" },
  { pilots: "17", ISO3: "SEN", country: "Senegal" },
  { pilots: "1", ISO3: "SRB", country: "Serbia" },
  { pilots: "3", ISO3: "SLE", country: "Sierra Leone" },
  { pilots: "2", ISO3: "SGP", country: "Singapore" },
  { pilots: "5", ISO3: "SVK", country: "Slovakia" },
  { pilots: "4", ISO3: "SVN", country: "Slovenia" },
  { pilots: "1", ISO3: "SOM", country: "Somalia" },
  { pilots: "51", ISO3: "ZAF", country: "South Africa" },
  { pilots: "2", ISO3: "KOR", country: "South Korea" },
  { pilots: "25", ISO3: "ESP", country: "Spain" },
  { pilots: "2", ISO3: "LKA", country: "Sri Lanka" },
  { pilots: "1", ISO3: "SDN", country: "Sudan" },
  { pilots: "5", ISO3: "SUR", country: "Suriname" },
  { pilots: "178", ISO3: "SWE", country: "Sweden" },
  { pilots: "12", ISO3: "CHE", country: "Switzerland" },
  { pilots: "2", ISO3: "TWN", country: "Taiwan" },
  { pilots: "2", ISO3: "TJA", country: "Tajikistan" },
  { pilots: "14", ISO3: "TZA", country: "Tanzania" },
  { pilots: "8", ISO3: "THA", country: "Thailand" },
  { pilots: "1", ISO3: "TGO", country: "Togo" },
  { pilots: "5", ISO3: "TTO", country: "Trinidad & Tobago" },
  { pilots: "7", ISO3: "TUN", country: "Tunisia" },
  { pilots: "7", ISO3: "TUR", country: "Turkey" },
  { pilots: "15", ISO3: "UGA", country: "Uganda" },
  { pilots: "12", ISO3: "UKR", country: "Ukraine" },
  { pilots: "5", ISO3: "ARE", country: "United Arab Emirates" },
  { pilots: "50", ISO3: "GBR", country: "United Kingdom" },
  { pilots: "213", ISO3: "USA", country: "United States" },
  { pilots: "7", ISO3: "URY", country: "Uruguay" },
  { pilots: "3", ISO3: "VEN", country: "Venezuela" },
  { pilots: "6", ISO3: "VNM", country: "Vietnam" },
  { pilots: "5", ISO3: "ZMB", country: "Zambia" },
  { pilots: "15", ISO3: "ZWE", country: "Zimbabwe" },
];
