import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getAllClientsList } from "../services/client";

export const useGetClientList = () => {
  const [clients, setClients] = useStateIfMounted([]);
  const [allClients, setAllClients] = useStateIfMounted([]);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);
  const [lastLoadedClient, setLastLoadedClient] = useStateIfMounted(null);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [dataLoadedTill, setDataLoadedTill] = useStateIfMounted(null);
  const [isLastPage, setIslastPage] = useStateIfMounted(false);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [limit, setLimit] = useStateIfMounted(5);
  const [filter, setFilter] = useStateIfMounted({
    nameEmail: "",
    location: "",
  });

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    try {
      setLoading(true);
      if (dataLoadedTill >= currentPage) {
        let prevPageClients = allClients.slice(
          (currentPage - 1) * limit,
          currentPage * limit
        );
        setClients(prevPageClients);
        setLoading(false);
      } else {
        getAllClientsList(firebase, lastLoadedClient, limit, filter).then(
          (data) => {
            setClients(data?.clientsData);
            setDataLoadedTill(currentPage);
            setLastLoadedClient(data?.lastLoaded);
            setAllClients([...allClients, ...data.clientsData]);
            if (data.isLast) {
              setLastPageNumber(currentPage);
              setIslastPage(true);
            }
            setLoading(false);
          }
        );
      }
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  }, [triggerReload]);

  return {
    clients,
    setAllClients,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedClient,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  };
};
