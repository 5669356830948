import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { orderBox } from "../../../../lib/styleConstants";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import theme from "../../../../theme";
import MapTypes from "./MapTypes";
import { sensorsType } from "../../../../lib/constants";
import MapTypeInfo from "./MapTypeInfo";

const styles = {
  mapTypesCls: {
    padding: "26px",
  },
  dividerCls: {
    width: "1px",
    height: "100%",
    backgroundColor: theme.palette.grey.dividersColor,
  },
  dividerContainer: {
    display: { xs: "none", md: "flex" },
    height: "100%",
  },
  errorMsg: {
    color: theme.palette.status.errorDark,
  },
};

function ChooseSensors({
  additionalStyle,
  numberCount,
  values,
  errors,
  touched,
  setFieldValue,
  triggerReload,
  setTriggerReload,
  setMapTypesFilter,
  colMdSpacing,
  colXsSpacing,
  hideJpg,
}) {
  const [clickedMap, setClickedMap] = useState("");
  const [jpgFlag, setJpgFlag] = useState(false);
  let selectedItems = [];

  const addMapTypes = () => {
    selectedItems = values?.droneSensors
      ? [...values.droneSensors, clickedMap]
      : [clickedMap];
    setFieldValue("droneSensors", selectedItems);
    if (setMapTypesFilter) {
      setMapTypesFilter(selectedItems);
      setTriggerReload(!triggerReload);
    }
    setClickedMap(null);
  };
  return (
    <Box sx={{ ...orderBox, ...additionalStyle }}>
      <TextStructures text="Choose your sensors" number={numberCount} />
      <Box sx={styles.mapTypesCls}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={clickedMap ? 8 : 12}>
            <MapTypes
              mapTypes={sensorsType}
              colMdSpacing={colMdSpacing || 2}
              colXsSpacing={colXsSpacing || 12}
              clickable
              selectedMapTypes={values.droneSensors}
              onRemove={(data) => {
                setFieldValue("droneSensors", data);
                if (setMapTypesFilter) {
                  setMapTypesFilter(data);
                  setTriggerReload(!triggerReload);
                }
              }}
              onSelect={(data) => setClickedMap(data)}
              setJpgFlag={setJpgFlag}
              hideJpg={hideJpg}
            />
            {errors.mapTypes && touched.mapTypes ? (
              <Typography variant="body3" sx={styles.errorMsg}>
                {errors.droneSensors}
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={0.5}>
            {clickedMap && (
              <Box sx={styles.dividerContainer}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={styles.dividerCls}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={3.5}>
            {clickedMap && (
              <MapTypeInfo
                label={clickedMap}
                onClick={addMapTypes}
                jpgFlag={jpgFlag}
                type="sensors"
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ChooseSensors.propTypes = {
  // mapTypes: PropTypes.array,
  values: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  // hideJpg: PropTypes.bool,
  // colMdSpacing: PropTypes.number,
  // colXsSpacing: PropTypes.number,
  numberCount: PropTypes.number.isRequired,
  // setMapTypesFilter: PropTypes.func,
  // triggerReload: PropTypes.bool,
  // setTriggerReload: PropTypes.func,
  additionalStyle: PropTypes.object,
};

ChooseSensors.defaultProps = {
  // mapTypes: [],
  touched: {},
  errors: {},
  // hideJpg: false,
  // colMdSpacing: 2,
  // colXsSpacing: 12,
  // setMapTypesFilter: undefined,
  // triggerReload: false,
  // setTriggerReload: undefined,
  additionalStyle: {},
};

export default ChooseSensors;
