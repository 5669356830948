import firebase from "src/firebase";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getInterests } from "src/services/interest-invite";

export const useGetInterestList = (status, missionId, pilotID) => {
  const [interests, setInterests] = useStateIfMounted([]);
  const [lastLoadedInterest, setLastLoadedInterest] = useStateIfMounted(null);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const [filterInterestList, setFilterInterestList] = useStateIfMounted([]);
  const [allInterests, setAllInterests] = useStateIfMounted([]);
  const [pageData, setPagedata] = useStateIfMounted({
    currentPage: 1,
    isLast: false,
    lastPage: null,
  });
  const [filterOrSortTriggered, setFilterorSortTriggered] =
    useStateIfMounted(false);
  const [previousPage, setPreviousPage] = useStateIfMounted(0);
  const [dataloadedTill, setDataLoadedTill] = useStateIfMounted(0);
  const [authorized, setAuthorized] = useStateIfMounted(null);

  useEffect(() => {
    if (!firebase) {
      return;
    }
    let limit = 10;
    setIsLoading(true);
    if (
      dataloadedTill >= pageData.currentPage ||
      (pageData.currentPage <= previousPage && filterOrSortTriggered === false)
    ) {
      let prevPageMissionData = allInterests.slice(
        (pageData.currentPage - 1) * 10,
        pageData.currentPage * 10
      );
      setInterests(prevPageMissionData);
      setPreviousPage(pageData.currentPage - 1);
      setIsLoading(false);
      setAuthorized(true);
    } else {
      if (filterOrSortTriggered) {
        setPagedata({
          currentPage: 1,
          isLast: false,
          lastPage: null,
        });
        setPreviousPage(0);
      }
      getInterests({
        firebase,
        status,
        lastVisible: lastLoadedInterest,
        limit,
        missionId,
        excludePilotId: pilotID,
      })
        .then((data) => {
          setInterests(data.interests);
          let allMissionData = allInterests || [];
          setPreviousPage(pageData.currentPage - 1);
          setLastLoadedInterest(data.lastVisible);
          setDataLoadedTill(pageData.currentPage);
          if (data.last) {
            let pageDataUpdated = pageData;
            pageDataUpdated["isLast"] = true;
            pageDataUpdated["lastPage"] = pageData.currentPage;
            setPagedata(pageDataUpdated);
          }
          if (filterOrSortTriggered) {
            setPreviousPage(0);
            setAllInterests([...data.interests]);
          } else {
            setAllInterests([...allMissionData, ...data.interests]);
          }
          setFilterorSortTriggered(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
          setAuthorized(true);
        });
    }
  }, [status]);

  useEffect(() => {
    let newMissionList = [...interests];
    setFilterInterestList(newMissionList);
  }, [interests]);

  return [authorized, filterInterestList, isLoading];
};
