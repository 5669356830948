import React from "react";
import { Box } from "@mui/material";
import FilterComponent from "../../../../global/elements/FilterComponent";
import {
  filterFieldsForPilotProjects,
  projectColumnForPilots,
} from "../../../../../lib/constants";
import useGetProjectListForPilot from "../../../../../hooks/useGetProjectListForPilot";
import GlobalTable from "../../../admin/GlobalTable";
import { navigate } from "gatsby";
import { getUser } from "../../../../../services/auth";

const PilotProjects = () => {
  const currentUser = getUser("user");
  const pilotId = currentUser.userID;
  const [
    project,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    setIsLastPage,
    lastPageNumber,
    setLastPageNumber,
    filter,
    setFilter,
    sort,
    setSort,
    setLastLoaded,
    setLastLoadedPageNumber,
    setProjectAll,
    loading,
    triggerReload,
    setTriggerReload,
  ] = useGetProjectListForPilot(pilotId);

  const goToPageFcn = (value) => {
    navigate(`/app/pilots/project/${value.id}`);
  };

  const clearDataAndTriggerReload = () => {
    setLastLoaded(null);
    setCurrentPage(1);
    setProjectAll([]);
    setLastPageNumber(null);
    setLastLoadedPageNumber(null);
    setIsLastPage(false);
    setTriggerReload(!triggerReload);
  };

  const limitHandler = (value) => {
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const paginationBtnClick = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (!(currentPage === 1)) {
        setCurrentPage(currentPage - 1);
        setLastLoaded(null);
        setTriggerReload(!triggerReload);
      }
    }
  };
  const sortByHandler = (data) => {
    let sortValue = "desc";
    if (sort.sortValue === "desc") {
      sortValue = "asc";
    }

    setSort({
      sortBy: data,
      sortValue: sortValue,
    });
    clearDataAndTriggerReload();
  };
  return (
    <Box>
      <FilterComponent
        filterFields={filterFieldsForPilotProjects}
        multiple={true}
        filterFormName={"mainFilterFields"}
        filter={filter}
        setFilter={setFilter}
        clearDataAndTriggerReload={clearDataAndTriggerReload}
      />
      <GlobalTable
        columns={projectColumnForPilots}
        allData={project}
        goToPageFcn={goToPageFcn}
        loading={loading}
        limitHandler={limitHandler}
        limit={limit}
        paginationBtnClick={paginationBtnClick}
        currentPage={currentPage}
        isLastPage={isLastPage}
        lastPage={lastPageNumber}
        sortByHandler={sortByHandler}
      />
    </Box>
  );
};

export default PilotProjects;
