import React, { Component } from "react";
import Logs from "../services/logs";
import { Container, Box, Typography } from "@mui/material";
import BackIcon from "../components/global/elements/BackIcon";
import Robot from "../assets/images/robot.png";

const styles = {
  // root: {
  //   height: "90vh",
  // },
  backIcon: {
    paddingTop: "5vh",
  },
  containerBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: { xs: "space-around", md: "center" },
    height: "80vh",
  },
  subtitle: {
    marginTop: "20px",
  },
  image: {
    width: { xs: "100%", md: "700px" },
  },
};
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    // this.state = { error: null, errorInfo: null };
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.setHasError(true);
    console.log("Error", error ? error.toString() : "No error, all is good");
    console.log("Error info", errorInfo?.componentStack);
    // You can also log error messages to an error reporting service here
    Logs(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (!this.props?.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }
  render() {
    if (this.state.hasError) {
      return (
        <Container maxWidth="md" sx={styles.root}>
          <Box sx={styles.backIcon}>
            <BackIcon />
          </Box>
          <Box sx={styles.containerBox}>
            <Box component="img" src={Robot} sx={styles.image} />
            <Typography variant="h3">
              This page is currently undergoing maintenance
            </Typography>
            <Typography variant="h5" sx={styles.subtitle}>
              We will be back shortly to serve you better. Thank you for your
              patience and understanding.
            </Typography>
          </Box>
        </Container>

        // <div>
        //   <h2>Something went wrong. Please try again after some time.</h2>
        //   <details style={{ whiteSpace: "pre-wrap" }}>
        //     {this.state.error && this.state.error.toString()}
        //     <br />
        //     {this.state.errorInfo.componentStack}
        //   </details>
        // </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
