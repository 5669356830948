import { Box } from "@mui/material";
import { Done, Info, Warning } from "@mui/icons-material";
import PropTypes from "prop-types";
import React from "react";
import {
  missionStatusLabels,
  statusAlertSeverity,
} from "../../../../lib/constants";
import {
  daysOnDelay,
  resolveMissionStatusLabel,
} from "../../../../lib/helpers";
import RejectedIcon from "../../../global/elements/RejectedIcon";
import theme from "../../../../theme";
import AppButton from "../../../global/elements/AppButton";
import StatusAlertContainer from "../../../global/elements/StatusAlertContainer";

const getStatusState = (mission) => {
  const status = resolveMissionStatusLabel(mission);
  const daysDelayed = daysOnDelay(mission.deadline);
  const isError =
    daysDelayed > 0 &&
    [
      missionStatusLabels.correction,
      missionStatusLabels.delayed,
      missionStatusLabels.inProgress,
    ].includes(status);
  const startState = {
    isError: isError,
    title: status,
    description: undefined,
    icon: <Info color="primary" />,
    delayedNotice:
      isError && `The delivery for this mission is ${daysDelayed} days late!`,
    borderColor: isError ? "error" : "primary",
  };
  switch (status) {
    case missionStatusLabels.correction:
      return {
        ...startState,
        icon: <RejectedIcon error={isError} />,
        description: (
          <>
            Your data requires corrections. Check the details in your last
            submission page and submit your new data here.
          </>
        ),
      };
    case missionStatusLabels.delayed:
      return {
        ...startState,
        icon: <Warning color="error" />,
        description: "Please upload your data as soon as possible.",
      };
    case missionStatusLabels.readyToReview:
      return {
        ...startState,
        title: "Data uploaded!",
        description: "It is now awaiting admin approval.",
        icon: <Done color="secondary" />,
        borderColor: "secondary",
      };
    default:
      return;
  }
};

const PilotSingleMissionStatus = ({ mission, directDownloadURL }) => {
  const state = getStatusState(mission);
  if (!state) {
    return null;
  }

  const { isError } = state;

  const styles = {
    missionStatus: {
      margin: { xs: "15px 0px", md: "30px 0px" },
      padding: "10x 0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& p": {
        marginBottom: "1em",
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
      paddingTop: "10px",
    },
    titleText: {
      "&.MuiTypography-h5": {
        marginLeft: "20px",
        color: isError && theme.palette.error.main,
        fontWeight: 500,
      },
    },
    successIcon: {
      color: theme.palette.success.main,
    },
    packageDownloadButton: {
      margin: "10px",
      textAlign: "center",
      borderRadius: "10px",
      background: theme.palette.primary.main,
      color: theme.palette.grey.white,
    },
    textDelayNotice: {
      "&.MuiTypography-body2": {
        fontSize: "0.9rem",
        fontWeight: 500,
      },
    },
    textDescription: {
      "&.MuiTypography-body2": {
        fontSize: "1rem",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
  };

  return (
    <Box sx={styles.missionStatus}>
      {state.title === missionStatusLabels.delayed ? (
        <StatusAlertContainer
          heading={state.delayedNotice + " " + state.description}
          coloredBackground
          severity={statusAlertSeverity.warning}
        />
      ) : state.title === "Data uploaded!" ? (
        <StatusAlertContainer
          heading={state.title + " " + state.description}
          severity={statusAlertSeverity.success}
          coloredBackground
        />
      ) : (
        state.title === missionStatusLabels.correction && (
          <StatusAlertContainer
            heading={state.description}
            severity={statusAlertSeverity.warning}
            coloredBackground
          />
        )
      )}
      {directDownloadURL && (
        <AppButton
          look="black"
          url={directDownloadURL}
          targetNewWidow
          externalLink
          label="Download drone data package"
        />
      )}
    </Box>
  );
};

PilotSingleMissionStatus.propTypes = {
  mission: PropTypes.shape({}).isRequired,
  directDownloadURL: PropTypes.string,
};
PilotSingleMissionStatus.defaultProps = {
  directDownloadURL: undefined,
};
export default PilotSingleMissionStatus;
