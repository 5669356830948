import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Layout from "../../../../Layout";
import AppButton from "../../../global/elements/AppButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import theme from "../../../../theme";
import Tips from "../../../global/elements/Tips";
import { getUser } from "../../../../services/auth";
import { userRoles } from "../../../../lib/constants";

const styles = {
  exportPilots: {
    padding: { xs: "10px 20px" },
    marginBottom: "30px",
    background: theme.palette.grey.white,
    marginTop: "10px",
    width: "100%",
  },
  adminTitle: { paddingBottom: "10px" },
};

const DocumentsAndGuides = () => {
  const userRole = getUser("user").role;
  const isAdmin = userRole === userRoles.admin;

  return (
    <Layout
      backButtonPageName={"Documents and guides"}
      hasSideMenu
      addBottomPadding
      backButton
    >
      {isAdmin ? (
        <>
          <Tips content="Here you can access some documents regarding the T&C and the pricing. The documents are stored in GLOBHE's drive. Make sure you are signed to drive before you can open these documents." />
          <Box sx={styles.exportPilots}>
            <Typography variant="h5" style={styles.adminTitle}>
              Available documents
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <AppButton
                  look="blue"
                  label="Checklist T&C"
                  externalLink
                  url="https://docs.google.com/spreadsheets/d/1nC-iNmEkkvbgU708z57VvuWta4BWKVkxrV_2WHCRi-g/edit#gid=57254131"
                  targetNewWidow
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography>
                  Read the terms and conditions for both clients and operators.
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <AppButton
                  look="blue"
                  label="Pricing doc"
                  externalLink
                  url="https://docs.google.com/spreadsheets/d/1M6RI-XKs_AaYHpYxfDz7-L328JbdO_RQczltqHwS45Q/edit#gid=1156044388"
                  targetNewWidow
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography>
                  Calculate pricing according to the country, equipment, travel
                  distance, among other criteria.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Typography
          variant="h4"
          sx={{ marginTop: "20vh", textAlign: "center" }}
        >
          This page is not available at the moment
        </Typography>
      )}
    </Layout>
  );
};

export default DocumentsAndGuides;
