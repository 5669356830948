import React from "react";
import { Box, Grid, InputAdornment } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFieldInput from "../../global/FormInputComponents/TextFieldInput";
import TextWithTooltip from "../../global/elements/TextWithTooltip";
import SwitchSelect from "../../global/elements/SwitchSelect";
import QuotesTermsAndConditions from "../Invoices/QuotesTermsAndConditions";
import AppButton from "../../global/elements/AppButton";
import { missionStatus } from "../../../lib/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PropTypes from "prop-types";
import MultiLineTextArea from "../../global/elements/TypographyElements/MultiLineTextArea";
import { getCurrencySymbol } from "../../../lib/helpers";

const styles = {
  quotesDetailForm: {
    marginTop: "10px",
  },
  quotesDiscount: {
    marginTop: "30px",
  },
  quotesDiscountSec: {
    width: {
      xs: "100%",
      sm: "35%",
      md: "21%",
      lg: "17%",
    },
  },
  quotesDiscountInputfieldSec: {
    marginTop: "20px",
  },
  quoteBottomSec: {
    textAlign: "right",
    marginBottom: "30px",
  },
  tooltip: {
    display: "flex",
    flexDirection: "row",
  },
};

const AdminQuoteForm = ({
  setShowQuoteDialog,
  setDiscountBool,
  discountBool,
  setQuoteInvoiceData,
  mission,
  isProject,
  totalQuotesCount,
  currency,
}) => {
  const formInitialValues = {
    quoteName: `${mission.id}-${totalQuotesCount}`,
    quoteDescription: "",
    estimatedTotalCost: "",
    estimatedDeliveryDate: "",
    discount: discountBool,
    discountPercent: "",
    discountReason: "",
    pricePerUnit: "",
  };

  const validationSchemaData = Yup.object().shape({
    discountPercent: Yup.number().when("discount", {
      is: true,
      then: Yup.number()
        .positive()
        .typeError("Only numbers are allowed")
        .max(1)
        .required("Discount % is required"),
    }),
    discountReason: Yup.string().when("discount", (discount) => {
      if (discount) {
        return Yup.string().required("Discount reason is required");
      }
    }),
    estimatedTotalCost: Yup.number()
      .min(1)
      .required("Subtotal before discount is required"),
    estimatedDeliveryDate: Yup.number()
      .min(1)
      .required("Estimated Delivery Days is required"),
    quoteName: Yup.string().required("Quote name is required"),
    quoteDescription: Yup.string().required("Quote description is required"),
    expiryDate: Yup.number().positive("Number must be positive"),
    pricePerUnit: Yup.number().positive("Number must be positive"),
  });

  const quoteHandler = (values) => {
    setShowQuoteDialog(true);
    setQuoteInvoiceData({
      pricePerUnit: values.pricePerUnit,
      priceBeforeDiscount: parseFloat(values.estimatedTotalCost).toFixed(2),
      days: values.estimatedDeliveryDate,
      discount: values.discount,
      discountPercent: !discountBool ? "" : Number(values.discountPercent),
      discountReason: !discountBool ? "" : values.discountReason,
      quoteName: values.quoteName,
      quoteDescription: values.quoteDescription,
      expiryDate: values.expiryDate,
    });
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchemaData}
          onSubmit={quoteHandler}
        >
          {({ values, isSubmitting, setFieldValue }) => {
            return (
              <Form id="quoteHandler">
                <Grid container spacing={2} sx={styles.quotesDetailForm}>
                  <Grid item xs={12}>
                    <TextFieldInput
                      type="text"
                      name="quoteName"
                      label="Quote Id"
                      variant="standard"
                      setFieldValue={setFieldValue}
                      values={values}
                      disabled
                    />
                  </Grid>
                  {isProject && (
                    <Grid item xs={12} md={4}>
                      <TextFieldInput
                        type="number"
                        name="pricePerUnit"
                        label="Price per site"
                        setFieldValue={setFieldValue}
                        values={values}
                        placeholder="Price per individual site"
                        startAdornment={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ outline: "none" }}
                            >
                              {getCurrencySymbol(currency)}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <TextFieldInput
                      type="number"
                      name="estimatedTotalCost"
                      label="Subtotal"
                      setFieldValue={setFieldValue}
                      values={values}
                      placeholder="Subtotal without any discount"
                      startAdornment={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ outline: "none" }}
                          >
                            {getCurrencySymbol(currency)}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldInput
                      type="text"
                      name="estimatedDeliveryDate"
                      label="Estimated delivery days"
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sx={styles.tooltip}>
                    <TextFieldInput
                      type="number"
                      name="expiryDate"
                      label="Quote expires in:"
                      setFieldValue={setFieldValue}
                      placeholder="Choose the number of days"
                    />
                    <TextWithTooltip tooltipText="Specify the duration, in days, after which the quote will expire. If no value is selected, the default expiration period is set to 7 days." />
                  </Grid>
                  <Grid item xs={12}>
                    <MultiLineTextArea
                      isFormik
                      name="quoteDescription"
                      label="Quote Description mission"
                      setFieldValue={setFieldValue}
                      minRows={3}
                      required
                      placeholder="Write some info to explain to the client what is the basis of this quote. By pressing enter, you can go to a new line. This field is required."
                    />
                  </Grid>
                </Grid>

                {mission.status != missionStatus.completed && (
                  <Box sx={styles.quotesDiscount}>
                    {/* Discount */}
                    <Box sx={styles.quotesDiscountSec}>
                      <TextWithTooltip
                        bold
                        text="Discount"
                        tooltipText="Choose yes if you want to give the client a discount on this order. 10% is 0.1 and 90% is 0.9"
                      />
                      {/* Discount Switch */}
                      <SwitchSelect
                        handleChange={(e, value) => {
                          setDiscountBool(value);
                          setFieldValue("discount", value);
                        }}
                        name="discount"
                        checked={discountBool}
                      />
                    </Box>

                    {discountBool && (
                      <Box sx={styles.quotesDiscountInputfieldSec}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextFieldInput
                              type="text"
                              name="discountPercent"
                              label="Discount percentage (write 0.1 for 10%)"
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextFieldInput
                              type="text"
                              name="discountReason"
                              label="Discount reason"
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                )}

                <QuotesTermsAndConditions showLabel={true} />

                {mission.status != missionStatus.completed && (
                  <Box sx={styles.quoteBottomSec}>
                    <AppButton
                      disabled={isSubmitting}
                      type="submit"
                      form="quoteHandler"
                      label="Send quote"
                      look="black"
                    />
                  </Box>
                )}
              </Form>
            );
          }}
        </Formik>
      </LocalizationProvider>
    </Box>
  );
};

AdminQuoteForm.propTypes = {
  mission: PropTypes.object,
  setShowQuoteDialog: PropTypes.func,
  setDiscountBool: PropTypes.func,
  discountBool: PropTypes.bool,
  setQuoteInvoiceData: PropTypes.func,
  isProject: PropTypes.bool,
  totalQuotesCount: PropTypes.number.isRequired,
  currency: PropTypes.string,
};
AdminQuoteForm.defaultProps = {
  mission: undefined,
  setShowQuoteDialog: undefined,
  setDiscountBool: undefined,
  discountBool: false,
  setQuoteInvoiceData: undefined,
  isProject: false,
  currency: undefined,
};
export default AdminQuoteForm;
