import React from "react";
import theme from "../../../theme";
import { Link } from "gatsby";
import { Typography, Box, Card } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import AppButton from "../../global/elements/AppButton";

const styles = {
  mainDiv: {
    minWidth: { xs: 275, md: 250, lg: 275 },
    maxWidth: { md: 275, lg: 320 },
    width: { xs: "100%", md: "30%", lg: "25%" },
    marginRight: "10px",
    marginBottom: "10px",
    "& .MuiPaper-outlined": {
      border: "none",
      borderRadius: "15px",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  heading: {
    fontSize: "18px",
    fontWeight: 450,
    color: "black",
  },
  contentCard: {
    maxHeight: { xs: 200, md: 220, lg: 200 },
    minHeight: { xs: 200, md: 220, lg: 200 },
    color: theme.palette.black.darkSeaBlack,
  },
  contentAsLink: {
    textDecoration: "none",
  },
};

const NewsTable = ({ title, content, link, id }) => {
  return (
    <Box sx={styles.mainDiv}>
      <Card variant="outlined" sx={{ padding: "10px" }}>
        <Link to={`/app/admin/news/${id}`} style={styles.contentAsLink}>
          <CardContent sx={styles.contentCard}>
            <Typography
              variant="h5"
              style={styles.heading}
              color={theme.palette.black.darkSeaBlack}
              gutterBottom
            >
              {title?.slice(0, 45)}
              {title.length > 45 && "..."}
            </Typography>
            <Typography variant="body1">
              {content.slice(0, 180)}
              {content.length > 180 && "..."}
            </Typography>
          </CardContent>
        </Link>
        <CardActions sx={{ float: "right" }}>
          <AppButton look="black" url={link} internalLink label="Update" />
        </CardActions>
      </Card>
    </Box>
  );
};

NewsTable.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default NewsTable;
