import React from "react";
import { Typography, Grid, Divider } from "@mui/material";
import PropTypes from "prop-types";
import AppButton from "../../../../global/elements/AppButton";
import firebase from "src/firebase";
import { deleteAdminCreatedClients } from "../../../../../services/client";
import { useSnackbar } from "notistack";

const styles = {
  container: {
    marginTop: "10px",
    padding: "10px",
  },
  item: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    fontWeight: 500,
  },
  button: {
    textAlign: "right",
  },
};

const NameComponent = ({ title, content }) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="body1" sx={styles.name}>
          {title}:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1">{content}</Typography>
      </Grid>
    </Grid>
  );
};

NameComponent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

const SingleClientContainer = ({ refreshPage, client, index }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteButtonClick = () => {
    deleteAdminCreatedClients(firebase, index)
      .then(() => {
        enqueueSnackbar("Your client profile has been deleted successfully", {
          variant: "success",
        });
        refreshPage();
        // Optionally, you can also update the state or trigger a re-fetch here
      })
      .catch((error) => {
        console.error("Error deleting array:", error);
        enqueueSnackbar("Error in deleting client profile", {
          variant: "error",
        });
      });
  };

  return (
    <Grid container spacing={1} sx={styles.container}>
      <Grid item xs={12} sx={styles.item}>
        <NameComponent title="Name" content={client.clientName} />
      </Grid>
      <Grid item xs={12} sx={styles.item}>
        <NameComponent title="Company" content={client.companyName} />
      </Grid>
      <Grid item xs={12} sx={styles.item}>
        <NameComponent title="Phone number" content={client.phone} />
      </Grid>
      <Grid item xs={12} sx={styles.button}>
        <AppButton
          look="inverted"
          label="Remove"
          onClick={handleDeleteButtonClick}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

SingleClientContainer.propTypes = {
  refreshPage: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
};

export default SingleClientContainer;
