import React, { useContext } from "react";
import AppButton from "../../../../global/elements/AppButton";
import MissionContext from "./MissionContext";
import DownloadIcon from "@mui/icons-material/Download";

const DownloadGuidlines = () => {
  const data = useContext(MissionContext);

  const downloadGuidelines = () => {
    window.open(data?.mission?.guidelinesPdfUrl);
  };
  return (
    <>
      {data?.mission?.guidelinesPdfUrl && (
        <AppButton
          look="black"
          label="Download Guidelines"
          onClick={() => downloadGuidelines()}
          size={"100%"}
          addtionalStyle={{
            justifyContent: "center",
          }}
          customIcon={<DownloadIcon />}
        />
      )}
    </>
  );
};

export default DownloadGuidlines;
