import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useDropzone } from "react-dropzone";
import AppButton from "../../../global/elements/AppButton";
import Dialogue from "../../../global/Dialogue";
import PropTypes from "prop-types";

const styles = {
  dashedBox: {
    border: `1px dashed`,
  },
  centerAlign: {
    textAlign: "center",
  },
  btnCls: {
    marginTop: "30px",
  },
  iconCls: {
    fontSize: "50px",
  },
  dragContainerCls: {
    marginTop: "30px",
  },
};

const UploadClientAgreement = ({ open, setOpen, onDrop }) => {
  const DialogueBody = () => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: ".pdf",
      onDrop: (acceptedFiles) => {
        onDrop(acceptedFiles);
      },
    });
    return (
      <Box sx={styles.dashedBox}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={styles.centerAlign}
          padding={2}
        >
          <Grid item xs={12}>
            <CloudUploadOutlinedIcon sx={styles.iconCls} />
          </Grid>
          <Box
            {...getRootProps({
              className: "dropzone",
            })}
            sx={styles.dragContainerCls}
          >
            <input name={"agreement"} {...getInputProps()} />
            <Grid item xs={12}>
              <Typography variant="h3">Drag file here</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">or browse</Typography>
            </Grid>

            <Box sx={styles.btnCls}>
              <Grid item xs={12}>
                <AppButton
                  look="black"
                  label="Browse File"
                  // onClick={() => {}}
                  size={"368px"}
                />
              </Grid>
            </Box>
          </Box>
          <Grid item xs={12}>
            <AppButton
              look="ghost"
              label="Cancel"
              noIcon
              size={"368px"}
              onClick={() => {
                setOpen(false);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      <Dialogue
        showDialogue={open}
        setShowDialogue={setOpen}
        DialogueHeadText={""}
        DialogueBody={<DialogueBody />}
        tableType={true}
      />
    </Box>
  );
};

UploadClientAgreement.propTypes = {
  onDrop: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UploadClientAgreement;
