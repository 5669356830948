import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import theme from "../../../../theme";
import PropTypes from "prop-types";

const TitleAndDivider = ({ title, marginTop }) => {
  return (
    <Grid item xs={12} mt={marginTop}>
      <Typography variant="h5">{title}</Typography>
      <Divider color={theme.palette.grey.warmGrey} />
    </Grid>
  );
};

TitleAndDivider.propTypes = {
  title: PropTypes.string.isRequired,
  marginTop: PropTypes.number,
};
TitleAndDivider.defaultProps = {
  marginTop: 4,
};

export default TitleAndDivider;
