import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getInspirations } from "../services/news-inspiration";

const useGetInspirationsList = () => {
  const [allInspiration, setAllInspiration] = useState(null);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(12);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    getInspirations(limit)
      .then((data) => {
        setAllInspiration(data?.inspirations);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [allInspiration, loading];
};

export default useGetInspirationsList;
