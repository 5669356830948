import React, { useState } from "react";
import PropTypes from "prop-types";
import { paymentStatusofInvoice } from "../../../lib/constants";
import { Box, TextField, Autocomplete } from "@mui/material";
import AutoCompleteFilter from "../../global/elements/AutoCompleteFilter";

const styles = {
  field: {
    marginBottom: "15px",
    width: "100%",
  },
  filterFieldsCss: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill, 240px)",
    margin: "10px 0",
  },
  filterContainer: {
    padding: "5px 0",
    width: "100%",
  },
  TextField: {
    height: "20px",
  },
};

const FilterInvoice = ({ valueChangeHandler }) => {
  const [filterField, setFilterField] = useState([]);
  const [changedFilterMonitor, setchangedFilterMonitor] = useState(false);
  const [filterData, setFilterData] = useState({
    "Payment Status": "",
  });

  const filterOptions = ["Payment Status"];

  const onChangeFilterField = (e, newValue) => {
    setFilterField(newValue);
    setchangedFilterMonitor(!changedFilterMonitor);
    valueChangeHandler(newValue);
  };

  return (
    <Box sx={styles.filterContainer}>
      <AutoCompleteFilter
        filterOptions={filterOptions}
        filterField={filterField}
        onChangeFilterField={onChangeFilterField}
        helperText="Select the fields to filter the invoice with"
      />
      <Box sx={styles.filterFieldsCss}>
        {filterField?.includes("Payment Status") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={paymentStatusofInvoice}
              defaultValue={filterData["Payment Status"]}
              onChange={(e, value) => {
                valueChangeHandler(value, "Payment Status", filterField);
                let newData = filterData;
                newData["Payment Status"] = value;
                setFilterData(newData);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Payment Status"
                />
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

FilterInvoice.propTypes = {
  valueChangeHandler: PropTypes.func.isRequired,
};

export default FilterInvoice;
