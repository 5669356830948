import React from "react";
import Layout from "../../../Layout";
import OrderMainContainer from "./OrderMainContainer";
import {
  orderLocationTypeLabel,
  formTypeConstant,
} from "../../../lib/constants";

const EditSingleLocationOrder = () => {
  return (
    <Layout>
      <OrderMainContainer
        formType={formTypeConstant.edit}
        orderType={orderLocationTypeLabel.singleLocationOrder}
      />
    </Layout>
  );
};

export default EditSingleLocationOrder;
