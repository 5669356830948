import React from "react";
import { useCookies } from "react-cookie";
import DashboardBanner from "./DashboardBanner";
import AppButton from "../../../../global/elements/AppButton";

const AddTeamMemberBanner = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["enterpriseAccount"]);

  const skipFunc = () => {
    removeCookie("enterpriseAccount", { path: "/" });
  };
  return (
    <DashboardBanner
      heading={"Congratulation! you’re now a enterprise account"}
      subHeading={
        " Easily expand your workforce by inviting new members to collaborate and boost productivity."
      }
    >
      <AppButton
        look="ghost"
        label="Skip for the moment"
        noIcon
        onClick={skipFunc}
      />
      <AppButton
        look="black"
        label="Manage team"
        url="/app/client/enterprise"
        internalLink
      />
    </DashboardBanner>
  );
};

export default AddTeamMemberBanner;
