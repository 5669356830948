import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  FilterFieldsMission,
  missionTypes,
  FilterFieldActiveMissionList,
  missionStatus,
  FilterFieldCompleteMissionList,
  userRoles,
  activeMissionStatus,
  PaymentStatus,
  archiveMissionTypes,
  FilterFieldsArchiveMission,
  pilotPaymentStatus,
  missionTypeConstant,
  FilterFieldClientOrdersActiveList,
  FilterFieldCompleteClientOrderList,
  FilterFieldsClientOrder,
  testMissionStatus,
  FilterFieldsClientArchiveMission,
  FilterFieldMissedMissions,
  FilterFieldRejectedMissions,
  FilterFieldInquiresMissions,
} from "../../../lib/constants";
import { Box, TextField, Autocomplete } from "@mui/material";
import AutoCompleteFilter from "../../global/elements/AutoCompleteFilter";

const styles = {
  field: {
    marginBottom: "15px",
    width: "100%",
  },
  filterFieldsCss: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill, 240px)",
    margin: "10px 0",
  },
  filterContainer: {
    padding: "5px 0",
    width: "100%",
  },
  TextField: {
    height: "20px",
  },
};
const FilterMission = ({
  valueChangeHandler,
  role,
  missionTypeData,
  missionType,
}) => {
  const [filterField, setFilterField] = useState([]);
  const [changedFilterMonitor, setchangedFilterMonitor] = useState(false);
  const [filterData, setFilterData] = useState({
    Id: "",
    "Mission Type": "",
    "Mission Name": "",
  });
  const [filterOptions, setFilterOptions] = useState([]);
  useEffect(() => {
    let options = getFilterOption();
    setFilterOptions(options);
  }, []);
  useEffect(() => {
    let filterFieldData = localStorage.getItem("filterField");
    if (filterFieldData) {
      let getAllFilters = filterFieldData?.split(",");
      if (getAllFilters?.length > 0) {
        let pageFilterOptions = getFilterOption();
        let applyFilterOn = pageFilterOptions.filter((opt) =>
          getAllFilters.includes(opt)
        );
        if (
          (missionTypeData === missionStatus.unassigned ||
            missionTypeData === missionStatus.confirmedMission) &
          (role == userRoles.pilot)
        ) {
          let newFilterField = applyFilterOn.filter(
            (flt) => flt != "Mission Name"
          );
          setFilterField(newFilterField);
          let newFilterData = JSON.parse(localStorage.getItem("filter"));
          newFilterData["Mission Name"] == "";
          setFilterData(newFilterData);
          valueChangeHandler("InitialLoad", "InitialLoad", newFilterField);
          localStorage.setItem("filterField", newFilterField);
        } else {
          setFilterField(applyFilterOn || []);
          setFilterData(JSON.parse(localStorage.getItem("filter")));
          valueChangeHandler("InitialLoad", "InitialLoad", applyFilterOn);
        }
      }
    }
  }, [changedFilterMonitor]);
  const getFilterOption = () => {
    if (missionType && missionType == missionTypeConstant.clientOrder) {
      if (missionTypeData == missionStatus.active) {
        return FilterFieldClientOrdersActiveList;
      } else if (missionTypeData == missionStatus.completed) {
        return FilterFieldCompleteClientOrderList;
      } else if (missionTypeData == missionStatus.archive) {
        return FilterFieldsClientArchiveMission;
      } else {
        return FilterFieldsClientOrder;
      }
    }
    if (
      missionType &&
      (missionType == missionTypeConstant.challengeMission ||
        missionType == missionTypeConstant.testMission ||
        missionType == missionTypeConstant.guinnessWorldRecord)
    ) {
      return FilterFieldClientOrdersActiveList;
    }
    if (missionTypeData === missionStatus.unassigned) {
      if (role == userRoles.pilot) {
        return FilterFieldInquiresMissions;
      } else {
        return FilterFieldsMission;
      }
    } else if (missionTypeData == missionStatus.active) {
      return FilterFieldActiveMissionList;
    } else if (missionTypeData == missionStatus.completed) {
      return FilterFieldCompleteMissionList;
    } else if (missionTypeData == missionStatus.archive) {
      return FilterFieldsArchiveMission;
    } else if (missionTypeData == missionStatus.missed) {
      return FilterFieldMissedMissions;
    } else if (missionTypeData == missionStatus.rejected) {
      return FilterFieldRejectedMissions;
    } else {
      return FilterFieldsMission;
    }
  };
  const onChangeFilterField = (e, newValue) => {
    setFilterField(newValue);
    setchangedFilterMonitor(!changedFilterMonitor);
    if (newValue?.length > 0) {
      localStorage.setItem("filterField", newValue);
    } else {
      localStorage.setItem("filterField", []);
    }
    valueChangeHandler("", "", newValue);
  };

  const getStatusOptions = () => {
    if (
      missionType == missionTypeConstant.challengeMission ||
      missionType == missionTypeConstant.testMission ||
      missionType == missionTypeConstant.guinnessWorldRecord
    ) {
      return testMissionStatus;
    } else {
      return activeMissionStatus;
    }
  };
  return (
    <Box sx={styles.filterContainer}>
      <AutoCompleteFilter
        filterOptions={filterOptions}
        filterField={filterField}
        onChangeFilterField={onChangeFilterField}
        helperText="Select the fields to filter the missions with"
      />
      <Box sx={styles.filterFieldsCss}>
        {filterField?.includes("Mission Name") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Mission Name"]}
              onChange={(e) => {
                let newData = filterData;
                newData["Mission Name"] = e.target.value;
                setFilterData(newData);
                valueChangeHandler(e.target.value, "Mission Name", filterField);
              }}
              label="Mission Name"
              sx={styles.field}
            />
          </Box>
        )}
        {filterField?.includes("Id") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Id"]}
              onChange={(e, value) => {
                let newData = filterData;
                newData["Id"] = e.target.value;
                setFilterData(newData);
                valueChangeHandler(e.target.value, "Id", filterField);
              }}
              label="Id"
              sx={styles.field}
            />
          </Box>
        )}
        {filterField?.includes("Mission Type") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={missionTypes}
              value={filterData["Mission Type"]}
              onChange={(e, value) => {
                valueChangeHandler(value, "Mission Type", filterField);
                let newData = filterData;
                newData["Mission Type"] = value;
                setFilterData(newData);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Mission Type"
                />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Mission Category") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={archiveMissionTypes}
              value={filterData["Mission Category"]}
              onChange={(e, value) => {
                valueChangeHandler(value, "Mission Category", filterField);
                let newData = filterData;
                newData["Mission Category"] = value;
                setFilterData(newData);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Mission Category"
                />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Status") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={getStatusOptions()}
              value={filterData["Status"]}
              onChange={(e, value) => {
                valueChangeHandler(value, "Status", filterField);
                let newData = filterData;
                newData["Status"] = value;
                setFilterData(newData);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Status" />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Payment Status") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={
                role == userRoles.pilot ? pilotPaymentStatus : PaymentStatus
              }
              value={filterData["Payment Status"]}
              onChange={(e, value) => {
                valueChangeHandler(value, "Payment Status", filterField);
                let newData = filterData;
                newData["Payment Status"] = value;
                setFilterData(newData);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Payment Status"
                />
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
FilterMission.propTypes = {
  valueChangeHandler: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  missionTypeData: PropTypes.string.isRequired,
  missionType: PropTypes.any,
};

FilterMission.defaultProps = {
  missionType: undefined,
};
export default FilterMission;
