import firebase from "src/firebase";
import {
  daysFromToday,
  getCurrentDateTime,
  getDateFromTimeStamp,
} from "../lib/helpers";
import { DateTime } from "luxon";
import { getUser } from "src/services/auth";
import { invoicePaymentStatus, missionStatus } from "../lib/constants";

const invoiceSchema = async (data) => {
  const date = getCurrentDateTime();
  const dueDateTime = daysFromToday(30);
  const dueDate = new Date(dueDateTime).toISOString().split("T");
  return firebase
    .firestore()
    .collection("invoices")
    .add({
      missionId: data?.missionId || "",
      mission_name: data?.mission_name || "",
      pilotId: data?.pilotId || "",
      pilotName: data?.pilotName || "",
      status: data?.status || "not_sent",
      due_date: data.due_date
        ? DateTime.utc(data?.due_date).toISODate()
        : dueDate[0],
      invoice_link: data?.invoice_link || "",
      comment: data?.comment || "no comments provided",
      adminEditAccess: data?.adminEditAccess || false,
      dateCreated: date,
      deleted: false,
      amount: data?.amount || 0,
      lastUpdated: date,
      pilotEditAccess: data.pilotEditAccess,
    })
    .then((docRef) => {
      return {
        status: "Success",
        invoice_details: docRef,
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
      return {
        status: "Error",
        error: err,
      };
    });
};

export const createInvoice = async (data) => {
  let status = await invoiceSchema(data);
  return status;
};

export const getInvoice = (id) => {
  return firebase
    .firestore()
    .collection("invoices")
    .doc(id)
    .get()
    .then((snapshot) => {
      const invoice_details = { ...snapshot.data(), id: snapshot?.id };
      return {
        invoice_details,
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
    });
};

export const getInvoiceList = (lastLoadedinvoice, limit = 10) => {
  const userId = getUser("user").userID;

  let query = firebase
    .firestore()
    .collection("invoices")
    .where("pilotId", "==", userId)
    .where("deleted", "==", false)
    .orderBy("dateCreated", "desc");

  if (lastLoadedinvoice) {
    query = query.startAfter(lastLoadedinvoice);
  }

  return query
    .limit(limit)
    .get()
    .then((snapshot) => {
      const invoices = snapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        acc.push({ ...data, id: doc.id });
        return acc;
      }, []);
      return {
        invoices,
        lastVisible:
          snapshot.size > 0 ? snapshot.docs[snapshot.docs.length - 1] : null,
        last: snapshot.size < limit,
      };
    })
    .catch((err) => {
      console.log("Error in creating the collection", err);
    });
};

export const getInvoiceListAdmin = (lastLoadedinvoice, limit = 10, filter) => {
  try {
    return new Promise(function (resolve) {
      let query = firebase
        .firestore()
        .collection("invoices")
        .where("deleted", "==", false)
        .orderBy("dateCreated", "desc");

      Object.keys(filter).forEach((filterField) => {
        if (
          filter[filterField] != null ||
          filter[filterField] != "" ||
          filter[filterField] != []
        ) {
          if (filterField == "Payment Status") {
            if (filter[filterField] == "paid") {
              query = query.where("status", "==", "paid");
            } else if (filter[filterField] == "unpaid") {
              query = query.where("status", "==", "unpaid");
            } else if (filter[filterField] == "Require Edit") {
              query = query.where("status", "==", "require_edit");
            } else if (filter[filterField] == "Not Received") {
              query = query.where("status", "==", "not_sent");
            }
          }
        }
      });

      if (lastLoadedinvoice) {
        query = query.startAfter(lastLoadedinvoice);
      }

      query
        .limit(limit)
        .get()
        .then((snapshot) => {
          const invoices = snapshot.docs.reduce((acc, doc) => {
            const data = doc.data();
            acc.push({ ...data, id: doc.id });
            return acc;
          }, []);

          resolve({
            invoices,
            lastVisible:
              snapshot.size > 0
                ? snapshot.docs[snapshot.docs.length - 1]
                : null,
            last: snapshot.size < limit,
          });
        });
    }).catch((err) => {
      console.log("Error in creating the collection", err);
    });

    // return query
    //   .limit(limit)
    //   .get()
    //   .then((snapshot) => {
    //     const invoices = snapshot.docs.reduce((acc, doc) => {
    //       const data = doc.data();
    //       acc.push({ ...data, id: doc.id });
    //       return acc;
    //     }, []);
    //     return {
    //       invoices,
    //       lastVisible:
    //         snapshot.size > 0
    //           ? snapshot.docs[snapshot.docs.length - 1]
    //           : null,
    //       last: snapshot.size < limit,
    //     };
    //   })
    //   .catch((err) => {
    //     console.log("Error in creating the collection", err);
    //   });
  } catch (error) {
    console.log(error);
  }
};

export const invoiceDetailsUpdate = (data, id) => {
  const date = getCurrentDateTime();
  const new_date = data?.due_date?.isLuxonDateTime
    ? DateTime.fromISO(data?.due_date).toISODate()
    : DateTime.fromJSDate(data?.due_date).toISODate();

  return firebase
    .firestore()
    .collection("invoices")
    .doc(id)
    .update({
      ...data,
      due_date: new_date || data?.due_date,
      lastUpdated: date,
    })
    .then(() => {
      return {
        status: "Success",
      };
    })
    .catch((err) => {
      console.log("function called error", err);

      return {
        status: "Error",
        error: err,
      };
    });
};

export const invoiceStatusUpdate = (data, id) => {
  const date = getCurrentDateTime();

  return firebase
    .firestore()
    .collection("invoices")
    .doc(id)
    .update({
      ...data,
      lastUpdated: date,
    })
    .then(() => {
      return {
        status: "Success",
      };
    })
    .catch((err) => {
      return {
        status: "Error",
        error: err,
      };
    });
};
export const getMissionInvoiceByProjectId = (pilotId, projectId) => {
  var query = firebase
    .firestore()
    .collection("invoices")
    .where("projectId", "==", projectId)
    .where("pilotId", "==", pilotId);
  return query
    .get()
    .then((snapshot) => {
      return { ...snapshot?.docs[0]?.data(), id: snapshot?.docs[0]?.id };
    })
    .catch((err) => {
      console.log("Error in getting the collection", err);
    });
};

const getPilotProjectMission = ({ project, pilotId }) => {
  try {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();
      let query = db.collection("missions");
      query = query
        .where("projectIds", "==", project.id)
        .where("assignedPilot", "==", pilotId)
        .where("status", "==", missionStatus.completed);

      query
        .get()
        .then((missions) => {
          let result = {
            missionIds: [],
            missions: [],
          };
          let pilotPrice = 0;
          let pilotName = null;
          for (let i = 0; i < missions?.docs?.length; i++) {
            let mission = {
              id: missions.docs[i].id,
              ...missions.docs[i].data(),
            };
            if (mission.pilotPrice && !isNaN(mission.pilotPrice)) {
              pilotPrice = pilotPrice + Number(mission.pilotPrice);
            }
            if (!pilotName && mission.assignedPilotName) {
              pilotName = mission.assignedPilotName;
            }
            result.missionIds.push(mission.id);
            result.missions.push({
              id: mission.id,
              missionName: mission.missionName,
            });
          }

          resolve({ ...result, pilotPrice, pilotName, pilotId });
        })
        .catch((e) => {
          console.log("Error", e);
          reject({ message: "Error in fetching pilot mission" });
        });
    });
  } catch (e) {
    console.log("Error", e);
    throw { message: "Catch :Error in fetching pilot mission" };
  }
};

export const createProjectInvoice = async ({ project }) => {
  try {
    let missionDataWithPilots = [];
    if (!project?.associatedDroneOperatorsIds?.length) {
      throw { message: "Associated pilot id is not found!" };
    }

    const requests = project?.associatedDroneOperatorsIds?.map((pilotId) =>
      getPilotProjectMission({ project, pilotId })
        .then((obj) => {
          if (obj) {
            missionDataWithPilots.push(obj);
          }
        })
        .catch((e) => {
          console.log("Error", e);
        })
    );
    return Promise.all(requests)
      .then(() => {
        return createPojectInvoiceHandle({ project, missionDataWithPilots });
      })
      .catch((e) => {
        console.log("Error", e);
        throw { message: e?.message || "Error in creating project invoice!" };
      });
  } catch (e) {
    console.log("Error", e);
    throw { message: e?.message || "Error in creating project invoice!" };
  }
};

const getProjectInvoiceObj = ({ project, data }) => {
  try {
    const date = getCurrentDateTime();
    const dueDateTime = daysFromToday(30);
    const dueDate = getDateFromTimeStamp(dueDateTime);

    let invoiceObj = {
      missionId: "",
      mission_name: "",
      pilotId: data?.pilotId || "",
      pilotName: data?.pilotName || "",
      status: invoicePaymentStatus.not_sent,
      due_date: dueDate,
      invoice_link: "",
      comment: "no comments provided",
      adminEditAccess: true,
      dateCreated: date,
      deleted: false,
      amount: data?.pilotPrice || 0,
      lastUpdated: date,
      pilotEditAccess: false,
      projectId: project.id,
      projectName: project.projectName,
      missionIds: data?.missionIds || [],
      missions: data?.missions || [],
    };
    return invoiceObj;
  } catch (e) {
    console.log("Error", e);
    throw { message: "Error in creating project invoice!" };
  }
};

const createPojectInvoiceHandle = async ({
  project,
  missionDataWithPilots,
}) => {
  try {
    const db = firebase.firestore();
    const batchCount = 499;
    const batchArray = [];
    let operationCounter = 0;
    let batchIndex = 0;
    batchArray.push(db.batch());
    for (let i = 0; i < missionDataWithPilots.length; i++) {
      const invoiceRef = db.collection("invoices").doc();
      const invoice = getProjectInvoiceObj({
        project,
        data: missionDataWithPilots[i],
      });
      await batchArray[batchIndex].set(invoiceRef, invoice);
      operationCounter++;
      if (operationCounter === batchCount) {
        batchArray.push(db.batch());
        batchIndex++;
        operationCounter = 0;
      }
    }
    batchArray.forEach(async (batch) => await batch.commit());
    return {
      status: "Success",
    };
  } catch (e) {
    console.log("Error", e);
    throw { message: "Error in creating project invoice!" };
  }
};

export const getMissionInvoiceByMissionId = ({ pilotId, missionId }) => {
  var query = firebase
    .firestore()
    .collection("invoices")
    .where("missionId", "==", missionId)
    .where("pilotId", "==", pilotId);
  return query
    .get()
    .then((snapshot) => {
      return { ...snapshot?.docs[0]?.data(), id: snapshot?.docs[0]?.id };
    })
    .catch((err) => {
      console.log("Error in getting the collection", err);
    });
};
export const getInvoiceUsingMissionId = async (missionId) => {
  return firebase
    .firestore()
    .collection("invoices")
    .where("missionId", "==", missionId)
    .get()
    .then(async (docs) => {
      let InvoiceData = {};

      if (docs.size) {
        docs.forEach((doc) => {
          let invData = { id: doc.id, ...doc.data() };
          InvoiceData = invData;
        });
        return InvoiceData;
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};

export const splitInvoice = (firebase, missionID, status) => {
  return firebase.firestore().collection("missions").doc(missionID).update({
    breakInvoice: status,
  });
};
export const splitProjectInvoice = (firebase, projectID, status) => {
  return firebase.firestore().collection("projects").doc(projectID).update({
    breakInvoice: status,
  });
};

export const getBreakInvoiceStatus = (firebase, missionID) => {
  return firebase
    .firestore()
    .collection("missions")
    .where("id", "==", missionID)
    .limit(1)
    .get()
    .then((snapshot) => {
      const doc = snapshot.docs[0];

      return doc.data().breakInvoice;
    });
};

export const getProjectBreakInvoiceStatus = (firebase, projectID) => {
  return firebase
    .firestore()
    .collection("projects")
    .doc(projectID) // Reference document directly using its ID
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data().breakInvoice;
      } else {
        console.log("No such document!");
        return null; // Return null or handle the absence of the document accordingly
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      throw error; // Rethrow the error for the caller to handle
    });
};
