import { expectedMbSize, getQuoteCurrency } from "../lib/helpers";
import firebase from "src/firebase";

export const clientQuoteEmail = async (
  firebase,
  missionData,
  quoteInvoiceData
) => {
  if (missionData.clientId || missionData.enterPriseId) {
    const clientConfirmMissionEmail = await firebase
      .functions()
      .httpsCallable("clientConfirmMissionEmail");
    const data = await clientConfirmMissionEmail({
      area: missionData?.areaSize?.toFixed(2),
      MBsize: expectedMbSize(missionData?.areaSize),
      days: quoteInvoiceData.days,
      pricePerMB: quoteInvoiceData.pricePerUnit,
      cost: quoteInvoiceData.priceBeforeDiscount,
      quoteNumber:
        new Date().toJSON().slice(0, 10).replace(/-/g, "") +
        Math.floor(1000 + Math.random() * 9000),
      clientName: missionData?.clientName,
      date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
      clientEmail: missionData?.clientEmail,
      missionId: missionData?.id,
      discountPercentage: quoteInvoiceData.discountPercent * 100,
      quoteName: quoteInvoiceData.quoteName,
      quoteDescription: quoteInvoiceData.quoteDescription,
      enterPriseId: missionData.enterPriseId,
      finalCost: (
        quoteInvoiceData.priceBeforeDiscount -
        quoteInvoiceData.discountPercent * quoteInvoiceData.priceBeforeDiscount
      ).toFixed(2),
      currency: getQuoteCurrency(quoteInvoiceData),
    });
    return {
      status: "200",
      data: data,
    };
  }
};

export const clientProjectQuoteEmail = async ({ project, quote }) => {
  try {
    const sendEmail = await firebase
      .functions()
      .httpsCallable("clientProjectQuoteEmail");
    const data = await sendEmail({
      clientName: project?.clientName,
      clientEmail: project?.clientEmail,
      projectId: project?.id,
      quoteName: quote?.quoteName,
      enterPriseId: project?.enterPriseId,
    });
    return {
      status: "Success",
      data: data,
    };
  } catch (e) {
    console.log("Error", e);
    return {
      status: "Error",
      message: e?.message || "Error in sending project quote to client",
    };
  }
};

const getProjectObj = ({ project }) => {
  let obj = {
    clientName: project.clientName,
    clientCompanyName: project.clientCompanyName,
    country: project.country,
    projectName: project.projectName,
    projectDescription: project.description,
    clientEmail: project.clientEmail,
    enterPriseId: project?.enterPriseId || null,
  };
  return obj;
};

export const projectCreatedByAdmin = async ({ project }) => {
  try {
    const sendEmail = await firebase
      .functions()
      .httpsCallable("sendEmailOnProjectCreatedByAdmin");
    let obj = getProjectObj({ project });
    const data = await sendEmail(obj);
    return {
      status: "Success",
      data: data,
    };
  } catch (e) {
    console.log("Error", e);
    return {
      status: "Error",
      message: e?.message || "Error in sending preject create email to client",
    };
  }
};

export const projectCreatedByClient = async ({ project }) => {
  try {
    const sendEmail = await firebase
      .functions()
      .httpsCallable("sendEmailOnProjectCreatedByClient");
    let obj = getProjectObj({ project });
    const data = await sendEmail(obj);
    return {
      status: "Success",
      data: data,
    };
  } catch (e) {
    console.log("Error", e);
    return {
      status: "Error",
      message: e?.message || "Error in sending preject create email to client",
    };
  }
};
export const projectEmail = async ({ project, functionName, errorMessage }) => {
  try {
    const sendEmail = await firebase.functions().httpsCallable(functionName);
    let obj = getProjectObj({ project });
    const data = await sendEmail(obj);
    return {
      status: "Success",
      data: data,
    };
  } catch (e) {
    console.log("Error", e);
    return {
      status: "Error",
      message: e?.message || errorMessage,
    };
  }
};
