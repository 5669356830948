import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Dialogue from "../../../global/Dialogue";

const styles = {
  popupRoot: {
    textAlign: "center",
    padding: "20px",
  },
  popupHeading: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  popupSubText: {
    width: { xs: "80%", sm: "70%" },
    textAlign: "center",
    margin: "auto",
    marginBottom: "30px",
  },
};

const DeleteEnterpriseDoc = ({ deletePopup, setDeletePopup, deleteFcn }) => {
  const deleteDocBody = () => {
    return (
      <Box sx={styles.popupRoot}>
        <Typography variant="h3" sx={styles.popupHeading}>
          Are you sure you want to delete this document?
        </Typography>
        <Typography variant="body2" sx={styles.popupSubText}>
          This action cannot be reverted
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <Dialogue
        showDialogue={deletePopup}
        setShowDialogue={setDeletePopup}
        DialogueBody={deleteDocBody()}
        showCancelButton={true}
        cancelButtonText={"Cancel"}
        sumitButtonText={"Delete"}
        onSubmit={() => {
          deleteFcn();
        }}
        submitButtonLook="green"
        bottomDeviderLine={true}
        noSubmitButtonIcon={true}
        hideIcon={true}
      />
    </>
  );
};

DeleteEnterpriseDoc.propTypes = {
  deletePopup: PropTypes.bool.isRequired,
  setDeletePopup: PropTypes.func.isRequired,
  deleteFcn: PropTypes.func.isRequired,
};

export default DeleteEnterpriseDoc;
