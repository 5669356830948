import React from "react";
import theme from "../../../theme";
import { Typography, Box, Divider } from "@mui/material";
import PropTypes from "prop-types";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { boxShadowStyle } from "../../../lib/styleConstants";

const style = {
  mission_stats_main: {
    background: theme.palette.grey.white,
    padding: "15px",
    order: { xs: 2, md: 1 },
    marginRight: "10px",
    height: "100%",
  },
  mission_stats_header: {
    display: "flex",
    margin: "0px 15px 15px 15px",
    position: "relative",
  },
  header_icon: {
    color: theme.palette.secondary.main,
    height: "35px",
    width: "44px",
    padding: "6px 5px 0px 5px",
    fontSize: "40px",
  },
  header_text: {
    margin: "auto 0px",
    textAlign: "center",
  },
  body_content: {
    display: "flex",
    marginTop: "30px",
  },
  text_value: {
    margin: "10px",
    textAlign: "center",
    "&.MuiTypography-h5": {
      paddingLeft: { xs: "27px", xl: "35px" },
      paddingTop: "12px",
    },
  },
  content_body_individual: {
    width: "33%",
    // margin: "auto",
    textAlign: "center",
  },
};

const MissionStats = ({
  unassignedMission,
  activeMission,
  completedMission,
}) => {
  const createTabs = (fieldName, value) => {
    return (
      <Box sx={style.content_body_individual}>
        <Typography variant="subtitle1Med" sx={style.header_text}>
          {fieldName}
        </Typography>
        <Typography variant="h4" sx={style.text_value}>
          {value}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ ...style.mission_stats_main, ...boxShadowStyle }}>
      <Box sx={style.mission_stats_header}>
        <Box sx={boxShadowStyle}>
          <MapOutlinedIcon sx={style.header_icon} />
        </Box>
      </Box>
      <Box sx={style.body_content}>
        {createTabs("Invited missions", unassignedMission)}
        <Divider orientation="vertical" flexItem />
        {createTabs("Ongoing missions", activeMission)}
        <Divider orientation="vertical" flexItem />
        {createTabs("Completed missions", completedMission)}
      </Box>
    </Box>
  );
};

MissionStats.propTypes = {
  unassignedMission: PropTypes.number.isRequired,
  activeMission: PropTypes.number.isRequired,
  completedMission: PropTypes.number.isRequired,
};

export default MissionStats;
