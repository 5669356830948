import React from "react";
import PropTypes from "prop-types";
import StatusBadge from "./StatusBadge";
import { Box, Typography } from "@mui/material";
import { colorStatus } from "../../../lib/constants";

const styles = {
  main: {
    padding: "10px 10px",
  },
  headingTextStyle: {
    width: "100%",
  },
  valueTextStyle: {
    width: "100%",
  },
};

const LabelDataSet = ({
  heading,
  value,
  type,
  headingTextColor,
  valueTextColor,
}) => {
  const getValue = (data, type) => {
    if (type == "status") {
      return (
        <StatusBadge
          name={
            data === false
              ? "NO"
              : data === true
              ? "YES"
              : data.charAt(0).toUpperCase() + data?.slice(1)
          }
          status={
            data == "info" || data == "maybe" || data === false
              ? colorStatus.red
              : data == undefined
              ? colorStatus.neutral
              : data == "yes" || data === true
              ? colorStatus.green
              : colorStatus.yellow
          }
          small
        />
      );
    } else if (typeof data == "string") {
      return value?.charAt(0).toUpperCase() + value?.slice(1);
    } else if (typeof data == "number") {
      return value;
    } else {
      let updatedString = value?.map((val, i) => {
        return (
          val?.charAt(0).toUpperCase() +
          val?.slice(1) +
          `${i < value.length - 1 ? ", " : ""}`
        );
      });
      return updatedString;
    }
  };

  return (
    <Box
      sx={styles.main}
      style={{
        width:
          heading == "ADMIN PRIVILEGES" || type == "question" ? "50%" : "33%",
      }}
      key={heading}
    >
      <Typography
        sx={{ ...styles.headingTextStyle, color: headingTextColor }}
        variant="subtitle1Med"
      >
        {heading}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          ...styles.valueTextStyle,
          color: valueTextColor,
          width: type == "status" ? "40%" : "100%",
        }}
        style={{ textAlign: type == "status" && "center" }}
      >
        {getValue(value, type)}
      </Typography>
    </Box>
  );
};

LabelDataSet.propTypes = {
  heading: PropTypes.string.isRequired,
  type: PropTypes.string,
  headingTextColor: PropTypes.string,
  valueTextColor: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

LabelDataSet.defaultProps = {
  type: undefined,
  value: "",
  headingTextColor: undefined,
  valueTextColor: undefined,
};

export default LabelDataSet;
