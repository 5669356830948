import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import {
  missionStatus,
  userRoles,
  PilotPackageStatus,
} from "../../../lib/constants";
import { getUser } from "../../../services/auth";
import SmallStatusBanner from "../../global/elements/SmallStatusBanner";
import MissionStepper from "../../global/elements/MissionStepper";
import MissionContext from "./NewMissionDetails/components/MissionContext";

const styles = {
  progress_bar_main: {
    border: "none",
    width: "100%",
  },
  statusContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "80px",
  },
};

const BannerAndStepperAdminsPilots = ({ progressStatus }) => {
  const data = useContext(MissionContext);
  const [missedMission, setMissedMission] = React.useState(false);
  const userRole = getUser("user");
  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (userRole.role === userRoles.pilot) {
      if (
        data?.mission?.assignedPilot !== userRole.userID &&
        (data?.mission?.status == missionStatus.active ||
          data?.mission?.status == missionStatus.completed)
      ) {
        if (
          data?.mission?.sentToPilots &&
          data?.mission?.sentToPilots.length &&
          data?.mission?.sentToPilots.includes(userRole.userID)
        ) {
          setMissedMission(true);
        }
      }
      if (
        data?.mission?.status === missionStatus.active &&
        data?.mission?.pilotPackageStatus != PilotPackageStatus.pendingReview
      ) {
        setActiveStep(1);
      } else if (
        data?.mission?.status === missionStatus.active &&
        data?.mission?.pilotPackageStatus == PilotPackageStatus.pendingReview
      ) {
        setActiveStep(2);
      } else if (data?.mission?.status === missionStatus.completed) {
        setActiveStep(3);
      }
    } else {
      if (
        data?.mission?.status === missionStatus.unassigned ||
        data?.mission?.status === missionStatus.confirmedMission
      ) {
        setActiveStep(1);
      } else if (
        data?.mission?.status === missionStatus.active &&
        data?.mission?.pilotPackageStatus != PilotPackageStatus.pendingReview
      ) {
        setActiveStep(2);
      } else if (
        data?.mission?.status === missionStatus.active &&
        data?.mission?.pilotPackageStatus == PilotPackageStatus.pendingReview
      ) {
        setActiveStep(3);
      } else if (data?.mission?.status === missionStatus.completed) {
        setActiveStep(4);
      }
    }
  }, [data?.mission, userRole.role, userRole.userID]);

  return (
    <Box sx={styles.progress_bar_main}>
      <MissionStepper progress={progressStatus} activeStep={activeStep} />

      {missedMission && (
        <Box sx={styles.statusContainer}>
          <SmallStatusBanner
            title="oh no... mission is currently filled."
            content=" This is either due to late application or your price range. You will
            remain on the waiting list by default, in case the currently
            assigned operator is unable to complete the mission. Next time, be quicker in showing your interest and use lower prices to compete with other operators."
          />
        </Box>
      )}
    </Box>
  );
};

BannerAndStepperAdminsPilots.propTypes = {
  value: PropTypes.number,
  progressStatus: PropTypes.array.isRequired,
  pilot: PropTypes.bool,
};
BannerAndStepperAdminsPilots.defaultProps = {
  pilot: false,
  value: undefined,
};

export default BannerAndStepperAdminsPilots;
