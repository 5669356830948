import React from "react";
import Dialogue from "../../../global/Dialogue";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { missionTypeConstant, submissionRole } from "../../../../lib/constants";
import DroneMapRequestForm from "../../../drone-map-request/form";
import { missionCreateForProject } from "../../../../services/project";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";

const styles = {
  droneMapPadding: { padding: "10px 20px" },
};
const CreateMissionForProjectModal = ({
  showDialogue,
  setShowDialogue,
  projectData,
  clearDataAndTriggerReload,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const submitMission = async (values, setSubmitting) => {
    setSubmitting(true);
    try {
      const data = await missionCreateForProject(firebase, projectData, values);
      if (data.status == "Success") {
        enqueueSnackbar("Mission created successfully.", {
          variant: "success",
        });
        setSubmitting(false);
        setShowDialogue(false);
        clearDataAndTriggerReload();
      } else {
        let errorMsg = data.message || "Error in creating mission";
        enqueueSnackbar(errorMsg, {
          variant: "error",
        });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Error in creating mission ${e}`, {
        variant: "error",
      });
    }
  };
  const globalTableBody = () => {
    return (
      <Box sx={{ ...styles.droneMapPadding }}>
        <DroneMapRequestForm
          submitMissionCall={submitMission}
          submissionType={submissionRole.pilotSubmission}
          missionType={missionTypeConstant.projectMission}
          projectMission={true}
          projectData={projectData}
        />
      </Box>
    );
  };
  return (
    <Dialogue
      showDialogue={showDialogue}
      setShowDialogue={setShowDialogue}
      DialogueHeadText={"CREATE MISSION"}
      DialogueBody={globalTableBody()}
      tableType={true}
      dividerLine={true}
      maxWidth="md"
      hideIcon={true}
    />
  );
};

CreateMissionForProjectModal.propTypes = {
  showDialogue: PropTypes.bool.isRequired,
  setShowDialogue: PropTypes.func.isRequired,
  projectData: PropTypes.object.isRequired,
  clearDataAndTriggerReload: PropTypes.func.isRequired,
};

CreateMissionForProjectModal.defaultProps = {
  valueConvertHandler: () => {},
};

export default CreateMissionForProjectModal;
