import React from "react";
import { Box } from "@mui/material";
import Tips from "../../global/elements/Tips";
import LineHeading from "../../global/elements/LineHeading";
import BankForm from "./BankForm";
import useGetBankDetails from "../../../hooks/useGetBankDetails";
import { getUser } from "src/services/auth";
import PropTypes from "prop-types";
import Layout from "../../../Layout";

const BankPage = ({ userId }) => {
  const userID = userId || getUser("userProfile").id;
  const [bankDetails, loading] = useGetBankDetails(userID);

  return (
    <Layout pageTitle={"Bank Information"} hasSideMenu addBottomPadding>
      <Box>
        <Tips
          content={`Your payment details and bank account are safely 
            stored in our database by following the GDPR procedures. Payment 
            of your invoices is dones automatically after you create an 
            invoice for GLOBHE. You don't need to fill this info unless you 
            submit an invoice to GLOBHE.`}
        />
        <br />
        <LineHeading title="Company  Details" />
        <BankForm bankDetails={bankDetails} bankId={bankDetails?.id} />
      </Box>
    </Layout>
  );
};

BankPage.propTypes = {
  userId: PropTypes.string,
};

BankPage.defaultProps = {
  userId: undefined,
};

export default BankPage;
