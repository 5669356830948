import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getCommunicationLogsList } from "../services/communication";

const useGetMessages = ({
  mission,
  talkingTo,
  currentUser,
  isProject,
  messagingTo,
}) => {
  const [items, setItems] = useStateIfMounted([]);
  const [lastLoaded, setLastLoaded] = useStateIfMounted(null);
  const [hasMore, setHasMore] = useStateIfMounted(false);
  const [reload, setReload] = useStateIfMounted(true);

  useEffect(() => {
    setItems([]);
    setLastLoaded(null);
    setHasMore(false);
    setReload(!reload);
  }, [talkingTo, messagingTo]);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    fetchData();
  }, [reload]);

  const checkMessageExistsAlready = (prevMessages, newMessages) => {
    let uniqueElements = newMessages;
    if (prevMessages?.length > 0) {
      uniqueElements = newMessages.filter(
        (item1) => !prevMessages.some((item2) => item1?.id === item2.id)
      );
    }

    return uniqueElements;
  };

  const fetchData = async () => {
    const chats = await getCommunicationLogsList(
      firebase,
      mission,
      lastLoaded,
      currentUser.role,
      talkingTo,
      isProject,
      messagingTo
    );
    if (chats?.status === "Success") {
      setItems((prevMessages) => {
        let latestMessage = checkMessageExistsAlready(
          prevMessages,
          chats.messages
        );
        return [...latestMessage, ...prevMessages];
      });
      // setItems((prevMessages) => [...chats.messages, ...prevMessages]);
      setLastLoaded(chats.lastLoaded);
      setHasMore(chats.hasMore);
    } else {
      setItems([]);
    }
  };

  return {
    items,
    setItems,
    hasMore,
    fetchData,
    setLastLoaded,
    setHasMore,
    reload,
    setReload,
    checkMessageExistsAlready,
  };
};

export default useGetMessages;
