import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LocationsPins from "../../projects/Components/LocationsPins";
import MultiOrderMissions from "./MultiOrderMissions";
import { multiLocaitonSummaryMissionCol } from "../../../../lib/constants";

const MultipleLocationMap = ({ uploadValidData }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <LocationsPins
          uploadValidData={uploadValidData}
          hint={""}
          mapHeight={"502px"}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiOrderMissions
          uploadValidData={uploadValidData}
          setUploadValidData={() => {}}
          columns={multiLocaitonSummaryMissionCol}
        />
      </Grid>
    </Grid>
  );
};

MultipleLocationMap.propTypes = {
  uploadValidData: PropTypes.array,
};

MultipleLocationMap.defaultProps = {
  uploadValidData: [],
};

export default MultipleLocationMap;
