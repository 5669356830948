import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../../Layout";
import ArrowHeaderContainer from "../../NewDataLibrary/components/ArrowHeaderContainer";
import AppContext from "../../../../contexts/AppContext";
import PropTypes from "prop-types";
import EnterpriseTabLayout from "../Components/EnterpriseTabLayout";
import { fetchEnterPrise } from "../../../../services/enterprise";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import { Box } from "@mui/material";
const styles = {
  mTop: {
    paddingTop: "15%",
    minHeight: "60vh",
  },
};

const AdminEnterpriseLayout = ({ enterpriseId }) => {
  const [enterprise, setEnterprise] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    fetchEnterPrise(enterpriseId)
      .then((data) => {
        setEnterprise(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error", err.message);
        setLoading(false);
      });
  }, [enterpriseId]);

  const appContext = useContext(AppContext);
  return (
    <Layout hasSideMenu loading={loading}>
      <ArrowHeaderContainer
        header={enterprise?.name || "Team"}
        redirectURL={"/app/admin/client-enterprise"}
        showBottomDivider={true}
      >
        {!loading ? (
          <EnterpriseTabLayout
            enterpriseData={enterprise}
            userData={appContext?.userData}
          />
        ) : (
          <Box sx={styles.mTop}>
            <UniversalLoader />
          </Box>
        )}
      </ArrowHeaderContainer>
    </Layout>
  );
};

AdminEnterpriseLayout.propTypes = {
  enterpriseId: PropTypes.string.isRequired,
};

export default AdminEnterpriseLayout;
