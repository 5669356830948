import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const styles = {
  clientDetails: {
    display: "block",
    marginBottom: "20px",
  },
  title: {
    color: theme.palette.grey.white,
  },
  titleBlackBack: {
    color: theme.palette.primary.main,
  },
  value: {
    color: theme.palette.grey.white,
  },
};

const TitleValue = ({ title, value, blackBackground, defaultText }) => {
  return (
    <Box sx={styles.clientDetails}>
      <Typography
        variant="h5"
        sx={
          blackBackground
            ? styles.titleBlackBack
            : !defaultText
            ? styles.title
            : ""
        }
      >
        {title}
      </Typography>
      <Typography variant="body1" sx={!defaultText ? styles.value : ""}>
        {value}
      </Typography>
    </Box>
  );
};

TitleValue.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
  blackBackground: PropTypes.bool,
  defaultText: PropTypes.bool,
};

TitleValue.defaultProps = {
  value: "",
  blackBackground: false,
  defaultText: false,
};

export default TitleValue;
