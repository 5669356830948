import React from "react";
import { Paper, Typography, Grid } from "@mui/material";
import theme from "../../../theme";
import TitleAndDivider from "./TypographyElements/TitleAndDivider";
import PropTypes from "prop-types";

const styles = {
  padding: "30px",
  backgroundColor: theme.palette.status.errorLight,
  marginTop: "30px",
};

const DangerZone = ({ children }) => {
  return (
    <Paper sx={styles} variant="outlined">
      <TitleAndDivider title="Danger zone" marginTop={0} />
      <Grid container mt={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body">
            Once you delete your profile, there is no going back. Please be
            certain. In case you have placed orders, you will lose all of them
            and will not have access to the data that your purchased.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

DangerZone.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DangerZone;
