import { Box, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useEffect, useState } from "react";
import { FilterFieldsPilotMissions } from "../../../../lib/constants";
import AutoCompleteFilter from "../../../global/elements/AutoCompleteFilter";

const styles = {
  filterFieldsCss: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill, 240px)",
    margin: "10px 0 10px 10px",
  },
};
const FilterMissions = ({ valueChangeHandler }) => {
  const [filterField, setFilterField] = useState([]);
  const [changedFilterMonitor, setchangedFilterMonitor] = useState(false);
  const [filterData, setFilterData] = useState({
    "Order Id": "",
  });
  const [filterOptions, setFilterOptions] = useState([]);
  useEffect(() => {
    let options = getFilterOption();
    setFilterOptions(options);
  }, []);
  useEffect(() => {
    let filterFieldData = localStorage.getItem("filterField");
    if (filterFieldData) {
      let getAllFilters = filterFieldData?.split(",");
      if (getAllFilters?.length > 0) {
        let pageFilterOptions = getFilterOption();
        let applyFilterOn = pageFilterOptions.filter((opt) =>
          getAllFilters.includes(opt)
        );
        setFilterField(applyFilterOn || []);
        setFilterData(JSON.parse(localStorage.getItem("filter")));
        valueChangeHandler("InitialLoad", "InitialLoad", applyFilterOn);
      }
    }
  }, [changedFilterMonitor]);
  const getFilterOption = () => {
    return FilterFieldsPilotMissions;
  };
  const onChangeFilterField = (e, newValue) => {
    setFilterField(newValue);
    setchangedFilterMonitor(!changedFilterMonitor);
    if (newValue?.length > 0) {
      localStorage.setItem("filterField", newValue);
    } else {
      localStorage.setItem("filterField", []);
      filterData["Order Id"] = "";
    }
    valueChangeHandler("", "", newValue);
  };

  return (
    <Box sx={styles.filterContainer}>
      <AutoCompleteFilter
        filterOptions={filterOptions}
        filterField={filterField}
        onChangeFilterField={onChangeFilterField}
        helperText="Select the fields to filter your submissions with"
      />
      <Box sx={styles.filterFieldsCss}>
        {filterField?.includes("Order Id") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Order Id"]}
              onChange={(e) => {
                let newData = filterData;
                newData["Order Id"] = e.target.value;
                setFilterData(newData);
                valueChangeHandler(e.target.value, "Order Id", filterField);
              }}
              label="Order Id"
              sx={styles.field}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
FilterMissions.propTypes = {
  valueChangeHandler: PropTypes.func.isRequired,
};
export default FilterMissions;
