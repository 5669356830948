import React, { useCallback, useState, useEffect } from "react";
import { Box, TextField, InputAdornment, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  userRoles,
  missionStatus,
  quoteStatus,
  colorStatus,
  columnForQuotesClientNew,
  columnForQuotesAdminNew,
} from "../../../lib/constants";
import { getUser } from "../../../services/auth";
import AppButton from "./AppButton";
import useGetQuotesList from "../../../hooks/useGetQuotesList";
import PropTypes from "prop-types";
import QuotePopup from "../../app/Clients/quotePopup";
import { notificationType } from "../../../lib/constants";
import { createNotification } from "../../../services/notification";
import { updateQuote } from "../../../services/missions";
import firebase from "src/firebase";
import OrderQuotesTable from "../../app/admin/OrderQuotesTable";
import debounce from "lodash.debounce";
import theme from "../../../theme";
import { textFieldStyle } from "../../../lib/styleConstants";
import StatusBadge from "./StatusBadge";
import Dialogue from "../Dialogue";
import { getQuoteTotalCost, setQuoteModifyBy } from "../../../lib/helpers";
import CancelAcceptedQuote from "../../app/quotes/CancelAcceptedQuote";
import RejectQuotePopup from "../../app/quotes/RejectQuotePopup";

const styles = {
  outerDivBackground: {
    backgroundColor: theme.palette.grey.white,
    padding: {
      xs: "10px 5px",
      sm: "20px  5px 20px 0",
      md: "20px 0",
    },
  },
  searchBoxOuterDiv: { display: "flex", width: "100%" },
  btnDisplay: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    rowGap: "1em",
    columnGap: "1em",
  },
  tableCellBorder: {
    borderBottom: { xs: "none", sm: "inline" },
  },
  popupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
};

const SingleOrderQuotesTable = ({
  missionData,
  setLoadingMission,
  loadingMission,
  setRefreshMission,
  refreshMission,
  setShowNoQuoteDialogue,
  setTotalQuotesCount,
}) => {
  const currentUser = getUser("userProfile");
  let user = getUser("user");
  let role = user.role;
  const [popupOpen, setPopupOpen] = useState(false);
  const [clickedQuoteId, setClickedQuoteId] = useState(null);
  const [quoteToReject, setQuoteToReject] = useState(null);
  const [rejectQuotePopup, setRejectQuotePopup] = useState(false);
  const [deleteQuotePoup, setDeleteQuotePopup] = useState(false);
  const [cancelQuotePopup, setCancelQuotePopup] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [acceptedQuote, setAcceptedQuote] = useState(null);
  const [
    allQuotes,
    loading,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    lastPage,
    setQuotesTotal,
    setLastLoadedPage,
    setLastLoadedQuotes,
    triggerReload,
    setFilter,
    setTriggerReload,
    setLoading,
  ] = useGetQuotesList(missionData.id, role, setTotalQuotesCount);

  useEffect(() => {
    if (allQuotes?.length > 0) {
      let quoteData = allQuotes.filter(
        (quote) => quote.status == quoteStatus.accepted
      );
      if (quoteData?.length > 0) {
        setAcceptedQuote(quoteData[0]);
      }
      setShowNoQuoteDialogue(false);
    } else {
      setShowNoQuoteDialogue(true);
    }
  }, [allQuotes]);

  const refreshTable = () => {
    setLoading(!loading);
    setQuotesTotal([]);
    setLastLoadedPage(0);
    setLastLoadedQuotes(null);
    setTriggerReload(!triggerReload);
  };
  // useEffect(() => {
  //   if (
  //     missionData.status != missionStatus.initialClientOrder &&
  //     !missionData.subscriptionId
  //   ) {
  //     getAcceptedQuote(firebase, missionData.id)
  //       .then()
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, []);

  const handlePopupOpen = (value) => {
    setClickedQuoteId(value.id);
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleDeletePopupOpen = (value) => {
    setClickedQuoteId(value.id);
    setDeleteQuotePopup(true);
  };

  const handleCancelPopupOpen = (value) => {
    setClickedQuoteId(value.id);
    setCancelQuotePopup(true);
  };

  const handleDeleteQuoteHandler = () => {
    setSubmitLoading(true);

    let cancelBy = setQuoteModifyBy(currentUser);
    const statusObj = {
      status: quoteStatus.deleted,
      cancelBy,
      deleted: true,
    };
    updateQuote(firebase, missionData.id, clickedQuoteId, statusObj)
      .then(() => {
        setSubmitLoading(false);
        setDeleteQuotePopup(false);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        refreshMission();
      });
  };

  const handleCancelAcceptedQuote = async () => {
    setSubmitLoading(true);

    let cancelBy = setQuoteModifyBy(currentUser);
    const statusObj = {
      status: quoteStatus.cancelled,
      cancelBy,
    };
    updateQuote(firebase, missionData.id, clickedQuoteId, statusObj)
      .then(async () => {
        setSubmitLoading(false);
        setCancelQuotePopup(false);
        if (missionData?.enterPriseId) {
          await createNotification(
            firebase,
            missionData.enterPriseId,
            "Quote is canceled by admin.",
            `We have cancelled your current quote based on your current update`,
            `/app/client/orderDetails/${missionData.id}`
          );
        } else if (missionData?.clientId) {
          await createNotification(
            firebase,
            missionData.clientId,
            "Quote is canceled by admin.",
            `We have cancelled your current quote based on your current update`,
            `/app/client/orderDetails/${missionData.id}`
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        refreshMission();
      });
  };

  const handleCancelQuoteHandler = async (value) => {
    await createNotification(
      firebase,
      notificationType.adminNotification,
      "A quote is declined by client.",
      "A quote is declined by client.",
      `/app/missions/${missionData.id}`
    );
    if (missionData?.enterPriseId) {
      await createNotification(
        firebase,
        missionData.enterPriseId,
        "Quote is declined by a team member.",
        "Quote is declined by a team member.",
        `/app/client/orderDetails/${missionData.id}`
      );
    }

    setLoadingMission(true);

    const statusObj = {
      status: quoteStatus.declined,
      clientId: currentUser.id,
      acceptedOrRecjectedByClient: currentUser.id,
      rejectReason: value.rejectReason,
    };

    let dataForMail = {
      clientName: currentUser?.name,
      clientCompany: currentUser?.companyName,
      country: currentUser?.location,
      email: currentUser?.email,
      price: getQuoteTotalCost(quoteToReject),
      url: `/app/missions/${missionData.id}`,
    };

    updateQuote(
      firebase,
      missionData.id,
      quoteToReject.id,
      statusObj,
      dataForMail
    )
      .then(() => {
        console.log("Quote Rejected");
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setQuoteToReject(null);
        setLoadingMission(false);
        setRefreshMission(true);
      });
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "action":
        return (
          <>
            {value["status"] === quoteStatus.quoteSent &&
            !acceptedQuote &&
            missionData.status !== missionStatus.completed ? (
              <Box sx={styles.btnDisplay}>
                <AppButton
                  look={"green"}
                  onClick={() => handlePopupOpen(value)}
                  label="Accept"
                  // isDisabled={
                  //   missionData.status === missionStatus.initialClientOrder
                  //     ? false
                  //     : true
                  // }
                />
                <AppButton
                  look={"negative"}
                  onClick={() => {
                    setQuoteToReject(value);
                    setRejectQuotePopup(true);
                  }}
                  // onClick={() => handleCancelQuoteHandler(value)}
                  // isDisabled={
                  //   missionData.status === missionStatus.initialClientOrder
                  //     ? false
                  //     : true
                  // }
                  label="Decline"
                />
              </Box>
            ) : (
              <StatusBadge
                name={
                  value.status == quoteStatus.quoteSent &&
                  role === userRoles.client
                    ? "Declined"
                    : value.status
                }
                status={
                  value.status == quoteStatus.accepted
                    ? colorStatus.green
                    : value.status == quoteStatus.expired
                    ? colorStatus.yellow
                    : value.status == quoteStatus.declined ||
                      value.status == quoteStatus.deleted ||
                      value.status == quoteStatus.cancelled
                    ? colorStatus.red
                    : role === userRoles.client &&
                      value.status == quoteStatus.quoteSent
                    ? colorStatus.red
                    : colorStatus.neutral
                }
              />
            )}
          </>
        );
      case "deleteQuote":
        return (
          <>
            {value["status"] === quoteStatus.quoteSent ? (
              <Box sx={styles.btnDisplay}>
                <AppButton
                  // look={
                  //   missionData.status === missionStatus.initialClientOrder
                  //     ? "negative"
                  //     : "invertedDisabled"
                  // }
                  // isDisabled={
                  //   missionData.status === missionStatus.initialClientOrder
                  //     ? false
                  //     : true
                  // }
                  look="negative"
                  onClick={() => handleDeletePopupOpen(value)}
                  label="Delete"
                />
              </Box>
            ) : (
              <></>
            )}
          </>
        );

      case "cancelQuote":
        return (
          <>
            {value["status"] === quoteStatus.accepted &&
            missionData?.status != missionStatus.completed ? (
              <Box sx={styles.btnDisplay}>
                <AppButton
                  look={"negative"}
                  onClick={() => handleCancelPopupOpen(value)}
                  label="Cancel"
                />
              </Box>
            ) : (
              <></>
            )}
          </>
        );
      default:
        return;
    }
  };

  const DialogueBody = () => {
    return (
      <Box sx={styles.popupCls}>
        <Typography variant="h6">Delete Quote</Typography>
        <Typography variant="subtitle1">
          Once submitted, the quote will no longer available to client.
        </Typography>
      </Box>
    );
  };

  const goToPageFcn = () => {
    return;
  };

  const limitHandler = (value) => {
    refreshTable();
    setLimit(value);
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = currentPage;
    if (type == "next") {
      if (!(lastPage === currentPage)) {
        pageNumberNew = pageNumberNew + 1;
        setTriggerReload(!triggerReload);
      }
    } else {
      pageNumberNew = pageNumberNew - 1;
      setTriggerReload(!triggerReload);
    }
    setCurrentPage(pageNumberNew);
  };
  const valueChangeHandler = (e) => {
    let filter = {
      quoteName: e?.target?.value,
    };
    setInputSearch(e?.target?.value);
    setFilter(filter);
    refreshTable();
  };
  const debouncedChangeHandler = useCallback(
    debounce(valueChangeHandler, 500),
    []
  );
  return (
    <>
      {(allQuotes && allQuotes.length) || inputSearch ? (
        <Box sx={styles.outerDivBackground}>
          <Box sx={styles.searchBoxOuterDiv}>
            <TextField
              id="search"
              margin="normal"
              sx={{ ...textFieldStyle, width: "100%" }}
              placeholder={"Enter quote name to filter"}
              onChange={(e) => {
                e.persist();
                debouncedChangeHandler(e);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <OrderQuotesTable
            allData={allQuotes}
            columns={
              currentUser.role === userRoles.admin
                ? columnForQuotesAdminNew
                : columnForQuotesClientNew
            }
            valueConvertHandler={valueConvertHandler}
            collapseFlag={true}
            goToPageFcn={goToPageFcn}
            limitHandler={limitHandler}
            limit={limit}
            paginationBtnClick={paginationBtnClick}
            currentPage={currentPage}
            isLastPage={isLastPage}
            lastPage={lastPage}
            role={user?.role}
          />

          {deleteQuotePoup && (
            <Dialogue
              showDialogue={deleteQuotePoup}
              setShowDialogue={setDeleteQuotePopup}
              DialogueHeadText={""}
              DialogueBody={DialogueBody()}
              showCancelButton={true}
              cancelButtonText={"Cancel"}
              sumitButtonText={"Yes"}
              showSubmitLoader={submitLoading}
              setShowSubmitLoader={setSubmitLoading}
              onSubmit={() => handleDeleteQuoteHandler()}
            />
          )}

          {cancelQuotePopup && (
            <CancelAcceptedQuote
              showDialogue={cancelQuotePopup}
              setShowDialogue={setCancelQuotePopup}
              onSubmit={handleCancelAcceptedQuote}
              loading={submitLoading}
            />
          )}

          <RejectQuotePopup
            showRejectDialogue={rejectQuotePopup}
            setShowRejectDialogue={setRejectQuotePopup}
            onSubmit={handleCancelQuoteHandler}
            loading={submitLoading}
          />

          <QuotePopup
            popupOpen={popupOpen}
            handlePopupOpen={handlePopupOpen}
            handlePopupClose={handlePopupClose}
            // missionID={missionData.id}
            mission={missionData}
            setLoadingMission={setLoadingMission}
            loadingMission={loadingMission}
            setRefreshMission={setRefreshMission}
            clickedQuoteId={clickedQuoteId}
            // clientId={currentUser.userID}
            client={currentUser}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

SingleOrderQuotesTable.propTypes = {
  missionData: PropTypes.object.isRequired,
  setLoadingMission: PropTypes.func,
  loadingMission: PropTypes.bool,
  setRefreshMission: PropTypes.func,
  refreshMission: PropTypes.func,
  setShowNoQuoteDialogue: PropTypes.func.isRequired,
  setTotalQuotesCount: PropTypes.func.isRequired,
};

SingleOrderQuotesTable.defaultProps = {
  setLoadingMission: undefined,
  loadingMission: false,
  setRefreshMission: undefined,
  refreshMission: undefined,
};

export default SingleOrderQuotesTable;
