import React from "react";
import Layout from "../../../Layout";
import OrderMainContainer from "./OrderMainContainer";
import { orderLocationType, formTypeConstant } from "../../../lib/constants";

const EditMultiLocationOrder = () => {
  return (
    <Layout>
      <OrderMainContainer
        formType={formTypeConstant.edit}
        orderType={orderLocationType.multiLocationOrder}
      />
    </Layout>
  );
};

export default EditMultiLocationOrder;
