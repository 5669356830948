import React, { useEffect, useState } from "react";
import firebase from "src/firebase";
import ErrorMessage from "src/components/global/errorMessage";
import GridColumn from "src/components/global/GridColumn";
import GridContainer from "src/components/global/GridContainer";
import { getUser } from "src/services/auth";
import { getAdminEmails, updateUserRole } from "src/services/handle-users";
import AddAdminForm from "./AddAdminForm";
import AdminAccountList from "./AdminAccountList";
import { Typography } from "@mui/material";
import theme from "../../../theme";
import Layout from "../../../Layout";
import { Box } from "@mui/material";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { checkAdmintype } from "../../../services/auth";
import { admintype } from "../../../lib/constants";
import { removeFromAdmin } from "../../../services/handle-users";
import Tips from "../../global/elements/Tips";

const style = {
  "@global body": {
    background: theme.palette.grey.lightWarmGrey,
  },
  adminTitle: { paddingBottom: "10px" },
  content: {
    width: "100%",
    margin: "0px auto",
    display: "flex",
    flexFlow: "row wrap",
    maxWidth: "100%",

    "& h1": {
      textAlign: "center",
      marginBottom: "8rem",
    },
  },
  addAdmin: {
    padding: "20px",
    width: "100%",
  },
  addAdminTitle: {
    marginBottom: "10px",
    fontWeight: 500,
    fontSize: "14px",
  },
  administrators: {
    padding: { xs: "1rem", lg: "2rem" },
    background: theme.palette.grey.white,
    marginTop: { xs: "1rem", lg: "2rem" },
    width: "100%",
  },
  adminAccountList: {
    marginBottom: "2rem",
  },
  loaderDiv: {
    paddingTop: "18%",
  },
};

const AdminStart = () => {
  const [hasError, showErrorMessage] = useState(false);
  const [errorMessageLabel, setErrorMessageLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [adminEmails, setAdminEmails] = useState([]);
  const [adminEmailsLoading, setAdminEmailsLoading] = useState(true);
  const [refreshAdminList, setRefreshAdminList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [currentUserAdminType, setCurrentUserAdmintype] =
    useState("Regular Admin");

  useEffect(() => {
    if (!firebase) return;
    setLoading(true);

    getAdminEmails(firebase)
      .then((emails) => {
        setAdminEmails(emails);
        setRefreshAdminList(false);
        setLoading(false);
      })

      .finally(() => {
        setAdminEmailsLoading(false);
        setLoading(false);
      });
  }, [refreshAdminList]);
  useEffect(() => {
    checkAdmintype(firebase).then((snapshot) => {
      if (snapshot?.adminType === admintype.superAdmin) {
        setAuthorized(true);
      }
      setCurrentUserAdmintype(snapshot?.adminType);
    });
  }, []);

  const addAdminSubmitHandler = (values) => {
    updateUserRole(firebase, values, "admin")
      .then(() => {
        setRefreshAdminList(true);
      })
      .catch(() => {
        showErrorMessage(true);
        setErrorMessageLabel("That email does not exist");
        setErrorMessage(
          "Make sure the user that you want to add has signed up as a drone operator"
        );
      });
  };

  const removeAdmin = (email) => {
    removeFromAdmin(firebase, email, "pilot")
      .then(() => {
        setRefreshAdminList(true);
      })
      .catch(() => {
        showErrorMessage(true);
        setErrorMessageLabel("Something went wrong");
      });
  };

  const closeTriggered = () => {
    setTimeout(function () {
      showErrorMessage(false);
    }, 300);
  };

  const getCurrentUserEmail = () => {
    return getUser("user").email;
  };

  return (
    <Layout pageTitle={"Admins"} hasSideMenu loading={loading}>
      <Tips content="Here you can see a list of all admins of Crowddroning. Admins have different access rights and the base is a regular admin. A regular admin can manage missions and see contracts only. Data admins can, in addition to regular admins rights, see pilots' details and have access to the stored data. Financial admins can work with invoices, pay invoices, and see the bank information of pilots. Super admins can do all of the above and assign different admin types." />
      {!loading ? (
        <GridContainer styles={style.content}>
          {authorized && (
            <GridColumn widthLarge="100%">
              <Box sx={style.addAdmin}>
                <Typography variant="h5" style={style.addAdminTitle}>
                  Add/Update Admin
                </Typography>
                <AddAdminForm submitHandler={addAdminSubmitHandler} />
              </Box>
            </GridColumn>
          )}

          <GridColumn widthLarge="100%">
            <Box sx={style.administrators}>
              <Typography variant="h6" style={style.adminTitle}>
                List of Crowddroning Admins
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "50px" }}>
                *Contact a super admin if you require additional access rights
              </Typography>
              <AdminAccountList
                styles={style.adminAccountList}
                adminEmails={adminEmails}
                removeAdminHandler={removeAdmin}
                loading={adminEmailsLoading}
                currentUserEmail={getCurrentUserEmail()}
                authorized={authorized}
                currentUserAdminType={currentUserAdminType}
              />
              <br />
            </Box>
          </GridColumn>
        </GridContainer>
      ) : (
        <Box sx={style.loaderDiv}>
          <UniversalLoader />
        </Box>
      )}
      {hasError ? (
        <ErrorMessage
          show={true}
          errorMessageLabel={errorMessageLabel}
          errorMessage={errorMessage}
          closeTriggered={closeTriggered}
        />
      ) : null}
    </Layout>
  );
};

export default AdminStart;
