import { userRoles } from "../lib/constants";
import { getCurrentDateTime, getCurrentDateTimeMillis } from "../lib/helpers";
import firebase from "src/firebase";

export const createCommunicationLogs = async (
  firebase,
  mission,
  data,
  isProject
) => {
  const db = firebase.firestore();
  const currentTime = getCurrentDateTime();
  let dataToUpload = data;
  const createCommunicationLogRef = isProject
    ? db
        .collection("projects")
        .doc(mission.id)
        .collection("communicationLogs")
        .doc()
    : db
        .collection("missions")
        .doc(mission.id)
        .collection("communicationLogs")
        .doc();
  dataToUpload.createdAt = getCurrentDateTimeMillis();
  dataToUpload.updatedAt = currentTime;
  dataToUpload.id = createCommunicationLogRef.id;
  dataToUpload.missionId = mission.id;
  dataToUpload.deleted = false;
  dataToUpload.missionName = isProject
    ? mission.projectName
    : mission.missionName;

  return createCommunicationLogRef
    .set(dataToUpload)
    .then(async () => {
      return {
        status: "Success",
        data: { id: createCommunicationLogRef.id, ...dataToUpload },
        message: "Log created successfully!",
      };
    })
    .catch((err) => {
      console.log("Error", err);
      return {
        status: "Error",
        message: "Error in creating Logs.",
      };
    });
};

export const getCommunicationLogsList = async (
  firebase,
  mission,
  lastLoaded,
  currentUserRole,
  chattingWith,
  isProject,
  messagingTo
) => {
  try {
    if (
      !currentUserRole ||
      !chattingWith ||
      ((currentUserRole === userRoles.pilot ||
        chattingWith === userRoles.pilot) &&
        !mission.assignedPilot)
    )
      return {
        status: "Success",
        messages: [],
        hasMore: false,
        lastLoaded: null,
      };

    let query;

    if (isProject) {
      query = firebase
        .firestore()
        .collection("projects")
        .doc(mission.id)
        .collection("communicationLogs")
        .where("seen", "==", true);
    } else {
      query = firebase
        .firestore()
        .collection("missions")
        .doc(mission.id)
        .collection("communicationLogs");
      // .where("seen", "==", true);
    }

    if (chattingWith !== userRoles.all) {
      query = query.where("seen", "==", true);
    }

    if (isProject) {
      if (
        chattingWith === userRoles.client ||
        currentUserRole === userRoles.client
      ) {
        query = query.where("clientId", "==", mission.clientId);
      } else if (
        (chattingWith === userRoles.pilot &&
          mission?.associatedDroneOperatorsIds?.length > 0) ||
        (currentUserRole === userRoles.pilot &&
          mission?.associatedDroneOperatorsIds?.length > 0)
      ) {
        query = query.where("pilotId", "==", messagingTo);
      }
    } else {
      // (currentUserRole === userRoles.pilot &&
      //   chattingWith === userRoles.client) ||
      // (currentUserRole === userRoles.client &&
      //   chattingWith === userRoles.pilot)
      if (chattingWith === userRoles.all) {
        query = query.where("viewer", "==", userRoles.all);
        if (currentUserRole === userRoles.pilot) {
          query = query.where("seenByPilot", "==", true);
        } else if (currentUserRole === userRoles.admin) {
          query = query.where("seenByAdmin", "==", true);
        } else if (currentUserRole === userRoles.client) {
          query = query.where("seenByClient", "==", true);
        }
      } else if (
        chattingWith === userRoles.client ||
        currentUserRole === userRoles.client
      ) {
        query = query
          .where("clientId", "==", mission.clientId)
          .where("viewer", "in", [userRoles.admin, userRoles.client]);
      } else if (
        (chattingWith === userRoles.pilot && mission.assignedPilot) ||
        (currentUserRole === userRoles.pilot && mission.assignedPilot)
      ) {
        query = query
          .where("pilotId", "==", mission.assignedPilot)
          .where("viewer", "in", [userRoles.admin, userRoles.pilot]);
      }
    }

    query = query.orderBy("createdAt", "desc");
    if (lastLoaded) {
      query = query.startAfter(lastLoaded);
    }

    return query
      .limit(21)
      .get()
      .then((snapshot) => {
        const logs = snapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          acc.push({ ...data, id: doc.id });
          return acc;
        }, []);
        return {
          status: "Success",
          messages: logs.slice(0, 20).reverse(),
          hasMore: snapshot.size > 20,
          lastLoaded:
            snapshot.size > 0 ? snapshot.docs[snapshot.docs.length - 2] : null,
        };
      })
      .catch((err) => {
        console.log("error", err);
      });
  } catch (err) {
    return {
      status: "Error",
      messages: [],
      hasMore: false,
      lastLoaded: null,
    };
  }
};

export const markAllMessageAsRead = (firebase, mission, user, currentUser) => {
  console.log("here");
  try {
    const currentTime = getCurrentDateTime();
    let query = firebase
      .firestore()
      .collection("missions")
      .doc(mission.id)
      .collection("communicationLogs");

    if (user === userRoles.client) {
      query = query.where("clientId", "==", mission.clientId);
    } else if (user === userRoles.pilot && mission.assignedPilot) {
      query = query.where("pilotId", "==", mission.assignedPilot);
    }

    query
      .where("seen", "==", false)
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach(async (doc) => {
          if (
            doc.data().sender !== currentUser ||
            (doc.data().viewer === userRoles.all &&
              ((doc.data().seenByPilot && doc.data().seenByClient) ||
                (doc.data().seenByPilot && doc.data().seenByAdmin) ||
                (doc.data().seenByClient && doc.data().seenByAdmin)))
          ) {
            if (
              !(
                doc.data().viewer === userRoles.all &&
                currentUser === userRoles.pilot &&
                doc.data().seenByPilot
              ) &&
              !(
                doc.data().viewer === userRoles.all &&
                currentUser === userRoles.client &&
                doc.data().seenByClient
              ) &&
              !(
                doc.data().viewer === userRoles.all &&
                currentUser === userRoles.admin &&
                doc.data().seenByAdmin
              )
            ) {
              let objectToUpdate = { seen: false, updatedAt: currentTime };
              if (
                doc.data().viewer === userRoles.all &&
                user === userRoles.all
              ) {
                if (currentUser === userRoles.admin) {
                  objectToUpdate.seenByAdmin = true;
                  if (doc.data().seenByClient || doc.data().seenByPilot) {
                    objectToUpdate.seen = true;
                    objectToUpdate.seenByPilot = true;
                    objectToUpdate.seenByClient = true;
                  }
                } else if (currentUser === userRoles.client) {
                  objectToUpdate.seenByClient = true;
                  if (doc.data().seenByPilot || doc.data().seenByAdmin) {
                    objectToUpdate.seen = true;
                    objectToUpdate.seenByAdmin = true;
                    objectToUpdate.seenByPilot = true;
                  }
                } else if (currentUser === userRoles.pilot) {
                  objectToUpdate.seenByPilot = true;
                  if (doc.data().seenByClient || doc.data().seenByAdmin) {
                    objectToUpdate.seen = true;
                    objectToUpdate.seenByAdmin = true;
                    objectToUpdate.seenByClient = true;
                  }
                }
                await firebase
                  .firestore()
                  .collection("missions")
                  .doc(mission.id)
                  .collection("communicationLogs")
                  .doc(doc.id)
                  .update(objectToUpdate);
              } else if (
                doc.data().viewer !== userRoles.all &&
                user !== userRoles.all
              ) {
                objectToUpdate.seen = true;
                await firebase
                  .firestore()
                  .collection("missions")
                  .doc(mission.id)
                  .collection("communicationLogs")
                  .doc(doc.id)
                  .update(objectToUpdate);
              }
            }
          }
        });
      })
      .catch((err) => {
        console.log("Error", err);
      });

    return;
  } catch (err) {
    console.log("Error", err);
    return;
  }
};

export const getChatListForMultiLocationOrder = async (
  clientId,
  associatedDroneOperatorsIds
) => {
  // if (!clientId) return [];
  const usersToChat = [];
  if (clientId) {
    const getClientInfo = await firebase
      .firestore()
      .collection("clients")
      .doc(clientId)
      .get();

    usersToChat.push({
      name: `Client - ${getClientInfo.data()?.name}`,
      id: getClientInfo.id,
      role: userRoles.client,
      profilePicture: getClientInfo.data()?.profileImageURL || "",
    });
  } else {
    usersToChat.push({
      name: `Client - (Not in platform)`,
      id: null,
      role: userRoles.client,
      profilePicture: "",
    });
  }

  if (associatedDroneOperatorsIds) {
    const getDroneOperatorsInfo = await firebase
      .firestore()
      .collection("pilots")
      .where("__name__", "in", associatedDroneOperatorsIds)
      .get();

    getDroneOperatorsInfo.docs.forEach((dp, i) => {
      usersToChat.push({
        name: `DO${i + 1} - ${dp.data()?.name}`,
        id: dp.id,
        role: dp.id,
        profilePicture: dp.data()?.profileImageURL || "",
      });
    });
  }

  return usersToChat;
};

export const deleteCommunicationLogs = async (missionId, messageId) => {
  const objectToUpdate = {
    deleted: true,
  };
  return await firebase
    .firestore()
    .collection("missions")
    .doc(missionId)
    .collection("communicationLogs")
    .doc(messageId)
    .update(objectToUpdate)
    .then(() => {
      return {
        status: "Success",
        message: "Message deleted successfully!",
      };
    })
    .catch((err) => {
      return {
        status: "Error",
        message: err?.message || "error in deleting the messages!",
      };
    });
};
