export const getPilotStatistics = (firebase) => {
  return firebase
    .firestore()
    .collection("statistics")
    .doc("pilotStats")
    .get()
    .then((snapshot) => {
      const data = snapshot.data();
      const stat = mapFirebaseData(data);
      return stat;
    });
};

//---------to check-----------
//this is not entirely working. To check how to get array or map data
//from firebase without having continuous refresh of the page
//Currently working for just getting the simply numbers but not arrays

const mapFirebaseData = (data) => {
  const pilotsByCountryISO = !data.pilotsByCountryISO2
    ? []
    : Array.isArray(data.pilotsByCountryISO2)
    ? data.pilotsByCountryISO2
    : data.pilotsByCountryISO2.split(",");
  const stat = {
    ...data,
    pilotsByCountryISO,
  };
  return stat;
};
