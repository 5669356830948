import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getNews, getInspirations } from "../services/news-inspiration";
import { missionCountsUpdate } from "../services/dashboard";

const useGetNews = () => {
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(100);
  const [inspiration, setInspiration] = useState(null);
  const [pilotData, setPilotData] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    getNews(limit)
      .then((data) => {
        setNews(data?.news);
      })
      .catch((err) => {
        console.log(err);
      });

    missionCountsUpdate()
      .then((data) => {
        setPilotData(data?.pilotData);
      })
      .catch((err) => {
        console.log(err);
      });
    getInspirations(limit)
      .then((data) => {
        setInspiration(data?.inspirations);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reload]);

  return [news, inspiration, pilotData, loading, reload, setReload];
};

export default useGetNews;
