import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import SingleMultipleCheckbox from "../../../global/FormInputComponents/SingleMultipleCheckbox";
import AppButton from "../../../global/elements/AppButton";

const reasonType = {
  platFormSupport: "Platform Support",
  taskingDrone: "Tasking drone and placing order",
  pricing: "Pricing",
  privacy: "Privacy and data protection",
  droneOperations: "Drone operations",
  termsAndCond: "Terms and conditions",
  other: "Other",
};

const ContactSupportForm = ({ onSubmit }) => {
  const initialValues = {
    description: "",
  };

  const reasons = Object.keys(reasonType).map((type) => {
    initialValues[type] = false;
    return { label: reasonType[type], name: type };
  });

  const validationSchema = Yup.object()
    .shape({
      description: Yup.string().required("Description is required"),
    })
    .test("checkSelectors", null, (obj) => {
      let errorMessage = "";

      const checkAnyReasonSelect = () => {
        for (const field of Object.keys(reasonType)) {
          if (obj[field]) {
            return true;
          }
        }
        return false;
      };
      if (!checkAnyReasonSelect()) {
        errorMessage = "Please select atleast one reason";
      } else {
        errorMessage = "";
        return;
      }
      return new Yup.ValidationError(errorMessage, null, "selectReasonError");
    });

  const formId = "support";

  const getSelectedReasons = (values) => {
    const reasonsArray = reasons
      .filter((reason) => values[reason.name] == true)
      .map((reason) => reason.label);
    return reasonsArray;
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          return new Promise(() => {
            const reasons = getSelectedReasons(values);
            onSubmit({ ...values, reasons }, setSubmitting);
          });
        }}
      >
        {({ values, setFieldValue, errors, isSubmitting }) => {
          return (
            <Form id={formId}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SingleMultipleCheckbox
                    checkboxFields={reasons}
                    values={values}
                    multiple={true}
                    setFieldValue={setFieldValue}
                    vertical={true}
                    errorFieldName="selectReasonError"
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    By describing your problem in the field below, we can better
                    understand your needs and assist you effectively
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextFieldInput
                    setFieldValue={setFieldValue}
                    values={values.description}
                    label={"Detailed description"}
                    name={"description"}
                    type="text"
                    rows={3}
                    multiline={true}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  spacing={2}
                  container
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <AppButton
                      label="Cancel"
                      look="inverted"
                      url="/app/client/my-account"
                      internalLink
                      noIcon
                      small
                    />
                  </Grid>

                  <Grid item>
                    <AppButton
                      label="Send request"
                      look="green"
                      noIcon
                      small
                      type="submit"
                      form={formId}
                      submittingState={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

ContactSupportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ContactSupportForm;
