import { useState, useEffect } from "react";
import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { getContractList, getContractsListAdmin } from "../services/contracts";
import { getUser } from "src/services/auth";
import { userRoles, users } from "../lib/constants";

const useGetContractList = () => {
  const [allContracts, setAllContracts] = useState(null);
  const [contractsTotal, setContractsTotal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPage, setlastPage] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const [lastLoadedcontract, setlastLoadedcontract] = useState(null);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    if (currentPage <= lastLoadedPage) {
      let newDataForContract = contractsTotal.slice(
        (currentPage - 1) * limit,
        currentPage * limit
      );
      setAllContracts(newDataForContract);
      setLoading(false);
    } else {
      const userRole = getUser(users.user).role;

      if (userRole == userRoles.admin) {
        getContractsListAdmin(lastLoadedcontract, limit)
          .then((data) => {
            if (data?.last) {
              setlastPage(currentPage);
              setIsLastPage(true);
            }
            setAllContracts(data?.contracts);
            setLastLoadedPage(currentPage);
            setlastLoadedcontract(data?.lastVisible);
            setContractsTotal([...contractsTotal, ...data?.contracts]);
          })
          .catch((err) => {
            console.log("error: ", err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        getContractList(lastLoadedcontract, limit)
          .then((data) => {
            if (data?.last) {
              setlastPage(currentPage);
              setIsLastPage(true);
            }
            setAllContracts(data?.contracts);
            setLastLoadedPage(currentPage);
            setlastLoadedcontract(data?.lastVisible);
            setContractsTotal([...contractsTotal, ...data?.contracts]);
          })
          .catch((err) => {
            console.log("error: ", err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [loadData]);

  return [
    allContracts,
    loading,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    lastPage,
    loadData,
    setLoadData,
  ];
};

export default useGetContractList;
