import firebase from "src/firebase";
import { isBrowser } from "src/services/auth";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getSingleMissionListByClientId } from "../services/missions";

export const useGetAllSingleOrdersList = (clientId) => {
  const [missions, setMissions] = useStateIfMounted([]);
  const [allMissions, setAllMissions] = useStateIfMounted([]);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);
  const [loading, setLoading] = useStateIfMounted(false);
  const [lastLoadedMission, setLastLoadedMission] = useStateIfMounted(null);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [dataLoadedTill, setDataLoadedTill] = useStateIfMounted(null);
  const [isLastPage, setIslastPage] = useStateIfMounted(false);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [limit, setLimit] = useStateIfMounted(5);
  const [filter, setFilter] = useStateIfMounted({
    missionName: "",
    id: "",
    status: "",
  });

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    try {
      setLoading(true);
      if (dataLoadedTill >= currentPage) {
        let prevPageClients = allMissions.slice(
          (currentPage - 1) * limit,
          currentPage * limit
        );
        setMissions(prevPageClients);
        setLoading(false);
      } else {
        getSingleMissionListByClientId(
          firebase,
          lastLoadedMission,
          limit,
          filter,
          clientId
        ).then((data) => {
          setMissions(data?.singleMissionData);
          setDataLoadedTill(currentPage);
          setLastLoadedMission(data?.lastLoaded);
          setAllMissions([...allMissions, ...data.singleMissionData]);
          if (data.isLast) {
            setLastPageNumber(currentPage);
            setIslastPage(true);
          }
          setLoading(false);
        });
      }
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  }, [triggerReload]);

  return {
    missions,
    setAllMissions,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedMission,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  };
};
