import React from "react";
import PropTypes from "prop-types";
import defaultImage from "src/assets/images/profileImage.jpg";
import LoadingEllipsis from "../loading-ellipsis";
import { Box, Avatar } from "@mui/material";

const ProfileImage = ({ imageURL, side, clickable, loading, style }) => {
  const styles = {
    root: {
      width: side,
      height: side,
      cursor: clickable ? "pointer" : "initial",
      maxWidth: "200px",
    },
  };

  return (
    <Box>
      {loading && <LoadingEllipsis />}
      <Avatar
        sx={{ ...styles.root, ...style }}
        alt="crowddroning pilot"
        src={imageURL}
      />
    </Box>
  );
};

ProfileImage.propTypes = {
  imageURL: PropTypes.string,
  side: PropTypes.string,
  clickable: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ProfileImage.defaultProps = {
  side: "150px",
  clickable: false,
  loading: false,
  imageURL: defaultImage,
  className: "",
  style: undefined,
};

export default ProfileImage;
