import { Box, Typography } from "@mui/material";
import React from "react";
import FilterComponent from "../../../global/elements/FilterComponent";
import { navigate } from "gatsby";
import GlobalTable from "../../admin/GlobalTable";
import {
  FilterFieldsForListingClientsAllProject,
  columnForListingClientsAllProjects,
  userRoles,
} from "../../../../lib/constants";
import theme from "../../../../theme";
import { boxShadowStyle } from "../../../../lib/styleConstants";
import { useGetProjectListByClientId } from "../../../../hooks/useGetProjectListByClientId";
import PropTypes from "prop-types";

const styles = {
  marginCss: { margin: "30px 0" },
  noDataText: {
    width: "90px",
    marginRight: "30px",
    borderRight: `1px solid ${theme.palette.grey.lightWarmGrey}`,
  },
  noOrders: {
    display: "flex",
    padding: "20px",
  },
};

const AllClientsProjects = ({ clientId, role }) => {
  const {
    projects,
    setAllProjects,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedProject,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  } = useGetProjectListByClientId(clientId);

  const goToPageFcn = (value) => {
    if (role == userRoles.admin) {
      navigate(`/app/admin/projects/${value.id}`);
    } else {
      navigate(`/app/client/projects/${value.id}`);
    }
  };
  const clearDataAndTriggerReload = () => {
    setLastLoadedProject(null);
    setCurrentPage(1);
    setAllProjects([]);
    setLastPageNumber(null);
    setDataLoadedTill(null);
    setIslastPage(false);
    setTriggerReload(!triggerReload);
  };

  const limitHandler = (value) => {
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const paginationBtnClick = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (!(currentPage === 1)) {
        setCurrentPage(currentPage - 1);
        setIslastPage(null);
        setTriggerReload(!triggerReload);
      }
    }
  };

  if (projects?.length !== 0 || loading === true || filter != {}) {
    return (
      <Box>
        <Box>
          <FilterComponent
            filterFields={FilterFieldsForListingClientsAllProject}
            multiple={true}
            filterFormName={"mainFilterFields"}
            filter={filter}
            setFilter={setFilter}
            clearDataAndTriggerReload={clearDataAndTriggerReload}
          />
        </Box>
        <Box mt={2}>
          <GlobalTable
            columns={columnForListingClientsAllProjects}
            allData={projects}
            goToPageFcn={goToPageFcn}
            loading={loading}
            limitHandler={limitHandler}
            limit={limit}
            paginationBtnClick={paginationBtnClick}
            currentPage={currentPage}
            isLastPage={isLastPage}
            lastPage={lastPageNumber}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ ...styles.noOrders, ...boxShadowStyle }} mt={2}>
        <Typography variant="h6">{"No projects"}</Typography>
      </Box>
    );
  }
};

AllClientsProjects.propTypes = {
  clientId: PropTypes.string.isRequired,
  role: PropTypes.string,
};

AllClientsProjects.defaultProps = {
  role: undefined,
};
export default AllClientsProjects;
