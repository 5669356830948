import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../../../theme";
import PropTypes from "prop-types";
import useGetLogs from "../../../../hooks/useGetLogs";
import AppButton from "../../../global/elements/AppButton";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import { getDateFromTimeStamp } from "../../../../lib/helpers";

const styles = {
  boldText: { fontWeight: "500 !important" },
  marginBox: { margin: "10px" },
  singleLogContainer: {
    padding: "10px",
  },
  titleInfoContainer: {
    display: { xs: "block", sm: "flex" },
    justifyContent: { xs: "inherit", sm: "space-between" },
  },
  greenText: {
    color: theme.palette.status.successDark,
  },
  buttonContainer: {
    justifyContent: "space-between",
    display: "flex",
    margin: "20px 0",
  },
  buttonStyle: {
    width: "80px",
    padding: "8px 20px",
  },
  loaderStyle: { marginTop: "25px" },
  noLogsText: {
    margin: "auto",
    color: theme.palette.secondary.main,
  },
  logBox: {
    textAlign: "center",
  },
};

const ProjectLogs = ({ projectId, pilotId }) => {
  const [
    logs,
    triggerReloadLogs,
    currentPage,
    setCurrentPage,
    lastPageNumber,
    setTriggerReloadLogs,
    loading,
    allLogs,
  ] = useGetLogs({
    projectId,
    pilotId,
  });
  const paginationBtnClick = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setTriggerReloadLogs(!triggerReloadLogs);
    } else if (type === "next" && currentPage !== lastPageNumber) {
      setCurrentPage(currentPage + 1);
      setTriggerReloadLogs(!triggerReloadLogs);
    }
  };
  return (
    <>
      <Box sx={styles.marginBox}>
        <Typography
          variant="subtitle1Med"
          sx={{ ...styles.boldText, ...styles.blackText }}
        >
          PROJECT LOGS
        </Typography>

        {allLogs?.length > 0 ? (
          !loading ? (
            <Box>
              {logs?.map((log, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      ...styles.singleLogContainer,
                      borderBottom:
                        i === 4
                          ? "none"
                          : `1px solid ${theme.palette.grey.dividersColor}`,
                    }}
                  >
                    <Box sx={styles.titleInfoContainer}>
                      <Typography variant="body1" sx={styles.boldText}>
                        {log.logTitle}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ ...styles.greenText, ...styles.boldText }}
                      >
                        {log?.createdAt
                          ? getDateFromTimeStamp(log.createdAt)
                          : "Date"}
                      </Typography>
                    </Box>

                    <Typography variant="body2">
                      {log?.logDescription}
                    </Typography>
                  </Box>
                );
              })}
              <Box sx={styles.buttonContainer}>
                <AppButton
                  look={currentPage === 1 ? "inactive" : "primary"}
                  isDisabled={currentPage === 1}
                  label="prev"
                  onClick={() => {
                    paginationBtnClick("prev");
                  }}
                  addtionalStyle={styles.buttonStyle}
                />
                <AppButton
                  look={currentPage === lastPageNumber ? "inactive" : "primary"}
                  isDisabled={currentPage === lastPageNumber}
                  label="next"
                  onClick={() => {
                    paginationBtnClick("next");
                  }}
                  addtionalStyle={styles.buttonStyle}
                />
              </Box>
            </Box>
          ) : (
            <UniversalLoader size={55} />
          )
        ) : (
          <Box sx={styles.logBox}>
            <Typography variant="h6" sx={styles.noLogsText}>
              No logs available yet
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

ProjectLogs.propTypes = {
  projectId: PropTypes.string.isRequired,
  pilotId: PropTypes.string,
};

ProjectLogs.defaultValue = {
  pilotId: null,
};

export default ProjectLogs;
