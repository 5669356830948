import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect } from "react";
import theme from "../../../theme";
import { useDispatch } from "react-redux";
import BackNav from "../../global/elements/BackNav";
import AppButton from "../../global/elements/AppButton";
import { FileDownloadOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MapDrawingTool from "../mapDrawingTool/MapDrawingTool";
import { navigate } from "gatsby";
import { getUser } from "../../../services/auth";
import { getUnitLib } from "../../../lib/helpers";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { useSnackbar } from "notistack";
import { ClearCart } from "../../../redux/actions/cart-detail-action";
import { useGetPurchasedList } from "../../../hooks/useGetPurchasedList";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "src/firebase";
import { pageSRC, users } from "../../../lib/constants";
import {
  singlePageOuterContainer,
  singlePageMainContainer,
} from "../../../lib/styleConstants";
import MenuBar from "../../global/elements/MenuBar";
import GuestStatusAlert from "../AnonymousUser/GuestStatusAlert";
import TablePagination from "../../global/Table/TablePagination";

const styles = {
  bodyHeader: {
    fontWeight: 600,
    marginTop: "17px",
    color: theme.palette.black.darkSeaBlack,
  },
  locationLabel: {
    fontWeight: 500,
    color: theme.palette.grey.deepWarmGrey,
    textTransformation: "uppercase",
    cursor: "pointer",
  },
  areaSize: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
  },
  infoBox: {
    fontWeight: 700,
    color: theme.palette.black.coolGrey,
  },
  infoBoxText: {
    opacity: 1,
    letterSpacing: "0.4px",
    color: theme.palette.grey.deepWarmGrey,
  },
  projectMetrics: {
    border: `2px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.palePistachio,
    marginTop: "1%",
  },
  infoIcon: {
    marginLeft: { xs: "10px", sm: "20px" },
    fontSize: { sm: "25px" },
    color: theme.palette.grey.deepWarmGrey,
    cursor: "pointer",
  },

  cardContainer: {
    boxShadow: `0px 0px 66px ${theme.palette.grey.lightWarmGrey}`,
    marginTop: "3%",
  },
  CardContentBox: {
    marginTop: "2%",
    display: "flex",
    justifyContent: "space-between",
  },
  DataMetricContainer: {
    width: { md: "100%", lg: "50%" },
    marginLeft: { md: "0px", lg: "2%" },
    marginTop: { md: "3%", lg: 0 },
  },
  DownloadDevider: {
    height: "auto",
    display: "inline-block",
    border: `0.5px solid ${theme.palette.primary.main}`,
  },
  noData: {
    color: theme.palette.black.coolGrey,
  },
  paginationKey: {
    paddingTop: "4px",
    fontWeight: "900",
    color: theme.palette.black.darkSeaBlack,
  },
  noDataBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "150px",
    justifyContent: "space-evenly",
  },
};

const Cart = () => {
  let userInfo = getUser(users.userProfile);

  const [
    filteredMissionList,
    isLoading,
    setIsLoading,
    limit,
    setLimit,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllMission,
    pageChanged,
    setPageChanged,
    setDataLoadedTill,
    setLastLoadedMission,
    setFilterorSortTriggered,
    reload,
    setReload,
  ] = useGetPurchasedList(userInfo?.id, userInfo?.enterPriseId);
  const { enqueueSnackbar } = useSnackbar();
  let datLibraryRef = firebase
    .firestore()
    .collection("clientDataLibraryPurchaseItems")
    .where("clientId", "==", userInfo?.id);

  const [data] = useCollectionData(datLibraryRef, {
    idField: "id",
  });

  let dispatch = useDispatch();
  const [pageSrc, setPageSrc] = React.useState(pageSRC.purchaseDataPage);
  const [userData, setUserData] = React.useState({});
  const user = getUser(users.user);

  useEffect(() => {
    const userProfile = getUser(users.userProfile);
    setUserData(userProfile);
    if (window?.location?.search) {
      let queryParam = window.location.search?.substring(
        window.location.search?.indexOf("=") + 1
      );
      setIsLoading(true);
      if (queryParam === "success") {
        if (data != undefined) {
          setReload(!reload);
        }
        setPageSrc(pageSRC.stripe);
        dispatch(ClearCart());
        enqueueSnackbar("You have successfully purchased data", {
          variant: "success",
        });
      }
    }
  }, []);

  const limitHandler = (value) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    setFilterorSortTriggered(true);
    setLimit(value);
    setDataLoadedTill(0);
    setAllMission([]);
    setPreviousPage(0);
    setLoadHandler(!loadHandler);
    setAllMission([]);
    setLastLoadedMission(null);
    setPagedata(pageDataNew);
  };

  const paginationBtnClick = (type) => {
    let pageDataNew = pageData;
    if (type == "next") {
      if (!(pageDataNew.lastPage == pageDataNew["currentPage"])) {
        pageDataNew["currentPage"] = pageDataNew["currentPage"] + 1;
      }
    } else {
      pageDataNew["currentPage"] = pageDataNew["currentPage"] - 1;
    }
    setPageChanged(!pageChanged);
    setPagedata(pageDataNew);
  };

  const visitMission = (id) => {
    navigate(`/app/client/data-library/${id}`);
  };

  const AreaComp = () => {
    return (
      <span>
        km<sup>2</sup>
      </span>
    );
  };
  return (
    <>
      <Box sx={singlePageOuterContainer}>
        <Box sx={singlePageMainContainer}>
          <Box>
            <MenuBar
              country={userData?.location || ""}
              profileImage={userData?.profileImageURL || ""}
            />
            <BackNav
              pageName="Purchased archive data"
              routeTo="/app/client/my-account"
            />
          </Box>
          {user?.isAnonymous && <GuestStatusAlert />}

          <Box sx={styles.bodyContainer}>
            <Typography variant="subtitle1">
              Access and download your purchased orders from the data library
              for the sharpest view of reality.
            </Typography>

            {!isLoading ? (
              <Box>
                <Box sx={{ marginTop: "3%" }}>
                  {filteredMissionList?.map((item, i) => {
                    return (
                      <Card sx={styles.cardContainer} key={i}>
                        <CardContent>
                          <Box sx={styles.CardContentBox}>
                            <Box>
                              <Typography variant="body1">
                                {item?.mission?.locationLabel &&
                                  item.mission.locationLabel}
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  ...styles.locationLabel,
                                  color: theme.palette.grey.deepWarmGrey,
                                }}
                                onClick={() => {
                                  visitMission(item.missionId);
                                }}
                              >
                                {item?.mission?.missionName &&
                                  item.mission.missionName}
                              </Typography>
                              <Typography variant="body2">
                                {item?.mission?.dataLibrary?.description &&
                                  item.mission.dataLibrary.description}
                              </Typography>
                            </Box>
                            <Box sx={{ display: { xs: "none", sm: "inline" } }}>
                              <AppButton
                                label="DOWNLOAD DATA"
                                endIcon={<FileDownloadOutlined />}
                                downloadFunction
                                look={"black"}
                                addtionalStyle={{
                                  width: "220px",
                                }}
                                onClick={() => {
                                  navigate(
                                    `/app/client/datalibrary/download/${item.missionId}`
                                  );
                                }}
                              />
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              display: { md: "block", lg: "flex" },
                              marginTop: "2%",
                            }}
                          >
                            <Box
                              sx={{
                                width: { md: "100%", lg: "50%" },
                                borderRadius: "20px",
                              }}
                            >
                              <MapDrawingTool
                                area={item.mission.locationMap}
                                readonly
                                mapHeight={{
                                  height: "300px",
                                  borderRadius: "15px",
                                  overflow: "hidden",
                                }}
                              />
                            </Box>
                            <Box sx={styles.DataMetricContainer}>
                              {/* ----------------project metrics------------ */}
                              <Typography sx={{ fontWeight: 450 }} variant="h6">
                                data metrics overview
                              </Typography>
                              <Box sx={styles.projectMetrics}>
                                <Box sx={{ padding: "2% 8%" }}>
                                  <Box sx={styles.areaSize}>
                                    <Box>
                                      <Typography
                                        sx={styles.infoBoxText}
                                        variant="h6"
                                      >
                                        Geographic Area
                                      </Typography>
                                      <Typography
                                        sx={styles.infoBox}
                                        variant="h5"
                                      >
                                        {item?.mission?.areaSize &&
                                          Number(item.mission.areaSize).toFixed(
                                            2
                                          )}{" "}
                                        {item?.mission &&
                                          (getUnitLib(item?.mission) == "km2"
                                            ? AreaComp()
                                            : getUnitLib(item?.mission))}
                                      </Typography>
                                    </Box>
                                    <hr style={styles.DownloadDevider} />
                                    <Box>
                                      <Typography
                                        sx={styles.infoBoxText}
                                        variant="h6"
                                      >
                                        Download Size
                                      </Typography>
                                      <Typography
                                        sx={styles.infoBox}
                                        variant="h5"
                                      >
                                        {item?.dataSize || 0} MB
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <InfoOutlinedIcon
                                        sx={styles.infoIcon}
                                        // onClick={handleOpen}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>

                              <Box
                                sx={{ display: { xs: "inline", sm: "none" } }}
                              >
                                <AppButton
                                  label="DOWNLOAD DATA"
                                  endIcon={<FileDownloadOutlined />}
                                  addtionalStyle={{
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    navigate(
                                      `/app/client/datalibrary/download/${item.missionId}`
                                    );
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    );
                  })}
                </Box>

                <Box sx={styles.bodyHeader}>
                  <Box sx={styles.tableBox}>
                    {filteredMissionList.length ? (
                      <TablePagination
                        currentPage={pageData.currentPage}
                        lastPage={pageData.lastPage}
                        onClickNext={() => paginationBtnClick("next")}
                        onClickPrevious={() => paginationBtnClick("prev")}
                        limit={limit}
                        limitOnChange={(e) => limitHandler(e.target.value)}
                      />
                    ) : (
                      <Box>
                        {pageSrc != pageSRC.stripe && (
                          <Box sx={styles.noDataBox}>
                            <Typography style={styles.noData} variant="h4">
                              You have not purchased data so far.
                            </Typography>
                            <AppButton
                              label="Check out our data library"
                              endIcon={<FileDownloadOutlined />}
                              downloadFunction
                              look={"primary"}
                              addtionalStyle={{
                                width: "fit-content",
                              }}
                              onClick={() => {
                                navigate("/app/client/data-library");
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ paddingTop: "18%" }}>
                <UniversalLoader />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Cart;
