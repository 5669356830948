import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  section_dataset: {
    display: "flex",
  },
  section_dataset_heading: {
    minWidth: { xs: "100px", sm: "160px" },
  },
  vertical_line: {
    borderRight: `1px solid ${theme.palette.grey.lightWarmGrey}`,
    margin: "0 10px",
    marginRight: "25px",
  },
  setion_dataset_content: {
    paddingBottom: "15px",
  },
};

const MissionDetailContent = ({ title, content }) => {
  return (
    <Box sx={styles.section_dataset}>
      <Typography variant="subtitle1Med" sx={styles.section_dataset_heading}>
        {title}
      </Typography>
      <Box sx={styles.vertical_line}></Box>
      <Typography variant="body1" sx={styles.setion_dataset_content}>
        {content}
      </Typography>
    </Box>
  );
};

MissionDetailContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default MissionDetailContent;
