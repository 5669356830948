import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import ArrowHeaderContainer from "../../NewDataLibrary/components/ArrowHeaderContainer";
import Layout from "../../../../Layout";
// import HorizontalPromotion from "./components/HorizontalPromotion";
import IconicLogo from "src/assets/logos/iconic_logo-01.png";
// import Dtm from "src/assets/images/DTM.jpg";
import SmallPromotion from "./components/SmallPromotion";
import AdvertisementBanner from "src/assets/images/i-conic banner-new.png";
import Pix4DLogo from "src/assets/images/pix4d-logo.png";

import { Link } from "gatsby";

const styles = {
  advertisementBanner: {
    width: "100%",
    // aspectRatio: "4.5/1",
    justifyContent: "center",
  },
  minHeight: {
    height: "100%",
  },
};

const PilotBenefitsPage = () => {
  return (
    <Layout hasSideMenu addBottomPadding>
      <ArrowHeaderContainer header="My benefits" variant={"h2"}>
        <Typography variant="h6">
          As a Crowddroning Operator with Globhe, you get more! Discover our
          partner discounts and promotions, with more on the way. We can't wait
          to show you what we have in store.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            {/* <HorizontalPromotion
              title="Get real-time 3D modelling from drones instantely"
              subtitle=" I-Conic creates the world's first 3D modelling with drones,
              generated while flying. Grab this offer to reduce your processing times and get live 3D."
              logo={IconicLogo}
              image={Dtm}
              link="https://i-conicvision.com/"
              //  discount="20%"
            /> */}
            <Link target="_blank" to={"https://i-conicvision.com/pricing/"}>
              <Box
                component="img"
                src={AdvertisementBanner}
                sx={styles.advertisementBanner}
              />
            </Link>
          </Grid>
          {/* Activate that when adding small promotions. Small promotions should only occupy half of the grid */}
          <Grid item xs={12} sm={6}>
            <SmallPromotion
              logo={IconicLogo}
              title="Instant 3D from video or still images"
              subtitle="I-CONIC Studio is a completely new desktop application that utilizes your graphics 
              card. Just drag and drop your drone video or still image and get a 3D model instantly. 
              Validate your captured data by generating a 3D model instantly in the field."
              link="https://i-conicvision.com/pricing/"
              discount="20%"
              background="green"
              btnText="Claim offer"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SmallPromotion
              logo={Pix4DLogo}
              title="Get any monthly subscription of Pix4D’s cloud or desktop products."
              subtitle="Pix4D provides desktop and cloud software for precise 2D and 3D 
              mapping and modeling projects. Enjoy free data capture apps and the capability 
              to seamlessly combine aerial and terrestrial images. Benefit from expert support 
              and cross-platform access! Enter PIX4DGLOBHEPRODUCTS2023 during check out 
              to redeem offer."
              link="https://www.pix4d.com/pricing/"
              couponCode="PIX4DGLOBHEPRODUCTS2023"
              btnText="Claim offer"
              discount="25%"
              background="lightPurple"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SmallPromotion
              logo={Pix4DLogo}
              title="Access any training from Pix4D."
              subtitle="Discover best practices for creating 2D and 3D maps and models from 
              Pix4D experts! Choose your preferred learning method from instructor-led 
              workshops to self-paced online courses. Test your knowledge and validate 
              your expertise with industry-recognized certification. Enter 
              PIX4DGLOBHETRAINING2023 during check out to redeem offer."
              link="http://training.pix4d.com/"
              couponCode="PIX4DGLOBHETRAINING2023"
              btnText="Enroll now"
              discount="25%"
            />
          </Grid>
        </Grid>
      </ArrowHeaderContainer>
    </Layout>
  );
};

export default PilotBenefitsPage;
