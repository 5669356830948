import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import PropTypes from "prop-types";
import defaultImage from "src/assets/images/profileImage.jpg";

const styles = {
  mainContainerPersonList: {
    //background: theme.palette.black.paleSilver,
    height: "100%",
    borderRight: "none",
  },
  individualBox: {
    padding: "10px 15px",
    borderRight: "none",
    cursor: "pointer",
    borderLeft: "none",
  },
  profilePicture: {
    height: "50px",
    width: "50px",
    alignSelf: "center",
    borderRadius: "50%",
    marginLeft: "10px",
  },
  individualPerson: {
    display: "flex",
  },
};

const SideChatMenu = ({ personToChat, onPersonClickFcn, active }) => {
  return (
    <Box sx={styles.mainContainerPersonList}>
      {personToChat?.map((person, i) => {
        return (
          <>
            <Box
              sx={{
                ...styles.individualPerson,
                background:
                  person.role === active
                    ? theme.palette.adminBlue.paleBlue
                    : "none",
              }}
              key={i}
            >
              <Avatar
                alt="Image"
                src={person.profilePicture || defaultImage}
                sx={styles.profilePicture}
              />
              <Grid
                sx={{
                  ...styles.individualBox,
                }}
                container
                onClick={() => onPersonClickFcn(person.role)}
              >
                <Grid item xs={12}>
                  <Typography variant="h6">{person.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body3">{person.id}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider />
          </>
        );
      })}
    </Box>
  );
};

SideChatMenu.propTypes = {
  personToChat: PropTypes.array.isRequired,
  onPersonClickFcn: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
};

export default SideChatMenu;
