import { Box } from "@mui/material";
import React from "react";
import { statusAlertSeverity } from "../../../../lib/constants";
import StatusAlertContainer from "../../../global/elements/StatusAlertContainer";
import { Link } from "gatsby";
import theme from "../../../../theme";
import PropTypes from "prop-types";

const styles = {
  contract_link: {
    fontSize: "14px",
    fontWeight: 400,
    color: theme.palette.secondary.lightPurple,
    textDecoration: "none",
  },
  mB: { marginBottom: "30px" },
};
const SignInContract = ({ contractId }) => {
  return (
    <Box sx={styles.mB}>
      {" "}
      <StatusAlertContainer
        severity={statusAlertSeverity.error}
        heading={
          <Box>
            Contract not signed! Please{" "}
            <Link
              style={styles.contract_link}
              to={contractId && `/app/my-profile/contracts/${contractId}`}
            >
              sign the contract
            </Link>{" "}
            to upload files for this mission. Contact Us if you require more
            information or if you have any doubts or concerns.
          </Box>
        }
      />
    </Box>
  );
};

SignInContract.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default SignInContract;
