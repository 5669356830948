import React from "react";
import OrderDetails from "../missions/NewMissionDetails/components/OrderDetails";
import CollapsableBox from "../../global/elements/CollapsableBox";
import { userRoles } from "../../../lib/constants";

function BasicDetailsInQuotes() {
  return (
    <CollapsableBox
      title="Order details"
      customVariant={"h3"}
      background={"none"}
      userType={userRoles.admin}
    >
      <OrderDetails minimizeDetails={true} />
    </CollapsableBox>
  );
}

export default BasicDetailsInQuotes;
