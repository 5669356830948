import React, { useContext } from "react";
import MissionContext from "./MissionContext";
import {
  missionStatus,
  missionTypeConstant,
} from "../../../../../lib/constants";
import { Grid } from "@mui/material";
import AppButton from "../../../../global/elements/AppButton";

const styles = {
  inviteMore: {
    textAlign: { xs: "center", md: "right" },
    marginTop: { xs: "30px", md: 0 },
  },
  inviteAllBtn: { textAlign: { xs: "center", md: "left" } },
};

const PilotSelectionPrompt = () => {
  const missionDetails = useContext(MissionContext);
  return (
    !(
      missionDetails?.mission.status == missionStatus.cancelled ||
      missionDetails?.mission.status == missionStatus.rejected
    ) && (
      <Grid container mt={3}>
        {!missionDetails?.mission?.assignedPilot && (
          <>
            <Grid item xs={12} md={6} sx={styles.inviteAllBtn}>
              {(missionDetails?.mission.status === missionStatus.unassigned ||
                missionDetails?.mission.status ===
                  missionStatus.confirmedMission ||
                missionDetails?.mission?.status ===
                  missionStatus.initialClientOrder) &&
                missionDetails?.mission.missionType !=
                  missionTypeConstant.projectMission && (
                  <AppButton
                    onClick={missionDetails?.openPopup}
                    look={
                      missionDetails?.checkInviteAssignDisablity()
                        ? "blackDisabled"
                        : "inverted"
                    }
                    label="Invite all from this country"
                    isDisabled={missionDetails?.checkInviteAssignDisablity()}
                  />
                )}
            </Grid>

            <Grid item xs={12} md={6} sx={styles.inviteMore}>
              {missionDetails?.mission.missionType !=
                missionTypeConstant.projectMission && (
                <AppButton
                  onClick={() =>
                    missionDetails?.setPageMode(
                      missionDetails?.PageModes.INVITE_PILOTS
                    )
                  }
                  look={
                    missionDetails?.checkInviteAssignDisablity() ||
                    missionDetails?.mission.pilotInstructions === ""
                      ? "blackDisabled"
                      : "blue"
                  }
                  label="Invite more drone operators"
                  isDisabled={
                    missionDetails?.checkInviteAssignDisablity() ||
                    missionDetails?.mission.pilotInstructions === ""
                  }
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    )
  );
};

export default PilotSelectionPrompt;
