import React from "react";
import { useGetProjectList } from "src/hooks/useGetProjectList";
import { getUser } from "../../../../services/auth";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import FilterProject from "../FilterProject";
import GlobalTable from "../../admin/GlobalTable";
import { getProjectStatusLabelData } from "../../../../lib/helpers";
import { missionStatusLabels, userRoles } from "../../../../lib/constants";
import { navigate } from "gatsby";
import StatusBadge from "../../../global/elements/StatusBadge";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import { listLoader, listLoaderParent } from "../../../../lib/styleConstants";

const styles = {
  columns: {
    float: "left",
    clear: "none",
    display: "flex",
    alignItems: "flex-start",
    padding: "0 15px",
    marginBottom: "1rem",
  },
  filter: {
    marginTop: "10px",
  },
  nameFilter: {
    paddingTop: "15px",
    width: { xs: "100%", md: "250px" },
    margin: { xs: 0, sm: 0 },
  },
  typeFilter: {
    marginRight: { xs: 0, md: "50px" },
    width: { xs: "100% !important", sm: "100% !important" },
  },
  titleHolder: {
    paddingTop: "40px",
    display: "flex",
    alignItems: "center",
  },
  countBadge: {
    marginLeft: "10px",
    float: "left",
    borderRadius: "50%",
    color: theme.palette.grey.white,
    textAlign: "center",
    minWidth: "23px",
    padding: "5px 10px",
    fontWeight: "500",
    marginTop: "2px",
    backgroundColor: theme.palette.primary.main,
  },
  badgeActive: {
    backgroundColor: theme.palette.primary.main,
  },
  badgeUnassigned: {
    backgroundColor: theme.palette.primary.main,
  },
  titleContainer: {
    position: "relative",
    maxWidth: "100%",
    padding: { lg: "0 20px", md: "0 20px", sm: "0 20px", xs: "0 15px" },
  },
  projectContainer: {
    width: "100%",
    padding: "0px",
  },
  contentWrapper: {
    width: "100%",
    display: "flex",
    padding: "30px 0",
  },
  info_message: {
    fontSize: { xs: "12px", md: ".73864rem" },
    textAlign: "center",
    color: theme.palette.status.errorDark,
  },
  status_mark: {
    float: "left",
    width: "8px",
    height: "8px",
    margin: "5px",
    borderRadius: "50%",
  },
  paymentStatusRow: {
    display: "flex",
    width: "90px",
    marginBottom: "2px",
    "& h6": {
      width: "auto !important",
    },
  },
  badgeButton: {
    height: "30px",
    width: "206px",
    textAlign: "center",
    borderRadius: "10px",
    paddingTop: "5px",
    fontWeight: 500,
  },
  unAuthorized: {
    width: "100%",
    marginTop: "10rem",
  },
  fontWeightStyle: {
    fontWeight: { xs: 200 },
  },
};

const ProjectPage = ({ status, columns, enterpriseId }) => {
  const getStatus = status;
  const currentUser = getUser("user");
  const [
    filteredProjectList,
    isLoading,
    limit,
    setLimit,
    filter,
    setFilter,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllProject,
    setDataLoadedTill,
    setLastLoadedProject,
    sortBy,
    setSortBy,
    pageChanged,
    setPageChanged,
  ] = useGetProjectList(
    getStatus,
    currentUser.role,
    currentUser.userID,
    enterpriseId
  );

  const limitHandler = (value) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    setLimit(value);
    setDataLoadedTill(0);
    setAllProject([]);
    setPreviousPage(0);
    setLoadHandler(!loadHandler);
    setAllProject([]);
    setDataLoadedTill(0);
    setLastLoadedProject(null);
    setPagedata(pageDataNew);
  };

  const paginationBtnClick = (type) => {
    let pageDataNew = pageData;
    if (type == "next") {
      if (!(pageDataNew.lastPage == pageDataNew["currentPage"])) {
        pageDataNew["currentPage"] = pageDataNew["currentPage"] + 1;
      }
    } else {
      pageDataNew["currentPage"] = pageDataNew["currentPage"] - 1;
    }
    setPageChanged(!pageChanged);
    setPagedata(pageDataNew);
  };

  const sortByHandler = (sort) => {
    setLoadHandler(!loadHandler);
    setAllProject([]);
    setDataLoadedTill(0);
    setLastLoadedProject(null);
    setPreviousPage(0);
    if (sort === sortBy.sortBy) {
      const order = sortBy.sortValue === "asc" ? "desc" : "asc";
      setSortBy({ sortBy: sort, sortValue: order });
    } else {
      setSortBy({ sortBy: sort, sortValue: "asc" });
    }
  };

  const valueChangeHandler = (value, field, filterFields) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    let filterDataNew = filter;
    if ((value == "") & (field == "")) {
      let changedField = Object.keys(filter).filter(
        (fld) => filterFields.indexOf(fld) == -1
      );
      changedField.forEach((chgFld) => {
        if (chgFld == "Project Type") {
          filterDataNew[chgFld] = [];
        } else {
          filterDataNew[chgFld] = "";
        }
      });
    } else if ((value == "InitialLoad") & (field == "InitialLoad")) {
      let filterDataSaved = JSON.parse(localStorage.getItem("filter"));
      if (getStatus == "draft") {
        filterDataSaved["Project Name"] = "";
        filterDataNew = filterDataSaved;
      } else {
        filterDataNew = filterDataSaved;
      }
    } else {
      filterDataNew[field] = value;
    }
    localStorage.setItem("filter", JSON.stringify(filterDataNew));
    let applyFilterOn = {};
    filterFields.forEach((opt) => {
      if (filterDataNew[opt]) {
        applyFilterOn[opt] = filterDataNew[opt];
      }
    });
    setPreviousPage(0);
    setPagedata(pageDataNew);
    setFilter(applyFilterOn);
    setLoadHandler(!loadHandler);
    setAllProject([]);
    setDataLoadedTill(0);
    setLastLoadedProject(null);
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "status": {
        const statusLabel = getProjectStatusLabelData(value, currentUser);
        return (
          <Box>
            <StatusBadge
              name={
                statusLabel.label === missionStatusLabels.correction
                  ? missionStatusLabels.inProgress
                  : statusLabel.label
              }
              status={statusLabel.statusColor}
            />
            <Box
              dangerouslySetInnerHTML={{
                __html: statusLabel.info,
              }}
              sx={styles.info_message}
            />
          </Box>
        );
      }
      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    if (currentUser?.role == userRoles.client) {
      navigate(`/app/client/projects/${value.id}`);
    } else {
      navigate(`/app/admin/projects/${value.id}`);
    }
  };

  return (
    <Box sx={listLoaderParent}>
      {isLoading && (
        <Box sx={listLoader}>
          <UniversalLoader />
        </Box>
      )}
      {(filteredProjectList.length !== 0 ||
        isLoading === false ||
        filter != {}) && (
        <>
          <Box sx={styles.projectContainer}>
            <Box sx={styles.filter}>
              <FilterProject
                valueChangeHandler={valueChangeHandler}
                projectTypeData={getStatus}
                role={currentUser?.role}
              />
            </Box>
            <GlobalTable
              columns={columns}
              allData={filteredProjectList}
              valueConvertHandler={valueConvertHandler}
              goToPageFcn={goToPageFcn}
              loading={isLoading}
              limitHandler={limitHandler}
              limit={limit}
              paginationBtnClick={paginationBtnClick}
              currentPage={pageData.currentPage}
              isLastPage={pageData.isLast}
              lastPage={pageData.lastPage}
              sortByHandler={sortByHandler}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

ProjectPage.propTypes = {
  status: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  enterpriseId: PropTypes.string,
};
ProjectPage.defaulProps = {
  enterpriseId: null,
};

export default ProjectPage;
