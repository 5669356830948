import firebase from "src/firebase";
import { useEffect, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { isBrowser } from "src/services/auth";
import { getDeclinedFeedbackByMissionId } from "src/services/missions";

const useGetDeclinedFeedback = (missionId) => {
  const [feedbacks, setFeedbacks] = useStateIfMounted([]);
  const [loading, setLoading] = useStateIfMounted(true);

  useEffect(() => {
    if (!firebase || !isBrowser() || !missionId) return;
    getDeclinedFeedbackByMissionId(firebase, missionId)
      .then((results) => {
        setFeedbacks(results);
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, [missionId]);

  return [feedbacks, loading];
};

export default useGetDeclinedFeedback;
