import { Search } from "@mui/icons-material";
import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../theme";

const styles = {
  root: {
    display: "flex",
    border: `1px solid ${theme.palette.black.coolGrey}`,
    borderRadius: 0,
    justifyContent: "space-between",
    paddingLeft: "13px",
    backgroundColor: theme.palette.grey.white,
    height: "50px",
    alignItems: "center",
  },
  inputField: {
    background: "none",
    border: "none",
    outline: "none",
    width: "100%",
    height: "50px",
  },
  searchIcon: {
    padding: { xs: "5px" },
    fontSize: "31px",
    color: `${theme.palette.black.coolGrey}`,
    margin: "auto",
  },
};

const DataRequestAutoComplete = ({ mapApi, map, addplace }) => {
  const searchInputRef = React.useRef(null);
  const [autoComplete, setAutoComplete] = React.useState(null);

  React.useEffect(() => {
    const options = {
      // restrict your search to a specific type of result
      // types: ['geocode', 'address', 'establishment', '(regions)', '(cities)'],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    const _autoComplete = new mapApi.places.Autocomplete(
      searchInputRef.current,
      options
    );
    setAutoComplete(_autoComplete);
    return () => {
      mapApi.event.clearInstanceListeners(searchInputRef.current);
    };
  }, []);

  React.useEffect(() => {
    if (autoComplete) {
      autoComplete.addListener("place_changed", onPlaceChanged);
      autoComplete.bindTo("bounds", map);
    }
  }, [autoComplete]);

  const onPlaceChanged = () => {
    const place = autoComplete.getPlace();

    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    addplace(place);
    searchInputRef.current.blur();
  };

  return (
    <Box sx={styles.root}>
      <Box
        component="input"
        ref={searchInputRef}
        type="text"
        placeholder="Enter a location"
        sx={styles.inputField}
        // autoFocus="false"
      />

      <Search sx={styles.searchIcon} />
    </Box>
  );
};

DataRequestAutoComplete.propTypes = {
  mapApi: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  addplace: PropTypes.func.isRequired,
};

export default DataRequestAutoComplete;
