import React, { useEffect, useState } from "react";
import Tips from "../../../global/elements/Tips";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Autocomplete,
} from "@mui/material";
import theme from "../../../../theme";
import { TextField } from "formik-mui";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import countries from "src/lib/countries";
import { countryProfileStatus } from "../../../../lib/constants";
import AppButton from "../../../global/elements/AppButton";
import firebase from "src/firebase";
import {
  createCountryProfile,
  getCountryProfile,
} from "../../../../services/countryProfile";
import { useSnackbar } from "notistack";
import mapboxgl from "!mapbox-gl";
import CountryProfileMap from "../CountryProfileMap";
import Layout from "../../../../Layout";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ2xvYmhlIiwiYSI6ImNrdXptNjlqdDM1dGwzMHM3bWt6ZHVkd20ifQ.IlPFrxSzNDeDB3BQUusayw";

const styles = {
  backgroundGradient: {
    background: `linear-gradient(180deg, ${theme.palette.primary.main}19 0%, rgba(255,255,255,1) 30px)`,
  },
  ContainerBorder: {
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "none",
    borderRadius: "10px",
  },
  cardTitle: {
    padding: "20px",
    color: theme.palette.black.darkSeaBlack,
    fontWeight: 500,
    fontSize: "20px",
  },
  notAllowed: {
    color: theme.palette.black.darkSeaBlack,
    fontWeight: 500,
  },
  inputAndStatus: {
    display: "flex",
    justifyContent: { xs: "space-around", sm: "flex-start" },
  },
  input_cls: {
    width: "30%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      color: theme.palette.grey.warmGrey,
    },
    "& .MuiChip-root": {
      color: theme.palette.black.darkSeaBlack,
      background: theme.palette.grey.lightWarmGrey,
    },
  },
  countryStatusStyle: {
    fontSize: "13px",
    color: theme.palette.grey.deepWarmGrey,
  },
  mapContainer: {
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "visible",
  },
};

const CountryProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [reload, setReload] = useState(false);
  const [countriesData, setCountriesData] = useState(null);
  useEffect(() => {
    getCountryProfile(firebase).then((data) => {
      setCountriesData(data.countries);
    });
  }, [reload]);

  const formInitialValues = {
    country: "",
    countryStatus: "",
  };

  const handleSubmit = (values) => {
    createCountryProfile(firebase, values).then((data) => {
      if (data.status) {
        enqueueSnackbar("Country status updated successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error in updating the country status", {
          variant: "error",
        });
      }
      setReload(!reload);
    });
  };

  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Select country"),
    countryStatus: Yup.string().required("Select status"),
  });

  return (
    <Layout
      backButtonPageName={"Country Profiles"}
      hasSideMenu
      addBottomPadding
      backButton
    >
      <Tips content="You visualise drone regulations and update country profiles below. The updates will be visible in the app where we use country info to decide on the feasibility of projects." />

      <Box sx={{ display: { xs: "block", sm: "flex" }, marginTop: "4%" }}>
        <Card
          sx={{
            width: { xs: "100%", sm: "60%" },
            height: { xs: "400px", sm: "auto" },
          }}
        >
          <CountryProfileMap />
        </Card>

        <Card
          sx={{
            marginLeft: { xs: 0, sm: "3%" },
            width: { xs: "100%", sm: "40%" },
            marginTop: { xs: "10%", sm: 0 },
            ...styles.ContainerBorder,
          }}
        >
          <Box>
            <Typography sx={styles.cardTitle}> Country breakdown</Typography>
          </Box>
          <CardContent sx={styles.backgroundGradient}>
            <Box sx={{ padding: "15px" }}>
              <Box>
                <Typography sx={styles.notAllowed}>Not Allowed</Typography>
                <Divider />
                <Box sx={{ paddingTop: "2%" }}>
                  {countriesData?.map((item, i) => {
                    return (
                      <Box key={i} sx={{ display: "flex" }}>
                        <Typography
                          sx={{
                            width: "70%",
                            ...styles.countryStatusStyle,
                          }}
                        >
                          {item.status == "Not Allowed" && item.country}
                        </Typography>
                        <Typography sx={styles.countryStatusStyle}>
                          {item.status == "Not Allowed" && item.status}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>

              <Box sx={{ paddingTop: "9%" }}>
                <Typography sx={styles.notAllowed}>Complicated</Typography>
                <Divider />
                <Box sx={{ paddingTop: "2%" }}>
                  {countriesData?.map((item, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "70%",
                            ...styles.countryStatusStyle,
                          }}
                        >
                          {item.status == "Complicated" && item.country}
                        </Typography>
                        <Typography sx={styles.countryStatusStyle}>
                          {item.status == "Complicated" && item.status}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Card sx={{ marginTop: "3%", boxShadow: "none" }}>
        <Box>
          <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
            UPDATE COUNTRY
          </Typography>
        </Box>
        <Box sx={{ marginTop: "3%" }}>
          <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form id="countryprofile" sx={{ marginTop: "20px" }}>
                  <Box sx={styles.inputAndStatus}>
                    <Autocomplete
                      value={values.country}
                      options={countries}
                      onInputChange={(e, value) => {
                        setFieldValue("country", value);
                      }}
                      sx={{ "&": styles.input_cls }}
                      variant="outlined"
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="country"
                          component={TextField}
                          label="Select country"
                          variant="outlined"
                        />
                      )}
                    />

                    <Autocomplete
                      value={values.countryStatus}
                      options={countryProfileStatus}
                      onInputChange={(e, value) => {
                        setFieldValue("countryStatus", value);
                      }}
                      sx={{ "&": styles.input_cls, marginLeft: "4%" }}
                      variant="outlined"
                      renderInput={(params) => (
                        <Field
                          {...params}
                          type="text"
                          name="countryStatus"
                          component={TextField}
                          label="Select legal status"
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column-reverse", sm: "row" },
              marginTop: "40px",
            }}
          >
            <AppButton
              look="inverted"
              label="Back"
              noIcon
              url="/app/admin/tools"
              internalLink
            />
            <AppButton
              type="submit"
              look="black"
              label="Save"
              form="countryprofile"
            />
          </Box>
        </Box>
      </Card>
    </Layout>
  );
};

export default CountryProfile;
