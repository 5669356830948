import React from "react";
import ProjectTableDetails from "../../projects/Components/ProjectTableDetails";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";

const MultiOrderMissions = ({
  uploadValidData,
  setUploadValidData,
  columns,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const deleteRec = (data) => {
    let filteredRec = uploadValidData?.filter(
      (rec) => rec.uniqueId !== data?.uniqueId
    );
    setUploadValidData(filteredRec);
    enqueueSnackbar("Order removed successfully", {
      variant: "success",
    });
  };
  return (
    <ProjectTableDetails
      title={`Your ${uploadValidData.length} locations`}
      columns={columns}
      allData={uploadValidData}
      noDataAvailableText={"There are no missions in this project currently."}
      // redirectURL={null}
      onClick={deleteRec}
    />
  );
};

MultiOrderMissions.propTypes = {
  uploadValidData: PropTypes.array,
  setUploadValidData: PropTypes.func.isRequired,
  columns: PropTypes.array,
};

MultiOrderMissions.defaultProps = {
  uploadValidData: [],
  columns: [],
};

export default MultiOrderMissions;
