import React, { useContext, useState } from "react";
import { Box, Divider, Typography, Grid, IconButton } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AppButton from "../../../global/elements/AppButton";
import UploadClientAgreement from "./UploadClientAgreement";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import {
  getCurrentDateTime,
  getCurrentDateTimeMillis,
  getFileSizeInUnits,
  uploadFileToStorage,
} from "../../../../lib/helpers";
import {
  BorderLinearProgress,
  fontWeightFive,
} from "../../../../lib/styleConstants";
import theme from "../../../../theme";
import { updateClient } from "../../../../services/client";
import DeleteClientAgreement from "./DeleteClientAgreement";
import SwitchSelect from "../../../global/elements/SwitchSelect";
import PropTypes from "prop-types";
import AppContext from "../../../../contexts/AppContext";
import { userRoles } from "../../../../lib/constants";

const styles = {
  containerCls: {
    paddingTop: "20px",
  },
  progressCls: {
    marginTop: "10px",
  },
  iconCls: {
    color: theme.palette.black.darkSeaBlack,
  },
};

const ClientAgreement = ({ client, reloadData, setReloadData }) => {
  const [open, setOpen] = useState(false);
  const [percent, setPercent] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const agreement = client?.agreement || null;
  const appContext = useContext(AppContext);
  const currentUser = appContext?.userData || null;

  const onDropHandler = async (acceptedFiles) => {
    try {
      if (acceptedFiles && client?.id) {
        if (acceptedFiles.length > 1) {
          enqueueSnackbar("Please upload one document at a time!", {
            variant: "warning",
          });
        } else {
          setUploading(true);
          setOpen(false);
          let timeStamp = getCurrentDateTimeMillis();
          let file = acceptedFiles[0];
          let path = `ClientAgreement/${client?.id}/${timeStamp}/${file.name}`;
          const fileUploadStatus = await uploadFileToStorage(
            firebase,
            file,
            path,
            setPercent
          );
          if (fileUploadStatus.status === "Success") {
            // Update the uploaded agreement in the client collection

            let agreement = {
              name: file.name,
              agreementUrl: fileUploadStatus?.fileURL,
              date: getCurrentDateTime(),
              size: file?.size,
              unit: "byte", // saving in bytes
            };
            await updateClient(client?.id, { agreement });
            setUploading(false);
            setReloadData(!reloadData);
          } else {
            enqueueSnackbar("uploading failed, please try again", {
              variant: "error",
            });
            console.log("Error", fileUploadStatus);
            setUploading(false);
          }
        }
      } else {
        enqueueSnackbar("Error in uploading, please try again", {
          variant: "error",
        });
      }
    } catch (e) {
      enqueueSnackbar("Error in uploading!", {
        variant: "error",
      });
      console.log("Error in uploading agreement", e);
    }
  };

  const deleteDoc = async () => {
    try {
      let updates = {
        agreement: null,
      };
      const resp = await updateClient(client.id, updates);
      if (resp?.status == "Success") {
        setDeletePopup(false);
        enqueueSnackbar("Agreement is deleted", { variant: "success" });
        setReloadData(!reloadData);
      } else {
        console.log("Error", resp);
        enqueueSnackbar("Error in deleting the agreement", {
          variant: "error",
        });
      }
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Error in deleting the agreement!!", {
        variant: "error",
      });
    }
  };

  const updateTerms = async (termsOfUse) => {
    try {
      let updates = {
        termsOfUse,
      };
      const resp = await updateClient(client.id, updates);
      if (resp?.status == "Success") {
        enqueueSnackbar(
          `Client is ${termsOfUse ? "unlock" : "lock"} successfully`,
          { variant: "success" }
        );
        setReloadData(!reloadData);
      } else {
        console.log("Error", resp);
        enqueueSnackbar(
          `Error in ${termsOfUse ? "unlocking" : "locking"} the client`,
          {
            variant: "error",
          }
        );
      }
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar(
        `Error in ${termsOfUse ? "unlocking" : "locking"} the client!!`,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <Box>
      <Typography variant="h5">Documents</Typography>
      <Divider color={theme.palette.grey.warmGrey} />

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={styles.containerCls}
      >
        <Grid item>
          <Typography variant="subtitle1Med">Terms and conditions</Typography>
          {uploading && (
            <BorderLinearProgress
              variant="determinate"
              value={percent}
              colorPrimary={theme.palette.grey.lightWarmGrey}
              colorBar={theme.palette.secondary.main}
              sx={styles.progressCls}
            />
          )}
        </Grid>
        {!uploading && !agreement && currentUser?.role === userRoles.admin && (
          <Grid item>
            <AppButton
              look="black"
              label="Upload document"
              onClick={() => {
                setOpen(true);
              }}
              noIcon
            />
          </Grid>
        )}
      </Grid>

      {agreement && (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body1">
                file name : {agreement?.name} &nbsp; &nbsp; size:{" "}
                {getFileSizeInUnits(agreement?.size)}
              </Typography>
            </Grid>

            <Grid item>
              <a
                href={agreement.agreementUrl}
                rel="noopener noreferrer"
                target="_blank"
                title={agreement.name}
                download={agreement.name}
              >
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <FileDownloadOutlinedIcon sx={styles.iconCls} />
                </IconButton>
              </a>
              &nbsp;
              {currentUser?.role === userRoles.admin && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setDeletePopup(true);
                  }}
                >
                  <DeleteForeverOutlinedIcon sx={styles.iconCls} />
                </IconButton>
              )}
            </Grid>
          </Grid>

          {currentUser?.role === userRoles.admin && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={styles.containerCls}
            >
              <Grid item>
                <Typography variant="body2" sx={fontWeightFive}>
                  Unlock client
                </Typography>
                <SwitchSelect
                  name="termsOfUse"
                  textRight="On"
                  textLeft="Off"
                  handleChange={
                    (e, value) => updateTerms(value)

                    // setFieldValue("businessAccount", value ? true : false)
                  }
                  checked={client?.termsOfUse}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}

      {open && (
        <UploadClientAgreement
          open={open}
          setOpen={setOpen}
          onDrop={onDropHandler}
        />
      )}

      {deletePopup && (
        <DeleteClientAgreement
          open={deletePopup}
          setOpen={setDeletePopup}
          onSubmit={deleteDoc}
        />
      )}
    </Box>
  );
};

ClientAgreement.propTypes = {
  client: PropTypes.object.isRequired,
  setReloadData: PropTypes.func.isRequired,
  reloadData: PropTypes.bool.isRequired,
};

export default ClientAgreement;
