import EditIcon from "@mui/icons-material/Edit";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import BackspaceIcon from "@mui/icons-material/Backspace";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import DataRequestAutoComplete from "./DataRequestAutoComplete";
import DataRequestMarker from "./DataRequestMarker";
import { areaUnits, OrderTypeConstant } from "../../../lib/constants";
import KMLFileDropZone from "../missions/shared/KMLFileDropZone";
import theme from "../../../theme";
import KMLDownloadField from "../missions/shared/KMLDownloadField";
import { getCountryCode } from "../../../lib/helpers";

import { Box, Select, FormControl, MenuItem, Grid } from "@mui/material";
import { getUser } from "../../../services/auth";
import { userRoles } from "../../../lib/constants";
import AppButton from "../../global/elements/AppButton";

const styles = {
  root: {
    width: "100%",
    // backgroundColor: theme.palette.grey.white,
  },
  topBar: {
    display: { xs: "block", sm: "flex" },
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  logo: {
    height: 23,
    width: "auto",
  },
  map: {
    width: "100%",
    minHeight: "300px",
  },

  ctaButton: {
    "&.MuiButton-root": {
      textTransform: "none",
      width: 300,
      borderRadius: "0px",
      background: theme.palette.primary.main,
      color: theme.palette.grey.white,
      padding: "6px",
      "&.Mui-disabled": {
        background: theme.palette.grey.lightWarmGrey,
        color: theme.palette.grey.warmGrey,
      },
      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
  },
  ctaClearButton: {
    "&.MuiButton-root": {
      textTransform: "none",
      width: 300,
      borderRadius: "0px",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderColor: theme.palette.primary.main,
      padding: "6px",
      "&:hover": {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  inputField: {
    color: theme.palette.grey.white,
  },
  searchBox: {
    marginTop: 0,
  },
  toolButton: {
    height: 40,
    width: { xs: "100%", sm: "100%" },
    textTransform: "none",
    borderRadius: 0,
  },
  editbutton: {
    height: 30,
    textTransform: "none",
    borderRadius: "21px",
    fontSize: "10px",
  },
  toolClearButton: {
    "&.MuiButton-root": {
      height: 30,
      textTransform: "none",
      borderRadius: "21px",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      fontSize: "10px",
    },
  },

  sizeField: {
    margin: "0px 20px",
    textAlign: "right",
  },
  topBarLeftSide: {
    width: { xs: "60%", sm: "40%", md: "43%" },
    marginBottom: { xs: "10px", sm: "0px" },
  },
  changeUnitCls: {
    border: `1px solid ${theme.palette.black.darkSeaBlack}`,
    borderRadius: 0,

    "&.MuiInput-underline:before": {
      border: "none !important",
    },

    "&:hover": {
      border: "none !important",
      outline: "none !important",
    },
    ".css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
      backgroundColor: "rgba(0, 0, 0, 0) !important",
    },
    "&.MuiInput-underline:after": {
      border: "none !important",
      outline: "none !important",
      backgroundColor: "white !important",
    },

    paddingLeft: "10px",
    outline: "none",
  },

  addScrollBar: {
    overflowY: { xs: "scroll", sm: "hidden" },
  },
  uploadKmlButton: {
    "&.MuiButton-root": {
      width: { xs: "100px", sm: "130px", md: "110px", lg: "130px" },
    },
  },
  uploadBtn: {
    borderRadius: 0,
    "& .MuiButton-fullWidth": {
      width: "100%",
    },
    "&.MuiButton-root": {
      margin: "auto 0 auto auto",
      padding: { xs: "4px 8px", lg: "5px 8px" },
      fontSize: { xs: "10px", lg: "12px" },
      width: "100%",
      borderColor: theme.palette.primary.main,
      color: theme.palette.grey.white,
      background: theme.palette.primary.main,
      borderRadius: "25px",
      fontWeight: 500,
      textTransform: "none",
      fontFamily: "NHaasGroteskDSPro",
      "&:hover": {
        borderColor: theme.palette.primary.main,
        color: theme.palette.grey.white,
        background: theme.palette.primary.main,
      },
    },
  },
  gridBox: {
    justifyContent: "flex-end",
    display: "flex",
  },
};

const DataRequestMap = ({
  area,
  setArea,
  areaSize,
  setAreaSize,
  selectedUnit,
  setSelectedUnit,
  kmlUrl,
  setKmlUrl,
  polygon,
  setPolygon,
  mapApi,
  setMapApi,
  geocoder,
  setGeocoder,
  // label,
  defaultLocation,
  defaultZoom,
  readonly,
  onSave,
  // onCancel,
  minHeight,
  mapHeight,
  createdByPilot,
  locDataPilot,
  activatePilotKml,
  orderData,
  setSelectedCountryCode,
  disabled,
}) => {
  const mapRef = useRef(null);
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [place, setPlace] = useState(null);
  const [inDrawMode, setInDrawMode] = useState(false);
  const [drawingManager, setDrawingManager] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [infoWindow, setInfoWindow] = useState(null);
  const [mapLocationLabel, setMapLocationLabel] = useState(null);
  const [city, setCity] = useState(null);
  const [orderType, setOrderType] = React.useState(null);
  const currentUser = getUser("user");

  //CSS workaround for safari
  useEffect(() => {
    if (mapRef) {
      mapRef.current.googleMapDom_.style.minHeight = mapHeight;
    }
  }, [mapRef]);

  useEffect(() => {
    if (!mapApiLoaded) return;
    if (area) {
      loadArea(area);
    }
    const _geocoder = new mapApi.Geocoder();
    setGeocoder(_geocoder);
    let _infoWindow = new mapApi.InfoWindow({
      content: "Right Click the map to get info about the location!",
      position: null,
    });
    _infoWindow.open(mapInstance);
    setInfoWindow(_infoWindow);
    const clickInfoListener = mapApi.event.addListener(
      mapInstance,
      "rightclick",
      (mapsMouseEvent) =>
        getLocationOnClick(_geocoder, mapsMouseEvent, _infoWindow)
    );

    if (!readonly) {
      const _drawingManager = new mapApi.drawing.DrawingManager({
        drawingControl: false,
        polygonOptions: {
          draggable: true,
          editable: true,
          strokeColor: theme.palette.primary.main,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: theme.palette.grey.white,
          fillOpacity: 0.35,
          geodesic: true,
        },
        polylineOptions: {
          draggable: true,
          editable: true,
          strokeColor: theme.palette.primary.main,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: theme.palette.grey.white,
          fillOpacity: 0.35,
          geodesic: true,
        },
      });
      setDrawingManager(_drawingManager);
    }
    return () => {
      mapApi.event.removeListener(clickInfoListener);
      //global cleanup
      mapApi.event.clearInstanceListeners(mapInstance);
    };
  }, [
    getLocationOnClick,
    // loadArea,
    mapApi,
    mapApiLoaded,
    mapInstance,
    readonly,
  ]);

  useEffect(() => {
    if (!drawingManager) return;
    drawingManager.setMap(mapInstance);

    const listener2 = mapApi.event.addListener(
      drawingManager,
      "polylinecomplete",
      (pol) => {
        // console.log("polylinecomplete", pol);
        setInDrawMode(false);
        pol.setMap(mapInstance);
        setPolygon(pol);
      }
    );

    return () => {
      // mapApi.event.removeListener(listener);
      mapApi.event.removeListener(listener2);
    };
  }, [drawingManager, mapInstance]);

  useEffect(() => {
    if (!drawingManager) return;
    drawingManager.setDrawingMode(
      inDrawMode ? mapApi.drawing.OverlayType.POLYLINE : null
    );
  }, [inDrawMode, drawingManager]);

  useEffect(() => {
    const polyListeners = [];
    if (polygon) {
      setAreaSize(getAreaSize(polygon));
      polyListeners.push(
        mapApi.event.addListener(polygon.getPath(), "insert_at", updateSize)
      );
      polyListeners.push(
        mapApi.event.addListener(polygon.getPath(), "remove_at", updateSize)
      );
      polyListeners.push(
        mapApi.event.addListener(polygon.getPath(), "set_at", updateSize)
      );
      polyListeners.push(
        mapApi.event.addListener(polygon, "dragend", () => setDragging(false))
      );
      polyListeners.push(
        mapApi.event.addListener(polygon, "dragstart", () => setDragging(true))
      );
      polyListeners.push(
        mapApi.event.addListener(polygon, "rightclick", (mouseEvent) =>
          getLocationOnClick(geocoder, mouseEvent, infoWindow)
        )
      );

      save(polygon);
    }
    return () => {
      polyListeners.forEach((listener) =>
        mapApi.event.removeListener(listener)
      );
    };
  }, [
    geocoder,
    getAreaSize,
    getLocationOnClick,
    infoWindow,
    polygon,
    updateSize,
    save,
  ]);

  const getLocationOnClick = (geocoder, mouseEvent, infoWindow) => {
    infoWindow.close();
    geocoder.geocode({ location: mouseEvent.latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          infoWindow.setPosition(mouseEvent.latLng);
          infoWindow.setContent(
            formatContent(results[0].formatted_address, mouseEvent)
          );
          infoWindow.open(mapInstance);
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  };

  const formatContent = (address, mouseEvent) => {
    const coordinates = JSON.stringify(mouseEvent.latLng.toJSON(), null, 2);
    return `
      <div>
        <p><b>${address}</b><br>${coordinates}</p>
      </div>`;
  };

  const updateSize = () => {
    if (polygon && !dragging) {
      setAreaSize(getAreaSize(polygon));
      if (!readonly) {
        save(polygon);
      }
    }
  };

  const clearPolygon = () => {
    polygon?.setMap(null);
    setPolygon(null);
    setAreaSize(null);
    setArea(null);
    setCity(null);
    setKmlUrl(null);
    setMapLocationLabel(null);
    setInDrawMode(true);
  };

  const setApiHasLoaded = (map, maps) => {
    setMapInstance(map);
    setMapApi(maps);
    setMapApiLoaded(true);
  };

  const loadArea = (area) => {
    // createPolygonFromArea(area);
    createPolylineFromArea(area);
    const bounds = areaToBounds(area);
    mapInstance.setCenter(bounds.getCenter());
    mapInstance.fitBounds(bounds);
  };

  const polygonToArea = (polygon) => {
    const area = [];
    polygon.getPath().forEach((element) => area.push(element.toJSON()));
    return area;
  };

  const areaToBounds = (area) => {
    const bounds = new mapApi.LatLngBounds();
    area.forEach((coord) => bounds.extend(new mapApi.LatLng(coord)));
    return bounds;
  };

  const getAreaSizeInSquareKiloMeters = (polygon) => {
    const areaSize = mapApi.geometry.spherical.computeArea(polygon.getPath());
    return areaSize / 1000000; //convert sqm to sqkm
  };

  const findOrderType = (area) => {
    let type = null;
    if (area) {
      if (area.length == 1) {
        // This is assest (point)
        setOrderType(OrderTypeConstant.assets);
        type = OrderTypeConstant.assets;
      } else {
        if (
          area[0].lat == area[area.length - 1].lat &&
          area[0].lng == area[area.length - 1].lng
        ) {
          // This is polygon
          setOrderType(OrderTypeConstant.area);
          type = OrderTypeConstant.area;
        } else {
          // This is linear
          setOrderType(OrderTypeConstant.linear);
          type = OrderTypeConstant.linear;
        }
      }
    }
    return type;
  };

  const getAreaSize = (polygon) => {
    const coord = polygonToArea(polygon);
    const type = findOrderType(coord);
    let areaSize = 0;
    if (type == OrderTypeConstant.area) {
      // POLYGON
      areaSize = mapApi.geometry.spherical.computeArea(polygon.getPath());
      return areaSize / 1000000; //convert sqm to sqkm
    } else if (type == OrderTypeConstant.assets) {
      // ASSET
      return 0;
    } else {
      // LINEAR
      for (let i = 0; i < coord.length; i++) {
        if (i != coord.length - 1) {
          areaSize += mapApi.geometry.spherical.computeDistanceBetween(
            new mapApi.LatLng(coord[i]),
            new mapApi.LatLng(coord[i + 1])
          );
        }
      }

      // return distance in meters
      areaSize = areaSize / 1000; // Changing this to Km
      return areaSize;
    }
  };

  const createPolylineFromArea = (area) => {
    const coords = [];
    area.forEach((coordinate) =>
      coords.push(new mapApi.LatLng(coordinate.lat, coordinate.lng))
    );
    const pol = new mapApi.Polyline({
      map: mapInstance,
      path: coords,
      strokeColor: theme.palette.primary.main,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: theme.palette.grey.white,
      fillOpacity: 0.35,
      draggable: !readonly,
      editable: !readonly,
      geodesic: true,
    });
    setPolygon(pol);
  };

  const createPolygonFromArea = (area) => {
    const pol = new mapApi.Polygon({
      map: mapInstance,
      paths: area,
      strokeColor: theme.palette.primary.main,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: theme.palette.grey.white,
      fillOpacity: 0.35,
      draggable: !readonly,
      editable: !readonly,
      geodesic: true,
    });
    setPolygon(pol);
  };

  const save = (polygon) => {
    const area = polygonToArea(polygon);
    const type = findOrderType(area);
    let locationLabel;
    let code;
    const bounds = new mapApi.LatLngBounds();
    area.forEach((coordinate) => bounds.extend(coordinate));
    geocoder.geocode({ location: bounds.getCenter() }, (results, status) => {
      if (status === "OK") {
        code = getCountryCode(results);
        if (results[0]) {
          locationLabel = results[0].formatted_address;
        }
        {
          locationLabel && setCity(locationLabel?.split(" ").slice(-4)[0]);
        }
        setMapLocationLabel(locationLabel?.split(" ").slice(-1)[0]);
        if (code && typeof setSelectedCountryCode == "function") {
          setSelectedCountryCode(code);
        }
      }
      setArea(area);
      onSave({ locationLabel: locationLabel, type: type });
    });
  };

  const CharacterDropDown = () => {
    if (orderType && orderType != OrderTypeConstant.area) {
      return null;
    }
    return (
      <FormControl variant="standard" sx={styles.toolButton}>
        <Select
          label="unit"
          onChange={function (e) {
            setSelectedUnit(e.target.value);
          }}
          sx={styles.changeUnitCls}
          title="Enter a location"
          value={selectedUnit}
        >
          <MenuItem value={areaUnits.sqkm}>{areaUnits.sqkm}</MenuItem>
          <MenuItem value={areaUnits.sqm}>{areaUnits.sqm}</MenuItem>
          <MenuItem value={areaUnits.hectare}>{areaUnits.hectare}</MenuItem>
          <MenuItem value={areaUnits.sqmiles}>{areaUnits.sqmiles}</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const setKML = (url, locData) => {
    setArea(null);
    setAreaSize(null);
    setMapData(url, locData);
  };

  useEffect(() => {
    if ((kmlUrl, locDataPilot)) {
      const url = kmlUrl;
      setKML(url, locDataPilot);
    }
  }, [activatePilotKml]);

  const setMapData = (url, { locMap, areaSize, countryCode }) => {
    setArea(locMap);
    setAreaSize(areaSize);
    loadArea(locMap);
    setKmlUrl(url);

    if (countryCode && typeof setSelectedCountryCode == "function") {
      setSelectedCountryCode(countryCode);
    }
  };

  const areaSizeUnitNotation = () => {
    if (orderType == OrderTypeConstant.area) {
      if (selectedUnit == areaUnits.sqkm) {
        return ` ${areaUnits.sqkm}`;
      }
      if (selectedUnit == areaUnits.sqm) {
        return ` ${areaUnits.sqm}`;
      }
      if (selectedUnit == areaUnits.hectare) {
        return ` ${areaUnits.hectare}`;
      }
      if (selectedUnit == areaUnits.sqmiles) {
        return ` ${areaUnits.sqmiles}`;
      }
    } else if (orderType == OrderTypeConstant.linear) {
      return " km";
    } else {
      return "";
    }
  };

  const displayAreaSizeInSelectedUnits = (areaSize) => {
    if (orderType == OrderTypeConstant.area) {
      if (selectedUnit == areaUnits.sqm) {
        return (areaSize * 1000000).toFixed(2); //convert sqkm to sqm
      }
      if (selectedUnit == areaUnits.hectare) {
        return (areaSize * 100).toFixed(2); //convert sqkm to hectare
      }
      if (selectedUnit == areaUnits.sqmiles) {
        return (areaSize * 0.38610215854781257).toFixed(2); //convert sqkm to square miles
      }
      return areaSize?.toFixed(2); //keep it in sq km
    } else if (orderType == OrderTypeConstant.linear) {
      return areaSize;
    } else {
      return areaSize;
    }
  };
  return (
    <Box
      sx={
        readonly
          ? { ...styles.root }
          : { ...styles.root, ...styles.addScrollBar }
      }
    >
      {!readonly && (
        <Grid container spacing={1} sx={{ marginBottom: "10px" }}>
          <Grid item xs={12} lg={12}>
            {mapApiLoaded && (
              <DataRequestAutoComplete
                map={mapInstance}
                mapApi={mapApi}
                addplace={(newPlace) => setPlace(newPlace)}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6} />
          <Grid item xs={12} lg={2} sx={styles.gridBox}>
            {!(
              (currentUser.role == userRoles.admin ||
                currentUser.role == userRoles.client) &&
              orderData?.subscriptionId
            ) && areaSize ? (
              <AppButton
                label="Edit"
                small
                onClick={() => setInDrawMode(false)}
                customIcon={<EditIcon />}
                isDisabled={inDrawMode || disabled}
                look={!inDrawMode ? "inverted" : "invertedDisabled"}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} lg={2} sx={styles.gridBox}>
            {!(
              (currentUser.role == userRoles.admin ||
                currentUser.role == userRoles.client) &&
              orderData?.subscriptionId
            ) && (
              <>
                {" "}
                <AppButton
                  label="Draw"
                  small
                  onClick={() => setInDrawMode(true)}
                  customIcon={<ArchitectureIcon />}
                  isDisabled={polygon || disabled}
                  look={
                    polygon ? "greenDisabled" : inDrawMode ? "green" : "green"
                  }
                />
              </>
            )}
          </Grid>
          <Grid item xs={6} lg={2} sx={styles.gridBox}>
            {!(
              (currentUser.role == userRoles.admin ||
                currentUser.role == userRoles.client) &&
              orderData?.subscriptionId
            ) && (
              <AppButton
                small
                label="Clear"
                onClick={() => clearPolygon()}
                customIcon={<BackspaceIcon />}
                look={"negative"}
                isDisabled={disabled}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Box
        sx={{
          width: "100%",
          minHeight: minHeight,
          height: mapHeight,
          borderRadius: "0px",
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            libraries: ["drawing", "geometry", "places"],
            key: process.env.GATSBY_FIREBASE_API_KEY,
          }}
          defaultCenter={defaultLocation}
          defaultZoom={defaultZoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => setApiHasLoaded(map, maps)}
          options={{
            mapTypeControl: true,
            mapTypeId: "satellite",
          }}
          ref={mapRef}
        >
          {place && (
            <DataRequestMarker
              key={place.id}
              text={place.name}
              lat={place.geometry.location.lat()}
              lng={place.geometry.location.lng()}
            />
          )}
        </GoogleMapReact>
      </Box>

      <Box
        sx={{
          marginTop: { xs: "20px", md: "30px" },
          display: "flex",
          flexWrap: { xs: "wrap", sm: "", md: "wrap", lg: "" },
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: {
              xs: areaSize ? "100%" : "35%",
              sm: areaSize ? "55%" : "50%",
              md: areaSize ? "50%" : "50%",
              lg: areaSize ? "55%" : "45%",
              xl: areaSize ? "55%" : "45%",
            },
            justifyContent: { xs: areaSize ? "space-between" : "", sm: "" },
          }}
        >
          <Box sx={{ display: "flex", marginRight: "20px" }}>
            <LocationOnOutlinedIcon />

            <Box component="span" sx={styles.areaLabel}>
              {city && city} {mapLocationLabel}
              {!city && !mapLocationLabel && "location"}
            </Box>
          </Box>

          {orderType != OrderTypeConstant.assets && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <MapOutlinedIcon sx={{ marginLeft: "10px" }} />
              <Box
                component="span"
                sx={{ width: { xs: areaSize ? "110px" : "40px" } }}
              >
                {displayAreaSizeInSelectedUnits(areaSize)}
                {areaSizeUnitNotation() || "km2"}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            width: { xs: "25%", sm: "15%", md: "20%", lg: "15%" },
            marginTop: { xs: "10px", sm: "0px", lg: "0px" },
          }}
        >
          {areaSize ? <Box>{CharacterDropDown()}</Box> : <></>}
        </Box>
        {!createdByPilot &&
          !(
            (currentUser.role == userRoles.admin ||
              currentUser.role == userRoles.client) &&
            orderData?.subscriptionId
          ) && (
            <KMLFileDropZone
              onUploaded={(url, locData) => {
                setKML(url, locData);
              }}
              currentFileUrl={kmlUrl}
              disabled={disabled}
              additionalStyle={{
                ...styles.uploadKmlButton,
                marginTop: {
                  xs: areaSize ? "10px" : "0px",
                  sm: "0px",
                  lg: "0px",
                },
              }}
            />
          )}
        {kmlUrl && <KMLDownloadField url={kmlUrl} />}
      </Box>
    </Box>
  );
};

DataRequestMap.propTypes = {
  area: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    })
  ),
  setArea: PropTypes.func,
  areaSize: PropTypes.number,
  setAreaSize: PropTypes.func,
  selectedUnit: PropTypes.string,
  setSelectedUnit: PropTypes.func,
  kmlUrl: PropTypes.string,
  setKmlUrl: PropTypes.func,
  polygon: PropTypes.string,
  setPolygon: PropTypes.func,
  mapApi: PropTypes.object,
  setMapApi: PropTypes.func,
  geocoder: PropTypes.string,
  setGeocoder: PropTypes.func,
  defaultLocation: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  defaultZoom: PropTypes.number,
  readonly: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  minHeight: PropTypes.string,
  mapHeight: PropTypes.object,
  createdByPilot: PropTypes.bool,
  activatePilotKml: PropTypes.bool,
  disabled: PropTypes.bool,
};

DataRequestMap.defaultProps = {
  setArea: undefined,
  areaSize: undefined,
  setAreaSize: undefined,
  selectedUnit: undefined,
  setSelectedUnit: undefined,
  kmlUrl: undefined,
  setKmlUrl: undefined,
  polygon: undefined,
  setPolygon: undefined,
  mapApi: undefined,
  setMapApi: undefined,
  geocoder: undefined,
  setGeocoder: undefined,
  area: [],
  defaultLocation: { lat: 0, lng: 0 },
  defaultZoom: 0,
  readonly: false,
  onSave: undefined,
  onCancel: undefined,
  mapHeight: undefined,
  minHeight: undefined,
  createdByPilot: false,
  activatePilotKml: false,
  disabled: false,
};

export default DataRequestMap;
