import React, { useState, useEffect } from "react";
import { generateFeatureObj } from "../../../lib/helpers";
import MapBox from "../../app/mapBox/Map";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  fullHeight: { height: "100%" },
};

const MissionMap = ({ missionData }) => {
  const [missions, setMissions] = useState([]);

  useEffect(() => {
    if (missionData?.length) {
      let featuresArray = [];
      missionData.map((msnData) => {
        if (msnData.locationMap) {
          const featureObj = generateFeatureObj(msnData);
          featuresArray.push({ ...featureObj, missionData: msnData });
        }
      });
      setMissions({ features: featuresArray, type: "FeatureCollection" });
    }
  }, [missionData]);
  return (
    <Box sx={styles.fullHeight}>
      <MapBox geoJson={missions} />
    </Box>
  );
};

MissionMap.propTypes = {
  missionData: PropTypes.array,
};
MissionMap.defaultProps = {
  missionData: [],
};

export default MissionMap;
