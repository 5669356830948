import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import AppButton from "../../../global/elements/AppButton";
import firebase from "src/firebase";
import { getUser } from "../../../../services/auth";
import { loadStripe } from "@stripe/stripe-js";

const PaymentTesting = () => {
  const currentUser = getUser("user");
  const checkoutFunction = async () => {
    const docRef = await firebase
      .firestore()
      .collection("clients")
      .doc(currentUser.userID)
      .collection("checkout_sessions")
      .add({
        line_items: [
          {
            price_data: {
              currency: "usd",
              product_data: {
                name: "mission 1",
                totalDataSize: 1000,
                dataSizeUnit: "MB",
                missionId: "erwe",
                description: "fdd",
              },
              unit_amount: 2000,
            },
            quantity: 1,
          },
          {
            price_data: {
              currency: "usd",
              product_data: {
                name: "mission 2",
                totalDataSize: 1500,
                dataSizeUnit: "MB",
                missionId: "erwe",
                description: "fdd",
              },
              unit_amount: 3000,
            },
            quantity: 1,
          },
        ],
        mode: "payment",
        success_url: `${window.location.origin}/app/clientdata-library/purchased`,
        cancel_url: `${window.location.origin}/app/client/data-library/wishlist`,
      });

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        alert(`An Error occured : ${error.message}`);
        return {
          status: "error",
          message: error.message,
        };
      }
      if (sessionId) {
        const stripe = await loadStripe(
          "pk_test_51JxXQ7BgfhxaRWFOygIieoPkA3eO1zuGzfwlwn7MDud7oMXOoC7FzQsFBMB0cVtPtuH2LIkIfrOH3tZEJ99kuAsZ00lbbHzMx2"
        );
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllPlans();
  }, []);

  const getAllPlans = () => {
    const db = firebase.firestore();
    setLoading(true);

    db.collection("subscriptionPricingPlans")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        let monthly = [];
        let yearly = [];
        querySnapshot.forEach(async (plansDoc) => {
          const priceSnapshot = await plansDoc.ref.collection("prices").get();
          priceSnapshot.docs.forEach((price) => {
            if (price.data().interval === "year") {
              let planObject = {
                id: plansDoc.id,
                data: plansDoc.data(),
              };
              planObject.data.price = {
                priceId: price.id,
                priceData: price.data(),
              };
              yearly.push(planObject);
            } else if (price.data().interval === "month") {
              let planObject = {
                id: plansDoc.id,
                data: plansDoc.data(),
              };
              planObject.data.price = {
                priceId: price.id,
                priceData: price.data(),
              };
              monthly.push(planObject);
            }
          });
        });
        setMonthlyPlans(monthly);
        setYearlyPlans(yearly);
        setLoading(false);
      });
  };

  const manageSubscriptionFcn = async () => {
    const functionRef = firebase
      .functions("europe-west3")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: "http://127.0.0.1:8000/app/client/subscription/payment",
      // locale: "auto", // Optional, defaults to "auto"
      // configuration: "bpc_1MMV9UGWSz0eXo0Pdlgvu0ik", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    window.location.assign(data.url);
  };

  return (
    <Box>
      {!loading ? (
        <>
          <Box>Testing Page for Payment</Box>
          {monthlyPlans?.length > 0 && (
            <>
              <Box>Montly Plan</Box>
              <Box>
                {monthlyPlans.map((plan, i) => {
                  return <Box key={i}>{plan?.name}</Box>;
                })}
              </Box>
            </>
          )}
          <br />
          <br />

          <AppButton
            look={"primary"}
            label="Checkout"
            onClick={checkoutFunction}
          />

          <br />
          <br />

          <AppButton
            look={"primary"}
            label="Manage Subscription"
            onClick={manageSubscriptionFcn}
          />
        </>
      ) : (
        <Box>Loading....</Box>
      )}
    </Box>
  );
};

export default PaymentTesting;
